import { AuthResponse } from "models/api/auth/NidAuth";
import { NidAuth, LoginError } from "models/Auth";
import { request } from "utils/apiClient";
import {
  getRtoasterId,
  setTokenAndId,
  setRtoasterId as setRtoasterIdToLocalStorage,
  setAuthMethod
} from "./localStorage";
import { countCharacter } from "utils/helper/common";
import { isEmpty } from "lodash";

interface ChargeCode {
  chargeCode1: string | null;
  chargeCode2: string | null;
}

interface AuthorizeByTokenOption extends ChargeCode {
  directAccessAuthCode: string;
}

interface AuthorizeByNikkeiIdOption extends ChargeCode {
  code: string;
  nonce: string;
  redirectUri: string;
  plan: string;
}

const buildRtoasterId = () => {
  const length = 128;
  const character =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  return [...Array(length)]
    .map(() => character[Math.floor(Math.random() * character.length)])
    .join("");
};

const setRtoasterId = () => {
  if (!getRtoasterId()) {
    setRtoasterIdToLocalStorage(buildRtoasterId());
  }
};

export const authorizeByToken = (option: AuthorizeByTokenOption) => {
  const params: { [key: string]: string } = {
    directAccessAuthCode: option.directAccessAuthCode
  };

  if (option.chargeCode1) {
    params["chargeCode1"] = option.chargeCode1;
    if (option.chargeCode2) {
      params["chargeCode2"] = option.chargeCode2;
    }
  }

  setRtoasterId();
  return requestAuthorize("/nid/token-auth", "Token", params);
};

export const authorizeByIp = () => {
  setRtoasterId();
  return requestAuthorize("/nid/ip-auth", "IP");
};

export const prepareAuthorizingByNikkeiId = async (chargeCodes?: {
  chargeCode1: string;
  chargeCode2: string;
}) => {
  const params = chargeCodes ?? {};
  const response = await request<NidAuth>("/nid/pre-auth", params);
  const redirectUrl =
    MODE === "production" && API_DOMAIN
      ? `https://${API_DOMAIN}/login`
      : `${window.location.origin}/login`;
  const searchParams = new URLSearchParams();
  searchParams.set("response_type", "code");
  searchParams.set("client_id", response.clientId);
  searchParams.set("redirect_uri", redirectUrl);
  searchParams.set("scope", response.scope);
  searchParams.set("nonce", response.nonceVal);
  return {
    redirectUrl: `${response.url}?${searchParams}`,
    response
  };
};

export const authorizeByNikkeiId = (option: AuthorizeByNikkeiIdOption) => {
  const params: { [key: string]: string } = {
    code: option.code,
    nonce: option.nonce,
    redirectUri: option.redirectUri,
    plan: option.plan
  };

  if (option.chargeCode1) {
    params["chargeCode1"] = option.chargeCode1;
    if (option.chargeCode2) {
      params["chargeCode2"] = option.chargeCode2;
    }
  }

  return requestAuthorize("/nid/post-auth", "NID", params);
};

export const authorizeByPlanDownload = (token: string) => {
  const params: { [key: string]: string } = {
    plan: "download",
    downloadToken: token
  };

  return requestAuthorize("/nid/post-auth", "NID", params);
};

export const handleAuthError = (error: any) => {
  if (
    error.hasOwnProperty("body") &&
    error.body.hasOwnProperty("details") &&
    Array.isArray(error.body.details)
  ) {
    return {
      apiErrorCode:
        "apiErrorCode" in error.body.details[0]
          ? (error.body.details[0].apiErrorCode as string)
          : null,
      other:
        "message" in error.body.details[0]
          ? (error.body.details[0].message as string)
          : null
    };
  }

  return null;
};

export const forceLogout = async (userIds: string) => {
  return await request("/nid-user/manage/force-logout", { userIds }, "POST");
};

const requestAuthorize = async (
  endpoint: string,
  method: "NID" | "IP" | "Token",
  params?: { [key: string]: string }
) => {
  const response = await request<AuthResponse>(endpoint, params);
  const { userId, tokenKey, contractId } = response.loginInfo;
  setTokenAndId(tokenKey, userId, contractId);
  setAuthMethod(method);
  return response;
};

export const validateChargeCode = (
  chargeCode1: string,
  chargeCode2: string
): LoginError => {
  // チャージコードの入力なし
  if (isEmpty(chargeCode1) && isEmpty(chargeCode2)) {
    return {
      chargeCode1: null,
      chargeCode2: null
    };
  }

  // チャージコード1 入力チェック
  if (chargeCode1 === "") {
    return {
      chargeCode1: "チャージコードを入力してください",
      chargeCode2: null
    };
  }

  // カウントチェック
  const count1 = countCharacter(chargeCode1);
  const count2 = countCharacter(chargeCode2);

  if ((count2 === 0 && count1 > 40) || count1 + count2 > 39) {
    return {
      chargeCode1: null,
      chargeCode2:
        "チャージコード1、チャージコード2はあわせて半角39(全角19）文字以内で入力してください"
    };
  }

  return {
    chargeCode1: null,
    chargeCode2: null
  };
};
