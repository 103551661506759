exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-NotFound-style__error--1RUje {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  width: calc(100vw - 160px);\n  height: 200px;\n  margin: auto;\n}\n\n@media screen and (max-width: 1591px) {\n  .src-features-NotFound-style__error--1RUje {\n    width: calc(100vw - 56px);\n  }\n}\n.src-features-NotFound-style__title--5S5eG {\n  margin-bottom: 32px;\n  text-align: center;\n}\n\n.src-features-NotFound-style__content--2gHfA {\n  text-align: center;\n}\n.src-features-NotFound-style__content--2gHfA p {\n  margin: 3px 0;\n}\n\n.src-features-NotFound-style__btn--Jmq-O {\n  width: 100%;\n  margin-top: 25px;\n  text-align: center;\n}\n\n.src-features-NotFound-style__button--2h-wD {\n  width: 240px;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"error": "src-features-NotFound-style__error--1RUje",
	"title": "src-features-NotFound-style__title--5S5eG",
	"content": "src-features-NotFound-style__content--2gHfA",
	"btn": "src-features-NotFound-style__btn--Jmq-O",
	"button": "src-features-NotFound-style__button--2h-wD"
};