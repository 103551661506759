import React from "react";
import DownloadContext from "contexts/download";

const fileDownload = Component => props => (
  <DownloadContext.Consumer>
    {_download => (
      <Component
        downloadResourceId={_download.downloadResourceId}
        startDownload={_download.startDownload}
        stopDownload={_download.stopDownload}
        needAuthenticate={_download.needAuthenticate}
        {...props}
      />
    )}
  </DownloadContext.Consumer>
);

export default fileDownload;
