// 初期データ取得
export const INIT_PAGE = "setting/user-fee/INIT_PAGE";
// 指定した年月の利用料金合計を取得
export const SUMMARY_GET = "setting/user-fee/SUMMARY_GET";
// 全体の年月変更
export const SELECTED_MONTH_INDEX_UPDATE =
  "setting/user-fee/SELECTED_MONTH_INDEX_UPDATE";
// ユーザ別利用明細の年月変更
export const SELECTED_USER_DETAIL_MONTH_INDEX_UPDATE =
  "setting/user-fee/SELECTED_USER_DETAIL_MONTH_INDEX_UPDATE";
// ユーザ別利用明細のチャージコード変更
export const SELECTED_CHARGE_CODE_UPDATE =
  "setting/user-fee/SELECTED_CHARGE_CODE_UPDATE";
// 選択中の user
export const CURRENT_USER_SET = "setting/user-fee/CURRENT_USER_SET";
// 指定したユーザの年月の利用明細を取得
export const USAGE_DETAIL_GET = "setting/user-fee/USAGE_DETAIL_GET";
// store を全てリセット
export const ALL_RESET = "setting/user-fee/ALL_RESET";
