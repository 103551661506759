import { setErrors } from "modules/toast/actions";
import * as corpDomesticFinanceActionTypes from "modules/corp/domestic/finance/actionType";
import * as corpDomesticMaStrategyActionTypes from "modules/corp/domestic/ma-strategy/actionType";
import * as bookmarkBalloonActionTypes from "modules/common/BookmarkBalloon/actionType";
import { setSessionState } from "modules/auth/temporary/actions";
import { setRedirectPath } from "modules/auth/saved/actions";
import { updateAccessStatus } from "modules/accessControl/actions";
import { ACCESS_CONTROL_PATHS } from "features/AccessControl/useAccessControl";
import { isEmpty } from "utils/helper/common";

// エラー処理を行わない ActionTypes
const whiteList = [
  `${corpDomesticFinanceActionTypes.PAGE_GET}_REJECTED`,
  `${corpDomesticFinanceActionTypes.BUSINESSCODE_CHANGE}_REJECTED`,
  `${corpDomesticMaStrategyActionTypes.MA_PAGING}_REJECTED`,
  "api/binder/save_REJECTED",
  "api/binder/update_REJECTED",
  `${bookmarkBalloonActionTypes.CORP_ITEM_INSERT}_REJECTED`,
  `${bookmarkBalloonActionTypes.INDUSTRY_ITEM_INSERT}_REJECTED`,
  `${bookmarkBalloonActionTypes.STATISTIC_ITEM_INSERT}_REJECTED`,
  `${bookmarkBalloonActionTypes.ARTICLE_ITEM_INSERT}_REJECTED`,
  "api/nid/token-auth_REJECTED",
  "api/nid/ip-auth_REJECTED",
  "api/nid/pre-auth_REJECTED",
  "api/nid/post-auth_REJECTED",
  "api/binder/card/external-page/preview_REJECTED",
  "api/binder/function-authority_REJECTED",
  "common/SourceSelector/SELECTION_GET_REJECTED"
];

// pending状態のActionのスタック
let pendingActionList = [];

export default () => next => action => {
  const actionName = action.type.replace(/(.*)_.*/, "$1");
  if (action.type.indexOf("_PENDING") >= 0) {
    // APIリクエスト中Actionを追加
    pendingActionList.push(actionName);
  }
  if (action.type.indexOf("_FULFILLED") >= 0) {
    // fulfillしたpending状態のActionを取り除く
    const targetIndex = pendingActionList.indexOf(actionName);
    pendingActionList = pendingActionList.filter(
      (el, index) => index !== targetIndex
    );
  }

  if (window.location.pathname.indexOf("/corp/") >= 0) {
    if (action.type.indexOf("PAGE_INIT") >= 0) {
      // https://nikkeilabs.backlog.jp/view/B2B_NVS_FR_2018-1540
      // 個社ページ初期化API取得時に取れるアクセスステータスでページ表示可否をコントロールする
      // ※ 海外企業ページの表示可否を想定
      if (action.payload && action.payload.accessStatus) {
        next(updateAccessStatus(action.payload.accessStatus));
      } else {
        next(updateAccessStatus(""));
      }
    }
  } else if (window.location.pathname.indexOf("/article/report") >= 0) {
    if (
      action.type.indexOf("PAGE_INIT") >= 0 ||
      action.type.indexOf("TAB_LIST_GET") >= 0
    ) {
      if (action.payload && action.payload.accessStatus) {
        next(updateAccessStatus(action.payload.accessStatus));
      } else {
        next(updateAccessStatus(""));
      }
    }
  } else if (window.location.pathname.indexOf("/article/news") >= 0) {
    if (action.type.indexOf("SEARCH_FULFILLED") >= 0) {
      if (action.payload && action.payload.accessStatus) {
        next(updateAccessStatus(action.payload.accessStatus));
      } else {
        next(updateAccessStatus(""));
      }
    }
  } else if (!ACCESS_CONTROL_PATHS.includes(window.location.pathname)) {
    // /features/AccessControl で制御しない場合
    next(updateAccessStatus(""));
  }

  if (action.type.indexOf("AUTHENTICATE_FULFILLED") >= 0) {
    // ログイン成功した時点でスタックを空にする(ログイン成功後に時間差で401エラーが返されたときにログイン画面に飛ばさないように)
    pendingActionList = [];
  }
  if (action.type === "login/LOGOUT_PENDING") {
    // ログアウトした時点でスタックを空にして処理スキップ(ログアウト成功後に時間差で401エラーが返されたときに再ダイレクトアクセスさせないように)
    pendingActionList = [];
    return next(action);
  }
  // APIエラー共通処理
  if (action.type.indexOf("_REJECTED") >= 0) {
    // エラー処理をスキップ
    if (whiteList.find(value => value === action.type) !== undefined) {
      return next(action);
    }

    let messages = [];

    switch (action.payload.message) {
      case "BAD_REQUEST":
        // Toast を表示
        messages = action.payload.body.details.map(item => item.message);
        next(setErrors(messages));
        break;
      case "INTERNAL_SERVER_ERROR":
        // Toast を表示
        // https://nikkeilabs.backlog.jp/view/B2B_NVS_FR_2018-4124#comment-1399584089
        messages = isEmpty(action.payload.body.details)
          ? "サーバーエラーによりお探しのページを表示できません。時間をおいて再度お試しください。解決しない場合は管理者に連絡してください。"
          : action.payload.body.details.map(item => item.message);
        next(setErrors(messages));
        break;
      case "UNAUTHORIZED":
        // 401になったActionに対応するpending状態のActionがない場合は既に再ログイン済みなので401何もしない
        if (pendingActionList.indexOf(actionName) === -1) {
          break;
        }
        // 再ログインを求める
        next(setSessionState("inactive"));
        next(setRedirectPath(`${location.pathname}${location.search}`));
        break;
    }

    // rejectしたpending状態のActionを取り除く
    const targetIndex = pendingActionList.indexOf(actionName);
    pendingActionList = pendingActionList.filter(
      (el, index) => index !== targetIndex
    );
  }

  return next(action);
};
