import React from "react";

import List from "./List";
import Button from "./Button";
import Label from "./Label";
import Search from "./Search";
import Radio from "./Radio";
import RadioButton from "./RadioButton";

/**
 * 汎用Select要素
 *
 * @prop {object[]} optionList - option項目の配列
 * options = [
 *  {value: "200709", label: "2007"}
 * ]
 * @prop {string} selectedOption - 選択中のoption項目の文字列
 * @prop {function} onSelected - 選択時の要素を返す callback
 * @prop {?string} type - 種別 (select, button, label, search, radio, radiobutton)
 * @prop {?string} label - ラベル
 * @prop {?boolean} multiple - 複数選択可否 (type:list のみ)
 * @prop {?number} size - 複数行時の行数指定 (type:list のみ)
 * @prop {?boolean} uncontrolled - 状態管理を手動でするかどうか (type:list のみ)
 *   true の場合は value に、false の場合は defaultValue に selectedOption を与える
 * @prop {string} name - input に指定する名前 (type:radio のみ)
 * @prop {?boolean} isBold - 太字にするか
 * @prop {?boolean} disabled - 無効状態かどうか
 */

const Select = ({ type = "list", label = null, isBold = false, ...props }) => {
  if (!props.optionList || !Object.keys(props.optionList).length) {
    return null;
  }

  const setProps = {
    optionList: props.optionList,
    selectedOption: props.selectedOption,
    onSelected: props.onSelected,
    label,
    ...props
  };
  const buttonProps = { ...setProps, isBold };

  switch (type) {
    case "button":
      return <Button {...buttonProps} />;
    case "label":
      return <Label {...setProps} />;
    case "search":
      return <Search {...setProps} />;
    case "list":
      return <List {...setProps} />;
    case "radio":
      return <Radio {...setProps} />;
    case "radiobutton":
      return <RadioButton {...setProps} />;
    default:
      return null;
  }
};

export default Select;
