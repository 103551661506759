import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { getOptionsResponses } from "utils/helper/telecomParams";
import { request } from "utils/apiClient/base";
import { flags } from "utils/helper/pager";

const initialState = {
  pageData: {},
  listData: {},
  params: []
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      pageData: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.LOAD_LIST}_FULFILLED`]: (state, action) => ({
      ...state,
      listData: action.payload
    }),
    [`${ActionTypes.LOAD_PARAMS}_FULFILLED`]: (state, action) => ({
      ...state,
      params: action.payload[0]
    })
  },
  initialState
);

export const actionCreators = {
  // 株価取得 /corp/index
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(136, params)),
  // 適宜開示一覧取得 /corp/index/page
  loadList: createAction(ActionTypes.LOAD_LIST, params =>
    request(141, {
      flag: flags.top,
      transitionId: "",
      currentPage: "1",
      ...params
    })
  ),
  // 適宜開示一覧 チェックボックス取得 /common/telecom/parameter
  loadParams: createAction(ActionTypes.LOAD_PARAMS, params =>
    getOptionsResponses(params)
  )
};
