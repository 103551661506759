import React from "react";
import styles from "./style.scss";
import { loadCorporationChildPagePaths, validatePath } from "utils/helper/url";

import Usual from "./Usual";
import SharesHoldings from "./SharersHoldings";
import Overview from "./Overview";
import MaStrategy from "./MaStrategy";
import { isEmpty } from "utils/helper/common";
import Investment from "./Investment";
import Tdb from "./Tdb/index";
import TrendReport from "./TrendReport";
import TrendReportPrint from "./TrendReport/Print";
import ArticleStrategy from "./ArticleStrategy";
import { useMobilePage } from "hooks/useMobilePage";

const vp = validatePath("popup");

/**
 * ポップアップ
 */
const Popup = props => {
  const isMobilePage = useMobilePage();
  const searchParams = new URLSearchParams(props.location.search);
  let match = false;
  const PopupRoute = ({ path, render, component, articleKbn }) => {
    if (Array.isArray(path))
      return path.map(p => (
        <PopupRoute key={p} path={p} component={component} render={render} />
      ));
    const isMatchArticleKbn = articleKbn
      ? articleKbn === searchParams.get("articleKbn")
      : true;
    if (match || !(path === props.pathname && isMatchArticleKbn)) return null;
    match = true;
    if (!isEmpty(render)) return render();
    return React.createElement(component, { ...props, isMobilePage });
  };
  const overviewPath = loadCorporationChildPagePaths("corporation", "basics")[1]
    .path;
  const overviewPathUnlisted = `${overviewPath}/unlisted`;

  return (
    <div className={styles.component}>
      <PopupRoute
        path={[
          overviewPath,
          overviewPathUnlisted,
          loadCorporationChildPagePaths("corporation", "meeting")[0].path
        ]}
        component={Overview}
      />
      <PopupRoute
        path={loadCorporationChildPagePaths("corporation", "shares")[1].path}
        render={() => <SharesHoldings {...props.sharesHoldingsProps} />}
      />
      <PopupRoute
        path={`${
          loadCorporationChildPagePaths("corporation", "shares")[0].path
        }/investments`}
        component={Investment}
      />
      <PopupRoute path={vp("/corp/ma-strategy/ma")} component={MaStrategy} />
      <PopupRoute
        path={[vp("/corp/summary/tdb-corp"), vp("/corp/summary/tdb-finance")]}
        render={() => <Tdb {...{ ...props, ...props.tdbPopupProps }} />}
      />
      <PopupRoute
        path={vp("/home/trend-report")}
        render={() => <TrendReport {...props.trendReportProps} />}
      />
      <PopupRoute
        path={vp("/home/trend-report-print")}
        render={() => <TrendReportPrint {...props.trendReportProps} />}
      />
      <PopupRoute
        path={vp("/binder")}
        articleKbn="4"
        render={() => <TrendReport {...props.trendReportProps} />}
      />
      <PopupRoute path={vp("/article/strategy")} component={ArticleStrategy} />
      {/* デフォルトのポップアップ、マッチするパスがなければこれが表示される */}
      <PopupRoute
        path={props.pathname}
        component={Usual}
        articleKbn={searchParams.get("articleKbn")}
      />
    </div>
  );
};

export default Popup;
