import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import styles from "./style.scss";
import { Sort } from "../Common/Sort";
import { Card } from "../Common/Card";
import { Table } from "../Common/Table";
import { Empty } from "../../../Common/Empty";
import { SearchEmpty } from "../../../Common/SearchEmpty";
import { usePersonalBinder } from "./hook";
import { useBinderAction } from "../../../Common/BinderActionModal/hook";
import Loading from "components/Common/Loading";
import Pager from "components/Common/Pager";
import { useIpAuthFlg } from "hooks/useIpAuthFlg";
import LoadingImage from "images/icon/loading.svg";

const FIRST_PAGE = "1";
const PREV_PAGE = "2";
const NEXT_PAGE = "3";

export const PersonalBinderList: React.FunctionComponent = () => {
  const {
    isLoading,
    sortValue,
    isError,
    binderItems,
    fetchPersonalBinders,
    sortPersonalBinder,
    cardViewMode,
    switchCardViewMode,
    addPersonalBinders,
    isAdding,
    totalCount,
    offset,
    limit,
    changePage,
    changeLimit,
    onKeywordSearch,
    personalKeyword,
    setPersonalKeyword
  } = usePersonalBinder();
  const { setBinderActionModal } = useBinderAction();
  const ipAuthFlg = useIpAuthFlg();
  const history = useHistory();
  const [bottom, setBottom] = useState(false);

  const tableWrapperRef = useRef(null);
  const [pageStart, setPageStart] = useState(1);
  const [pageEnd, setPageEnd] = useState(limit);

  const onClickPage = (pageNum: number, flag?: string) => {
    let sendOffset;
    switch (flag) {
      case FIRST_PAGE: {
        sendOffset = 0;
        break;
      }
      case PREV_PAGE: {
        sendOffset = (pageNum - 2) * limit;
        break;
      }
      case NEXT_PAGE: {
        sendOffset = pageNum * limit;
        break;
      }
      default: {
        sendOffset = (pageNum - 1) * limit;
        break;
      }
    }

    changePage(sendOffset, limit);
  };

  useEffect(() => {
    setPageStart(1);
    setPageEnd(limit);
  }, [setPageStart, setPageEnd, limit]);

  useEffect(() => {
    if (cardViewMode) {
      if (bottom && !isLoading && !isAdding) {
        setBottom(false);
        addPersonalBinders();
      }

      const onScroll = () => {
        const isBottom =
          window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.offsetHeight;
        setBottom(isBottom);
      };

      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [addPersonalBinders, bottom, cardViewMode, isAdding, isLoading]);

  useEffect(() => {
    // ipログインの場合は、topに遷移する
    if (ipAuthFlg) {
      history.push("/");
    } else {
      fetchPersonalBinders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, ipAuthFlg]);

  useEffect(() => {
    setPageStart(offset + 1);
    if (offset + limit < totalCount) {
      setPageEnd(offset + limit);
    } else {
      setPageEnd(totalCount);
    }
  }, [offset, limit, totalCount]);

  // topに遷移する際、マイコレクションDOMの映り込み防止
  // エラーの場合、何も表示しない
  if (ipAuthFlg || isError) return null;

  if (isLoading) {
    return (
      <div className={styles.component}>
        <div className={styles.loadingWrapper}>
          <Loading isLoading={isLoading} />
        </div>
      </div>
    );
  }

  if (personalKeyword && binderItems.length === 0) {
    return (
      <div className={styles.component}>
        <div className={styles.sortWrapper}>
          <Sort
            onChange={sortPersonalBinder}
            setCardViewMode={switchCardViewMode}
            cardViewMode={cardViewMode}
            changeLimit={changeLimit}
            limit={limit}
            sort={sortValue}
            onKeywordSearch={onKeywordSearch}
            keyword={personalKeyword}
            setKeyword={setPersonalKeyword}
          />
        </div>
        <div
          className={styles.collectionWrapper}
          data-testid="BinderPage-List-Components-PersonalBinderList-collectionWrapper"
        >
          <div
            className={cardViewMode ? styles.card : styles.list}
            ref={tableWrapperRef}
          >
            <SearchEmpty />
          </div>
        </div>
      </div>
    );
  }

  if (binderItems.length === 0) {
    return (
      <div className={styles.component}>
        <Empty name="myBinder" subLabel="マイコレクションを作成してください" />
      </div>
    );
  }

  return (
    <div className={styles.component}>
      <div className={styles.sortWrapper}>
        <Sort
          onChange={sortPersonalBinder}
          setCardViewMode={switchCardViewMode}
          cardViewMode={cardViewMode}
          changeLimit={changeLimit}
          limit={limit}
          sort={sortValue}
          onKeywordSearch={onKeywordSearch}
          keyword={personalKeyword}
          setKeyword={setPersonalKeyword}
        />
      </div>
      {isLoading && sortValue !== "" ? (
        <div className={styles.component}>
          <div className={styles.loadingWrapper}>
            <Loading isLoading={isLoading} />
          </div>
        </div>
      ) : (
        <div
          className={styles.collectionWrapper}
          data-testid="BinderPage-List-Components-PersonalBinderList-collectionWrapper"
        >
          <div
            className={cardViewMode ? styles.card : styles.list}
            ref={tableWrapperRef}
          >
            {cardViewMode ? (
              binderItems.map(binderItem => {
                return (
                  <Card
                    key={binderItem.binderInfo.binderId}
                    type="personal"
                    binderItem={binderItem}
                    setBinderActionModal={setBinderActionModal}
                    fetchBinderList={fetchPersonalBinders}
                  />
                );
              })
            ) : (
              <div>
                <Table
                  items={binderItems}
                  type="personal"
                  setBinderActionModal={setBinderActionModal}
                  tableWrapperRef={tableWrapperRef}
                  fetchBinderList={fetchPersonalBinders}
                />
                <Pager
                  page={offset / limit + 1}
                  perPage={limit}
                  totalNumber={totalCount}
                  onClick={onClickPage}
                  start={pageStart.toString()}
                  end={pageEnd.toString()}
                />
              </div>
            )}
          </div>
          {isAdding && (
            <div className={styles.loadingContainer}>
              <img
                src={LoadingImage}
                alt="読み込み中"
                className={styles.loading}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
