import { actionCreators } from "modules/global";

const actionSuffix = ["PENDING", "FULFILLED", "REJECTED"];
let queue = "";
let pathname = "";
let corpIndustyCode = "";
let menuId = "";

// 画面のスクロール状態をTOPに戻す
const resetScrolling = () => window.scrollTo(0, 0);

export default store => next => action => {
  const searchParams = new URLSearchParams(location.search);
  // ページ初期化 or 企業の変更 or 財務系ページ初期化アクション名と一致したらページ初期化APIを叩くアクション
  const isInit = action.type.match(
    /PAGE_INIT|PAGE_CHANGE|corp\/finance\/PAGE_GET/g
  );
  // urlが変わった時に発行されるアクションのうち、pathnameが変わった or 財務系でmenuIdが変わった場合、ページ遷移を起こすアクション
  const isPageChange =
    action.type.match(/LOCATION_CHANGE/g) &&
    (pathname !== action.payload.pathname ||
      (pathname === "/corp/finance" && menuId !== searchParams.get("menuId")));
  // 表示中の企業・業界が変わった
  const isCorpChange =
    (pathname.startsWith("/corp") || pathname.startsWith("/industry")) &&
    action.type.match(/LOCATION_CHANGE/g) &&
    corpIndustyCode !== searchParams.get("corpIndustyCode");
  // ヘッダー情報の初期化完了
  const headerFulfilled = action.type.match(/HEADER_INIT_FULFILLED/g);

  corpIndustyCode = searchParams.get("corpIndustyCode");
  menuId = searchParams.get("menuId");

  // ページ遷移が起こったらローディング画面表示
  if (isPageChange) {
    store.dispatch(actionCreators.setLoadingStatus(true));
    // 遷移先のpathnameを保存しておく
    pathname = action.payload.pathname;
    queue = "pageChange";
    resetScrolling();
  }

  // 企業・業界変更が起こったらローディング画面表示
  if (isCorpChange) {
    store.dispatch(actionCreators.setLoadingStatus(true));
    queue = "corpChange";
    resetScrolling();
  }

  // ページ初期化が起こったらローディング画面表示
  if (isInit) {
    const [PENDING, FULFILLED, REJECTED] = actionSuffix;
    // promiseMiddlewareのsuffixがFULFILLEDまたはREJECTED
    const isDone = new RegExp(`${FULFILLED}|${REJECTED}`, "g");
    // promiseMiddlewareのsuffixがPENDING
    const isDoing = new RegExp(`${PENDING}`, "g");
    // suffixを取り除いたアクション名を取得
    const suffix = new RegExp(`${PENDING}|${FULFILLED}|${REJECTED}`, "g");
    const actionName = action.type.replace(suffix, "");

    if (action.type.match(isDoing)) {
      // 現在ロード中のアクション名を保存
      queue = actionName;
      store.dispatch(actionCreators.setLoadingStatus(true));
    } else if (action.type.match(isDone)) {
      // 現在ロード中のアクション名と同じアクション名が完了していたらロード完了
      if (queue === actionName) {
        queue = "";
        store.dispatch(actionCreators.setLoadingStatus(false));
      }
    }
    resetScrolling();
  }

  // ページ遷移、企業変更だけが起こったあとヘッダーが初期化されたらロード完了(ページ初期化がアクションないページの場合)
  if (headerFulfilled && (queue === "pageChange" || queue === "corpChange")) {
    store.dispatch(actionCreators.setLoadingStatus(false));
  }

  return next(action);
};
