// 初期表示
export const PAGE_INIT = "corp/overseas/earning-forecast/PAGE_INIT";
export const OPTION_LIST_GET = "corp/overseas/earning-forecast/OPTION_LIST_GET";
export const OPTION_LIST_SET = "corp/overseas/earning-forecast/OPTION_LIST_SET";
// 表示条件変更
export const CONDITION_SET = "corp/overseas/earning-forecast/CONDITION_SET";
export const CONDITION_CHANGE =
  "corp/overseas/earning-forecast/CONDITION_CHANGE";
// モーダル表示時
export const OPEN_MODAL = "corp/overseas/earning-forecast/OPEN_MODAL";
