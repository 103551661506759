/**
 * Atlas config 設定値
 */
export const buildConfig = ({
  url = null,
  referrer = null
}: {
  url?: string | null;
  referrer?: string | null;
} = {}) => ({
  system: {
    endpoint: `atlas-endpoint${IS_ATLAS_STAGING ? "-staging" : ""}.data.n8s.jp`,
    cookieDomain: "valuesearch.nikkei.com",
    beaconTimeout: 2000,
    cookieName: "atlasId",
    cookieMaxAge: 2 * 365 * 24 * 60 * 60
  },
  defaults: {
    pageUrl: url || window.parent.document.location.href,
    pageReferrer: referrer || window.parent.document.referrer,
    pageTitle: window.parent.document.title
  },
  product: {
    productFamily: "ValueSearch",
    productName: ATLAS_PRODUCT_NAME
  },
  options: {
    trackLink: {
      enable: true,
      internalDomains: ["valuesearch.nikkei.com", "nvs.nikkei.co.jp"],
      nameAttribute: "data-atlas-link-name"
    },
    trackDownload: {
      enable: true,
      fileExtensions: [
        "pdf",
        "zip",
        "laz",
        "tar",
        "gz",
        "tgz",
        "docx",
        "xlsx",
        "pptx",
        "doc",
        "xls",
        "ppt"
      ],
      nameAttribute: "data-atlas-link-name"
    },
    trackPerformance: {
      enable: true
    },
    trackScroll: {
      enable: true,
      granularity: 20,
      threshold: 2
    },
    trackInfinityScroll: {
      enable: false,
      step: 600,
      threshold: 2
    },
    trackRead: {
      enable: false,
      target: [],
      granularity: 25,
      milestones: [4, 10, 30, 60, 120]
    },
    trackViewability: {
      enable: false,
      targets: []
    },
    trackMedia: {
      enable: false,
      targets: [],
      step: 10
    },
    trackUnload: {
      enable: true
    },
    trackForm: {
      enable: false,
      targets: []
    },
    observeMutation: false,
    dataSrc: "",
    trackClick: {
      enable: false
    }
  }
});
