import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.scss";
import cn from "classnames";

import { convertPageAndFlag } from "utils/helper/pager";

// ページャ設定
const pagerConf = {
  first: 1, // 最初のページ
  linkCount: 10 // ページ数の数字リンクの最大表示数
};

interface Props {
  perPage: string | number;
  totalNumber: string | number;
  isMini?: boolean;
  start: string | null;
  end: string | null;
  onClick: (currentPage: number, flag: string | undefined) => void;
  page?: string | number;
  uncontrolled?: boolean;
}

/**
 * ページャー
 *
 * @prop {string} totalNumber 総件数
 * @prop {string} perPage 1ページあたり表示件数
 * @prop {?string} start 表示開始位置
 * @prop {?string} end 表示終了位置
 * @prop {function} onClick ページ遷移時の処理。引数に遷移後の表示中になるページ数と件数の初めの数と終わりの数をわたす
 * @prop {?boolean} isMini ミニ版にするか
 */

const Pager: React.FunctionComponent<Props> = ({
  perPage = "",
  start = null,
  end = null,
  onClick = () => {},
  isMini = false,
  totalNumber = "",
  page = null,
  uncontrolled = false
}) => {
  const navRef = useRef<HTMLElement>(null);
  const rightEndRef = useRef<HTMLDivElement>(null);

  const totalPage = Math.ceil(Number(totalNumber) / Number(perPage));
  const [currentPage, setCurrentPage] = useState(
    page ? Number(page) : pagerConf.first
  );
  const [pageLinkStart, setPageLinkStart] = useState(pagerConf.first);
  const [pageLinkEnd, setPageLinkEnd] = useState(
    totalPage < pagerConf.linkCount ? totalPage : pagerConf.linkCount
  );
  const [isMiniState, setIsMiniState] = useState(isMini);
  const [lastRightEnd, setLastRightEnd] = useState(0);

  // アクティブなページから、ページリンクの開始値と終了値を取得する
  const getPageLinks = useCallback(
    (currentPage: number) => {
      let start = currentPage - Math.floor(pagerConf.linkCount / 2);
      let end = currentPage + Math.floor(pagerConf.linkCount / 2);

      if (start < pagerConf.first) {
        start = pagerConf.first;
        end = start + pagerConf.linkCount - 1;
        if (end > totalPage) end = totalPage;
      }

      if (end > totalPage) {
        end = totalPage;
        start = end - pagerConf.linkCount + 1;
        if (start < pagerConf.first) start = pagerConf.first;
      }

      return {
        start: start,
        end: end
      };
    },
    [totalPage]
  );

  // ページリンクの数の配列を作成する
  const createPageNumbers = (start: number, end: number) => {
    const numbers = [];
    for (let i = start; i <= end; i++) {
      numbers.push(i);
    }
    return numbers;
  };

  // 表示ページを変更し、ページャの数字と表示中件数を変更する
  const changecurrentPage = (page: number) => {
    if (page === currentPage || page < pagerConf.first || page > totalPage)
      return;
    // ページリンク
    const pageLinks = getPageLinks(page);
    // 表示件数
    const prevCurrentPage = parseInt(String(currentPage), 10);

    setCurrentPage(page);
    setPageLinkStart(pageLinks.start);
    setPageLinkEnd(pageLinks.end);

    const target = parseInt(String(page), 10);
    const pageAndFlag = convertPageAndFlag(target, prevCurrentPage);

    const flag = pageAndFlag && pageAndFlag.flag;

    onClick(flag ? prevCurrentPage : target, flag);
  };

  const hancleClickPrev = () => {
    changecurrentPage(currentPage - 1);
  };

  const hancleClickNext = () => {
    changecurrentPage(currentPage + 1);
  };

  const hancleClickFirst = () => {
    changecurrentPage(pagerConf.first);
  };

  const pageLinks = createPageNumbers(pageLinkStart, pageLinkEnd);

  const renderPrev = () => {
    const disabled = currentPage === pagerConf.first || currentPage === 0;
    return (
      <React.Fragment>
        <div
          data-testid="first-button"
          className={cn(styles.navigator, styles.first, {
            [styles.disabled]: disabled,
            [styles.mini]: isMiniState
          })}
          onClick={hancleClickFirst}
        >
          <span className={styles.icon} />
          <span>先頭へ</span>
        </div>
        <div
          data-testid="before-button"
          className={`
            ${styles.navigator}
            ${styles.prev}
            ${disabled && styles.disabled}
            ${isMiniState && styles.mini}
          `}
          onClick={hancleClickPrev}
        >
          <span className={styles.icon} />
          <span>{isMiniState ? "前へ" : `前の${perPage}件`}</span>
        </div>
      </React.Fragment>
    );
  };

  const renderNext = () => {
    const disabled = currentPage === totalPage || Number(totalNumber) === 0;
    return (
      <div
        data-testid="next-button"
        className={`
          ${styles.navigator}
          ${styles.next}
          ${disabled && styles.disabled}
          ${isMiniState && styles.mini}
        `}
        onClick={hancleClickNext}
      >
        <span>{isMiniState ? "次へ" : `次の${perPage}件`}</span>
        <span className={styles.icon} />
      </div>
    );
  };

  const toggleMini = () => {
    // ページャーの幅が表示崩れを生む狭さならミニ版にする
    if (navRef.current && navRef.current.clientWidth > 0 && !isMini) {
      const rightEnd =
        rightEndRef.current &&
        rightEndRef.current.getBoundingClientRect().right;
      const navRight = navRef.current.getBoundingClientRect().right;
      const isMini = rightEnd && rightEnd > navRight;
      if (isMiniState === isMini || (!isMini && lastRightEnd > navRight))
        return;

      setIsMiniState(Boolean(isMini));
      setLastRightEnd(Number(rightEnd));
    }
  };

  useEffect(() => {
    toggleMini();
  });

  useEffect(() => {
    if (Number(start) === 1) {
      setCurrentPage(1);
      const pageLinks = getPageLinks(1);
      setPageLinkStart(pageLinks.start);
      setPageLinkEnd(pageLinks.end);
    }
  }, [getPageLinks, start]);

  useEffect(() => {
    if (uncontrolled) {
      setCurrentPage(Number(page));
    }
  }, [page, uncontrolled]);

  return (
    <nav className={styles.component} ref={navRef}>
      {renderPrev()}

      {!isMini && Number(totalNumber) > 0 && (
        <ul className={styles.pages}>
          {pageLinks.map(page => (
            <li key={page}>
              <span
                data-testid="components-button"
                className={`${styles.number} ${currentPage === page &&
                  styles.active}`}
                onClick={() => changecurrentPage(page)}
              >
                {page}
              </span>
            </li>
          ))}
        </ul>
      )}

      {renderNext()}

      {totalNumber === 0 ? (
        <div className={styles.all} ref={rightEndRef}>
          <span data-testid="total_number_zero">{totalNumber}</span>件
        </div>
      ) : (
        <React.Fragment>
          <div className={styles.all}>
            <span data-testid="total_number">{totalNumber}</span>
            件中
          </div>

          <div ref={rightEndRef}>
            <span data-testid="currently-displayed">
              {start}～{Number(end) > Number(totalNumber) ? totalNumber : end}
            </span>
            件表示
          </div>
        </React.Fragment>
      )}
    </nav>
  );
};

export default Pager;
