import { useState } from "react";
import { useReduxState } from "hooks/useReduxState";
import { request } from "utils/apiClient";
import { Response } from "models/api/nidUser/OptionUpdate";
import { useDispatch } from "react-redux";
import { actionCreators as sourceSelectorActionCreators } from "modules/common/SourceSelector";
import { clearLoginOptions } from "modules/auth/temporary/actions";
import { setAuth as setSearchTabAuth } from "modules/auth/searchTab/actions";
import { AuthTabResponse } from "models/Auth";
import { useScreeningTree } from "hooks/useScreeningTree";

export const useAuthOption = () => {
  const dispatch = useDispatch();

  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [error, setError] = useState("");

  const options = useReduxState(state => state.auth.temporary.options);

  const { fetchFilterTreeList, fetchDisplayTree } = useScreeningTree();

  const handleChangeCheck = (checked: boolean, id: string) => {
    setCheckedIds(prev => {
      return checked ? [...prev, id] : prev.filter(value => value !== id);
    });
  };

  const getTabAuth = async () => {
    const authResponse = await request<AuthTabResponse>(
      "/searchlist/tab-authority"
    );
    dispatch(
      setSearchTabAuth({
        corpVisible: authResponse.corp,
        industryVisible: authResponse.industry,
        newsVisible: authResponse.news,
        magazineVisible: authResponse.magazine,
        irVisible: authResponse.ir,
        collectionVisible: authResponse.binder,
        dataVisible: authResponse.disclosure,
        governmentVisible: authResponse.governmentOffice,
        macroVisible: authResponse.macroStat
      })
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await request<Response>("nid-user/option/update", {
      productIds: checkedIds.join(",")
    });

    const error = response.optionInfos.find(item => item.remaining === "0");

    // オプション選択エラー
    if (error) {
      setError(error.message);
      return;
    }

    // オプション設定が終わってから、企業分類のデフォルト値取得
    dispatch(sourceSelectorActionCreators.getSelection());

    // 権限を取得し直す
    getTabAuth();

    // 企業リスト作成の条件一覧を取得（サイズが大きいため事前に取得しておく）
    fetchFilterTreeList({ currency: "1" });

    // 企業リスト作成の表示項目一覧を取得（サイズが大きいため事前に取得しておく）
    fetchDisplayTree();

    dispatch(clearLoginOptions());
  };

  return { checkedIds, options, error, handleChangeCheck, handleSubmit };
};
