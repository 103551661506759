import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  settings: []
};

export default handleActions(
  {
    [`${ActionTypes.INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      settings:
        action.payload.CorpFilterDto.corpFilter_Checkbox_LabelValueDtoList,
      paymentConfirmFlg: action.payload.paymentConfirmFlg,
      userMediaFeeType: action.payload.userMediaFeeType
    }),
    [ActionTypes.UPDATE]: (state, action) => ({
      ...state,
      settings: action.payload
    }),
    [`${ActionTypes.SAVE}_FULFILLED`]: state => ({
      ...state
      // API は保存成功後のデータを返さない
    }),
    [`${ActionTypes.PAYMENT_CONFIRM_FLG_GET}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        paymentConfirmFlg: action.payload.paymentConfirmFlg
      };
    },
    [ActionTypes.PAYMENT_CONFIRM_FLG_UPDATE]: (state, action) => ({
      ...state,
      paymentConfirmFlg: action.payload.paymentConfirmFlg
    }),
    [ActionTypes.CLEAR]: state => ({
      ...state,
      settings: [...initialState.settings]
    })
  },
  initialState
);

export const actionCreators = {
  init: createAction(ActionTypes.INIT, params => request(789, params)),
  update: createAction(ActionTypes.UPDATE),
  save: createAction(ActionTypes.SAVE, params => request(791, params)),
  getPaymentConfirmFlg: createAction(
    ActionTypes.PAYMENT_CONFIRM_FLG_GET,
    params =>
      request(789, {
        corpIndustyKbn: "8",
        ...params
      })
  ),
  paymentConfirmFlgUpdate: createAction(ActionTypes.PAYMENT_CONFIRM_FLG_UPDATE),
  savePaymentConfirmFlg: createAction(
    ActionTypes.PAYMENT_CONFIRM_FLG_SAVE,
    params => request(876, params)
  ),
  clearPersonalSystem: createAction(ActionTypes.CLEAR)
};
