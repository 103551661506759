import React from "react";
import ReactModal from "react-modal";

import styles from "./style.scss";

interface Props {
  isOpen: boolean;
  header?: React.ReactNode;
  children: React.ReactNode;
  onClose: () => void;
}

const Minimum: React.FC<Props> = ({ isOpen, header, children, onClose }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      className={styles.modalSizing}
      overlayClassName={styles.wrapper}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      <div className={styles.closeWrapper}>
        <div className={styles.close} onClick={() => onClose()}>
          <span className={styles.label}>閉じる</span>
          <div className={styles.icon} />
        </div>
      </div>
      <div className={styles.childWrapper}>
        {header && (
          <div
            className={styles.header}
            data-testid="Components-Common-Modal-Minimum-header"
          >
            {header}
          </div>
        )}
        <div
          className={styles.content}
          data-testid="Components-Common-Modal-Minimum-content"
        >
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

export default Minimum;
