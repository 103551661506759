import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  // 設定状況 --------------------
  corps: [], // 企業
  media: [], // 一括ニュース・関連記事設定の項目（メディア名）
  checkedMedia: [], // 一括ニュース・関連記事設定 選択されたメディア
  checkedCompanyCodes: [], // 選択された企業

  // 企業選択 --------------------
  needsMstL: [], // 業界大分類
  needsMstM: [], // 業界中分類
  needsMstS: [], // 業界小分類
  companyList: [], // （業界分類から取得した）企業
  searchResult: null, // 企業名からの検索結果
  isLoadingSearch: false, // 企業名からの検索 ローディング表示flag
  recentList: [], // 最近見た企業
  selectedFolderId: "", // 選択されたブックマークフォルダのID
  bookmarkFolderList: [], // ブックマークフォルダ
  bookmarkList: [] // ブックマーク
};

const corpsByUpdatingAnyItemChecked = (state, data) => {
  const corps = [...state.corps].map(corp => {
    if (
      data.corpCodes.includes(corp.corpCode) &&
      // corp.diffflg === "0" は格付け情報が disabled なので "1" のみを対象とする
      corp.diffflg === "1"
    ) {
      return {
        ...corp,
        ...{ [data.flagName]: data.flag }
      };
    } else {
      return corp;
    }
  });
  return corps;
};

export default handleActions(
  {
    [`${ActionTypes.INIT}_FULFILLED`]: (state, action) => {
      const checkedMedia =
        action.payload.UserAlert01Dto.userAlert01Data[0] &&
        action.payload.UserAlert01Dto.userAlert01Data[0].onceMediumCode
          ? action.payload.UserAlert01Dto.userAlert01Data[0].onceMediumCode.split(
              " "
            )
          : initialState.checkedMedia; // 文字列が空の場合 split は空の配列ではなく **1つの空文字列を含む配列 [""]** を返すので
      return {
        ...state,
        corps: action.payload.UserAlert01Dto.userAlert01Data,
        checkedMedia: checkedMedia
      };
    },
    [`${ActionTypes.MEDIA_SETTINGS_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      media: action.payload
    }),
    [`${ActionTypes.MEDIA_CHECKED_SAVE}_FULFILLED`]: (state, action) => {
      const checkedMedia = action.payload
        ? action.payload.split(" ")
        : initialState.checkedMedia; // 文字列が空の場合 split は空の配列ではなく **1つの空文字列を含む配列 [""]** を返すので
      return {
        ...state,
        checkedMedia: checkedMedia
      };
    },
    [ActionTypes.CHECKED_COMPANY_CODE_UPDATE]: (state, action) => ({
      ...state,
      checkedCompanyCodes: action.payload
    }),
    [ActionTypes.COMPANY_MEDIA_CHECKED_UPDATE]: (state, action) => {
      const newCorps = [...state.corps].map(corp => {
        if (corp.corpCode === action.payload.code) {
          return {
            ...corp,
            ...{ newsArtSendFlg: action.payload.checked }
          };
        } else {
          return corp;
        }
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [ActionTypes.ALL_COMPANY_MEDIA_CHECKED_UPDATE]: (state, action) => {
      const flag = action.payload ? "1" : "0";
      const newCorps = [...state.corps].map(corp => {
        return {
          ...corp,
          ...{ onceNewsArtSendFlg: flag }
        };
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [ActionTypes.PERSONAL_INFO_CHECKED_UPDATE]: (state, action) => {
      const newCorps = [...state.corps].map(corp => {
        if (action.payload.codes.includes(corp.corpCode)) {
          return {
            ...corp,
            ...{ personalInfoFlg: action.payload.flag }
          };
        } else {
          return corp;
        }
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [ActionTypes.STOCK_PRICE_CHECKED_UPDATE]: (state, action) => {
      const newCorps = [...state.corps].map(corp => {
        if (action.payload.codes.includes(corp.corpCode)) {
          return {
            ...corp,
            ...{ stockSendFlg: action.payload.flag }
          };
        } else {
          return corp;
        }
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [ActionTypes.BATCH_STOCK_PRICE_CHECKED_UPDATE]: (state, action) => {
      const newCorps = [...state.corps].map(corp => {
        return {
          ...corp,
          ...{ onceStockSendFlg: action.payload.flag }
        };
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [ActionTypes.RANK_INFO_CHECKED_UPDATE]: (state, action) => {
      const newCorps = corpsByUpdatingAnyItemChecked(state, {
        flagName: "rankInfoFlg",
        flag: action.payload.flag,
        corpCodes: action.payload.codes
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [ActionTypes.TIMELY_DISCLOSURE_CHECKED_UPDATE]: (state, action) => {
      const newCorps = corpsByUpdatingAnyItemChecked(state, {
        flagName: "timelyIndicatFlg",
        flag: action.payload.flag,
        corpCodes: action.payload.codes
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [ActionTypes.LARGE_SHAREHOLDING_REPORT_CHECKED_UPDATE]: (state, action) => {
      const newCorps = corpsByUpdatingAnyItemChecked(state, {
        flagName: "largeAmtPosReportFlg",
        flag: action.payload.flag,
        corpCodes: action.payload.codes
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [`${ActionTypes.COMPANY_ADD}_FULFILLED`]: (state, action) => ({
      ...state,
      corps: action.payload.UserAlert01Dto.userAlert01Data
    }),
    [`${ActionTypes.COMPANY_DELETE}_FULFILLED`]: (state, action) => {
      const corps = action.payload.UserAlert01Dto.userAlert01Data;
      return {
        ...state,
        checkedCompanyCodes: [],
        corps: corps,
        // 全企業削除なら、一括ニュース・関連記事設定は破棄する（チェックボックスも未選択に）
        //（サーバ側できちんと破棄されるが、その再取得が困難なのでクライアント手動で破棄している意図）
        checkedMedia: corps.length === 0 ? [] : state.checkedMedia
      };
    },
    [`${ActionTypes.STOCK_SETTINGS_SAVE}_FULFILLED`]: (state, action) => {
      // 保存成功後の corps は返ってこないので、自前で作って state を更新する
      const newCorps = [...state.corps].map(corp => {
        if (corp.corpCode === action.meta.p1IndustCode) {
          return {
            ...corp,
            ...{
              stockPriceChan: action.meta.p1stockPriceChan,
              stockPriceChanFlg: action.meta.p1stockPriceChanFlg,
              stockPriceVarRate: action.meta.p1stockPriceVarRate,
              stockPriceVarRateFlg: action.meta.p1stockPriceVarRateFlg
            }
          };
        } else {
          return corp;
        }
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [`${ActionTypes.ALL_STOCK_SETTINGS_SAVE}_FULFILLED`]: (state, action) => {
      // 保存成功後の corps は返ってこないので、自前で作って state を更新する
      const newCorps = [...state.corps].map(corp => {
        return {
          ...corp,
          ...{
            onceStockPriceVarRate: action.meta.p2onceStockPriceVarRate,
            onceStockFlg: action.meta.p2onceStockFlg
          }
        };
      });
      return {
        ...state,
        corps: newCorps
      };
    },
    [`${ActionTypes.ALERT_SAVE}_FULFILLED`]: (state, action) => {
      const checkedMedia = initialState.checkedMedia;
      let newCorps = [...state.corps];
      // 更新されたコンテンツをリストに表示する
      newCorps = state.corps.map(corp => {
        const updatedIndex = action.payload.findIndex(updatedCorpInfo => {
          return updatedCorpInfo.corpCode === corp.corpCode;
        });
        if (updatedIndex !== -1) {
          return {
            ...corp,
            ...action.payload[updatedIndex]
          };
        } else {
          return corp;
        }
      });
      // エンタープライズ アラートを現在のリストに追加する
      const addCorps = [];
      for (const addCorp of action.payload) {
        if (!state.corps.some(corp => corp.corpCode === addCorp.corpCode)) {
          addCorps.push(addCorp);
        }
      }

      newCorps = [...newCorps, ...addCorps];

      return {
        ...state,
        corps: newCorps,
        checkedMedia: checkedMedia
      };
    },
    [`${ActionTypes.NEEDS_MST_L_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      needsMstM: [],
      needsMstS: [],
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      companyList: []
    }),
    [`${ActionTypes.COMPANY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      companyList: action.payload.companyList
    }),
    [`${ActionTypes.COMPANY_SEARCH}_PENDING`]: state => ({
      ...state,
      isLoadingSearch: true
    }),
    [`${ActionTypes.COMPANY_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      searchResult: action.payload.companyList,
      isLoadingSearch: false
    }),
    [`${ActionTypes.RECENTLY_BROWSED_COMPANY_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      recentList: action.payload.companyList
    }),
    [`${ActionTypes.BOOKMARK_FOLDER_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      selectedFolderId: action.payload.selectedFolder.folderId,
      bookmarkFolderList: action.payload.folderList
    }),
    [`${ActionTypes.BOOKMARK_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      selectedFolderId: action.meta.selectedFolder,
      bookmarkList: action.payload.companyList
    }),
    [ActionTypes.CORP_SELECTION_CLEAR]: state => ({
      ...state,
      needsMstL: initialState.needsMstL,
      needsMstM: initialState.needsMstM,
      needsMstS: initialState.needsMstS,
      companyList: initialState.companyList,
      searchResult: initialState.searchResult,
      isLoadingSearch: initialState.isLoadingSearch,
      recentList: initialState.recentList,
      selectedFolderId: initialState.selectedFolderId,
      bookmarkFolderList: initialState.bookmarkFolderList,
      bookmarkList: initialState.bookmarkList
    })
  },
  initialState
);

export const actionCreators = {
  init: createAction(ActionTypes.INIT, params => request(764, params)),
  getMediaSettings: createAction(ActionTypes.MEDIA_SETTINGS_GET, params =>
    request(772, params)
  ),
  saveMediaChecked: createAction(ActionTypes.MEDIA_CHECKED_SAVE, params =>
    request(773, params)
  ),
  updateCheckedCompanyCode: createAction(
    ActionTypes.CHECKED_COMPANY_CODE_UPDATE
  ),
  addCompany: createAction(ActionTypes.COMPANY_ADD, params =>
    request(774, params)
  ),
  deleteCompany: createAction(ActionTypes.COMPANY_DELETE, params =>
    request("/user/alert/delete-all", params, "POST")
  ),
  saveStockSettings: createAction(
    ActionTypes.STOCK_SETTINGS_SAVE,
    params => request(770, params.payload),
    params => params.meta
  ),
  saveAllStockSettings: createAction(
    ActionTypes.ALL_STOCK_SETTINGS_SAVE,
    params => request(771, params.payload),
    params => params.meta
  ),
  saveAlert: createAction(ActionTypes.ALERT_SAVE, params =>
    request("/user/alert/update", params, "POST")
  ),
  getNeedsMstL: createAction(ActionTypes.NEEDS_MST_L_GET, params =>
    request(766, params)
  ),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(767, params)
  ),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(768, params)
  ),
  getCompany: createAction(ActionTypes.COMPANY_GET, params =>
    request(769, params)
  ),
  searchCompany: createAction(ActionTypes.COMPANY_SEARCH, params =>
    request(775, params)
  ),
  getRecentlyBrowsedCompanies: createAction(
    ActionTypes.RECENTLY_BROWSED_COMPANY_GET,
    params => request(776, params)
  ),
  getBookmarkFolder: createAction(ActionTypes.BOOKMARK_FOLDER_GET, params =>
    request(18, params)
  ),
  getBookmark: createAction(
    ActionTypes.BOOKMARK_GET,
    params => request(777, params),
    params => params
  ),
  clearCorpSelection: createAction(ActionTypes.CORP_SELECTION_CLEAR)
};
