import {
  CorpCount,
  FilterCondition,
  FilterConditionListItemResponse,
  INNEY_CODE,
  SourceItem
} from "models/CorpScreening";
import { isEmpty } from "utils/helper/common";
import { tabId, userSetKbn } from "utils/helper/params";
import { extractNumberFromVariables } from "utils/helper/format";
import variable from "utils/css/_variables.scss";
import jump from "jump.js";
import { isIE } from "utils/helper/common";

const FREE_WORD_KEY = "BULK00001";
const FREE_WORD_INPUT_ID = "57";

export const HAS_ADDITIONAL_INFO_ID = ["SCR000158"];

const splitValue = (filters: FilterCondition[], targetValue: string[]) => {
  return filters.flatMap(item => {
    if (targetValue.includes(item.inputAreaId)) {
      const industryValue = Array.isArray(item.value)
        ? item.value
        : item.value.split(",");

      return industryValue.map(v => ({
        ...item,
        value: v
      }));
    }

    return item;
  });
};

// 絞り込み条件のリクエストパラメータ作成
export const getFilterCondition = (
  filters: FilterCondition[],
  freeWordCondition: {
    text: string;
    target: string[];
  }
) => {
  const spr1 = String.fromCharCode(28); // 条件毎のセパレータ
  const spr2 = String.fromCharCode(29); // 項目毎のセパレータ

  // NEEDS業種分類は設定された値の個数分、切り分ける
  // { inputAreaId: 2, value: ['000', '111'] } -> [{inputAreaId: 2, value: '000'}, {inputAreaId: 2, value: '111'}]
  let tmpFilter: {
    id: string;
    value: string | string[];
  }[] = splitValue(filters, ["2", "8"]);

  // フリーワードがある場合はフリーワードを絞り込み条件に追加
  if (!isEmpty(freeWordCondition.text)) {
    tmpFilter = [
      {
        id: FREE_WORD_KEY,
        value: [
          `{\"and\":\"${freeWordCondition.text}\"}`,
          freeWordCondition.target.join("#")
        ]
      },
      ...tmpFilter
    ];
  }

  return tmpFilter
    .map(
      item =>
        `${item.id}${spr2}${
          Array.isArray(item.value)
            ? `${!isEmpty(item.value[0]) ? item.value[0] : " "}${spr2}${
                !isEmpty(item.value[1]) ? item.value[1] : " "
              }`
            : !isEmpty(item.value)
            ? item.value
            : " "
        }`
    )
    .join(spr1);
};

const getChecked = (sourceItems: SourceItem[], code: string) => {
  const item = sourceItems.find(item => item.inneyCode === code);

  if (!item) return false;

  return item.selected;
};

// 企業種別コンバート
export const getSourceItemChecked = (sourceItems: SourceItem[]) => {
  return {
    listed: getChecked(sourceItems, INNEY_CODE.listed),
    holder: getChecked(sourceItems, INNEY_CODE.holder),
    nikkei: getChecked(sourceItems, INNEY_CODE.unlistedCorp),
    tdb: getChecked(sourceItems, INNEY_CODE.tdbCorp),
    overseas: getChecked(sourceItems, INNEY_CODE.overseasCorp),
    overseasUnlisted: getChecked(sourceItems, INNEY_CODE.unlistedOverseasCorp),
    northAmerica:
      getChecked(sourceItems, INNEY_CODE.listedNorthAmerica) ||
      getChecked(sourceItems, INNEY_CODE.unlistedNorthAmerica),
    listedNorthAmerica: getChecked(sourceItems, INNEY_CODE.listedNorthAmerica),
    unlistedNorthAmerica: getChecked(
      sourceItems,
      INNEY_CODE.unlistedNorthAmerica
    ),
    southAmerica:
      getChecked(sourceItems, INNEY_CODE.listedSouthAmerica) ||
      getChecked(sourceItems, INNEY_CODE.unlistedSouthAmerica),
    listedSouthAmerica: getChecked(sourceItems, INNEY_CODE.listedSouthAmerica),
    unlistedSouthAmerica: getChecked(
      sourceItems,
      INNEY_CODE.unlistedSouthAmerica
    ),
    europe:
      getChecked(sourceItems, INNEY_CODE.listedEurope) ||
      getChecked(sourceItems, INNEY_CODE.unlistedEurope),
    listedEurope: getChecked(sourceItems, INNEY_CODE.listedEurope),
    unlistedEurope: getChecked(sourceItems, INNEY_CODE.unlistedEurope),
    asia:
      getChecked(sourceItems, INNEY_CODE.listedAsia) ||
      getChecked(sourceItems, INNEY_CODE.unlistedAsia),
    listedAsia: getChecked(sourceItems, INNEY_CODE.listedAsia),
    unlistedAsia: getChecked(sourceItems, INNEY_CODE.unlistedAsia),
    mideast:
      getChecked(sourceItems, INNEY_CODE.listedMideast) ||
      getChecked(sourceItems, INNEY_CODE.unlistedMideast),
    listedMideast: getChecked(sourceItems, INNEY_CODE.listedMideast),
    unlistedMideast: getChecked(sourceItems, INNEY_CODE.unlistedMideast),
    africa:
      getChecked(sourceItems, INNEY_CODE.listedAfrica) ||
      getChecked(sourceItems, INNEY_CODE.unlistedAfrica),
    listedAfrica: getChecked(sourceItems, INNEY_CODE.listedAfrica),
    unlistedAfrica: getChecked(sourceItems, INNEY_CODE.unlistedAfrica)
  };
};

// 結果APIコンバート
export const convertResultResponse = (response: {
  tableData: {
    datas: {
      link: string;
      text: string;
      textAlign: string;
      value: string;
      highlight: string[];
      highlightText: string[];
    }[][];
    headers: string[][];
  };
  currentPage: string;
  currentNkCodeList: string;
  sanpuzuCapableScreeningItemList: any[];
  groupItemDupulicateFlg: "0" | "1";
  listingNkcodeTotalCount: string;
  unListedNkcodeTotalCount: string;
  unListedHolderNkcodeTotalCount: string;
  unListedTdbNkcodeTotalCount: string;
  africaNkcodeTotalCount: string;
  asiaPacificNkcodeTotalCount: string;
  europeNkcodeTotalCount: string;
  northAmericaNkcodeTotalCount: string;
  southAmericaNkcodeTotalCount: string;
  mideastNkcodeTotalCount: string;
  unlistedAfricaNkcodeTotalCount: string;
  unlistedAsiaPacificNkcodeTotalCount: string;
  unlistedEuropeNkcodeTotalCount: string;
  unlistedNorthAmericaNkcodeTotalCount: string;
  unlistedSouthAmericaNkcodeTotalCount: string;
  unlistedMideastNkcodeTotalCount: string;
  totalCount: string;
  maxDisplayableNkcodeCount: string;
  nkcodeCountForShow: string;
  listingCorpSelect: boolean;
  unlistedHolderCorpSelect: boolean;
  unlistedCorpSelect: boolean;
  unlistedTdbCorpSelect: boolean;
  northAmericaSelect: boolean;
  southAmericaSelect: boolean;
  europeSelect: boolean;
  asiaSelect: boolean;
  mideastSelect: boolean;
  africaSelect: boolean;
  unlistedNorthAmericaSelect: boolean;
  unlistedSouthAmericaSelect: boolean;
  unlistedEuropeSelect: boolean;
  unlistedAsiaSelect: boolean;
  unlistedMideastSelect: boolean;
  unlistedAfricaSelect: boolean;
}) => {
  return {
    data: response.tableData.datas,
    headers: response.tableData.headers,
    currentPage: response.currentPage,
    currentNkCodeList: response.currentNkCodeList,
    sanpuzuCapableScreeningItemList: response.sanpuzuCapableScreeningItemList,
    groupItemDupulicateFlg: Boolean(Number(response.groupItemDupulicateFlg)),
    listingNkcodeTotalCount: response.listingNkcodeTotalCount,
    unListedNkcodeTotalCount: response.unListedNkcodeTotalCount,
    unListedHolderNkcodeTotalCount: response.unListedHolderNkcodeTotalCount,
    unListedTdbNkcodeTotalCount: response.unListedTdbNkcodeTotalCount,
    africaNkcodeTotalCount: response.africaNkcodeTotalCount,
    asiaPacificNkcodeTotalCount: response.asiaPacificNkcodeTotalCount,
    europeNkcodeTotalCount: response.europeNkcodeTotalCount,
    northAmericaNkcodeTotalCount: response.northAmericaNkcodeTotalCount,
    southAmericaNkcodeTotalCount: response.southAmericaNkcodeTotalCount,
    mideastNkcodeTotalCount: response.mideastNkcodeTotalCount,
    unlistedAfricaNkcodeTotalCount: response.unlistedAfricaNkcodeTotalCount,
    unlistedAsiaPacificNkcodeTotalCount:
      response.unlistedAsiaPacificNkcodeTotalCount,
    unlistedEuropeNkcodeTotalCount: response.unlistedEuropeNkcodeTotalCount,
    unlistedNorthAmericaNkcodeTotalCount:
      response.unlistedNorthAmericaNkcodeTotalCount,
    unlistedSouthAmericaNkcodeTotalCount:
      response.unlistedSouthAmericaNkcodeTotalCount,
    unlistedMideastNkcodeTotalCount: response.unlistedMideastNkcodeTotalCount,
    totalCount: Number(response.totalCount),
    maxDisplayableNkcodeCount: Number(response.maxDisplayableNkcodeCount),
    nkcodeCountForShow: Number(response.nkcodeCountForShow),
    listingCorpSelect: response.listingCorpSelect,
    unlistedHolderCorpSelect: response.unlistedHolderCorpSelect,
    unlistedCorpSelect: response.unlistedCorpSelect,
    unlistedTdbCorpSelect: response.unlistedTdbCorpSelect,
    northAmericaSelect: response.northAmericaSelect,
    southAmericaSelect: response.southAmericaSelect,
    europeSelect: response.europeSelect,
    asiaSelect: response.asiaSelect,
    mideastSelect: response.mideastSelect,
    africaSelect: response.africaSelect,
    unlistedNorthAmericaSelect: response.unlistedNorthAmericaSelect,
    unlistedSouthAmericaSelect: response.unlistedSouthAmericaSelect,
    unlistedEuropeSelect: response.unlistedEuropeSelect,
    unlistedAsiaSelect: response.unlistedAsiaSelect,
    unlistedMideastSelect: response.unlistedMideastSelect,
    unlistedAfricaSelect: response.unlistedAfricaSelect
  };
};

const joinTargetValues = (target: FilterCondition[], id: string) => {
  const joinTarget = target.filter(item => item.inputAreaId === id);

  if (!joinTarget.length) return target;

  return [
    ...target.filter(item => item.inputAreaId !== id),
    {
      ...joinTarget[0],
      value: joinTarget.map(item =>
        Array.isArray(item.value) ? item.value.join(",") : item.value
      ),
      needsName: joinTarget.map(item => item.needsName).join(",")
    }
  ];
};

export const convertFilterConditionListResponse = (
  items: FilterConditionListItemResponse[]
) => {
  let tmpItems: FilterCondition[] = items.map(item => ({
    id: item.screeningId,
    label: item.hierarchyName,
    value: !isEmpty(item.value2) ? [item.value1, item.value2] : item.value1,
    inputAreaId: item.inputAreaId,
    inputRuleMessage: item.inputRuleMessage,
    list: !isEmpty(item.sbContents1) ? item.sbContents1 : undefined,
    list2: !isEmpty(item.sbContents2) ? item.sbContents2 : undefined,
    hierarchyNames: item.hierarchyNames,
    selectTreeItem: item.selectTreeItem,
    needsName: item.needsName,
    groupItemFlg: item.groupItemFlg
  }));

  // NEEDS業種分類の結合
  tmpItems = joinTargetValues(tmpItems, "2");

  // FactSet業種分類の結合
  tmpItems = joinTargetValues(tmpItems, "8");

  return tmpItems.filter(item => item.inputAreaId !== FREE_WORD_INPUT_ID);
};

export const convertCommonSearchRequestParameter = ({
  currentNkCodeList,
  dispItemList,
  expect,
  ltm,
  needsListStr,
  nkCodeListTotalList,
  screeningIdListStr,
  accKbn,
  currency,
  filter,
  freeWord,
  scCondition,
  ytCondition,
  sourceItems,
  init,
  toolsearchKeyword,
  ...corpCount
}: Partial<CorpCount> & {
  currentNkCodeList: string;
  dispItemList: string;
  expect: boolean;
  ltm: boolean;
  needsListStr: string;
  nkCodeListTotalList: string;
  screeningIdListStr: string;
  accKbn: string;
  currency: string;
  filter: FilterCondition[];
  freeWord: {
    text: string;
    target: string[];
  };
  scCondition: string;
  ytCondition: string;
  sourceItems: SourceItem[];
  init: boolean;
  toolsearchKeyword?: string;
}) => {
  const checked = getSourceItemChecked(sourceItems);

  return {
    corpIndustyKbn: "7",
    currentNkCodeList,
    dispItemList: dispItemList,
    init,
    expectCheck: String(Number(expect)),
    ltmCheck: String(Number(ltm)),
    needs: needsListStr,
    nkCodeListTotal: nkCodeListTotalList,
    originalNkCodeList: nkCodeListTotalList,
    screeningId: screeningIdListStr,
    tabId: tabId.screeningCorpItem,
    toolsearchAccCondition: accKbn,
    toolsearchCurrencyCondition: currency,
    toolsearchKeyword:
      toolsearchKeyword || getFilterCondition(filter, freeWord),
    toolsearchScCondition: scCondition,
    toolsearchYtCondition: ytCondition,
    userSetKbn: userSetKbn.screening,
    listingCorpSelect: String(
      Number(checked.listed || Number(corpCount.listingNkcodeCount) > 0)
    ),
    unlistedHolderCorpSelect: String(
      Number(checked.holder || Number(corpCount.unListedHolderNkcodeCount) > 0)
    ),
    unlistedCorpSelect: String(
      Number(checked.nikkei || Number(corpCount.unListedNkcodeCount) > 0)
    ),
    africaSelect: String(
      Number(checked.africa || Number(corpCount.africaNkcodeCount) > 0)
    ),
    asiaSelect: String(
      Number(checked.asia || Number(corpCount.asiaPacificNkcodeCount) > 0)
    ),
    europeSelect: String(
      Number(checked.europe || Number(corpCount.europeNkcodeCount) > 0)
    ),
    mideastSelect: String(
      Number(checked.mideast || Number(corpCount.mideastNkcodeCount) > 0)
    ),
    northAmericaSelect: String(
      Number(
        checked.northAmerica || Number(corpCount.northAmericaNkcodeCount) > 0
      )
    ),
    southAmericaSelect: String(
      Number(
        checked.southAmerica || Number(corpCount.southAmericaNkcodeCount) > 0
      )
    ),
    unlistedAfricaSelect: String(
      Number(
        checked.unlistedAfrica ||
          Number(corpCount.unlistedAfricaNkcodeCount) > 0
      )
    ),
    unlistedAsiaSelect: String(
      Number(
        checked.unlistedAsia ||
          Number(corpCount.unlistedAsiaPacificNkcodeCount) > 0
      )
    ),
    unlistedEuropeSelect: String(
      Number(
        checked.unlistedEurope ||
          Number(corpCount.unlistedEuropeNkcodeCount) > 0
      )
    ),
    unlistedMideastSelect: String(
      Number(
        checked.unlistedMideast ||
          Number(corpCount.unlistedMideastNkcodeCount) > 0
      )
    ),
    unlistedNorthAmericaSelect: String(
      Number(
        checked.unlistedNorthAmerica ||
          Number(corpCount.unlistedNorthAmericaNkcodeCount) > 0
      )
    ),
    unlistedSouthAmericaSelect: String(
      Number(
        checked.southAmerica ||
          Number(corpCount.unlistedSouthAmericaNkcodeCount) > 0
      )
    ),
    unlistedTdbCorpSelect: String(
      Number(checked.tdb || Number(corpCount.unListedTdbNkcodeCount) > 0)
    ),
    count: "500"
  };
};

export const getActive = (sourceItems: SourceItem[], code: string) => {
  const item = sourceItems.find(item => item.inneyCode === code);

  if (!item) return false;

  return item.active;
};

export const convertKatakana = (str: string) => {
  const kanaMap: { [key: string]: string } = {
    ｶﾞ: "ガ",
    ｷﾞ: "ギ",
    ｸﾞ: "グ",
    ｹﾞ: "ゲ",
    ｺﾞ: "ゴ",
    ｻﾞ: "ザ",
    ｼﾞ: "ジ",
    ｽﾞ: "ズ",
    ｾﾞ: "ゼ",
    ｿﾞ: "ゾ",
    ﾀﾞ: "ダ",
    ﾁﾞ: "ヂ",
    ﾂﾞ: "ヅ",
    ﾃﾞ: "デ",
    ﾄﾞ: "ド",
    ﾊﾞ: "バ",
    ﾋﾞ: "ビ",
    ﾌﾞ: "ブ",
    ﾍﾞ: "ベ",
    ﾎﾞ: "ボ",
    ﾊﾟ: "パ",
    ﾋﾟ: "ピ",
    ﾌﾟ: "プ",
    ﾍﾟ: "ペ",
    ﾎﾟ: "ポ",
    ｳﾞ: "ヴ",
    ﾜﾞ: "ヷ",
    ｦﾞ: "ヺ",
    ｱ: "ア",
    ｲ: "イ",
    ｳ: "ウ",
    ｴ: "エ",
    ｵ: "オ",
    ｶ: "カ",
    ｷ: "キ",
    ｸ: "ク",
    ｹ: "ケ",
    ｺ: "コ",
    ｻ: "サ",
    ｼ: "シ",
    ｽ: "ス",
    ｾ: "セ",
    ｿ: "ソ",
    ﾀ: "タ",
    ﾁ: "チ",
    ﾂ: "ツ",
    ﾃ: "テ",
    ﾄ: "ト",
    ﾅ: "ナ",
    ﾆ: "ニ",
    ﾇ: "ヌ",
    ﾈ: "ネ",
    ﾉ: "ノ",
    ﾊ: "ハ",
    ﾋ: "ヒ",
    ﾌ: "フ",
    ﾍ: "ヘ",
    ﾎ: "ホ",
    ﾏ: "マ",
    ﾐ: "ミ",
    ﾑ: "ム",
    ﾒ: "メ",
    ﾓ: "モ",
    ﾔ: "ヤ",
    ﾕ: "ユ",
    ﾖ: "ヨ",
    ﾗ: "ラ",
    ﾘ: "リ",
    ﾙ: "ル",
    ﾚ: "レ",
    ﾛ: "ロ",
    ﾜ: "ワ",
    ｦ: "ヲ",
    ﾝ: "ン",
    ｧ: "ァ",
    ｨ: "ィ",
    ｩ: "ゥ",
    ｪ: "ェ",
    ｫ: "ォ",
    ｯ: "ッ",
    ｬ: "ャ",
    ｭ: "ュ",
    ｮ: "ョ",
    "｡": "。",
    "､": "、",
    ｰ: "ー",
    "｢": "「",
    "｣": "」",
    "･": "・"
  };

  const reg = new RegExp("(" + Object.keys(kanaMap).join("|") + ")", "g");

  return str
    .replace(reg, function(match) {
      return kanaMap[match];
    })
    .replace(/ﾞ/g, "゛")
    .replace(/ﾟ/g, "゜");
};

export const toHalfWidth = (str: string) => {
  // 半角変換
  const halfVal = str.replace(/[！-～]/g, tmpStr => {
    // 文字コードをシフト
    return String.fromCharCode(tmpStr.charCodeAt(0) - 0xfee0);
  });

  // 文字コードシフトで対応できない文字の変換
  return halfVal
    .replace(/”/g, '"')
    .replace(/’/g, "'")
    .replace(/‘/g, "`")
    .replace(/￥/g, "\\")
    .replace(/　/g, " ")
    .replace(/〜/g, "~");
};

export const scroll = (id: string, behavior: any) => {
  const element = document.getElementById(id);
  if (element == null) return;

  // ヘッダーの高さ
  const headerHeight = extractNumberFromVariables(variable.lHeaderHeight);

  // IE は jump.js を使う
  if (isIE()) {
    const duration = behavior !== "smooth" ? 0 : 500;

    jump(`#${id}`, {
      duration,
      offset: -headerHeight
    });
    return;
  }

  const rect = element.getBoundingClientRect();
  window.scrollTo({
    left: window.pageXOffset,
    top: rect.top + window.pageYOffset - Number(headerHeight),
    behavior: behavior
  });
};

export const scrollTable = (behavior = "smooth") => {
  scroll("vsComparisonTable", behavior);
};

export const scrollChart = (behavior = "smooth") => {
  scroll("vsComparisonChart", behavior);
};
