import React, { useCallback, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useReduxState } from "hooks/useReduxState";
import { actionCreators } from "modules/download/actions";
import { setSessionState } from "modules/auth/temporary/actions";
import { setRedirectPath } from "modules/auth/saved/actions";
import { useToast } from "hooks/useToast";

interface LocationState {
  reserveErrors: string[];
}

export const useDownloadContext = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const toast = useToast();

  const download = useReduxState(state => state.download);

  const startDownload = (resourceId: any) => {
    toast.clearErrors();
    dispatch(actionCreators.startDownload(resourceId));
  };

  const stopDownload = useCallback(
    () => dispatch(actionCreators.stopDownload()),
    [dispatch]
  );

  const needAuthenticate = () => {
    const redirectPath = location.pathname + location.search + location.hash;
    dispatch(setSessionState("inactive"));
    dispatch(setRedirectPath(redirectPath));
  };

  return {
    download,
    startDownload,
    stopDownload,
    needAuthenticate
  };
};

export const DownloadContextProvider: React.FunctionComponent = props => {
  const {
    download,
    startDownload,
    stopDownload,
    needAuthenticate
  } = useDownloadContext();

  useEffect(() => {
    // downloadResourceId をクリア
    stopDownload();
  }, [stopDownload]);

  return (
    <DownloadContext.Provider
      value={{
        downloadResourceId: download.downloadResourceId,
        startDownload,
        stopDownload,
        needAuthenticate
      }}
    >
      {props.children}
    </DownloadContext.Provider>
  );
};

export interface DownloadContextProperties {
  startDownload: (id: string) => void;
  stopDownload: () => void;
  downloadResourceId: string;
  needAuthenticate: () => void;
}

const DownloadContext = React.createContext<DownloadContextProperties>({
  startDownload: () => {},
  stopDownload: () => {},
  downloadResourceId: "",
  needAuthenticate: () => {}
});

export default DownloadContext;
