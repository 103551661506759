// ホーム初期表示
export const HEADER_SETTING_GET = "header/HEADER_SETTING_GET";
export const BINDER_LIST_GET = "header/BINDER_LIST_GET";
export const LAST_VIEW_GET = "header/LAST_VIEW_GET";

// 更新要求
export const REQUEST_UPDATE = "header/REQUEST_UPDATE";

export const FILTER_UPDATE = "header/FILTER_UPDATE";

export const FILTER_CLEAR = "header/FILTER_CLEAR";

// 一括検索キーワード
export const SEARCH_KEYWORD_TAGS_UPDATE = "search/SEARCH_KEYWORD_TAGS_UPDATE";
export const SEARCH_KEYWORD_TAGS_INIT = "search/SEARCH_KEYWORD_TAGS_INIT";
// 関連キーワード
export const GET_ASSOCIATED_WORDS = "search/ASSOCIATED_WORDS";
