import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  error: {},
  expectationTypeList: [], // 予想区分セレクトボックス項目
  accKbnList: [], // 決算区分セレクトボックス項目
  accKindList: [], // 決算種別セレクトボックス項目
  itemTypeList: [], // 表示項目セレクトボックス項目
  expTypeList: [], // 達成率に利用する予想セレクトボックス項目
  expectationFlg: "", // 経営計画
  expectationType: "",
  accKbn: "",
  accKind: "",
  itemType: "",
  expType: ""
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      expectationType: action.payload.expectationType,
      accKbn: action.payload.accKbn,
      accKind: action.payload.accKind,
      itemType: action.payload.itemType,
      expType: action.payload.expType
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.CONDITION_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.OPTIONS_LOAD}_FULFILLED`]: (state, action) => ({
      ...state,
      [`${action.meta.key}List`]: action.payload
    }),
    [ActionTypes.SELECTED_OPTIONS_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(28, params)),
  changeCondition: createAction(ActionTypes.CONDITION_CHANGE, params =>
    request(29, params)
  ),
  loadOptions: createAction(
    ActionTypes.OPTIONS_LOAD,
    params => request(822, params),
    (params, name) => ({
      key: name, // セレクトボックスの名前
      params: params
    })
  ),
  setSelectedOptions: createAction(
    ActionTypes.SELECTED_OPTIONS_SET,
    params => params,
    (params, name) => ({
      key: name, // セレクトボックスの名前
      params: params
    })
  )
};
