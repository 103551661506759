exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh {\n  max-width: 55rem;\n  margin: 3px auto;\n  white-space: pre-wrap;\n}\n\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table {\n  width: 100%;\n  border-collapse: collapse;\n  border-top: 4px solid #eaf1f5;\n  border-bottom: 4px solid #eaf1f5;\n}\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table th,\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table td {\n  text-align: left;\n}\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table tbody > tr th {\n  padding: 16px;\n  border: none;\n  font-weight: normal;\n}\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table tbody > tr th:first-child {\n  box-sizing: border-box;\n  width: 160px;\n}\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table tbody > tr td {\n  padding: 16px;\n  border: none;\n  border-left: 2px solid #fff;\n  font-weight: normal;\n}\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table tbody > tr td:first-child {\n  box-sizing: border-box;\n  width: 160px;\n}\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table tbody > tr:nth-child(odd) th {\n  background-color: #eaf1f5;\n}\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table tbody > tr:nth-child(even) th:first-child {\n  background-color: #dee8ef;\n}\n.src-components-Common-Popup-MaStrategy-style__body--2jiJh table tbody > tr:nth-child(even) td {\n  background-color: #eaf1f5;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"body": "src-components-Common-Popup-MaStrategy-style__body--2jiJh"
};