import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.UPDATE_GRAPH}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(280, params)),
  updateGraph: createAction(ActionTypes.UPDATE_GRAPH, params =>
    request(281, params)
  )
};
