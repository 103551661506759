import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  sortOptions: [],
  newsOptions: [],
  newsPaperOptions: []
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.LOAD_NEWS}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        corpNewsDto: {
          ...state.data.corpNewsDto,
          nikkeiCurPage: action.payload.corpNewsDto.nikkeiCurPage,
          nikkeiEndNo: action.payload.corpNewsDto.nikkeiEndNo,
          nikkeiEndPage: action.payload.corpNewsDto.nikkeiEndPage,
          nikkeiNewsList: action.payload.corpNewsDto.nikkeiNewsList,
          nikkeiStartNo: action.payload.corpNewsDto.nikkeiStartNo,
          nikkeiStartPage: action.payload.corpNewsDto.nikkeiStartPage,
          nikkeiTotalCnt: action.payload.corpNewsDto.nikkeiTotalCnt,
          nikkeiTrId: action.payload.corpNewsDto.nikkeiTrId
        }
      }
    }),
    [`${ActionTypes.SORT_NEWS}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.SEARCH_NEWS}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.LOAD_NEWS_PAPER}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        corpNewsDto: {
          ...state.data.corpNewsDto,
          articleCurPage: action.payload.corpNewsDto.articleCurPage,
          articleEndNo: action.payload.corpNewsDto.articleEndNo,
          articleEndPage: action.payload.corpNewsDto.articleEndPage,
          articleList: action.payload.corpNewsDto.articleList,
          articleStartNo: action.payload.corpNewsDto.articleStartNo,
          articleStartPage: action.payload.corpNewsDto.articleStartPage,
          articleTotalCnt: action.payload.corpNewsDto.articleTotalCnt,
          articleTrId: action.payload.corpNewsDto.articleTrId
        }
      }
    }),
    [`${ActionTypes.LOAD_NEWS_MAGAZINE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        corpNewsDto: {
          ...state.data.corpNewsDto,
          magazineArticlesCurPage:
            action.payload.corpNewsDto.magazineArticlesCurPage,
          magazineArticlesEndNo:
            action.payload.corpNewsDto.magazineArticlesEndNo,
          magazineArticlesEndPage:
            action.payload.corpNewsDto.magazineArticlesEndPage,
          magazineArticlesList: action.payload.corpNewsDto.magazineArticlesList,
          magazineArticlesStartNo:
            action.payload.corpNewsDto.magazineArticlesStartNo,
          magazineArticlesStartPage:
            action.payload.corpNewsDto.magazineArticlesStartPage,
          magazineArticlesTotalCnt:
            action.payload.corpNewsDto.magazineArticlesTotalCnt,
          magazineArticlesTrId: action.payload.corpNewsDto.magazineArticlesTrId
        }
      }
    }),
    [`${ActionTypes.LOAD_NIKKEI_PRIME}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        corpNewsDto: {
          ...state.data.corpNewsDto,
          nikkeiPrimeArticlesCurPage:
            action.payload.corpNewsDto.nikkeiPrimeArticlesCurPage,
          nikkeiPrimeArticlesEndNo:
            action.payload.corpNewsDto.nikkeiPrimeArticlesEndNo,
          nikkeiPrimeArticlesEndPage:
            action.payload.corpNewsDto.nikkeiPrimeArticlesEndPage,
          nikkeiPrimeArticlesList:
            action.payload.corpNewsDto.nikkeiPrimeArticlesList,
          nikkeiPrimeArticlesStartNo:
            action.payload.corpNewsDto.nikkeiPrimeArticlesStartNo,
          nikkeiPrimeArticlesStartPage:
            action.payload.corpNewsDto.nikkeiPrimeArticlesStartPage,
          nikkeiPrimeArticlesTotalCnt:
            action.payload.corpNewsDto.nikkeiPrimeArticlesTotalCnt,
          nikkeiPrimeArticlesTrId:
            action.payload.corpNewsDto.nikkeiPrimeArticlesTrId
        }
      }
    }),
    [ActionTypes.SORT_OPTIONS_SET]: (state, action) => ({
      ...state,
      sortOptions: action.payload
    }),
    [ActionTypes.NEWS_OPTIONS_SET]: (state, action) => ({
      ...state,
      newsOptions: action.payload
    }),
    [ActionTypes.NEWS_PAPER_OPTIONS_SET]: (state, action) => ({
      ...state,
      newsPaperOptions: action.payload
    }),
    [ActionTypes.NEWS_MAGAZINE_OPTIONS_SET]: (state, action) => ({
      ...state,
      newsMagazineOptions: action.payload
    }),
    [ActionTypes.NIKKEI_PRIME_OPTIONS_SET]: (state, action) => ({
      ...state,
      nikkeiPrimeOptions: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(178, params)),
  loadNews: createAction(
    ActionTypes.LOAD_NEWS,
    params => request(181, params) // ニュースページング
  ),
  sortNews: createAction(
    ActionTypes.SORT_NEWS,
    params => request(180, params) // ニュース・新聞ソート
  ),
  searchNews: createAction(
    ActionTypes.SEARCH_NEWS,
    params => request(179, params) // ニュース・新聞絞り込み
  ),
  loadNewsPaper: createAction(
    ActionTypes.LOAD_NEWS_PAPER,
    params => request(182, params) // 新聞ページング
  ),
  loadNewsMagazine: createAction(
    ActionTypes.LOAD_NEWS_MAGAZINE,
    params => request(872, params) // 雑誌
  ),
  loadNikkeiPrime: createAction(
    ActionTypes.LOAD_NIKKEI_PRIME,
    params => request(886, params) // NikkeiPrime
  ),
  setSortOptions: createAction(ActionTypes.SORT_OPTIONS_SET),
  setNewsOptions: createAction(ActionTypes.NEWS_OPTIONS_SET),
  setNewsPaperOptions: createAction(ActionTypes.NEWS_PAPER_OPTIONS_SET),
  setNewsMagazineOptions: createAction(ActionTypes.NEWS_MAGAZINE_OPTIONS_SET),
  setNikkeiPrimeOptions: createAction(ActionTypes.NIKKEI_PRIME_OPTIONS_SET)
};
