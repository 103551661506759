import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";

const pptContents = [
  {
    label: "<表紙・目次>",
    value: "10",
    checked: true,
    disabled: false,
    children: [
      {
        label: "表紙",
        value: "1",
        checked: true,
        key: "coverCheckBox",
        disabled: false
      },
      {
        label: "日付",
        value: "4",
        checked: true,
        key: "dateCheckBox",
        disabled: false
      },
      {
        label: "目次",
        value: "2",
        checked: true,
        key: "contentCheckBox",
        disabled: false
      },
      {
        label: "中表紙",
        value: "3",
        checked: true,
        key: "centerCoverCheckBox",
        disabled: false
      }
    ],
    key: "cover"
  },
  {
    label: "<コンテンツ>",
    value: "11",
    checked: true,
    disabled: false,
    children: [
      {
        label: "基本情報",
        value: "5",
        checked: true,
        disabled: false,
        key: "basicInfoCheckBox"
      },
      {
        label: "主要指標の推移",
        value: "6",
        checked: true,
        disabled: false,
        key: "indicatorCheckBox"
      },
      {
        label: "競合比較",
        value: "7",
        checked: true,
        disabled: false,
        key: "compareCheckBox"
      },
      {
        label: "経済業界環境",
        value: "8",
        checked: true,
        disabled: false,
        key: "economyCheckBox"
      },
      {
        label: "Appendix",
        value: "9",
        checked: true,
        disabled: false,
        key: "appendixCheckBox"
      }
    ],
    key: "contents"
  }
];

const wordContents = [
  {
    label: "<企業基本>",
    value: "",
    checked: true,
    disabled: false,
    children: [
      {
        label: "基本情報",
        value: "1",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "解説コメント",
        value: "2",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "沿革",
        value: "3",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "役員",
        value: "4",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "大株主",
        value: "5",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "事業所",
        value: "6",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "取引先",
        value: "7",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "関係会社",
        value: "8",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "設備状況",
        value: "9",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "設備計画",
        value: "10",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      },
      {
        label: "労務状況",
        value: "11",
        checked: true,
        disabled: false,
        key: "reportKigyoukihon"
      }
    ],
    key: "reportKigyoukihon"
  },
  {
    label: "<人事情報>",
    value: "12",
    checked: true,
    disabled: false,
    children: [],
    key: "reportJinjiJyouhou"
  },
  {
    label: "<決算・株価データ>",
    value: "",
    checked: true,
    disabled: false,
    children: [
      {
        label: "業績",
        value: "13",
        checked: true,
        disabled: false,
        key: "reportKetsanKabukaData"
      },
      {
        label: "セグメント情報",
        value: "14",
        checked: true,
        disabled: false,
        key: "reportKetsanKabukaData"
      },
      {
        label: "株価",
        value: "15",
        checked: true,
        disabled: false,
        key: "reportKetsanKabukaData"
      },
      {
        label: "同業比較",
        value: "16",
        checked: true,
        disabled: false,
        key: "reportKetsanKabukaData"
      }
    ],
    key: "reportKetsanKabukaData"
  },
  {
    label: "<テキストデータ>",
    value: "",
    checked: true,
    disabled: false,
    children: [
      {
        label: "日経新聞記事<見出し：直近30件>",
        value: "17",
        checked: true,
        disabled: false,
        key: "reportTextData"
      },
      {
        label: "企業活動情報",
        value: "19",
        checked: true,
        disabled: false,
        key: "reportTextData"
      },
      {
        label: "適時開示<見出し:直近20件>",
        value: "20",
        checked: true,
        disabled: false,
        key: "reportTextData"
      },
      {
        label: "事業等のリスク",
        value: "21",
        checked: true,
        disabled: false,
        key: "reportTextData"
      },
      {
        label: "対処すべき課題",
        value: "22",
        checked: true,
        disabled: false,
        key: "reportTextData"
      }
    ],
    key: "reportTextData"
  },
  {
    label: "<業界レポート>",
    value: "",
    checked: true,
    disabled: false,
    children: [
      {
        label: "業界概要",
        value: "23",
        checked: true,
        disabled: false,
        key: "reportGyokaiReport"
      },
      {
        label: "市場動向",
        value: "24",
        checked: true,
        disabled: false,
        key: "reportGyokaiReport"
      },
      {
        label: "競合状況",
        value: "25",
        checked: true,
        disabled: false,
        key: "reportGyokaiReport"
      },
      {
        label: "業界統計",
        value: "27",
        checked: true,
        disabled: false,
        key: "reportGyokaiReport"
      },
      {
        label: "事業セグメント比較",
        value: "26",
        checked: true,
        disabled: false,
        key: "reportGyokaiReport"
      }
    ],
    key: "reportGyokaiReport"
  }
];

const sourceParamNames = {
  CORPFILTER_CHECKBOXID_LISTED_CORP_CreateReport: "listedChkBox",
  CORPFILTER_CHECKBOXID_SECURITYREPORTED_CORP_CreateReport: "unlistedAsrChkBox",
  CORPFILTER_CHECKBOXID_UNLISTED_CORP_CreateReport: "unlistedChkBox",
  CORPFILTER_CHECKBOXID_OVERSEAS_CORP_CreateReport: "overseasChkBox",
  CORPFILTER_CHECKBOXID_OVERSEAS_NORTHAMERICA_CORP_CreateReport:
    "northUsaChkBox",
  CORPFILTER_CHECKBOXID_OVERSEAS_SOUTHAMERICA_CORP_CreateReport:
    "southUsaChkBox",
  CORPFILTER_CHECKBOXID_OVERSEAS_EUROPE_CORP_CreateReport: "euChkBox",
  CORPFILTER_CHECKBOXID_OVERSEAS_ASIAPACIFIC_CORP_CreateReport: "apChkBox",
  CORPFILTER_CHECKBOXID_OVERSEAS_MIDEAST_CORP_CreateReport: "mEastChkBox",
  CORPFILTER_CHECKBOXID_OVERSEAS_AFRICA_CORP_CreateReport: "africaChkBox"
};

// 表紙チェックボックスが非選択なら日付チェックボックスを非活性に
const updateDateCheckBoxDisabled = (children, coverCheckBoxChecked) => {
  return children.map(child => {
    if (child.key === "dateCheckBox") {
      child.disabled = !coverCheckBoxChecked;
      if (child.disabled) child.checked = false;
    }
    return child;
  });
};

/**
 * /corp/createreport/save-word-context のレスポンス(checkedIds)から
 * コンテンツ情報の配列 を更新して返す
 * @param {object[]} contents コンテンツ情報の配列
 * @param {string[]} checkedIds 選択済みvalueの配列
 */
const updateContentsChecked = (contents, checkedIds) => {
  return contents.map(parent => {
    if (parent.key === "reportJinjiJyouhou") {
      parent.checked = checkedIds.indexOf(parent.value) >= 0;
      return parent;
    }
    let isAllChecked = true;
    let coverCheckBoxChecked = null;
    let children = parent.children.map(content => {
      content.checked = false;
      if (checkedIds.indexOf(content.value) >= 0) {
        content.checked = true;
      } else {
        isAllChecked = false;
      }
      if (content.key === "coverCheckBox")
        coverCheckBoxChecked = content.checked;
      return content;
    });
    if (parent.key === "cover") {
      children = updateDateCheckBoxDisabled(children, coverCheckBoxChecked);
    }
    return {
      ...parent,
      checked: isAllChecked,
      children: children
    };
  });
};

/**
 * 選択済みvalueの配列があればそちらから、なければコンテンツ情報の配列から
 * wordのcontentsParamsを作成する
 * @param {object[]} contents コンテンツ情報の配列
 * @param {?string[]} checkIds 選択済みvalueの配列
 *      /corp/createreport/save-word-context のレスポンス
 */
const createWordContentsParams = (contents, checkedIds = []) => {
  const params = {
    reportKigyoukihon: [],
    reportJinjiJyouhou: [],
    reportKetsanKabukaData: [],
    reportTextData: [],
    reportGyokaiReport: []
  };

  if (!isEmpty(checkedIds)) {
    // valueのみの配列からパラメータ作成
    contents.map(parent => {
      if (parent.key === "reportJinjiJyouhou") {
        if (checkedIds.indexOf(parent.value) >= 0) {
          params[parent.key].push(parent.value);
        }
      } else {
        parent.children.map(content => {
          if (checkedIds.indexOf(content.value) >= 0) {
            params[content.key].push(content.value);
          }
        });
      }
    });
  } else {
    // コンテンツ情報の配列からパラメータ作成
    contents.map(parent => {
      if (parent.key === "reportJinjiJyouhou") {
        if (parent.checked) {
          params[parent.key].push(parent.value);
        }
      } else {
        parent.children.map(content => {
          if (content.checked) {
            params[content.key].push(content.value);
          }
        });
      }
    });
  }

  return {
    reportKigyoukihon: params.reportKigyoukihon.join(","),
    reportJinjiJyouhou: params.reportJinjiJyouhou.join(","),
    reportKetsanKabukaData: params.reportKetsanKabukaData.join(","),
    reportTextData: params.reportTextData.join(","),
    reportGyokaiReport: params.reportGyokaiReport.join(",")
  };
};

/**
 * 選択済みvalueの配列があればそちらから、なければコンテンツ情報の配列から
 * pptのcontentsParamsを作成する
 * @param {object[]} contents コンテンツ情報の配列
 * @param {?string[]} checkIds 選択済みvalueの配列
 *      /corp/createreport/save-word-context のレスポンス
 */
const createPptContentsParams = (contents, checkedIds = []) => {
  const params = {
    contentCheckBox: "",
    coverCheckBox: "",
    centerCoverCheckBox: "",
    dateCheckBox: "",
    dateText: "",
    basicInfoCheckBox: "",
    indicatorCheckBox: "",
    compareCheckBox: "",
    economyCheckBox: "",
    appendixCheckBox: "",
    personName: "",
    positionName: ""
  };

  if (!isEmpty(checkedIds)) {
    // valueのみの配列からパラメータ作成
    contents.map(parent => {
      parent.children.map(content => {
        if (checkedIds.indexOf(content.value) >= 0) {
          params[content.key] = "on";
        }
      });
    });
    return params;
  }

  // コンテンツ情報の配列からパラメータ作成
  contents.map(parent => {
    parent.children.map(content => {
      if (content.checked) {
        params[content.key] = "on";
      }
    });
  });
  return params;
};

// コンテンツ情報の配列から、チェック済みのvalueを","で連結した文字列を返す
const createSaveContextParames = contents => {
  const checkedIds = [];
  contents.map(parent => {
    if (parent.key === "reportJinjiJyouhou") {
      if (parent.checked) {
        checkedIds.push(parent.value);
      }
    } else {
      parent.children.map(content => {
        if (content.checked) {
          checkedIds.push(content.value);
        }
      });
    }
  });
  return checkedIds.join(",");
};

const initialState = {
  currentReportType: "ppt",
  entityList: {},
  isLoadingEntityList: false, // entityList 取得中フラグ
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  companyList: [],
  isLoadingCompanyList: false,
  companyBySuggestList: [],
  latestviewCompanyList: [],
  bookmarkCompanyList: [],
  selectedFolderId: "", // 選択中ブックマークフォルダ
  groupingCompanyList: [],
  selectedCompanyList: [],
  checkedContents: [],
  // /corp/createreport/ppt-create-report のパラメータ用
  pptContentsParams: {
    contentCheckBox: "",
    coverCheckBox: "",
    centerCoverCheckBox: "",
    dateCheckBox: "",
    dateText: "",
    basicInfoCheckBox: "",
    indicatorCheckBox: "",
    compareCheckBox: "",
    economyCheckBox: "",
    appendixCheckBox: "",
    currency: "en"
  },
  // /corp/createreport/word-create-report のパラメータ用
  wordContentsParams: {
    reportKigyoukihon: "",
    reportJinjiJyouhou: "",
    reportKetsanKabukaData: "",
    reportTextData: "",
    reportGyokaiReport: ""
  },
  // /corp/createreport/save-word-context のパラメータ用
  saveContextParames: {
    ppt: "",
    word: ""
  },
  pptContents: pptContents, // pptコンテンツ情報オブジェクト
  wordContents: wordContents, // wordコンテンツ情報オブジェクト
  // /corp/createreport/filter のパラメータ用（上場・海外等のチェックボックス選択状態）
  conditionParams: {
    mainFlgReportSaimuComboBox: "",
    africaChkBox: "",
    mEastChkBox: "",
    apChkBox: "",
    euChkBox: "",
    southUsaChkBox: "",
    northUsaChkBox: "",
    overseasChkBox: "",
    unlistedChkBox: "",
    unlistedAsrChkBox: "",
    listedChkBox: "",
    mainFlg: "" // 主業種・所属業種
  },
  dateText: "", // ppt日付文字列
  items: {}, // 上場・海外等選択項目
  personnel: {}, // 人事情報
  selectedPersons: [], // 選択済みの人事情報
  selectedPersonsTemp: [], // 未保存の選択中人事情報
  isLoadingPersonnel: false, // personnal 取得中フラグ
  paging: {}, // 人事情報ページング用開始件数と終了件数
  isOpen: false
};

export default handleActions(
  {
    [`${ActionTypes.REPORT_CREATE_INIT}_FULFILLED`]: (state, action) => {
      const newCondition = {};
      action.payload.labelList.map(item => {
        const name = sourceParamNames[item.id];
        if (name !== undefined) {
          newCondition[name] = item.selected ? "on" : "";
        }
      });
      return {
        ...state,
        selectedCompanyList: action.payload.domesticCorpList,
        conditionParams: {
          ...state.conditionParams,
          ...newCondition
        },
        items: action.payload.labelList,
        pptContentsParams: {
          ...state.pptContentsParams,
          currency: "en"
        }
      };
    },
    [`${ActionTypes.ENTITY_LIST_GET}_PENDING`]: state => ({
      ...state,
      isLoadingEntityList: true
    }),
    [`${ActionTypes.ENTITY_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      entityList: action.payload,
      isLoadingEntityList: false,
      conditionParams: {
        ...state.conditionParams,
        mainFlgReportSaimuComboBox: action.payload.nkgncodes,
        mainFlg: !isEmpty(action.payload.mainFlgList)
          ? action.payload.mainFlgList[0].value
          : ""
      }
    }),
    [`${ActionTypes.ENTITY_LIST_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingEntityList: false
    }),
    [`${ActionTypes.REPORT_TYPE_SET}`]: (state, action) => ({
      ...state,
      currentReportType: action.payload
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_PENDING`]: state => ({
      ...state,
      needsMstL: [],
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstL: true,
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstL: false,
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstM: true,
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      isLoadingNeedsMstM: false,
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      isLoadingNeedsMstS: true,
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      isLoadingNeedsMstS: false,
      companyList: []
    }),
    [`${ActionTypes.COMPANY_GET}_PENDING`]: state => ({
      ...state,
      companyList: [],
      isLoadingCompanyList: true
    }),
    [`${ActionTypes.COMPANY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      companyList: action.payload.companyList,
      isLoadingCompanyList: false
    }),
    [`${ActionTypes.COMPANY_BY_SUGGEST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      companyBySuggestList: action.payload.companyList
    }),
    [`${ActionTypes.LATESTVIEW_COMPANY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      latestviewCompanyList: action.payload.companyList
    }),
    [`${ActionTypes.BOOKMARK_COMPANY_GET}_PENDING`]: (state, action) => ({
      ...state,
      selectedFolderId: action.meta.selectedFolder
    }),
    [`${ActionTypes.BOOKMARK_COMPANY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      bookmarkCompanyList: action.payload.companyList
    }),
    [`${ActionTypes.SELECTED_BOOKMARK_FOLDER_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      selectedFolderId: action.payload.selectedFolder.folderId
    }),
    [`${ActionTypes.SELECTED_COMPANIES_ADD}_FULFILLED`]: (state, action) => ({
      ...state,
      selectedCompanyList: action.payload.companyList
    }),
    [ActionTypes.SELECTED_COMPANY_REMOVE]: (state, action) => {
      const isDifferentCompany = (target, company) => {
        if (
          target.companyCode !== undefined &&
          company.companyCode !== undefined
        ) {
          return target.companyCode !== company.companyCode;
        }
        if (target.nkcode !== undefined && company.nkcode !== undefined) {
          return target.nkcode !== company.nkcode;
        }
        if (target.companyCode !== undefined && company.nkcode !== undefined) {
          return target.companyCode !== company.nkcode;
        }
        if (target.nkcode !== undefined && company.companyCode !== undefined) {
          return target.nkcode !== company.companyCode;
        }
        return false;
      };
      const selectedCompanyList = state.selectedCompanyList.filter(company =>
        isDifferentCompany(company, action.payload)
      );
      return {
        ...state,
        selectedCompanyList: selectedCompanyList
      };
    },
    [ActionTypes.SELECTED_COMPANY_CLEAR]: state => ({
      ...state,
      selectedCompanyList: []
    }),
    [`${ActionTypes.COMPANY_LIST_RESEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      selectedCompanyList: action.payload.companyList
    }),
    [`${ActionTypes.CONTENTS_SAVE}_FULFILLED`]: (state, action) => {
      const res = action.payload;
      const newState = {
        ...state,
        currentReportType: action.payload.fileType
      };

      const checkedIds = action.payload.contents.split(",");

      if (res.fileType === "word") {
        const result = updateContentsChecked(state.wordContents, checkedIds);
        return {
          ...newState,
          wordContents: result,
          wordContentsParams: {
            ...newState.wordContentsParams,
            ...createWordContentsParams(state.wordContents, checkedIds)
          },
          saveContextParames: {
            ...state.saveContextParames,
            word: action.payload.contents
          }
        };
      }

      const result = updateContentsChecked(state.pptContents, checkedIds);
      return {
        ...newState,
        pptContents: result,
        pptContentsParams: {
          ...newState.pptContentsParams,
          ...createPptContentsParams(state.pptContents, checkedIds)
        },
        saveContextParames: {
          ...state.saveContextParames,
          ppt: action.payload.contents
        }
      };
    },
    [ActionTypes.CONDITIONS_UPDATE]: (state, action) => {
      return {
        ...state,
        conditionParams: {
          ...state.conditionParams,
          ...action.payload
        }
      };
    },
    [ActionTypes.CONTENTS_UPDATE]: (state, action) => {
      const { newContent, type } = action.payload;
      if (type === "ppt") {
        return {
          ...state,
          pptContents: newContent,
          pptContentsParams: {
            ...state.pptContentsParams,
            ...createPptContentsParams(newContent)
          },
          saveContextParames: {
            ...state.saveContextParames,
            ppt: createSaveContextParames(newContent)
          }
        };
      }

      return {
        ...state,
        wordContents: newContent,
        wordContentsParams: createWordContentsParams(newContent),
        saveContextParames: {
          ...state.saveContextParames,
          word: createSaveContextParames(newContent)
        }
      };
    },
    [ActionTypes.DATE_SAVE]: (state, action) => ({
      ...state,
      dateText: action.payload
    }),
    [ActionTypes.ITEMS_SAVE]: (state, action) => ({
      ...state,
      items: action.payload
    }),
    [ActionTypes.CURRENCY_SAVE]: (state, action) => ({
      ...state,
      pptContentsParams: {
        ...state.pptContentsParams,
        currency: action.payload
      }
    }),
    [`${ActionTypes.PERSONNEL_GET}_PENDING`]: state => ({
      ...state,
      isLoadingPersonnel: true
    }),
    [`${ActionTypes.PERSONNEL_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      personnel: action.payload,
      paging: {
        start: 1,
        end: 20
      },
      selectedPersons: action.payload.whosList.slice(0, 5),
      selectedPersonsTemp: action.payload.whosList.slice(0, 5),
      isLoadingPersonnel: false
    }),
    [`${ActionTypes.PERSONNEL_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingPersonnel: false
    }),
    [`${ActionTypes.PERSONNEL_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      personnel: action.payload,
      paging: {
        start: 1,
        end: action.payload.whosList.length
      },
      selectedPersonsTemp: [],
      personName: action.meta.personName,
      positionName: action.meta.positionName
    }),
    [`${ActionTypes.PERSONNEL_PAGING}_FULFILLED`]: (state, action) => ({
      ...state,
      personnel: {
        ...state.personnel,
        whosList: [...state.personnel.whosList, ...action.payload.whosList],
        pnaver: action.payload.pnaver,
        transitionId: action.payload.transitionId
      },
      paging: action.meta
    }),
    [ActionTypes.PERSONS_SET]: (state, action) => ({
      ...state,
      selectedPersons: action.payload
    }),
    [ActionTypes.PAGE_SET]: (state, action) => ({
      ...state,
      paging: action.payload
    }),
    [ActionTypes.TEMP_PERSONS_SET]: (state, action) => ({
      ...state,
      selectedPersonsTemp: action.payload
    }),
    [ActionTypes.REPORT_CLOSE]: state => ({
      ...state,
      isOpen: false,
      dateText: initialState.dateText,
      // 閉じる際に、クリアする必要のあるデータをクリア
      entityList: {},
      personnel: {},
      selectedPersons: [],
      selectedPersonsTemp: []
    }),
    [ActionTypes.REPORT_OPEN]: state => ({
      ...state,
      isOpen: true
    })
  },
  initialState
);

export const actionCreators = {
  initReportCreate: createAction(ActionTypes.REPORT_CREATE_INIT, params =>
    request(12, params)
  ),
  setReportType: createAction(ActionTypes.REPORT_TYPE_SET),
  getEntityList: createAction(ActionTypes.ENTITY_LIST_GET, params =>
    request(622, params)
  ),
  getNeedsMstL: createAction(ActionTypes.NEEDS_MST_L_GET, params =>
    request(49, params)
  ),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(50, params)
  ),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(51, params)
  ),
  getCompany: createAction(ActionTypes.COMPANY_GET, params =>
    request(52, params)
  ),
  getCompanyBySuggest: createAction(
    ActionTypes.COMPANY_BY_SUGGEST_GET,
    params => request(53, params)
  ),
  getLatestviewCompany: createAction(
    ActionTypes.LATESTVIEW_COMPANY_GET,
    params => request(54, params)
  ),
  getBookmarkCompany: createAction(
    ActionTypes.BOOKMARK_COMPANY_GET,
    params => request(55, params),
    params => ({ selectedFolder: params.selectedFolder })
  ),
  getSelectedBookmarkFolder: createAction(
    ActionTypes.SELECTED_BOOKMARK_FOLDER_GET,
    params => request(18, params)
  ),
  selectedCompanies: createAction(ActionTypes.SELECTED_COMPANIES),
  addSelectedCompanies: createAction(
    ActionTypes.SELECTED_COMPANIES_ADD,
    params => request(57, params)
  ),
  removeSelectedCompany: createAction(ActionTypes.SELECTED_COMPANY_REMOVE),
  clearSelectedCompany: createAction(ActionTypes.SELECTED_COMPANY_CLEAR),
  researchCompanyList: createAction(ActionTypes.COMPANY_LIST_RESEARCH, params =>
    request(58, params)
  ),
  setCheckedContents: createAction(ActionTypes.CHECKED_CONTENTS_SET),
  createIndustryReport: createAction(
    ActionTypes.INDUSTRY_REPORT_CREATE,
    params => request(19, params)
  ),
  downloadIndustryReport: createAction(
    ActionTypes.INDUSTRY_REPORT_DOWNLOAD,
    params => request(20, params)
  ),
  saveContents: createAction(
    ActionTypes.CONTENTS_SAVE,
    params => request(44, params),
    (params, isInit) => ({ isInit: isInit ? isInit : false })
  ),
  updateConditions: createAction(ActionTypes.CONDITIONS_UPDATE),
  updateContents: createAction(ActionTypes.CONTENTS_UPDATE),
  saveDate: createAction(ActionTypes.DATE_SAVE),
  createPptReport: createAction(ActionTypes.PPT_REPORT_CREATE, params =>
    request(46, params)
  ),
  createWordReport: createAction(ActionTypes.WORD_REPORT_CREATE, params =>
    request(45, params)
  ),
  downloadReport: createAction(ActionTypes.REPORT_DOWNLOAD, params =>
    request(47, params)
  ),
  saveItems: createAction(ActionTypes.ITEMS_SAVE), // 上場・海外等選択項目のセット
  updateCurrency: createAction(ActionTypes.CURRENCY_SAVE),
  getPersonnel: createAction(ActionTypes.PERSONNEL_GET, params =>
    request(48, params)
  ),
  searchPersonnel: createAction(
    ActionTypes.PERSONNEL_SEARCH,
    params => request(316, params),
    params => ({
      personName: params.personName,
      positionName: params.positionName
    })
  ),
  pagingPersonnel: createAction(
    ActionTypes.PERSONNEL_PAGING,
    params => request(317, params),
    (params, pages) => ({ ...pages })
  ),
  setPersons: createAction(ActionTypes.PERSONS_SET), // 選択された人を保存
  setPage: createAction(ActionTypes.PAGE_SET), // ページャ開始値終了値セット
  setTempPersons: createAction(ActionTypes.TEMP_PERSONS_SET),
  closeReport: createAction(ActionTypes.REPORT_CLOSE),
  openReport: createAction(ActionTypes.REPORT_OPEN)
};
