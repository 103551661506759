import { CollectionAction } from "./actions";
import { BinderItem } from "models/BinderItem";
import { Reducer } from "redux";
import produce from "immer";

export interface CollectionState {
  collections: BinderItem[];
  isAdding: boolean;
  totalCount: number;
}

const initialState: CollectionState = {
  collections: [],
  isAdding: false,
  totalCount: 0
};

export const collection: Reducer<CollectionState, CollectionAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "setting/personal/alert/collection/SET_COLLECTIONS":
      return produce(state, draft => {
        draft.collections = action.payload.collections;
      });
    case "setting/personal/alert/collection/ADD_COLLECTIONS":
      return produce(state, draft => {
        draft.collections = draft.collections.concat(
          action.payload.collections
        );
      });
    case "setting/personal/alert/collection/SET_TOTAL_COUNT":
      return produce(state, draft => {
        draft.totalCount = action.payload.totalCount;
      });
    case "setting/personal/alert/collection/CHANGE_ISADDING":
      return produce(state, draft => {
        draft.isAdding = action.payload.isAdding;
      });
    default:
      return state;
  }
};
