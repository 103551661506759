exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Toast-style__component--2ZDgV {\n  position: fixed;\n  z-index: 11;\n  bottom: 40px;\n  left: 16px;\n  width: calc(100% - 16px * 2);\n  min-height: 72px;\n  max-height: calc(100% - 40px * 2);\n}\n\n.src-components-Common-Toast-style__toast--2aEr4 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: start;\n      align-items: flex-start;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  background-color: rgba(20, 30, 50, 0.8);\n}\n.src-components-Common-Toast-style__toast--2aEr4.src-components-Common-Toast-style__error--1u51m {\n  margin-top: 16px;\n  background-color: rgba(170, 42, 42, 0.8);\n}\n\n.src-components-Common-Toast-style__messages--2XrOx {\n  color: #fff;\n  font-size: 18px;\n}\n.src-components-Common-Toast-style__messages--2XrOx .src-components-Common-Toast-style__message--PKvth {\n  padding-bottom: 14px;\n}\n.src-components-Common-Toast-style__messages--2XrOx .src-components-Common-Toast-style__message--PKvth:first-child {\n  padding-top: 27px;\n}\n.src-components-Common-Toast-style__messages--2XrOx .src-components-Common-Toast-style__message--PKvth:last-child {\n  padding-bottom: 27px;\n}\n\n.src-components-Common-Toast-style__buttonWrapper--WUQOP {\n  width: 24px;\n  height: 24px;\n  margin: 24px 24px 0 0;\n}\n.src-components-Common-Toast-style__buttonWrapper--WUQOP .src-components-Common-Toast-style__icon--3CJsU {\n  width: 24px;\n  height: 24px;\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-components-Common-Toast-style__component--2ZDgV",
	"toast": "src-components-Common-Toast-style__toast--2aEr4",
	"error": "src-components-Common-Toast-style__error--1u51m",
	"messages": "src-components-Common-Toast-style__messages--2XrOx",
	"message": "src-components-Common-Toast-style__message--PKvth",
	"buttonWrapper": "src-components-Common-Toast-style__buttonWrapper--WUQOP",
	"icon": "src-components-Common-Toast-style__icon--3CJsU"
};