// 定数の取得
export const CONST_GET = "common/ChartGenerator/CONST_GET";
// 表示項目の取得
export const ITEMS_GET = "common/ChartGenerator/ITEMS_GET";
export const ITEMS_UPDATE = "common/ChartGenerator/ITEMS_UPDATE";
// 属性値をグラフに反映ボタン押下
export const CHART_UPDATE = "common/ChartGenerator/CHART_UPDATE";
// XLSファイルパス取得
export const XLS_PATH_GET = "common/ChartGenerator/XLS_PATH_GET";
// CSVファイルパス取得
export const CSV_PATH_GET = "common/ChartGenerator/CSV_PATH_GET";
// 系列追加
export const SERIES_ADD = "common/ChartGenerator/SERIES_ADD";
// 系列削除
export const SERIES_REMOVE = "common/ChartGenerator/SERIES_REMOVE";
export const SERIES_REMOVE_ALL = "common/ChartGenerator/SERIES_REMOVE_ALL";
// 系列更新
export const SERIES_UPDATE = "common/ChartGenerator/SERIES_UPDATE";
// グラフ描画
export const DRAW_GRAPH = "common/ChartGenerator/DRAW_GRAPH";
// 選択中企業一覧取得
export const CORP_LIST_GET = "common/ChartGenerator/CORP_LIST_GET";
// 選択中企業一覧取得(バリュエーションが閲覧可能かつ財務が閲覧不可能なユーザーのみ)
export const CORP_LIST_VALUATION_GET =
  "common/ChartGenerator/CORP_LIST_VALUATION_GET";

/* 財務・バリュエーションタブ データ分類取得 */
// データ分類１初期表示
export const FINANCIAL_DATA_CLASSIFICATION_1_INIT =
  "common/ChartGenerator/FINANCIAL_DATA_CLASSIFICATION_1_INIT";
// データ分類２初期表示
export const FINANCIAL_DATA_CLASSIFICATION_2_INIT =
  "common/ChartGenerator/FINANCIAL_DATA_CLASSIFICATION_2_INIT";
// データ分類２取得
export const FINANCIAL_DATA_CLASSIFICATION_2_GET =
  "common/ChartGenerator/FINANCIAL_DATA_CLASSIFICATION_2_GET";
// データ分類３取得
export const FINANCIAL_DATA_CLASSIFICATION_3_GET =
  "common/ChartGenerator/FINANCIAL_DATA_CLASSIFICATION_3_GET";

/* セグメントタブ データ分類取得 */
// データ分類１初期表示
export const SEGMENT_DATA_CLASSIFICATION_1_INIT =
  "common/ChartGenerator/SEGMENT_DATA_CLASSIFICATION_1_INIT";
// データ分類２初期表示
export const SEGMENT_DATA_CLASSIFICATION_2_INIT =
  "common/ChartGenerator/SEGMENT_DATA_CLASSIFICATION_2_INIT";
// データ分類２取得
export const SEGMENT_DATA_CLASSIFICATION_2_GET =
  "common/ChartGenerator/SEGMENT_DATA_CLASSIFICATION_2_GET";
// データ分類３取得(データ分類１変更、データ分類２変更、企業ラジオボタン変更時)
export const SEGMENT_DATA_CLASSIFICATION_3_GET =
  "common/ChartGenerator/SEGMENT_DATA_CLASSIFICATION_3_GET";
// データ分類３取得(期間変更時の場合)
export const SEGMENT_DATA_CLASSIFICATION_3_PERIOD_GET =
  "common/ChartGenerator/SEGMENT_DATA_CLASSIFICATION_3_PERIOD_GET";

// 会社チェックボックス選択
export const SELECTED_CORP_UPDATE =
  "common/ChartGenerator/SELECTED_CORP_UPDATE";
// 会社をリストから取り除く
export const CORP_REMOVE = "common/ChartGenerator/CORP_REMOVE";
// 系列データチェックボックス選択（財務）
export const FINANCIAL_SELECTED_SERIES_UPDATE =
  "common/ChartGenerator/FINANCIAL_SELECTED_SERIES_UPDATE";
// 系列データチェックボックス選択（バリュエーション）
export const VALUATION_SELECTED_SERIES_UPDATE =
  "common/ChartGenerator/VALUATION_SELECTED_SERIES_UPDATE";
// 系列データチェックボックス選択（セグメント）
export const SEGMENT_SELECTED_SERIES_UPDATE =
  "common/ChartGenerator/SEGMENT_SELECTED_SERIES_UPDATE";
// 系列データチェックボックス選択（IR）
export const IR_SELECTED_SERIES_UPDATE =
  "common/ChartGenerator/IR_SELECTED_SERIES_UPDATE";

// 系列データ取得（財務）
export const FINANCIAL_SERIES_GET =
  "common/ChartGenerator/FINANCIAL_SERIES_GET";
// 系列データ取得（バリュエーション）
export const VALUATION_SERIES_GET =
  "common/ChartGenerator/VALUATION_SERIES_GET";
// 系列データ取得（セグメント）
export const SEGMENT_SERIES_GET = "common/ChartGenerator/SEGMENT_SERIES_GET";
// 系列データ取得（IR）
export const IR_SERIES_GET = "common/ChartGenerator/IR_SERIES_GET";
// 系列データ詳細取得（IR）
export const IR_SERIES_DETAIL_GET =
  "common/ChartGenerator/IR_SERIES_DETAIL_GET";

// データ分類選択（財務・バリュエーション）
export const FINANCIAL_DATA_CLASSIFICATION_SELECT =
  "common/ChartGenerator/FINANCIAL_DATA_CLASSIFICATION_SELECT";
// データ分類選択（セグメント）
export const SEGMENT_DATA_CLASSIFICATION_SELECT =
  "common/ChartGenerator/SEGMENT_DATA_CLASSIFICATION_SELECT";

export const PERIOD_CHANGE = "common/ChartGenerator/PERIOD_CHANGE";

// 企業選択
// selectMenuProps
export const NEEDS_MST_L_GET = "common/ChartGenerator/NEEDS_MST_L_GET";
export const NEEDS_MST_M_GET = "common/ChartGenerator/NEEDS_MST_M_GET";
export const NEEDS_MST_S_GET = "common/ChartGenerator/NEEDS_MST_S_GET";
export const COMPANY_GET = "common/ChartGenerator/COMPANY_GET";
// searchListProps
export const COMPANY_BY_SUGGEST_GET =
  "common/ChartGenerator/COMPANY_BY_SUGGEST_GET";
// recentListProps
export const LATESTVIEW_COMPANY_GET =
  "common/ChartGenerator/LATESTVIEW_COMPANY_GET";
// bookmarkListProps
export const BOOKMARK_COMPANY_GET =
  "common/ChartGenerator/BOOKMARK_COMPANY_GET";
export const SELECTED_BOOKMARK_FOLDER_GET =
  "common/ChartGenerator/SELECTED_BOOKMARK_FOLDER_LIST";

export const DISPLAY_CORPS_UPDATE =
  "common/ChartGenerator/DISPLAY_CORPS_UPDATE";
export const MESSAGE_SET = "common/ChartGenerator/MESSAGE_SET";
export const MESSAGE_CLEAR = "common/ChartGenerator/MESSAGE_CLEAR";

// 全状態クリア
export const ALL_RESET = "common/ChartGenerator/ALL_RESET";
