import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

import { selectTable } from "components/Tool/Common/IndustryComparisonTable/ComparisonTable/Table/checkboxData";
import { isEmpty } from "utils/helper/common";

const initialState = {
  conditionData: {},
  comparisonHead: {},
  isLoadingComparisonHead: false,
  comparisonData: {},
  isLoadingComparisonData: false,
  comparisonConditions: {
    listingMiddleSelect: "0",
    listingSmallSelect: "0",
    selectedMiddleList: [],
    selectedSmallList: [],
    sortFlag: "",
    sortId: 0,
    userIndexValue: ""
  },
  // comparisonConditions: { // 比較表 選択状態の管理データ
  //   listingMiddleSelect: // 中分類チェックボックスチェック状態
  //   listingSmallSelect:  // 小分類チェックボックスチェック状態
  //   selectedMiddleList: // 削除後の中分類コードリスト(配列)
  //   selectedSmallList: // 削除後の小分類コードリスト(配列)
  //   sortFlag: // ソート状態(up / down)
  //   sortId: // ソート中の列番号
  //   userIndexValue: // スクリーニング検索条件セレクトボックスの選択値
  // }
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  needsSearchType: null,
  middleList: [],
  smallList: [],

  // チャートデータ
  isLoadingChartCount: false,
  chartData: {},
  isLoadingChartData: false,
  // ユーザー指標
  indexTree: [],
  selectedOption: "", // ユーザ指標編集 選択中の保存条件
  parentId: "",
  isAvailableIndex: "",
  selectedIndex: [],
  savedConditionList: [],

  searchResult: [],
  recentList: [],
  binderItems: [],
  binderList: [],
  binderId: "",
  isBinderItemsLoading: false,
  isBinderListLoading: false,

  // 比較表ファイルダウンロードモーダル系
  dlPeriodList: [],
  dlPeriodFrom: "",
  dlPeriodTo: "",

  error: {},

  // 遷移元から指定する業界コード
  defaultSelectedCodes: {
    middle: [],
    small: []
  }
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      conditionData: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonHead: true,
      defaultSelectedCodes: initialState.defaultSelectedCodes
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_FULFILLED`]: (state, action) => {
      const meta = action.meta;
      const newComparisonConditions = {
        // 初期化 & データ代入
        selectedMiddleList: meta.selectedMiddleList,
        selectedSmallList: meta.selectedSmallList
      };
      const comparisonHead = action.payload;
      // チェックボックスの選択状態をマージ
      for (const prop in selectTable) {
        const key = selectTable[prop];
        if (comparisonHead[key] == "1") newComparisonConditions[key] = "1";
      }
      const {
        userIndexGroupListOfScreening,
        userIndexGroupListOfBasic,
        userIndexGroupListOfUserSet
      } = comparisonHead.userIndexGroupListDto;
      let userIndexValue;
      if (
        !isEmpty(action.meta.dispItemListInitValue) &&
        userIndexGroupListOfUserSet.find(
          index => index.value == action.meta.dispItemListInitValue
        )
      ) {
        // 選択中のユーザ指標
        userIndexValue = action.meta.dispItemListInitValue;
      } else if (!isEmpty(action.meta.nowEditItem)) {
        // ユーザ指標編集で選択されたアイテムのラベル
        userIndexValue = userIndexGroupListOfUserSet.find(
          index => index.label == action.meta.nowEditItem
        ).value;
      } else {
        // デフォルト値
        userIndexValue = !isEmpty(userIndexGroupListOfScreening)
          ? userIndexGroupListOfScreening[0].value
          : !isEmpty(userIndexGroupListOfBasic)
          ? userIndexGroupListOfBasic[0].value
          : "";
      }
      newComparisonConditions["userIndexValue"] = userIndexValue;
      return {
        ...state,
        comparisonHead: comparisonHead,
        comparisonConditions: newComparisonConditions,
        isLoadingComparisonHead: false
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonHead: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: action.payload,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_FULFILLED`]: (state, action) => {
      const sortData = action.payload;
      return {
        ...state,
        comparisonData: {
          ...state.comparisonData,
          tableData: sortData.tableData
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_SORT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_FULFILLED`]: (
      state,
      action
    ) => {
      const sortData = action.payload;
      // 必要なデータだけマージする
      const newComparisonData = {
        ...state.comparisonData,
        fullNumber: sortData.fullNumber,
        hasReachedNkgnCodeShowMaxSize: sortData.hasReachedNkgnCodeShowMaxSize,
        listingMiddleCount: sortData.listingMiddleCount,
        listingMiddleSelect: sortData.listingMiddleSelect,
        listingSmallCount: sortData.listingSmallCount,
        listingSmallSelect: sortData.listingSmallSelect,
        maxDisplayableNkgnCodeCount: sortData.maxDisplayableNkgnCodeCount,
        messageDto: sortData.messageDto,
        nkgnCodeCountForShow: sortData.nkgnCodeCountForShow,
        nkgnCodeShowMaxSize: sortData.nkgnCodeShowMaxSize,
        screeningIdList: sortData.screeningIdList,
        selectedMiddleList: sortData.selectedMiddleList,
        selectedSmallList: sortData.selectedSmallList,
        tableData: sortData.tableData
      };
      const meta = action.meta;
      let newComparisonConditions = { ...state.comparisonConditions };
      // meta に渡された List 系を次のリクエストでも使うために、 comparisonConditions に保存する
      if (meta) {
        newComparisonConditions = {
          ...newComparisonConditions,
          selectedMiddleList: meta.selectedMiddleList, // 業界比較は配列で保持
          selectedSmallList: meta.selectedSmallList // 業界比較は配列で保持
        };
      }
      return {
        ...state,
        comparisonData: newComparisonData,
        comparisonConditions: newComparisonConditions,
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.COMPARISON_DATA_CLEAR]: state => ({
      ...state,
      comparisonData: {}, // クリア
      comparisonConditions: {}, // 選択、入力状態をクリア
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.COMPARISON_CONDITIONS_UPDATE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [`${ActionTypes.CHART_COUNT_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartCount: true
    }),
    [`${ActionTypes.CHART_COUNT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData:
        parseInt(action.payload) >= action.meta.selectedCount
          ? state.chartData
          : {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_COUNT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: {
        ...state.chartData,
        lineChartData: action.payload.lineChartData,
        bubbleChartData: action.payload.bubbleChartData,
        bubbleChartDataForEdit: action.payload.bubbleChartDataForEdit
      },
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [ActionTypes.CHART_DATA_CLEAR]: state => ({
      ...state,
      chartData: null
    }),
    [ActionTypes.RESULT_DATA_APPLY]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_FULFILLED`]: (state, action) => {
      const newIndexTree = action.payload;
      let selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      if (action.meta.kensakuName !== undefined && selectedIndex.length === 0) {
        selectedIndex = state.selectedIndex;
      }
      if (!isEmpty(action.meta.kensakuName)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            if (!isEmpty(item.child)) {
              item.childExistFlag = true;
              item.isOpen = true;
              item.childLoaded = true;
              setOpenToAllChildState(item.child);
            }
            return item;
          });
        };
        newIndexTree.treeDataList = setOpenToAllChildState(
          newIndexTree.treeDataList
        );
      }
      return {
        ...state,
        indexTree: newIndexTree,
        selectedOption: isEmpty(state.selectedOption)
          ? action.payload.userSelect[0].value
          : state.selectedOption,
        selectedIndex: selectedIndex
      };
    },
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      const newIndexTree = { ...state.indexTree };
      let depth = 0;
      const updateTargetChildState = treeDataList => {
        const targetParentItemId = meta.currentTreeIds[depth];
        for (const prop in treeDataList) {
          const item = treeDataList[prop];
          if (item.id === targetParentItemId) {
            depth += 1;
            updateTargetChildState(item.child);
          } else if (!targetParentItemId && item.id === meta.currentId) {
            if (!item.childLoaded) {
              item.child = payload.treeDataList;
              item.childLoaded = true;
              item.isOpen = true;
            } else {
              item.isOpen = !item.isOpen;
            }
          }
        }
      };
      updateTargetChildState(newIndexTree.treeDataList);

      return {
        ...state,
        indexTree: newIndexTree
      };
    },
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_FULFILLED`]: state => state,
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_ADD}_FULFILLED`]: (state, action) => {
      const newSelectedIndex = [...state.selectedIndex];
      // 存在、上限チェック
      const matchedIndex = newSelectedIndex.find(
        index => index.screeningId == action.payload.screeningId
      );

      if (
        !matchedIndex &&
        parseInt(state.indexTree.maxCount) > newSelectedIndex.length
      ) {
        newSelectedIndex.push(action.payload);
      }

      return {
        ...state,
        selectedIndex: newSelectedIndex
      };
    },
    [`${ActionTypes.INDEX_ADD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedIndex: action.payload
    }),
    [ActionTypes.INDEX_REMOVE_ALL]: state => ({
      ...state,
      selectedIndex: [],
      // 選択中の条件も先頭にリセット
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      indexTree: {
        ...state.indexTree,
        userSelect: action.payload
      },
      selectedOption: action.payload[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList,
      selectedIndex: [],
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_SET}_FULFILLED`]: state => ({
      ...state,
      indexTree: {} // クリア
    }),
    [`${ActionTypes.CONDITION_SET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      return {
        ...state,
        selectedIndex: selectedIndex,
        selectedOption: action.meta.userSetId
      };
    },
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_ALL_CLEAR]: state => ({
      ...state,
      indexTree: {},
      selectedOption: "",
      parentId: "",
      isAvailableIndex: "",
      selectedIndex: [],
      savedConditionList: []
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_PENDING`]: state => ({
      ...state,
      needsMstL: [],
      isLoadingNeedsMstL: true
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      isLoadingNeedsMstL: false,
      needsMstL: action.payload.needsMstL
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstM: true
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingNeedsMstM: false,
      error: action.payload
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingNeedsMstS: false,
      error: action.payload
    }),
    [`${ActionTypes.INDUSTRY_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      searchResult: action.payload.industryList
    }),
    [`${ActionTypes.INDUSTRY_SEARCH}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.RECENT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      recentList: action.payload.industryList
    }),
    [`${ActionTypes.RECENT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.BINDER_ITEM_GET}_PENDING`]: state => ({
      ...state,
      isBinderItemsLoading: true
    }),
    [`${ActionTypes.BINDER_ITEM_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      binderItems: action.payload.industryList,
      isBinderItemsLoading: false
    }),
    [`${ActionTypes.BINDER_ITEM_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isBinderItemsLoading: false
    }),
    [`${ActionTypes.BINDER_LIST_GET}_PENDING`]: state => ({
      ...state,
      isBinderListLoading: true
    }),
    [`${ActionTypes.BINDER_LIST_GET}_REJECTED`]: state => ({
      ...state,
      isBinderListLoading: false
    }),
    [`${ActionTypes.BINDER_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      binderList: action.payload.binderList,
      isBinderListLoading: false
    }),
    [ActionTypes.SEARCH_FLAG_SET]: (state, action) => ({
      ...state,
      needsSearchType: action.payload.type
    }),
    [ActionTypes.SEARCH_FLAG_RESET]: state => ({
      ...state,
      needsSearchType: null
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      }
    }),

    // 比較表ファイルダウンロードモーダル系
    [`${ActionTypes.DOWNLOAD_PERIOD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      dlPeriodList: action.payload.periodList,
      dlPeriodFrom: action.payload.periodFrom,
      // レスポンスにToの初期値なし
      dlPeriodTo: action.payload.periodList[0].value
    }),
    [ActionTypes.DOWNLOAD_PERIOD_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),
    [ActionTypes.TRANSITION_CODES_SET]: (state, action) => ({
      ...state,
      defaultSelectedCodes: {
        middle: action.payload.middle,
        small: action.payload.small
      }
    }),
    [ActionTypes.ALL_RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(692, params)),
  getComparisonHead: createAction(
    ActionTypes.COMPARISON_HEAD_GET,
    params => request(670, params.payload),
    params => params.meta
  ),
  getComparisonData: createAction(ActionTypes.COMPARISON_DATA_GET, params =>
    request(671, params)
  ),
  sortComparisonData: createAction(
    ActionTypes.COMPARISON_DATA_SORT,
    params => request(671, params) // get と API が同じ
  ),
  removeComparisonDataRow: createAction(
    ActionTypes.COMPARISON_DATA_ROW_REMOVE,
    params => request(671, params.payload), // get と API が同じ
    // comparisonConditions に残したいパラメータのみを meta に渡す
    params => params.meta
  ),
  clearComparisonData: createAction(ActionTypes.COMPARISON_DATA_CLEAR),
  updateComparisonConditions: createAction(
    ActionTypes.COMPARISON_CONDITIONS_UPDATE
  ),
  getChartCount: createAction(
    ActionTypes.CHART_COUNT_GET,
    params => request(691, params.payload),
    params => params.meta
  ),
  getChartData: createAction(ActionTypes.CHART_DATA_GET, params =>
    request(701, params)
  ),
  updateChartData: createAction(ActionTypes.CHART_DATA_UPDATE, params =>
    request(702, params)
  ),
  clearChartData: createAction(ActionTypes.CHART_DATA_CLEAR),
  applyResultData: createAction(ActionTypes.RESULT_DATA_APPLY),
  getIndexTreeRoot: createAction(
    ActionTypes.INDEX_TREE_ROOT_GET,
    params => request(673, params),
    params => ({ kensakuName: params.kensakuName })
  ),
  getIndexTreeChild: createAction(
    ActionTypes.INDEX_TREE_CHILD_GET,
    params => request(674, params.payload),
    params => params.meta
  ),
  getIndexAvailability: createAction(
    ActionTypes.INDEX_AVAILABILITY_GET,
    param => request(675, param)
  ),
  addIndex: createAction(ActionTypes.INDEX_ADD, params => request(676, params)),
  updateIndex: createAction(ActionTypes.INDEX_UPDATE),
  removeAllIndex: createAction(ActionTypes.INDEX_REMOVE_ALL),
  getSavedConditionList: createAction(ActionTypes.SAVED_CONDITION_GET, params =>
    request(679, params)
  ),
  getSavedConditionSelectList: createAction(
    ActionTypes.SAVED_CONDITION_SELECT_GET,
    params => request(682, params)
  ),
  deleteSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_DELETE,
    params => request(680, params)
  ),
  updateSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_UPDATE,
    params => request(681, params)
  ),
  getSavedConditionDetail: createAction(
    ActionTypes.SAVED_CONDITION_DETAIL_GET,
    params => request(678, params),
    params => ({ userSetId: params.userSetId })
  ),
  setCondition: createAction(
    ActionTypes.CONDITION_SET,
    params => request(677, params),
    params => ({ userSetName: params.userSetName })
  ),
  clearIndexAll: createAction(ActionTypes.INDEX_ALL_CLEAR),
  getNeedsMstL: createAction(ActionTypes.NEEDS_MST_L_GET, params =>
    request(693, params)
  ),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(694, params)
  ),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(695, params)
  ),
  searchIndustry: createAction(ActionTypes.INDUSTRY_SEARCH, params =>
    request(696, params)
  ),
  getRecent: createAction(ActionTypes.RECENT_GET, params =>
    request(697, params)
  ),
  getBinderItem: createAction(ActionTypes.BINDER_ITEM_GET, params =>
    request("/common/binder/list/industry", params)
  ),
  // コレクションリスト取得
  getBinderList: createAction(ActionTypes.BINDER_LIST_GET, () =>
    request("/common/binder")
  ),
  setSearchFlag: createAction(ActionTypes.SEARCH_FLAG_SET, params => ({
    type: (params && params.type) || "update"
  })),
  resetSearchFlag: createAction(ActionTypes.SEARCH_FLAG_RESET),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET),

  // 比較表ファイルダウンロードモーダル系
  getDownloadPeriod: createAction(ActionTypes.DOWNLOAD_PERIOD_GET, params =>
    request(684, params)
  ),
  setDownloadPeriod: createAction(
    ActionTypes.DOWNLOAD_PERIOD_SET,
    period => period,
    (_, key) => ({ key })
  ),
  resetAll: createAction(ActionTypes.ALL_RESET),
  setTransitionCodes: createAction(ActionTypes.TRANSITION_CODES_SET)
};
