// 適時開示アラート

// 保存済みアラート設定をすべて取得
export const ALL_ALERT_GET =
  "setting/personal/alert/timelyDisclosure/ALL_ALERT_GET";
// アラート名を更新
export const ALERT_NAME_UPDATE =
  "setting/personal/alert/timelyDisclosure/ALERT_NAME_UPDATE";
// アラート条件のフリーキーワードを更新
export const ALERT_KEYWORD_UPDATE =
  "setting/personal/alert/timelyDisclosure/ALERT_KEYWORD_UPDATE";
// アラート条件の入力内容を消去
export const CONDITION_CLEAR =
  "setting/personal/alert/timelyDisclosure/CONDITION_CLEAR";
// 業界大分類を取得
export const NEEDS_MST_L_GET =
  "setting/personal/alert/timelyDisclosure/NEEDS_MST_L_GET";
// 業界中分類を取得
export const NEEDS_MST_M_GET =
  "setting/personal/alert/timelyDisclosure/NEEDS_MST_M_GET";
// 業界小分類を取得
export const NEEDS_MST_S_GET =
  "setting/personal/alert/timelyDisclosure/NEEDS_MST_S_GET";
// 企業を（業界分類から）取得
export const COMPANY_GET =
  "setting/personal/alert/timelyDisclosure/COMPANY_GET";
// アラート条件に企業を追加
export const COMPANY_CONDITION_ADD =
  "setting/personal/alert/timelyDisclosure/COMPANY_CONDITION_ADD";
// アラート条件から企業を削除
export const COMPANY_CONDITION_REMOVE =
  "setting/personal/alert/timelyDisclosure/COMPANY_CONDITION_REMOVE";
// 保存済みアラート設定を取得
export const ALERT_GET = "setting/personal/alert/timelyDisclosure/ALERT_GET";
// 設定したアラート条件でのプレビュー結果を取得
export const ALERT_PREVIEW_GET =
  "setting/personal/alert/timelyDisclosure/ALERT_PREVIEW_GET";
// 設定を保存
export const ALERT_SAVE = "setting/personal/alert/timelyDisclosure/ALERT_SAVE";
// 設定を削除
export const ALERT_DELETE =
  "setting/personal/alert/timelyDisclosure/ALERT_DELETE";
// アラート設定の選択状態を更新
export const CHECKED_ALERT_ID_UPDATE =
  "setting/personal/alert/timelyDisclosure/CHECKED_ALERT_ID_UPDATE";
