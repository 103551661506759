import { useEffect } from "react";
import { useLocation } from "react-router";
import {
  getAccessStatus,
  updateAccessStatus
} from "modules/accessControl/actions";
import { useDispatch } from "react-redux";
import { usePrevious } from "hooks/usePrevious";

// /common/access-control API のリクエストが必要なページ
export const ACCESS_CONTROL_PATHS = ["/article/ma", "/tool/multiple"];

export const useAccessControl = () => {
  const { pathname, search } = useLocation();
  const previousPathname = usePrevious(pathname);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pathname === previousPathname) return;
    if (ACCESS_CONTROL_PATHS.includes(pathname)) {
      const searchParams = new URLSearchParams(search);
      dispatch(
        getAccessStatus({
          contentId: searchParams.get("contentId") || "",
          corpIndustyKbn: searchParams.get("corpIndustyKbn") || ""
        })
      );
    }

    if (previousPathname && ACCESS_CONTROL_PATHS.includes(previousPathname)) {
      // 初期値に戻す
      dispatch(updateAccessStatus(""));
    }
  }, [previousPathname, pathname, search, dispatch]);
};
