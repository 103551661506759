import { persistCombineReducers, persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import global from "./global";
import permanentGlobal from "./permanentGlobal";
import home from "./home";
import history from "./history";
import reportCreator from "./corp/common/ReportCreator";
import pageActions from "./corp/common/PageActions";
import corpSummary from "./corp/domestic/summary";
import corpOverview from "./corp/domestic/overview";
import corpDisclosure from "./corp/domestic/disclosure";
import corpSegment from "./corp/domestic/segment";
import corpRisk from "./corp/domestic/risk";
import corpFinance from "./corp/domestic/finance";
import corpSharesHoldings from "./corp/domestic/shares/holdings";
import corpSupplychain from "./corp/common/supplychain";
import corpPersonnel from "./corp/domestic/whoswho";
import corpShareholder from "./corp/domestic/shares/shareholder";
import corpMeeting from "./corp/domestic/meeting";
import corpLoan from "./corp/domestic/loan";
import corpGovernance from "./corp/domestic/csr/governance";
import corpEnvironment from "./corp/domestic/csr/environment";
import corpLabor from "./corp/domestic/csr/labor";
import corpEvaluation from "./corp/domestic/csr/evaluation";
import corpNews from "./corp/domestic/news";
import corpReport from "./corp/domestic/report";
import corpEarningsForecast from "./corp/domestic/earning-forecast";
import corpStockPrice from "./corp/domestic/index";
import corpIrData from "modules/corp/domestic/ir-data";
import corpCommonComparison from "./corp/common/comparison";
import corpComparisonFinance from "./corp/common/comparison/finance";
import corpComparisonSegment from "./corp/common/comparison/segment";
import corpValuationDcf from "modules/corp/domestic/valuation/dcf";
import corpValuationBeta from "modules/corp/domestic/valuation/beta";
import corpValuationValue from "modules/corp/domestic/valuation/value";
import corpValuationInvestmentIndex from "modules/corp/domestic/valuation/investment-index";
import corpMaEvent from "./corp/domestic/ma-event";
import corpOverseasSummary from "./corp/overseas/summary";
import corpOverseasFinance from "./corp/overseas/finance";
import corpOverseasBusiness from "./corp/overseas/segment/business";
import corpOverseasCountry from "./corp/overseas/segment/country";
import corpOverseasShareholder from "./corp/overseas/shares/shareholder";
import corpOverseasValuation from "./corp/overseas/valuation";
import corpOverseasDisclosure from "./corp/overseas/disclosure";
import corpOverseasEarningsForecast from "./corp/overseas/earning-forecast";
import corpCommonBreadCrumb from "./corp/common/BreadCrumb";
import corpCommonTitle from "./corp/common/Title";
import corpCommonMainMenu from "./corp/common/MainMenu";
import corpOverseasStockPrice from "./corp/overseas/index";
import corpMaStrategy from "./corp/domestic/ma-strategy";
import corpGraph from "./corp/domestic/graph";
import corpTelecomSearch from "./corp/domestic/telecom/search";
import corpTelecomTradePress from "./corp/domestic/telecom/trade-press";
import industrySummary from "./industry/summary";
import industryIndex from "./industry/industry-index";
import industryScale from "./industry/scale";
import industryNews from "./industry/news";
import industryReport from "./industry/report";
import industryResearchReport from "./industry/research-report";
import industryStrategy from "./industry/strategy";
import industryTelecomTradePress from "./industry/telecom/trade-press";
import toolComparisonCorp from "./tool/comparison/corp";
import toolComparisonCorpResultData from "./tool/comparison/corp/result-data";
import toolComparisonIndustry from "./tool/comparison/industry";
import toolComparisonIndustryResultData from "./tool/comparison/industry/result-data";
import toolScreeningCorpItem from "./tool/screening/corp/legacyItem";
import { toolScreeningCorpModernItem } from "./tool/screening/corp/modernItem";
import { toolScreeningCorpItemTree } from "./tool/screening/corp/modernItem/tree";
import toolScreeningCorpItemResultData from "./tool/screening/corp/legacyItem/result-data";
import toolScreeningCorpScenario from "./tool/screening/corp/scenario";
import toolScreeningCorpScenarioResultData from "./tool/screening/corp/scenario/result-data";
import toolScreeningSegmentBusiness from "./tool/screening/segment/business";
import toolScreeningSegmentBusinessResultData from "./tool/screening/segment/business/result-data";
import toolScreeningSegmentArea from "./tool/screening/segment/area";
import toolScreeningSegmentAreaResultData from "./tool/screening/segment/area/result-data";
import toolScreeningIndustry from "./tool/screening/industry";
import toolScreeningIndustryResultData from "./tool/screening/industry/result-data";
import { toolExcelAddIn } from "./tool/excelAddIn";
import { toolEsgReport } from "./tool/esg/report";
import { toolEsgResult } from "./tool/esg/result";
import { toolEsgMateriality } from "./tool/esg/materiality";
import commonChartGenerator from "./common/ChartGenerator";
import commonBookmarkBalloon from "./common/BookmarkBalloon";
import commonEventSearch from "./common/EventSearch";
import commonPopup from "./common/Popup";
import commonIrDataSearch from "./common/IRDataSearch";
import commonSourceSelector from "./common/SourceSelector";
import commonAddingMyFolder from "./common/AddingMyFolder";
import commonBinderList from "./common/BinderList";
import header from "./header";
import sample from "./sample";
import globalNavigation from "./globalNavigation";
import globalSearch from "./globalSearch";
import search from "./search";
import searchClassification from "./search/classification";
import searchIndustry from "./search/industry";
import searchTabInfo from "./search/tabInfo";
import articleDisclosure from "./article/disclosure";
import articleNews from "./article/news";
import articleStrategy from "./article/strategy";
import { ma } from "./article/ma";
import { bindersList } from "./binders/list";
import { bindersItem } from "./binders/item";
import { bindersSaved } from "./binders/saved";
import { statistic } from "./macro/statistic";
import { area } from "./macro/area";
import { commodity } from "./macro/commodity";
import { summary } from "./macro/summary";
import { forecast } from "./macro/forecast";
import articleReport from "./article/report";
import { tab as articleReportTab } from "./article/report/tab";
import settingPersonalSystem from "./setting/personal/system";
import { collection as settingPersonalAlertCollection } from "./setting/personal/alert/collection";
import settingPersonalCorpAlert from "./setting/personal/alert/corp";
import settingPersonalTimelyDisclosureAlert from "./setting/personal/alert/timelyDisclosure";
import settingUserFee from "./setting/user-fee";
import { homeTop } from "./homeTop";
import { quickMenu } from "./quickMenu";
import { authSaved } from "./auth/saved";
import { authTemporary } from "./auth/temporary";
import { authSearchTab } from "./auth/searchTab";
import { authCorp } from "./auth/corp";
import { accessControl } from "./accessControl";
import { toast } from "./toast";
import { download } from "./download";
import { modal } from "./modal";
import { toolMultiple } from "./tool/multiple";
import { operandRawSearch } from "./operandRawSearch";
import { operandRawSearchModal } from "./operandRawSearch/modal";
import { corpEsgEnvironment } from "./corp/esg/environment";
import { corpEsgSociety } from "./corp/esg/society";
import { corpEsgGovernance } from "./corp/esg/governance";
import { downloadManagement } from "./setting/downloadManagement";
import { simpleIndustry } from "./search/industry/simple";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "global",
    "permanentGlobal",
    "toolComparisonCorpResultData",
    "toolComparisonIndustryResultData",
    "toolScreeningCorpItemResultData",
    "toolScreeningCorpScenarioResultData",
    "toolScreeningSegmentBusinessResultData",
    "toolScreeningSegmentAreaResultData",
    "toolScreeningIndustryResultData",
    "commonSourceSelector",
    "searchTabInfo",
    "toast",
    "download",
    "modal",
    "settingPersonalSystem",
    "authSearchTab"
  ]
};

const homePersistConfig = {
  key: "home",
  storage: storage,
  whitelist: ["sortKbn"]
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["saved", "corp"]
};

const bindersPersistConfig = {
  key: "binders",
  storage: storage,
  whitelist: ["saved"]
};

const operandRawSearchPersistConfig = {
  key: "operandRawSearchSearch",
  storage: storage,
  whitelist: ["saved"]
};

const toolCorpConfig = {
  key: "toolScreeningCorpItemModern",
  storage,
  whitelist: ["tree"]
};

const reducers = persistCombineReducers(persistConfig, {
  global,
  permanentGlobal,
  home: persistReducer(homePersistConfig, home),
  history,
  reportCreator,
  pageActions,
  corpSummary,
  corpOverview,
  corpDisclosure,
  corpSegment,
  corpRisk,
  corpFinance,
  corpSharesHoldings,
  corpSupplychain,
  corpPersonnel,
  corpShareholder,
  corpMeeting,
  corpLoan,
  corpGovernance,
  corpEnvironment,
  corpLabor,
  corpEvaluation,
  corpNews,
  corpReport,
  corpEarningsForecast,
  corpStockPrice,
  corpIrData,
  corpCommonComparison,
  corpComparisonFinance,
  corpComparisonSegment,
  corpValuationDcf,
  corpValuationBeta,
  corpValuationValue,
  corpValuationInvestmentIndex,
  corpMaEvent,
  corpOverseasSummary,
  corpOverseasFinance,
  corpOverseasBusiness,
  corpOverseasCountry,
  corpOverseasShareholder,
  corpOverseasValuation,
  corpOverseasDisclosure,
  corpOverseasEarningsForecast,
  corpCommonTitle,
  corpCommonBreadCrumb,
  corpCommonMainMenu,
  corpOverseasStockPrice,
  corpMaStrategy,
  corpGraph,
  corpTelecomSearch,
  corpTelecomTradePress,
  industrySummary,
  industryIndex,
  industryScale,
  industryNews,
  industryReport,
  industryResearchReport,
  industryStrategy,
  industryTelecomTradePress,
  toolComparisonCorp,
  toolComparisonCorpResultData,
  toolComparisonIndustry,
  toolComparisonIndustryResultData,
  toolScreeningCorpItem,
  toolScreeningCorpItemResultData,
  toolScreeningCorpScenario,
  toolScreeningCorpScenarioResultData,
  toolScreeningSegmentBusiness,
  toolScreeningSegmentBusinessResultData,
  toolScreeningSegmentArea,
  toolScreeningSegmentAreaResultData,
  toolScreeningIndustry,
  toolScreeningIndustryResultData,
  commonChartGenerator,
  commonBookmarkBalloon,
  commonEventSearch,
  commonPopup,
  commonIrDataSearch,
  commonSourceSelector,
  commonAddingMyFolder,
  header,
  sample,
  globalNavigation,
  globalSearch,
  search,
  searchClassification,
  searchIndustry,
  articleDisclosure,
  articleNews,
  settingPersonalAlertCollection,
  settingPersonalSystem,
  settingPersonalCorpAlert,
  settingPersonalTimelyDisclosureAlert,
  settingUserFee,
  articleStrategy,
  article: combineReducers({
    ma: ma
  }),
  binders: persistCombineReducers(bindersPersistConfig, {
    saved: bindersSaved,
    list: bindersList,
    item: bindersItem
  }),
  macro: combineReducers({
    statistic,
    area,
    commodity,
    summary,
    forecast
  }),
  tool: combineReducers({
    excelAddIn: toolExcelAddIn,
    multiple: toolMultiple,
    esgReport: toolEsgReport,
    esgResult: toolEsgResult,
    esgMateriality: toolEsgMateriality
  }),
  toolScreeningCorpItemModern: persistCombineReducers(toolCorpConfig, {
    item: toolScreeningCorpModernItem,
    tree: toolScreeningCorpItemTree
  }),
  searchTabInfo,
  commonBinderList,
  homeTop,
  quickMenu,
  articleReport,
  articleReportTab,
  auth: persistCombineReducers(authPersistConfig, {
    saved: authSaved,
    temporary: authTemporary,
    corp: authCorp
  }),
  accessControl,
  toast,
  download,
  modal,
  operandRawSearch: persistCombineReducers(operandRawSearchPersistConfig, {
    saved: operandRawSearch,
    modal: operandRawSearchModal
  }),
  corpEsgEnvironment,
  corpEsgSociety,
  corpEsgGovernance,
  downloadManagement,
  authSearchTab,
  simpleIndustry
});

export default reducers;
