// 初期表示
export const PAGE_INIT = "industry/strategy/PAGE_INIT";
// セレクトボックス等のデータ取得
export const CODE_LIST_GET = "industry/strategy/CODE_LIST_GET";
// 選択した条件での検索
export const STRATEGY_DATA_SEARCH = "industry/strategy/STRATEGY_DATA_SEARCH";
// ソート・表示件数を変更してデータ取得
export const STRATEGY_DATA_SORT = "industry/strategy/STRATEGY_DATA_SORT";
// ページング
export const STRATEGY_PAGING = "industry/strategy/STRATEGY_TOP_PAGE";
