import React, { Component } from "react";
import styles from "./style.scss";
import Heading from "components/Common/Heading/index";
import Button from "components/Common/Button/Text";
import { isEmpty } from "utils/helper/common";
import CompanyTable from "./CompanyTable/index";
import { getParamsFromUrl } from "utils/helper/url";
import FinanceTable from "./FinanceTable/index";
import SectionLoading from "components/Common/SectionLoading";

const corp = {
  title: "帝国データバンク企業情報",
  Table: CompanyTable,
  dataKey: "corpSummaryTdbComapny"
};
const finance = {
  title: "帝国データバンク財務情報",
  Table: FinanceTable,
  dataKey: "corpfinTdbResult"
};

class Tdb extends Component {
  constructor(props) {
    super(props);

    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.onClickPrintButton = this.onClickPrintButton.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  onBeforeUnload(event) {
    event.preventDefault();
    event.returnValue = "";
  }

  onClickPrintButton() {
    window.print();
  }

  render() {
    const { pathname, corpName } = getParamsFromUrl();
    const info = pathname === "/corp/summary/tdb-corp" ? corp : finance;
    const data = this.props[info.dataKey];
    if (this.props.isPopupLoading) {
      return (
        <div>
          <SectionLoading isLoading={true} />
          <div className={styles.text}>
            購入しました、コンテンツを取得中です。
          </div>
        </div>
      );
    }

    if (isEmpty(data)) return null;
    return (
      <div data-testid="Common-Popup-Tdb">
        <header className={styles.header}>
          <div className={styles.title}>
            <Heading level="2">{corpName}</Heading>
            <span>{info.title}</span>
          </div>
          <Button
            data-testid="Common-Popup-Tdb-printButton"
            onClick={this.onClickPrintButton}
          >
            印刷
          </Button>
        </header>
        <main>
          <info.Table data={data} />
        </main>
      </div>
    );
  }
}

export default Tdb;
