import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.DETAILS_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.DETAILS_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(59, params)),
  udpateDetails: createAction(ActionTypes.DETAILS_UPDATE, params =>
    request(60, params)
  )
};
