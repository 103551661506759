import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import { findAnalyticsWithPath } from "utils/helper/url";
import { isIE } from "utils/helper/common";

const isPromise = obj =>
  obj instanceof Promise || (obj && typeof obj.then === "function");

const errorHandle = error => {
  if (IS_DEBUG) {
    console.error(error);
    return error;
  }

  // ページ名が取得できれば設定する
  const info = findAnalyticsWithPath(location.pathname);
  const pageName = info === undefined ? "" : info.pageName;
  if (error.body && error.body.code && error.body.code === 401) return error;

  // Sentry ログ送信
  Sentry.withScope(scope => {
    scope.setExtra({
      pageName,
      catchAt: "Redux Middleware"
    });
    Sentry.captureException(error);
  });

  if (!isIE()) {
    // LogRocket ログ送信
    LogRocket.captureException(error, {
      extra: { pageName }
    });
  }
  return error;
};

export default () => next => action => {
  Sentry.addBreadcrumb({
    category: "redux-action",
    message: action.type
  });

  try {
    return isPromise(action.payload)
      ? next(action).catch(errorHandle)
      : next(action);
  } catch (error) {
    errorHandle(error);
  }
};
