// 初期表示
export const PAGE_INIT = "tools/comparison/industry/PAGE_INIT";
// 比較表ヘッダ情報取得（仮）
export const COMPARISON_HEAD_GET =
  "tools/comparison/industry/COMPARISON_HEAD_GET";
// 比較表明細情報取得（仮）
export const COMPARISON_DATA_GET =
  "tools/comparison/industry/COMPARISON_DATA_GET";
// 比較表ソート取得
export const COMPARISON_DATA_SORT =
  "tools/comparison/industry/COMPARISON_DATA_SORT";
// 比較表行削除
export const COMPARISON_DATA_ROW_REMOVE =
  "tools/comparison/industry/COMPARISON_DATA_ROW_REMOVE";
// ユーザが表示キャンセル時に表データをクリアする
export const COMPARISON_DATA_CLEAR =
  "tools/comparison/industry/COMPARISON_DATA_CLEAR";
// 比較表セレクトボックス等の選択状態更新
export const COMPARISON_CONDITIONS_UPDATE =
  "tools/comparison/industry/COMPARISON_CONDITIONS_UPDATE";
// 散布図選択件数の上限値を取得
export const CHART_COUNT_GET = "tools/comparison/industry/CHART_COUNT_GET";
// チャート情報を取得
export const CHART_DATA_GET = "tools/comparison/industry/CHART_DATA_GET";
// チャート情報を更新
export const CHART_DATA_UPDATE = "tools/comparison/industry/CHART_DATA_UPDATE";
// チャート情報削除
export const CHART_DATA_CLEAR = "tools/comparison/industry/CHART_DATA_CLEAR";
// 保存データの内容を state に適用する
export const RESULT_DATA_APPLY = "tools/comparison/industry/RESULT_DATA_APPLY";
// 指標ツリーのルート階層を取得
export const INDEX_TREE_ROOT_GET =
  "tools/comparison/industry/INDEX_TREE_ROOT_GET";
// 指標ツリーの子ノードを取得
export const INDEX_TREE_CHILD_GET =
  "tools/comparison/industry/INDEX_TREE_CHILD_GET";
// 指標を検索条件に追加できるかを取得
export const INDEX_AVAILABILITY_GET =
  "tools/comparison/industry/INDEX_AVAILABILITY_GET";
// 指標を検索条件に追加
export const INDEX_ADD = "tools/comparison/industry/INDEX_ADD";
// 指標の選択状態を更新
export const INDEX_UPDATE = "tools/comparison/industry/INDEX_UPDATE";
// 指標を検索条件からすべて削除
export const INDEX_REMOVE_ALL = "tools/comparison/industry/INDEX_REMOVE_ALL";
// 保存した検索条件セレクトボックスの中身取得
export const SAVED_CONDITION_SELECT_GET =
  "tools/comparison/industry/SAVED_CONDITION_SELECT_GET";
// 保存した検索条件一覧取得
export const SAVED_CONDITION_GET =
  "tools/comparison/industry/SAVED_CONDITION_GET";
// 保存した検索条件の削除
export const SAVED_CONDITION_DELETE =
  "tools/comparison/industry/SAVED_CONDITION_DELETE";
// 保存した検索条件の更新
export const SAVED_CONDITION_UPDATE =
  "tools/comparison/industry/SAVED_CONDITION_UPDATE";
// 保存した検索条件の詳細取得
export const SAVED_CONDITION_DETAIL_GET =
  "tools/comparison/industry/SAVED_CONDITION_DETAIL_GET";
// 保存した検索条件の取得
export const CONDITION_SET = "tools/comparison/industry/CONDITION_SET";
// 指標編集系の全てデータをクリアする
export const INDEX_ALL_CLEAR = "tools/comparison/industry/INDEX_ALL_CLEAR";
// 業界分類の取得
export const NEEDS_MST_L_GET = "tools/comparison/industry/NEEDS_MST_L_GET";
export const NEEDS_MST_M_GET = "tools/comparison/industry/NEEDS_MST_M_GET";
export const NEEDS_MST_S_GET = "tools/comparison/industry/NEEDS_MST_S_GET";
export const INDUSTRY_SELECT = "tools/comparison/industry/INDUSTRY_SELECT";
// 業界分類の検索
export const INDUSTRY_SEARCH = "tools/comparison/industry/INDUSTRY_SEARCH";
// 最近見た業界の取得
export const RECENT_GET = "tools/comparison/industry/RECENT_GET";
// コレクションの業界の取得
export const BINDER_ITEM_GET = "tools/comparison/industry/BINDER_ITEM_GET";
export const BINDER_LIST_GET = "tools/comparison/industry/BINDER_LIST_GET";
// 遷移元から指定する業界コードの設定
export const TRANSITION_CODES_SET =
  "tools/comparison/industry/TRANSITION_CODES_SET";

/**
 * その他
 */
// 再検索フラグをセット／リセット
export const SEARCH_FLAG_SET = "tools/comparison/industry/SEARCH_FLAG_SET";
export const SEARCH_FLAG_RESET = "tools/comparison/industry/SEARCH_FLAG_RESET";
// ソート状態を解除
export const SORT_INFO_UNSET = "tools/comparison/industry/SORT_INFO_UNSET";

// ダウンロード時の期間セレクトボックス情報取得
export const DOWNLOAD_PERIOD_GET =
  "tools/comparison/industry/DOWNLOAD_PERIOD_GET";
export const DOWNLOAD_PERIOD_SET =
  "tools/comparison/industry/DOWNLOAD_PERIOD_SET";

// ページ遷移時にキャッシュ削除
export const ALL_RESET = "tools/comparison/industry/ALL_RESET";
