import fullKanaMap from "utils/json/fullKanaMap.json";

/**
 * formatYMDToDateStr
 * 渡された数値8桁に、/区切りを付けて返す
 * ex. YYYYMMDD => YYYY/MM/DD
 *     20180102 => 2018/01/02
 * @param {string} str 数値8桁
 */
export const formatYMDToDateStr = (dateStr: string) => {
  return dateStr.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
};

/**
 * formatYMToDateStr
 * 渡された数値6桁に、/区切りを付けて返す
 * ex. YYYYMM => YYYY/MM
 *     201801 => 2018/01
 * @param {string} str 数値6桁
 */
export const formatYMToDateStr = (dateStr: string) => {
  return dateStr.replace(/(\d{4})(\d{2})/, "$1/$2");
};

/**
 * formatYMDToDateJapaneseStr
 * 渡された数値8桁に、年月日を付けて返す
 * または
 * 渡された数値6桁に、年月を付けて返す
 * ex. YYYYMMDD => YYYY年MM月DD日
 *     20180102 => 2018年01月02日
 *     201801   => 2018年01月
 * @param {string} str 数値8桁
 */
export const formatYMDToDateJapaneseStr = (dateStr: string) => {
  const fullReg = /(\d{4})(\d{2})(\d{2})/;
  const shortReg = /(\d{4})(\d{2})/;
  if (dateStr.match(fullReg) !== null) {
    return dateStr.replace(fullReg, "$1年$2月$3日");
  } else if (dateStr.match(shortReg) !== null) {
    return dateStr.replace(shortReg, "$1年$2月");
  }
  return dateStr;
};

/**
 * formatYMToEndedDateStr
 * 渡された数値6桁に、年月期を付けて返す
 * ex. YYYYMM => YYYY年MM月期
 *     201801 => 2018年01月期
 * @param {string} dateStr 数値6桁
 */
export const formatYMToEndedDateStr = (dateStr: string, prefix = "月期") => {
  return dateStr.replace(/(\d{4})(\d{2})/, `$1年$2${prefix}`);
};

/**
 * formatComma
 * 渡された金額などの文字列に3桁区切りのカンマを加えたものを返す
 * @param {string} str 等の文字列(カンマ無し、マイナスも対応)
 */
export const formatComma = (str: string) => {
  if (!str || str.indexOf(",") !== -1) return str;
  const newStr = str.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  return newStr;
};

/**
 * formatQuery
 * 渡されたクエリパラメータ混ざりのURLをクエリパラメータのみにして返す
 * &amp;を&に置換する
 * @param {string} str
 */
export const formatQuery = (str: string) =>
  str.split("?")[1].replace(/&amp;/g, "&");

/**
 * roundDecimal
 * 数字の文字列を、小数点第place位で四捨五入して文字列として返す
 * @param {string} str 数字の文字列
 * @param {number} place 小数の桁数
 * @param {?number} fillZero 0埋めする桁数
 */
export const roundDecimal = (
  str: string,
  place: number,
  fillZero: number | null = null
) => {
  if (str === "" || str.indexOf(",") !== -1) return str;
  const data =
    Math.round(parseFloat(str) * Math.pow(10, place)) / Math.pow(10, place);
  return fillZero === null ? String(data) : String(data.toFixed(fillZero));
};

/**
 * round
 * 数字の文字列から小数点を四捨五入して整数の文字列として返す
 * ,つきの文字列は処理せず返す
 * @param {string} str 数字の文字列
 */
export const round = (str: string) => {
  if (str === "" || str.indexOf(",") !== -1) return str;
  return String(Math.round(parseFloat(str)));
};

/**
 * roundDown
 * 数字の文字列から小数点を切り捨てて整数の文字列として返す
 * ,つきの文字列は処理せず返す
 * @param {string} str 数字の文字列
 */
export const roundDown = (str: string) => {
  if (str === "" || str.indexOf(",") !== -1) return str;
  return String(parseInt(str, 10));
};

/**
 * DateオブジェクトをYYYYMMDD形式の日付文字列としてして返す
 * separatorを渡した場合は、separatorで区切った文字列を返す
 * @param {Date} date
 */
export const formatDateToYMDStr = (date: Date, separator = "") => {
  const zeroUme = (date: number) => ("0" + date).slice(-2);
  return `${date.getFullYear()}${separator}${zeroUme(
    date.getMonth() + 1
  )}${separator}${zeroUme(date.getDate())}`;
};

/**
 * 文字列内のスペース系文字を半角スペースに変換し、トリムしたものを返す
 * 主に検索用文字列に使用する
 * @param {string} str
 */
export const trimSpaces = (str: string) => {
  /* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */
  return str.replace(/[\s　]/g, " ").trim();
};

/**
 * 文頭のスペース系文字を削除して返す
 * @param {String} str
 */
export const trimStartOfLineSpaces = (str: string) => {
  return str.replace(/^[\s\uFEFF\xA0]+/, "");
};

/**
 * 数字の文字列(,の有無は問わない)の小数点以下を切り捨てる
 * @param {string} numStr
 */
export const roundDownStr = (numStr: string) => {
  return numStr.replace(/\.\d+$/, "");
};

/**
 * Sass の Variables から数値を抽出
 * @param {string} target 対象文字列
 * @returns {number|false}
 */
export const extractNumberFromVariables = (target: string) => {
  const result = target.match(/\d+\.?\d*/);
  if (result === null) return false;
  return Number(result[0]);
};

/**
 * 複数の値の標準偏差を取得する(1. 要素数の平均値を求める 2. 平均値の差分を二乗後、足した値を要素数で割る)
 * 計算が不可能な場合や不適当な引数が与えられた場合0を返す
 * @param {[number[]} array 対象文字列
 * @returns {number}
 */
export const getStandardDeviation = (ary: number[]): number => {
  if (
    !Array.isArray(ary) ||
    ary.length === 0 ||
    ary.every(e => typeof e !== "number")
  )
    return 0;
  const average = ary.reduce((acc, cur) => acc + cur) / ary.length;
  return Math.sqrt(
    ary
      .map(current => {
        const difference = current - average;
        return difference ** 2;
      })
      .reduce((acc, cur) => acc + cur) / ary.length
  );
};

export default interface IndexableInterface {
  [key: string]: string;
}

/**
 * 半角カナ → 全角カナ
 * @param {String} str
 */
export const formatToFullKana = (str: string) => {
  const reg = new RegExp("(" + Object.keys(fullKanaMap).join("|") + ")", "g");
  return str.replace(reg, function(match) {
    return (fullKanaMap as IndexableInterface)[match];
  });
};

/**
 * 半角英数字 → 全角英数字
 * @param {String} str
 */
export const formatToFullSize = (str: string) => {
  return str.replace(/[A-Za-z0-9]/g, function(match) {
    return String.fromCharCode(match.charCodeAt(0) + 0xfee0);
  });
};
