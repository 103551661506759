import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { abortablePostRequest, request } from "utils/apiClient/base";
import { valueFromKeyOrDefault } from "utils/helper/common";
import { isEmpty, isEqual } from "utils/helper/common";

const initialState = {
  activeTab: 1,
  redirectInfo: {},
  // 一括検索元のURL情報 { pathname: "", search: "" }
  redirectBasePath: {},
  allSelectResult: {},
  checkedCorpCodes: "",
  checkedIndustryCodes: "",
  corpIndustryTreemapInfo: {},
  sourceItems: [],
  corpInfos: {
    searchListFilterInfo: [],
    corpSearchListFilterInfo: [],
    articleSearchListFilterInfo: [],
    searchListCheckboxStates: [],
    selectboxLabel: "1"
  },
  industryInfos: {
    industrySearchListFilterInfo: [],
    searchListCheckboxStates: [],
    selectboxLabel: "1"
  },
  telecom: {},
  tabInfo: {
    corpVisible: undefined,
    industryVisible: undefined,
    irVisible: undefined,
    newsVisible: undefined,
    magazineVisible: undefined,
    macroVisible: undefined,
    collectionVisible: undefined,
    statisticsVisible: undefined,
    dataVisible: undefined,
    governmentVisible: undefined,
    corpCount: "loading...",
    industryCount: "loading...",
    dataCount: "loading...",
    irCount: "loading...",
    macroCount: "loading...",
    newsCount: "loading...",
    magazineCount: "loading...",
    collectionCount: "loading...",
    governmentDataCount: "loading..."
  },
  bubbleChartAssociatedArticles: {},
  treeMapAssociatedArticles: {},
  summaryError: {
    corp: false,
    industry: false,
    news: false,
    magazine: false,
    collection: false,
    insight: false
  },
  corpResult: {},
  corpResultSummary: {},
  industryResult: {},
  industryResultSummary: {},
  newsResult: {},
  newsResultSummary: {},
  disclosureResult: {},
  magazineResult: {},
  magazineResultSummary: {},
  collectionResult: {},
  collectionResultSummary: {},
  researchResult: {},
  irResult: {},
  statisticsResult: {},
  governmentResult: {},
  isSummaryMode: {
    // 業界統計IR・経済統計・コレクションの軽量取得モード判定
    // 処理概要：https://nikkeidevs.slack.com/archives/C0414DLG0RL/p1689068759856259?thread_ts=1688952350.632779&cid=C0414DLG0RL
    corpResult: false,
    industryResult: false,
    collectionResult: false,
    irResult: false,
    statisticsResult: false
  },
  isSearching: false,
  isOpenModal: {
    corp: false,
    insight: false
  },
  // 各タブのLoading情報
  loadingInfo: {
    isCorpSearching: false,
    isDisclosureSearching: false,
    isNewsSearching: false,
    isMagazineSearching: false,
    isResearchSearching: false,
    isIRSearching: false,
    isStatisticsSearching: false,
    isCorpIndustryTreeMapSearching: false,
    isCollectionSearching: false,
    isGovernmentSearching: false
  },
  // 企業タブの選択値
  corpFilter: {
    corpSource: [],
    articleSource: [],
    corpType: [],
    sort: "0",
    perPage: "50",
    searchTarget: "0"
  },
  industryFilter: {
    source: undefined,
    sort: "0",
    perPage: "50"
  },
  isLoading: {
    selectAllCorp: false
  },
  corpDownloadParams: {
    dlpropriety: "",
    dlxls: "",
    dlcsv: "",
    dlcsvpath: "",
    dlxlspath: ""
  },
  collectionFilter: {
    searchTargetList: [],
    binderSortIndex: "0",
    binderDisCount: "100"
  },
  searchAbortController: null
};

const mergeSourceItems = (items, count) => {
  return items.map(item => {
    let itemCount;
    switch (item.inneyCode) {
      case "1":
        itemCount = Number(count.listedCount);
        break;
      case "2":
        itemCount = Number(count.unlistedHolderCount);
        break;
      case "3":
        itemCount = Number(count.unlistedNikkeiCount);
        break;
      case "4":
        itemCount = Number(count.overseasCount);
        break;
      case "5":
        itemCount = Number(count.northAmericaCount);
        break;
      case "6":
        itemCount = Number(count.southAmericaCount);
        break;
      case "7":
        itemCount = Number(count.europeCount);
        break;
      case "8":
        itemCount = Number(count.asiaPacificCount);
        break;
      case "9":
        itemCount = Number(count.middleEastCount);
        break;
      case "10":
        itemCount = Number(count.africaCount);
        break;
      case "11":
        itemCount = Number(count.unlistedTdbCount);
        break;
      case "13":
        itemCount = Number(count.unlistedStartupCount);
        break;
      case "14":
        itemCount = Number(count.overseasUnlistedCount);
        break;
      case "15":
        itemCount = Number(count.unlistedNorthAmericaCount);
        break;
      case "16":
        itemCount = Number(count.unlistedSouthAmericaCount);
        break;
      case "17":
        itemCount = Number(count.unlistedEuropeCount);
        break;
      case "18":
        itemCount = Number(count.unlistedAsiaPacificCount);
        break;
      case "19":
        itemCount = Number(count.unlistedMiddleEastCount);
        break;
      case "20":
        itemCount = Number(count.unlistedAfricaCount);
        break;
      default:
        itemCount = 0;
    }
    return { ...item, corpCount: itemCount };
  });
};

const initSearchListCheckboxStates = searchTargetDataTypeList => {
  return {
    stringifiedSelectedCheckbox: formatCheckboxValues(searchTargetDataTypeList),
    selectedLabels: searchTargetDataTypeList
      .map(checkbox => checkbox.value)
      .join(" ")
  };
};

const formatCheckboxValues = list => {
  if (isEmpty(list)) return null;

  const formated =
    "#" +
    list
      .map(c => {
        if (c.selected) return c.label;
        return null;
      })
      .join("#") +
    "#";

  return formated;
};

const addCountToTargetList = (list, personalCount, nikkeiCount) => {
  return list.map(target => {
    switch (target.label) {
      case "マイコレクション":
        target.count = personalCount;
        break;
      case "日経コレクション":
        target.count = nikkeiCount;
        break;
    }
    return target;
  });
};

const countCollectionTotal = (searchTargetList, personalCount, nikkeiCount) => {
  const list = searchTargetList.filter(
    target => target.selected && target.active
  );
  return list
    .map(target => {
      switch (target.label) {
        case "マイコレクション":
          return parseInt(personalCount);
        case "日経コレクション":
          return parseInt(nikkeiCount);
      }
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};

export default handleActions(
  {
    [`${ActionTypes.SEARCH}_PENDING`]: (state, action) => ({
      ...initialState,
      corpInfos: { ...state.corpInfos }, // 次回検索時にチェックボックス状態を維持するために必要
      industryInfos: { ...state.industryInfos }, // 次回検索時にチェックボックス状態を維持するために必要
      // 新規タブの挙動向けに初期化せず
      tabInfo: {
        ...state.tabInfo
      },
      disclosureResult: { ...state.disclosureResult },
      newsResult: { ...state.newsResult },
      magazineResult: { ...state.magazineResult },
      researchResult: { ...state.researchResult },
      irResult: { ...state.irResult },
      statisticsResult: { ...state.statisticsResult },
      governmentResult: { ...state.governmentResult },
      collectionResult: { ...state.collectionResult },
      corpIndustryTreemapInfo: { ...state.corpIndustryTreemapInfo },
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: true
      },
      collectionFilter: { ...state.collectionFilter }, // 次回検索時にチェックボックス状態を維持するために必要
      searchAbortController: state.searchAbortController,
      summaryError: { ...state.summaryError },
      isSummaryMode: {
        ...state.isSummaryMode,
        corpResult: action.meta.isSummaryMode || false,
        industryResult: action.meta.isSummaryMode || false
      }
    }),
    [`${ActionTypes.SEARCH}_FULFILLED`]: (state, action) => {
      const hasRedirectInfo = redirectInfo => {
        return (
          Object.prototype.hasOwnProperty.call(
            redirectInfo,
            "corpIndustyCode"
          ) && redirectInfo.corpIndustyCode
        );
      };
      // 企業サマリにリダイレクトする場合は Store を更新しない
      if (hasRedirectInfo(action.payload.redirectInfo)) return state;
      return {
        ...state,
        redirectInfo: action.payload.redirectInfo,
        redirectBasePath: valueFromKeyOrDefault(action, "meta.path", {}),
        corpResult: action.payload.searchList01Dto,
        corpResultSummary: action.payload.searchList01Dto,
        tabInfo: {
          ...state.tabInfo,
          corpCount: action.payload.searchList01Dto.totalCount,
          dataCount: state.tabInfo.dataCount,
          industryResearchReportCount:
            state.tabInfo.industryResearchReportCount,
          irCount: state.tabInfo.irCount,
          macroCount: state.tabInfo.macroCount,
          newsCount: state.tabInfo.newsCount,
          magazineCount: state.tabInfo.magazineCount,
          collectionVisible: state.tabInfo.collectionVisible,
          collectionCount: state.tabInfo.collectionCount,
          governmentVisible: state.tabInfo.governmentVisible,
          governmentDataCount: state.tabInfo.governmentDataCount
        },
        activeTab: 1,
        loadingInfo: {
          ...state.loadingInfo,
          isCorpSearching: false
        },
        corpFilter: {
          ...initialState.corpFilter,
          searchTarget: action.payload.searchTarget,
          corpSource: action.meta.searchCorpDataFilterValue
            .split("#")
            .filter(item => !isEmpty(item)),
          articleSource: action.meta.searchArticleDataFilterValue
            .split("#")
            .filter(item => !isEmpty(item)),
          corpType: action.payload.concatenatedCorpFilterValue
            .split("#")
            .filter(item => !isEmpty(item))
        },
        industryFilter: {
          ...initialState.industryFilter,
          source: action.payload.industrySearchTarget
            .split("#")
            .filter(item => item)
        },
        corpDownloadParams: {
          dlpropriety: action.payload.dlpropriety,
          dlxls: action.payload.dlxls,
          dlcsv: action.payload.dlcsv,
          dlcsvpath: action.payload.dlcsvpath,
          dlxlspath: action.payload.dlxlspath
        },
        searchAbortController: null,
        isSummaryMode: {
          ...state.isSummaryMode,
          corpResult: action.meta.isSummaryMode || false,
          industryResult: action.meta.isSummaryMode || false
        },
        sourceItems: mergeSourceItems(
          action.meta.sourceItems,
          action.payload.searchList01Dto
        )
      };
    },
    [`${ActionTypes.SEARCH}_REJECTED`]: (state, error) => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        // AbortController でリクエストが中止された際はローディングを続ける
        isCorpSearching: error.payload.name === "AbortError"
      }
    }),
    [ActionTypes.SET_SEARCH_ABORTCONTROLLER]: (state, action) => ({
      ...state,
      searchAbortController: action.payload.abortController
    }),
    [`${ActionTypes.TELECOM_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      telecom: action.payload
    }),
    [ActionTypes.SUMMARY_SET]: (state, action) => {
      return {
        ...state,
        [action.payload.key]: action.payload.result
      };
    },
    [`${ActionTypes.CORP_SORT}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: true
      }
    }),
    [`${ActionTypes.CORP_SORT}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: false
      }
    }),
    [`${ActionTypes.CORP_SORT}_FULFILLED`]: (state, action) => ({
      ...state,
      corpResult: action.payload,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: false
      }
    }),
    [ActionTypes.UPDATE_CORP_SORT]: (state, action) => ({
      ...state,
      corpFilter: {
        ...state.corpFilter,
        sort: action.payload.sort,
        perPage: action.payload.perPage
      }
    }),
    [`${ActionTypes.CORP_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      corpResult: action.payload,
      activeTab: 1
    }),
    [`${ActionTypes.SEARCH_All_GET}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        selectAllCorp: true
      }
    }),
    [`${ActionTypes.SEARCH_All_GET}_REJECTED`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        selectAllCorp: false
      }
    }),
    [`${ActionTypes.SEARCH_All_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      allSelectResult: action.payload,
      activeTab: 1,
      isLoading: {
        ...state.isLoading,
        selectAllCorp: false
      }
    }),
    [`${ActionTypes.INDUSTRY_SEARCH_All_GET}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        selectAllIndustry: true
      }
    }),
    [`${ActionTypes.INDUSTRY_SEARCH_All_GET}_REJECTED`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        selectAllIndustry: false
      }
    }),
    [`${ActionTypes.INDUSTRY_SEARCH_All_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      allSelectResult: action.payload,
      activeTab: 1,
      isLoading: {
        ...state.isLoading,
        selectAllIndustry: false
      }
    }),
    [`${ActionTypes.CORP_LIST_REQUEST}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: true
      }
    }),
    [`${ActionTypes.CORP_LIST_REQUEST}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: false
      }
    }),
    [`${ActionTypes.CORP_LIST_REQUEST}_FULFILLED`]: (state, action) => {
      const corpTypes = action.meta.concatenatedCorpFilterValue
        .split("#")
        .filter(code => code);
      const sources = action.meta.searchTargetDataFilterValue
        .split("#")
        .filter(code => code);
      const stateSources =
        state.corpFilter.searchTarget === "0"
          ? state.corpFilter.corpSource
          : state.corpFilter.articleSource;

      // 現在選択状態とあった結果だけを反映
      if (
        !isEqual(corpTypes, state.corpFilter.corpType) ||
        !isEqual(sources, stateSources)
      )
        return state;

      return {
        ...state,
        corpResult: action.payload,
        corpResultSummary: action.payload,
        sourceItems: mergeSourceItems(state.sourceItems, action.payload).map(
          item => ({
            ...item,
            selected: corpTypes.includes(item.inneyCode)
          })
        ),
        tabInfo: {
          ...state.tabInfo,
          corpCount: action.payload.totalCount
        },
        loadingInfo: {
          ...state.loadingInfo,
          isCorpSearching: false
        }
      };
    },

    [`${ActionTypes.INDUSTRY_LIST_REQUEST}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: true
      }
    }),
    [`${ActionTypes.INDUSTRY_LIST_REQUEST}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: false
      }
    }),

    [`${ActionTypes.INDUSTRY_LIST_REQUEST}_FULFILLED`]: (state, action) => {
      const sources = action.meta.industrySearchTarget
        .split("#")
        .filter(code => code);
      // 現在選択状態とあった結果だけを反映
      if (!isEqual(sources, state.industryFilter.source)) return state;

      return {
        ...state,
        industryResult: action.payload,
        industryResultSummary: action.payload,
        tabInfo: {
          ...state.tabInfo,
          industryCount: action.payload.totalCount
        },
        loadingInfo: {
          ...state.loadingInfo,
          isCorpSearching: false
        }
      };
    },

    [ActionTypes.CORP_RESULT_RESET]: state => ({
      ...state,
      tabInfo: {
        ...state.tabInfo,
        corpCount: "0"
      },
      corpResult: {
        ...state.corpResult,
        corpResultList: []
      },
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: false
      }
    }),
    [ActionTypes.INDUSTRY_RESULT_RESET]: state => ({
      ...state,
      tabInfo: {
        ...state.tabInfo,
        industryCount: "0"
      },
      industryResult: {
        ...state.industryResult,
        industryResultList: []
      },
      loadingInfo: {
        ...state.loadingInfo,
        isCorpSearching: false
      }
    }),
    [`${ActionTypes.INDUSTRY_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      industryResult: {
        ...state.industryResult,
        currentPage: action.payload.currentPage,
        totalCount: action.payload.totalCount,
        startNum: action.payload.startNum,
        endNum: action.payload.endNum,
        startNum: action.payload.startNum,
        startPageFlag: action.payload.startNum,
        endPageFlag: action.payload.endNum,
        industryInfo: action.payload.industryInfo
      },
      activeTab: 2
    }),
    [`${ActionTypes.DISCLOSURE_GET}_PENDING`]: state => ({
      ...state,
      isSearching: false,
      tabInfo: {
        ...state.tabInfo
      },
      loadingInfo: {
        ...state.loadingInfo,
        isDisclosureSearching: true
      }
    }),
    [`${ActionTypes.DISCLOSURE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      disclosureResult: action.payload,
      tabInfo: {
        ...state.tabInfo,
        dataCount: action.payload.totalCount
      },
      loadingInfo: {
        ...state.loadingInfo,
        isDisclosureSearching: false
      }
    }),
    [`${ActionTypes.DISCLOSURE_GET}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isDisclosureSearching: false
      }
    }),
    [`${ActionTypes.DISCLOSURE_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      disclosureResult: action.payload,
      activeTab: 3
    }),
    [`${ActionTypes.NEWS_GET}_PENDING`]: state => ({
      ...state,
      newsResult: { ...initialState.newsResult },
      tabInfo: {
        ...state.tabInfo
      },
      loadingInfo: {
        ...state.loadingInfo,
        isNewsSearching: true
      }
    }),
    [`${ActionTypes.NEWS_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      newsResult: action.payload,
      newsResultSummary: action.payload,
      tabInfo: {
        ...state.tabInfo,
        newsCount: action.payload.totalCount
      },
      loadingInfo: {
        ...state.loadingInfo,
        isNewsSearching: false
      }
    }),
    [`${ActionTypes.NEWS_GET}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isNewsSearching: false
      }
    }),
    [`${ActionTypes.INDUSTRY_GET}_PENDING`]: state => ({
      ...state,
      newsResult: { ...initialState.newsResult },
      tabInfo: {
        ...state.tabInfo
      },
      loadingInfo: {
        ...state.loadingInfo,
        isNewsSearching: true
      }
    }),
    [`${ActionTypes.INDUSTRY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      industryResult: action.payload,
      industryResultSummary: action.payload,
      tabInfo: {
        ...state.tabInfo,
        industryCount: action.payload.totalCount
      },
      loadingInfo: {
        ...state.loadingInfo,
        isIndustrySearching: false
      }
    }),
    [`${ActionTypes.INDUSTRY_GET}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isIndustrySearching: false
      }
    }),
    [`${ActionTypes.NEWS_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      newsResult: action.payload,
      activeTab: 4
    }),
    [`${ActionTypes.MAGAZINE_GET}_PENDING`]: state => ({
      ...state,
      tabInfo: {
        ...state.tabInfo
      },
      loadingInfo: {
        ...state.loadingInfo,
        isMagazineSearching: true
      }
    }),
    [`${ActionTypes.MAGAZINE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      magazineResult: action.payload,
      magazineResultSummary: action.payload,
      tabInfo: {
        ...state.tabInfo,
        magazineCount: action.payload.totalCount
      },
      loadingInfo: {
        ...state.loadingInfo,
        isMagazineSearching: false
      }
    }),
    [`${ActionTypes.MAGAZINE_GET}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isMagazineSearching: false
      }
    }),
    [`${ActionTypes.MAGAZINE_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      magazineResult: action.payload,
      activeTab: 5
    }),
    [`${ActionTypes.IR_GET}_PENDING`]: (state, action) => {
      return {
        ...state,
        tabInfo: {
          ...state.tabInfo
        },
        loadingInfo: {
          ...state.loadingInfo,
          isIRSearching: true
        },
        isSummaryMode: {
          ...state.isSummaryMode,
          irResult: action.meta.isSummaryMode || false
        }
      };
    },
    [`${ActionTypes.IR_GET}_FULFILLED`]: (state, action) => {
      const max = Math.min(
        parseInt(action.payload.keywordSeriesTotalCount, 10),
        parseInt(action.payload.maxSeriesTotalCount, 10)
      );
      return {
        ...state,
        irResult: {
          ...action.payload,
          industrySeriesList: action.payload.industrySeriesList.slice(0, max),
          keywordSeriesTotalCount: max.toString()
        },
        tabInfo: {
          ...state.tabInfo,
          irCount: max.toString()
        },
        loadingInfo: {
          ...state.loadingInfo,
          isIRSearching: false
        },
        isSummaryMode: {
          ...state.isSummaryMode,
          irResult: action.meta.isSummaryMode || false
        }
      };
    },
    [`${ActionTypes.IR_GET}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isIRSearching: false
      }
    }),
    [`${ActionTypes.STATISTICS_GET}_PENDING`]: state => ({
      ...state,
      tabInfo: {
        ...state.tabInfo
      },
      loadingInfo: {
        ...state.loadingInfo,
        isStatisticsSearching: true
      }
    }),
    [`${ActionTypes.STATISTICS_GET}_FULFILLED`]: (state, action) => {
      const max = Math.min(
        parseInt(action.payload.keywordSeriesTotalCount, 10),
        parseInt(action.payload.maxSeriesTotalCount, 10)
      );
      return {
        ...state,
        statisticsResult: {
          ...action.payload,
          kewordSeriesList: action.payload.kewordSeriesList.slice(0, max),
          keywordSeriesTotalCount: max.toString()
        },
        tabInfo: {
          ...state.tabInfo,
          macroCount: max.toString()
        },
        loadingInfo: {
          ...state.loadingInfo,
          isStatisticsSearching: false
        },
        isSummaryMode: {
          ...state.isSummaryMode,
          statisticsResult: action.meta.isSummaryMode || false
        }
      };
    },
    [`${ActionTypes.STATISTICS_GET}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isStatisticsSearching: false
      }
    }),
    [ActionTypes.SOURCE_ITEMS_SET]: (state, action) => {
      return {
        ...state,
        sourceItems: mergeSourceItems(
          action.payload.sourceItems,
          action.payload.corpCount
        )
      };
    },
    [`${ActionTypes.SEARCHLIST_FILTER_GET}_FULFILLED`]: (state, action) => {
      const corpSearchListFilterInfo = action.payload.corpInfoSearchTargetList;
      const articleSearchListFilterInfo =
        action.payload.articleSearchTargetList;
      return {
        ...state,
        corpInfos: {
          ...state.corpInfos,
          corpSearchListFilterInfo,
          articleSearchListFilterInfo,
          searchListCheckboxStates: isEmpty(
            state.corpInfos.searchListCheckboxStates
          )
            ? initSearchListCheckboxStates(corpSearchListFilterInfo)
            : state.corpInfos.searchListCheckboxStates
        },
        corpFilter: {
          ...state.corpFilter,
          corpSource: corpSearchListFilterInfo
            .filter(item => item.selected)
            .map(item => item.label),
          articleSource: articleSearchListFilterInfo
            .filter(item => item.selected)
            .map(item => item.label)
        }
      };
    },
    [`${ActionTypes.INDUSTRY_SEARCHLIST_FILTER_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const industrySearchListFilterInfo =
        action.payload.industrySearchTargetDataList;

      return {
        ...state,
        industryInfos: {
          ...state.industryInfos,
          industrySearchListFilterInfo,
          searchListCheckboxStates: isEmpty(
            state.industryInfos.searchListCheckboxStates
          )
            ? initSearchListCheckboxStates(industrySearchListFilterInfo)
            : state.industryInfos.searchListCheckboxStates
        },
        industryFilter: {
          ...state.industryFilter,
          source: industrySearchListFilterInfo
            .filter(item => item.selected)
            .map(item => item.label)
        }
      };
    },
    [ActionTypes.CORP_FILTER_UPDATE]: (state, action) => ({
      ...state,
      corpFilter: action.payload
    }),
    [ActionTypes.INDUSTRY_FILTER_UPDATE]: (state, action) => ({
      ...state,
      industryFilter: action.payload
    }),
    [ActionTypes.SEARCHLIST_FILTER_SELECTBOX_UPDATE]: (state, action) => ({
      ...state,
      corpInfos: {
        ...state.corpInfos,
        selectboxLabel: action.payload
      }
    }),
    [ActionTypes.CHECKED_CORP_CODES_UPDATE]: (state, action) => ({
      ...state,
      checkedCorpCodes: action.payload
    }),
    [ActionTypes.CHECKED_CORP_CODES_INIT]: state => ({
      ...state,
      checkedCorpCodes: ""
    }),
    [ActionTypes.CHECKED_INDUSTRY_CODES_UPDATE]: (state, action) => ({
      ...state,
      checkedIndustryCodes: action.payload
    }),
    [ActionTypes.CHECKED_INDUSTRY_CODES_INIT]: state => ({
      ...state,
      checkedIndustryCodes: ""
    }),
    [`${ActionTypes.CORP_INDUSTRY_TREE_MAP}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpIndustryTreeMapSearching: true
      }
    }),
    [`${ActionTypes.CORP_INDUSTRY_TREE_MAP}_FULFILLED`]: (state, action) => ({
      ...state,
      corpIndustryTreemapInfo: action.payload,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpIndustryTreeMapSearching: false
      }
    }),
    [`${ActionTypes.CORP_INDUSTRY_TREE_MAP}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCorpIndustryTreeMapSearching: false
      }
    }),
    [`${ActionTypes.BUBBLE_CHART_ASSOCIATED_ARTICLES_GET}_PENDING`]: (
      state,
      action
    ) => ({
      ...state,
      bubbleChartAssociatedArticles: {
        articles: null,
        timestamp: action.meta.timestamp
      }
    }),
    [`${ActionTypes.BUBBLE_CHART_ASSOCIATED_ARTICLES_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      bubbleChartAssociatedArticles:
        state.bubbleChartAssociatedArticles.timestamp === action.meta.timestamp
          ? action.payload
          : state.bubbleChartAssociatedArticles
    }),
    [`${ActionTypes.TREE_MAP_ASSOCIATED_ARTICLES_GET}_PENDING`]: (
      state,
      action
    ) => ({
      ...state,
      treeMapAssociatedArticles: {
        articles: null,
        timestamp: action.meta.timestamp
      }
    }),
    [`${ActionTypes.TREE_MAP_ASSOCIATED_ARTICLES_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      treeMapAssociatedArticles:
        state.treeMapAssociatedArticles.timestamp === action.meta.timestamp
          ? action.payload
          : state.treeMapAssociatedArticles
    }),
    [`${ActionTypes.CHECK_SEARCH_COLLECTION}_FULFILLED`]: (state, action) => ({
      ...state,
      collectionFilter: {
        ...state.collectionFilter,
        searchTargetList: action.payload.searchTargetList
      }
    }),
    [`${ActionTypes.COLLECTION_LIST_GET}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCollectionSearching: true
      },
      tabInfo: {
        ...state.tabInfo
      }
    }),
    [`${ActionTypes.COLLECTION_LIST_GET}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        collectionResult: action.payload,
        collectionResultSummary: action.payload,
        tabInfo: {
          ...state.tabInfo,
          collectionCount: countCollectionTotal(
            state.collectionFilter.searchTargetList,
            action.payload.count.personal,
            action.payload.count.nikkei
          )
        },
        loadingInfo: {
          ...state.loadingInfo,
          isCollectionSearching: false
        },
        collectionFilter: {
          ...state.collectionFilter,
          searchTargetList: addCountToTargetList(
            state.collectionFilter.searchTargetList,
            action.payload.count.personal,
            action.payload.count.nikkei
          )
        },
        isSummaryMode: {
          ...state.isSummaryMode,
          collectionResult: action.meta.isSummaryMode || false
        }
      };
    },
    [`${ActionTypes.GOVERNMENT_GET}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isGovernmentSearching: true
      },
      tabInfo: {
        ...state.tabInfo
      }
    }),
    [`${ActionTypes.GOVERNMENT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      governmentResult: action.payload,
      tabInfo: {
        ...state.tabInfo,
        governmentDataCount: action.payload.totalCount
      },
      loadingInfo: {
        ...state.loadingInfo,
        isGovernmentSearching: false
      }
    }),
    [`${ActionTypes.GOVERNMENT_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      governmentResult: action.payload,
      activeTab: 7
    }),
    [`${ActionTypes.NEWS_GET}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isGovernmentSearching: false
      }
    }),
    [`${ActionTypes.UPDATE_TARGET_VALUE}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCollectionSearching: true
      }
    }),
    [`${ActionTypes.UPDATE_TARGET_VALUE}_FULFILLED`]: (state, action) => {
      const targetList = action.meta.searchTargetFilterValue
        .split("#")
        .filter(code => code);
      const currentTargetList = state.collectionFilter.searchTargetList
        .filter(item => item.active && item.selected)
        .map(target => target.value);
      // 現在選択状態とあった結果だけを反映
      if (!isEqual(targetList, currentTargetList)) return state;

      return {
        ...state,
        collectionResult: action.payload,
        collectionResultSummary: action.payload,
        loadingInfo: {
          ...state.loadingInfo,
          isCollectionSearching: false
        },
        tabInfo: {
          ...state.tabInfo,
          collectionCount: countCollectionTotal(
            state.collectionFilter.searchTargetList,
            action.payload.count.personal,
            action.payload.count.nikkei
          )
        },
        collectionFilter: {
          ...state.collectionFilter,
          searchTargetList: addCountToTargetList(
            state.collectionFilter.searchTargetList,
            action.payload.count.personal,
            action.payload.count.nikkei
          )
        }
      };
    },
    [`${ActionTypes.SORT_COLLECTION}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCollectionSearching: true
      }
    }),
    [`${ActionTypes.SORT_COLLECTION}_FULFILLED`]: (state, action) => ({
      ...state,
      collectionResult: action.payload,
      loadingInfo: {
        ...state.loadingInfo,
        isCollectionSearching: false
      },
      tabInfo: {
        ...state.tabInfo,
        collectionCount: countCollectionTotal(
          state.collectionFilter.searchTargetList,
          action.payload.count.personal,
          action.payload.count.nikkei
        )
      },
      collectionFilter: {
        ...state.collectionFilter,
        searchTargetList: addCountToTargetList(
          state.collectionFilter.searchTargetList,
          action.payload.count.personal,
          action.payload.count.nikkei
        )
      }
    }),
    [`${ActionTypes.GET_COLLECTION_PAGE}_PENDING`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCollectionSearching: true
      }
    }),
    [`${ActionTypes.GET_COLLECTION_PAGE}_FULFILLED`]: (state, action) => ({
      ...state,
      collectionResult: action.payload,
      loadingInfo: {
        ...state.loadingInfo,
        isCollectionSearching: false
      },
      tabInfo: {
        ...state.tabInfo,
        collectionCount: countCollectionTotal(
          state.collectionFilter.searchTargetList,
          action.payload.count.personal,
          action.payload.count.nikkei
        )
      },
      collectionFilter: {
        ...state.collectionFilter,
        searchTargetList: addCountToTargetList(
          state.collectionFilter.searchTargetList,
          action.payload.count.personal,
          action.payload.count.nikkei
        )
      }
    }),
    [`${ActionTypes.GET_COLLECTION_PAGE}_REJECTED`]: state => ({
      ...state,
      loadingInfo: {
        ...state.loadingInfo,
        isCollectionSearching: false
      }
    }),
    [ActionTypes.UPDATE_COLLECTION_FILTER]: (state, action) => ({
      ...state,
      collectionFilter: action.payload
    }),
    [ActionTypes.TAB_INFO_SET]: (state, action) => ({
      ...state,
      tabInfo: {
        ...state.tabInfo,
        ...action.payload
      }
    }),
    [ActionTypes.SET_SUMMARY_ERROR]: (state, action) => ({
      ...state,
      summaryError: {
        ...state.summaryError,
        [action.payload.key]: action.payload.isError
      }
    }),
    [ActionTypes.CLEAR_SUMMARY_ERROR]: state => ({
      ...state,
      summaryError: {
        ...initialState.summaryError
      }
    }),
    [ActionTypes.SET_CORP_RESULT]: (state, action) => ({
      ...state,
      corpResult: action.payload.searchList01Dto,
      isSummaryMode: {
        ...state.isSummaryMode,
        corpResult: false,
        industryResult: false
      }
    })
  },
  initialState
);

export const actionCreators = {
  search: createAction(
    ActionTypes.SEARCH,
    params => {
      const requestParams = Object.assign({}, params);
      delete requestParams.abortController;

      const { fetchMethod, handleResponse } = abortablePostRequest(
        565,
        {
          ...requestParams,
          corpDisCount: initialState.corpFilter.perPage,
          industryDisCount: initialState.industryFilter.perPage
        },
        params.abortController
      );

      return fetchMethod().then(response => handleResponse(response));
    },
    (_, meta) => ({ ...meta })
  ),
  setAbortController: createAction(ActionTypes.SET_SEARCH_ABORTCONTROLLER),
  getTelecom: createAction(ActionTypes.TELECOM_GET, params =>
    request(566, params)
  ),
  setSummary: createAction(ActionTypes.SUMMARY_SET),
  sortCorp: createAction(ActionTypes.CORP_SORT, params => request(567, params)),
  updateCorpSort: createAction(ActionTypes.UPDATE_CORP_SORT, params => ({
    sort: params.corpSortIndex,
    perPage: params.corpDisCount
  })),
  getCorpPage: createAction(ActionTypes.CORP_PAGE_GET, params =>
    request(568, params)
  ),
  requestCorpList: createAction(
    ActionTypes.CORP_LIST_REQUEST,
    params => request(569, params),
    params => params
  ),
  requestIndustryList: createAction(
    ActionTypes.INDUSTRY_LIST_REQUEST,
    params => request(888, params, "POST"),
    params => params
  ),
  getIndustryPage: createAction(ActionTypes.INDUSTRY_PAGE_GET, params =>
    request(570, params)
  ),
  getDisclosure: createAction(
    ActionTypes.DISCLOSURE_GET,
    params => request(518, params),
    (_, meta) => ({ ...meta })
  ),
  getDisclosurePage: createAction(ActionTypes.DISCLOSURE_PAGE_GET, params =>
    request(519, params)
  ),
  downloadDisclosurePdf: createAction(
    ActionTypes.DISCLOSURE_PDF_DOWNLOAD,
    params => request(508, params)
  ),
  downloadDisclosureXbrl: createAction(
    ActionTypes.DISCLOSURE_XBRL_DOWNLOAD,
    params => request(509, params)
  ),
  downloadDisclosureAttach: createAction(
    ActionTypes.DISCLOSURE_ATTACH_DOWNLOAD,
    params => request(510, params)
  ),
  getNews: createAction(
    ActionTypes.NEWS_GET,
    params => request(540, params),
    (_, meta) => {
      return { ...meta };
    }
  ),
  getIndustry: createAction(
    ActionTypes.INDUSTRY_GET,
    params => request(890, params, "POST"),
    (_, meta) => {
      return { ...meta };
    }
  ),
  getNewsPage: createAction(ActionTypes.NEWS_PAGE_GET, params =>
    request(541, params)
  ),
  downloadNewsPdf: createAction(ActionTypes.NEWS_PDF_DOWNLOAD, params =>
    request(533, params)
  ),
  downloadNewsXbrl: createAction(ActionTypes.NEWS_XBRL_DOWNLOAD, params =>
    request(534, params)
  ),
  getMagazine: createAction(
    ActionTypes.MAGAZINE_GET,
    params => request(552, params),
    (_, meta) => ({ ...meta })
  ),
  getMagazinePage: createAction(ActionTypes.MAGAZINE_PAGE_GET, params =>
    request(549, params)
  ),
  downloadMagazinePdf: createAction(ActionTypes.MAGAZINE_PDF_DOWNLOAD, params =>
    request(546, params)
  ),
  downloadMagazineXls: createAction(ActionTypes.MAGAZINE_XLS_DOWNLOAD, params =>
    request(547, params)
  ),
  downloadMagazineXbrl: createAction(
    ActionTypes.MAGAZINE_XBRL_DOWNLOAD,
    params => request(548, params)
  ),
  getIR: createAction(
    ActionTypes.IR_GET,
    params => request(369, params),
    (_, meta) => ({ ...meta })
  ),
  getIRList: createAction(ActionTypes.IR_LIST_GET, params =>
    request(366, params)
  ),
  getStatistics: createAction(
    ActionTypes.STATISTICS_GET,
    params => request(464, params),
    (_, meta) => ({ ...meta })
  ),
  getStatisticsList: createAction(ActionTypes.STATISTICS_LIST_GET, params =>
    request(461, params)
  ),
  setSourceItems: createAction(ActionTypes.SOURCE_ITEMS_SET),
  getSearchListFilterInfo: createAction(
    ActionTypes.SEARCHLIST_FILTER_GET,
    params => request("/common/searchlist-filter", params)
  ),
  getIndustrySearchListFilterInfo: createAction(
    ActionTypes.INDUSTRY_SEARCHLIST_FILTER_GET,
    params => request("/common/industry-searchlist-filter", params)
  ),
  updateCorpFilter: createAction(ActionTypes.CORP_FILTER_UPDATE),
  updateIndustryFilter: createAction(ActionTypes.INDUSTRY_FILTER_UPDATE),
  updateSearchFilterSelectbox: createAction(
    ActionTypes.SEARCHLIST_FILTER_SELECTBOX_UPDATE
  ),
  updateCheckedCorpCodes: createAction(ActionTypes.CHECKED_CORP_CODES_UPDATE),
  initCheckedCorpCodes: createAction(ActionTypes.CHECKED_CORP_CODES_INIT),
  updateCheckedIndustryCodes: createAction(
    ActionTypes.CHECKED_INDUSTRY_CODES_UPDATE
  ),
  initCheckedIndustryCodes: createAction(
    ActionTypes.CHECKED_INDUSTRY_CODES_INIT
  ),
  getCorpIndustryTreeMap: createAction(
    ActionTypes.CORP_INDUSTRY_TREE_MAP,
    params => request(869, params)
  ),
  getBubbleChartAssociatedArticles: createAction(
    ActionTypes.BUBBLE_CHART_ASSOCIATED_ARTICLES_GET,
    params => request(870, params),
    (_, meta) => ({ ...meta })
  ),
  getTreeMapAssociatedArticles: createAction(
    ActionTypes.TREE_MAP_ASSOCIATED_ARTICLES_GET,
    params => request(870, params),
    (_, meta) => ({ ...meta })
  ),
  getSearchAll: createAction(ActionTypes.SEARCH_All_GET, params =>
    request("/searchlist/select-all", params)
  ),
  getIndustrySearchAll: createAction(
    ActionTypes.INDUSTRY_SEARCH_All_GET,
    params => request("/searchlist/industry/select-all", params, "POST")
  ),
  resetCorpResult: createAction(ActionTypes.CORP_RESULT_RESET),
  resetIndustryResult: createAction(ActionTypes.INDUSTRY_RESULT_RESET),
  checkSearchCollection: createAction(ActionTypes.CHECK_SEARCH_COLLECTION, () =>
    request(859)
  ),
  getCollectionList: createAction(
    ActionTypes.COLLECTION_LIST_GET,
    params => request(860, params),
    (_, meta) => ({ ...meta })
  ),
  getGovernment: createAction(ActionTypes.GOVERNMENT_GET, params =>
    request(866, params)
  ),
  getGovernmentPage: createAction(ActionTypes.GOVERNMENT_PAGE_GET, params =>
    request(867, params)
  ),
  updateTargetValue: createAction(
    ActionTypes.UPDATE_TARGET_VALUE,
    params => request(861, params),
    params => params
  ),
  sortCollection: createAction(ActionTypes.SORT_COLLECTION, params =>
    request(862, params)
  ),
  getCollectionPage: createAction(ActionTypes.GET_COLLECTION_PAGE, params =>
    request(863, params)
  ),
  updateCollectionFilter: createAction(ActionTypes.UPDATE_COLLECTION_FILTER),
  setTabInfo: createAction(ActionTypes.TAB_INFO_SET),
  setSummaryError: createAction(ActionTypes.SET_SUMMARY_ERROR),
  clearSummaryError: createAction(ActionTypes.CLEAR_SUMMARY_ERROR),
  setCorpResult: createAction(ActionTypes.SET_CORP_RESULT)
};
