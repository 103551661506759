export const setPersonalCardViewMode = (value: boolean) => ({
  type: "binders/list/SET_PERSONAL_CARD_VIEW_MODE" as const,
  payload: {
    value
  }
});

export const setNikkeiCardViewMode = (value: boolean) => ({
  type: "binders/list/SET_NIKKEI_CARD_VIEW_MODE" as const,
  payload: {
    value
  }
});

export const clearCardViewMode = () => ({
  type: "binders/list/CLEAR_CARD_VIEW_MODE" as const
});

export type BindersSavedAction = ReturnType<
  | typeof setPersonalCardViewMode
  | typeof setNikkeiCardViewMode
  | typeof clearCardViewMode
>;
