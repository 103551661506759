import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  items: [],
  sortOptions: [],
  mediaTabId: "",
  sort: "",
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      mediaTabId: action.payload.mediaTabId
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.LOAD_REPORT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      mediaTabId: action.payload.mediaTabId,
      sort: action.payload.sort
    }),
    [`${ActionTypes.OPTION_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      mediaTabId: action.payload.mediaTabId,
      sort: action.payload.sort
    }),
    [ActionTypes.SET_ITEMS]: (state, action) => ({
      ...state,
      items: action.payload
    }),
    [ActionTypes.SET_SORT_OPTIONS]: (state, action) => ({
      ...state,
      sortOptions: action.payload,
      sort: action.payload[0].value
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(188, params)),
  loadReport: createAction(
    ActionTypes.LOAD_REPORT,
    params => request(190, params) // ページング
  ),
  changeOption: createAction(
    ActionTypes.OPTION_CHANGE,
    params => request(189, params) // ソート・絞り込み
  ),
  setItems: createAction(ActionTypes.SET_ITEMS),
  setSortOptions: createAction(ActionTypes.SET_SORT_OPTIONS)
};
