import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  list: [],
  media: [],
  isLoading: false,
  result: null,
  insertButtonDisabledFlg: false,
  binderListRefetchFlg: false
};

export default handleActions(
  {
    [`${ActionTypes.BINDER_LIST_GET}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.BINDER_LIST_GET}_REJECTED`]: state => ({
      ...state,
      isLoading: false
    }),
    [`${ActionTypes.BINDER_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      list: action.payload.binderList,
      isLoading: false
    }),
    [`${ActionTypes.MEDIA_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      media: action.payload.mediaList
    }),
    [`${ActionTypes.CORP_ITEM_INSERT}_PENDING`]: state => ({
      ...state,
      insertButtonDisabledFlg: true
    }),
    [`${ActionTypes.CORP_ITEM_INSERT}_REJECTED`]: state => ({
      ...state,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.CORP_ITEM_INSERT}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload.result,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.INDUSTRY_ITEM_INSERT}_PENDING`]: state => ({
      ...state,
      insertButtonDisabledFlg: true
    }),
    [`${ActionTypes.INDUSTRY_ITEM_INSERT}_REJECTED`]: state => ({
      ...state,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.INDUSTRY_ITEM_INSERT}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload.result,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.GOVERNMENT_ITEM_INSERT}_PENDING`]: state => ({
      ...state,
      insertButtonDisabledFlg: true
    }),
    [`${ActionTypes.GOVERNMENT_ITEM_INSERT}_REJECTED`]: state => ({
      ...state,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.GOVERNMENT_ITEM_INSERT}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload.result,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.STATISTIC_ITEM_INSERT}_PENDING`]: state => ({
      ...state,
      insertButtonDisabledFlg: true
    }),
    [`${ActionTypes.STATISTIC_ITEM_INSERT}_REJECTED`]: state => ({
      ...state,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.STATISTIC_ITEM_INSERT}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload.result,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.AREA_ITEM_INSERT}_PENDING`]: state => ({
      ...state,
      insertButtonDisabledFlg: true
    }),
    [`${ActionTypes.AREA_ITEM_INSERT}_REJECTED`]: state => ({
      ...state,
      insertButtonDisabledFlg: false
    }),
    [`${ActionTypes.AREA_ITEM_INSERT}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload.result,
      insertButtonDisabledFlg: false
    }),
    [ActionTypes.BINDER_LIST_REFETCH_FLG_TOGGLE]: state => ({
      ...state,
      // 値が変更されたかを useEffect に通知するためのフラグのため反転させている
      binderListRefetchFlg: !state.binderListRefetchFlg
    })
  },
  initialState
);

export const actionCreators = {
  getBinderList: createAction(ActionTypes.BINDER_LIST_GET, params =>
    request("/common/binder", { ...params, binderFilter: "2" })
  ),
  getMediaList: createAction(ActionTypes.MEDIA_LIST_GET, params =>
    request("/binder/article-media", params)
  ),
  insertCorpItem: createAction(ActionTypes.CORP_ITEM_INSERT, params =>
    request("/binder/card/corp/save", params, "POST")
  ),
  insertIndustryItem: createAction(ActionTypes.INDUSTRY_ITEM_INSERT, params =>
    request("/binder/card/industry/save", params, "POST")
  ),
  insertArticleItem: createAction(ActionTypes.INDUSTRY_ITEM_INSERT, params =>
    request("/binder/card/saved-article/save", params, "POST")
  ),
  insertGovernmentItem: createAction(
    ActionTypes.GOVERNMENT_ITEM_INSERT,
    params => request("/binder/card/external-page/save", params, "POST")
  ),
  insertStatisticItem: createAction(ActionTypes.STATISTIC_ITEM_INSERT, params =>
    request("/binder/card/statistics/save", params, "POST")
  ),
  insertAreaItem: createAction(ActionTypes.AREA_ITEM_INSERT, params =>
    request("/binder/card/statistics/save", params, "POST")
  ),
  toggleBinderListRefetchFlg: createAction(
    ActionTypes.BINDER_LIST_REFETCH_FLG_TOGGLE
  )
};
