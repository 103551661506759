import { ToastAction } from "./actions";
import { Reducer } from "redux";
import { isEmpty } from "lodash";

export interface ToastState {
  messages: string[];
  errors: string[];
}

const initialState: ToastState = {
  messages: [],
  errors: []
};

export const toast: Reducer<ToastState, ToastAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "toast/MESSAGES_SET":
      const messages = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      return {
        ...state,
        messages: messages
      };
    case "toast/MESSAGES_CLEAR":
      // すでに空の場合に空配列で更新すると container が render されるため、
      // 対症療法的に state を返し render を発生させないようにする
      if (isEmpty(state.messages)) return state;

      return {
        ...state,
        messages: []
      };
    case "toast/ERRORS_SET":
      const errors = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      return {
        ...state,
        errors: errors
      };
    case "toast/ERRORS_CLEAR":
      // 対症療法的に state を返し render を発生させないようにする
      if (isEmpty(state.errors)) return state;

      return {
        ...state,
        errors: []
      };
    default:
      return state;
  }
};
