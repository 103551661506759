import { Reducer } from "redux";
import produce from "immer";

export interface OperandRawSearchModalState {
  isOpenHelpModal: boolean;
  isOpenHelpAddon: boolean;
}

export const initialState: OperandRawSearchModalState = {
  isOpenHelpModal: false,
  isOpenHelpAddon: false
};

export type Witch = "news" | "report" | "disclosure" | "collection";

export const operandRawSearchModal: Reducer<OperandRawSearchModalState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "operandRawSearchModal/HELP_MODAL":
      return produce(state, draft => {
        draft.isOpenHelpModal = action.payload.isOpenHelpModal;
      });
    case "operandRawSearchModal/HELP_ADDON":
      return produce(state, draft => {
        draft.isOpenHelpAddon = action.payload.isOpenHelpAddon;
      });
    default:
      return state;
  }
};
