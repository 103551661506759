// 企業アラート

// ----------------------------------------
// 設定状況
// ----------------------------------------

// 初期表示
export const INIT = "setting/personal/alert/corp/INIT";
// 一括ニュース・関連記事 設定項目を取得
export const MEDIA_SETTINGS_GET =
  "setting/personal/alert/corp/MEDIA_SETTINGS_GET";
// 一括ニュース・関連記事 選択状態を保存
export const MEDIA_CHECKED_SAVE =
  "setting/personal/alert/corp/MEDIA_CHECKED_SAVE";
// 企業の選択状態を更新
export const CHECKED_COMPANY_CODE_UPDATE =
  "setting/personal/alert/corp/CHECKED_COMPANY_CODE_UPDATE";
// 一括ニュース・関連記事 企業の選択状態を更新
export const ALL_COMPANY_MEDIA_CHECKED_UPDATE =
  "setting/personal/alert/corp/ALL_COMPANY_MEDIA_CHECKED_UPDATE";
// 個別ニュース・関連記事 企業の選択状態を更新
export const COMPANY_MEDIA_CHECKED_UPDATE =
  "setting/personal/alert/corp/COMPANY_MEDIA_CHECKED_UPDATE";
// 人事異動情報 企業の選択状態を更新
export const PERSONAL_INFO_CHECKED_UPDATE =
  "setting/personal/alert/corp/PERSONAL_INFO_CHECKED_UPDATE";
// 株価設定 企業の選択状態を更新
export const STOCK_PRICE_CHECKED_UPDATE =
  "setting/personal/alert/corp/STOCK_PRICE_CHECKED_UPDATE";
// 一括株価設定 企業の選択状態を更新
export const BATCH_STOCK_PRICE_CHECKED_UPDATE =
  "setting/personal/alert/corp/BATCH_STOCK_PRICE_CHECKED_UPDATE";
// 格付け情報 企業の選択状態を更新
export const RANK_INFO_CHECKED_UPDATE =
  "setting/personal/alert/corp/RANK_INFO_CHECKED_UPDATE";
// 適時開示 企業の選択状態を更新
export const TIMELY_DISCLOSURE_CHECKED_UPDATE =
  "setting/personal/alert/corp/TIMELY_DISCLOSURE_CHECKED_UPDATE";
// 大量保有報告 企業の選択状態を更新
export const LARGE_SHAREHOLDING_REPORT_CHECKED_UPDATE =
  "setting/personal/alert/corp/LARGE_SHAREHOLDING_REPORT_CHECKED_UPDATE";
// 企業を追加
export const COMPANY_ADD = "setting/personal/alert/corp/COMPANY_ADD";
// 企業を削除
export const COMPANY_DELETE = "setting/personal/alert/corp/COMPANY_DELETE";
// 個別株価アラート設定 設定を保存
export const STOCK_SETTINGS_SAVE =
  "setting/personal/alert/corp/STOCK_SETTINGS_SAVE";
// 一括株価アラート設定 設定を保存
export const ALL_STOCK_SETTINGS_SAVE =
  "setting/personal/alert/corp/ALL_STOCK_SETTINGS_SAVE";
// 企業アラート 設定を保存
export const ALERT_SAVE = "setting/personal/alert/corp/ALERT_SAVE";

// ----------------------------------------
// 企業選択
// ----------------------------------------

// 業界大分類を取得
export const NEEDS_MST_L_GET = "setting/personal/alert/corp/NEEDS_MST_L_GET";
// 業界中分類を取得
export const NEEDS_MST_M_GET = "setting/personal/alert/corp/NEEDS_MST_M_GET";
// 業界小分類を取得
export const NEEDS_MST_S_GET = "setting/personal/alert/corp/NEEDS_MST_S_GET";
// 企業を（業界分類から）取得
export const COMPANY_GET = "setting/personal/alert/corp/COMPANY_GET";
// 企業名から検索
export const COMPANY_SEARCH = "setting/personal/alert/corp/COMPANY_SEARCH";
// 最近見た企業を取得
export const RECENTLY_BROWSED_COMPANY_GET =
  "setting/personal/alert/corp/RECENTLY_BROWSED_COMPANY_GET";
// ブックマークフォルダを取得
export const BOOKMARK_FOLDER_GET =
  "setting/personal/alert/corp/BOOKMARK_FOLDER_GET";
// ブックマークを取得
export const BOOKMARK_GET = "setting/personal/alert/corp/BOOKMARK_GET";
// 企業選択をクリア
export const CORP_SELECTION_CLEAR =
  "setting/personal/alert/corp/CORP_SELECTION_CLEAR";
