import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";

const parseActivity = ({
  corpMaStrategy01Dto: {
    strategyDtoList: activityList = [],
    strategyCount = "0"
  } = {},
  startKenme = "0",
  endKenme = "0",
  sortKbn = "1",
  needsActiveContent: activeContent = "00",
  nikkeiNeedsActiveContentsList: activeContentList = [],
  ...data
} = {}) => {
  const sort = isEmpty(sortKbn) ? "1" : sortKbn;
  return {
    activityList,
    activeContent,
    activeContentList: activeContentList.map(({ label, value }) => ({
      label,
      value
    })),
    sort,
    count: parseInt(strategyCount, 10) || 0,
    start: parseInt(startKenme, 10) || 0,
    end: parseInt(endKenme, 10) || 0,
    dlpropriety: data.dlpropriety,
    dlxls: data.dlxls,
    dlxlspath: data.dlxlspath,
    dlcsv: data.dlcsv,
    dlcsvpath: data.dlcsvpath,
    nikkeiCode: data.corpIndustyCode,
    periodFrom: data.bakPeriodFrom,
    periodTo: data.bakPeriodTo,
    needsActiveContents: data.needsActiveContents,
    sortKbn
  };
};

const parseMaInformation = ({
  corpMaStrategy02Dto,
  maList: informationList = [],
  totalCount = "0",
  startNo = "0",
  endNo = "0",
  ...data
} = {}) => {
  if (!isEmpty(corpMaStrategy02Dto)) {
    // 与えられたのがM&A情報オブジェクトでない場合、
    // 配下のM&A情報オブジェクトを処理する
    return parseMaInformation(corpMaStrategy02Dto);
  }

  return {
    informationList,
    count: parseInt(totalCount, 10) || 0,
    start: parseInt(startNo, 10) || 0,
    end: parseInt(endNo, 10) || 0,
    transitionId: data.transitionId,
    pnaver: data.pnaver
  };
};

const parsePeriod = ({
  periodDto: {
    periodList = [{ seq: "", label: "-", inneyCode: "", value: "-" }],
    fromDate,
    toDate
  } = {}
} = {}) => {
  return {
    periodList,
    fromDate,
    toDate
  };
};

// エラーレスポンスのハンドラ
const errorHandler = (state, action) => ({
  ...state,
  error: action.payload
});

// state.data にレスポンスをすべて反映するパーサ
const uniteParser = (state, { payload }) => ({
  ...state,
  data: {
    ...payload,
    activity: parseActivity(payload),
    information: parseMaInformation(payload),
    periods: parsePeriod(payload)
  },
  // apiに表示件数パラメータが存在しないため表示件数をクリアする
  perPage: 10
});

// 企業活動情報のみのパーサ
const strategyParser = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    ...payload,
    activity: parseActivity(payload),
    periods: parsePeriod(payload)
  },
  perPage: !isEmpty(payload.dispCount) ? payload.dispCount : 10
});

// M&A情報のみのパーサ
const maParser = (state, { payload }) => ({
  ...state,
  data: {
    ...state.data,
    ...payload,
    information: parseMaInformation(payload)
  }
});

const telecomParser = (state, { payload }) => ({
  ...state,
  telecom: payload
});

const initialState = {
  data: {
    activity: parseActivity(),
    information: parseMaInformation(),
    periods: parsePeriod()
  },
  telecom: {},
  error: {},
  sortOptions: [],
  perPageOptions: [],
  perPage: 10,
  maPerPage: 10
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: uniteParser,
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: errorHandler,
    [`${ActionTypes.PERIOD_CHANGE}_FULFILLED`]: uniteParser,
    [`${ActionTypes.PERIOD_CHANGE}_REJECTED`]: errorHandler,
    [`${ActionTypes.TELECOM_GET}_FULFILLED`]: telecomParser,
    [`${ActionTypes.TELECOM_GET}_REJECTED`]: errorHandler,
    [`${ActionTypes.ACTIVE_CONTENT_CHANGE}_FULFILLED`]: strategyParser,
    [`${ActionTypes.ACTIVE_CONTENT_CHANGE}_REJECTED`]: errorHandler,
    [`${ActionTypes.STRATEGY_PAGER_CHANGE}_FULFILLED`]: strategyParser,
    [`${ActionTypes.SORT_OR_COUNT_CHANGE}_FULFILLED`]: strategyParser,
    [`${ActionTypes.SORT_OR_COUNT_CHANGE}_REJECTED`]: errorHandler,
    [`${ActionTypes.MA_PAGING}_FULFILLED`]: maParser,
    [`${ActionTypes.MA_PAGING}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      data: {
        ...state.data,
        information: {
          ...state.data.information,
          error: action.payload
        }
      }
    }),
    [ActionTypes.SET_SORT_OPTIONS]: (state, action) => ({
      ...state,
      sortOptions: action.payload
    }),
    [ActionTypes.SET_PER_PAGE_OPTIONS]: (state, action) => ({
      ...state,
      perPageOptions: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(167, params)),
  periodChange: createAction(ActionTypes.PERIOD_CHANGE, params =>
    request(168, params)
  ),
  telecom: createAction(ActionTypes.TELECOM_GET, params => request(21, params)),
  activeContentChange: createAction(ActionTypes.ACTIVE_CONTENT_CHANGE, params =>
    request(168, params)
  ),
  strategyChangPage: createAction(ActionTypes.STRATEGY_PAGER_CHANGE, params =>
    request(842, params)
  ),
  sortOrCountChange: createAction(ActionTypes.SORT_OR_COUNT_CHANGE, params =>
    request(177, params)
  ),
  maPaging: createAction(ActionTypes.MA_PAGING, params => request(174, params)),
  setSortOptions: createAction(ActionTypes.SET_SORT_OPTIONS),
  setPerPageOptions: createAction(ActionTypes.SET_PER_PAGE_OPTIONS)
};
