// 初期表示
export const PAGE_INIT = "corp/valuation/value/PAGE_INIT";
// 表示条件変更
export const PAGE_UPDATE = "corp/valuation/value/PAGE_UPDATE";
// 別の企業の表示(パンくず、ヘッダーのブックマーク・履歴などから企業を選択した場合)
export const PAGE_CHANGE = "corp/valuation/value/PAGE_CHANGE";
// 表示条件保存
export const SASVE_CONDITONS = "corp/valuation/value/SASVE_CONDITONS";
// 子ツリーの開閉トグル
export const TOGGLE = "corp/valuation/value/TOGGLE";
