import React from "react";

export default (loader, collection) =>
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = { Component: null, error: null };
    }

    componentDidMount() {
      if (!this.state.Component) {
        loader()
          .then(Component => this.setState({ Component }))
          .catch(error => {
            this.setState({ error });
          });
      }
    }

    render() {
      if (this.state.error) throw this.state.error;

      if (this.state.Component) {
        return <this.state.Component {...this.props} {...collection} />;
      }

      return null;
    }
  };
