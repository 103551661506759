import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  environmentalReport: [],
  isLoading: false,
  environmentPeriod: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      environmentPeriod: {
        from: action.payload.selectedAccFrom,
        to: action.payload.selectedAccTo
      },
      isLoading: false
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.PAGE_UPFATE}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.PAGE_UPFATE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      environmentPeriod: {
        from: action.payload.selectedAccFrom,
        to: action.payload.selectedAccTo
      },
      isLoading: false
    }),
    [`${ActionTypes.REPORT_LOAD}_FULFILLED`]: (state, action) => ({
      ...state,
      environmentalReport: action.payload,
      isLoading: false
    }),
    [ActionTypes.ENVIRONMENT_PERIOD_CHANGE]: (state, action) => ({
      ...state,
      environmentPeriod: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(65, params)),
  updatePage: createAction(ActionTypes.PAGE_UPFATE, params =>
    request(66, params)
  ),
  loadReport: createAction(ActionTypes.REPORT_LOAD, params =>
    request(71, params)
  ),
  changeEnvironmentPeriod: createAction(ActionTypes.ENVIRONMENT_PERIOD_CHANGE)
};
