import { TDB_INFO_SET } from "modules/corp/common/BreadCrumb/actionType";
import { PARAMS_SET } from "modules/corp/common/MainMenu/actionType";
import { isEmpty } from "utils/helper/common";

export default () => next => action => {
  if (
    action.type.indexOf("PAGE_INIT") >= 0 &&
    action.type.indexOf("_FULFILLED") >= 0 &&
    !isEmpty(action.payload)
  ) {
    // 同業企業比較画面で複数APIのリクエストを配列化しているので配列の場合0番目をチェック
    const payload = Array.isArray(action.payload)
      ? action.payload[0]
      : action.payload;

    // ぱんくずAPI用 TDBの場合に必要なパラメータをセット
    next({
      type: TDB_INFO_SET,
      payload: {
        tbdIndustryCodeList: isEmpty(payload.tbdIndustryCodeList)
          ? []
          : payload.tbdIndustryCodeList,
        tdbNkgnCodes: isEmpty(payload.tdbNkgnCodes) ? "" : payload.tdbNkgnCodes
      }
    });
    // メインメニューAPI用パラメータをセット
    next({
      type: PARAMS_SET,
      payload: {
        subContextPath: isEmpty(payload.subContextPath)
          ? ""
          : payload.subContextPath,
        nkgncodes: isEmpty(payload.nkgncodes) ? "" : payload.nkgncodes,
        menuId: isEmpty(payload.menuId) ? "" : payload.menuId,
        contentId: isEmpty(payload.tabContentId) ? "" : payload.tabContentId
      }
    });
  }

  return next(action);
};
