// 期種データ型
export interface ExtType {
  extA: string;
  extAFrom: string;
  extATo: string;
  extD: string;
  extDFrom: string;
  extDTo: string;
  extHy: string;
  extHyFrom: string;
  extHyTo: string;
  extM: string;
  extMFrom: string;
  extMTo: string;
  extQ: string;
  extQFrom: string;
  extQTo: string;
  extSa: string;
  extSaFrom: string;
  extSaTo: string;
  extW: string;
  extWFrom: string;
  extWTo: string;
  extY: string;
  extYFrom: string;
  extYTo: string;
  extFyFrom: string;
  extFyTo: string;
}

export interface AreaExtType {
  extCity: string;
  extCityFrom: string;
  extCityTo: string;
  extCountry: string;
  extCountryFrom: string;
  extCountryTo: string;
  extState: string;
  extStateFrom: string;
  extStateTo: string;
  extTokyo: string;
  extTokyoFrom: string;
  extTokyoTo: string;
  extTowns: string;
  extTownsFrom: string;
  extTownsTo: string;
}

// 期種リストの型
export type ExtStringType = "D" | "W" | "M" | "Q" | "Sa" | "Hy" | "A" | "Y";

export const isExtStringType = (arg: any): arg is ExtStringType => {
  if (typeof arg !== "string") return false;
  return ["D", "W", "M", "Q", "Sa", "Hy", "A", "Y"].includes(arg);
};

export interface MacroNode {
  childExistFlag: boolean;
  data: string[];
  schema: string;
  id: string;
}

export interface Series {
  areaExtFlg?: AreaExtType;
  extPeriodInfo?: AreaExtType;
  groupcd: string;
  orgdescj: string;
  schema: string;
  seriesdescj: string;
  termFrom: string;
  termTo: string;
}

export interface Area {
  areacode: string;
  seriesdescj: string;
}

export interface TransitionItem {
  area: string;
  areaFlg: AreaExtType;
  areadescj: string;
  extFlg: ExtType;
  label: string;
  orgdescj: string;
  schema: string;
  series: string;
  seriesdescj: string;
}

export interface CollectionItem {
  area: string;
  areaExtFlg: AreaExtType;
  areadescj: string;
  cardId: string;
  dataExistsFlg: boolean;
  extFlg: ExtType;
  label: string;
  orgdescj: string;
  schema: string;
  series: string;
  statisticsType: string;
}

export interface Binder {
  binderId: string;
  caption: string;
}

export interface DataType {
  label: string;
  seq: string;
  value: string;
}

export interface AreaData {
  macroArea: {
    dataTypeList: DataType[];
    workDay: string;
  };
  macroArea01: {
    areaJson: string;
    groupJson: string;
  };
}
