var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-utils-helper-replaceDescriptionUrl-style__link--3bZlI {\n  text-decoration: underline;\n  margin: 0 2px;\n  color: #00578e;\n}\n.src-utils-helper-replaceDescriptionUrl-style__link--3bZlI.src-utils-helper-replaceDescriptionUrl-style__external--oVVSD::after {\n  content: \"\";\n  display: inline-block;\n  width: 16px;\n  height: 17px;\n  background: url(" + escape(require("images/icon/ExrternalLink.svg")) + ") no-repeat center;\n  margin-left: 2px;\n  vertical-align: text-top;\n}\n\n.src-utils-helper-replaceDescriptionUrl-style__highlight--1YVQY {\n  padding: 4px;\n  background-color: #ccd7e1;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"link": "src-utils-helper-replaceDescriptionUrl-style__link--3bZlI",
	"external": "src-utils-helper-replaceDescriptionUrl-style__external--oVVSD",
	"highlight": "src-utils-helper-replaceDescriptionUrl-style__highlight--1YVQY"
};