import { Reducer } from "redux";
import produce from "immer";
import { DisplayItem, FilterItem } from "models/CorpScreening";
import { ToolScreeningCorpModernItemTreeAction } from "./actions";

interface ToolScreeningCorpItemTreeState {
  filterTree: FilterItem[];
  displayTree: DisplayItem[];
  isFilterTreeLoading: boolean;
}

const initialState: ToolScreeningCorpItemTreeState = {
  filterTree: [],
  displayTree: [],
  isFilterTreeLoading: false
};

export const toolScreeningCorpItemTree: Reducer<
  ToolScreeningCorpItemTreeState,
  ToolScreeningCorpModernItemTreeAction
> = (state = initialState, action) => {
  switch (action.type) {
    case "tool/screening/corp/modernItem/tree/SET_FILTER_TREE":
      return produce(state, draft => {
        draft.filterTree = action.payload;
      });
    case "tool/screening/corp/modernItem/tree/SET_DISPLAY_Tree":
      return produce(state, draft => {
        draft.displayTree = action.payload;
      });
    case "tool/screening/corp/modernItem/tree/SET_IS_FILTER_TREE_LOADING":
      return produce(state, draft => {
        draft.isFilterTreeLoading = action.payload;
      });
    default:
      return state;
  }
};
