import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const popupUrl = require("utils/json/popupUrl.json");

const initialState = {
  data: {},
  pdf: {},
  xls: {},
  xbrl: {},
  error: {},
  // ローダーの表示(ポップアップ専用)
  isPopupLoading: false,
  selectedOption: null
};

export default handleActions(
  {
    [`${ActionTypes.BODY_GET}_PENDING`]: state => ({
      ...state,
      isPopupLoading: true
    }),
    [`${ActionTypes.BODY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      isPopupLoading: false
    }),
    [`${ActionTypes.BODY_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isPopupLoading: false
    }),
    [`${ActionTypes.PDF_DOWNLOAD}_FULFILLED`]: (state, action) => ({
      ...state,
      pdf: action.payload
    }),
    [`${ActionTypes.PDF_DOWNLOAD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.XLS_DOWNLOAD}_FULFILLED`]: (state, action) => ({
      ...state,
      xls: action.payload
    }),
    [`${ActionTypes.XLS_DOWNLOAD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.XBRL_DOWNLOAD}_FULFILLED`]: (state, action) => ({
      ...state,
      xbrl: action.payload
    }),
    [`${ActionTypes.XBRL_DOWNLOAD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SET_BODY_TEXT}`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.SET_SELECTED_OPTION}`]: (state, action) => ({
      ...state,
      selectedOption: action.payload
    }),
    [`${ActionTypes.GET_TREND_REPORT_TEXT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: { ...action.payload, ...action.meta }
    }),
    [`${ActionTypes.GET_TREND_REPORT_TEXT}_REJECTED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.GET_BINDER_ARTICLE}_PENDING`]: state => ({
      ...state,
      isPopupLoading: true
    }),
    [`${ActionTypes.GET_BINDER_ARTICLE}_FULFILLED`]: (state, action) => {
      let data = {};
      switch (action.meta.articleKbn) {
        case "3":
          data = action.payload.cpfText;
          break;
        case "4":
          data = action.payload.trendReportText;
          break;
        default:
          data = action.payload.telecomText;
      }
      return {
        ...state,
        data,
        isPopupLoading: false
      };
    },
    [`${ActionTypes.GET_ARTICLE_PREVIEW}_PENDING`]: state => ({
      ...state,
      isPopupLoading: true
    }),
    [`${ActionTypes.GET_ARTICLE_PREVIEW}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        data: action.payload,
        isPopupLoading: false
      };
    }
  },
  initialState
);

export const actionCreators = {
  getBody: createAction(ActionTypes.BODY_GET, (params, path) =>
    request(popupUrl[path] && popupUrl[path].body, params)
  ),
  downloadPdf: createAction(ActionTypes.PDF_DOWNLOAD, (params, path) =>
    request(popupUrl[path].pdf, params)
  ),
  downloadXls: createAction(ActionTypes.XLS_DOWNLOAD, (params, path) =>
    request(popupUrl[path].xls, params)
  ),
  downloadXbrl: createAction(ActionTypes.XBRL_DOWNLOAD, (params, path) =>
    request(popupUrl[path].xbrl, params)
  ),
  setBodyText: createAction(ActionTypes.SET_BODY_TEXT, (bodyText, title) => {
    return {
      headline: title,
      mediaName: "",
      characterCount: "",
      printedDate: "",
      body: bodyText
    };
  }),
  setSelectedOption: createAction(ActionTypes.SET_SELECTED_OPTION),
  getBinderArticle: createAction(
    ActionTypes.GET_BINDER_ARTICLE,
    params => request("/binder/card/article/text", params, "GET", true),
    params => ({ articleKbn: params.articleKbn })
  ),
  getBinderArticleFreeText: createAction(
    ActionTypes.GET_BINDER_ARTICLE,
    params => request("/binder/card/article/free-text", params, "GET"),
    params => ({ articleKbn: params.articleKbn })
  ),
  getArticlePreview: createAction(ActionTypes.GET_ARTICLE_PREVIEW, params =>
    request(873, { articleId: params.articleId })
  )
};
