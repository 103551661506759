import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import {
  isNotEmptyKeys,
  valueFromKeyOrDefault,
  isEmpty,
  deepClone
} from "utils/helper/common";
import { formatComma } from "utils/helper/format";

const keyInfo = require("utils/json/dcfKeyInfo.json");

const initialState = {
  data: {},
  favoriteOptionList: [], // 条件セレクトボックス
  selectedFavoriteValue: "", // 条件セレクトボックス選択値
  favoriteList: [], // 登録した条件一覧表示用
  salesPopup: [], // 売上高変更ポップアップのデータ
  tempLastSalesPopup: [], // 売上高変更ポップアップ表示時に保持するデータ
  favoriteDetail: [], // 登録した条件反映用
  // 選択中の条件
  selectedIndexes: {
    sale: "0",
    wacc: "0",
    freeRate: "0",
    premium: "0",
    beta: "0",
    taxRate: "0",
    debt: "0",
    marketValue: "0",
    cost: "0",
    terminalValue: "0",
    growth: "0",
    shareholderCapitalCost: "0",
    pretaxDc: "0"
  },
  // パラメーター選択肢
  options: {
    sale: [],
    wacc: [],
    freeRate: [],
    premium: [],
    beta: [],
    taxRate: [],
    debt: [],
    marketValue: [],
    cost: [],
    terminalValue: [],
    growth: [],
    shareholderCapitalCost: [],
    pretaxDc: []
  },
  conditions: {
    from: "",
    to: ""
  },
  // ユーザが選択した表示期間
  selectedConditions: {
    from: "",
    to: ""
  },
  // ユーザー指定の値
  userInput: {
    sale: "",
    wacc: "",
    freeRate: "",
    premium: "",
    beta: "",
    taxRate: "",
    debt: "",
    marketValue: "",
    cost: "",
    terminalValue: "",
    growth: ""
  },
  // options初期値
  defaultOptions: {},
  // 条件保存時エラー
  saveFavoriteError: null,
  // FCFツリー項目
  tree: []
};

const createConditions = payload => {
  return {
    from: payload.fromTimeAcc,
    to: payload.toTimeAcc
  };
};

// ツリー項目の開閉処理
const toggleStatus = (tree, target) => {
  return tree.map(node => {
    const newNode = { ...node };
    if (newNode.id === target.id) {
      newNode.status = newNode.status === "1" ? "0" : "1";
    }
    if (!isEmpty(newNode.child)) {
      newNode.child = toggleStatus(newNode.child, target);
    }
    return newNode;
  });
};

const takeStatusOverFromPreviousTree = (prevTree, newTree) => {
  return newTree.map(parent => {
    const prevParent = prevTree.find(item => item.id === parent.id);
    parent.status = prevParent.status;
    return parent;
  });
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => {
      const options = buildOptions(action.payload);
      return {
        ...state,
        userInput: initialState.userInput,
        data: action.payload.corpValuationDCF01,
        salesPopup: action.payload.corpValuationDCF01.futureDcfDataAcc.map(
          row => formatComma(row.sales)
        ),
        favoriteOptionList: action.payload.conditionSaveList,
        selectedFavoriteValue: "",
        selectedIndexes: initialState.selectedIndexes,
        options: options,
        conditions: createConditions(action.payload),
        selectedConditions: createConditions(action.payload),
        defaultOptions: deepClone(options),
        tree: action.payload.corpValuationDCF01.treeDataList
      };
    },
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.CHECK_VALUE}_FULFILLED`]: state => ({
      ...state
    }),
    [`${ActionTypes.UPDATE_PAGE}_PENDING`]: state => ({
      ...state,
      favoriteDetail: []
    }),
    [`${ActionTypes.UPDATE_PAGE}_FULFILLED`]: (state, action) => {
      let tree = action.payload.treeDataList;
      if (!action.meta.isUpdateWithPeriod) {
        tree = takeStatusOverFromPreviousTree(state.tree, tree);
      }
      return {
        ...state,
        data: action.payload,
        salesPopup: action.payload.futureDcfDataAcc.map(row =>
          formatComma(row.sales)
        ),
        tree: tree
      };
    },
    [`${ActionTypes.LIST_BOX_CHANGE}_FULFILLED`]: (state, action) => {
      // ユーザ入力値の更新
      const userInput = {};
      const userSetParamValue = action.meta.userSetParamValue;
      userInput[action.meta.key] =
        userSetParamValue === "delete" ? "" : userSetParamValue;

      // 更新対象のプルダウンメニューはpayloadから取得
      return {
        ...state,
        options: {
          ...state.options,
          [action.meta.key]: [...action.payload]
        },
        userInput: {
          ...state.userInput,
          ...userInput
        }
      };
    },
    [`${ActionTypes.CHECK_FABORITE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 新しいお気に入りのID取得
      saveFavorite: {
        id: action.payload.favoliteId,
        name: action.payload.favoliteName
      }
    }),
    [`${ActionTypes.LOAD_FAVORITE}_FULFILLED`]: (state, action) => ({
      ...state,
      favoriteDetail: action.payload.favoliteDetailList,
      userInput: initialState.userInput, // クリア
      options: state.defaultOptions // リセット
    }),
    [`${ActionTypes.DELETE_FAVORITE}_FULFILLED`]: (state, action) => ({
      ...state,
      favoriteOptionList: action.payload.conditionSaveList,
      // 次の保存可能かのチェックのため空にする
      saveFavorite: {},
      // 選択中のお気に入りを削除した場合、選択項目を変更する
      selectedFavoriteValue:
        action.meta.deletedId === state.selectedFavoriteValue
          ? ""
          : state.selectedFavoriteValue
    }),
    [`${ActionTypes.SAVE_FAVORITE}_PENDING`]: state => {
      return {
        ...state,
        saveFavoriteError: null
      };
    },
    [`${ActionTypes.SAVE_FAVORITE}_FULFILLED`]: (state, action) => {
      let value = state.selectedFavoriteValue;
      if (action.payload.errorMessage.length === 0) {
        value =
          action.payload.conditionSaveValue === "NEW"
            ? action.payload.conditionSaveList[1].value
            : action.payload.conditionSaveValue;
      }

      return {
        ...state,
        favoriteOptionList: action.payload.conditionSaveList,
        selectedFavoriteValue: value,
        // 次の保存可能かのチェックのため空にする
        saveFavorite: {},
        saveFavoriteError: action.payload.errorMessage
      };
    },
    [`${ActionTypes.UPDATE_FAVORITE}_FULFILLED`]: (state, action) => ({
      ...state,
      favoriteOptionList: action.payload.conditionSaveList
    }),
    [`${ActionTypes.LOAD_FAVORITE_LIST}_FULFILLED`]: (state, action) => ({
      ...state,
      favoriteList: action.payload.corpValuationDcfFavoliteDtoList
    }),
    [`${ActionTypes.SALES_POPUP_CALCULATE}_FULFILLED`]: (state, action) => ({
      ...state,
      salesPopup: action.payload.futureDcfDataAcc.map(row =>
        formatComma(row.sales)
      )
    }),
    [ActionTypes.SALES_POPUP_CLEAR]: state => ({
      ...state,
      salesPopup: state.tempLastSalesPopup,
      tempLastSalesPopup: []
    }),
    [ActionTypes.SALES_POPUP_INPUT]: (state, action) => ({
      ...state,
      salesPopup: state.salesPopup.map((value, index) => {
        return index === action.payload.index ? action.payload.value : value;
      })
    }),
    [ActionTypes.SELECTED_CONDITION_CHANGE]: (state, action) => ({
      ...state,
      selectedIndexes: {
        ...state.selectedIndexes,
        ...action.payload
      }
    }),
    [ActionTypes.USER_OPTION_ADD]: (state, action) => {
      const addOptions = {};
      const userInput = {};
      Object.keys(action.payload).forEach(key => {
        const options = state.options[key];
        const value = action.payload[key];
        const unit = keyInfo[key].unit;
        const newOption = buildSelectList(options, value, unit);
        if (!isEmpty(newOption)) addOptions[key] = newOption;
        userInput[key] = value === "delete" ? "" : value;
      });
      return {
        ...state,
        options: {
          ...state.options,
          ...addOptions
        },
        userInput: {
          ...state.userInput,
          ...userInput
        }
      };
    },
    [ActionTypes.SAVE_CONDITONS]: (state, action) => ({
      ...state,
      conditions: action.payload
    }),
    [ActionTypes.FAVORITE_SELECTED_UPDATE]: (state, action) => ({
      ...state,
      selectedFavoriteValue: action.payload
    }),
    [ActionTypes.TEMP_LAST_SALES_POPUP_SET]: state => ({
      ...state,
      tempLastSalesPopup: state.salesPopup
    }),
    [ActionTypes.RESET_USER_SELECTED_VALUES]: state => ({
      ...state,
      options: state.defaultOptions,
      userInput: initialState.userInput,
      selectedIndexes: initialState.selectedIndexes,
      selectedFavoriteValue: initialState.selectedFavoriteValue
    }),
    [ActionTypes.UPDATE_STATUS]: (state, action) => {
      const target = action.payload;
      return {
        ...state,
        tree: toggleStatus(state.tree, target)
      };
    },
    [ActionTypes.SELECTED_CONDITIONS_UPDATE]: (state, action) => ({
      ...state,
      selectedConditions: createConditions(action.payload)
    })
  },
  initialState
);

export const actionCreators = {
  // 初期表示 /corp/valuation/dcf
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(284, params)),
  // 入力値チェック（STEP1,STEP2ユーザー指定時）
  // /corp/valuation/dcf/set-dcf-condition
  checkValue: createAction(ActionTypes.CHECK_VALUE, params =>
    request(289, {
      ...params,
      comBoId: "selsales"
    })
  ),
  // 選択値を表示内容に反映（セレクトボックス変更時・ユーザー指定時・STEP3計算ボタン押下時）
  // /corp/valuation/dcf/calculate
  updatePage: createAction(
    ActionTypes.UPDATE_PAGE,
    params => {
      const fixedParams = {
        paramIncomeIndex: 7,
        paramAfterTaxIndex: 0,
        paramWorkingCapitalIndex: 4,
        paramMoneyIndex: 3,
        paramDepreciationIndex: 3,
        paramFcfIndex: 0,
        dcfCaculateFlag: 0,
        salesGearingFlg: 1
      };
      return request(292, { ...fixedParams, ...params });
    },
    (params, isUpdateWithPeriod) => ({ isUpdateWithPeriod: isUpdateWithPeriod })
  ),
  changeListBox: createAction(
    ActionTypes.LIST_BOX_CHANGE,
    params => request(293, params),
    (p, meta) => meta
  ),
  // お気に入り保存数のチェック及びお気に入り名称の取得（条件を保存ボタン押下時(モーダルopen時)）
  // /corp/valuation/dcf/check-favolite
  checkFavorite: createAction(ActionTypes.CHECK_FABORITE, params =>
    request(294, params)
  ),
  // 選択したお気に入りに登録されている条件を取得(登録ずみ条件をセレクトボックスから選択時)
  // /corp/valuation/dcf/dcf-favolite
  loadFavorite: createAction(ActionTypes.LOAD_FAVORITE, params =>
    request(297, params)
  ),
  // 選択したお気に入り条件を削除し、削除後のお気に入り条件一覧を取得(名称変更モーダルで削除リンク押下時)
  // /corp/valuation/dcf/delete-favolite
  deleteFavorite: createAction(
    ActionTypes.DELETE_FAVORITE,
    params => request(298, params),
    params => ({ deletedId: params.favoliteId })
  ),
  // 画面の条件をお気に入りに保存(条件保存モーダルで保存ボタン押下時)
  // /corp/valuation/dcf/save-favolite
  saveFavorite: createAction(ActionTypes.SAVE_FAVORITE, params =>
    request(295, params)
  ),
  // お気に入り条件名を変更(名称変更モーダルで保存ボタン押下時)
  // /corp/valuation/dcf/update-favolite
  updateFavorite: createAction(ActionTypes.UPDATE_FAVORITE, params =>
    request(299, params)
  ),
  // お気に入りに保存した条件一覧を取得(名称変更削除モーダル初期表示時)
  // /corp/valuation/dcf/user-favolite-list
  loadFavoriteList: createAction(ActionTypes.LOAD_FAVORITE_LIST, params =>
    request(296, params)
  ),
  // 売上高選択ポップアップ計算
  // /corp/valuation/dcf/calculate-dcf
  calculateSalesPopup: createAction(ActionTypes.SALES_POPUP_CALCULATE, params =>
    request(288, params)
  ),
  // 売上高選択ポップアップ計算値クリア
  clearSalesPopup: createAction(ActionTypes.SALES_POPUP_CLEAR),
  // 売上高選択ポップアップ値変更
  inputSalesPopup: createAction(ActionTypes.SALES_POPUP_INPUT),
  // 選択中の条件変更
  changeSelect: createAction(ActionTypes.SELECTED_CONDITION_CHANGE),
  // ユーザ指定の選択肢追加
  addUserOption: createAction(ActionTypes.USER_OPTION_ADD),
  // 表示条件保存
  saveConditions: createAction(ActionTypes.SAVE_CONDITONS),
  // 登録した条件の選択値更新
  updateFavoriteSelected: createAction(ActionTypes.FAVORITE_SELECTED_UPDATE),
  // ダウンロードパス生成 /corp/valuation/dcf/excel-dcf-download
  buildDownloadPath: createAction(ActionTypes.DOWNLOAD_PATH_BUILD, params =>
    request(285, params)
  ),
  // 変更前の salesPopup を一時的に保存する
  setTempLastSalesPopup: createAction(ActionTypes.TEMP_LAST_SALES_POPUP_SET),
  // ユーザが設定した条件をリセット（期間はリセットしない）
  resetUserSeletedValues: createAction(ActionTypes.RESET_USER_SELECTED_VALUES),
  checkCalculateValue: createAction(ActionTypes.CALCULATE_VALUE_CHECK, params =>
    request(291, params)
  ),
  udpateStatus: createAction(ActionTypes.UPDATE_STATUS),
  // 表示期間の更新
  updateSelectedConditions: createAction(ActionTypes.SELECTED_CONDITIONS_UPDATE)
};

// 選択肢生成
const buildOptions = data => {
  const options = {};
  if (isNotEmptyKeys(data, ["corpValuationDCF01.treeDataList"])) {
    const sale = data.corpValuationDCF01.treeDataList.find(
      item => item.id === "sales"
    );

    if (sale === undefined) return options;

    options["sale"] = sale.comBoxData.map(item => ({
      label: item[1],
      value: item[0]
    }));
  } else {
    options["sale"] = [];
  }
  options["wacc"] = valueFromKeyOrDefault(data, "waccList", []);
  options["freeRate"] = valueFromKeyOrDefault(data, "riskFreeRateList", []);
  options["premium"] = valueFromKeyOrDefault(data, "riskFeeList", []);
  options["beta"] = valueFromKeyOrDefault(data, "lbList", []);
  options["taxRate"] = valueFromKeyOrDefault(data, "rateList", []);
  options["debt"] = valueFromKeyOrDefault(
    data,
    "corpValuationDCF01.interestObligationAmountSelect",
    []
  );
  options["marketValue"] = valueFromKeyOrDefault(
    data,
    "corpValuationDCF01.currentPriceAmountSelect",
    []
  );
  options["cost"] = valueFromKeyOrDefault(data, "dcList", []);
  options["terminalValue"] = valueFromKeyOrDefault(
    data,
    "terminalValueList",
    []
  );
  options["growth"] = valueFromKeyOrDefault(data, "fcfgList", []);
  options["shareholderCapitalCost"] = valueFromKeyOrDefault(
    data,
    "corpValuationDCF01.shareholderCapitalCostSelect",
    []
  );
  options["pretaxDc"] = valueFromKeyOrDefault(
    data,
    "corpValuationDCF01.pretaxDcSelect",
    []
  );
  return options;
};

// 指定済みセレクトリスト生成
const buildSelectList = (options, value, unit) => {
  let newOptions = options.concat();
  // セレクトボックスのchangeイベントを受けたケース
  // ユーザ指定オプションを削除する
  if (value === "delete") {
    const removeIndex = options.findIndex(
      option => option.value === "ユーザ指定済み"
    );
    if (removeIndex === -1) return options;
    newOptions.splice(removeIndex, 1);
    return newOptions;
  }

  // 登録条件から復元した場合
  // 登録条件の設定値からユーザ指定オプションを作成・追加する
  const description = value === "" ? "" : `${value}${unit}`;
  const newOption = {
    label: `ユーザ指定（${description}）`,
    value: "ユーザ指定済み"
  };

  const prevOptions = options.concat();
  const targetIndex = prevOptions.findIndex(
    option => option.value === "ユーザ指定済み"
  );
  if (targetIndex === -1) {
    // ユーザー指定済みがない場合は追加
    newOptions.splice(prevOptions.length - 1, 0, newOption);
  } else {
    // ユーザー指定済みがある場合は更新
    newOptions = prevOptions.map((option, index) =>
      index === targetIndex ? newOption : option
    );
  }

  return newOptions;
};
