import React from "react";
import { connect } from "react-redux";

import Usual from "./Implementation";

const UsualContainer = props => {
  return <Usual {...props} />;
};

const mapStateToProps = state => ({
  paymentConfirmFlg: state.reducers.settingPersonalSystem.paymentConfirmFlg,
  isPopupLoading: state.reducers.commonPopup.isPopupLoading
});

export default connect(mapStateToProps)(UsualContainer);
