import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { isEmpty, fillZero } from "utils/helper/common";
import { isArray } from "util";

const isUnique = arr =>
  !arr.some((v, i, whole) => whole.some((_v, _i) => i < _i && _v === v));

const initialState = {
  constants: {},
  chart: "",
  items: {},
  chartSeriesList: [],
  exports: {
    csvFilePath: "",
    xlsFilePath: ""
  },
  displayCorps: [],
  corpList: [],
  financialDataClassificationList: {},
  segmentDataClassificationList: {
    1: [],
    2: [],
    3: []
  },
  selectedFinancialDataClassification: {},
  selectedSegmentDataClassification: {},
  financialSeries: [],
  valuationSeries: [],
  segmentSeries: [],
  segmentPeriodList: [],
  irSeries: [],
  irSeriesDetail: [],
  error: {},
  period: {
    fromYear: "",
    toYear: "",
    fromYearList: [],
    toYearList: []
  },
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  companyList: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  isLoadingCompanyList: false,
  companyBySuggestList: [],
  latestviewCompanyList: [],
  bookmarkCompanyList: [],
  bookmarkFolderList: [],
  selectedFolderId: "",
  message: "",
  initialItems: {}
};

const distributePresentID = (series, index) => ({
  ...series,
  presentID: `${index + 1}`
});

const removeSpellingName = series => ({
  ...series,
  spellingName: ""
});

const initBindedAxisY = series => ({
  ...series,
  bindedAxisY: series.bindedAxisY !== "" ? series.bindedAxisY : "0"
});

const setGraphType = series => ({
  ...series,
  graphType: series.graphType !== "" ? series.graphType : series.barORLine
});

const formatAccs = ({ currAccType, currAccCase, ...series }) => ({
  ...series,
  currAccType: fillZero(currAccType, 2),
  currAccCase: fillZero(currAccCase, 2)
});

const initAxisAuto = axis => {
  axis.autoMaxVal = true;
  axis.autoMinVal = true;
  return axis;
};

const initNumValues = ({ items, ...rest }) => ({
  ...rest,
  items: {
    ...items,
    plotStyle: {
      ...items.plotStyle,
      markerSize: "",
      lineSize: "",
      barSize: ""
    },
    chartAxisYList: items.chartAxisYList.map(axis => ({
      ...axis,
      minVal: "",
      maxVal: "",
      step: "",
      bShowLogarithms: axis.bShowLogarithms ? axis.bShowLogarithms : "0",
      bShowName: "1"
    })),
    dlGraphSize: {
      ...items.dlGraphSize,
      width: "",
      height: ""
    }
  }
});

const setForecast = series => {
  return {
    ...series,
    forecast:
      series.forecast === "0" || series.forecast === "2"
        ? series.forecast
        : "1",
    LTM: series.LTM === "0" || series.LTM === "2" ? series.LTM : "1"
  };
};

// 異常な系列を落とす
// TODO: 落とす系列の条件精査
const validateSeries = s => !isEmpty(s.name);

export default handleActions(
  {
    [`${ActionTypes.CONST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      constants: action.payload
    }),
    [`${ActionTypes.ITEMS_GET}_FULFILLED`]: (state, action) =>
      initNumValues({
        ...state,
        items: {
          ...action.payload,
          chartSeriesList: action.payload.chartSeriesList
            .filter(validateSeries)
            .map(distributePresentID)
            .map(removeSpellingName)
            .map(setForecast)
            .map(initBindedAxisY)
            .map(formatAccs),
          backGround: {
            ...action.payload.backGround,
            bUseDefault: "0"
          },
          chartAxisYList: action.payload.chartAxisYList.map(initAxisAuto)
        },
        chartSeriesList: action.payload.chartSeriesList
          .filter(validateSeries)
          .map(distributePresentID)
          .map(removeSpellingName)
          .map(setForecast)
          .map(initBindedAxisY)
          .map(formatAccs),
        industryCompare: action.payload.industryCompare,
        competitionCompare: action.payload.competitionCompare
      }),
    [`${ActionTypes.DRAW_GRAPH}_FULFILLED`]: (state, action) => {
      if (action.payload.checkGraphDataIsExistFlg === "0") {
        return state;
      }
      return {
        ...state,
        items: {
          ...state.items,
          transitionType: action.payload.transitionType,
          businessDataType: {
            ...action.payload.businessDataType,
            fromYear: state.items.businessDataType.fromYear,
            toYear: state.items.businessDataType.toYear
          },
          chartAxisYList: action.payload.chartAxisYList,
          chartAxisX: action.payload.chartAxisX,
          chartSeriesList: action.payload.chartSeriesList
            .map(distributePresentID)
            .map(setGraphType)
        }
      };
    },
    [`${ActionTypes.CHART_UPDATE}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        chart: JSON.stringify(action.payload),
        items: {
          ...state.items,
          ...action.meta,
          transitionType: action.payload.transitionType
        }
      };
    },
    [`${ActionTypes.XLS_PATH_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      exports: {
        xlsFilePath: action.payload
      }
    }),
    [`${ActionTypes.CSV_PATH_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      exports: {
        csvFilePath: action.payload
      }
    }),
    [`${ActionTypes.CORP_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      corpList: action.payload
    }),
    [`${ActionTypes.SERIES_ADD}_FULFILLED`]: (state, action) => {
      const series = action.payload.chartSeriesList.map(s => {
        // unitName が未設定(-)の場合、百万円を設定する
        if (s.unitName === "─") s.unitName = "百万円";
        return s;
      });
      const selectedSeries = series.filter(
        selected =>
          !state.chartSeriesList.some(
            series =>
              series.companyCode === selected.companyCode &&
              series.iD === selected.iD &&
              series.dataCategoryValue3 === selected.dataCategoryValue3
          )
      );
      return {
        ...state,
        chartSeriesList: [...state.chartSeriesList, ...selectedSeries],
        items: {
          ...state.items,
          currChartType: "01"
        }
      };
    },
    [`${ActionTypes.SERIES_REMOVE}_FULFILLED`]: (state, action) => {
      const isLastSeriesOfTheCorp =
        action.payload.chartSeriesList.filter(
          series => series.nikkeiCode === action.meta.companyCode
        ).length === 0;
      const displayCorps = state.displayCorps.filter(
        corp => !isLastSeriesOfTheCorp || corp !== action.meta.companyCode
      );
      const corpList = state.corpList.filter(
        corp =>
          !isLastSeriesOfTheCorp || corp.companyCode !== action.meta.companyCode
      );
      return {
        ...state,
        chartSeriesList: action.payload.chartSeriesList,
        displayCorps,
        corpList,
        items: {
          ...state.items,
          currChartType: "01"
        }
      };
    },
    [ActionTypes.SERIES_REMOVE_ALL]: state => ({
      ...state,
      chartSeriesList: [],
      displayCorps: [...initialState.displayCorps],
      corpList: [...initialState.corpList],
      period: { ...initialState.period }
    }),
    [`${ActionTypes.FINANCIAL_DATA_CLASSIFICATION_1_INIT}_FULFILLED`]: (
      state,
      action
    ) => {
      const newFinancialDataClassificationList = {
        ...state.financialDataClassificationList
      };
      newFinancialDataClassificationList[1] = action.payload;
      const newSelectedDataClassification = {
        ...state.selectedFinancialDataClassification
      };
      newSelectedDataClassification[1] = action.payload[0].value; //データ分類１の初期値

      return {
        ...state,
        financialDataClassificationList: newFinancialDataClassificationList,
        selectedFinancialDataClassification: newSelectedDataClassification
      };
    },
    [`${ActionTypes.FINANCIAL_DATA_CLASSIFICATION_2_INIT}_FULFILLED`]: (
      state,
      action
    ) => {
      const newFinancialDataClassificationList = {
        ...state.financialDataClassificationList
      };
      newFinancialDataClassificationList[2] = action.payload;
      const newSelectedDataClassification = {
        ...state.selectedFinancialDataClassification
      };
      newSelectedDataClassification[2] = action.payload[0].value; //データ分類２の初期値

      return {
        ...state,
        financialDataClassificationList: newFinancialDataClassificationList,
        selectedFinancialDataClassification: newSelectedDataClassification
      };
    },
    [`${ActionTypes.FINANCIAL_DATA_CLASSIFICATION_2_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const newFinancialDataClassificationList = {
        ...state.financialDataClassificationList
      };
      newFinancialDataClassificationList[2] = action.payload;

      return {
        ...state,
        financialDataClassificationList: newFinancialDataClassificationList
      };
    },
    [`${ActionTypes.FINANCIAL_DATA_CLASSIFICATION_3_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const newFinancialDataClassificationList = {
        ...state.financialDataClassificationList
      };
      newFinancialDataClassificationList[3] = action.payload;

      return {
        ...state,
        financialDataClassificationList: newFinancialDataClassificationList
      };
    },
    [`${ActionTypes.SEGMENT_DATA_CLASSIFICATION_1_INIT}_FULFILLED`]: (
      state,
      action
    ) => {
      const newSegmentDataClassificationList = {
        ...state.segmentDataClassificationList
      };
      newSegmentDataClassificationList[1] = action.payload;

      const newSelectedDataClassification = {
        ...state.selectedSegmentDataClassification
      };
      newSelectedDataClassification[1] = action.payload[0].value; //データ分類１の初期値

      return {
        ...state,
        segmentDataClassificationList: newSegmentDataClassificationList,
        selectedSegmentDataClassification: newSelectedDataClassification
      };
    },
    [`${ActionTypes.SEGMENT_DATA_CLASSIFICATION_2_INIT}_FULFILLED`]: (
      state,
      action
    ) => {
      const newSegmentDataClassificationList = {
        ...state.segmentDataClassificationList
      };
      newSegmentDataClassificationList[2] = action.payload;

      const newSelectedDataClassification = {
        ...state.selectedSegmentDataClassification
      };
      newSelectedDataClassification[2] = action.payload[0].value; //データ分類２の初期値

      return {
        ...state,
        segmentDataClassificationList: newSegmentDataClassificationList,
        selectedSegmentDataClassification: newSelectedDataClassification
      };
    },
    [`${ActionTypes.SEGMENT_DATA_CLASSIFICATION_2_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const newSegmentDataClassificationList = {
        ...state.segmentDataClassificationList
      };
      newSegmentDataClassificationList[2] = action.payload;

      return {
        ...state,
        segmentDataClassificationList: newSegmentDataClassificationList
      };
    },
    [`${ActionTypes.SEGMENT_DATA_CLASSIFICATION_3_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const newSegmentDataClassificationList = {
        ...state.segmentDataClassificationList
      };
      newSegmentDataClassificationList[3] = action.payload.kamokuMeisyo;

      return {
        ...state,
        segmentDataClassificationList: newSegmentDataClassificationList,
        segmentSeries: action.payload.segmentNameList.map(series => ({
          seriesName: series
        })),
        segmentPeriodList: action.payload.segmentPeriodDto.period
      };
    },
    [`${ActionTypes.SEGMENT_DATA_CLASSIFICATION_3_PERIOD_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const newSegmentDataClassificationList = {
        ...state.segmentDataClassificationList
      };
      newSegmentDataClassificationList[3] = action.payload.kamokuMeisyo;

      return {
        ...state,
        segmentDataClassificationList: newSegmentDataClassificationList,
        segmentSeries: action.payload.segmentNameList.map(series => ({
          seriesName: series
        }))
      };
    },
    [`${ActionTypes.FINANCIAL_SERIES_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      financialSeries: action.payload.groupJson,
      valuationSeries: []
    }),
    [`${ActionTypes.VALUATION_SERIES_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      financialSeries: [],
      valuationSeries: action.payload
    }),
    [`${ActionTypes.SEGMENT_SERIES_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      segmentSeries: action.payload.segmentNameList.map(series => ({
        seriesName: series
      }))
    }),
    [`${ActionTypes.IR_SERIES_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      irSeries: action.payload.groupJson
    }),
    [ActionTypes.SELECTED_CORP_UPDATE]: (state, action) => {
      if (action.payload.filter(item => item.isSelected).length === 0) {
        // 企業が選択されていない場合、データ分類３と系列データを消す
        const financialDataClassificationList = {
          ...state.financialDataClassificationList
        };
        if (!isEmpty(financialDataClassificationList))
          financialDataClassificationList[3] = [];

        return {
          ...state,
          financialDataClassificationList: financialDataClassificationList,
          financialSeries: [],
          valuationSeries: [],
          corpList: action.payload
        };
      }

      return {
        ...state,
        corpList: action.payload
      };
    },
    [ActionTypes.CORP_REMOVE]: (state, action) => {
      if (isEmpty(action.payload)) return state;
      const target = isArray(action.payload)
        ? action.payload.map(corp => corp.companyCode)
        : [action.payload.companyCode];
      const segmentDataClassificationList = {
        ...state.segmentDataClassificationList,
        3: []
      };
      const chartSeriesList = state.chartSeriesList.filter(
        series => !target.includes(series.nikkeiCode)
      );
      return {
        ...state,
        corpList: [...state.corpList].filter(
          corp => !target.includes(corp.companyCode)
        ),
        displayCorps: [...state.displayCorps].filter(
          corp => !target.includes(corp)
        ),
        chartSeriesList,
        valuationSeries: [],
        financialSeries: [],
        segmentSeries: [],
        segmentDataClassificationList: segmentDataClassificationList,
        segmentPeriodList: [],
        irSeries: []
      };
    },
    [ActionTypes.FINANCIAL_SELECTED_SERIES_UPDATE]: (state, action) => ({
      ...state,
      financialSeries: action.payload
    }),
    [ActionTypes.VALUATION_SELECTED_SERIES_UPDATE]: (state, action) => ({
      ...state,
      valuationSeries: action.payload
    }),
    [ActionTypes.SEGMENT_SELECTED_SERIES_UPDATE]: (state, action) => ({
      ...state,
      segmentSeries: action.payload
    }),
    [ActionTypes.IR_SELECTED_SERIES_UPDATE]: (state, action) => ({
      ...state,
      irSeriesDetail: action.payload
    }),
    [ActionTypes.FINANCIAL_DATA_CLASSIFICATION_SELECT]: (state, action) => {
      if (action.payload.class === 1) {
        // データ分類１変更時はデータ系列２，３と企業のチェック状態をリセット
        const newCorpList = [...state.corpList].map(item => {
          item.isSelected = false;
          return item;
        });

        return {
          ...state,
          financialSeries: [],
          valuationSeries: [],
          corpList: newCorpList,
          financialDataClassificationList: {
            1: state.financialDataClassificationList[1],
            2: [],
            3: []
          },
          selectedFinancialDataClassification: {
            1: action.payload.value,
            2: "",
            3: ""
          }
        };
      }
      const newSelectedDataClassification = {
        ...state.selectedFinancialDataClassification
      };
      newSelectedDataClassification[action.payload.class] =
        action.payload.value;

      return {
        ...state,
        selectedFinancialDataClassification: newSelectedDataClassification
      };
    },
    [ActionTypes.SEGMENT_DATA_CLASSIFICATION_SELECT]: (state, action) => {
      const newSelectedDataClassification = {
        ...state.selectedSegmentDataClassification
      };
      newSelectedDataClassification[action.payload.class] =
        action.payload.value;

      return {
        ...state,
        selectedSegmentDataClassification: newSelectedDataClassification
      };
    },
    [`${ActionTypes.IR_SERIES_DETAIL_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      irSeriesDetail: action.payload.irdatainfoList
    }),
    [ActionTypes.PERIOD_CHANGE]: (state, action) => ({
      ...state,
      period: {
        ...state.period,
        ...action.payload
      }
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_PENDING`]: state => ({
      ...state,
      needsMstL: [],
      needsMstM: [],
      needsMstS: [],
      companyList: [],
      isLoadingNeedsMstL: true
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      needsMstM: [],
      needsMstS: [],
      companyList: [],
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      companyList: [],
      isLoadingNeedsMstM: true
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      companyList: [],
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      companyList: [],
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      companyList: [],
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.COMPANY_GET}_PENDING`]: state => ({
      ...state,
      companyList: [],
      isLoadingCompanyList: true
    }),
    [`${ActionTypes.COMPANY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      companyList: action.payload.companyList,
      isLoadingCompanyList: false
    }),
    [`${ActionTypes.COMPANY_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingCompanyList: false
    }),
    [`${ActionTypes.COMPANY_BY_SUGGEST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      companyBySuggestList: action.payload.companyList
    }),
    [`${ActionTypes.LATESTVIEW_COMPANY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      latestviewCompanyList: action.payload.companyList
    }),
    [`${ActionTypes.BOOKMARK_COMPANY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      selectedFolderId: action.meta,
      bookmarkCompanyList: action.payload.companyList
    }),
    [`${ActionTypes.SELECTED_BOOKMARK_FOLDER_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      bookmarkFolderList: action.payload.folderList,
      selectedFolderId: action.payload.selectedFolder.folderId
    }),
    [ActionTypes.DISPLAY_CORPS_UPDATE]: (state, action) => ({
      ...state,
      displayCorps: [...action.payload]
    }),
    [ActionTypes.ALL_RESET]: () => initialState,
    [ActionTypes.ITEMS_UPDATE]: (state, action) => ({
      ...state,
      items: {
        ...state.items,
        ...action.payload
      }
    }),
    [ActionTypes.SERIES_UPDATE]: (state, action) => ({
      ...state,
      chartSeriesList: action.payload
    }),
    [ActionTypes.MESSAGE_SET]: (state, action) => {
      return {
        ...state,
        message: action.payload
      };
    },
    [ActionTypes.MESSAGE_CLEAR]: state => ({
      ...state,
      message: ""
    })
  },
  initialState
);

export const actionCreators = {
  getConstants: createAction(ActionTypes.CONST_GET, params =>
    request(642, params)
  ),
  getItems: createAction(ActionTypes.ITEMS_GET, params => request(662, params)),
  updateGraph: createAction(
    ActionTypes.CHART_UPDATE,
    (params, orders) =>
      request(663, params).then(res => {
        // 系列表示順はクライアント側で対応する必要があるため、レスポンスを加工する
        // 初期読み込みや未記入は加工なし
        if (isEmpty(orders) || orders.every(v => v === "")) return res;
        // 重複や記入漏れが存在する場合エラー
        if (orders.some(v => v === "") || !isUnique(orders))
          throw new Error("系列表示順に重複または記入漏れがあります。");
        // TODO: インデックス外の指定は現行サイトが未対応のため対応を保留

        const chart = res.highChartsDto;
        const swapColor = seriesList => {
          const colors = seriesList.map(series => series.color);
          return orders.reduce((result, v, i) => {
            const realNum = v !== "" ? Number(v) - 1 : i;
            // 参照を変更してコピー
            const target = { ...seriesList[i] };
            target.color = colors[realNum];
            result[realNum] = target;

            return result;
          }, []);
        };
        if (chart.highChartsSeriesDtoList.length === orders.length) {
          chart.highChartsSeriesDtoList = swapColor(
            chart.highChartsSeriesDtoList
          );
        } else {
          chart.highChartsSeriesDtoList = chart.highChartsSeriesDtoList.map(
            series => ({
              ...series,
              highChartsSeriesDataDtoList: swapColor(
                series.highChartsSeriesDataDtoList
              )
            })
          );
        }
        return res;
      }),
    (params, orders, raw) => raw
  ),
  getXLS: createAction(ActionTypes.XLS_PATH_GET, params =>
    request(664, params)
  ),
  getCSV: createAction(ActionTypes.CSV_PATH_GET, params =>
    request(666, params)
  ),
  addSeries: createAction(ActionTypes.SERIES_ADD, params =>
    request(648, params)
  ),
  removeSeries: createAction(
    ActionTypes.SERIES_REMOVE,
    params => request(649, params),
    params => ({ companyCode: params.nikkeiCode })
  ),
  drawGraph: createAction(ActionTypes.DRAW_GRAPH, params =>
    request(650, params)
  ),
  getCorpList: createAction(ActionTypes.CORP_LIST_GET, params =>
    request(651, params)
  ),
  getCorpListValuation: createAction(
    ActionTypes.CORP_LIST_VALUATION_GET,
    params => request(652, params)
  ),

  /* 財務・バリュエーションタブ データ分類取得 */
  // データ分類取得系のAPIは部分的に共通しているが、レスポンス内容からはどの分類を取得したかを判別できないのでアクションを分けた
  // データ分類１初期表示
  initFinancialDataClassification1: createAction(
    ActionTypes.FINANCIAL_DATA_CLASSIFICATION_1_INIT,
    params => request(822, params)
  ),
  // データ分類２初期表示
  initFinancialDataClassification2: createAction(
    ActionTypes.FINANCIAL_DATA_CLASSIFICATION_2_INIT,
    params => request(822, params)
  ),
  // データ分類２取得
  getFinancialDataClassification2: createAction(
    ActionTypes.FINANCIAL_DATA_CLASSIFICATION_2_GET,
    params => request(653, params)
  ),
  // データ分類３取得
  getFinancialDataClassification3: createAction(
    ActionTypes.FINANCIAL_DATA_CLASSIFICATION_3_GET,
    params => request(653, params)
  ),

  /* セグメントタブ データ分類取得 */
  // データ分類１初期表示
  initSegmentDataClassification1: createAction(
    ActionTypes.SEGMENT_DATA_CLASSIFICATION_1_INIT,
    params => request(822, params)
  ),
  // データ分類２初期表示
  initSegmentDataClassification2: createAction(
    ActionTypes.SEGMENT_DATA_CLASSIFICATION_2_INIT,
    params => request(822, params)
  ),
  // データ分類２取得
  getSegmentDataClassification2: createAction(
    ActionTypes.SEGMENT_DATA_CLASSIFICATION_2_GET,
    params => request(653, params)
  ),
  // データ分類３取得(データ分類１変更、データ分類２変更、企業ラジオボタン変更時)
  getSegmentDataClassification3: createAction(
    ActionTypes.SEGMENT_DATA_CLASSIFICATION_3_GET,
    params => request(656, params)
  ),
  // データ分類３取得(期間変更時の場合)
  getSegmentDataClassification3WhenPeriodChange: createAction(
    ActionTypes.SEGMENT_DATA_CLASSIFICATION_3_PERIOD_GET,
    params => request(657, params)
  ),

  updateSelectedCorpList: createAction(ActionTypes.SELECTED_CORP_UPDATE),
  removeCorp: createAction(ActionTypes.CORP_REMOVE),
  updateSelectedFinancialSeries: createAction(
    ActionTypes.FINANCIAL_SELECTED_SERIES_UPDATE
  ),
  updateSelectedValuationSeries: createAction(
    ActionTypes.VALUATION_SELECTED_SERIES_UPDATE
  ),
  updateSelectedSegmentSeries: createAction(
    ActionTypes.SEGMENT_SELECTED_SERIES_UPDATE
  ),
  updateSelectedIrSeries: createAction(ActionTypes.IR_SELECTED_SERIES_UPDATE),
  getFinancialSeriesList: createAction(
    ActionTypes.FINANCIAL_SERIES_GET,
    params => request(654, params)
  ),
  getValuationSeriesList: createAction(
    ActionTypes.VALUATION_SERIES_GET,
    params => request(655, params)
  ),
  getSegmentSeriesList: createAction(ActionTypes.SEGMENT_SERIES_GET, params =>
    request(656, params)
  ),
  getIrSeriesList: createAction(ActionTypes.IR_SERIES_GET, params =>
    request(646, params)
  ),
  getIrSeriesDetail: createAction(ActionTypes.IR_SERIES_DETAIL_GET, params =>
    request(647, params)
  ),
  selectFinancialDataClassification: createAction(
    ActionTypes.FINANCIAL_DATA_CLASSIFICATION_SELECT
  ),
  selectSegmentDataClassification: createAction(
    ActionTypes.SEGMENT_DATA_CLASSIFICATION_SELECT
  ),
  changePeriod: createAction(ActionTypes.PERIOD_CHANGE),
  removeAllSeries: createAction(ActionTypes.SERIES_REMOVE_ALL),

  // 企業選択
  // SelectMenuProps
  getNeedsMstL: createAction(ActionTypes.NEEDS_MST_L_GET, params =>
    request(49, params)
  ),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(643, params)
  ),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(644, params)
  ),
  getCompany: createAction(ActionTypes.COMPANY_GET, params =>
    request(645, params)
  ),
  getCompanyBySuggest: createAction(
    ActionTypes.COMPANY_BY_SUGGEST_GET,
    params => request(658, params)
  ),
  getLatestviewCompany: createAction(
    ActionTypes.LATESTVIEW_COMPANY_GET,
    params => request(659, params)
  ),
  getBookmarkCompany: createAction(
    ActionTypes.BOOKMARK_COMPANY_GET,
    params => request(660, params),
    params => params.selectedFolder
  ),
  getSelectedBookmarkFolder: createAction(
    ActionTypes.SELECTED_BOOKMARK_FOLDER_GET,
    params => request(18, params)
  ),
  updateDisplayCorps: createAction(ActionTypes.DISPLAY_CORPS_UPDATE),
  reset: createAction(ActionTypes.ALL_RESET),
  updateItems: createAction(ActionTypes.ITEMS_UPDATE),
  updateSeries: createAction(ActionTypes.SERIES_UPDATE),
  setMessage: createAction(ActionTypes.MESSAGE_SET),
  clearMessage: createAction(ActionTypes.MESSAGE_CLEAR)
};
