import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { deepClone } from "utils/helper/common";

const initialState = {
  data: {},
  displayData: {},
  items: [],
  selectedItems: [],
  displayItems: [],
  conditions: {
    termToParam: "",
    termFromParam: "",
    extFlgParam: "",
    seriesdescjParam: "",
    seriesParam: ""
  },
  workDay: "",
  shouldConditionsInitialize: false
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      workDay: action.payload.corpir.workDay
    }),
    [`${ActionTypes.LOAD_DATA}_FULFILLED`]: (state, action) => ({
      ...state,
      displayData: action.payload,
      // 表示するデータ内容に合わせてstoreを更新
      selectedItems: deepClone(state.displayItems)
    }),
    [`${ActionTypes.LOAD_ITEMS}_FULFILLED`]: (state, action) => ({
      ...state,
      items: action.payload.irdatainfoList
    }),
    [ActionTypes.ADD_ITEMS]: (state, action) => ({
      ...state,
      selectedItems: deepClone(action.payload),
      displayItems: deepClone(action.payload)
    }),
    [ActionTypes.REPLACE_ITEMS]: (state, action) => ({
      ...state,
      displayItems: action.payload
    }),
    [ActionTypes.REMOVE_ALL_ITEMS]: state => ({
      ...state,
      selectedItems: []
    }),
    [ActionTypes.CONDITIONS_SET]: (state, action) => ({
      ...state,
      conditions: {
        ...state.conditions,
        ...action.payload
      }
    }),
    [ActionTypes.SHOULD_CONDITIONS_INITIALIZE_SET]: (state, action) => ({
      ...state,
      shouldConditionsInitialize: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(155, params)),
  loadData: createAction(ActionTypes.LOAD_DATA, params => request(157, params)),
  loadItems: createAction(ActionTypes.LOAD_ITEMS, params =>
    request(156, params)
  ),
  addItems: createAction(ActionTypes.ADD_ITEMS),
  replaceItems: createAction(ActionTypes.REPLACE_ITEMS),
  removeAllItems: createAction(ActionTypes.REMOVE_ALL_ITEMS),
  setConditions: createAction(ActionTypes.CONDITIONS_SET),
  setShouldConditionsInitialize: createAction(
    ActionTypes.SHOULD_CONDITIONS_INITIALIZE_SET
  )
};
