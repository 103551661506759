import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/suggest";

const initialState = {
  rawText: "",
  rawOperand: {
    and: "",
    or: "",
    not: ""
  },
  lastSearch: {
    rawText: "",
    rawOperand: {
      and: "",
      or: "",
      not: ""
    }
  },
  suggestions: [],
  suggestionActiveIndex: null,
  isOpenAdvancedSearch: false,
  isToggled: false,
  forceSearch: false,
  abortSuggetions: false
};

export default handleActions(
  {
    [ActionTypes.CHANGE_INPUT_RAW_TEXT]: (state, action) => ({
      ...state,
      rawText: action.payload.inputText
    }),
    [ActionTypes.CHANGE_INPUT_RAW_OPERAND]: (state, action) => ({
      ...state,
      rawOperand: action.payload.inputOperand
    }),
    [ActionTypes.CHANGE_LAST_SEARCH]: (state, action) => ({
      ...state,
      lastSearch: action.payload.lastSearch
    }),
    [ActionTypes.CLEAR_SUGGESTIONS]: state => ({
      ...state,
      suggestions: [],
      suggestionActiveIndex: null
    }),
    [ActionTypes.OPEN_ADVANCED_SEARCH]: state => ({
      ...state,
      isOpenAdvancedSearch: true
    }),
    [ActionTypes.CLOSE_ADVANCED_SEARCH]: state => ({
      ...state,
      isOpenAdvancedSearch: false
    }),
    [ActionTypes.TOGGLE_ADVANCED_SEARCH]: state => ({
      ...state,
      isOpenAdvancedSearch: !state.isOpenAdvancedSearch
    }),
    [ActionTypes.SET_IS_TOGGLE]: (state, action) => ({
      ...state,
      isToggled: action.payload
    }),
    [`${ActionTypes.REQUEST_SUGGESTIONS}_FULFILLED`]: (state, action) => {
      return !state.abortSuggetions
        ? {
            ...state,
            suggestions: action.payload,
            suggestionActiveIndex: null
          }
        : {
            ...state,
            suggestions: [],
            suggestionActiveIndex: null,
            abortSuggetions: false
          };
    },
    [ActionTypes.CURSOR_UP_SUGGESTION_ACTIVE_INDEX]: state => {
      if (state.suggestions.length === 0) return state;

      let nextActiveIndex = null;
      if (
        state.suggestionActiveIndex === null ||
        state.suggestionActiveIndex === 0
      ) {
        nextActiveIndex = state.suggestions.length - 1;
      } else if (state.suggestionActiveIndex > 0) {
        {
          nextActiveIndex = state.suggestionActiveIndex - 1;
        }
      }

      return {
        ...state,
        suggestionActiveIndex: nextActiveIndex
      };
    },
    [ActionTypes.CURSOR_DOWN_SUGGESTION_ACTIVE_INDEX]: state => {
      if (state.suggestions.length === 0) return state;

      let nextActiveIndex = null;
      if (
        state.suggestionActiveIndex === null ||
        state.suggestionActiveIndex === state.suggestions.length - 1
      ) {
        nextActiveIndex = 0;
      } else if (state.suggestionActiveIndex < state.suggestions.length - 1) {
        {
          nextActiveIndex = state.suggestionActiveIndex + 1;
        }
      }

      return {
        ...state,
        suggestionActiveIndex: nextActiveIndex
      };
    },
    [ActionTypes.UNSET_SUGGESTION_ACTIVE_INDEX]: state => ({
      ...state,
      suggestionActiveIndex: null
    }),
    [ActionTypes.SET_FORCE_SEARCH]: (state, action) => ({
      ...state,
      forceSearch: action.payload
    }),
    [ActionTypes.ABORT_SUGGESTIONS]: (state, action) => ({
      ...state,
      abortSuggetions: action.payload
    }),
    [ActionTypes.RESET_INPUT_TEXT]: state => ({
      ...initialState,
      lastSearch: state.lastSearch
    })
  },
  initialState
);

export const actionCreators = {
  changeInputRawText: createAction(
    ActionTypes.CHANGE_INPUT_RAW_TEXT,
    inputText => ({
      inputText
    })
  ),
  changeInputRawOperand: createAction(
    ActionTypes.CHANGE_INPUT_RAW_OPERAND,
    inputOperand => ({
      inputOperand
    })
  ),
  changeLastSearch: createAction(
    ActionTypes.CHANGE_LAST_SEARCH,
    lastSearch => ({
      lastSearch
    })
  ),
  clearSuggestions: createAction(ActionTypes.CLEAR_SUGGESTIONS),
  openAdvancedSearch: createAction(ActionTypes.OPEN_ADVANCED_SEARCH),
  closeAdvancedSearch: createAction(ActionTypes.CLOSE_ADVANCED_SEARCH),
  toggleAdvancedSearch: createAction(ActionTypes.TOGGLE_ADVANCED_SEARCH),
  setIsToggled: createAction(ActionTypes.SET_IS_TOGGLE),
  requestSuggestions: createAction(ActionTypes.REQUEST_SUGGESTIONS, params =>
    request(params)
  ),
  cursorUpSuggestionActiveIndex: createAction(
    ActionTypes.CURSOR_UP_SUGGESTION_ACTIVE_INDEX
  ),
  cursorDownSuggestionActiveIndex: createAction(
    ActionTypes.CURSOR_DOWN_SUGGESTION_ACTIVE_INDEX
  ),
  unsetSuggestionActiveIndex: createAction(
    ActionTypes.UNSET_SUGGESTION_ACTIVE_INDEX
  ),
  setAbortSuggestions: createAction(ActionTypes.ABORT_SUGGESTIONS),
  setForceSearch: createAction(ActionTypes.SET_FORCE_SEARCH),
  resetInputText: createAction(ActionTypes.RESET_INPUT_TEXT)
};
