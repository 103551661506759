import { getTokenAndId } from "utils/helper/localStorage";
import urls from "utils/json/url";
import methods from "utils/json/method";
import { getAccessContentId } from "utils/helper/accessContentId";
import { isEmpty, isIE } from "utils/helper/common";

const handleErrors = (status, body, path) => {
  let message = "UNHANDLED_ERROR";
  switch (status) {
    case 400:
      message = "BAD_REQUEST";
      break;
    case 401:
      message = "UNAUTHORIZED";
      break;
    case 500:
      message = "INTERNAL_SERVER_ERROR";
      break;
    case 502:
      message = "BAD_GATEWAY";
      break;
    case 504:
      message = "GATEWAY_TIMEOUT";
      break;
    case 404:
      message = "NOT_FOUND";
      break;
  }

  const error = new Error(message);
  error.body = body;
  error.path = path;
  return error;
};

const handleResponse = (response, path) => {
  return new Promise((resolve, reject) => {
    response
      .json()
      .then(json => {
        if (response.ok) resolve(json);
        const error = handleErrors(response.status, json, path);
        reject(error);
      })
      .catch(_ => {
        const error = handleErrors(response.status, "", path);
        reject(error);
      });
  });
};

/**
 * API リクエスト
 * @param {number|string} urlNumber URL番号 | URL
 * @param {object} params リクエストパラメーター
 * @param {string} method HTTP Method
 * @param {boolean} isDownload /download か
 * @returns {Promise} リクエストプロミス
 */
const request = (
  urlNumber,
  params = null,
  method = "GET",
  isDownload = false
) => {
  const num = `${urlNumber}`;
  const path = urls.hasOwnProperty(num) ? urls[num] : urlNumber;

  // 参照元を汚さないように新しいオブジェクトにする
  const p = Object.assign({}, params);

  // 閲覧履歴
  const accessContentId = getAccessContentId(num);
  if (!isEmpty(accessContentId)) {
    p["accessContentId"] = accessContentId;
  }

  // base URL
  const host = API_DOMAIN === "" ? location.host : API_DOMAIN;
  const apiPath =
    isDownload || methods["DOWNLOAD"].hasOwnProperty(num)
      ? API_DOWNLOAD_PATH
      : API_PATH;
  const url = `${IS_PROXY ? "http" : "https"}://${host}/${apiPath}${path}`;

  if (methods["POST"].hasOwnProperty(num)) {
    return post(url, p);
  }
  if (method === "POST") {
    return post(url, p);
  }

  return get(url, p, path);
};

const get = (urlStr, params, path) => {
  const searchParams = new URLSearchParams();
  const headers = new Headers();

  // IE のみ キャッシュしないよう設定
  if (isIE() && MODE === "production") {
    headers.append("If-Modified-Since", "Thu, 01 Jun 1970 00:00:00 GMT");
  }

  if (params != null && typeof params === "object") {
    Object.keys(params).forEach(key => {
      if (key === "tokenKey") return;
      if (Array.isArray(params[key])) {
        // パラメータが配列の場合
        params[key].forEach(item => {
          searchParams.append(key, item);
        });
      } else {
        searchParams.append(key, params[key]);
      }
    });
  }

  const { token, id } = getTokenAndId();

  if (!isEmpty(token)) {
    headers.append("x-nvs-token", token);
    searchParams.set("userId", id);
  }

  return fetch(`${urlStr}?${searchParams}`, { headers: headers })
    .catch(e => {
      throw Error(e);
    })
    .then(response => handleResponse(response, path));
};

const post = (url, params, path) => {
  const headers = new Headers({
    "Content-Type": "application/json; charset=utf-8"
  });

  // IE のみ キャッシュしないよう設定
  if (isIE() && MODE === "production") {
    headers.append("If-Modified-Since", "Thu, 01 Jun 1970 00:00:00 GMT");
  }

  const { token, id } = getTokenAndId();

  if (!isEmpty(token)) {
    headers.append("x-nvs-token", token);
    params["userId"] = id;
  }

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(params),
    headers: headers
  })
    .catch(e => {
      throw Error(e);
    })
    .then(response => handleResponse(response, path));
};

const buildCorpParams = input => {
  const defaultParams = {
    corpIndustyKbn: "",
    corpIndustyCode: "",
    corpType: "",
    corpStkCode: "",
    corpPlaceMarket: "",
    corpIndustryPath: "",
    corpno: ""
  };
  if (input === null || typeof input !== "object") return defaultParams;
  const target = { ...input };
  Object.keys(input).forEach(key => {
    if (
      input[key] === null ||
      Object.keys(defaultParams).find(defaultKey => defaultKey == key) ===
        undefined
    ) {
      delete target[key];
    }
  });
  return { ...defaultParams, ...target };
};

const abortablePostRequest = (urlNumber, params, abortController) => {
  const num = `${urlNumber}`;
  const path = urls.hasOwnProperty(num) ? urls[num] : urlNumber;

  // 参照元を汚さないように新しいオブジェクトにする
  const p = Object.assign({}, params);

  // 閲覧履歴
  const accessContentId = getAccessContentId(num);
  if (!isEmpty(accessContentId)) {
    p["accessContentId"] = accessContentId;
  }

  // base URL
  const host = API_DOMAIN === "" ? location.host : API_DOMAIN;
  const apiPath = methods["DOWNLOAD"].hasOwnProperty(num)
    ? API_DOWNLOAD_PATH
    : API_PATH;
  const url = `${IS_PROXY ? "http" : "https"}://${host}/${apiPath}${path}`;

  const headers = new Headers({
    "Content-Type": "application/json; charset=utf-8"
  });

  // IE のみ キャッシュしないよう設定
  if (isIE() && MODE === "production") {
    headers.append("If-Modified-Since", "Thu, 01 Jun 1970 00:00:00 GMT");
  }

  const { token, id } = getTokenAndId();

  if (!isEmpty(token)) {
    headers.append("x-nvs-token", token);
    p["userId"] = id;
  }

  const fetchMethod = () =>
    fetch(url, {
      method: "POST",
      body: JSON.stringify(p),
      headers: headers,
      signal: abortController.signal
    });

  return { fetchMethod, handleResponse };
};

export { request, buildCorpParams, abortablePostRequest };
