export const sourceSelectorItems = [
  {
    id: "CORPFILTER_CHECKBOXID_LISTED_CORP",
    labelId: "CORPFILTER_LABELID_LISTED_CORP",
    selected: false,
    corpCount: "0",
    active: true,
    haveAuthority: true,
    fsarea: "",
    value: "1",
    seq: "1",
    inneyCode: "1",
    label: "上場"
  },
  {
    id: "CORPFILTER_CHECKBOXID_SECURITYREPORTED_CORP",
    labelId: "CORPFILTER_LABELID_SECURITYREPORTED_CORP",
    selected: false,
    corpCount: "0",
    active: true,
    haveAuthority: true,
    fsarea: "",
    value: "2",
    seq: "2",
    inneyCode: "2",
    label: "有報"
  }
];

export const countTable = {
  CORPFILTER_CHECKBOXID_LISTED_CORP: "listingNkcodeCount", // 上場
  CORPFILTER_CHECKBOXID_SECURITYREPORTED_CORP: "unListedHolderNkcodeCount" // 非上場有報
};

export const selectTable = {
  CORPFILTER_CHECKBOXID_LISTED_CORP: "listingCorpSelect", // 上場
  CORPFILTER_CHECKBOXID_SECURITYREPORTED_CORP: "unlistedHolderCorpSelect" // 非上場有報
};
