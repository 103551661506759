import React from "react";
import styles from "./style.scss";

/**
 * チェックボックス
 *
 * @prop {string} id - input の id、一意である必要がある
 * @prop {string} name - input に指定する名前、ラジオボタンのグルーピングに必要
 * @prop {string} label - ラベル名
 * @prop {string} value - ラジオの値
 * @prop {?string} className - クラス名
 * @prop {?function} onSelected - 選択時 callback
 * @prop {?boolean} isActive - 活性ならtrue、非活性ならfalse
 * @prop {?boolean} isSelected - 選択状態ならtrue、非選択状態ならfalse
 * @prop {?boolean} uncontrolled - 状態管理を手動でするかどうか、デフォルトは false
 *                                 true にすると isSelected に順従する
 */

const defaultProps = {
  label: null,
  value: "",
  className: "",
  isActive: true,
  isSelected: false,
  onSelected: () => {},
  uncontrolled: false
};

const Radio = props => {
  const onChange = event => {
    event.stopPropagation();
    props.onSelected({
      label: props.label,
      value: props.value
    });
  };
  const radio = `${styles.radio} ${!props.isActive && styles.disable}`;
  return (
    <span
      className={`
        ${styles.component}
        ${props.className}
      `}
    >
      {props.uncontrolled ? (
        <input
          type="radio"
          id={props.id}
          name={props.name}
          value={props.value}
          className={radio}
          checked={props.isSelected}
          disabled={!props.isActive}
          onChange={onChange}
          data-testid="uncontrolled"
        />
      ) : (
        <input
          type="radio"
          id={props.id}
          name={props.name}
          value={props.value}
          className={radio}
          defaultChecked={props.isSelected}
          disabled={!props.isActive}
          onChange={onChange}
          data-testid="controlled"
        />
      )}
      <label
        htmlFor={props.id}
        className={`${!props.isActive && styles.disabledLabel}`}
      >
        {props.label}
      </label>
    </span>
  );
};

Radio.defaultProps = defaultProps;

export default Radio;
