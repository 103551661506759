import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  keyword: "",
  data: {},
  strategyData: {},
  itemPerPage: "",
  sortType: "",
  codeList: {
    hitPetternOptionList: [],
    placeCheckboxList: [],
    contentOptionList: [],
    addressOptionList: [],
    releaseDateOptionList: [],
    dispCountList: [],
    sortOptionList: []
  },
  error: null,
  // corpmodal
  corpList: [],
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  isLoading: false,
  isResultLoading: false
};

export default handleActions(
  {
    [ActionTypes.KEYWORD_SET]: (state, action) => ({
      ...state,
      keyword: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      strategyData: action.payload.ReportStrategyDto
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CODE_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      codeList: action.payload,
      itemPerPage: action.payload.dispCountList[0].value,
      sortType: action.payload.sortOptionList[0].value
    }),
    [`${ActionTypes.CODE_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.STRATEGY_DATA_SEARCH}_PENDING`]: state => ({
      ...state,
      // データ表示リスト系で選択中の値をリセット
      itemPerPage: state.codeList.dispCountList[0].value,
      sortType: state.codeList.sortOptionList[0].value,
      isResultLoading: true
    }),
    [`${ActionTypes.STRATEGY_DATA_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      strategyData: action.payload.ReportStrategyDto,
      isResultLoading: false
    }),
    [`${ActionTypes.STRATEGY_DATA_SEARCH}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isResultLoading: true
    }),
    [`${ActionTypes.STRATEGY_PAGING}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      strategyData: action.payload.ReportStrategyDto
    }),
    [`${ActionTypes.STRATEGY_PAGING}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.STRATEGY_DATA_SORT}_PENDING`]: (state, action) => ({
      ...state,
      itemPerPage: action.meta.itemPerPage
        ? action.meta.itemPerPage
        : state.itemPerPage,
      sortType: action.meta.sortType ? action.meta.sortType : state.sortType
    }),
    [`${ActionTypes.STRATEGY_DATA_SORT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      strategyData: action.payload.ReportStrategyDto
    }),
    [`${ActionTypes.STRATEGY_DATA_SORT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.GET_NEEDS_MST_L}_PENDING`]: state => ({
      ...state,
      needsMstL: [],
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstL: true,
      corpList: []
    }),
    [`${ActionTypes.GET_NEEDS_MST_L}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstL: false,
      corpList: []
    }),
    [`${ActionTypes.GET_NEEDS_MST_M}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstM: true,
      corpList: []
    }),
    [`${ActionTypes.GET_NEEDS_MST_M}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      isLoadingNeedsMstM: false,
      corpList: []
    }),
    [`${ActionTypes.GET_NEEDS_MST_S}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      isLoadingNeedsMstS: true,
      corpList: []
    }),
    [`${ActionTypes.GET_NEEDS_MST_S}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      isLoadingNeedsMstS: false,
      corpList: []
    }),
    [ActionTypes.INITIAL_LOADING_UPDATE]: (state, action) => ({
      ...state,
      isLoading: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  setKeyword: createAction(ActionTypes.KEYWORD_SET),
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(553, params)),
  getCodeList: createAction(ActionTypes.CODE_LIST_GET, params =>
    Promise.all([
      request(822, { gamenId: "N9050", groupId: "01", ...params }),
      request(822, { gamenId: "N9050", groupId: "06", ...params }),
      request(822, { gamenId: "N9050", groupId: "02", ...params }),
      request(822, { gamenId: "N9050", groupId: "05", ...params }),
      request(822, { gamenId: "N9050", groupId: "09", ...params }),
      request(822, { gamenId: "N9050", groupId: "08", ...params })
    ]).then(
      ([
        hitPetternOptionList,
        placeCheckboxList,
        contentOptionList,
        addressOptionList,
        dispCountList,
        sortOptionList
      ]) => ({
        hitPetternOptionList,
        placeCheckboxList,
        contentOptionList,
        addressOptionList,
        dispCountList,
        sortOptionList
      })
    )
  ),
  searchStrategyData: createAction(ActionTypes.STRATEGY_DATA_SEARCH, params =>
    request(554, params)
  ),
  paging: createAction(ActionTypes.STRATEGY_PAGING, params =>
    request("/report/strategy/page-change", { corpIndustyKbn: 6, ...params })
  ),
  sortStrategyData: createAction(
    ActionTypes.STRATEGY_DATA_SORT,
    params => request(561, { corpIndustyKbn: 6, ...params }),
    params => ({ itemPerPage: params.dispCount, sortType: params.sortKbn })
  ),
  getNeedsMstL: createAction(ActionTypes.GET_NEEDS_MST_L, () =>
    request(562, { corpIndustyKbn: 6 })
  ),
  getNeedsMstM: createAction(ActionTypes.GET_NEEDS_MST_M, params =>
    request(563, { corpIndustyKbn: 6, nkgncodel: params.nkgncodel })
  ),
  getNeedsMstS: createAction(ActionTypes.GET_NEEDS_MST_S, params =>
    request(564, { corpIndustyKbn: 6, nkgncodem: params.nkgncodem })
  ),
  updateInitialLoading: createAction(ActionTypes.INITIAL_LOADING_UPDATE)
};
