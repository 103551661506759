// 初期表示
export const PAGE_INIT = "industry/summary/PAGE_INIT";
// 「業界動向」「市場環境」「競争環境」「関連情報」データ取得
export const INDUSTRY_TEXT_GET = "industry/summary/INDUSTRY_TEXT_GET";
// 「日経速報ニュース・新聞記事」データ取得
export const NEWS_PAGE_GET = "industry/summary/NEWS_PAGE_GET";
// 業界内指標比較表 追加セレクトボックスデータ取得
export const INDUSTRY_ENTRT_LIST_GET =
  "industry/summary/INDUSTRY_ENTRT_LIST_GET";
// 業界内指標比較表選択状態を更新
export const COMPARISON_CONDITION_UPDATE =
  "industry/summary/COMPARISON_CONDITION_UPDATE";
// 業界内指標比較表ソート処理
export const SORT_COMPARISON_DATA = "industry/summary/SORT_COMPARISON_DATA";
// 業界内指標比較表削除処理
export const DELETE_ROW = "industry/summary/DELETE_ROW";
// ユーザが表示キャンセル時に表データをクリアする
export const SEARCH_DATA_CLEAR = "industry/summary/SEARCH_DATA_CLEAR";
// 表データの取得
export const LIST_CHANGE = "industry/summary/LIST_CHANGE";
// 業界内指標比較表ヘッダ情報取得
export const COMPARISON_HEAD_GET = "industry/summary/COMPARISON_HEAD_GET";
// 業界内指標比較表明細情報取得
export const COMPARISON_DATA_GET = "industry/summary/COMPARISON_DATA_GET";
// 再検索フラグをセット／リセット
export const SEARCH_FLAG_SET = "industry/summary/SEARCH_FLAG_SET";
export const SEARCH_FLAG_RESET = "industry/summary/SEARCH_FLAG_RESET";
// ソート状態を解除
export const SORT_INFO_UNSET = "industry/summary/SORT_INFO_UNSET";
// 散布図選択件数の上限値を取得
export const CHART_COUNT_GET = "industry/summary/CHART_COUNT_GET";
// チャート情報を取得
export const CHART_DATA_GET = "industry/summary/CHART_DATA_GET";
// チャート情報更新
export const CHART_DATA_UPDATE = "industry/summary/CHART_DATA_UPDATE";
// チャート情報削除
export const CHART_DATA_CLEAR = "industry/summary/CHART_DATA_CLEAR";
// 指標ツリーのルート階層を取得
export const INDEX_TREE_ROOT_GET = "industry/summary/INDEX_TREE_ROOT_GET";
// 指標ツリーの子ノードを取得
export const INDEX_TREE_CHILD_GET = "industry/summary/INDEX_TREE_CHILD_GET";
// 指標を検索条件に追加できるかを取得
export const INDEX_AVAILABILITY_GET = "industry/summary/INDEX_AVAILABILITY_GET";
// 指標を検索条件に追加
export const INDEX_ADD = "industry/summary/INDEX_ADD";
// 指標の選択状態を更新
export const INDEX_UPDATE = "industry/summary/INDEX_REMOVE";
// 指標を検索条件からすべて削除
export const INDEX_REMOVE_ALL = "industry/summary/INDEX_REMOVE_ALL";
// 保存した検索条件セレクトボックスの中身取得
export const SAVED_CONDITION_SELECT_GET =
  "industry/summary/SAVED_CONDITION_SELECT_GET";
// 保存した検索条件一覧取得
export const SAVED_CONDITION_GET = "industry/summary/SAVED_CONDITION_GET";
// 保存した検索条件の削除
export const SAVED_CONDITION_DELETE = "industry/summary/SAVED_CONDITION_DELETE";
// 保存した検索条件の更新
export const SAVED_CONDITION_UPDATE = "industry/summary/SAVED_CONDITION_UPDATE";
// 保存した検索条件の詳細取得
export const SAVED_CONDITION_DETAIL_GET =
  "industry/summary/SAVED_CONDITION_DETAIL_GET";
// 保存した検索条件の取得
export const CONDITION_SET = "industry/summary/CONDITION_SET";
// 指標編集系の全てデータをクリアする
export const INDEX_ALL_CLEAR = "industry/summary/INDEX_ALL_CLEAR";
// ダウンロード時の期間セレクトボックス情報取得
export const DOWNLOAD_PERIOD_GET = "industry/summary/DOWNLOAD_PERIOD_GET";
export const DOWNLOAD_PERIOD_SET = "industry/summary/DOWNLOAD_PERIOD_SET";
