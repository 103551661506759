import React from "react";
import styles from "./style.scss";

const MaStrategy = props => {
  return (
    <div
      className={styles.body}
      data-testid="Common-Popup-MaStrategy"
      dangerouslySetInnerHTML={{ __html: props.body }}
    />
  );
};

export default MaStrategy;
