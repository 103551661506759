import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

const initialState = {
  industry: {
    sectorReportInfoModalToggle: false
  }
};

export default handleActions(
  {
    [ActionTypes.INDUSTRY_SECTOR_REPORT_INFO_MODAL_OPEN]: state => ({
      ...state,
      industry: {
        ...state.industry,
        sectorReportInfoModalToggle: true
      }
    }),
    [ActionTypes.INDUSTRY_SECTOR_REPORT_INFO_MODAL_CLOSE]: state => ({
      ...state,
      industry: {
        ...state.industry,
        sectorReportInfoModalToggle: false
      }
    })
  },
  initialState
);

export const actionCreators = {
  openSectorReportInfoModal: createAction(
    ActionTypes.INDUSTRY_SECTOR_REPORT_INFO_MODAL_OPEN
  ),
  closeSectorReportInfoModal: createAction(
    ActionTypes.INDUSTRY_SECTOR_REPORT_INFO_MODAL_CLOSE
  )
};
