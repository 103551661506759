import { Response } from "models/api/Login";
import {
  SessionState,
  OptionInfo,
  LoginUserData,
  LoginError
} from "models/Auth";

export const setSessionState = (sessionState: SessionState) => ({
  type: "auth/temporary/SET_SESSION_STATE" as const,
  payload: { sessionState }
});

export const setLoginInfo = (response: Response) => ({
  type: "auth/temporary/SET_LOGIN_INFO" as const,
  payload: {
    notices: response.noticemanagerlist,
    nidAuthenticated: response.nidAuthenticated !== "",
    contractorId: response.contractorId,
    contractorName: response.contractorName
  }
});

export const setIsAuthorizing = (isAuthorizing: boolean) => ({
  type: "auth/temporary/SET_IS_AUTHORIZING" as const,
  payload: { isAuthorizing }
});

export const setIsLoading = (isLoading: boolean) => ({
  type: "auth/temporary/SET_IS_LOADING" as const,
  payload: { isLoading }
});

export const setLoginOptions = (options: OptionInfo[]) => ({
  type: "auth/temporary/SET_LOGIN_OPTIONS" as const,
  payload: options
});

export const clearLoginOptions = () => ({
  type: "auth/temporary/CLEAR_LOGIN_OPTIONS" as const
});

export const setIsMaximum = (isMaximum: boolean) => ({
  type: "auth/temporary/SET_IS_MAXIMUM" as const,
  payload: { isMaximum }
});

export const setLoginUsers = (users: LoginUserData[]) => ({
  type: "auth/temporary/SET_LOGIN_USERS" as const,
  payload: { users }
});

export const setIsLogoutLoading = (isLogoutLoading: boolean) => ({
  type: "auth/temporary/SET_IS_LOGOUT_LOADING" as const,
  payload: { isLogoutLoading }
});

export const setLogoutError = (logoutError: string | null) => ({
  type: "auth/temporary/SET_LOGOUT_ERROR" as const,
  payload: { logoutError }
});

export const setError = (errors: LoginError) => ({
  type: "auth/temporary/SET_ERROR" as const,
  payload: errors
});

export const clearError = () => ({
  type: "auth/temporary/CLEAR_ERROR" as const
});

export type AuthTemporaryAction = ReturnType<
  | typeof setSessionState
  | typeof setLoginInfo
  | typeof setIsAuthorizing
  | typeof setLoginOptions
  | typeof clearLoginOptions
  | typeof setIsLoading
  | typeof setIsMaximum
  | typeof setLoginUsers
  | typeof setIsLogoutLoading
  | typeof setLogoutError
  | typeof setError
  | typeof clearError
>;
