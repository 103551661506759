import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  // 表示中の金融機関別借入金データ
  data: {},
  // 期間、チェックボックスの状態
  conditions: {
    csdbt: null,
    cldbt: null,
    cdbt: null,
    cdbtPer: null,
    from: "",
    to: ""
  },
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...action.payload,
        // 表示している期間(from, to)の保存
        // ページ初期化時は期間の指定がないのでデフォルト値を設定しておく
        from: action.meta.from
          ? action.meta.from
          : action.payload.financialLoanPeriodDto.defaultFromAcc,
        to: action.meta.to
          ? action.meta.to
          : action.payload.financialLoanPeriodDto.defaultToAcc
      },
      // 条件の選択状態を初期化
      conditions: {
        csdbt: action.payload.csdbt,
        cldbt: action.payload.cldbt,
        cdbt: action.payload.cdbt,
        cdbtPer: action.payload.cdbtPer,
        from: action.meta.from
          ? action.meta.from
          : action.payload.financialLoanPeriodDto.defaultFromAcc,
        to: action.meta.to
          ? action.meta.to
          : action.payload.financialLoanPeriodDto.defaultToAcc
      }
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITIONS_UPDATE}`]: (state, action) => ({
      ...state,
      conditions: {
        ...state.conditions,
        ...action.payload
      }
    }),
    [ActionTypes.DATA_RESET]: state => ({
      ...state,
      data: initialState.data
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => request(25, params),
    // APIからのレスポンスには表示中の期間の情報がないのでリクエストパラメータをmetaにいれる
    params => ({
      from: params.accPeriodFrom,
      to: params.accPeriodTo
    })
  ),
  updateConditions: createAction(ActionTypes.CONDITIONS_UPDATE),
  resetData: createAction(ActionTypes.DATA_RESET)
};
