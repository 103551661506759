exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Popup-Common-RelatedLinks-style__component--2JGNW:not(:first-child) {\n  margin-top: 20px;\n}\n\n.src-components-Common-Popup-Common-RelatedLinks-style__heading--1ThEd {\n  margin: 0 0 10px;\n  color: #333;\n  font-size: 16px;\n}\n\n.src-components-Common-Popup-Common-RelatedLinks-style__list--1O7vd {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n}\n.src-components-Common-Popup-Common-RelatedLinks-style__list--1O7vd > li {\n  display: -ms-flexbox;\n  display: flex;\n  margin-right: 8px;\n  margin-bottom: 8px;\n}\n.src-components-Common-Popup-Common-RelatedLinks-style__list--1O7vd > li a {\n  cursor: pointer;\n  border: 1px solid #00578e;\n  border-radius: 2px;\n  padding: 4px 8px;\n  font-size: 12px;\n}\n.src-components-Common-Popup-Common-RelatedLinks-style__list--1O7vd > li a:hover {\n  text-decoration: none;\n  background-color: #f2f6f8;\n}\n.src-components-Common-Popup-Common-RelatedLinks-style__list--1O7vd > li a.src-components-Common-Popup-Common-RelatedLinks-style__printUse--2Yo3_ {\n  cursor: default;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-components-Common-Popup-Common-RelatedLinks-style__component--2JGNW",
	"heading": "src-components-Common-Popup-Common-RelatedLinks-style__heading--1ThEd",
	"list": "src-components-Common-Popup-Common-RelatedLinks-style__list--1O7vd",
	"printUse": "src-components-Common-Popup-Common-RelatedLinks-style__printUse--2Yo3_"
};