exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-BinderPage-List-Components-NikkeiBinderList-style__sortWrapper--3XNZi {\n  padding-right: 24px;\n  padding-left: 24px;\n  border-bottom: 1px solid #dbdbdb;\n}\n\n.src-features-BinderPage-List-Components-NikkeiBinderList-style__collectionWrapper--2b3f5 {\n  margin: 16px 16px 60px;\n}\n.src-features-BinderPage-List-Components-NikkeiBinderList-style__collectionWrapper--2b3f5 .src-features-BinderPage-List-Components-NikkeiBinderList-style__card--2nyGx {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  position: relative;\n}\n.src-features-BinderPage-List-Components-NikkeiBinderList-style__collectionWrapper--2b3f5 .src-features-BinderPage-List-Components-NikkeiBinderList-style__list--2IOW_ {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  overflow-x: auto;\n  position: relative;\n}\n\n.src-features-BinderPage-List-Components-NikkeiBinderList-style__component--z1Eu_ {\n  position: relative;\n}\n\n.src-features-BinderPage-List-Components-NikkeiBinderList-style__loadingWrapper--1yKnk {\n  height: calc(100vh - 180px);\n}\n\n.src-features-BinderPage-List-Components-NikkeiBinderList-style__loadingContainer--2jQlw {\n  text-align: center;\n}\n.src-features-BinderPage-List-Components-NikkeiBinderList-style__loadingContainer--2jQlw .src-features-BinderPage-List-Components-NikkeiBinderList-style__loading--2fiW1 {\n  width: 30px;\n  height: 30px;\n  padding: 30px;\n  animation: src-features-BinderPage-List-Components-NikkeiBinderList-style__spin--1SZwK 1s linear infinite;\n}\n@keyframes src-features-BinderPage-List-Components-NikkeiBinderList-style__spin--1SZwK {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"sortWrapper": "src-features-BinderPage-List-Components-NikkeiBinderList-style__sortWrapper--3XNZi",
	"collectionWrapper": "src-features-BinderPage-List-Components-NikkeiBinderList-style__collectionWrapper--2b3f5",
	"card": "src-features-BinderPage-List-Components-NikkeiBinderList-style__card--2nyGx",
	"list": "src-features-BinderPage-List-Components-NikkeiBinderList-style__list--2IOW_",
	"component": "src-features-BinderPage-List-Components-NikkeiBinderList-style__component--z1Eu_",
	"loadingWrapper": "src-features-BinderPage-List-Components-NikkeiBinderList-style__loadingWrapper--1yKnk",
	"loadingContainer": "src-features-BinderPage-List-Components-NikkeiBinderList-style__loadingContainer--2jQlw",
	"loading": "src-features-BinderPage-List-Components-NikkeiBinderList-style__loading--2fiW1",
	"spin": "src-features-BinderPage-List-Components-NikkeiBinderList-style__spin--1SZwK"
};