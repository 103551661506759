// ホームの目的別メニューの開閉状態を設定
export const HOME_MENU_OPEN_FLAG_SET =
  "permanentGlobal/HOME_MENU_OPEN_FLAG_SET";
// 業界小で表示する新業界レポート追加情報モーダルの表示済み状態を設定
export const INDUSTRY_SEGMENT_REPORT_INFO_MODAL_OPENED =
  "permanentGlobal/INDUSTRY_SEGMENT_REPORT_INFO_MODAL_OPENED";
// 一括検索のインサイトタブのバブルチャート関連記事表示設定のトグル
export const SEARCH_INSIGHT_BUBBLE_RENDERING_ARTICLE_TOGGLE =
  "permanentGlobal/SEARCH_INSIGHT_BUBBLE_RENDERING_ARTICLE_TOGGLE";
// 一括検索のインサイトタブのツリーマップ関連記事表示設定のトグル
export const SEARCH_INSIGHT_TREE_RENDERING_ARTICLE_TOGGLE =
  "permanentGlobal/SEARCH_INSIGHT_TREE_RENDERING_ARTICLE_TOGGLE";
// タブの状態保存
export const TAB_STATE_SAVE = "permanentGlobal/TAB_STATE_SAVE";
