// ホーム初期表示
export const PAGE_INIT = "homes/PAGE_INIT";
// お知らせ取得
export const NOTICE_GET = "homes/NOTICE_GET";
// ニュース取得
export const NEWS_GET = "homes/NEWS_GET";
// ニュースページ取得
export const NEWS_PAGE_GET = "homes/NEWS_PAGE_GET";
// ニュース、ファイルDL
export const NEWS_PDF_GET = "homes/NEWS_PDF_GET";
export const NEWS_XBRL_GET = "homes/NEWS_XBRL_GET";
// ブックマーク取得
export const BOOKMARK_GET = "homes/BOOKMARK_GET";
export const REPORT_TEXT_GET = "homes/REPORT_TEXT_GET";
// Bizトレンド取得
export const TREND_REPORT_GET = "homes/TREND_REPORT_GET";
// 日経業界分析レポート取得
export const SECTOR_REPORT_GET = "homes/SECTOR_REPORT_GET";
// 新業界レポート追加のお知らせモーダルの表示非表示
export const SECTOR_REPORT_INFO_MODAL_OPEN =
  "homes/SECTOR_REPORT_INFO_MODAL_OPEN";
export const SECTOR_REPORT_INFO_MODAL_CLOSE =
  "homes/SECTOR_REPORT_INFO_MODAL_CLOSE";
