import { request } from "utils/apiClient";
import { useDispatch } from "react-redux";
import { actionCreators as headerActionCreators } from "modules/header";
import { useReduxState } from "hooks/useReduxState";

export const useCard = () => {
  const dispatch = useDispatch();

  const authority = useReduxState(
    state => state.auth.saved.collectionAuthority
  );

  const updatePinned = async (binderId: string) => {
    try {
      await request("/binder/pinned", { binderId }, "POST");
      dispatch(
        headerActionCreators.requestUpdate({ shouldLoadCollection: true })
      );
      return { success: true };
    } catch {
      return { success: false };
    }
  };

  const updateMailCollection = async (binderId: string) => {
    await request("/binder/mail-alert", { binderId }, "POST");
  };

  return {
    updatePinned,
    authority,
    updateMailCollection
  };
};
