// ページ初期化
export const PAGE_INIT = "corp/finance/PAGE_INIT";
export const BUSINESSCODE_CHANGE = "corp/finance/PAGE_CHANGE";
export const IDS_CLEAR = "corp/finance/IDS_CLEAR";
// ページ内容取得
export const PAGE_GET = "corp/finance/PAGE_GET";
// 検索条件変更
export const CONDITION_CHANGE = "corp/finance/CONDITION_CHANGE";
// DisplayCondition の各パラメータ変更
export const FROM_UPDATE = "corp/finance/FROM_UPDATE";
export const TO_UPDATE = "corp/finance/TO_UPDATE";
export const DIVISION_UPDATE = "corp/finance/DIVISION_UPDATE";
export const LTM_UPDATE = "corp/finance/LTM_UPDATE";
export const EXPECT_UPDATE = "corp/finance/EXPECT_UPDATE";
export const UNIT_UPDATE = "corp/finance/UNIT_UPDATE";
export const TYPE_UPDATE = "corp/finance/TYPE_UPDATE";
// 企業切り替え済フラグの解除
export const CORP_CHANGED_CLEAR = "corp/finance/CORP_CHANGED_CLEAR";
