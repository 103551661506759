exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-SectionTitle-style__title--1sqWj {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: end;\n      align-items: flex-end;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  box-sizing: border-box;\n  width: 100%;\n  min-height: 40px;\n  margin-top: 16px;\n  margin-bottom: 16px;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  border-bottom: 1px solid #d4dfe7;\n}\n.src-components-Common-SectionTitle-style__title--1sqWj.src-components-Common-SectionTitle-style__tabTitle--cT43Q {\n  -ms-flex-align: center;\n      align-items: center;\n  box-sizing: content-box;\n  padding: 0 0 16px;\n  border-bottom-color: #eaf1f5;\n}\n.src-components-Common-SectionTitle-style__title--1sqWj .src-components-Common-SectionTitle-style__right--1AiyA {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: end;\n      align-items: flex-end;\n  white-space: nowrap;\n}\n.src-components-Common-SectionTitle-style__title--1sqWj .src-components-Common-SectionTitle-style__right--1AiyA .src-components-Common-SectionTitle-style__sub--2z-Zz {\n  padding-top: 4px;\n  color: #141e32;\n  font-size: 11px;\n  line-height: 1;\n}\n.src-components-Common-SectionTitle-style__title--1sqWj .src-components-Common-SectionTitle-style__right--1AiyA .src-components-Common-SectionTitle-style__notice--3bdLA {\n  padding-top: 4px;\n  white-space: pre-wrap;\n  color: #d86262;\n  font-size: 11px;\n  line-height: 1;\n}\n.src-components-Common-SectionTitle-style__title--1sqWj .src-components-Common-SectionTitle-style__right--1AiyA .src-components-Common-SectionTitle-style__textButtonWrapper--3kMLZ {\n  width: 200px;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"title": "src-components-Common-SectionTitle-style__title--1sqWj",
	"tabTitle": "src-components-Common-SectionTitle-style__tabTitle--cT43Q",
	"right": "src-components-Common-SectionTitle-style__right--1AiyA",
	"sub": "src-components-Common-SectionTitle-style__sub--2z-Zz",
	"notice": "src-components-Common-SectionTitle-style__notice--3bdLA",
	"textButtonWrapper": "src-components-Common-SectionTitle-style__textButtonWrapper--3kMLZ"
};