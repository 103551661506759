// 初期表示
export const PAGE_INIT = "corp/graph/PAGE_INIT";

// 期間の選択肢を取得
export const ACC_TYPE_CHANGE = "corp/graph/ACC_TYPE_CHANGE";

// 選択した期間・決算区分・決算種別・単位でグラフの取得
export const GRAPH_SHOW = "corp/graph/GRAPH_SHOW";

// 選択項目のセット
export const SET_ACC_KINDS = "corp/graph/SET_ACC_KINDS";
export const SET_ACC_TYPES = "corp/graph/SET_ACC_TYPES";
export const SET_MONETARY_CODES = "corp/graph/SET_MONETARY_CODES";
export const SET_CONDITIONS = "corp/graph/SET_CONDITIONS";
