import {
  ConditionDisplayItem,
  Corp,
  CorpArea,
  CorpType,
  DisplayCondition,
  DisplayConditionItem,
  FilterCondition,
  FreeWordTarget,
  ScreeningItem,
  SourceItem,
  SavedCondition,
  ErrorCondition,
  ScatterList,
  ConditionItem
} from "models/CorpScreening";

export const setIsInitializing = (initializing: boolean) => ({
  type: "tool/screening/corp/modernItem/SET_IS_INITIALIZING" as const,
  payload: initializing
});

export const setInitialize = (initialized: boolean) => ({
  type: "tool/screening/corp/modernItem/SET_INITIALIZE" as const,
  payload: initialized
});

export const setIsModern = (isModern: boolean) => ({
  type: "tool/screening/corp/modernItem/SET_MODERN_ITEM" as const,
  payload: isModern
});

export const setSavedConditions = (conditions: {
  user: SavedCondition[];
  recommended: SavedCondition[];
}) => ({
  type: "tool/screening/corp/modernItem/SET_SAVED_CONDITIONS" as const,
  payload: conditions
});

export const setFreeWordTarget = (list: string[]) => ({
  type: "tool/screening/corp/modernItem/SET_FREE_WORD_TARGET" as const,
  payload: list
});

export const setFreeWordTargetList = (list: FreeWordTarget[]) => ({
  type: "tool/screening/corp/modernItem/SET_FREE_WORD_TARGET_LIST" as const,
  payload: list
});

export const setFreeWordText = (value: string) => ({
  type: "tool/screening/corp/modernItem/SET_FREE_WORD_TEXT" as const,
  payload: value
});

export const setDisplayCondition = (key: DisplayCondition, value: string) => ({
  type: "tool/screening/corp/modernItem/SET_DISPLAY_CONDITION" as const,
  payload: { key, value }
});

export const setFilterCondition = (conditions: FilterCondition[]) => ({
  type: "tool/screening/corp/modernItem/SET_FILTER_CONDITION" as const,
  payload: conditions
});

export const setFilterConditionValue = (
  value: FilterCondition & {
    value: string | string[];
    inputAreaId: string;
  }
) => ({
  type: "tool/screening/corp/modernItem/SET_FILTER_CONDITION_VALUE" as const,
  payload: value
});

export const setDefaultFilterCondition = (conditions: FilterCondition[]) => ({
  type: "tool/screening/corp/modernItem/SET_DEFAULT_FILTER_CONDITION" as const,
  payload: conditions
});

export const setConditionDisplayItems = (
  condition: ConditionDisplayItem[]
) => ({
  type: "tool/screening/corp/modernItem/SET_CONDITION_DISPLAY_ITEMS" as const,
  payload: condition
});

export const setDefaultConditionDisplayItems = (
  condition: ConditionDisplayItem[]
) => ({
  type: "tool/screening/corp/modernItem/SET_DEFAULT_CONDITION_DISPLAY_ITEMS" as const,
  payload: condition
});

export const setCorporations = (corporations: Corp[]) => ({
  type: "tool/screening/corp/modernItem/SET_CORPORATIONS" as const,
  payload: corporations
});

export const setCurrencyList = (list: DisplayConditionItem[]) => ({
  type: "tool/screening/corp/modernItem/SET_CURRENCY_LIST" as const,
  payload: list
});

export const setScConditionList = (list: DisplayConditionItem[]) => ({
  type: "tool/screening/corp/modernItem/SET_SC_CONDITION_LIST" as const,
  payload: list
});

export const setYtConditionList = (list: DisplayConditionItem[]) => ({
  type: "tool/screening/corp/modernItem/SET_YT_CONDITION_LIST" as const,
  payload: list
});

export const setCompanySelectSourceItems = (items: SourceItem[]) => ({
  type: "tool/screening/corp/modernItem/SET_COMPANY_SELECT_SOURCE_ITEMS" as const,
  payload: items
});

export const setConditionData = (condition: {
  dlxls: string;
  dlcsv: string;
  dlxls_chart: string;
  dlcsv_chart: string;
  dlxlspath_chart: string;
  dlcsvpath_chart: string;
  dlxlspath: string;
  dlcsvpath: string;
  dlpropriety: string;
  dlpropriety_chart: string;
  dlpropriety_segment: string;
  labelValueDtoList08: ConditionItem[];
}) => ({
  type: "tool/screening/corp/modernItem/SET_CONDITION_DATA" as const,
  payload: condition
});

export const setErrorConditions = (errors: ErrorCondition[]) => ({
  type: "tool/screening/corp/modernItem/SET_ERROR_CONDITIONS" as const,
  payload: errors
});

export const setResult = (result: {
  data: ScreeningItem[][];
  dispItemList: string;
  headers: string[][];
  currentNkCodeList: string;
  deletedNkCodeList?: string;
  screeningIdAndConditionsListStr: string;
  needsListStr: string;
  nkCodeListTotalList: string;
  screeningIdListStr: string;
  toolsearchKeyword: string;
  accKbn: string;
  currency: string;
  scCondition: string;
  ytCondition: string;
  ltm: boolean;
  expect: boolean;
  selectCorps: string;
  maxDisplayableNkcodeCount: number;
  nkcodeCountForShow: number;
  totalCount: number;
  groupItemDupulicateFlg: boolean;
  sanpuzuCapableScreeningItemList: any[];
  listingNkcodeTotalCount: string;
  unListedNkcodeTotalCount: string;
  unListedHolderNkcodeTotalCount: string;
  unListedTdbNkcodeTotalCount: string;
  africaNkcodeTotalCount: string;
  asiaPacificNkcodeTotalCount: string;
  europeNkcodeTotalCount: string;
  northAmericaNkcodeTotalCount: string;
  southAmericaNkcodeTotalCount: string;
  mideastNkcodeTotalCount: string;
  unlistedAfricaNkcodeTotalCount: string;
  unlistedAsiaPacificNkcodeTotalCount: string;
  unlistedEuropeNkcodeTotalCount: string;
  unlistedNorthAmericaNkcodeTotalCount: string;
  unlistedSouthAmericaNkcodeTotalCount: string;
  unlistedMideastNkcodeTotalCount: string;
  listingCorpSelect: boolean;
  unlistedHolderCorpSelect: boolean;
  unlistedCorpSelect: boolean;
  unlistedTdbCorpSelect: boolean;
  northAmericaSelect: boolean;
  southAmericaSelect: boolean;
  europeSelect: boolean;
  asiaSelect: boolean;
  mideastSelect: boolean;
  africaSelect: boolean;
  unlistedNorthAmericaSelect: boolean;
  unlistedSouthAmericaSelect: boolean;
  unlistedEuropeSelect: boolean;
  unlistedAsiaSelect: boolean;
  unlistedMideastSelect: boolean;
  unlistedAfricaSelect: boolean;
  sortId?: number;
  sortFlag?: "up" | "down";
}) => ({
  type: "tool/screening/corp/modernItem/SET_RESULT" as const,
  payload: result
});

export const setSelectedTab = (tab: "company" | "segment") => ({
  type: "tool/screening/corp/modernItem/SET_SELECTED_TAB" as const,
  payload: tab
});

export const setSegmentType = (tab: "business" | "overseasSales") => ({
  type: "tool/screening/corp/modernItem/SET_SEGMENT_TYPE" as const,
  payload: tab
});

export const setSearchFilterKeyword = (
  value: string,
  key: "filter" | "display"
) => ({
  type: "tool/screening/corp/modernItem/SET_FILTER_KEYWORD" as const,
  payload: { value, key }
});

export const setIsResultLoading = (isLoading: boolean) => ({
  type: "tool/screening/corp/modernItem/SET_IS_RESULT_LOADING" as const,
  payload: isLoading
});

export const setIsTableLoading = (isLoading: boolean) => ({
  type: "tool/screening/corp/modernItem/SET_IS_TABLE_LOADING" as const,
  payload: isLoading
});

export const setSegmentResult = (result: {
  allNkCodeList: string;
  data: ScreeningItem[][];
  currentPage: string;
  sortFlag?: "up" | "down";
  sortId?: number;
  headers: string[][];
  totalCount: number;
  nikkeiCodeList?: string;
}) => ({
  type: "tool/screening/corp/modernItem/SET_SEGMENT_RESULT" as const,
  payload: result
});

export const setSourceItems = (items: SourceItem[]) => ({
  type: "tool/screening/corp/modernItem/SET_SOURCE_ITEMS" as const,
  payload: items
});

export const setSelectedTypeSourceItems = (
  type: CorpType,
  isChecked: boolean
) => ({
  type: "tool/screening/corp/modernItem/SET_SELECTED_TYPE_SOURCE_ITEMS" as const,
  payload: { type, isChecked }
});

export const setSelectedAreaSourceItems = (
  type: CorpArea,
  isChecked: boolean
) => ({
  type: "tool/screening/corp/modernItem/SET_SELECTED_AREA_SOURCE_ITEMS" as const,
  payload: { type, isChecked }
});

export const setCorpTypeSourceItems = (
  selected: {
    [key in CorpType]: boolean;
  }
) => ({
  type: "tool/screening/corp/modernItem/SET_CORP_TYPE_SOURCE_ITEMS" as const,
  payload: selected
});

export const setCorpAreaSourceItems = (
  selected: {
    [key in CorpArea]: boolean;
  },
  overseas: { listed: boolean; unlisted: boolean }
) => ({
  type: "tool/screening/corp/modernItem/SET_CORP_AREA_SOURCE_ITEMS" as const,
  payload: { selected, overseas }
});

export const setResultDisplayConditionList = ({
  accKbnList,
  currency,
  scConditionList,
  ytConditionList
}: {
  accKbnList: DisplayConditionItem[];
  currency: DisplayConditionItem[];
  scConditionList: DisplayConditionItem[];
  ytConditionList: DisplayConditionItem[];
}) => ({
  type: "tool/screening/corp/modernItem/SET_RESULT_DISPLAY_CONDITION_LIST" as const,
  payload: { currency, accKbnList, scConditionList, ytConditionList }
});

export const setIsScatterLoading = (isLoading: boolean) => ({
  type: "tool/screening/corp/modernItem/SET_IS_SCATTER_LOADING" as const,
  payload: isLoading
});

export const setDisableFetchCorpCount = (isDisabled: boolean) => ({
  type: "tool/screening/corp/modernItem/SET_DISABLED_FETCH_CORP_COUNT" as const,
  payload: isDisabled
});

export const setIsFetchFilterMessage = (isFetch: boolean) => ({
  type: "tool/screening/corp/modernItem/SET_IS_FETCH_FILTER_MESSAGE" as const,
  payload: isFetch
});

export const setScatterData = (data: {
  code: string[];
  toolComparisonList9Dto: ScatterList;
  chart02Str: string;
  chart03Str: string;
  chart04Str: string;
}) => ({
  type: "tool/screening/corp/modernItem/SET_SCATTER_DATA" as const,
  payload: data
});

export const clearResult = () => ({
  type: "tool/screening/corp/modernItem/CLEAR_RESULT" as const
});

export const clearConditions = ({
  baseSourceItems
}: {
  baseSourceItems: SourceItem[];
}) => ({
  type: "tool/screening/corp/modernItem/CLEAR_CONDITIONS" as const,
  payload: {
    baseSourceItems
  }
});

export const clearScatterData = () => ({
  type: "tool/screening/corp/modernItem/CLEAR_SCATTER_DATA" as const
});

// 企業種別をクリアするため
export const clearState = () => ({
  type: "tool/screening/corp/modernItem/CLEAR_STATE" as const
});

export type ToolScreeningCorpModernItemAction = ReturnType<
  | typeof setIsInitializing
  | typeof setInitialize
  | typeof setIsModern
  | typeof setSavedConditions
  | typeof setFreeWordTarget
  | typeof setFreeWordTargetList
  | typeof setFreeWordText
  | typeof setDisplayCondition
  | typeof setFilterCondition
  | typeof setFilterConditionValue
  | typeof setDefaultFilterCondition
  | typeof setConditionDisplayItems
  | typeof setDefaultConditionDisplayItems
  | typeof setCorporations
  | typeof setCurrencyList
  | typeof setScConditionList
  | typeof setYtConditionList
  | typeof setCompanySelectSourceItems
  | typeof setConditionData
  | typeof setErrorConditions
  | typeof setResult
  | typeof setSelectedTab
  | typeof setSegmentType
  | typeof setSearchFilterKeyword
  | typeof setIsResultLoading
  | typeof setIsTableLoading
  | typeof setSegmentResult
  | typeof setSourceItems
  | typeof setSelectedTypeSourceItems
  | typeof setSelectedAreaSourceItems
  | typeof setCorpTypeSourceItems
  | typeof setCorpAreaSourceItems
  | typeof setResultDisplayConditionList
  | typeof setIsScatterLoading
  | typeof setDisableFetchCorpCount
  | typeof setIsFetchFilterMessage
  | typeof setScatterData
  | typeof clearResult
  | typeof clearConditions
  | typeof clearScatterData
  | typeof clearState
>;
