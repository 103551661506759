import { RawOperand } from "models/GlobalSearch";

export const validateKeywords = (
  rawText: string,
  rawOperand: RawOperand = { and: "", or: "", not: "" },
  advancedMode: boolean,
  num?: number
): {
  errorMessage: string[];
  searchSkip: boolean;
  rawText: string;
  andKeyword: string;
  orKeyword: string;
  notKeyword: string;
} => {
  const keywordsLength = rawText.length;
  const totalLength =
    rawOperand.and.length + rawOperand.or.length + rawOperand.not.length;
  const limitNum = num ? num : 500;

  const rawKeyword = advancedMode ? "" : rawText;
  const andKeyword = advancedMode ? rawOperand.and : "";
  const orKeyword = advancedMode ? rawOperand.or : "";
  const notKeyword = advancedMode ? rawOperand.not : "";

  if (keywordsLength > limitNum || totalLength > limitNum) {
    return {
      errorMessage: [`W1082 入力可能な文字数(${limitNum})を超えています`],
      searchSkip: true,
      rawText: rawKeyword
        .replace(/\band\b/gi, "AND")
        .replace(/\bor\b/gi, "OR")
        .replace(/\bnot\b/gi, "NOT"),
      andKeyword: andKeyword,
      orKeyword: orKeyword,
      notKeyword: notKeyword
    };
  } else if (
    rawOperand.and === "" &&
    rawOperand.or === "" &&
    rawOperand.not !== ""
  ) {
    return {
      errorMessage: [
        "「すべてを含む」もしくは「いずれかを含む」にキーワードを設定してください"
      ],
      searchSkip: false,
      rawText: rawKeyword
        .replace(/\band\b/gi, "AND")
        .replace(/\bor\b/gi, "OR")
        .replace(/\bnot\b/gi, "NOT"),
      andKeyword: andKeyword,
      orKeyword: orKeyword,
      notKeyword: notKeyword
    };
  } else {
    return {
      errorMessage: [],
      searchSkip: false,
      rawText: rawKeyword
        .replace(/\band\b/gi, "AND")
        .replace(/\bor\b/gi, "OR")
        .replace(/\bnot\b/gi, "NOT"),
      andKeyword: andKeyword,
      orKeyword: orKeyword,
      notKeyword: notKeyword
    };
  }
};
