import { getAtlasCookie } from "@nikkei/atlas-react";
import { getTokenAndId } from "utils/helper/localStorage";

export interface BuildInitPage {
  pageName: string;
  categories: string[];
  planValue: string | null;
  contentId: string | null;
  contentName: string | null;
  pageNum: number;
  customObject: object;
  referrer?: string | null;
  url?: string | null;
}

/**
 * Atlas initPage 設定値
 * @param {string} pageName - ページ名
 * @param {?number} pageNum - ページ数
 * @param {[]string} categories - ページ階層
 * @param {?string} planValue - アカウントのプラン
 * @param {?string} contentId - コンテンツID
 * @param {?object} customObject - カスタム設定値
 * @param {?string} mediaCode - メディアコード
 */
export const buildInitPage = ({
  pageName,
  categories = [],
  planValue = null,
  contentId = null,
  contentName = null,
  pageNum = 1,
  customObject = {}
}: BuildInitPage) => {
  let id, contractId;
  try {
    id = getTokenAndId().id;
    contractId = getTokenAndId().contractId;
  } catch (_) {
    id = "";
    contractId = "";
  }

  return {
    user: {
      user_id: getAtlasCookie("NID-Serial-Cookie"),
      user_status: planValue,
      site_session: getAtlasCookie("JSESSIONID")
    },
    context: {
      app: null,
      app_version: null,
      source: null,
      edition: null,
      id: null,
      root_id: null,
      content_id: contentId,
      content_name: contentName,
      content_status: null,
      page_name: pageName,
      page_num: pageNum,
      category_l1: categories.length > 0 ? categories[0] : null,
      category_l2: categories.length > 1 ? categories[1] : null,
      category_l3: categories.length > 2 ? categories[2] : null,
      tracking_code: null,
      events: null,
      custom_object: {
        value_search_id: id,
        contract_id: contractId,
        ...customObject
      },
      funnel: {}
    }
  };
};
