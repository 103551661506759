import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.scss";
import cn from "classnames";
import SearchModeDescription from "images/other/search_mode_description.png";

interface Props {
  isInModal?: boolean;
  onClose: Function;
}

export const AdvancedSearch: React.FC<Props> = ({
  isInModal = false,
  onClose
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [closing, setClosing] = useState(false);

  const onClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as Node | null;
    if (wrapperRef.current && !wrapperRef.current.contains(target)) {
      setClosing(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", onClickOutside);
    return () => window.removeEventListener("click", onClickOutside);
  }, [onClickOutside]);

  const onAnimetionEnd = useCallback(() => {
    if (!closing) return;
    onClose();
  }, [closing, onClose]);

  useEffect(() => {
    wrapperRef.current?.addEventListener("animationend", onAnimetionEnd);

    return () => {
      window.removeEventListener("animationend", onAnimetionEnd);
    };
  }, [onAnimetionEnd]);

  return (
    <div
      className={cn(isInModal ? styles.helpModal : styles.helpWrap, {
        [styles.closing]: closing
      })}
      ref={wrapperRef}
    >
      <div
        className={cn(styles.titleWrap, {
          [styles.modalModeTitle]: isInModal
        })}
      >
        <div className={styles.title}>検索条件の指定方法</div>
        <div
          className={styles.closeWrap}
          onClick={() => {
            setClosing(true);
          }}
        >
          <span className={styles.text}>閉じる</span>
          <span className={styles.closeButton} />
        </div>
      </div>
      <div
        className={cn(styles.body, {
          [styles.modalModeBody]: isInModal
        })}
      >
        <div className={styles.descriptionWrap}>
          <div className={styles.about}>
            <span className={styles.emphasis}>
              記事・レポート検索配下の各メニューにおける検索条件やコレクションのフィード条件
            </span>
            を指定する方法について解説します。
          </div>

          <div className={styles.section}>
            <div className={styles.title}>基本の使い方</div>
            <div className={styles.sentence}>
              複数の単語を半角スペースで区切って入力することで、AND検索を実行します。
              <br />
              <span className={styles.emphasis}>例）</span>ビール 新商品
              →「ビール」 と 「新商品」 を含む結果を表示
            </div>

            <div className={styles.sentence}>
              スペースを含んだ複数の単語を一括りで検索したい場合は、対象の範囲を&quot;&quot;
              <span className={styles.emphasis}>(半角二重引用符)</span>
              で囲んでください。スペースや語順まで含め、完全に一致する結果が表示されます。
            </div>
            <table className={styles.descriptionTable}>
              <tbody>
                <tr>
                  <th>演算子</th>
                  <td>
                    <span className={styles.emphasis}>&quot;&quot;</span>
                  </td>
                </tr>
                <tr>
                  <th>できること</th>
                  <td>
                    <span className={styles.emphasis}>完全一致検索</span>
                    <br />
                    スペースや語順も考慮して完全に一致するものを検索します。
                  </td>
                </tr>
                <tr>
                  <th>使用例</th>
                  <td>
                    <span className={styles.emphasis}>
                      &quot;electric vehicles&quot; TOYOTA
                    </span>
                    <br />
                    =「electric vehicles」と「TOYOTA」を含む結果を表示
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>検索モード切替とは</div>
            <div className={styles.sentence}>
              より簡単に条件を指定したい場合は
              <span className={styles.attention}>*1</span>
              <span className={styles.emphasis}>「検索モード切替」</span>
              をクリックすることで、入力補助モードに切り替えることができます。
              入力補助モードでは演算子を入力する代わりに
              <span className={styles.attention}>*2</span>
              <span className={styles.emphasis}>
                「すべてを含む」「いずれかを含む」「含まない」
              </span>
              のそれぞれのフォームにキーワードを入力します。
            </div>
            <img src={SearchModeDescription} />
          </div>

          <div className={styles.section}>
            <div className={styles.title}>標準モードで使用可能な演算子</div>
            <div className={styles.sentence}>
              以下の演算子を使用することで詳しい条件を指定できます。(いずれも半角で入力してください)
              <br />
              ※標準モードのみ利用可能です。入力補助モードでは利用できません。
            </div>

            <div className={styles.sentence}>
              <table className={styles.descriptionTable}>
                <tr>
                  <th>演算子</th>
                  <td>
                    <span className={styles.emphasis}>AND</span>
                  </td>
                </tr>
                <tr>
                  <th>できること</th>
                  <td>
                    <span className={styles.emphasis}>検索対象を絞り込む</span>
                    <br />
                    前後の単語の両方を含むものを検索します。
                  </td>
                </tr>
                <tr>
                  <th>使用例</th>
                  <td>
                    <span className={styles.emphasis}>ビール AND 新商品</span>
                    <br />
                    ＝「ビール」 と 「新商品」 を含む結果を表示
                  </td>
                </tr>
              </table>

              <table className={styles.descriptionTable}>
                <tr>
                  <th>演算子</th>
                  <td>
                    <span className={styles.emphasis}>OR</span>
                  </td>
                </tr>
                <tr>
                  <th>できること</th>
                  <td>
                    <span className={styles.emphasis}>検索対象を拡げる</span>
                    <br />
                    前後の単語の少なくとも一方を含むものを検索します。
                  </td>
                </tr>
                <tr>
                  <th>使用例</th>
                  <td>
                    <span className={styles.emphasis}>ビール OR ワイン</span>
                    <br />
                    =「ビール」または「ワイン」を含む結果を表示
                  </td>
                </tr>
              </table>

              <table className={styles.descriptionTable}>
                <tr>
                  <th>演算子</th>
                  <td>
                    <span className={styles.emphasis}>NOT</span>
                  </td>
                </tr>
                <tr>
                  <th>できること</th>
                  <td>
                    <span className={styles.emphasis}>検索対象から除く</span>
                    <br />
                    前の単語を含み、後の単語を含まないものを検索します。
                  </td>
                </tr>
                <tr>
                  <th>使用例</th>
                  <td>
                    <span className={styles.emphasis}>ビール NOT 発泡酒</span>
                    <br />
                    ＝「ビール」を含む結果のうち「発泡酒」が含まれているものを除いた結果を表示{" "}
                  </td>
                </tr>
              </table>

              <table className={styles.descriptionTable}>
                <tr>
                  <th>演算子</th>
                  <td>
                    <span className={styles.emphasis}>()</span>
                  </td>
                </tr>
                <tr>
                  <th>できること</th>
                  <td>
                    <span className={styles.emphasis}>
                      条件をグルーピングする
                    </span>
                    <br />
                    複数の演算子を使用する場合、()で囲んだ範囲の条件を優先します
                  </td>
                </tr>
                <tr>
                  <th>使用例</th>
                  <td>
                    <span className={styles.emphasis}>
                      米国 AND (パソコン OR 半導体)
                    </span>
                    <br />
                    ＝「米国」を含み、かつ「パソコン」と「半導体」のいずれかを含む結果を表示
                  </td>
                </tr>
              </table>
            </div>
            <div>
              ※複数のキーワードを指定する場合は、キーワードの間に半角スペースを入力してください。
              <br />
              ※上記の演算子を使用する場合は、入力キーワードと演算子の間に半角スペースを入力してください。
              <br />
              ※演算子が複数指定されている場合、() &gt; NOT &gt; AND &gt; OR
              の順に優先されます。
              <br />
              ※一括検索専用の演算子（ / や - ）は使用できません。
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.exampleTitle}>＜検索式の例＞</div>
            <div className={styles.subTitle}>
              新商品の、ビールとワインについて
            </div>
            <div className={styles.input}>
              <span className={styles.searchIcon} />
              <span className={styles.tagsInput}>
                新商品 AND ( ビール OR ワイン )
              </span>
            </div>
            <div className={styles.subTitle}>
              新商品のビールと、(新商品に限らず)ワインについて
            </div>
            <div className={styles.input}>
              <span className={styles.searchIcon} />
              <span className={styles.tagsInput}>
                新商品 AND ビール OR ワイン
              </span>
            </div>
          </div>
        </div>
        <a
          className={cn(styles.helpLink)}
          href="https://intercom.help/valuesearch/ja/articles/4970713"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>検索方法に関するヘルプ</span>
          <span className={cn(styles.popup)} />
        </a>
      </div>
    </div>
  );
};
