export const KEYWORD_SET = "article/strategy/KEYWORD_SET";
// 初期表示
export const PAGE_INIT = "article/strategy/PAGE_INIT";
// セレクトボックス等のデータ取得
export const CODE_LIST_GET = "article/strategy/CODE_LIST_GET";
// 選択した条件での検索
export const STRATEGY_DATA_SEARCH = "article/strategy/STRATEGY_DATA_SEARCH";
// ソート・表示件数を変更してデータ取得
export const STRATEGY_DATA_SORT = "article/strategy/STRATEGY_DATA_SORT";
// ページング
export const STRATEGY_PAGING = "article/strategy/STRATEGY_TOP_PAGE";
// Needs業種分類の大項目を取得
export const GET_NEEDS_MST_L = "article/strategy/GET_NEEDS_MST_L";
// Needs業種分類の中項目を取得
export const GET_NEEDS_MST_M = "article/strategy/GET_NEEDS_MST_M";
// Needs業種分類の小項目を取得
export const GET_NEEDS_MST_S = "article/strategy/GET_NEEDS_MST_S";
// 初期ローディングの状態更新
export const INITIAL_LOADING_UPDATE = "article/strategy/INITIAL_LOADING_UPDATE";
