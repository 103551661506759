// コレクション一覧の取得
export const BINDER_LIST_GET = "common/BookmarkBalloon/BINDER_LIST_GET";
// 媒体一覧の取得
export const MEDIA_LIST_GET = "common/BookmarkBalloon/MEDIA_LIST_GET";
// 企業追加
export const CORP_ITEM_INSERT = "common/BookmarkBalloon/CORP_ITEM_INSERT";
// 業界追加
export const INDUSTRY_ITEM_INSERT =
  "common/BookmarkBalloon/INDUSTRY_ITEM_INSERT";
// 記事追加
export const ARTICLE_ITEM_INSERT = "common/BookmarkBalloon/ARTICLE_ITEM_INSERT";
// 官公庁追加
export const GOVERNMENT_ITEM_INSERT =
  "common/BookmarkBalloon/GOVERNMENT_ITEM_INSERT";
// 統計追加
export const STATISTIC_ITEM_INSERT =
  "common/BookmarkBalloon/STATISTIC_ITEM_INSERT";
// 地域追加
export const AREA_ITEM_INSERT = "common/BookmarkBalloon/AREA_ITEM_INSERT";
// 再レンダリングして欲しいことを通知するフラグ
export const BINDER_LIST_REFETCH_FLG_TOGGLE =
  "common/BookmarkBalloon/BINDER_LIST_REFETCH_FLG_TOGGLE";
