import { useState, useRef } from "react";
import { request } from "utils/apiClient";
import { useToast } from "hooks/useToast";

interface ErrorResponse {
  name: string;
  path: string;
  message: string;
  body: {
    code: number;
    details: MessageDetails[];
    message: string;
  };
}

interface MessageDetails {
  apiErrorCode: string;
  message: string;
  stackTrace?: string;
}

export const useFileImport = () => {
  const [displayImportArea, setDisplayImportArea] = useState(false);
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef<any /*HTMLDivElement*/>(null); // HTMLDivElement にしたいが差分ビルド時にエラーが出るため any にする
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClose = (event: MouseEvent) => {
    if (
      // event.target instanceof Node && // 差分ビルド時にエラーになるためコメントアウト
      componentRef.current &&
      !componentRef.current.contains(event.target)
    ) {
      setDisplayImportArea(false);
    }
  };

  const handleClickOpen = () => setDisplayImportArea(prev => !prev);

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    setFile(files[0]);
  };

  const handleClickCancel = () => {
    setFile(undefined);
    setDisplayImportArea(false);
  };

  const readFile = (file: File) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result.split(";")[1].substr(7));
        }
        reject();
      };
    });

  const toast = useToast();

  const handleClickExecute = async (onFinishImport: () => void) => {
    if (!file) {
      toast.setErrors(["ファイルを指定してください"]);
      return;
    }
    toast.clearErrors();
    setIsLoading(true);
    try {
      const data = await readFile(file);
      const response = await request<{ result: string }>(
        "/binder/import-bookmark",
        { importFile: data },
        "POST"
      );
      if (response.result) {
        toast.setMessages([response.result]);
        onFinishImport();
      }
    } catch (error) {
      const e = error as ErrorResponse;
      if (
        e &&
        e.body &&
        e.body.code === 400 &&
        e.body.details &&
        e.body.details.length > 0
      ) {
        const msgList = e.body.details.map((d: MessageDetails) => d.message);
        toast.setErrors(msgList);
      } else {
        toast.setErrors(["ファイルの読み込みに失敗しました"]);
      }
    } finally {
      setIsLoading(false);
      setFile(undefined);
      setDisplayImportArea(false);
    }
  };

  return {
    displayImportArea,
    file,
    isLoading,
    componentRef,
    inputRef,
    handleClose,
    handleClickOpen,
    handleChangeFile,
    handleClickCancel,
    handleClickExecute
  };
};
