import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  periodDto: {},
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      periodDto: action.payload.periodDto
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SEARCH_PAGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      periodDto: action.payload.periodDto
    }),
    [`${ActionTypes.SEARCH_PAGE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.PAGER_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      periodDto: action.payload.periodDto
    }),
    [ActionTypes.ALL_RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(160, params)),
  searchPage: createAction(ActionTypes.SEARCH_PAGE, params =>
    request(161, params)
  ),
  changePage: createAction(ActionTypes.PAGER_CHANGE, params =>
    request(841, params)
  ),
  resetAll: createAction(ActionTypes.ALL_RESET)
};
