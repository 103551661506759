exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-Login-MobileFooter-style__component--1rzjD {\n  padding: 24px 16px;\n  background-color: #ccd7e1;\n}\n\n.src-features-Login-MobileFooter-style__linkWrapper--1omt4 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n  width: 100%;\n  margin-top: 16px;\n}\n.src-features-Login-MobileFooter-style__linkWrapper--1omt4 > :first-child {\n  margin-right: 16px;\n}\n.src-features-Login-MobileFooter-style__linkWrapper--1omt4 span {\n  color: black;\n}\n\n.src-features-Login-MobileFooter-style__description--hQteH {\n  margin: 24px auto;\n  color: #141e32;\n  font-size: 11px;\n  line-height: 18px;\n}\n\n.src-features-Login-MobileFooter-style__logoWrapper--gEt58 {\n  text-align: center;\n  font-size: 10px;\n}\n.src-features-Login-MobileFooter-style__logoWrapper--gEt58 > * {\n  display: block;\n}\n.src-features-Login-MobileFooter-style__logoWrapper--gEt58 img {\n  width: 90px;\n}\n\n.src-features-Login-MobileFooter-style__note--3P8w7 {\n  margin: 4px 0;\n  text-align: center;\n  font-weight: 400;\n  font-size: 10px;\n  color: #747576;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-features-Login-MobileFooter-style__component--1rzjD",
	"linkWrapper": "src-features-Login-MobileFooter-style__linkWrapper--1omt4",
	"description": "src-features-Login-MobileFooter-style__description--hQteH",
	"logoWrapper": "src-features-Login-MobileFooter-style__logoWrapper--gEt58",
	"note": "src-features-Login-MobileFooter-style__note--3P8w7"
};