import React from "react";

import RelatedLinks from "components/Common/Popup/Common/RelatedLinks";

import { fillZero, isEmpty } from "utils/helper/common";

import styles from "../../Common/style.scss";

/**
 * 文字列日付から、yyyy/MM/dd hh:mm形式の文字列に変換
 * @param {string} dateStr
 */
const formatDate = dateStr => {
  const dateObj = new Date(dateStr);
  const year = dateObj.getFullYear();
  const month = fillZero(dateObj.getMonth() + 1);
  const date = fillZero(dateObj.getDate());

  return `${year}/${month}/${date}`;
};

export const renderSub = ({
  industryInfo,
  companyInfo,
  forPrintUse = false,
  trackAtlasAction = () => {}
}) => {
  if (isEmpty(industryInfo) && isEmpty(companyInfo)) {
    return null;
  }
  return (
    <div className={styles.sub}>
      <div className={styles.subInner}>
        <RelatedLinks
          type="industry"
          list={industryInfo}
          forPrintUse={forPrintUse}
          onTrackAtlasAction={data => {
            trackAtlasAction(data);
          }}
        />
        <RelatedLinks
          type="corp"
          list={companyInfo}
          forPrintUse={forPrintUse}
          onTrackAtlasAction={data => {
            trackAtlasAction(data);
          }}
        />
      </div>
    </div>
  );
};

export const renderHeader = (title, displayTime, mediaName, wordCount) => (
  <React.Fragment>
    <div className={styles.meta}>
      <div className={styles.displayTime}>{formatDate(displayTime)}</div>
      <div className={styles.mediaName}>{mediaName}</div>
      <div className={styles.wordCount}>{wordCount}文字</div>
    </div>
    <div className={styles.header}>
      <div className={styles.headerInner}>
        <h1 className={styles.headingLev1}>{title}</h1>
      </div>
    </div>
  </React.Fragment>
);

export const renderBody = body => {
  if (!isEmpty(body) && /\<\/[^\>]+\>/.test(body)) {
    return (
      <div
        className={styles.bodyInTable}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    );
  }
  return <div className={styles.body}>{body}</div>;
};
