import { ModalAction } from "./actions";
import { Reducer } from "redux";

export interface ModalState {
  // 表示中のモーダル管理用stack、モーダルIDをPUSHする
  modalStack: string[];
}

const initialState: ModalState = {
  modalStack: []
};

export const modal: Reducer<ModalState, ModalAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "modal/MODAL_REGISTER":
      return {
        modalStack: [...state.modalStack, action.payload]
      };
    case "modal/MODAL_RELEASE":
      return {
        modalStack: state.modalStack.slice(0, -1)
      };
    case "modal/MODAL_STACK_RESET":
      return {
        modalStack: initialState.modalStack
      };
    default:
      return state;
  }
};
