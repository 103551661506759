import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router";
import { request } from "utils/apiClient";

export const Debug: React.FunctionComponent = () => {
  if (!IS_DEBUG) return <Redirect to={{ pathname: "/" }} />;

  return (
    <Switch>
      <Route path="/debug/logout">
        <Logout />
      </Route>
    </Switch>
  );
};

const Logout: React.FunctionComponent = () => {
  const [isLogout, setIsLogout] = useState(false);

  useEffect(() => {
    request("/logout").then(() => setIsLogout(true));
  }, []);

  const style: React.CSSProperties = {
    width: "100%",
    textAlign: "center",
    marginTop: "100px",
    fontSize: "24px"
  };
  const message = isLogout ? "ログアウトしました" : "ログアウト中です...";
  return <div style={style}>{message}</div>;
};
