import { AccessStatus } from "./index";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { request } from "utils/apiClient/base";

interface GetAccessStatusParams {
  contentId: string;
  corpIndustyCode?: string;
  corpIndustyKbn?: string;
}

// アクセス権限の取得
export const getAccessStatus = (params: Partial<GetAccessStatusParams>) => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>
) => {
  dispatch(updateAccessStatus("PENDING"));

  return request(820, params)
    .then(result => dispatch(updateAccessStatus(result.accessStatus)))
    .catch(() => dispatch(updateAccessStatus("")));
};

// アクセス権限の更新
export const updateAccessStatus = (accessState: AccessStatus) => ({
  type: "accessControl/ACCESS_STATUS_UPDATE" as const,
  payload: accessState
});

export const actionCreators = {
  updateAccessStatus
};

export type AccessControlAction = ReturnType<typeof updateAccessStatus>;
