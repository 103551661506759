import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  news: {},
  isRequestingNews: false,
  segmentCorp: {},
  isLoadingSegmentCorp: false,
  industryList: [],
  selectedIndustry: {},
  kamokuList: [],
  selectedKamoku: {},
  pdfUrl: "",

  tdbCode: "",
  tdbCorp: {},
  tdbLinkInfo: {},
  submitResult: {},
  tdbInfo: {},
  error: {},

  isInitLoading: false
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => {
      const list = action.payload.corpSummary07.segmentList.map(item => ({
        value: item.seq,
        label: item.segmentName
      }));
      return {
        ...state,
        data: action.payload,
        industryList: list,
        selectedIndustry: list[0],
        tdbLinkInfo: action.payload.tdbLinkInfo,
        isInitLoading: false
      };
    },
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => ({
      ...initialState,
      isInitLoading: true
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NEWS_GET}_PENDING`]: state => ({
      ...state,
      isRequestingNews: true
    }),
    [`${ActionTypes.NEWS_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      news: action.payload,
      isRequestingNews: false
    }),
    [`${ActionTypes.NEWS_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isRequestingNews: false
    }),
    [`${ActionTypes.SEGMENT_CORP_GET}_PENDING`]: state => ({
      ...state,
      isLoadingSegmentCorp: true
    }),
    [`${ActionTypes.SEGMENT_CORP_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      segmentCorp: action.payload,
      isLoadingSegmentCorp: false,
      kamokuList: action.meta.update
        ? action.payload.kamokuMeisyoList
        : state.kamokuList,
      selectedKamoku: action.meta.update
        ? action.payload.kamokuMeisyoList[0]
        : state.selectedKamoku
    }),
    [`${ActionTypes.SEGMENT_CORP_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingSegmentCorp: false
    }),
    [`${ActionTypes.PDF_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      pdfUrl: action.payload
    }),
    [`${ActionTypes.PDF_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),

    /**
     * TDB課金モーダル関係
     */
    [`${ActionTypes.TDB_CORP_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      tdbCorp: action.payload,
      tdbCode: action.meta.tdbCode
    }),
    [`${ActionTypes.TDB_CORP_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.SELECTED_INDUSTRY_SET]: (state, action) => ({
      ...state,
      selectedIndustry: action.payload
    }),
    [ActionTypes.SELECTED_KAMOKU_SET]: (state, action) => ({
      ...state,
      selectedKamoku: action.payload
    }),
    [`${ActionTypes.MODAL_SUBMIT}_FULFILLED`]: (state, action) => ({
      ...state,
      submitResult: action.payload
    }),
    [`${ActionTypes.MODAL_SUBMIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.TDB_INFO_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      tdbInfo: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(270, params)),
  getNews: createAction(ActionTypes.NEWS_GET, params => request(271, params)),
  getSegmentCorp: createAction(
    ActionTypes.SEGMENT_CORP_GET,
    params => request(274, params),
    (p, update = false) => ({ update })
  ),
  getTdbCorp: createAction(
    ActionTypes.TDB_CORP_GET,
    params => request(277, params),
    params => ({ tdbCode: params.tdbCode })
  ),
  getPdf: createAction(ActionTypes.PDF_GET, params =>
    request("/corp/summary/management-policy-pdf-download", params)
  ),
  setIndustryList: createAction(ActionTypes.INDUSTRY_LIST_SET),
  setSelectedIndustry: createAction(ActionTypes.SELECTED_INDUSTRY_SET),
  setSelectedKamoku: createAction(ActionTypes.SELECTED_KAMOKU_SET),
  setOperateHistry: createAction(ActionTypes.SET_OPERATE_HISTORY, params =>
    request(279, params)
  ),
  submitModal: createAction(ActionTypes.MODAL_SUBMIT, params =>
    request(278, params)
  ),
  getTdbInfo: createAction(ActionTypes.TDB_INFO_GET, (params, isCorp) =>
    isCorp ? request(847, params) : request(848, params)
  )
};
