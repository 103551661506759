import React, { ReactNode } from "react";
import styles from "./style.scss";

/**
 * 文字列ボタン
 *
 * @prop {?string} classNmae - クラス名
 * @prop {?boolean} isBold - 太字にするか
 * @prop {?boolean} isSmall - 小さくするか
 * @prop {?boolean} isLink - リンクスタイルにするかどうか、キャンセルボタンは true 必須
 * @prop {?string} color - 色
 * @prop {function} onClick - ボタン押下時コールバック
 */

interface Props {
  color?: string;
  isBold?: boolean;
  isSmall?: boolean;
  className?: string;
  children?: ReactNode;
  isLink?: boolean;
}

const Button = React.forwardRef(
  (
    {
      color = "primary",
      isBold = true,
      isSmall = false,
      className = "",
      children,
      isLink = false,
      ...props
    }: Props & React.ButtonHTMLAttributes<HTMLButtonElement>,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    let style = `${className} ${styles.btn}`;

    if (isSmall) {
      style += ` ${styles.small}`;
    } else if (isBold) {
      style += ` ${styles.bold}`;
    }

    if (isLink) {
      style += ` ${styles.link}`;
    }

    if (styles.hasOwnProperty(color)) {
      style += ` ${styles[color]}`;
    }

    return (
      <button ref={ref} {...props} className={style}>
        {children}
      </button>
    );
  }
);

export default Button;
