// ユーザ設定区分
export const userSetKbn = {
  screening: "1", // スクリーニング
  competing: "2", // 競合
  grouping: "3", // 類似
  industry: "6", // 業界
  screeningIndustry: 7 // スクリーニング(業界), 業界比較
};

// 表示区分
export const displayKbn = {
  screening: "1", //スクリーニング
  comparison: "2", //競合比較
  grouping: "3", //類似企業
  competingConparison1: "4", //競合比較①（レポート作成）
  competingConparison2: "5", //競合比較②（レポート作成）
  industry: "6", //業界指標
  screeningIndustry: "8" // スクリーニング(業界), 業界比較
};

// 定義値
export const tabId = {
  screeningCorpItem: "1", // スクリーニング(企業)/項目, スクリーニング(項目)/シナリオ
  screeningBusiness: "2", // スクリーニング(セグメント)/事業
  screeningArea: "3", // スクリーニング(セグメント)/地域
  corporationComparison: "4", // 同業企業比較/企業比較
  // grouping: "7", // 類似企業サーチ (廃止)
  industrySmall: "9", // 業界（業界小分類)
  comparisonIndustry: "A", // 業界比較
  screeningIndustry: "B" // スクリーニング(業界)
};
