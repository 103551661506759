import React from "react";
import styles from "./style.scss";
import cn from "classnames";

interface Props {
  isActive: boolean | null;
}

/**
 * 有料タグ
 *
 * @prop {?boolean} isActive
 */

const PayTag: React.FunctionComponent<Props> = ({ isActive = true }) => {
  return (
    <span
      className={cn(styles.tag, {
        [styles.disabled]: !isActive
      })}
    >
      有料
    </span>
  );
};

export default PayTag;
