import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";

const initialState = {
  // 条件選択状態
  comparisonConditions: {
    nkgncodes: "", // 業界
    mainFlg: "" // 主／所属業種
  },
  categoryItems: [], // 業界リスト
  mainFlgList: [], // 主／所属業種リスト
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.INIT}_FULFILLED`]: (state, action) => {
      const conditions = state.comparisonConditions;
      const nkgncodes =
        isEmpty(conditions.nkgncodes) || action.meta.forceUpdate
          ? action.payload.nkgncodes
          : conditions.nkgncodes;
      const mainFlg =
        isEmpty(conditions.mainFlg) || action.meta.forceUpdate
          ? action.payload.mainFlgList[0].value
          : conditions.mainFlg;
      return {
        ...state,
        categoryItems: action.payload.corpEntityList,
        mainFlgList: action.payload.mainFlgList,
        comparisonConditions: { nkgncodes, mainFlg }
      };
    },
    [`${ActionTypes.INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.CHANGE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [ActionTypes.RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  init: createAction(
    ActionTypes.INIT,
    params => request(622, params),
    (p, forceUpdate = false) => ({ forceUpdate })
  ),
  change: createAction(ActionTypes.CHANGE),
  reset: createAction(ActionTypes.RESET)
};
