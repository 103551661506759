import { BindersSavedAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";

export interface BindersSavedState {
  personalCardViewMode: boolean;
  nikkeiCardViewMode: boolean;
}

const initialState: BindersSavedState = {
  personalCardViewMode: true,
  nikkeiCardViewMode: true
};

export const bindersSaved: Reducer<BindersSavedState, BindersSavedAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "binders/list/SET_PERSONAL_CARD_VIEW_MODE":
      return produce(state, draft => {
        draft.personalCardViewMode = action.payload.value;
      });
    case "binders/list/SET_NIKKEI_CARD_VIEW_MODE":
      return produce(state, draft => {
        draft.nikkeiCardViewMode = action.payload.value;
      });
    case "binders/list/CLEAR_CARD_VIEW_MODE":
      return produce(state, draft => {
        draft.nikkeiCardViewMode = initialState.nikkeiCardViewMode;
        draft.personalCardViewMode = initialState.personalCardViewMode;
      });
    default:
      return state;
  }
};
