import { DownloadAction } from "./actions";
import { Reducer } from "redux";

export interface DownloadState {
  // ダウンロード中ステータス管理
  // ダウンロード中のリソース(ボタン)ID、存在する場合はダウンロード中という判断
  downloadResourceId: string;
}

const initialState: DownloadState = {
  downloadResourceId: ""
};

export const download: Reducer<DownloadState, DownloadAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "download/DOWNLOAD_START":
      return {
        downloadResourceId: action.payload
      };
    case "download/DOWNLOAD_STOP":
      return {
        downloadResourceId: ""
      };
    default:
      return state;
  }
};
