import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";

const initialState = {
  // 比較表関係
  comparisonHead: {},
  initialData: {},
  financeComparisonData: {},
  segmentComparisonData: {},
  // 条件選択状態
  comparisonConditions: {
    userIndexValue: "", // 項目
    accKbnValue: "", // 期間単位
    ytConditionValue: "", // 有報
    scConditionValue: "", // 連結
    currencyConditionValue: "", // 通貨単位
    ltmValue: "0", // LTM
    expectValue: "0", // 日経予想
    listingCorpSelect: "", // 上場
    unlistedHolderCorpSelect: "", // 非上場有報
    unlistedTdbCorpSelect: "", // 非上場(TDB)
    unlistedCorpSelect: "", // 非上場(日経)
    industrycorptop: "", // 売上高上位
    industrycorptopCondition: "",
    northAmericaSelect: "", // 北米
    southAmericaSelect: "", // 南米
    europeSelect: "", // 欧州
    asiaSelect: "", // アジア
    mideastSelect: "", // 中東
    africaSelect: "", // アフリカ
    unlistedNorthAmericaSelect: "", // 北米非上場
    unlistedSouthAmericaSelect: "", // 南米非上場
    unlistedEuropeSelect: "", // 欧州非上場
    unlistedAsiaSelect: "", // アジア非上場
    unlistedMideastSelect: "", // 中東非上場
    unlistedAfricaSelect: "", // アフリカ非上場
    nkgncodes: "", // 業界
    mainFlg: "", // 主／所属業種フラグ
    sortId: "", // ソート列番号
    sortFlag: "", // ソートの種類 up|down
    indexGroupIdFlg: "0" // 指標グループID値の判断フラグ
  },
  segmentComparisonConditions: {
    sortId: "", // ソート列番号
    sortFlag: "" // ソートの種類 up|down
  },
  // 企業数
  corpCounts: {
    africaCorpCount: "", // アフリカ
    mideastCorpCount: "", // 中東
    asiaCorpCount: "", // アジア
    europeCorpCount: "", // ヨーロッパ
    southAmericaCorpCount: "", // 南米
    northAmericaCorpCount: "", // 北米
    unlistedAfricaCorpCount: "", // アフリカ非上場
    unlistedMideastCorpCount: "", // 中東非上場
    unlistedAsiaCorpCount: "", // アジア非上場
    unlistedEuropeCorpCount: "", // ヨーロッパ非上場
    unlistedSouthAmericaCorpCount: "", // 南米非上場
    unlistedNorthAmericaCorpCount: "", // 北米非上場
    unlistedTdbCorpCount: "", // 非上場TDB
    unlistedCorpCount: "", // 非上場日経
    unlistedHolderCorpCount: "", // 有報
    listingCorpCount: "" // 上場
  },
  segmentLabelList: [], // セグメントリスト
  categoryItems: [], // 業界リスト
  mainFlgList: [], // 主／所属業種リスト
  resultData: {},
  nikkeiCodeList: "", // 企業コードリスト
  nkCodeListTotal: "", // 全ての企業コードリスト
  contentId: "", // 比較表識別ID "corpcomparisonlist"
  isLoadingComparisonHead: false, // 条件ローディング中フラグ
  isLoadingFinanceComparisonData: false, // 検索ローディング中フラグ
  isLoadingSegmentComparisonData: false, // 検索ローディング中フラグ
  dlPeriodList: [],
  dlPeriodFrom: "",
  dlPeriodTo: "",
  //ユーザー指標編集
  selectedIndex: [],
  indexTree: [],
  // チャート
  isLoadingChartCount: false,
  chartData: {},
  isLoadingChartData: false,
  zAxisList: [],
  // 企業検索
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  selectedNeedsMstL: "",
  selectedNeedsMstM: "",
  selectedNeedsMstS: "",
  corpList: [],
  isLoadingCorpList: false,
  selectedCodes: "",
  searchResult: null,
  recentList: [],
  bookmarkList: [],
  selectedFolderId: "", // 選択中ブックマークフォルダ
  concatenatedCorpFilterValue: "",
  isLoadingSearch: false,
  isLoadingRecent: false,
  isLoadingBookmark: false,
  // 比較表のタブ
  resultTabList: [
    { label: "企業", value: true },
    { label: "セグメント", value: false }
  ],
  // 遷移元から指定する企業コード
  defaultSelectedCodes: "",
  // その他
  needsSearchFinance: false, // 財務再検索フラグ
  needsSearchSegment: false, // セグメント再検索フラグ
  bookmarkFolderList: [],
  isLoadingInit: false // 初期表示APIローディング中フラグ
};

const buildComparisonConditions = payload => {
  const comparisonConditions = {};
  Object.keys(initialState.comparisonConditions).forEach(key => {
    if (key === "nkgncodes") {
      return;
    } else if (
      key === "asiaSelect" &&
      payload.hasOwnProperty("asiaPacificSelect")
    ) {
      comparisonConditions["asiaSelect"] = payload["asiaPacificSelect"];
    } else if (payload.hasOwnProperty(key)) {
      comparisonConditions[key] = payload[key];
    }
  });
  return comparisonConditions;
};

export default handleActions(
  {
    /**
     * 比較表
     */
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...state,
      isLoadingInit: true
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: state => ({
      ...state,
      isLoadingInit: false
    }),
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => {
      const comparisonConditions = buildComparisonConditions(action.payload[0]);
      comparisonConditions["userIndexValue"] =
        action.meta.dispItemList ||
        action.payload[1].userIndexGroupListDto.userIndexGroupListOfBasic[0]
          .value;
      comparisonConditions["accKbnValue"] =
        action.payload[1].accKbnList[0].value;
      comparisonConditions["ytConditionValue"] =
        action.payload[1].ytCommonConditionList[0].value;
      comparisonConditions["scConditionValue"] =
        action.payload[1].scCommonConditionList[0].value;
      comparisonConditions["currencyConditionValue"] =
        action.payload[1].currencyList[0].value;
      comparisonConditions["nkgncodes"] = action.payload[2].nkgncodes;
      comparisonConditions["mainFlg"] = action.payload[2].mainFlgList[0].value;
      const payload = action.payload[0];
      return {
        ...state,
        nikkeiCodeList: action.payload[0].nikkeiCodeList,
        nkCodeListTotal: action.payload[0].nikkeiCodeList,
        contentId: action.payload[0].contentId,
        initialData: action.payload[0],
        comparisonHead: action.payload[1],
        comparisonConditions: {
          ...state.comparisonConditions,
          ...comparisonConditions
        },
        corpCounts: {
          africaCorpCount: payload.africaNkcodeCount,
          asiaCorpCount: payload.asiaPacificNkcodeCount,
          europeCorpCount: payload.europeNkcodeCount,
          listingCorpCount: payload.listingNkcodeCount,
          mideastCorpCount: payload.mideastNkcodeCount,
          northAmericaCorpCount: payload.northAmericaNkcodeCount,
          southAmericaCorpCount: payload.southAmericaNkcodeCount,
          unlistedCorpCount: payload.unListedNkcodeCount,
          unlistedHolderCorpCount: payload.unListedHolderNkcodeCount,
          unlistedTdbCorpCount: payload.unListedTdbCount,
          unlistedAfricaCorpCount: payload.unlistedAfricaNkcodeCount,
          unlistedMideastCorpCount: payload.unlistedMideastNkcodeCount,
          unlistedAsiaCorpCount: payload.unlistedAsiaPacificNkcodeCount,
          unlistedEuropeCorpCount: payload.unlistedEuropeNkcodeCount,
          unlistedSouthAmericaCorpCount:
            payload.unlistedSouthAmericaNkcodeCount,
          unlistedNorthAmericaCorpCount: payload.unlistedNorthAmericaNkcodeCount
        },
        categoryItems: action.payload[2].corpEntityList,
        mainFlgList: action.payload[2].mainFlgList,
        zAxisList: action.payload[0].labelValueDtoList08,
        segmentLabelList: action.payload[0].labelValueDto,
        isLoadingInit: false
      };
    },
    [`${ActionTypes.GET_ITEMS_PER_PAGE}_FULFILLED`]: (state, action) => ({
      ...state,
      count: action.payload
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonHead: true
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_FULFILLED`]: (state, action) => {
      const comparisonHead = action.payload;
      let newComparisonConditions = { ...state.comparisonConditions };
      const {
        userIndexGroupListOfBasic,
        userIndexGroupListOfUserSet
      } = comparisonHead.userIndexGroupListDto;
      // セレクトボックス初期値の取得
      let userIndexValue;
      if (
        !isEmpty(action.meta.dispItemListInitValue) &&
        userIndexGroupListOfUserSet.find(
          index => index.value == action.meta.dispItemListInitValue
        )
      ) {
        // 選択中のユーザ指標
        userIndexValue = action.meta.dispItemListInitValue;
      } else if (!isEmpty(action.meta.nowEditItem)) {
        // ユーザ指標編集で選択されたアイテムのラベル
        userIndexValue = userIndexGroupListOfUserSet.find(
          index => index.label == action.meta.nowEditItem
        ).value;
      } else {
        // デフォルト値
        userIndexValue = !isEmpty(userIndexGroupListOfBasic)
          ? userIndexGroupListOfBasic[0].value
          : userIndexGroupListOfUserSet[0].value;
      }
      newComparisonConditions = {
        ...newComparisonConditions,
        userIndexValue: userIndexValue
      };
      return {
        ...state,
        comparisonHead: action.payload,
        comparisonConditions: {
          ...state.comparisonConditions,
          ...newComparisonConditions
        },
        isLoadingComparisonHead: false
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingComparisonHead: false
    }),
    [`${ActionTypes.FINANCE_SEARCH}_PENDING`]: state => ({
      ...state,
      isLoadingFinanceComparisonData: true
    }),
    [`${ActionTypes.FINANCE_SEARCH}_FULFILLED`]: (state, action) => {
      const isCheckboxesActive =
        action.payload.selectItemDisabled == "1" ||
        action.payload.selectItemDisabled == "3";
      const { meta = {} } = action;
      return {
        ...state,
        financeComparisonData: action.payload,
        nikkeiCodeList: action.payload.currentNkCodeList,
        nkCodeListTotal: action.payload.nkCodeListTotalList,
        comparisonConditions: {
          ...state.comparisonConditions,
          // チェックボックス群が無効になった場合、選択状態解除
          ltmValue: isCheckboxesActive
            ? state.comparisonConditions.ltmValue
            : "0",
          expectValue: isCheckboxesActive
            ? state.comparisonConditions.expectValue
            : "0",
          // 条件追加の場合には、チェックボックス状態を更新するために meta で受け取る
          ...(meta.comparisonConditions || {})
        },
        isLoadingFinanceComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.FINANCE_SEARCH}_REJECTED`]: state => ({
      ...state,
      isLoadingFinanceComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.SEGMENT_SEARCH}_PENDING`]: (state, action) => ({
      ...state,
      isLoadingSegmentComparisonData: true,
      comparisonConditions: {
        ...state.comparisonConditions,
        indexGroupIdFlg: action.meta.indexGroupIdFlg
      }
    }),
    [`${ActionTypes.SEGMENT_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      segmentComparisonData: action.payload,
      isLoadingSegmentComparisonData: false
    }),
    [`${ActionTypes.SEGMENT_SEARCH}_REJECTED`]: state => ({
      ...state,
      isLoadingSegmentComparisonData: false
    }),
    [`${ActionTypes.LIST_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...buildComparisonConditions(action.payload)
      },
      nikkeiCodeList: action.payload.nikkeiCodeList,
      nkCodeListTotal: action.payload.nikkeiCodeList
    }),
    [ActionTypes.SELECTED_CHANGE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [ActionTypes.SEGMENT_SELECTED_CHANGE]: (state, action) => ({
      ...state,
      segmentComparisonConditions: {
        ...state.segmentComparisonConditions,
        ...action.payload
      }
    }),
    [`${ActionTypes.FINANCE_SORT}_PENDING`]: state => ({
      ...state,
      isLoadingFinanceComparisonData: true
    }),
    [`${ActionTypes.FINANCE_SORT}_FULFILLED`]: (state, action) => ({
      ...state,
      financeComparisonData: {
        ...state.financeComparisonData,
        tableData: action.payload.tableData,
        currentPage: action.payload.currentPage,
        startNo: action.payload.startNo,
        endNo: action.payload.endNo
      },
      isLoadingFinanceComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.FINANCE_SORT}_REJECTED`]: state => ({
      ...state,
      isLoadingFinanceComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.SEGMENT_SORT}_PENDING`]: state => ({
      ...state,
      isLoadingSegmentComparisonData: true
    }),
    [`${ActionTypes.SEGMENT_SORT}_FULFILLED`]: (state, action) => ({
      ...state,
      segmentComparisonData: {
        ...state.segmentComparisonData,
        tableData: action.payload.tableData,
        currentPage: action.payload.currentPage
      },
      segmentComparisonConditions: {
        sortId: action.meta.sortId,
        sortFlag: action.meta.sortFlag
      },
      isLoadingSegmentComparisonData: false
    }),
    [`${ActionTypes.SEGMENT_SORT}_REJECTED`]: state => ({
      ...state,
      isLoadingSegmentComparisonData: false
    }),
    [`${ActionTypes.FINANCE_ROW_DELETE}_PENDING`]: state => ({
      ...state,
      isLoadingFinanceComparisonData: true
    }),
    [`${ActionTypes.FINANCE_ROW_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      selectedCodes: action.payload.nkCodeListTotalList,
      financeComparisonData: {
        ...state.financeComparisonData,
        // 必要なデータだけマージする
        africaNkcodeCount: action.payload.africaNkcodeCount,
        asiaPacificNkcodeCount: action.payload.asiaPacificNkcodeCount,
        currentNkCodeList: action.payload.currentNkCodeList,
        errorMessage: action.payload.errorMessage,
        europeNkcodeCount: action.payload.europeNkcodeCount,
        hasReachedMaxDisplayableNkcodeCount:
          action.payload.hasReachedMaxDisplayableNkcodeCount,
        industrycorptop: action.payload.industrycorptop,
        listingNkcodeCount: action.payload.listingNkcodeCount,
        maxDisplayableNkcodeCount: action.payload.maxDisplayableNkcodeCount,
        maxWidthOfAllColumns: action.payload.maxWidthOfAllColumns,
        messageDto: action.payload.messageDto,
        mideastNkcodeCount: action.payload.mideastNkcodeCount,
        nkCodeListTotalList: action.payload.nkCodeListTotalList,
        nkCodeShowMaxSize: action.payload.nkCodeShowMaxSize,
        nkcodeCountForShow: action.payload.nkcodeCountForShow,
        northAmericaNkcodeCount: action.payload.northAmericaNkcodeCount,
        sortFlag: action.payload.sortFlag,
        sortId: action.payload.sortId,
        sortedNkCodeList: action.payload.sortedNkCodeList,
        southAmericaNkcodeCount: action.payload.southAmericaNkcodeCount,
        tableData: action.payload.tableData,
        unListedHolderNkcodeCount: action.payload.unListedHolderNkcodeCount,
        unListedNkcodeCount: action.payload.unListedNkcodeCount,
        unListedTdbNkcodeCount: action.payload.unListedTdbNkcodeCount
      },
      // セグメントタブへ削除情報を反映させるためsegmentComparisonDataも更新する
      segmentComparisonData: {
        ...state.segmentComparisonData,
        allNkCodeList: action.payload.currentNkCodeList
      },
      nikkeiCodeList: action.payload.currentNkCodeList,
      nkCodeListTotal: action.payload.nkCodeListTotalList,
      isLoadingFinanceComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.FINANCE_ROW_DELETE}_REJECTED`]: state => ({
      ...state,
      isLoadingFinanceComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.SEGMENT_ROW_DELETE}_PENDING`]: state => ({
      ...state,
      isLoadingSegmentComparisonData: true
    }),
    [`${ActionTypes.SEGMENT_ROW_DELETE}_FULFILLED`]: (state, action) => {
      const selectedCodes = [...action.payload.allNkCodeList.split(",")]
        .map(code => code.split("#")[0]) // #以降削除
        .filter((code, index, self) => self.indexOf(code) === index) // 重複削除
        .filter(code => code !== "")
        .sort()
        .join();
      return {
        ...state,
        segmentComparisonData: action.payload,
        selectedCodes: selectedCodes,
        nikkeiCodeList: selectedCodes,
        nkCodeListTotal: selectedCodes,
        isLoadingSegmentComparisonData: false
      };
    },
    [`${ActionTypes.SEGMENT_ROW_DELETE}_REJECTED`]: state => ({
      ...state,
      isLoadingSegmentComparisonData: false
    }),
    [ActionTypes.COMPARISON_DATAS_CLEAR]: state => {
      const { initialData, comparisonHead } = state;
      const comparisonConditions = buildComparisonConditions(initialData);
      comparisonConditions["userIndexValue"] =
        comparisonHead.userIndexGroupListDto.userIndexGroupListOfBasic[0].value;
      comparisonConditions["accKbnValue"] = comparisonHead.accKbnList[0].value;
      comparisonConditions["ytConditionValue"] =
        comparisonHead.ytCommonConditionList[0].value;
      comparisonConditions["scConditionValue"] =
        comparisonHead.scCommonConditionList[0].value;
      comparisonConditions["currencyConditionValue"] =
        comparisonHead.currencyList[0].value;
      return {
        ...state,
        // 空に変更
        financeComparisonData: initialState.financeComparisonData,
        segmentComparisonData: initialState.segmentComparisonData,
        segmentComparisonConditions: {
          ...initialState.segmentComparisonConditions
        },
        // 初期化
        nikkeiCodeList: initialData.nikkeiCodeList,
        nkCodeListTotal: initialData.nikkeiCodeList,
        contentId: initialData.contentId,
        comparisonConditions: {
          ...state.comparisonConditions,
          ...comparisonConditions
        },
        corpCounts: {
          africaCorpCount: initialData.africaNkcodeCount,
          asiaCorpCount: initialData.asiaPacificNkcodeCount,
          europeCorpCount: initialData.europeNkcodeCount,
          listingCorpCount: initialData.listingNkcodeCount,
          mideastCorpCount: initialData.mideastNkcodeCount,
          northAmericaCorpCount: initialData.northAmericaNkcodeCount,
          southAmericaCorpCount: initialData.southAmericaNkcodeCount,
          unlistedCorpCount: initialData.unListedNkcodeCount,
          unlistedHolderCorpCount: initialData.unListedHolderNkcodeCount,
          unlistedTdbCorpCount: initialData.unListedTdbCount
        },
        zAxisList: initialData.labelValueDtoList08,
        segmentLabelList: initialData.labelValueDto
      };
    },
    [ActionTypes.RESULT_DATA_APPLY]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [ActionTypes.TAB_SWITCH]: (state, action) => ({
      ...state,
      resultTabList: action.payload
    }),

    /**
     * ユーザ指標編集
     */
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_FULFILLED`]: (state, action) => {
      const newIndexTree = action.payload;
      let selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      if (action.meta.kensakuName !== undefined && selectedIndex.length === 0) {
        selectedIndex = state.selectedIndex;
      }
      if (!isEmpty(action.meta.kensakuName)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            if (!isEmpty(item.child)) {
              item.childExistFlag = true;
              item.isOpen = true;
              item.childLoaded = true;
              setOpenToAllChildState(item.child);
            }
            return item;
          });
        };
        newIndexTree.treeDataList = setOpenToAllChildState(
          newIndexTree.treeDataList
        );
      }
      return {
        ...state,
        indexTree: newIndexTree,
        selectedOption: isEmpty(state.selectedOption)
          ? action.payload.userSelect[0].value
          : state.selectedOption,
        selectedIndex: selectedIndex
      };
    },
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      const newIndexTree = { ...state.indexTree };
      let depth = 0;
      const updateTargetChildState = treeDataList => {
        const targetParentItemId = meta.currentTreeIds[depth];
        for (const prop in treeDataList) {
          const item = treeDataList[prop];
          if (item.id === targetParentItemId) {
            depth += 1;
            updateTargetChildState(item.child);
          } else if (!targetParentItemId && item.id === meta.currentId) {
            if (!item.childLoaded) {
              item.child = payload.treeDataList;
              item.childLoaded = true;
              item.isOpen = true;
            } else {
              item.isOpen = !item.isOpen;
            }
          }
        }
      };
      updateTargetChildState(newIndexTree.treeDataList);

      return {
        ...state,
        indexTree: newIndexTree
      };
    },
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_FULFILLED`]: state => state,
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_ADD}_FULFILLED`]: (state, action) => {
      const newSelectedIndex = [...state.selectedIndex];
      // 存在、上限チェック
      const matchedIndex = newSelectedIndex.find(
        index => index.screeningId == action.payload.screeningId
      );
      if (
        !matchedIndex &&
        parseInt(state.indexTree.maxCount) > newSelectedIndex.length
      ) {
        newSelectedIndex.push(action.payload);
      }
      return {
        ...state,
        selectedIndex: newSelectedIndex
      };
    },
    [`${ActionTypes.INDEX_ADD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedIndex: action.payload
    }),
    [ActionTypes.INDEX_REMOVE_ALL]: state => ({
      ...state,
      selectedIndex: [],
      // 選択中の条件も先頭にリセット
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      indexTree: {
        ...state.indexTree,
        userSelect: action.payload
      },
      selectedOption: action.payload[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_SET}_FULFILLED`]: state => ({
      ...state,
      indexTree: {} // クリア
    }),
    [`${ActionTypes.CONDITION_SET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      return {
        ...state,
        selectedIndex: selectedIndex,
        selectedOption: action.meta.userSetId
      };
    },
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_ALL_CLEAR]: state => ({
      ...state,
      indexTree: {},
      selectedOption: "",
      parentId: "",
      isAvailableIndex: "",
      selectedIndex: [],
      savedConditionList: []
    }),
    [`${ActionTypes.DOWNLOAD_PERIOD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      dlPeriodList: action.payload.periodList,
      dlPeriodFrom: action.payload.periodFrom,
      // レスポンスにToの初期値なし
      dlPeriodTo: action.payload.periodList[0].value
    }),
    [ActionTypes.DOWNLOAD_PERIOD_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),

    /**
     * チャート
     */
    // チャート選択件数制御数データ取得
    [`${ActionTypes.CHART_COUNT_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartCount: true
    }),
    [`${ActionTypes.CHART_COUNT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData:
        parseInt(action.payload) >= action.meta.selectedCount
          ? state.chartData
          : {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_COUNT_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingChartCount: false
    }),
    // チャートデータ取得
    [`${ActionTypes.CHART_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: {
        ...state.chartData,
        chart01Str: action.payload.chart01Str,
        chart02Str: action.payload.chart02Str,
        chart03Str: action.payload.chart03Str,
        chart04Str: action.payload.chart04Str
      },
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_REJECTED`]: state => ({
      ...state,
      isLoadingChartData: false
    }),
    [ActionTypes.CHART_DATA_CLEAR]: state => ({
      ...state,
      chartData: {}
    }),

    /**
     * 企業検索
     */
    [`${ActionTypes.NEEDS_MST_L_GET}_PENDING`]: state => ({
      ...state,
      needsMstL: [],
      needsMstM: [],
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstL: true
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      needsMstM: [],
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstM: true
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      corpList: [],
      selectedNeedsMstL: action.meta,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      corpList: [],
      selectedNeedsMstM: action.meta,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.CORP_GET}_PENDING`]: state => ({
      ...state,
      corpList: [],
      isLoadingCorpList: true
    }),
    [`${ActionTypes.CORP_GET}_FULFILLED`]: (state, action) => {
      // すでに選択済みの項目はチェックした状態で表示するためにisSelectedキーをtrueにする
      const corpList = action.payload.companyList.map(item => {
        for (const prop in state.selectedCorpList) {
          const selectedItem = state.selectedCorpList[prop];
          if (selectedItem.companyCode === item.companyCode) {
            item.isSelected = true;
            break;
          }
        }
        return item;
      });
      return {
        ...state,
        corpList: corpList,
        concatenatedCorpFilterValue: action.meta.concatenatedCorpFilterValue,
        selectedNeedsMstS: action.meta.nkgncodes,
        isLoadingCorpList: false
      };
    },
    [`${ActionTypes.CORP_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingCorpList: false
    }),
    [ActionTypes.CORP_SELECT]: (state, action) => ({
      ...state,
      selectedCodes: action.payload // 更新後の選択済み項目と追加された項目をマージ
    }),
    [`${ActionTypes.CORP_SEARCH}_PENDING`]: state => ({
      ...state,
      isLoadingSearch: true
    }),
    [`${ActionTypes.CORP_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      searchResult: action.payload.companyList,
      isLoadingSearch: false
    }),
    [`${ActionTypes.RECENT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      recentList: action.payload.companyList
    }),
    [`${ActionTypes.BOOKMARK_GET}_PENDING`]: (state, action) => ({
      ...state,
      selectedFolderId: action.meta.selectedFolder
    }),
    [`${ActionTypes.BOOKMARK_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      bookmarkList: action.payload.companyList
    }),
    [`${ActionTypes.SELECTED_BOOKMARK_FOLDER_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      selectedFolderId: action.payload.selectedFolder.folderId
    }),
    [`${ActionTypes.FILTER_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      concatenatedCorpFilterValue: action.payload
    }),

    /**
     * 遷移元から企業コードを渡された場合
     */
    [ActionTypes.DEFAULT_SELECTED_CODES_SET]: (state, action) => ({
      ...state,
      defaultSelectedCodes: action.payload
    }),
    [ActionTypes.DEFAULT_SELECTED_CODES_CLEAR]: state => ({
      ...state,
      defaultSelectedCodes: ""
    }),

    /**
     * その他
     */
    [ActionTypes.SEARCH_FINANCE_FLAG_SET]: state => ({
      ...state,
      needsSearchFinance: true
    }),
    [ActionTypes.SEARCH_FINANCE_FLAG_RESET]: state => ({
      ...state,
      needsSearchFinance: false
    }),
    [ActionTypes.SEARCH_SEGMENT_FLAG_SET]: state => ({
      ...state,
      needsSearchSegment: true
    }),
    [ActionTypes.SEARCH_SEGMENT_FLAG_RESET]: state => ({
      ...state,
      needsSearchSegment: false
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      },
      segmentComparisonConditions: {
        sortId: "",
        sortFlag: ""
      }
    }),
    [`${ActionTypes.FOLDER_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      bookmarkFolderList: action.payload.folderList
    }),
    [ActionTypes.ALL_RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  // 比較表関係
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => {
      return Promise.all([
        request(620, params.payload),
        request(595, {
          ...params.payload,
          tabId: "4",
          userSetKbn: "2",
          displayKbn: "2"
        }),
        request(622, params.payload)
      ]);
    },
    params => params.meta
  ),
  getComparisonHead: createAction(
    ActionTypes.COMPARISON_HEAD_GET,
    params => request(595, params.payload),
    params => params.meta
  ),
  getItemsPerPage: createAction(ActionTypes.GET_ITEMS_PER_PAGE, params =>
    request(613, params)
  ),
  searchFinance: createAction(
    ActionTypes.FINANCE_SEARCH,
    params => request(597, params),
    (_, meta) => ({ ...meta })
  ),
  deleteRowFinance: createAction(ActionTypes.FINANCE_ROW_DELETE, params =>
    request(597, params)
  ),
  sortFinance: createAction(ActionTypes.FINANCE_SORT, params =>
    request(609, params)
  ),
  searchSegment: createAction(
    ActionTypes.SEGMENT_SEARCH,
    params => request(633, params),
    params => ({ indexGroupIdFlg: params.indexGroupIdFlg })
  ),
  sortSegment: createAction(
    ActionTypes.SEGMENT_SORT,
    params => request(635, params),
    params => ({ sortId: params.sortId, sortFlag: params.sortFlag })
  ),
  deleteRowSegment: createAction(ActionTypes.SEGMENT_ROW_DELETE, params =>
    request(634, params)
  ),
  clearComparisonDatas: createAction(ActionTypes.COMPARISON_DATAS_CLEAR),
  changeList: createAction(ActionTypes.LIST_CHANGE, params =>
    request(621, params)
  ),
  changeSelected: createAction(ActionTypes.SELECTED_CHANGE),
  changeSegmentSelected: createAction(ActionTypes.SEGMENT_SELECTED_CHANGE),
  getDownloadPeriod: createAction(ActionTypes.DOWNLOAD_PERIOD_GET, params =>
    request(616, params)
  ),
  setDownloadPeriod: createAction(
    ActionTypes.DOWNLOAD_PERIOD_SET,
    period => period,
    (period, key) => ({ key })
  ),
  applyResultData: createAction(ActionTypes.RESULT_DATA_APPLY),
  switchTab: createAction(ActionTypes.TAB_SWITCH),
  // ユーザ指標関係
  getIndexTreeRoot: createAction(
    ActionTypes.INDEX_TREE_ROOT_GET,
    params => request(599, params),
    params => ({ kensakuName: params.kensakuName })
  ),
  getIndexTreeChild: createAction(
    ActionTypes.INDEX_TREE_CHILD_GET,
    params => request(606, params.payload),
    params => params.meta
  ),
  getIndexAvailability: createAction(
    ActionTypes.INDEX_AVAILABILITY_GET,
    param => request(605, param)
  ),
  addIndex: createAction(ActionTypes.INDEX_ADD, params => request(607, params)),
  updateIndex: createAction(ActionTypes.INDEX_UPDATE),
  removeAllIndex: createAction(ActionTypes.INDEX_REMOVE_ALL),
  getSavedConditionList: createAction(ActionTypes.SAVED_CONDITION_GET, params =>
    request(600, params)
  ),
  getSavedConditionSelectList: createAction(
    ActionTypes.SAVED_CONDITION_SELECT_GET,
    params => request(608, params)
  ),
  deleteSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_DELETE,
    params => request(602, params)
  ),
  updateSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_UPDATE,
    params => request(601, params)
  ),
  getSavedConditionDetail: createAction(
    ActionTypes.SAVED_CONDITION_DETAIL_GET,
    params => request(604, params),
    params => ({ userSetId: params.userSetId })
  ),
  setCondition: createAction(
    ActionTypes.CONDITION_SET,
    params => request(603, params),
    params => ({ userSetName: params.userSetName })
  ),
  clearIndexAll: createAction(ActionTypes.INDEX_ALL_CLEAR),
  // 企業検索
  getNeedsMstL: createAction(ActionTypes.NEEDS_MST_L_GET, params =>
    request(625, { ...params, corpIndustyKbn: "7" })
  ),
  getNeedsMstM: createAction(
    ActionTypes.NEEDS_MST_M_GET,
    params => request(626, { ...params, corpIndustyKbn: "7" }),
    params => params.nkgncodel
  ),
  getNeedsMstS: createAction(
    ActionTypes.NEEDS_MST_S_GET,
    params => request(627, { ...params, corpIndustyKbn: "7" }),
    params => params.nkgncodem
  ),
  getCorp: createAction(
    ActionTypes.CORP_GET,
    params => request(628, { ...params, corpIndustyKbn: "5" }),
    params => params
  ),
  selectCorp: createAction(ActionTypes.CORP_SELECT),
  searchCorp: createAction(ActionTypes.CORP_SEARCH, params =>
    request(623, { ...params, corpIndustyKbn: "7" })
  ),
  getRecent: createAction(ActionTypes.RECENT_GET, params =>
    request(624, { ...params, corpIndustyKbn: "7" })
  ),
  getSelectedBookmarkFolder: createAction(
    ActionTypes.SELECTED_BOOKMARK_FOLDER_GET,
    params => request(18, params)
  ),
  getBookmark: createAction(
    ActionTypes.BOOKMARK_GET,
    params => request(624, { ...params, corpIndustyKbn: "7" }),
    params => ({ selectedFolder: params.selectedFolder })
  ),
  updateFilter: createAction(ActionTypes.FILTER_UPDATE),

  // チャート関係
  getChartCount: createAction(
    ActionTypes.CHART_COUNT_GET,
    params => request(614, params.payload),
    params => params.meta
  ),
  getChartData: createAction(ActionTypes.CHART_DATA_GET, params =>
    request(629, params)
  ),
  updateChartData: createAction(ActionTypes.CHART_DATA_UPDATE, params =>
    request(630, { ...params, corpIndustyKbn: "7" })
  ),
  clearChartData: createAction(ActionTypes.CHART_DATA_CLEAR),

  // 遷移元から企業コードを渡された場合
  setDefaultSelectedCodes: createAction(ActionTypes.DEFAULT_SELECTED_CODES_SET),
  clearDefaultSelectedCodes: createAction(
    ActionTypes.DEFAULT_SELECTED_CODES_CLEAR
  ),

  // その他
  setSearchFinanceFlag: createAction(ActionTypes.SEARCH_FINANCE_FLAG_SET),
  resetSearchFinanceFlag: createAction(ActionTypes.SEARCH_FINANCE_FLAG_RESET),
  setSearchSegmentFlag: createAction(ActionTypes.SEARCH_SEGMENT_FLAG_SET),
  resetSearchSegmentFlag: createAction(ActionTypes.SEARCH_SEGMENT_FLAG_RESET),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET),

  resetAll: createAction(ActionTypes.ALL_RESET)
};
