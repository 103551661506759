// 初期表示
export const PAGE_INIT = "corp/whoswho/PAGE_INIT";
// 人事異動一覧を取得
export const PERSONNEL_GET = "corp/whoswho/PERSONNEL_GET";
// 人事情報一覧をさらに読み込む
export const MORE_PERSONNEL_GET = "corp/whoswho/MORE_PERSONNEL_GET";
// 人事詳細情報取得
export const DETAIL_GET = "corp/whoswho/DETAIL_GET";
// 人事異動情報取得
export const PRTSONNEL_CHANGES_GET = "corp/whoswho/PRTSONNEL_CHANGES_GET";
// ニュース一覧取得
export const NEWS_LIST_GET = "corp/whoswho/NEWS_LIST_GET";
// ニュースPDF取得
export const NEWS_PDF_GET = "corp/whoswho/NEWS_PDF_GET";
// 人事情報一覧の表示ページング情報セット
export const PAGE_SET = "corp/whoswho/PAGE_SET";
// 人物選択
export const PERSON_SELECT = "corp/whoswho/PERSON_SELECT";
// 1ページあたりの表示件数変更
export const PER_PAGE_CHANGE = "corp/whoswho/PER_PAGE_CHANGE";
