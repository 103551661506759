import { Reducer } from "redux";
import produce from "immer";

import { ToolScreeningCorpModernItemAction } from "./actions";
import {
  Corp,
  DisplayCondition,
  FilterCondition,
  FreeWordTarget,
  ScreeningItem,
  DisplayConditionItem,
  ConditionDisplayItem,
  SourceItem,
  INNEY_CODE,
  AREA_CODE,
  CORP_TYPE_CODE,
  SavedCondition,
  ErrorCondition,
  ScatterList,
  ConditionItem,
  LISTED_AREA_CODE,
  UNLISTED_AREA_CODE
} from "models/CorpScreening";
import { getActive } from "components/Tool/Screening/Corporation/Item/Modern/utils";

const getChangedSourceItems = (
  sourceItems: SourceItem[],
  code: string[],
  selected: boolean
) => {
  return sourceItems.map(item => {
    if (code.includes(item.inneyCode)) {
      return { ...item, selected };
    }
    return { ...item };
  });
};

interface ToolScreeningCorpItemState {
  isInitializing: boolean;
  setInitialized: boolean;
  isModern: boolean;
  savedConditions: {
    user: SavedCondition[];
    recommended: SavedCondition[];
  };
  condition: {
    freeWord: {
      text: string;
      target: string[];
    };
    displayCondition: { [key in DisplayCondition]: string };
    filter: FilterCondition[];
    displayItems: ConditionDisplayItem[];
    corp: Corp[];
    sourceItems: SourceItem[];
  };
  conditionError: ErrorCondition[];
  defaultCondition: {
    filter: FilterCondition[];
    displayItems: ConditionDisplayItem[];
  };
  freeWordTargetList: FreeWordTarget[];
  currencyList: DisplayConditionItem[];
  scConditionList: DisplayConditionItem[];
  ytConditionList: DisplayConditionItem[];
  companySelectSourceItems: SourceItem[]; // 企業選択モーダル内一覧からタブの企業種別
  resultDisplayConditionList: {
    accKbnList: DisplayConditionItem[];
    currency: DisplayConditionItem[];
    scConditionList: DisplayConditionItem[];
    ytConditionList: DisplayConditionItem[];
  };
  conditionData: {
    dlxls: string;
    dlcsv: string;
    dlxls_chart: string;
    dlcsv_chart: string;
    dlxlspath_chart: string;
    dlcsvpath_chart: string;
    dlxlspath: string;
    dlcsvpath: string;
    dlpropriety: string;
    dlpropriety_chart: string;
    dlpropriety_segment: string;
    labelValueDtoList08: ConditionItem[];
  };
  result: {
    data?: ScreeningItem[][];
    dispItemList?: string;
    headers?: string[][];
    currentPage?: string;
    currentNkCodeList?: string;
    deletedNkCodeList?: string;
    screeningIdAndConditionsListStr?: string;
    needsListStr?: string;
    nkCodeListTotalList?: string;
    screeningIdListStr?: string;
    toolsearchKeyword?: string;
    accKbn?: string;
    currency?: string;
    scCondition?: string;
    ytCondition?: string;
    ltm: boolean;
    expect: boolean;
    maxDisplayableNkcodeCount?: number;
    nkcodeCountForShow?: number;
    totalCount?: number;
    groupItemDupulicateFlg?: boolean;
    sanpuzuCapableScreeningItemList?: any[];
    listingNkcodeTotalCount?: string;
    unListedNkcodeTotalCount?: string;
    unListedHolderNkcodeTotalCount?: string;
    unListedTdbNkcodeTotalCount?: string;
    africaNkcodeTotalCount?: string;
    asiaPacificNkcodeTotalCount?: string;
    europeNkcodeTotalCount?: string;
    northAmericaNkcodeTotalCount?: string;
    southAmericaNkcodeTotalCount?: string;
    mideastNkcodeTotalCount?: string;
    unlistedAfricaNkcodeTotalCount?: string;
    unlistedAsiaPacificNkcodeTotalCount?: string;
    unlistedEuropeNkcodeTotalCount?: string;
    unlistedNorthAmericaNkcodeTotalCount?: string;
    unlistedSouthAmericaNkcodeTotalCount?: string;
    unlistedMideastNkcodeTotalCount?: string;
    selectCorps?: string;
    listingCorpSelect?: boolean;
    unlistedHolderCorpSelect?: boolean;
    unlistedCorpSelect?: boolean;
    unlistedTdbCorpSelect?: boolean;
    northAmericaSelect?: boolean;
    southAmericaSelect?: boolean;
    europeSelect?: boolean;
    asiaSelect?: boolean;
    mideastSelect?: boolean;
    africaSelect?: boolean;
    unlistedNorthAmericaSelect?: boolean;
    unlistedSouthAmericaSelect?: boolean;
    unlistedEuropeSelect?: boolean;
    unlistedAsiaSelect?: boolean;
    unlistedMideastSelect?: boolean;
    unlistedAfricaSelect?: boolean;
    sortFlag?: "up" | "down";
    sortId?: number;
  };
  selectedTab: "company" | "segment";
  segmentType: "business" | "overseasSales";
  modalSearchKeyword: {
    filter: string;
    display: string;
  };
  firstResultSet: boolean;
  isResultLoading: boolean;
  isTableLoading: boolean;
  segmentResult: {
    allNkCodeList?: string;
    data?: ScreeningItem[][];
    currentPage?: string;
    sortFlag?: "up" | "down";
    sortId?: number;
    headers?: string[][];
    totalCount?: number;
    nikkeiCodeList?: string;
  };
  isScatterLoading: boolean;
  isDisabledFetchCorpCount: boolean;
  isFetchFilterMessage: boolean;
  scatter?: {
    code: string[];
    toolComparisonList9Dto: ScatterList;
    chart02Str: string;
    chart03Str: string;
    chart04Str: string;
  };
}

const initialState: ToolScreeningCorpItemState = {
  isInitializing: true,
  setInitialized: false,
  isModern: true,
  savedConditions: {
    user: [],
    recommended: []
  },
  condition: {
    freeWord: {
      text: "",
      target: []
    },
    displayCondition: {
      ytCondition: "1",
      scCommonCondition: "1",
      currency: "1"
    },
    filter: [],
    displayItems: [],
    corp: [],
    sourceItems: []
  },
  conditionError: [],
  defaultCondition: {
    filter: [],
    displayItems: []
  },
  freeWordTargetList: [],
  currencyList: [],
  scConditionList: [],
  ytConditionList: [],
  companySelectSourceItems: [],
  resultDisplayConditionList: {
    accKbnList: [],
    currency: [],
    scConditionList: [],
    ytConditionList: []
  },
  conditionData: {
    dlxls: "",
    dlcsv: "",
    dlxls_chart: "",
    dlcsv_chart: "",
    dlxlspath_chart: "",
    dlcsvpath_chart: "",
    dlxlspath: "",
    dlcsvpath: "",
    dlpropriety: "",
    dlpropriety_chart: "",
    dlpropriety_segment: "",
    labelValueDtoList08: []
  },
  result: {
    data: undefined,
    headers: undefined,
    currentPage: undefined,
    currentNkCodeList: undefined,
    screeningIdAndConditionsListStr: undefined,
    needsListStr: undefined,
    screeningIdListStr: undefined,
    toolsearchKeyword: undefined,
    accKbn: undefined,
    currency: undefined,
    scCondition: undefined,
    ytCondition: undefined,
    ltm: false,
    expect: false,
    maxDisplayableNkcodeCount: undefined,
    nkcodeCountForShow: undefined,
    totalCount: undefined,
    groupItemDupulicateFlg: undefined,
    sanpuzuCapableScreeningItemList: undefined,
    listingNkcodeTotalCount: undefined,
    unListedNkcodeTotalCount: undefined,
    unListedHolderNkcodeTotalCount: undefined,
    unListedTdbNkcodeTotalCount: undefined,
    africaNkcodeTotalCount: undefined,
    asiaPacificNkcodeTotalCount: undefined,
    europeNkcodeTotalCount: undefined,
    northAmericaNkcodeTotalCount: undefined,
    southAmericaNkcodeTotalCount: undefined,
    mideastNkcodeTotalCount: undefined,
    unlistedAfricaNkcodeTotalCount: undefined,
    unlistedAsiaPacificNkcodeTotalCount: undefined,
    unlistedEuropeNkcodeTotalCount: undefined,
    unlistedNorthAmericaNkcodeTotalCount: undefined,
    unlistedSouthAmericaNkcodeTotalCount: undefined,
    unlistedMideastNkcodeTotalCount: undefined,
    selectCorps: undefined
  },
  selectedTab: "company",
  segmentType: "business",
  modalSearchKeyword: { filter: "", display: "" },
  isResultLoading: false,
  isTableLoading: false,
  firstResultSet: true,
  segmentResult: {
    headers: undefined,
    currentPage: undefined,
    data: undefined,
    totalCount: undefined
  },
  isScatterLoading: false,
  isDisabledFetchCorpCount: false,
  isFetchFilterMessage: true,
  scatter: undefined
};

export const toolScreeningCorpModernItem: Reducer<
  ToolScreeningCorpItemState,
  ToolScreeningCorpModernItemAction
> = (state = initialState, action) => {
  switch (action.type) {
    case "tool/screening/corp/modernItem/SET_IS_INITIALIZING":
      return produce(state, draft => {
        draft.isInitializing = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_INITIALIZE":
      return produce(state, draft => {
        draft.setInitialized = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_MODERN_ITEM":
      return produce(state, draft => {
        draft.isModern = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_SAVED_CONDITIONS":
      return produce(state, draft => {
        draft.savedConditions.user = action.payload.user;
        draft.savedConditions.recommended = action.payload.recommended;
      });
    case "tool/screening/corp/modernItem/SET_FREE_WORD_TARGET":
      return produce(state, draft => {
        draft.condition.freeWord.target = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_FREE_WORD_TARGET_LIST":
      return produce(state, draft => {
        draft.freeWordTargetList = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_FREE_WORD_TEXT":
      return produce(state, draft => {
        draft.condition.freeWord.text = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_DISPLAY_CONDITION":
      return produce(state, draft => {
        draft.condition.displayCondition = {
          ...state.condition.displayCondition,
          [action.payload.key]: action.payload.value
        };
      });
    case "tool/screening/corp/modernItem/SET_FILTER_CONDITION":
      return produce(state, draft => {
        draft.condition.filter = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_FILTER_CONDITION_VALUE":
      return produce(state, draft => {
        draft.condition.filter = state.condition.filter.map(item => {
          if (item.id !== action.payload.id) {
            return { ...item };
          }
          return {
            ...item,
            value: action.payload.value,
            needsName: action.payload.needsName
          };
        });
      });
    case "tool/screening/corp/modernItem/SET_DEFAULT_FILTER_CONDITION":
      return produce(state, draft => {
        draft.defaultCondition.filter = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_CONDITION_DISPLAY_ITEMS":
      return produce(state, draft => {
        draft.condition.displayItems = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_DEFAULT_CONDITION_DISPLAY_ITEMS":
      return produce(state, draft => {
        draft.defaultCondition.displayItems = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_CORPORATIONS":
      return produce(state, draft => {
        draft.condition.corp = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_CURRENCY_LIST":
      return produce(state, draft => {
        draft.currencyList = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_SC_CONDITION_LIST":
      return produce(state, draft => {
        draft.scConditionList = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_YT_CONDITION_LIST":
      return produce(state, draft => {
        draft.ytConditionList = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_COMPANY_SELECT_SOURCE_ITEMS":
      return produce(state, draft => {
        draft.companySelectSourceItems = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_CONDITION_DATA":
      return produce(state, draft => {
        draft.conditionData.dlxls = action.payload.dlxls;
        draft.conditionData.dlcsv = action.payload.dlcsv;
        draft.conditionData.dlxls_chart = action.payload.dlxls_chart;
        draft.conditionData.dlcsv_chart = action.payload.dlcsv_chart;
        draft.conditionData.dlxlspath_chart = action.payload.dlxlspath_chart;
        draft.conditionData.dlcsvpath_chart = action.payload.dlcsvpath_chart;
        draft.conditionData.dlxlspath = action.payload.dlxlspath;
        draft.conditionData.dlcsvpath = action.payload.dlcsvpath;
        draft.conditionData.dlpropriety = action.payload.dlpropriety;
        draft.conditionData.dlpropriety_chart =
          action.payload.dlpropriety_chart;
        draft.conditionData.dlpropriety_segment =
          action.payload.dlpropriety_segment;
        draft.conditionData.labelValueDtoList08 =
          action.payload.labelValueDtoList08;
      });
    case "tool/screening/corp/modernItem/SET_ERROR_CONDITIONS":
      return produce(state, draft => {
        draft.conditionError = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_SELECTED_TAB":
      return produce(state, draft => {
        draft.selectedTab = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_SEGMENT_TYPE":
      return produce(state, draft => {
        draft.segmentType = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_FILTER_KEYWORD":
      return produce(state, draft => {
        draft.modalSearchKeyword[action.payload.key] = action.payload.value;
      });
    case "tool/screening/corp/modernItem/SET_RESULT":
      return produce(state, draft => {
        draft.result = { ...action.payload };
      });
    case "tool/screening/corp/modernItem/SET_IS_RESULT_LOADING":
      return produce(state, draft => {
        draft.isResultLoading = action.payload;
        draft.firstResultSet = false;
      });
    case "tool/screening/corp/modernItem/SET_IS_TABLE_LOADING":
      return produce(state, draft => {
        draft.isTableLoading = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_SEGMENT_RESULT":
      return produce(state, draft => {
        draft.segmentResult.allNkCodeList = action.payload.allNkCodeList;
        draft.segmentResult.data = action.payload.data;
        draft.segmentResult.currentPage = action.payload.currentPage;
        draft.segmentResult.sortFlag = action.payload.sortFlag;
        draft.segmentResult.sortId = action.payload.sortId;
        draft.segmentResult.headers = action.payload.headers;
        draft.segmentResult.totalCount = action.payload.totalCount;
        draft.segmentResult.nikkeiCodeList = action.payload.nikkeiCodeList;
      });
    case "tool/screening/corp/modernItem/SET_SOURCE_ITEMS":
      return produce(state, draft => {
        draft.condition.sourceItems = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_SELECTED_TYPE_SOURCE_ITEMS":
      return produce(state, draft => {
        let code: string[] = [];

        switch (action.payload.type) {
          case "listed":
            code = [INNEY_CODE.listed];
            break;
          case "holder":
            code = [INNEY_CODE.holder];
            break;
          case "nikkei":
            code = [INNEY_CODE.unlistedCorp];
            break;
          case "tdb":
            code = [INNEY_CODE.tdbCorp];
            break;
          case "unlisted":
            [
              INNEY_CODE.holder,
              INNEY_CODE.unlistedCorp,
              INNEY_CODE.tdbCorp
            ].forEach(target => {
              if (getActive(state.condition.sourceItems, target)) {
                code.push(target);
              }
            });
            break;
          case "overseasListed":
            code = [INNEY_CODE.overseasCorp];
            [
              INNEY_CODE.listedNorthAmerica,
              INNEY_CODE.listedSouthAmerica,
              INNEY_CODE.listedEurope,
              INNEY_CODE.listedAsia,
              INNEY_CODE.listedMideast,
              INNEY_CODE.listedAfrica
            ].forEach(target => {
              if (getActive(state.condition.sourceItems, target)) {
                code.push(target);
              }
            });

            break;
          case "overseasUnlisted":
            code = [INNEY_CODE.unlistedOverseasCorp];
            [
              INNEY_CODE.unlistedNorthAmerica,
              INNEY_CODE.unlistedSouthAmerica,
              INNEY_CODE.unlistedEurope,
              INNEY_CODE.unlistedAsia,
              INNEY_CODE.unlistedMideast,
              INNEY_CODE.unlistedAfrica
            ].forEach(target => {
              if (getActive(state.condition.sourceItems, target)) {
                code.push(target);
              }
            });
        }

        const newSourceItems = getChangedSourceItems(
          state.condition.sourceItems,
          code,
          action.payload.isChecked
        );

        draft.condition.sourceItems = newSourceItems;
      });
    case "tool/screening/corp/modernItem/SET_SELECTED_AREA_SOURCE_ITEMS":
      return produce(state, draft => {
        const code: string[] = [];

        const initSelectedListedAreas = state.condition.sourceItems.filter(
          item => LISTED_AREA_CODE.includes(item.inneyCode) && item.selected
        );

        const initSelectedUnlistedAreas = state.condition.sourceItems.filter(
          item => UNLISTED_AREA_CODE.includes(item.inneyCode) && item.selected
        );

        // 上場・非上場のどちらにもチェックが入っていない場合、両方ともチェックを入れる
        const isBothTypeEmpty =
          initSelectedListedAreas.length === 0 &&
          initSelectedUnlistedAreas.length === 0;

        const listedSelected = state.condition.sourceItems.find(
          item => item.inneyCode === INNEY_CODE.overseasCorp
        )?.selected;
        const unlistedSelected = state.condition.sourceItems.find(
          item => item.inneyCode === INNEY_CODE.unlistedOverseasCorp
        )?.selected;

        const overseasListed = isBothTypeEmpty || listedSelected;
        const overseasUnlisted = isBothTypeEmpty || unlistedSelected;

        switch (action.payload.type) {
          case "asia":
            getActive(state.condition.sourceItems, INNEY_CODE.listedAsia) &&
              overseasListed &&
              code.push(INNEY_CODE.listedAsia);
            getActive(state.condition.sourceItems, INNEY_CODE.unlistedAsia) &&
              overseasUnlisted &&
              code.push(INNEY_CODE.unlistedAsia);
            break;
          case "europe":
            getActive(state.condition.sourceItems, INNEY_CODE.listedEurope) &&
              overseasListed &&
              code.push(INNEY_CODE.listedEurope);
            getActive(state.condition.sourceItems, INNEY_CODE.unlistedEurope) &&
              overseasUnlisted &&
              code.push(INNEY_CODE.unlistedEurope);
            break;
          case "africa":
            getActive(state.condition.sourceItems, INNEY_CODE.listedAfrica) &&
              overseasListed &&
              code.push(INNEY_CODE.listedAfrica);
            getActive(state.condition.sourceItems, INNEY_CODE.unlistedAfrica) &&
              overseasUnlisted &&
              code.push(INNEY_CODE.unlistedAfrica);
            break;
          case "mideast":
            getActive(state.condition.sourceItems, INNEY_CODE.listedMideast) &&
              overseasListed &&
              code.push(INNEY_CODE.listedMideast);
            getActive(
              state.condition.sourceItems,
              INNEY_CODE.unlistedMideast
            ) &&
              overseasUnlisted &&
              code.push(INNEY_CODE.unlistedMideast);
            break;
          case "northAmerica":
            getActive(
              state.condition.sourceItems,
              INNEY_CODE.listedNorthAmerica
            ) &&
              overseasListed &&
              code.push(INNEY_CODE.listedNorthAmerica);
            getActive(
              state.condition.sourceItems,
              INNEY_CODE.unlistedNorthAmerica
            ) &&
              overseasUnlisted &&
              code.push(INNEY_CODE.unlistedNorthAmerica);
            break;
          case "southAmerica":
            getActive(
              state.condition.sourceItems,
              INNEY_CODE.listedSouthAmerica
            ) &&
              overseasListed &&
              code.push(INNEY_CODE.listedSouthAmerica);
            getActive(
              state.condition.sourceItems,
              INNEY_CODE.unlistedSouthAmerica
            ) &&
              overseasUnlisted &&
              code.push(INNEY_CODE.unlistedSouthAmerica);
            break;
        }

        let newSourceItems = getChangedSourceItems(
          state.condition.sourceItems,
          code,
          action.payload.isChecked
        );

        const selectedListedAreas = newSourceItems.filter(
          item => LISTED_AREA_CODE.includes(item.inneyCode) && item.selected
        );

        const selectedUnlistedAreas = newSourceItems.filter(
          item => UNLISTED_AREA_CODE.includes(item.inneyCode) && item.selected
        );

        // 海外上場の地域にチェックが入っていなかったら、海外上場のチェックも外す
        newSourceItems = getChangedSourceItems(
          newSourceItems,
          [INNEY_CODE.overseasCorp],
          selectedListedAreas.length > 0
        );

        // 海外非上場の地域にチェックが入っていなかったら、海外非上場のチェックも外す
        newSourceItems = getChangedSourceItems(
          newSourceItems,
          [INNEY_CODE.unlistedOverseasCorp],
          selectedUnlistedAreas.length > 0
        );

        draft.condition.sourceItems = newSourceItems;
      });
    case "tool/screening/corp/modernItem/SET_CORP_TYPE_SOURCE_ITEMS":
      return produce(state, draft => {
        const code: string[] = [];

        if (action.payload.listed) {
          code.push(INNEY_CODE.listed);
        }
        if (action.payload.holder) {
          code.push(INNEY_CODE.holder);
        }
        if (action.payload.nikkei) {
          code.push(INNEY_CODE.unlistedCorp);
        }
        if (action.payload.tdb) {
          code.push(INNEY_CODE.tdbCorp);
        }
        if (action.payload.overseasListed) {
          code.push(INNEY_CODE.overseasCorp);
        }
        if (action.payload.overseasUnlisted) {
          code.push(INNEY_CODE.unlistedOverseasCorp);
        }

        const sourceItems = state.condition.sourceItems.map(item => ({
          ...item,
          selected: false
        }));

        const areaCode = state.condition.sourceItems
          .filter(item => {
            if (!AREA_CODE.includes(item.inneyCode)) return;

            return item.selected;
          })
          .map(item => item.inneyCode);

        draft.condition.sourceItems = getChangedSourceItems(
          sourceItems,
          [...code, ...areaCode],
          true
        );
      });
    case "tool/screening/corp/modernItem/SET_CORP_AREA_SOURCE_ITEMS":
      return produce(state, draft => {
        const code: string[] = [];

        if (action.payload.selected.asia) {
          action.payload.overseas.listed && code.push(INNEY_CODE.listedAsia);
          action.payload.overseas.unlisted &&
            code.push(INNEY_CODE.unlistedAsia);
        }
        if (action.payload.selected.europe) {
          action.payload.overseas.listed && code.push(INNEY_CODE.listedEurope);
          action.payload.overseas.unlisted &&
            code.push(INNEY_CODE.unlistedEurope);
        }
        if (action.payload.selected.northAmerica) {
          action.payload.overseas.listed &&
            code.push(INNEY_CODE.listedNorthAmerica);
          action.payload.overseas.unlisted &&
            code.push(INNEY_CODE.unlistedNorthAmerica);
        }
        if (action.payload.selected.southAmerica) {
          action.payload.overseas.listed &&
            code.push(INNEY_CODE.listedSouthAmerica);
          action.payload.overseas.unlisted &&
            code.push(INNEY_CODE.unlistedSouthAmerica);
        }
        if (action.payload.selected.mideast) {
          action.payload.overseas.listed && code.push(INNEY_CODE.listedMideast);
          action.payload.overseas.unlisted &&
            code.push(INNEY_CODE.unlistedMideast);
        }
        if (action.payload.selected.africa) {
          action.payload.overseas.listed && code.push(INNEY_CODE.listedAfrica);
          action.payload.overseas.unlisted &&
            code.push(INNEY_CODE.unlistedAfrica);
        }

        const corpTypeCode = state.condition.sourceItems
          .filter(item => {
            if (!CORP_TYPE_CODE.includes(item.inneyCode)) return;

            return item.selected;
          })
          .map(item => item.inneyCode);

        const sourceItems = state.condition.sourceItems.map(item => ({
          ...item,
          selected: false
        }));

        draft.condition.sourceItems = getChangedSourceItems(
          sourceItems,
          [...code, ...corpTypeCode],
          true
        );
      });
    case "tool/screening/corp/modernItem/SET_RESULT_DISPLAY_CONDITION_LIST":
      return produce(state, draft => {
        draft.resultDisplayConditionList.accKbnList = action.payload.accKbnList;
        draft.resultDisplayConditionList.scConditionList =
          action.payload.scConditionList;
        draft.resultDisplayConditionList.currency = action.payload.currency;

        draft.resultDisplayConditionList.ytConditionList =
          action.payload.ytConditionList;
      });
    case "tool/screening/corp/modernItem/SET_IS_SCATTER_LOADING":
      return produce(state, draft => {
        draft.isScatterLoading = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_DISABLED_FETCH_CORP_COUNT":
      return produce(state, draft => {
        draft.isDisabledFetchCorpCount = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_IS_FETCH_FILTER_MESSAGE":
      return produce(state, draft => {
        draft.isFetchFilterMessage = action.payload;
      });
    case "tool/screening/corp/modernItem/SET_SCATTER_DATA":
      return produce(state, draft => {
        draft.scatter = action.payload;
      });
    case "tool/screening/corp/modernItem/CLEAR_RESULT":
      return produce(state, draft => {
        draft.result = { ...initialState.result };
        draft.firstResultSet = true;
      });
    case "tool/screening/corp/modernItem/CLEAR_CONDITIONS":
      return produce(state, draft => {
        draft.condition.sourceItems = action.payload.baseSourceItems;
        draft.condition.freeWord.text = "";
        draft.condition.freeWord.target = state.freeWordTargetList.map(
          item => item.value
        );
        draft.condition.displayCondition.ytCondition = "1";
        draft.condition.displayCondition.scCommonCondition = "1";
        draft.condition.displayCondition.currency = "1";
        draft.condition.displayItems = [...state.defaultCondition.displayItems];
        draft.condition.filter = [...state.defaultCondition.filter];
        draft.condition.corp = [];
        draft.conditionError = [];
      });
    case "tool/screening/corp/modernItem/CLEAR_SCATTER_DATA":
      return produce(state, draft => {
        draft.scatter = undefined;
      });
    case "tool/screening/corp/modernItem/CLEAR_STATE":
      return { ...initialState };
    default:
      return state;
  }
};
