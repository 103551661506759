exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-OverseasPremium-DetailModal-style__content--21bGU {\n  width: 432px;\n  box-sizing: border-box;\n  padding: 16px 16px 24px;\n}\n.src-features-OverseasPremium-DetailModal-style__content--21bGU .src-features-OverseasPremium-DetailModal-style__bold--19yy4 {\n  font-weight: bold;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"content": "src-features-OverseasPremium-DetailModal-style__content--21bGU",
	"bold": "src-features-OverseasPremium-DetailModal-style__bold--19yy4"
};