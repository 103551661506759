import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";

const initialState = {
  // コレクション一覧
  binderList: [],
  // 履歴一覧
  histories: {
    corp: [],
    industry: []
  },
  // ヘッダーの再取得要求
  needUpdate: false,
  folderList: [],

  // 企業検索結果の会社種別チェックボックスの設定値
  concatenatedCorpFilterValue: "",

  // サポートの URL データ
  supportLinks: {},

  // 設定メニュー
  settingMenu: [],
  constnts: {},
  userName: "",
  ipAuthFlg: false,
  reportLastCreateDate: "",

  // 一括検索用データ
  associatedWords: [],
  searchKeywordTags: [],
  isAssociatedWordsSearching: true,

  // 読込中
  isLoading: false,
  // コレクション変更時にローディング表示するか
  shouldLoadCollection: false
};

const createContents = settingMenu => {
  const addContent = (contents, child) => {
    // /jsp はあとでつけるので一旦削除
    contents[child.menuContentId] = `${child.urlValue.replace(
      /^\/jsp/,
      ""
    )}&contentId=${child.menuContentId}`;
  };

  const contents = {};
  settingMenu.map(parent => {
    parent.childMenu.map(child => {
      addContent(contents, child);
    });
  });

  return contents;
};

const buildList = (list, isIndustry = false) =>
  list.map(data => {
    const result = { ...data };
    if (data.hasOwnProperty("url")) {
      const urlArray = data["url"].split("?");
      if (urlArray.length > 1) result["query"] = urlArray[1];
    }
    if (data.hasOwnProperty("bookmarkId")) {
      result["id"] = data["bookmarkId"];
    }
    result["isIndustry"] = isIndustry;
    return result;
  });

// ヘッダに表示されるDropDownリストのデータ(MAX8件)を作成
const buildCorpList = (list, isIndustry = false) => {
  return buildList(list, isIndustry).slice(0, 8);
};

// ヘッダに表示されるDropDownリストのデータ(MAX5件)を作成
const buildIndustryList = (list, isIndustry = true) => {
  return buildList(list, isIndustry).slice(0, 5);
};

const validateKeywords = keywordsAry => {
  // キーワードは5つまでしか受け取らない
  if (keywordsAry.length > 5) return keywordsAry.slice(0, 5);
  // 重複した値があれば削除
  return keywordsAry.filter((k, i, self) => self.indexOf(k) === i);
};

export default handleActions(
  {
    [`${ActionTypes.HEADER_SETTING_GET}_PENDING`]: state => ({
      ...state,
      needUpdate: false
    }),
    [`${ActionTypes.HEADER_SETTING_GET}_REJECTED`]: state => ({
      ...state,
      isLoading: false
    }),
    [`${ActionTypes.HEADER_SETTING_GET}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        folderList: action.payload.folderList,
        settingMenu: action.payload.settingMenu,
        constnts: createContents(action.payload.settingMenu),
        // ユーザ名未設定の場合はログインID
        userName: isEmpty(action.payload.userNameForShow)
          ? action.payload.userId
          : action.payload.userNameForShow,
        ipAuthFlg: action.payload.ipAuthFlg,
        isLoading: false,
        reportLastCreateDate: action.payload.reportLastCreateDate
      };
    },
    [`${ActionTypes.BINDER_LIST_GET}_PENDING`]: state => ({
      ...state,
      needUpdate: false,
      isLoading: state.shouldLoadCollection || isEmpty(state.binderList), // 初回のみローディング表示
      shouldLoadCollection: initialState.shouldLoadCollection
    }),
    [`${ActionTypes.BINDER_LIST_GET}_REJECTED`]: state => ({
      ...state,
      isLoading: false
    }),
    [`${ActionTypes.BINDER_LIST_GET}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        binderList: action.payload.binderList,
        isLoading: false
      };
    },
    [`${ActionTypes.LAST_VIEW_GET}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        histories: {
          corp: buildCorpList(action.payload.recentAccessenTpCorpList),
          industry: buildIndustryList(
            action.payload.recentAccessenTpIndustryList
          )
        },
        isLoading: false
      };
    },
    [ActionTypes.REQUEST_UPDATE]: (state, action) => {
      return {
        ...state,
        needUpdate: true,
        shouldLoadCollection:
          action.payload && "shouldLoadCollection" in action.payload
            ? action.payload.shouldLoadCollection
            : state.shouldLoadCollection
      };
    },
    [ActionTypes.FILTER_UPDATE]: (state, action) => ({
      ...state,
      concatenatedCorpFilterValue: action.payload
    }),
    [ActionTypes.FILTER_CLEAR]: state => ({
      ...state,
      concatenatedCorpFilterValue: ""
    }),
    [`${ActionTypes.GET_ASSOCIATED_WORDS}_PENDING`]: state => ({
      ...state,
      isAssociatedWordsSearching: true
    }),
    [`${ActionTypes.GET_ASSOCIATED_WORDS}_FULFILLED`]: (state, action) => ({
      ...state,
      associatedWords: action.payload,
      isAssociatedWordsSearching: false
    }),
    [`${ActionTypes.GET_ASSOCIATED_WORDS}_REJECTED`]: state => ({
      ...state,
      isAssociatedWordsSearching: false
    }),
    [ActionTypes.SEARCH_KEYWORD_TAGS_UPDATE]: (state, action) => ({
      ...state,
      searchKeywordTags: validateKeywords(action.payload)
    }),
    [ActionTypes.SEARCH_KEYWORD_TAGS_INIT]: state => ({
      ...state,
      searchKeywordTags: []
    })
  },
  initialState
);

export const actionCreators = {
  getSetting: createAction(ActionTypes.HEADER_SETTING_GET, params =>
    request(816, params)
  ),
  getBinderList: createAction(ActionTypes.BINDER_LIST_GET, params =>
    request(864, params)
  ),
  getLastView: createAction(ActionTypes.LAST_VIEW_GET, params =>
    request(865, params)
  ),
  requestUpdate: createAction(ActionTypes.REQUEST_UPDATE),
  updateCorpFilterForHeaderSearch: createAction(ActionTypes.FILTER_UPDATE),
  clearFilter: createAction(ActionTypes.FILTER_CLEAR),
  getAssociatedWords: createAction(ActionTypes.GET_ASSOCIATED_WORDS, params =>
    request(868, params)
  ),
  updateSearchKeywordTags: createAction(ActionTypes.SEARCH_KEYWORD_TAGS_UPDATE),
  initSearchKeywordTags: createAction(ActionTypes.SEARCH_KEYWORD_TAGS_INIT)
};
