export const flags = {
  noChange: undefined, // ページ変化なし
  top: "1", // 先頭ページへ
  prev: "2", // 前ページへ
  next: "3" // 次ページへ
};

export const pageTypes = {
  first: "top", // 先頭ページへ (startは"遷移先ページの件数の最小値"を指定して使用する)
  prev: "prev", // 前ページへ (startは"前のページの先頭件数"を指定して使用する)
  next: "next" // 次ページへ（startは"前のページの先頭件数"を指定して使用する）
};

/**
 * convertPageAndFlag
 * currentPageとflagでページを操作するAPIのパラメータ作成用処理。
 * 表示中のページ数と遷移先のページ数、最終ページ数から、パラメータになるcurrentPageとflagを返す。
 * 表示中のページ数と遷移先のページ数が空や負の値のとき、undefinedを返す。
 * @param {number|string} currentPage 表示中のページ数
 * @param {number|string} targetPage 遷移先のページ数
 * @return {{currentPage: string | number; flag: string | undefined;}|undefined} パラメータになるcurrentPageとflag or undefined
 */
export const convertPageAndFlag = (
  targetPage: number | string,
  currentPage: number | string
) => {
  // targetPage = parseInt(targetPage, 10);
  targetPage =
    typeof targetPage === "string" ? parseInt(targetPage, 10) : targetPage;
  currentPage =
    typeof currentPage === "string" ? parseInt(currentPage, 10) : currentPage;

  if (isNaN(targetPage) || isNaN(currentPage)) return undefined;

  // 先頭ページ
  if (targetPage === 1) {
    return {
      currentPage: "1",
      flag: flags.top
    };
  }

  // 前のページ
  if (targetPage === currentPage - 1)
    return {
      currentPage,
      flag: flags.prev
    };

  // 次のページ
  if (targetPage === currentPage + 1)
    return {
      currentPage,
      flag: flags.next
    };

  return {
    currentPage: targetPage,
    flag: flags.noChange
  };
};

/**
 * 遷移先ページの件数の最小値と最大値から、startとpageTypeを返す
 * APIにより、startの扱いが異なるため、
 * isPrevStartにfalseを渡すと、startを遷移先ページの件数最小値にする。
 * trueを渡すと、startを遷移先ページの一つ前のページの件数最小値にする。
 * @param {number|string} first
 * @param {number|string} end
 * @param {boolean} isPrevStart startを遷移先ページの件数最小値にするかどうか
 */
export const convertStartAndPageType = (
  first: number | string,
  perPage = 5,
  isPrevStart = true
) => {
  first = typeof first === "string" ? parseInt(first, 10) : first;
  if (first === 1) {
    return {
      start: first,
      pageType: pageTypes.first // 1ページ目の時はtopを指定
    };
  }

  // 1ページ目以外の時はpageType: nextで指定する
  let start = first;
  if (isPrevStart) {
    // 前のページの先頭件数を算出して設定
    start = first - perPage;
  }

  return {
    start: start,
    pageType: pageTypes.next
  };
};
