exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-BinderPage-Common-CloseConfirmModal-style__wrapper--rqyI_ {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-pack: center;\n      justify-content: center;\n  position: fixed;\n  z-index: 10;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n}\n\n.src-features-BinderPage-Common-CloseConfirmModal-style__modalSizing--_Mnc5 {\n  width: auto;\n  min-width: 400px;\n  height: auto;\n  min-height: 50px;\n}\n\n.src-features-BinderPage-Common-CloseConfirmModal-style__reactModal--cz0xj {\n  width: 100%;\n  height: 100%;\n  background: #fff;\n  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);\n}\n\n.src-features-BinderPage-Common-CloseConfirmModal-style__header--TXQxb {\n  box-sizing: border-box;\n  height: 50px;\n  padding: 16px 16px 0;\n  color: #21465d;\n  background-color: #f2f6f8;\n  font-size: 20px;\n  font-weight: bold;\n}\n\n.src-features-BinderPage-Common-CloseConfirmModal-style__body--2d7ll {\n  overflow: auto;\n  height: calc(100% - 50px);\n  padding: 0 19px;\n}\n\n.src-features-BinderPage-Common-CloseConfirmModal-style__reactModalContent--2K14z {\n  margin-top: 24px;\n  margin-bottom: 40px;\n  white-space: pre;\n  font-size: 13px;\n  font-weight: normal;\n  line-height: 1.6;\n}\n\n.src-features-BinderPage-Common-CloseConfirmModal-style__reactModalButtonWrapper--Sha2x {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  width: 368px;\n  margin: 15px 9px;\n}\n.src-features-BinderPage-Common-CloseConfirmModal-style__reactModalButtonWrapper--Sha2x > button {\n  width: 176px;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"wrapper": "src-features-BinderPage-Common-CloseConfirmModal-style__wrapper--rqyI_",
	"modalSizing": "src-features-BinderPage-Common-CloseConfirmModal-style__modalSizing--_Mnc5",
	"reactModal": "src-features-BinderPage-Common-CloseConfirmModal-style__reactModal--cz0xj",
	"header": "src-features-BinderPage-Common-CloseConfirmModal-style__header--TXQxb",
	"body": "src-features-BinderPage-Common-CloseConfirmModal-style__body--2d7ll",
	"reactModalContent": "src-features-BinderPage-Common-CloseConfirmModal-style__reactModalContent--2K14z",
	"reactModalButtonWrapper": "src-features-BinderPage-Common-CloseConfirmModal-style__reactModalButtonWrapper--Sha2x"
};