import React, { useCallback, useEffect, useRef } from "react";

import { useDispatch } from "react-redux";
import { useReduxState } from "hooks/useReduxState";
import { actionCreators } from "modules/modal/actions";
import { isEmpty } from "lodash";

export const useModalUtilContext = () => {
  const dispatch = useDispatch();

  const modal = useReduxState(state => state.modal);

  const registerModal = (modalId: string) =>
    dispatch(actionCreators.registerModal(modalId));

  const releaseModal = useCallback(
    () => dispatch(actionCreators.releaseModal()),
    [dispatch]
  );

  const resetModalStack = useCallback(
    () => dispatch(actionCreators.resetModalStack()),
    [dispatch]
  );

  // リスナーしている`keydown`のハンドラ
  const handleKeyEvent = useCallback(
    event => {
      switch (event.key) {
        // esc が押された場合
        // windowのイベントを自前でハンドリングする場合はIEでキー名が異なるため独自対応が必要
        case "Esc":
        case "Escape": {
          // ダイアログが表示されていれば閉じる処理を実施
          if (0 < modal.modalStack.length) {
            releaseModal();
          }
          break;
        }
      }
    },
    [modal.modalStack, releaseModal]
  );

  const keyEventRef = useRef<number>();

  // `keydown` イベント登録用関数
  const addKeyEventListener = useCallback(() => {
    if (isEmpty(keyEventRef.current)) {
      window.addEventListener("keydown", handleKeyEvent);
      keyEventRef.current = Date.now();
    }
  }, [handleKeyEvent]);

  // `keydown` イベント削除用関数
  const removeKeyEventListener = useCallback(() => {
    window.removeEventListener("keydown", handleKeyEvent);
    keyEventRef.current = undefined;
  }, [handleKeyEvent]);

  return {
    modal,
    registerModal,
    releaseModal,
    resetModalStack,
    addKeyEventListener,
    removeKeyEventListener
  };
};

export const ModalUtilContextProvider: React.FunctionComponent = props => {
  const {
    modal,
    registerModal,
    releaseModal,
    resetModalStack,
    addKeyEventListener,
    removeKeyEventListener
  } = useModalUtilContext();

  useEffect(() => {
    // modalStackをクリアする
    resetModalStack();
  }, [resetModalStack]);

  useEffect(() => {
    // escキーでモーダルを閉じるためのイベントリスナー登録
    addKeyEventListener();
    // 登録したイベントリスナーを解除
    return removeKeyEventListener;
  }, [addKeyEventListener, removeKeyEventListener]);

  return (
    <ModalUtilContext.Provider
      value={{
        modalStack: modal.modalStack,
        registerModal,
        releaseModal,
        addKeyEventListener,
        removeKeyEventListener
      }}
    >
      {props.children}
    </ModalUtilContext.Provider>
  );
};

interface ModalUtilContextProps {
  modalStack: string[];
  registerModal: (modalId: string) => void;
  releaseModal: () => void;
  addKeyEventListener: () => void;
  removeKeyEventListener: () => void;
}

const ModalUtilContext = React.createContext<ModalUtilContextProps>({
  modalStack: [],
  registerModal: () => {},
  releaseModal: () => {},
  addKeyEventListener: () => {},
  removeKeyEventListener: () => {}
});

export default ModalUtilContext;
