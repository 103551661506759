import { EsgSociety } from "./actions";
import { Reducer } from "redux";
import produce from "immer";

import { DislosureYears, EsgInfoData } from "models/CorpEsg";

export interface EsgSocietyState {
  data?: {
    accessStatus: string;
    easeWorkSelectedPublishYearFrom: string;
    easeWorkSelectedPublishYearTo: string;
    directaccess_corpIndustyKbn: string;
    directaccess_corpIndustyCode: string;
    directaccess_corpType: string;
    tokenKey: string;
    corpcsrlabor01: {
      updDate: string;
      easeWorkInfoStr: string[][];
    };
  };
  dislosureYears: DislosureYears[];
  employeeStatusInfo?: EsgInfoData;
  humanRightsAssessmentInfo?: EsgInfoData;
  childLabourInfo?: EsgInfoData;
  forcedLabourInfo?: EsgInfoData;
  nonDiscriminationInfo?: EsgInfoData;
  occupationalSafetyInfo?: EsgInfoData;
  employeeStatusDisclosureYear: string;
  humanRightsAssessmentDisclosureYear: string;
  childLabourDisclosureYear: string;
  forcedLabourDisclosureYear: string;
  nonDiscriminationDisclosureYear: string;
  occupationalSafetyDisclosureYear: string;
  employeeStatusTextDataYear: string;
  humanRightsAssessmentTextDataYear: string;
  childLabourTextDataYear: string;
  forcedLabourTextDataYear: string;
  nonDiscriminationTextDataYear: string;
  occupationalSafetyTextDataYear: string;
}

const initialState: EsgSocietyState = {
  data: undefined,
  dislosureYears: [],
  employeeStatusInfo: undefined,
  humanRightsAssessmentInfo: undefined,
  childLabourInfo: undefined,
  forcedLabourInfo: undefined,
  nonDiscriminationInfo: undefined,
  occupationalSafetyInfo: undefined,
  employeeStatusDisclosureYear: "",
  humanRightsAssessmentDisclosureYear: "",
  childLabourDisclosureYear: "",
  forcedLabourDisclosureYear: "",
  nonDiscriminationDisclosureYear: "",
  occupationalSafetyDisclosureYear: "",
  employeeStatusTextDataYear: "",
  humanRightsAssessmentTextDataYear: "",
  childLabourTextDataYear: "",
  forcedLabourTextDataYear: "",
  nonDiscriminationTextDataYear: "",
  occupationalSafetyTextDataYear: ""
};

export const corpEsgSociety: Reducer<EsgSocietyState, EsgSociety> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "corp/esg/society/SET_LABOR_DATA_PAGE_INIT":
      return produce(state, draft => {
        draft.data = action.payload;
      });
    case "corp/esg/society/SET_DISCLOSURE_YEARS":
      return produce(state, draft => {
        draft.dislosureYears = action.payload;
      });
    case "corp/esg/society/SET_EMPLOYEE_STATUS_INFO":
      return produce(state, draft => {
        draft.employeeStatusInfo = action.payload;
      });
    case "corp/esg/society/SET_HUMAN_RIGHTS_ASSESSMENT_INFO":
      return produce(state, draft => {
        draft.humanRightsAssessmentInfo = action.payload;
      });
    case "corp/esg/society/SET_CHILD_LABOUR_INFO":
      return produce(state, draft => {
        draft.childLabourInfo = action.payload;
      });
    case "corp/esg/society/SET_FORCED_LABOUR_INFO":
      return produce(state, draft => {
        draft.forcedLabourInfo = action.payload;
      });
    case "corp/esg/society/SET_NON_DISCRIMINATION_INFO":
      return produce(state, draft => {
        draft.nonDiscriminationInfo = action.payload;
      });
    case "corp/esg/society/SET_OCCUPATIONAL_SAFETY_INFO":
      return produce(state, draft => {
        draft.occupationalSafetyInfo = action.payload;
      });
    case "corp/esg/society/SET_EMPLOYEE_STATUS_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.employeeStatusDisclosureYear = action.payload;
      });
    case "corp/esg/society/SET_HUMAN_RIGHTS_ASSESSMENT_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.humanRightsAssessmentDisclosureYear = action.payload;
      });
    case "corp/esg/society/SET_CHILD_LABOUR_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.childLabourDisclosureYear = action.payload;
      });
    case "corp/esg/society/SET_FORCED_LABOUR_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.forcedLabourDisclosureYear = action.payload;
      });
    case "corp/esg/society/SET_NON_DISCRIMINATION_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.nonDiscriminationDisclosureYear = action.payload;
      });
    case "corp/esg/society/SET_OCCUPATIONAL_SAFETY_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.occupationalSafetyDisclosureYear = action.payload;
      });
    case "corp/esg/society/SET_EMPLOYEE_STATUS_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.employeeStatusTextDataYear = action.payload;
      });
    case "corp/esg/society/SET_HUMAN_RIGHTS_ASSESSMENT_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.humanRightsAssessmentTextDataYear = action.payload;
      });
    case "corp/esg/society/SET_CHILD_LABOUR_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.childLabourTextDataYear = action.payload;
      });
    case "corp/esg/society/SET_FORCED_LABOUR_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.forcedLabourTextDataYear = action.payload;
      });
    case "corp/esg/society/SET_NON_DISCRIMINATION_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.nonDiscriminationTextDataYear = action.payload;
      });
    case "corp/esg/society/SET_OCCUPATIONAL_SAFETY_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.occupationalSafetyTextDataYear = action.payload;
      });

    default:
      return state;
  }
};
