import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  contents: {} // contentIdとurl
};

const createContents = data => {
  const contents = {};

  for (const index in data) {
    const menu = data[index];
    menu.menuInfoList.map(parent => {
      parent.childMenu.map(child => {
        // /jsp はあとでつけるので一旦削除
        contents[child.menuContentId] = child.urlValue.replace(/^\/jsp/, "");
      });
    });
  }

  return contents;
};

export default handleActions(
  {
    [`${ActionTypes.MENU_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      contents: createContents(action.payload)
    })
  },
  initialState
);

export const actionCreators = {
  initMenu: createAction(ActionTypes.MENU_INIT, params => request(814, params))
};
