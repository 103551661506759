import { range } from "lodash";
import dayjs from "dayjs";
import { ExtType, ExtStringType, isExtStringType } from "models/Macro";

// 期種リスト
const extTypes: ExtStringType[] = ["D", "W", "M", "Q", "Sa", "Hy", "A", "Y"];

export interface ExtPeriod {
  extCity: string;
  extCityFrom: string;
  extCityTo: string;
  extCountry: string;
  extCountryFrom: string;
  extCountryTo: string;
  extState: string;
  extStateFrom: string;
  extStateTo: string;
  extTokyo: string;
  extTokyoFrom: string;
  extTokyoTo: string;
  extTowns: string;
  extTownsFrom: string;
  extTownsTo: string;
}

const extPeriodTypes = ["City", "Country", "State", "Tokyo", "Towns"];

// 期種名リスト
const extTypeNames = new Map<ExtStringType, string>([
  ["D", "日"],
  ["W", "週"],
  ["M", "月"],
  ["Q", "四半期"],
  ["Sa", "暦年半期"],
  ["Hy", "年度半期"],
  ["A", "暦年"],
  ["Y", "年度"]
]);

// 期種ごとの初期表示年数のインターバル
const getMaximumInterval = (type: ExtStringType): number => {
  switch (type) {
    case "Q":
      return 3;
    case "Sa":
    case "Hy":
      return 5;
    case "A":
    case "Y":
      return 10;
    default:
      return 1;
  }
};

const emptyExt: ExtType = {
  extA: "",
  extAFrom: "",
  extATo: "",
  extD: "",
  extDFrom: "",
  extDTo: "",
  extHy: "",
  extHyFrom: "",
  extHyTo: "",
  extM: "",
  extMFrom: "",
  extMTo: "",
  extQ: "",
  extQFrom: "",
  extQTo: "",
  extSa: "",
  extSaFrom: "",
  extSaTo: "",
  extW: "",
  extWFrom: "",
  extWTo: "",
  extY: "",
  extYFrom: "",
  extYTo: "",
  extFyFrom: "",
  extFyTo: ""
};

// 期種データの中で最小の期種を取得
const getType = (ext: ExtType): ExtStringType => {
  const validKeys = extTypes.filter(key => {
    const extFlagKey = `ext${key}` as keyof ExtType;
    return ext[extFlagKey] === "1";
  });
  return validKeys.length > 0 ? validKeys[0] : "Y";
};

// 期種リストの index 取得
export const getTypeIndex = (ext: ExtType | ExtStringType | ""): number => {
  if (ext === "") return 0;
  const type = isExtStringType(ext) ? ext : getType(ext);
  return extTypes.findIndex(elem => elem === type) + 1;
};

// 期種データリストの中で最大の期種のデータを取得
const getMaximumType = (extList: ExtType[]): ExtType => {
  if (extList.length === 0) return emptyExt;
  return extList.reduce((previousValue, currentValue) => {
    const previousIndex = getTypeIndex(previousValue);
    const currentIndex = getTypeIndex(currentValue);
    return previousIndex > currentIndex ? previousValue : currentValue;
  });
};

// 最大の期種文字列を取得
export const getMaximumTypeString = (extList: ExtType[]): ExtStringType =>
  getType(getMaximumType(extList));

// 最大の期種番号を取得
export const getMaximumTypeIndex = (extStringType: ExtStringType): number =>
  getTypeIndex(extStringType);

// 期種から日付を丸める単位を算出
export const getDayjsUnit = (extKey: ExtStringType): dayjs.OpUnitType =>
  ["A", "Y"].some(key => key === extKey) ? "year" : "month";

// 期種データリストの中で最小/最大の日付を取得
export const getEdgeDate = (
  extList: ExtPeriod[],
  type: "From" | "To",
  workDay: string
): string => {
  if (extList.length === 0) return "";
  const extKeys = extPeriodTypes.map(
    key => `ext${key}${type}` as keyof ExtPeriod
  );

  const edgeDate = extKeys.reduce((previous, key) => {
    const edgeExt = extList.reduce((previousValue, currentValue) => {
      const currentDateInt = parseInt(currentValue[key]);
      if (!currentDateInt) return previousValue;
      const previousDateInt = parseInt(previousValue[key]);
      if (type === "From") {
        if (!previousDateInt) return currentValue;
        return previousDateInt < currentDateInt ? previousValue : currentValue;
      } else {
        return previousDateInt > currentDateInt ? previousValue : currentValue;
      }
    });

    const currentValue = parseInt(edgeExt[key]);
    if (!currentValue) return previous;
    const previousValue = parseInt(previous);
    if (type === "From") {
      if (!previousValue) return edgeExt[key];
      return previousValue < currentValue ? previous : edgeExt[key];
    } else {
      return previousValue > currentValue ? previous : edgeExt[key];
    }
  }, "");

  const baseDay = dayjs(workDay);

  if (type === "From") {
    return parseInt(baseDay.subtract(30, "year").format("YYYYMMDD")) >
      parseInt(edgeDate)
      ? baseDay
          .subtract(29, "y")
          .startOf("year")
          .format("YYYYMMDD")
      : dayjs(edgeDate)
          .startOf("year")
          .format("YYYYMMDD");
  }

  return parseInt(baseDay.add(30, "year").format("YYYYMMDD")) <
    parseInt(edgeDate)
    ? baseDay
        .add(30, "y")
        .endOf("year")
        .format("YYYYMMDD")
    : dayjs(edgeDate)
        .endOf("year")
        .format("YYYYMMDD");
};

// 初期値として設定する From の取得
export const getDefaultFromDate = (
  from: string,
  to: string,
  ext: ExtType[] | ExtStringType
) => {
  let key: ExtStringType;
  if (ext instanceof Array) {
    key = getMaximumTypeString(ext);
  } else {
    key = ext;
  }

  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  const maximumInterval = getMaximumInterval(key);

  const unit = getDayjsUnit(key);

  return fromDate.add(maximumInterval, "year").isBefore(toDate)
    ? toDate
        .subtract(maximumInterval, "year")
        .startOf(unit)
        .format("YYYYMMDD")
    : from;
};

// 年月リスト生成
export const buildDateList = (
  from: string,
  to: string
): { year: number[]; month: number[] } => {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  const month = range(1, 13);
  const year = range(fromDate.year(), toDate.year() + 1);
  return { year, month };
};

export const buildYearList = (from: string, to: string): number[] => {
  const fromDate = dayjs(from);
  const toDate = dayjs(to);
  const year = range(fromDate.year(), toDate.year() + 1);
  return year;
};

// 期種リスト生成
export const buildExtList = (extList: ExtType[]) => {
  const validExtTypes = extTypes.filter(extType => {
    const extFlagKey = `ext${extType}` as keyof ExtType;
    return extList.some(extData => {
      return extData[extFlagKey] === "1";
    });
  });
  return validExtTypes.map(extType => ({
    value: extType,
    label: extTypeNames.get(extType) || ""
  }));
};

export const getDateFromYear = (year: string, type: "From" | "To") => {
  return type === "From" ? year + "0101" : year + "1231";
};
