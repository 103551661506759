// 初期表示
export const PAGE_INIT = "corp/index/PAGE_INIT";
// 適宜開示一覧取得・ページ遷移
export const LOAD_LIST = "corp/index/LOAD_LIST";
// 株式情報
export const LOAD_CODE_INFO = "corp/index/LOAD_INFO";
// 株価チャート情報取得
export const LOAD_CHART = "corp/index/LOAD_CHART";
// Excelダウンロード期間取得
export const LOAD_DATE = "corp/index/LOAD_DATE";
// テレコンパラメータ（適時開示情報のチェックボックス値）取得
export const LOAD_PARAMS = "corp/index/LOAD_PARAMS";
