import React from "react";
import styles from "./style.scss";
import { stringifyQuery } from "utils/helper/url";
import { isEmpty } from "utils/helper/common";

/**
 * ポップアップを表示するリンク
 * @param {object} query URL クエリパラメーター
 * @param {?string} pathname URL パス
 * @param {?boolean} focus ポップアップ表示後 focus するか
 */

interface PopupLinkProps {
  query?: object;
  pathname?: string;
  focus?: boolean;
  className?: string;
}

const PopupLink: React.FunctionComponent<PopupLinkProps> = ({
  query,
  pathname = "/popup",
  focus = true,
  children,
  className
}) => {
  const onClick = () => openPopup({ pathname, query, focus });
  return (
    <span
      className={`${styles.component} ${className}`}
      onClick={onClick}
      data-testid="Common-Popup-Link"
    >
      {children}
    </span>
  );
};

export default PopupLink;

/**
 * ポップアップを表示
 *
 * memo: window.open第3引数にnullを指定した場合、ieで別ウインドウで開かれる
 *
 * @param {object} params
 *   {object} query URL クエリパラメーター
 *   {?string} pathname URL パス
 *   {?boolean} focus ポップアップ表示後 focus するか
 * @param {?string} windowOpenOptions window.openの第3引数
 */

interface OpenPopupProps {
  query?: object;
  pathname?: string;
  focus?: boolean;
}

export const openPopup = (
  { query, pathname = "/popup", focus = true }: OpenPopupProps,
  windowOpenOptions: string | null = null
) => {
  const url = isEmpty(query)
    ? pathname
    : `${pathname}?${stringifyQuery(query)}`;

  if (!windowOpenOptions) {
    if (focus) {
      const popup = window.open(url, "_blank");
      if (popup && !isEmpty(popup)) popup.focus();
    } else {
      window.open(url, "_blank");
    }
  } else {
    if (focus) {
      const popup = window.open(url, "_blank", windowOpenOptions);
      if (popup && !isEmpty(popup)) popup.focus();
    } else {
      window.open(url, "_blank", windowOpenOptions);
    }
  }
  return url;
};
