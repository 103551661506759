import React, { SyntheticEvent, useCallback, useRef, useState } from "react";
import styles from "./style.scss";
import cn from "classnames";

interface Props {
  src: string;
  fallbackSrc?: string;
  width?: string;
  height?: string;
  title?: string;
  className?: string;
}

// IE11 対応 object-fit
export const FitImage: React.FunctionComponent<Props> = props => {
  const imageRef = useRef<HTMLImageElement>(null);

  const [imgStyle, setImageStyle] = useState({
    height: "100%",
    width: "auto"
  });

  const onErrorImage = useCallback(
    (event: SyntheticEvent<HTMLImageElement>) => {
      if (props.fallbackSrc) {
        event.currentTarget.src = props.fallbackSrc;
      }
    },
    [props.fallbackSrc]
  );

  if (!props.width && !props.height) return null;
  const style = {
    height: props.height || "100%",
    width: props.width || "100%"
  };

  const screenWidth = parseInt(props.width?.match(/(\d+)px/)?.pop() || "");
  const screenHeight = parseInt(props.height?.match(/(\d+)px/)?.pop() || "");
  const screenAspectRatio =
    screenWidth && screenHeight ? screenWidth / screenHeight : null;

  const onLoadImage = () => {
    if (!imageRef.current) return;
    const width = imageRef.current.naturalWidth;
    const height = imageRef.current.naturalHeight;
    const imageAspectRatio = width / height;
    const isLongerWidth = imageAspectRatio > 1;
    const isFitVertical = screenAspectRatio
      ? screenAspectRatio < imageAspectRatio && isLongerWidth
      : isLongerWidth;
    setImageStyle({
      height: isFitVertical ? "100%" : "auto",
      width: !isFitVertical ? "100%" : "auto"
    });
  };

  return (
    <span className={cn(styles.component, props.className)} style={style}>
      <img
        src={props.src}
        style={imgStyle}
        title={props.title}
        alt={props.title}
        ref={imageRef}
        onLoad={onLoadImage}
        onError={onErrorImage}
      />
    </span>
  );
};
