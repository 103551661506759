// 初期表示
export const PAGE_INIT = "tools/screening/corp/scenario/PAGE_INIT";
// 検索条件のチェックボックス選択状態リストを更新
export const CONDITION_SOURCE_LIST_UPDATE =
  "tools/screening/corp/scenario/CONDITION_SOURCE_LIST_UPDATE";
// 絞り込み条件の初期データ取得
export const INIT_CONDITION_LIST_ITEM_GET =
  "tools/screening/corp/scenario/INIT_CONDITION_LIST_ITEM_GET";
// 選択中のシナリオ ID
export const CURRENT_SCENARIO_ID_UPDATE =
  "tools/screening/corp/scenario/CURRENT_SCENARIO_ID_UPDATE";
// 選択中のシナリオグループ
export const CURRENT_SCENARIO_GROUP_UPDATE =
  "tools/screening/corp/scenario/CURRENT_SCENARIO_GROUP_UPDATE";
// 選択したシナリオの検索条件取得
export const SCENARIO_CONDITION_GET =
  "tools/screening/corp/scenario/SCENARIO_CONDITION_GET";
// 前回選択したシナリオと同じ場合に再表示する
export const SCENARIO_CONDITION_RESELECT =
  "tools/screening/corp/scenario/SCENARIO_CONDITION_RESELECT";
// 選択したシナリオの更新
export const SCENARIO_COND_LIST_UPDATE =
  "tools/screening/corp/scenario/SCENARIO_COND_LIST_UPDATE";
// 選択したシナリオの検索条件をキャンセルし、選択に戻る
export const SCENARIO_CONDITION_CANCEL =
  "tools/screening/corp/scenario/SCENARIO_CONDITION_CANCEL";
// 条件クリア 選択時に入力中のデータをリセットする
export const SELECTED_SCENARIO_DATAS_RESET =
  "tools/screening/corp/scenario/SELECTED_SCENARIO_DATAS_RESET";
// "条件を保存"前に保存可能かチェック
export const FAVORITE_CHECK = "tools/screening/corp/scenario/FAVORITE_CHECK";
// "条件を保存"からキャンセル(一時データを削除)
export const SAVE_FAVORITE_CANCEL =
  "tools/screening/corp/scenario/SAVE_FAVORITE_CANCEL";
// "条件を保存"モーダルで条件を保存する
export const FAVORITE_SAVE = "tools/screening/corp/scenario/FAVORITE_SAVE";
// 入力対象の絞り込み条件の項目を削除
export const INPUT_CONDITION_REMOVE =
  "tools/screening/corp/scenario/INPUT_CONDITION_REMOVE";
// ユーザが表示キャンセル時に表データをクリアする
export const COMPARISON_DATA_CLEAR =
  "tools/screening/corp/scenario/COMPARISON_DATA_CLEAR";
// 入力対象の絞り込み条件リストをまるごと置き換え
export const INPUT_CONDITION_LIST_REPLACE =
  "tools/screening/corp/scenario/INPUT_CONDITION_LIST_REPLACE";
// スクリーニング結果の企業コードを取得
export const SCREENING_RESULT_GET =
  "tools/screening/corp/scenario/SCREENING_RESULT_GET";
// 比較表ヘッダ情報取得
export const COMPARISON_HEAD_GET =
  "tools/screening/corp/scenario/COMPARISON_HEAD_GET";
// 比較表明細情報取得
export const COMPARISON_DATA_GET =
  "tools/screening/corp/scenario/COMPARISON_DATA_GET";
// 比較表ソート取得
export const COMPARISON_DATA_SORT =
  "tools/screening/corp/scenario/COMPARISON_DATA_SORT";
// 比較表行削除
export const COMPARISON_DATA_ROW_REMOVE =
  "tools/screening/corp/scenario/COMPARISON_DATA_ROW_REMOVE";
// 比較表セレクトボックス等の選択状態更新
export const COMPARISON_CONDITIONS_UPDATE =
  "tools/screening/corp/scenario/COMPARISON_CONDITIONS_UPDATE";
// 散布図選択件数の上限値を取得
export const CHART_COUNT_GET = "tools/screening/corp/scenario/CHART_COUNT_GET";
// チャート情報を取得
export const CHART_DATA_GET = "tools/screening/corp/scenario/CHART_DATA_GET";
// チャート情報を更新
export const CHART_DATA_UPDATE =
  "tools/screening/corp/scenario/CHART_DATA_UPDATE";
// チャート情報削除
export const CHART_DATA_CLEAR =
  "tools/screening/corp/scenario/CHART_DATA_CLEAR";
// 保存データの内容を state に適用する
export const RESULT_DATA_APPLY =
  "tools/screening/corp/scenario/RESULT_DATA_APPLY";
// 初期の絞込条件ツリーのルート階層を取得
export const INIT_TREE_MST_GET =
  "tools/screening/corp/scenario/INIT_TREE_MST_GET";
// 絞込条件ツリーのルート階層(検索と、それ以降)を取得
export const SCREENING_TREE_ROOT_GET =
  "tools/screening/corp/scenario/SCREENING_TREE_ROOT_GET";
// 絞込条件ツリーの子ノードを取得
export const SCREENING_TREE_CHILD_GET =
  "tools/screening/corp/scenario/SCREENING_TREE_CHILD_GET";
// 絞り込み条件を検索条件に追加できるかを取得
export const SCREENING_AVAILABILITY_GET =
  "tools/screening/corp/scenario/SCREENING_AVAILABILITY_GET";
// 絞り込み条件を検索条件に追加
export const SCREENING_ADD = "tools/screening/corp/scenario/SCREENING_ADD";
// 絞り込み条件を検索条件から削除
export const SCREENING_REMOVE =
  "tools/screening/corp/scenario/SCREENING_REMOVE";
// 絞り込み条件を検索条件からすべて削除
export const SCREENING_REMOVE_ALL =
  "tools/screening/corp/scenario/SCREENING_REMOVE_ALL";
// 絞り込み条件で選択した項目に更新
export const INPUT_CONDITION_LIST_UPDATE =
  "tools/screening/corp/scenario/INPUT_CONDITION_LIST_UPDATE";
// 指標ツリーのルート階層を取得
export const INDEX_TREE_ROOT_GET =
  "tools/screening/corp/scenario/INDEX_TREE_ROOT_GET";
// 指標ツリーの子ノードを取得
export const INDEX_TREE_CHILD_GET =
  "tools/screening/corp/scenario/INDEX_TREE_CHILD_GET";
// 指標を検索条件に追加できるかを取得
export const INDEX_AVAILABILITY_GET =
  "tools/screening/corp/scenario/INDEX_AVAILABILITY_GET";
// 指標を検索条件に追加
export const INDEX_ADD = "tools/screening/corp/scenario/INDEX_ADD";
// 指標の選択状態を更新
export const INDEX_UPDATE = "tools/screening/corp/scenario/INDEX_REMOVE";
// 指標を検索条件からすべて削除
export const INDEX_REMOVE_ALL =
  "tools/screening/corp/scenario/INDEX_REMOVE_ALL";
// ユーザ指標編集 保存した検索条件セレクトボックスの中身取得
export const SAVED_CONDITION_SELECT_GET =
  "tools/screening/corp/scenario/SAVED_CONDITION_SELECT_GET";
// ユーザ指標編集 保存した検索条件一覧取得
export const SAVED_CONDITION_GET =
  "tools/screening/corp/scenario/SAVED_CONDITION_GET";
// ユーザ指標編集 保存した検索条件の削除
export const SAVED_CONDITION_DELETE =
  "tools/screening/corp/scenario/SAVED_CONDITION_DELETE";
// ユーザ指標編集 保存した検索条件の更新
export const SAVED_CONDITION_UPDATE =
  "tools/screening/corp/scenario/SAVED_CONDITION_UPDATE";
// ユーザ指標編集 保存した検索条件の詳細取得
export const SAVED_CONDITION_DETAIL_GET =
  "tools/screening/corp/scenario/SAVED_CONDITION_DETAIL_GET";
/**
 * 比較表データ再取得系
 */
// 再検索フラグをセット／リセット
export const SEARCH_FLAG_SET = "tools/screening/corp/scenario/SEARCH_FLAG_SET";
export const SEARCH_FLAG_RESET =
  "tools/screening/corp/scenario/SEARCH_FLAG_RESET";
// ソート状態を解除
export const SORT_INFO_UNSET = "tools/screening/corp/scenario/SORT_INFO_UNSET";

// 保存した条件(スクリーニング検索条件)一覧取得
export const SAVED_FAVORITE_GET =
  "tools/screening/corp/scrnario/SAVED_FAVORITE_GET";
// 保存した条件(スクリーニング検索条件)の削除
export const SAVED_FAVORITE_DELETE =
  "tools/screening/corp/scrnario/SAVED_FAVORITE_DELETE";
// 保存した条件(スクリーニング検索条件)の更新
export const SAVED_FAVORITE_UPDATE =
  "tools/screening/corp/scrnario/SAVED_FAVORITE_UPDATE";
// 保存した条件(スクリーニング検索条件)の詳細取得
export const SAVED_FAVORITE_DETAIL_GET =
  "tools/screening/corp/scrnario/SAVED_FAVORITE_DETAIL_GET";
// 登録した条件から選択 で選択中の条件をクリアする
export const ALL_CONDITION_REMOVE =
  "tools/screening/corp/scrnario/ALL_CONDITION_REMOVE";
// Needs業種分類の大項目を取得
export const NEEDS_MST_L_GET = "tools/screening/corp/screening/NEEDS_MST_L_GET";
// Needs業種分類の中項目を取得
export const NEEDS_MST_M_GET = "tools/screening/corp/screening/NEEDS_MST_M_GET";
// Needs業種分類の小項目を取得
export const NEEDS_MST_S_GET = "tools/screening/corp/screening/NEEDS_MST_S_GET";
// Needs業種分類クリア
export const NEEDS_MST_CLEAR = "tools/screening/corp/screening/NEEDS_MST_CLEAR";
// 上場市場のチェックボックスリスト取得
export const MARKET_LIST_GET = "tools/screening/corp/screening/MARKET_LIST_GET";
// 年金制度のチェックボックスリスト取得
export const FNCL_LIST_GET = "tools/screening/corp/screening/FNCL_LIST_GET";
// 住所（都道府県）のチェックボックスリスト取得
export const ADDRESS_LIST_GET =
  "tools/screening/corp/screening/ADDRESS_LIST_GET";
// 東証業種のチェックボックスリスト取得
export const NTCLS_LIST_GET = "tools/screening/corp/screening/NTCLS_LIST_GET";
// 保存した検索条件の取得
export const CONDITION_SET = "tools/screening/corp/scenario/CONDITION_SET";
// 指標編集系の全てデータをクリアする
export const INDEX_ALL_CLEAR = "tools/screening/corp/scenario/INDEX_ALL_CLEAR";

// ダウンロード時の期間セレクトボックス情報取得
export const DOWNLOAD_PERIOD_GET =
  "tools/screening/corp/scenario/DOWNLOAD_PERIOD_GET";
export const DOWNLOAD_PERIOD_SET =
  "tools/screening/corp/scenario/DOWNLOAD_PERIOD_SET";

// ページ遷移時にキャッシュ削除
export const ALL_RESET = "tools/screening/corp/scenario/ALL_RESET";

// set timestamp
export const SET_TIMESTAMP = "tools/screening/corp/scenario/TIMESTAMP";
