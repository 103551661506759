// 検索
export const SEARCH = "article/news/SEARCH";
// ページング
export const PAGING = "article/news/PAGING";
// ソート
export const RESULT_SORT = "article/news/RESULT_SORT";
// 検索条件をクリアする
export const CONDITIONS_CLEAR = "article/news/CONDITIONS_CLEAR";
// 検索時の状態に戻す
export const SEARCHED_STATE_RESTORE = "article/news/SEARCHED_STATE_RESTORE";
// 自動検索実行用フラグ
export const DO_FORCE_SEARCH_SET = "article/news/DO_FORCE_SEARCH_SET";
export const KEYWORD_UPDATE = "article/news/KEYWORD_UPDATE";
// 検索文字列の更新
export const OPERAND_RAW_UPDATE = "article/news/OPERAND_RAW_UPDATE";
// 高度な検索：開閉
export const IS_OPEN_ADVANCED_SEARCH_UPDATE =
  "article/news/IS_OPEN_ADVANCED_SEARCH_UPDATE";
// 検索期間：全期間かどうか
export const ENABLE_ALL_PERIOD_SET = "article/news/ENABLE_ALL_PERIOD_SET";
// 検索from日付の設定
export const FROM_DATE_UPDATE = "article/news/FROM_DATE_UPDATE";
// 検索to日付の設定
export const TO_DATE_UPDATE = "article/news/TO_DATE_UPDATE";
// 検索範囲チェックボックス
export const INIT_SCOPE_CHECKBOXES_SET =
  "article/news/INIT_SCOPE_CHECKBOXES_SET";
export const SCOPE_CHECKBOXES_UPDATE = "article/news/SCOPE_CHECKBOXES_UPDATE";
// 検索対象チェックボックス
export const INIT_TARGET_CHECKBOXES_SET =
  "article/news/INIT_TARGET_CHECKBOXES_SET";
export const TARGET_CHECKBOXES_UPDATE = "article/news/TARGET_CHECKBOXES_UPDATE";
// ソート項目名
export const FILTER_OPTION_LIST_INIT = "article/news/FILTER_OPTION_LIST_INIT";
export const PDF_DOWNLOAD = "article/news/PDF_DOWNLOAD";
export const XBRL_DOWNLOAD = "article/news/XBRL_DOWNLOAD";
// 検索条件のローディング状態変更
export const CONDITION_LOADING_UPDATE = "article/news/CONDITION_LOADING_UPDATE";
// 全データクリア
export const ALL_RESET = "article/news/ALL_RESET";
// 検索範囲チェックボックスを事前設定
export const PREPARED_TARGET_CHECKBOXES_SET =
  "article/news/PREPARED_TARGET_CHECKBOXES_SET";
