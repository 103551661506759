import React, { useEffect } from "react";
import { useAuth } from "./hook";
import { useHistory, useLocation } from "react-router";
import { AuthContext } from "contexts/auth";
import { Loading } from "./Loading";
import { Option } from "./Option";

export const Auth: React.FunctionComponent = props => {
  const {
    sessionState,
    requestTokenValidation,
    directAccessCode,
    authorize,
    logout,
    handleNidLogout,
    loginOptions,
    redirectLogin,
    planDownloadPostAuthorizing
  } = useAuth();

  const history = useHistory();

  useEffect(() => {
    requestTokenValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionState === "inactive") {
      if (directAccessCode) {
        authorize(directAccessCode);
      } else {
        // 非同期ダウンロードメール認証
        const searchParams = new URLSearchParams(location.search);
        const plan = searchParams.get("plan");
        const downloadToken = searchParams.get("downloadToken");

        if (plan && downloadToken) {
          planDownloadPostAuthorizing(downloadToken);
        } else {
          redirectLogin();
        }
      }
    } else if (sessionState === "logout") {
      history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState, directAccessCode]);

  const { pathname } = useLocation();
  useEffect(() => {
    handleNidLogout(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (sessionState === "active") {
    if (loginOptions.length > 0) {
      return <Option />;
    }

    return (
      <AuthContext.Provider value={{ logout }}>
        {props.children}
      </AuthContext.Provider>
    );
  }

  return <Loading />;
};
