// 初期表示
export const PAGE_INIT = "tools/screening/corp/item/PAGE_INIT";
// 検索条件のチェックボックス選択状態リストを更新
export const CONDITION_SOURCE_LIST_UPDATE =
  "tools/screening/corp/item/CONDITION_SOURCE_LIST_UPDATE";
// 登録した条件から選択 で選択中の条件をクリアする
export const SELECTED_CONDITION_CLEAR =
  "tools/screening/corp/item/SELECTED_CONDITION_CLEAR";
// 企業スクリーニングツリー情報を取得
export const TREE_MST_GET = "tools/screening/corp/item/TREE_MST_GET";
// 企業スクリーニングツリー情報を検索(再取得)
export const SCREENING_TREE_GET =
  "tools/screening/corp/item/SCREENING_TREE_GET";
// スクリーニング条件検索データ更新
export const CONDITION_DATA_UPDATE =
  "tools/screening/corp/item/CONDITION_DATA_UPDATE";
// 有報・短信セレクトボックス選択項目更新
export const YT_CONDITION_SELECTED_UPDATE =
  "tools/screening/corp/item/YT_CONDITION_SELECTED_UPDATE";
// 連結・単独セレクトボックス選択項目更新
export const SC_CONDITION_SELECTED_UPDATE =
  "tools/screening/corp/item/SC_CONDITION_SELECTED_UPDATE";
// 通貨単位セレクトボックス選択項目更新
export const CURRENCY_SELECTED_UPDATE =
  "tools/screening/corp/item/CURRENCY_SELECTED_UPDATE";
// 入力エリアデータリストの更新
export const INPUT_AREA_DATA_LIST_UPDATE =
  "tools/screening/corp/item/INPUT_AREA_DATA_LIST_UPDATE";
// ツリー子ノード情報取得
export const CHILD_DATA_GET = "tools/screening/corp/item/CHILD_DATA_GET";
// ツリー階層の項目情報(入力アイテムを追加できるかどうか)取得
export const USER_CONDITION_GET =
  "tools/screening/corp/item/USER_CONDITION_GET";
// 指定したスクリーニングIDに対する入力項目情報(どんな入力アイテム形式か)の取得
export const USER_CONDITION_ADD_GET =
  "tools/screening/corp/item/USER_CONDITION_ADD_GET";
// "条件を保存"前に保存可能かチェック
export const FAVORITE_CHECK = "tools/screening/corp/item/FAVORITE_CHECK";
// "条件を保存"からキャンセル(一時データを削除)
export const SAVE_FAVORITE_CANCEL =
  "tools/screening/corp/item/SAVE_FAVORITE_CANCEL";
// "条件を保存"モーダルで条件を保存する
export const FAVORITE_SAVE = "tools/screening/corp/item/FAVORITE_SAVE";
// スクリーニング結果の企業コードを取得
export const SCREENING_RESULT_GET =
  "tools/screening/corp/item/SCREENING_RESULT_GET";
// 比較表ヘッダ情報取得（仮）
export const COMPARISON_HEAD_GET =
  "tools/screening/corp/item/COMPARISON_HEAD_GET";
// 比較表明細情報取得（仮）
export const COMPARISON_DATA_GET =
  "tools/screening/corp/item/COMPARISON_DATA_GET";
// 比較表ソート取得
export const COMPARISON_DATA_SORT =
  "tools/screening/corp/item/COMPARISON_DATA_SORT";
// 比較表行削除
export const COMPARISON_DATA_ROW_REMOVE =
  "tools/screening/corp/item/COMPARISON_DATA_ROW_REMOVE";
// ユーザが表示キャンセル時に表データをクリアする
export const COMPARISON_DATA_CLEAR =
  "tools/screening/corp/item/COMPARISON_DATA_CLEAR";
// 比較表セレクトボックス等の選択状態更新
export const COMPARISON_CONDITIONS_UPDATE =
  "tools/screening/corp/item/COMPARISON_CONDITIONS_UPDATE";
// 散布図選択件数の上限値を取得
export const CHART_COUNT_GET = "tools/screening/corp/item/CHART_COUNT_GET";
// チャート情報を取得
export const CHART_DATA_GET = "tools/screening/corp/item/CHART_DATA_GET";
// チャート情報を更新
export const CHART_DATA_UPDATE = "tools/screening/corp/item/CHART_DATA_UPDATE";
// チャート情報削除
export const CHART_DATA_CLEAR = "tools/screening/corp/item/CHART_DATA_CLEAR";
// 保存データの内容を state に適用する
export const RESULT_DATA_APPLY = "tools/screening/corp/item/RESULT_DATA_APPLY";
// 保存した条件(スクリーニング検索条件)一覧取得
export const SAVED_FAVORITE_GET =
  "tools/screening/corp/item/SAVED_FAVORITE_GET";
// 保存した条件(スクリーニング検索条件)の削除
export const SAVED_FAVORITE_DELETE =
  "tools/screening/corp/item/SAVED_FAVORITE_DELETE";
// 保存した条件(スクリーニング検索条件)の更新
export const SAVED_FAVORITE_UPDATE =
  "tools/screening/corp/item/SAVED_FAVORITE_UPDATE";
// 保存した条件(スクリーニング検索条件)の詳細取得
export const SAVED_FAVORITE_DETAIL_GET =
  "tools/screening/corp/item/SAVED_FAVORITE_DETAIL_GET";
// Needs業種分類の大項目を取得
export const NEEDS_MST_L_GET = "tools/screening/corp/item/NEEDS_MST_L_GET";
// Needs業種分類の中項目を取得
export const NEEDS_MST_M_GET = "tools/screening/corp/item/NEEDS_MST_M_GET";
// Needs業種分類の小項目を取得
export const NEEDS_MST_S_GET = "tools/screening/corp/item/NEEDS_MST_S_GET";
// Needs業種分類の項目をクリア
export const NEEDS_MST_CLEAR = "tools/screening/corp/item/NEEDS_MST_CLEAR";
// 上場市場のチェックボックスリスト取得
export const MARKET_LIST_GET = "tools/screening/corp/item/MARKET_LIST_GET";
// 年金制度のチェックボックスリスト取得
export const FNCL_LIST_GET = "tools/screening/corp/item/FNCL_LIST_GET";
// 住所（都道府県）のチェックボックスリスト取得
export const ADDRESS_LIST_GET = "tools/screening/corp/item/ADDRESS_LIST_GET";
// 東証業種のチェックボックスリスト取得
export const NTCLS_LIST_GET = "tools/screening/corp/item/NTCLS_LIST_GET";
// 指標ツリーのルート階層を取得
export const INDEX_TREE_ROOT_GET =
  "tools/screening/corp/item/INDEX_TREE_ROOT_GET";
// 指標ツリーの子ノードを取得
export const INDEX_TREE_CHILD_GET =
  "tools/screening/corp/item/INDEX_TREE_CHILD_GET";
// 指標を検索条件に追加できるかを取得
export const INDEX_AVAILABILITY_GET =
  "tools/screening/corp/item/INDEX_AVAILABILITY_GET";
// 指標を検索条件に追加
export const INDEX_ADD = "tools/screening/corp/item/INDEX_ADD";
// 指標の選択状態を更新
export const INDEX_UPDATE = "tools/screening/corp/item/INDEX_REMOVE";
// 指標を検索条件からすべて削除
export const INDEX_REMOVE_ALL = "tools/screening/corp/item/INDEX_REMOVE_ALL";
// 保存した検索条件セレクトボックスの中身取得
export const SAVED_CONDITION_SELECT_GET =
  "tools/screening/corp/item/SAVED_CONDITION_SELECT_GET";
// 保存した検索条件一覧取得
export const SAVED_CONDITION_GET =
  "tools/screening/corp/item/SAVED_CONDITION_GET";
// 保存した検索条件の削除
export const SAVED_CONDITION_DELETE =
  "tools/screening/corp/item/SAVED_CONDITION_DELETE";
// 保存した検索条件の更新
export const SAVED_CONDITION_UPDATE =
  "tools/screening/corp/item/SAVED_CONDITION_UPDATE";
// 保存した検索条件の詳細取得
export const SAVED_CONDITION_DETAIL_GET =
  "tools/screening/corp/item/SAVED_CONDITION_DETAIL_GET";
// 保存した検索条件の取得
export const CONDITION_SET = "tools/screening/corp/item/CONDITION_SET";
// 指標編集系の全てデータをクリアする
export const INDEX_ALL_CLEAR = "tools/screening/corp/item/INDEX_ALL_CLEAR";

/**
 * 比較表データ再取得系
 */
// 再検索フラグをセット／リセット
export const SEARCH_FLAG_SET = "tools/screening/corp/item/SEARCH_FLAG_SET";
export const SEARCH_FLAG_RESET = "tools/screening/corp/item/SEARCH_FLAG_RESET";
// ソート状態を解除
export const SORT_INFO_UNSET = "tools/screening/corp/item/SORT_INFO_UNSET";

// ダウンロード時の期間セレクトボックス情報取得
export const DOWNLOAD_PERIOD_GET =
  "tools/screening/corp/item/DOWNLOAD_PERIOD_GET";
export const DOWNLOAD_PERIOD_SET =
  "tools/screening/corp/item/DOWNLOAD_PERIOD_SET";

// ページ遷移時にキャッシュ削除
export const ALL_RESET = "tools/screening/corp/item/ALL_RESET";
