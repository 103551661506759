import { useEffect, useLayoutEffect } from "react";
import { queryObjectToString, noop } from "utils/useMediaUtils";
import { DependencyList, EffectCallback } from "react";
import { useDispatch } from "react-redux";
import { actionCreators as globalActionCreators } from "modules/global";

type Effect = (effect: EffectCallback, deps?: DependencyList) => void;
type MediaQueryObject = { [key: string]: string | number | boolean };

const mockMediaQueryList: MediaQueryList = {
  media: "",
  matches: false,
  onchange: noop,
  addListener: noop,
  removeListener: noop,
  addEventListener: noop,
  removeEventListener: noop,
  dispatchEvent: (_: Event) => true
};

const createUseMedia = (effect: Effect) => (
  rawQuery: string | MediaQueryObject
) => {
  const dispatch = useDispatch();
  const query = queryObjectToString(rawQuery);

  effect(() => {
    let mounted = true;
    const mediaQueryList: MediaQueryList =
      typeof window === "undefined"
        ? mockMediaQueryList
        : window.matchMedia(query);

    const onChange = () => {
      if (!mounted) {
        return;
      }
      dispatch(
        globalActionCreators.setIsMobileSize(Boolean(mediaQueryList.matches))
      );
    };

    mediaQueryList.addListener(onChange);
    dispatch(globalActionCreators.setIsMobileSize(mediaQueryList.matches));

    return () => {
      mounted = false;
      mediaQueryList.removeListener(onChange);
    };
  }, [query]);

  return;
};

export const useMedia = createUseMedia(useEffect);
export const useMediaLayout = createUseMedia(useLayoutEffect);

export default useMedia;
