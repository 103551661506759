import { useCallback } from "react";
import { useLocation } from "react-router";
import { usePrevious } from "./usePrevious";
import { parseQuery } from "utils/helper/url";
import { isSameCorp as _isSameCorp } from "utils/helper/corp";
import { isSameIndustry as _isSameIndustry } from "utils/helper/industry";

export const useLocationCheck = () => {
  const location = useLocation();
  const prev = usePrevious(location);

  const isSamePage = useCallback(
    () =>
      prev &&
      location.pathname === prev.pathname &&
      location.search === prev.search,
    [location.pathname, location.search, prev]
  );

  const isSameCorpPage = useCallback(() => {
    if (!prev) return false;

    const search = parseQuery(location.search);
    const prevSearch = parseQuery(prev.search);

    // 同企業での変更はページ遷移扱いにしない
    if (
      location.pathname === prev.pathname &&
      location.pathname.startsWith("/corp") &&
      _isSameCorp(search, prevSearch)
    ) {
      // 財務系ページは menuId, contentId も見る
      if (
        location.pathname !== "/corp/finance" ||
        (search.menuId === prevSearch.menuId &&
          search.contentId === prevSearch.connectId)
      ) {
        return true;
      }
      return false;
    }
  }, [location.pathname, location.search, prev]);

  const isSameIndustryPage = useCallback(() => {
    if (!prev) return false;

    const search = parseQuery(location.search);
    const prevSearch = parseQuery(prev.search);

    // 同業界での変更はページ遷移扱いしない
    if (
      location.pathname === prev.pathname &&
      location.pathname.startsWith("/industry") &&
      _isSameIndustry(search, prevSearch)
    ) {
      return true;
    }
    return false;
  }, [location.pathname, location.search, prev]);

  const isSameCorpOrIndustryPage = useCallback(
    () => isSamePage() || isSameCorpPage() || isSameIndustryPage(),
    [isSamePage, isSameCorpPage, isSameIndustryPage]
  );

  return {
    isSamePage,
    isSameCorpOrIndustryPage
  };
};
