import React from "react";
import styles from "./style.scss";
import Transition, {
  TransitionStatus
} from "react-transition-group/Transition";
import Lottie from "components/Common/Lottie";
import variables from "utils/css/_variables.scss";
import loader from "images/animation/loader.json";
import cn from "classnames";

const duration = 300;
const zIndex = parseInt(variables.zPageLoading);

const transitionStyles: { [key in TransitionStatus]: React.CSSProperties } = {
  entering: { opacity: 1, zIndex, pointerEvents: "none" },
  entered: { opacity: 1, zIndex },
  exiting: { opacity: 1, zIndex },
  exited: { opacity: 0, zIndex: 0, pointerEvents: "none" },
  unmounted: {}
};

interface Props {
  isLoading: boolean;
  isTransparent?: boolean;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet"
  },
  renderer: "svg",
  animationData: loader
};

/**
 * ロード中画面
 * @props {boolean} isLoading ロード中かどうか
 * @props {boolean} isTransparent 背景を透過するか
 */
const Loading: React.FunctionComponent<Props> = ({
  isLoading,
  isTransparent = true
}) => {
  return (
    <Transition in={isLoading} timeout={duration}>
      {state => (
        <div
          data-testid="loading"
          className={cn(styles.component, {
            [styles.transparent]: isTransparent
          })}
          style={{ ...transitionStyles[state] }}
        >
          <Lottie height={"88px"} width={"188px"} options={defaultOptions} />
        </div>
      )}
    </Transition>
  );
};

export default Loading;
