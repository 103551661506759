import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";
import { CorpInfo } from "models/CorpInfo";

interface Binder {
  binderId: string;
  caption: string;
}

export interface GlobalState {
  common: {
    // 以下、URLパラメータで与えられた企業・業界情報
    //（corpコンテナ以下でcorpParamsとして利用可能な情報）
    corpIndustyCode: string; // 企業業界コード
    corpType: string; // 企業タイプ（上、未、海区分フラグ）
    corpIndustyKbn: string; // 企業・業界区分
    corpStkCode: string; // 株式コード
    corpPlaceMarket: string; // 代表上場市場
    corpno: string; //法人番号
  };
  corpInfo: CorpInfo;
  corpRelatedCollection: {
    totalCount: string;
    nikkeiBinderList: Binder[];
  };
  industryInfo: {
    name: string; // 業界名
    corpIndustryPath: string; // 業界パス
    sectorReportPath: string; // 新業界レポートURL
    keyPpt: string; //PPTダウンロードパス
  };
  corpParams: {
    corpIndustyKbn: string;
    corpIndustyCode: string;
    corpType: string;
    asrf: string;
  };
  telecom: {
    n21magic: string;
  };
  fromDiscParams: {
    asrf: string;
    corpIndustyCode: string;
  };
  // ローダーの表示(全画面)
  isLoading: boolean;
  // モバイル関係
  isMobileSize: boolean;
}

export const initialState: GlobalState = {
  common: {
    // 以下、URLパラメータで与えられた企業・業界情報
    //（corpコンテナ以下でcorpParamsとして利用可能な情報）
    corpIndustyCode: "", // 企業業界コード
    corpType: "", // 企業タイプ（上、未、海区分フラグ）
    corpIndustyKbn: "", // 企業・業界区分
    corpStkCode: "", // 株式コード
    corpPlaceMarket: "", // 代表上場市場
    corpno: "" //法人番号
  },
  corpInfo: {
    // APIから取得した企業情報
    country: "", // 海外企業の場合、国名が入る
    asrf: "", // diffflgとあわせて企業の分類を判別可能
    diffflg: "", // 企業タイプ（上、未、海区分フラグ）
    name: "", // 企業名称
    nameEn: "", // 企業名称（英語）
    nikkeiCode: "", // 日経コード（企業等の分類によっては空）
    placeMarket: "", // 代表上場市場
    stkcode: "", // 株式コード
    notAsrf: "",
    dis: "", // 「消滅会社」などが入る補足説明部分
    corpIndustryPath: "", // 業界パス
    corpIndustyCode: "", // corpInfoの企業・業界のコード(discリクエストした企業)
    corpIndustyKbn: "", // corpInfoの企業・業界の区分
    businessCode: "" //業態分類
  },
  corpRelatedCollection: {
    totalCount: "",
    nikkeiBinderList: []
  },
  industryInfo: {
    name: "", // 業界名
    corpIndustryPath: "", // 業界パス
    sectorReportPath: "", // 新業界レポートURL
    keyPpt: "" //PPTダウンロードパス
  },
  corpParams: {
    corpIndustyKbn: "",
    corpIndustyCode: "",
    corpType: "",
    asrf: ""
  },
  telecom: {
    n21magic: ""
  },
  fromDiscParams: {
    asrf: "",
    corpIndustyCode: ""
  },
  // ローダーの表示(全画面)
  isLoading: false,
  isMobileSize: false
};

const pickExistsKeys = (filteredObject: any, basedObject: any) => {
  const filtered: any = {};
  Object.keys(filteredObject).forEach(targetKey => {
    if (Object.keys(basedObject).includes(targetKey)) {
      filtered[targetKey] = filteredObject[targetKey];
    }
  });
  return filtered;
};

export default handleActions<GlobalState, any>(
  {
    [ActionTypes.PARAMS_SET]: (state, action) => {
      if (typeof action.payload !== "object") return state;
      const mergeState = { ...action.payload };
      // initialState にキーが存在するもののみ更新
      Object.keys(action.payload).forEach(key => {
        if (
          Object.keys(initialState.common).find(stateKey => stateKey == key) ===
          undefined
        ) {
          delete mergeState[key];
        }
      });

      return { ...state, common: { ...state.common, ...mergeState } };
    },
    [ActionTypes.CORP_INFO_SET]: (state, action) => ({
      ...state,
      corpInfo: {
        ...state.corpInfo,
        ...pickExistsKeys(action.payload, initialState.corpInfo)
      },
      common: {
        ...state.common,
        corpStkCode: action.payload.stkcode,
        corpPlaceMarket: action.payload.placeMarket
      }
    }),
    [ActionTypes.RELATED_COLLECTION_SET]: (state, action) => ({
      ...state,
      corpRelatedCollection: {
        ...state.corpRelatedCollection,
        ...pickExistsKeys(action.payload, initialState.corpRelatedCollection)
      }
    }),
    [ActionTypes.INDUSTRY_INFO_SET]: (state, action) => ({
      ...state,
      industryInfo: {
        ...state.industryInfo,
        ...pickExistsKeys(action.payload, initialState.industryInfo)
      }
    }),
    [ActionTypes.CORP_PARAMS_SET]: (state, action) => ({
      ...state,
      corpParams: {
        ...state.corpParams,
        ...pickExistsKeys(action.payload, initialState.corpParams)
      }
    }),
    [`${ActionTypes.LOAD_TELECOM_INFO}_FULFILLED`]: (state, action) => ({
      ...state,
      telecom: { n21magic: action.payload.n21magic }
    }),
    [ActionTypes.ASRF_SAVE]: (state, action) => ({
      ...state,
      fromDiscParams: action.payload
    }),
    [ActionTypes.LOADING_SET]: (state, action) => ({
      ...state,
      isLoading: action.payload
    }),
    [ActionTypes.RELATED_COLLECTION_RESET]: state => ({
      ...state,
      corpRelatedCollection: initialState.corpRelatedCollection
    }),
    [ActionTypes.IS_MOBILE_SIZE_SET]: (state, action) => ({
      ...state,
      isMobileSize: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  setParams: createAction(ActionTypes.PARAMS_SET),
  setCorpInfo: createAction(ActionTypes.CORP_INFO_SET),
  setIndustryInfo: createAction(ActionTypes.INDUSTRY_INFO_SET),
  setCorpParams: createAction(ActionTypes.CORP_PARAMS_SET),
  setRelatedCollection: createAction(ActionTypes.RELATED_COLLECTION_SET),
  loadTelecomInfo: createAction(ActionTypes.LOAD_TELECOM_INFO, (params: any) =>
    request(21, params)
  ),
  saveAsrf: createAction(ActionTypes.ASRF_SAVE),
  setLoadingStatus: createAction(ActionTypes.LOADING_SET),
  resetRelatedCollection: createAction(ActionTypes.RELATED_COLLECTION_RESET),
  setIsMobileSize: createAction(ActionTypes.IS_MOBILE_SIZE_SET)
};
