import React from "react";
import ModalUtilContext from "contexts/modalUtil";

const modalUtil = Component =>
  React.forwardRef((props, ref) => (
    <ModalUtilContext.Consumer>
      {methods => (
        <Component
          ref={ref}
          modalStack={methods.modalStack}
          registerModal={methods.registerModal}
          releaseModal={methods.releaseModal}
          addKeyEventListener={methods.addKeyEventListener}
          removeKeyEventListener={methods.removeKeyEventListener}
          {...props}
        />
      )}
    </ModalUtilContext.Consumer>
  ));

export default modalUtil;
