// 初期表示
export const PAGE_INIT = "industry/research-report/PAGE_INIT";
export const OPTION_LIST_SET = "industry/research-report/OPTION_LIST_SET";
// セレクトボックス変更時
export const OPTION_SET = "industry/research-report/OPTION_SET";
// 産業調査レポート検索結果ページ取得
export const RESEARCH_PAGE_GET = "industry/research-report/RESEARCH_PAGE_GET";
// 産業調査レポート検索結果並び順取得
export const RESEARCH_SORTED_GET =
  "industry/research-report/RESEARCH_SORTED_GET";
// 産業調査レポート検索結果国別取得
export const RESEARCH_COUNTRY_GET =
  "industry/research-report/RESEARCH_COUNTRY_GET";
// 産業調査レポート検索結果業界変更時
export const RESEARCH_INDUSTRY_CHANGE =
  "industry/research-report/RESEARCH_INDUSTRY_CHANGE";
