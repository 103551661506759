/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

// 企業・業界区分
export const corpIndustryKbn = {
  domestic: "1", // 国内企業
  overseas: "2", // 海外企業
  industryMedium: "3", // 業界中分類
  industrySmall: "4", // 業界小分類
  statistics: "5", // 経済統計
  article: "6", // ニュース・調査レポート
  tool: "7", // ツール
  settings: "8", // 設定
  system: "9", // システム管理
  search: "10" // 検索
};

export type PathInfo = {
  name?: string;
  path?: string;
  context?: PathContext[];
  query?: any;
  key?: string;
  params?: any;
  subPathKey?: any; // TODO: 削除予定
  scopeRef?: string;
  children?: PathInfo[];
  parent?: PathInfo;
};

export type PathContext =
  | "listed"
  | "report"
  | "unlisted"
  | "startup"
  | "tdb"
  | "medium"
  | "small"
  | "setting"
  | "overseas"
  | "unlistedOverseas"
  | "directAccess";

const _prepareContextChecker = (ctx?: PathContext | PathContext[]) => (
  value: PathContext
): boolean => {
  return Array.isArray(ctx) ? ctx.includes(value) : ctx === value;
};

export const loadPaths = (ctx?: PathContext | PathContext[]): PathInfo[] => {
  const isContext = _prepareContextChecker(ctx);
  return [
    {
      name: "企業",
      key: "corp",
      children: [
        {
          name: "企業情報",
          key: "corporation",
          children: [
            {
              name: "企業基本",
              key: "basics",
              path: "/corp/summary",
              children: [
                {
                  name: "企業サマリ",
                  path: "/corp/summary",
                  context: [
                    "listed",
                    "report",
                    "unlisted",
                    "startup",
                    "tdb",
                    "overseas",
                    "unlistedOverseas",
                    "directAccess"
                  ]
                },
                {
                  name: "企業概要",
                  path: "/corp/overview",
                  context: ["listed", "report", "unlisted"]
                },
                {
                  name: "サプライチェーン",
                  path: "/corp/supplychain",
                  context: ["listed", "report", "overseas", "unlistedOverseas"]
                },
                {
                  name: "人事・組織情報",
                  path: "/corp/whoswho",
                  context: ["listed", "report", "unlisted", "directAccess"]
                },
                {
                  name: "リスク情報",
                  path: "/corp/risk",
                  context: ["listed", "report"]
                }
              ]
            },
            {
              name: "株主・保有株式",
              key: "shares",
              path: "/corp/shares/shareholder",
              children: [
                {
                  name: "株主情報",
                  path: "/corp/shares/shareholder",
                  context: ["listed", "report"]
                },
                {
                  name: "株主・保有株式",
                  path: "/corp/shares/shareholder",
                  context: ["overseas"]
                },
                {
                  name: "保有株式情報",
                  path: "/corp/shares/holdings",
                  context: ["listed", "report"]
                }
              ]
            },
            {
              name: "株主総会",
              key: "meeting",
              path: "/corp/meeting",
              context: ["listed", "report"],
              children: [{ name: "株主総会", path: "/corp/meeting" }]
            },
            {
              name: "CSR・ガバナンス",
              key: "csr",
              path: "/corp/csr/governance",
              context: ["listed", "report"],
              children: [
                {
                  name: "コーポレートガバナンス",
                  path: "/corp/csr/governance"
                },
                { name: "環境活動", path: "/corp/csr/environment" },
                { name: "労務関連", path: "/corp/csr/labor" },
                {
                  name: "コーポレートガバナンス評価",
                  path: "/corp/csr/evaluation"
                }
              ]
            },
            {
              name: "ESG",
              key: "esg",
              path: "/corp/esg/environment",
              context: ["listed", "report"],
              children: [
                {
                  name: "環境",
                  path: "/corp/esg/environment"
                },
                { name: "社会", path: "/corp/esg/society" },
                { name: "ガバナンス", path: "/corp/esg/governance" }
              ]
            }
          ]
        },
        {
          name: "財務情報",
          key: "finance",
          children: [
            {
              name: "財務諸表",
              key: "table",
              path: "/corp/finance",
              query: {
                menuId: isContext("overseas")
                  ? "CK3010"
                  : isContext("unlisted")
                  ? "CH3010"
                  : "C3010"
              },
              children: [
                { name: "財務サマリ", path: "", context: ["listed", "report"] },
                {
                  name: "財務諸表",
                  path: "",
                  context: ["unlisted", "overseas"]
                }
              ]
            },
            {
              name: "明細票・注記",
              key: "statement",
              path: "/corp/finance",
              query: { menuId: "C3020" },
              context: ["listed", "report"],
              children: [{ name: "費用明細", path: "" }]
            },
            {
              name: "財務分析表",
              key: "analysis",
              path: "/corp/finance",
              query: { menuId: "C3030" },
              context: ["listed", "report"],
              children: [{ name: "財務指標", path: "" }]
            },
            {
              name: "財務指標",
              key: "indicator",
              path: "/corp/finance",
              query: { menuId: "CK3020" },
              context: ["overseas"],
              children: [{ name: "財務指標", path: "" }]
            },
            {
              name: "セグメント情報",
              key: "segment",
              path: "/corp/segment/business",
              children: [
                {
                  name: isContext("overseas") ? "事業別・地域別" : "事業別",
                  key: "business",
                  path: "/corp/segment/business",
                  context: ["listed", "report", "overseas"]
                },
                {
                  name: "地域別",
                  key: "area",
                  path: "/corp/segment/area",
                  context: ["listed", "report"]
                },
                {
                  name: "国別",
                  key: "country",
                  path: "/corp/segment/country",
                  context: ["listed", "report", "overseas"]
                }
              ]
            },
            {
              name: "金融機関別借入金",
              key: "loan",
              path: "/corp/loan",
              context: ["listed", "report"],
              children: [{ name: "金融機関別借入金", path: "/corp/loan" }]
            },
            {
              name: "業績予想",
              key: "forecast",
              path: "/corp/earning-forecast",
              context: ["listed", "report", "overseas"],
              children: [{ name: "業績予想", path: "/corp/earning-forecast" }]
            }
          ]
        },
        {
          name: "IR情報",
          key: "ir",
          children: [
            {
              name: "開示資料",
              key: "disclosure",
              path: "/corp/disclosure",
              context: ["listed", "report", "overseas"],
              children: [{ name: "開示資料", path: "/corp/disclosure" }]
            },
            {
              name: "IRデータ",
              key: "ir",
              path: "/corp/ir-data",
              context: ["listed", "report"],
              children: [{ name: "IRデータ", path: "/corp/ir-data" }]
            }
          ]
        },
        {
          name: "マーケット情報",
          key: "market",
          children: [
            {
              name: "株価",
              key: "stock",
              path: "/corp/index",
              context: ["listed", "report", "overseas", "directAccess"],
              children: [{ name: "株価", path: "/corp/index" }]
            },
            {
              name: "バリュエーション",
              key: "valuation",
              path: "/corp/valuation/dcf",
              children: [
                {
                  name: "DCF",
                  path: "/corp/valuation/dcf",
                  context: ["listed", "report"]
                },
                {
                  name: "ベータ値",
                  path: "/corp/valuation/beta",
                  context: ["listed", "report"]
                },
                {
                  name: isContext("overseas") ? "バリュエーション" : "企業価値",
                  path: "/corp/valuation/value",
                  context: ["listed", "report", "overseas"]
                },
                {
                  name: "投資指標",
                  path: "/corp/valuation/investment-index",
                  context: ["listed", "report"]
                }
              ]
            }
          ]
        },
        {
          name: "記事・レポート",
          key: "news",
          context: ["listed", "report", "unlisted", "startup", "tdb"],
          children: [
            {
              name: "ニュース記事・雑誌",
              key: "news",
              path: "/corp/news",
              children: [{ name: "ニュース記事・雑誌", path: "/corp/news" }]
            },
            {
              name: "業界調査レポート",
              key: "report",
              path: "/corp/report",
              children: [{ name: "業界調査レポート", path: "/corp/report" }]
            }
          ]
        },
        {
          name: "比較表・M&A",
          key: "ma",
          children: [
            {
              name: "同業企業比較",
              key: "comparison",
              path: "/corp/comparison/finance",
              context: ["listed", "report", "unlisted", "tdb", "overseas"],
              children: [
                { name: "企業", path: "/corp/comparison/finance" },
                { name: "セグメント", path: "/corp/comparison/segment" }
              ]
            },
            {
              name: "企業イベント",
              key: "event",
              path: "/corp/ma-event",
              context: ["listed", "report"],
              children: [{ name: "企業イベント", path: "/corp/ma-event" }]
            },
            {
              name: "M&A・企業活動",
              key: "strategy",
              path: "/corp/ma-strategy",
              context: ["listed", "report"],
              children: [{ name: "M&A・企業活動", path: "/corp/ma-strategy" }]
            }
          ]
        },
        {
          name: "ツール",
          key: "tool",
          children: [
            {
              name: "グラフ集",
              key: "graph",
              path: "/corp/graph",
              context: ["listed", "report"],
              children: [{ name: "グラフ集", path: "/corp/graph" }]
            }
          ]
        },
        {
          name: "日経テレコン",
          key: "telecom",
          children: [
            {
              name: "企業情報検索",
              key: "search",
              path: "/corp/telecom/search",
              context: ["listed", "report", "unlisted", "startup", "tdb"],
              children: [{ name: "企業情報検索", path: "/corp/telecom/search" }]
            },
            {
              name: "業界紙検索",
              key: "press",
              path: "/corp/telecom/trade-press",
              context: ["listed", "report", "unlisted", "startup", "tdb"],
              children: [
                { name: "業界紙検索", path: "/corp/telecom/trade-press" }
              ]
            }
          ]
        }
      ]
    },
    {
      name: "業界",
      key: "industry",
      children: [
        {
          name: "業界情報",
          key: "industry",
          children: [
            {
              name: "業界概要",
              key: "summary",
              path: "/industry/summary",
              context: ["medium", "small", "directAccess"],
              children: [{ name: "業界概要", path: "/industry/summary" }]
            },
            {
              name: "業界指標",
              key: "index",
              path: "/industry/index",
              children: [{ name: "業界指標", path: "/industry/index" }]
            },
            {
              name: "業界統計・ＩＲデータ",
              key: "ir-data",
              path: "/industry/ir-data",
              children: [
                { name: "業界統計・ＩＲデータ", path: "/industry/ir-data" }
              ]
            },
            {
              name: "業界集計",
              key: "scale",
              path: "/industry/scale",
              children: [{ name: "業界規模", path: "/industry/scale" }]
            }
          ]
        },
        {
          name: "記事・レポート",
          key: "news",
          children: [
            {
              name: "ニュース記事・雑誌",
              key: "news",
              path: "/industry/news",
              children: [{ name: "ニュース記事・雑誌", path: "/industry/news" }]
            },
            {
              name: "業界調査レポート",
              key: "report",
              path: "/industry/report",
              children: [{ name: "業界調査レポート", path: "/industry/report" }]
            },
            {
              name: "産業調査レポート",
              key: "research-report",
              path: "/industry/research-report",
              children: [
                { name: "産業調査レポート", path: "/industry/research-report" }
              ]
            }
          ]
        },
        {
          name: "企業比較・Ｍ＆Ａ",
          key: "event",
          children: [
            {
              name: "企業イベント",
              key: "event",
              path: "/industry/event",
              children: [{ name: "企業イベント", path: "/industry/event" }]
            },
            {
              name: "企業活動情報",
              key: "strategy",
              path: "/industry/strategy",
              children: [{ name: "企業活動情報", path: "/industry/strategy" }]
            }
          ]
        },
        {
          name: "日経テレコン",
          key: "telecom",
          context: ["small"],
          children: [
            {
              name: "業界紙検索",
              key: "press",
              path: "/industry/telecom/trade-press",
              children: [
                { name: "業界紙検索", path: "/industry/telecom/trade-press" }
              ]
            }
          ]
        }
      ]
    },
    {
      name: "企業検索",
      key: "company-search",
      children: [
        {
          name: "詳細条件から",
          path: "/tool/screening/corp/item"
        },
        {
          name: "シナリオから",
          path: "/tool/screening/corp/scenario"
        },
        {
          name: "事業セグメントから",
          path: "/tool/screening/segment/business"
        },
        {
          name: "地域セグメントから",
          path: "/tool/screening/segment/area"
        },
        {
          name: "業界分類から",
          path: "/tool/screening/segment/area"
        }
      ]
    },
    {
      name: "業界検索",
      key: "industry-search",
      children: [
        {
          name: "簡易検索",
          path: "/industry-search"
        },
        {
          name: "業界リスト作成",
          path: "/industry-search/list"
        },
        {
          name: "ヒートマップ",
          path: "/industry-search/heat-map"
        }
      ]
    },
    {
      name: "便利ツール",
      key: "tool",
      children: [
        {
          name: "スクリーニング（企業）",
          key: "screeningCorporation",
          context: ["directAccess"],
          children: [
            {
              name: isContext("directAccess")
                ? "スクリーニング(企業)"
                : "詳細条件から",
              path: "/tool/screening/corp/item"
            },
            {
              name: isContext("directAccess")
                ? "スクリーニング(企業)"
                : "シナリオから",
              path: "/tool/screening/corp/scenario"
            },
            {
              name: "事業セグメントから",
              path: "/tool/screening/segment/business"
            },
            {
              name: "地域セグメントから",
              path: "/tool/screening/segment/area"
            },
            {
              name: "業界分類から",
              path: "/tool/by-industry"
            }
          ]
        }
      ]
    },
    {
      key: "globalNavigation",
      children: [
        {
          name: "企業・業界検索",
          key: "corporationIndustries",
          children: [
            {
              name: "企業を探す",
              children: [
                {
                  name: "業界から絞り込む",
                  path: "/search/by-industry",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.search,
                    contentId: "H004002"
                  }
                },
                {
                  name: "スクリーニング（企業）",
                  path: "/tool/screening/corp/scenario", // TODO: 重複項目
                  subPathKey: "screeningCorporation", // TODO: 削除予定
                  scopeRef: "tool.screeningCorporation.*",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "H004003"
                  }
                },
                {
                  name: "スクリーニング\n（セグメント）",
                  path: "/tool/screening/segment/business", // TODO: 重複項目
                  subPathKey: "screeningSegment", // TODO: 削除予定
                  scopeRef: "tool.screeningSegment.*",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "H004004"
                  }
                }
              ]
            },
            {
              name: "業界を探す",
              key: "searchIndustry",
              children: [
                {
                  name: "業界検索",
                  path: "/search/industry",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.search,
                    contentId: "H005001"
                  }
                },
                {
                  name: "分類から絞り込む",
                  path: "/search/classification",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.search,
                    contentId: "H005002"
                  }
                },
                {
                  name: "スクリーニング（業界）",
                  path: "/tool/screening/industry",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "H005003"
                  }
                }
              ]
            }
          ]
        },
        {
          name: "記事・レポート検索",
          key: "articles",
          children: [
            {
              name: undefined,
              children: [
                {
                  name: "ニュース記事・雑誌",
                  path: "/article/news",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.article,
                    contentId: "N6010"
                  }
                },
                {
                  name: "業界調査レポート",
                  path: "/article/report",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.article,
                    contentId: "N6020"
                  }
                },
                {
                  name: "産業調査レポート",
                  path: "/article/research-report",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.article,
                    contentId: "N6030"
                  }
                },
                {
                  name: "開示資料",
                  path: "/article/disclosure",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.article,
                    contentId: "N4010"
                  }
                },
                {
                  name: "企業イベント",
                  path: "/article/event",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.article,
                    contentId: "N9020"
                  }
                },
                {
                  name: "Ｍ＆Ａ情報",
                  path: "/article/ma",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.article,
                    contentId: "N9010"
                  }
                },
                {
                  name: "企業活動情報",
                  path: "/article/strategy",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.article,
                    contentId: "N9050"
                  }
                }
              ]
            }
          ]
        },
        {
          name: "経済統計・商品情報",
          key: "statistics",
          children: [
            {
              name: undefined,
              children: [
                {
                  name: "経済統計サマリ",
                  path: "/macro/summary",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.statistics,
                    contentId: "E1010"
                  }
                },
                {
                  name: "経済統計",
                  path: "/macro/statistics",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.statistics,
                    contentId: "E2010"
                  }
                },
                {
                  name: "経済予測",
                  path: "/macro/forecast",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.statistics,
                    contentId: "E2020"
                  }
                },
                {
                  name: "業界統計・ＩＲデータ",
                  path: "/macro/ir-data",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.statistics,
                    contentId: "E2030"
                  }
                },
                {
                  name: "商品市況",
                  path: "/macro/commodity",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.statistics,
                    contentId: "E2050"
                  }
                },
                {
                  name: "地域情報",
                  path: "/macro/area",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.statistics,
                    contentId: "E2040"
                  }
                }
              ]
            }
          ]
        },
        {
          name: "便利ツール",
          key: "tools",
          children: [
            {
              name: undefined,
              children: [
                {
                  name: "スクリーニング（企業）",
                  path: "/tool/screening/corp/scenario",
                  subPathKey: "screeningCorporation", // TODO: 削除予定
                  scopeRef: "tool.screeningCorporation.*",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T3010"
                  }
                },
                {
                  name: "スクリーニング\n（セグメント）",
                  path: "/tool/screening/segment/business",
                  subPathKey: "screeningSegment", // TODO: 削除予定
                  scopeRef: "tool.screeningSegment.*",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T5010"
                  }
                },
                {
                  name: "スクリーニング（業界）",
                  path: "/tool/screening/industry",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T7010"
                  }
                },
                {
                  name: "業界から絞り込む",
                  path: "/tool/by-industry",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.search,
                    contentId: "H004002"
                  }
                },
                {
                  name: "企業比較",
                  path: "/tool/comparison/corp",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T2010"
                  }
                },
                {
                  name: "業界比較",
                  path: "/tool/comparison/industry",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T6010"
                  }
                },
                {
                  name: "マルチプル\n（ヒストリカル）",
                  path: "/tool/multiple",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T2020"
                  }
                },
                {
                  name: "グラフ作成",
                  path: "/tool/graph-generator",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T4020"
                  }
                },
                {
                  name: "Excel\nアドインテンプレート集",
                  path: "/tool/excel-addin",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T4040"
                  }
                },
                {
                  name: "ESG総合",
                  path: "/tool/esg",
                  params: {
                    corpIndustyKbn: corpIndustryKbn.tool,
                    contentId: "T8010"
                  }
                },
                {
                  name: "ダウンロード管理画面",
                  path: "/tool/download"
                },
                {
                  name: "メール経由ダウンロード管理画面",
                  path: "/tool/download/list"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: "設定",
      key: "setting",
      children: [
        { name: "オプション契約", path: "/setting/option" },
        { name: "従量料金照会", path: "/setting/user-fee" },
        { name: "ログイン履歴照会", path: "/setting/login-history" },
        { name: "個人設定", path: "/setting/user" },
        { name: "個人設定", path: "/setting/personal" },
        { name: "掲示コンテンツ管理", path: "/setting/contents" }
      ]
    },
    {
      key: "help",
      children: [
        {
          name: "お知らせ",
          path: "/home/notice",
          key: "noticeUrl"
        },
        {
          name: "お問い合わせ",
          path: "",
          key: "qaUrl"
        },
        {
          name: "ヘルプサイト",
          path: "https://intercom.help/valuesearch/"
        },
        {
          name: "データ定義",
          path: "",
          key: "helpUrl"
        }
      ]
    },
    {
      name: "ヘッダー",
      key: "header",
      children: [
        { name: "コレクション", path: "/binder" },
        { name: "閲覧履歴", path: "/history" }
      ]
    },
    {
      key: "footer",
      children: [
        {
          name: "フッター",
          key: "internal",
          children: [
            { path: "/rights", name: "著作権" },
            { path: "/policy", name: "ご利用上の制限" },
            { path: "/disclaimer", name: "免責" },
            {
              path: "https://www.nikkei.co.jp/digitalmedia/privacy.html",
              name: "個人情報"
            },
            {
              path: "https://www.nikkei.com/lounge/privacy/cookie/policy.html",
              name: "クッキーポリシー"
            }
          ]
        },
        {
          key: "external",
          children: [
            {
              path: "https://www.nikkei.co.jp/nikkeiinfo/",
              name: "会社情報・お知らせ"
            },
            {
              path: "https://www.nikkei.com/promotion/service/share/",
              name: "記事利用について"
            }
          ]
        }
      ]
    },
    {
      name: "ホーム",
      key: "home",
      children: [
        { name: "トップ", path: "/home" },
        { name: "お知らせ一覧", path: "/home/notice" },
        { name: "日経 Bizトレンド一覧", path: "/home/trend-report" },
        { name: "日経業界分析レポート一覧", path: "/home/sector-report" }
      ]
    },
    {
      name: "ポップアップ",
      key: "popup",
      children: [
        { path: "/corp/ma-strategy/ma" },
        { path: "/corp/summary/tdb-corp" },
        { path: "/corp/summary/tdb-finance" },
        { path: "/home/trend-report" },
        { path: "/home/trend-report-print" },
        { path: "/binder" },
        { path: "/article/strategy" },
        { path: "/binder/(personal|public)" }
      ]
    },
    {
      key: "directAccess",
      context: ["directAccess"],
      children: [
        { name: "一括検索", path: "/searchlist" },
        { name: "ホーム", path: "/gohome" },
        { name: "ホーム", path: "/" }
      ]
    },
    {
      name: "一括検索",
      key: "searchlist",
      children: [
        { path: "/searchlist/summary" },
        { path: "/searchlist/corp" },
        { path: "/searchlist/industry" },
        { path: "/searchlist/disclosure" },
        { path: "/searchlist/news" },
        { path: "/searchlist/magazine" },
        { path: "/searchlist/government" },
        { path: "/searchlist/ir" },
        { path: "/searchlist/statistics" },
        { path: "/searchlist/collection" }
      ]
    },
    {
      name: "コレクション",
      key: "binder",
      children: [
        {
          name: "コレクション一覧",
          path: "/binder/(personal|public)",
          children: [
            { name: "マイコレクション", path: "/binder/personal" },
            { name: "日経コレクション", path: "/binder/public" }
          ]
        },
        {
          name: "コレクション詳細",
          path: "/binder/:binderId(\\d+)/(.*)",
          children: [
            { name: "フィード", path: "/binder/(\\d+)/article" },
            { name: "企業", path: "/binder/(\\d+)/corp" },
            { name: "業界", path: "/binder/(\\d+)/industry" },
            { name: "統計", path: "/binder/(\\d+)/statistics" },
            { name: "記事", path: "/binder/(\\d+)/saveArticle" },
            { name: "ページ", path: "/binder/(\\d+)/externalPage" }
          ]
        }
      ]
    }
  ];
};
