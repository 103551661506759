import { EsgGovernance } from "./actions";
import { Reducer } from "redux";
import produce from "immer";

import { DislosureYears, EsgInfoData } from "models/CorpEsg";

export interface EsgGovernanceState {
  data?: {
    accessStatus: string;
    corpCSRReport01: {
      governance: {
        auditorNum: string;
        auditSystemEmp: string;
        auditorAssociationNum: string;
        auditorInstallation: string;
        auditorOtherAuditor: string;
        auditorOtherAuditorNum: string;
        auditorOtherIndependentDirectorNum: string;
        corpOfficerNum: string;
        dataDate: string;
        defensiveMeasure: string[];
        defensiveMeasureFlg: string;
        directorAssociationNum: string;
        directorAssociationTerm: string;
        directorChairman: string;
        directorNum: string;
        directorIndependentDirectorNum: string;
        directorOtherDirector: string;
        directorOtherDirectorNum: string;
        directorOtherIndependentDirectorNum: string;
        supplementaryExplanation: string;
        tissueMorphology: string;
        tissueMorphologyFlg: string;
        rewardCorpOfficer: string;
        rewardDecisionPolicy: string;
        rewardDecisionPolicyFlg: string;
        rewardDirectorIndividual: string;
      };
      incentive: string;
      stock: string;
    };
    dlpropriety: string;
    dlxlspath: string;
    dlxls: string;
    dlcsvpath: string;
    dlcsv: string;
    directaccess_corpIndustyKbn: string;
    directaccess_corpIndustyCode: string;
    directaccess_corpType: string;
  };
  dislosureYears: DislosureYears[];
  generalDisclosureInfo?: EsgInfoData;
  customerPrivacyInfo?: EsgInfoData;
  sustainabilityInfo?: EsgInfoData;
  evaluation?: {
    corpCSRCgesDto: {
      summaryCorpGovWeightList: [];
      corpCSRCgesDetailDataList: [];
      updateMonth: string;
    };
    str01: string;
    itemName: string;
    dlxls1: string;
    dlxls2: string;
    dlcsv1: string;
    dlcsv2: string;
    dlxlspath1: string;
    dlxlspath2: string;
    dlcsvpath1: string;
    dlcsvpath2: string;
    dlpropriety1: string;
    dlpropriety2: string;
    itemNameList: [];
  };
  reportData?: {};
  generalDisclosureDisclosureYear: string;
  customerPrivacyDisclosureYear: string;
  sustainabilityDisclosureYear: string;
  generalDisclosureTextDataYear: string;
  customerPrivacyTextDataYear: string;
  sustainabilityTextDataYear: string;
  defaultBusinessYear: string;
}

const initialState: EsgGovernanceState = {
  data: undefined,
  dislosureYears: [],
  generalDisclosureInfo: undefined,
  customerPrivacyInfo: undefined,
  sustainabilityInfo: undefined,
  evaluation: undefined,
  reportData: undefined,
  generalDisclosureDisclosureYear: "",
  customerPrivacyDisclosureYear: "",
  sustainabilityDisclosureYear: "",
  generalDisclosureTextDataYear: "",
  customerPrivacyTextDataYear: "",
  sustainabilityTextDataYear: "",
  defaultBusinessYear: ""
};

export const corpEsgGovernance: Reducer<EsgGovernanceState, EsgGovernance> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "corp/esg/governance/SET_GOVERNANCE_DATA_PAGE_INIT":
      return produce(state, draft => {
        draft.data = action.payload;
      });
    case "corp/esg/governance/SET_DISCLOSURE_YEARS":
      return produce(state, draft => {
        draft.dislosureYears = action.payload;
      });
    case "corp/esg/governance/SET_GENERAL_DISCLOSURE_INFO":
      return produce(state, draft => {
        draft.generalDisclosureInfo = action.payload;
      });
    case "corp/esg/governance/SET_CUSTOMER_PRIVACY_INFO":
      return produce(state, draft => {
        draft.customerPrivacyInfo = action.payload;
      });
    case "corp/esg/governance/SET_SUSTAINABILITY_INFO":
      return produce(state, draft => {
        draft.sustainabilityInfo = action.payload;
      });
    case "corp/esg/governance/SET_EVALUATION_DATA":
      return produce(state, draft => {
        draft.evaluation = action.payload;
      });
    case "corp/esg/governance/SET_GENERAL_DISCLOSURE_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.generalDisclosureDisclosureYear = action.payload;
      });
    case "corp/esg/governance/SET_CUSTOMER_PRIVACY_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.customerPrivacyDisclosureYear = action.payload;
      });
    case "corp/esg/governance/SET_SUSTAINABILITY_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.sustainabilityDisclosureYear = action.payload;
      });
    case "corp/esg/governance/SET_GENERAL_DISCLOSURE_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.generalDisclosureTextDataYear = action.payload;
      });
    case "corp/esg/governance/SET_CUSTOMER_PRIVACY_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.customerPrivacyTextDataYear = action.payload;
      });
    case "corp/esg/governance/SET_SUSTAINABILITY_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.sustainabilityTextDataYear = action.payload;
      });
    case "corp/esg/governance/SET_REPORT_DATA":
      return produce(state, draft => {
        draft.reportData = action.payload;
      });
    default:
      return state;
  }
};
