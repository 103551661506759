import React from "react";
import styles from "./style.scss";

export const SearchEmpty: React.FunctionComponent = () => {
  return (
    <div className={styles.component}>
      <div className={styles.icon} />
      <div className={styles.label}>一致する情報が見つかりませんでした</div>
      <div className={styles.description}>
        キーワードを変更いただくことで関連する情報が見つかる可能性があります。
      </div>
    </div>
  );
};
