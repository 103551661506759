import React from "react";
import styles from "./style.scss";

import { isEmpty } from "utils/helper/common";
import { openPopup } from "components/Common/Popup/Link/index";
import { corpIndustryKbn, stringifyQuery } from "utils/helper/url";

const RelatedLinks = props => {
  if (isEmpty(props.list)) return null;

  const type = props.type;
  const heading = {
    corp: "企業",
    industry: "業界"
  };
  const pathname = {
    corp: "/corp/summary",
    industry: "/industry/summary"
  };

  const handlePopupOpen = param => {
    if (props.forPrintUse) return false; // 印刷用画面ではリンクを機能させない
    openPopup(param);
  };

  return (
    <div className={styles.component}>
      <h4 className={styles.heading}>{heading[type]}</h4>
      <ul className={styles.list}>
        {props.list.map((item, index) => {
          let query;
          let itemName;
          let code;
          if (type === "corp") {
            query = {
              corpIndustyCode: item.nikkeiCode,
              corpIndustyKbn: item.corpIndustyKbn,
              corpType: item.corpType,
              asrf: item.asrf,
              corpStkCode: item.stkcode
            };
            itemName = item.corpName;
            code = item.nikkeiCode;
          }
          if (type === "industry") {
            query = {
              corpIndustyCode: item.nkgncodes,
              corpIndustyKbn: corpIndustryKbn.industrySmall
            };
            itemName = item.nkgnnames;
            code = item.nkgncodes;
          }
          const url = `${pathname[type]}?${stringifyQuery(query)}`;
          return (
            <li key={index}>
              <a
                className={props.forPrintUse ? styles.printUse : null}
                data-testid="Common-Popup-RelatedLink-Li"
                onClick={() => {
                  handlePopupOpen({ pathname: pathname[type], query });
                  props.onTrackAtlasAction({
                    type: type,
                    name: itemName,
                    url: url,
                    code: code
                  });
                }}
              >
                {itemName}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RelatedLinks;
