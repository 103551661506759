import React, { useEffect, useRef, useState } from "react";
import styles from "./style.scss";
import cn from "classnames";
import * as CopyButtonImage from "images/icon/copy_button.svg";
import * as TextExpandImage from "images/icon/textExpand.svg";
import * as TextClosedImage from "images/icon/textClosed.svg";
import { useToast } from "hooks/useToast";

export interface Props {
  className?: string;
  copyText: string;
}

export const CopyInput: React.FunctionComponent<Props> = props => {
  const [isFullTextOpen, setIsFullTextOpen] = useState<boolean>(false);
  const toast = useToast();
  const textRef = useRef<HTMLDivElement | null>(null);
  const [displayExpandButton, setDisplayExpandButton] = useState(false);

  const copyTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    if (!textRef.current) return;

    setDisplayExpandButton(
      textRef.current.scrollWidth > textRef.current.offsetWidth
    );
  }, [props.copyText]);

  return (
    <div
      className={cn(styles.inputArea, {
        [styles.isClosedTextArea]: !isFullTextOpen
      })}
    >
      <div
        className={cn(styles.text, {
          [styles.isClosedText]: !isFullTextOpen
        })}
        ref={textRef}
      >
        {props.copyText}
      </div>
      {displayExpandButton && (
        <span
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            setIsFullTextOpen(!isFullTextOpen);
          }}
        >
          <img
            src={isFullTextOpen ? TextClosedImage : TextExpandImage}
            className={styles.expandIcon}
          />
        </span>
      )}
      <span
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation();
          copyTextToClipboard(props.copyText);
          toast.setMessages(["テキストをクリップボードにコピーしました"]);
        }}
      >
        <img src={CopyButtonImage} className={styles.copyIcon} />
      </span>
    </div>
  );
};

export default CopyInput;
