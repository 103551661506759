import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";

const initialState = {
  comparisonData: {},
  // 条件選択状態
  comparisonConditions: {
    indexGroupIdFlg: "", // selectSegment 選択中 index
    sortFlag: "",
    sortId: ""
  },
  nikkeiCodeList: [], // 企業コードリスト
  allNkCodeList: [], // 全ての企業コードリスト
  isLoadingComparisonData: false,
  selectSegment: [], // セグメントリスト
  dlPeriodList: [],
  dlPeriodFrom: "",
  dlPeriodTo: "",
  needsSearch: false,
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.GET_COMPARISON_DATA}_PENDING`]: state => ({
      ...state,
      needsSearch: false,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.GET_COMPARISON_DATA}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: action.payload,
      allNkCodeList: action.payload.allNkCodeList,
      isLoadingComparisonData: false
    }),
    [`${ActionTypes.GET_COMPARISON_DATA}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonData: false
    }),
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      initialData: action.payload,
      nikkeiCodeList: action.payload.nikkeiCodeList,
      selectSegment: action.payload.labelValueDto,
      comparisonConditions: {
        ...state.comparisonConditions,
        indexGroupIdFlg: 0
      }
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.DELETE_ROW}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.DELETE_ROW}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: action.payload,
      allNkCodeList: action.payload.allNkCodeList,
      nikkeiCodeList: action.payload.allNkCodeList,
      comparisonConditions: {
        ...state.comparisonConditions,
        deletedNkCodeList: [] // 共通コンポーネントの兼ね合いで削除したコードが残ってしまうので消す
      },
      isLoadingComparisonData: false
    }),
    [`${ActionTypes.DELETE_ROW}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonData: false
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: {
        ...state.comparisonData,
        tableData: action.payload.tableData
      },
      isLoadingComparisonData: false
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonData: false
    }),
    [`${ActionTypes.CHANGE_LIST}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.CHANGE_LIST}_FULFILLED`]: (state, action) => ({
      ...state,
      nikkeiCodeList: action.payload.nikkeiCodeList,
      isLoadingComparisonData: false
    }),
    [`${ActionTypes.CHANGE_LIST}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingComparisonData: false,
      error: action.payload
    }),
    [ActionTypes.SELECTED_CHANGE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      }
    }),
    [`${ActionTypes.PERIOD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      dlPeriodList: action.payload.periodList,
      dlPeriodFrom: action.payload.periodFrom,
      // レスポンスにToの初期値なし
      dlPeriodTo: action.payload.periodList[0].value
    }),
    [ActionTypes.PERIOD_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),
    [ActionTypes.SEARCH_FLAG_SET]: state => ({
      ...state,
      needsSearch: true
    })
  },
  initialState
);

export const actionCreators = {
  search: createAction(ActionTypes.GET_COMPARISON_DATA, params =>
    request(633, params)
  ),
  // middleware で `PAGE_INIT` を拾うためにアクション名変更
  initSelectSegment: createAction(ActionTypes.PAGE_INIT, params =>
    request(620, params)
  ),
  deleteRow: createAction(
    ActionTypes.DELETE_ROW,
    params => request(634, params),
    (_, codes) => ({ codes })
  ),
  sort: createAction(ActionTypes.SORT_COMPARISON_DATA, params =>
    request(635, params)
  ),
  changeList: createAction(ActionTypes.CHANGE_LIST, params =>
    request(621, params)
  ),
  changeSelected: createAction(ActionTypes.SELECTED_CHANGE),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET),
  excelDownload: createAction(ActionTypes.EXCEL_DOWNLOAD, params =>
    request(636, params)
  ),
  csvDownload: createAction(ActionTypes.CSV_DOWNLOAD, params =>
    request(637, params)
  ),
  getDownloadPeriod: createAction(ActionTypes.PERIOD_GET, params =>
    request(616, params)
  ),
  setDownloadPeriod: createAction(
    ActionTypes.PERIOD_SET,
    period => period,
    (period, key) => ({ key })
  ),
  setSearchFlag: createAction(ActionTypes.SEARCH_FLAG_SET)
};
