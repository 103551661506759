import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  relatedNews: {},
  isLoading: false,
  environmentPeriod: {},
  detailPeriod: {},
  sortOptions: [],
  selectedSort: {},
  error: {}
};

const initPending = (state, action) => {
  const newState = action.meta ? initialState : state;
  return {
    ...newState,
    isLoading: true
  };
};
const initFulfilled = (state, action) => ({
  ...state,
  data: action.payload,
  isLoading: false,
  environmentPeriod: {
    from: action.payload.easeWorkSelectedPublishYearFrom,
    to: action.payload.easeWorkSelectedPublishYearTo
  },
  detailPeriod: {
    from: action.payload.laborSituationSelectedAccFrom,
    to: action.payload.laborSituationSelectedAccTo
  }
});
const initRejected = (state, action) => ({
  ...state,
  error: action.payload,
  isLoading: false
});

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: initPending,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: initFulfilled,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: initRejected,
    [`${ActionTypes.ON_DISPLAY}_PENDING`]: initPending,
    [`${ActionTypes.ON_DISPLAY}_FULFILLED`]: initFulfilled,
    [`${ActionTypes.ON_DISPLAY}_REJECTED`]: initRejected,
    [`${ActionTypes.RELATED_NEWS_GET}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.RELATED_NEWS_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      relatedNews: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.RELATED_NEWS_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.PAGING}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.PAGING}_FULFILLED`]: (state, action) => ({
      ...state,
      relatedNews: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.PAGING}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false
    }),
    [ActionTypes.ENVIRONMENT_PERIOD_CHANGE]: (state, action) => ({
      ...state,
      environmentPeriod: action.payload
    }),
    [ActionTypes.DETAIL_PERIOD_CHANGE]: (state, action) => ({
      ...state,
      detailPeriod: action.payload
    }),
    [ActionTypes.SORT_OPTIONS_SET]: (state, action) => ({
      ...state,
      sortOptions: action.payload,
      selectedSort: action.payload[0]
    }),
    [ActionTypes.SELECTED_SORT_SET]: (state, action) => ({
      ...state,
      selectedSort: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => request(73, params),
    () => true
  ),
  onDisplay: createAction(
    ActionTypes.ON_DISPLAY,
    params => request(73, params),
    () => false
  ),
  getRelatedNews: createAction(ActionTypes.RELATED_NEWS_GET, params =>
    request(78, params)
  ),
  changePage: createAction(ActionTypes.PAGING, params => request(79, params)),
  changeEnvironmentPeriod: createAction(ActionTypes.ENVIRONMENT_PERIOD_CHANGE),
  changeDetailPeriod: createAction(ActionTypes.DETAIL_PERIOD_CHANGE),
  setSortOptions: createAction(ActionTypes.SORT_OPTIONS_SET),
  setSelectedSort: createAction(ActionTypes.SELECTED_SORT_SET)
};
