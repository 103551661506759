import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { persistStore } from "redux-persist";
// Library middleware
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import freeze from "redux-freeze";
// Local middleware
import errorHandler from "../middlewares/error";
import initHandler from "../middlewares/init";
import logServiceHandler from "../middlewares/logService";
import loadingHandler from "../middlewares/loading";
// Reducer
import reducers from "../modules/index";

export default function createStoreWithMiddleware() {
  // 本番環境のみ Redux DevTools を無効化
  const composeEnhancers =
    !IS_DEBUG || !window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25
        });

  const devMiddlewares = [];
  if (IS_DEBUG) {
    if (USE_FREEZE) devMiddlewares.push(freeze);
  }

  const middlewares = [
    thunk,
    initHandler,
    logServiceHandler,
    promiseMiddleware(),
    errorHandler,
    loadingHandler,
    ...devMiddlewares
  ];

  const store = createStore(
    combineReducers({
      reducers
    }),
    composeEnhancers(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);
  return { store, persistor, middlewares };
}
