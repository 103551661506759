exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-Auth-Loading-style__component--1WYos {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-pack: center;\n      justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background: #c5ddeb;\n}\n.src-features-Auth-Loading-style__component--1WYos.src-features-Auth-Loading-style__hide--2gG8s {\n  display: none;\n}\n\n.src-features-Auth-Loading-style__loading--2bDkI {\n  margin: 35px 0;\n  text-align: center;\n  color: #28a3db;\n  font-size: 12px;\n  font-weight: bold;\n}\n\n.src-features-Auth-Loading-style__title--1rz7Y {\n  text-align: center;\n  color: #064889;\n  font-size: 24px;\n  font-weight: bold;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-features-Auth-Loading-style__component--1WYos",
	"hide": "src-features-Auth-Loading-style__hide--2gG8s",
	"loading": "src-features-Auth-Loading-style__loading--2bDkI",
	"title": "src-features-Auth-Loading-style__title--1rz7Y"
};