import { Reducer } from "redux";
import { ForecastAction } from "./actions";
import produce from "immer";

export interface News {
  headline: string;
  printedDate: string;
  mediaName: string;
  keyPdf: string;
  wordCount: string;
  pageFromTo: string;
}

export interface Needs {
  data1q: string;
  data2q: string;
  data3q: string;
  data4q: string;
  dataFy1: string;
  dataFy2: string;
  dataFy3: string;
  dataFy4: string;
  itemLabel: string;
  itemUnit: string;
  itemOyaFlag: string;
  displayOrder: string;
}

interface ForecastState {
  needs: {
    macroNeeds02: {
      datad: string;
      maroNeedsDtoList: Needs[];
      maroNeedsLabel: string[];
    };
  };
  summary: {
    headline: string;
    keyPdf: string;
    body: string;
  };
  news: {
    macroNeedsDtoList: News[];
    startNum: string;
    endNum: string;
    totalCount: string;
    transitionId: string;
  };
}

const initialState: ForecastState = {
  needs: {
    macroNeeds02: {
      datad: "",
      maroNeedsDtoList: [],
      maroNeedsLabel: []
    }
  },
  summary: {
    headline: "",
    keyPdf: "",
    body: ""
  },
  news: {
    macroNeedsDtoList: [],
    startNum: "",
    endNum: "",
    totalCount: "",
    transitionId: ""
  }
};

export const forecast: Reducer<ForecastState, ForecastAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "macro/forecast/SET_NEEDS":
      return produce(state, draft => {
        draft.needs = action.payload.data;
      });
    case "macro/forecast/SET_SUMMARY":
      return produce(state, draft => {
        draft.summary.headline = action.payload.data.headline;
        draft.summary.body = action.payload.data.body.replace(
          /(\r\n|\n|\r)/gm,
          "<br />"
        );
        draft.summary.keyPdf = action.payload.data.keyPdf;
      });
    case "macro/forecast/SET_NEWS":
      return produce(state, draft => {
        draft.news.macroNeedsDtoList = action.payload.data.maroNeedsDtoList;
        draft.news.startNum = action.payload.data.startNum;
        draft.news.endNum = action.payload.data.endNum;
        draft.news.totalCount = action.payload.data.totalCount;
        draft.news.transitionId = action.payload.data.transitionId;
      });
    default:
      return state;
  }
};
