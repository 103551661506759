// 検索
export const SEARCH = "search/SEARCH";
// 検索用Loading画面フラグ
export const IS_SEARCHING = "search/IS_SEARCHING";
// 検索用AbortController
export const SET_SEARCH_ABORTCONTROLLER = "search/SET_SEARCH_ABORTCONTROLLER";
// テレコン情報取得
export const TELECOM_GET = "search/TELECOM_GET";
// サマリ結果セット
export const SUMMARY_SET = "search/SUMMARY_SET";
// 企業検索結果ソート
export const CORP_SORT = "search/CORP_SORT";
// 企業検索並び順・表示件数選択更新
export const UPDATE_CORP_SORT = "search/UPDATE_CORP_SORT";
// 企業検索結果ページ取得
export const CORP_PAGE_GET = "search/CORCORP_PAGE_GETP_SORT";
// 企業検索結果ページ全件選択取得
export const SEARCH_All_GET = "search/SEARCH_All_GET";
// 業界検索結果ページ全件選択取得
export const INDUSTRY_SEARCH_All_GET = "search/INDUSTRY_SEARCH_All_GET";
// 企業検索結果絞り込み
export const CORP_LIST_REQUEST = "search/CORP_LIST_REQUEST";
// 業界検索検索対象切替
export const INDUSTRY_LIST_REQUEST = "search/INDUSTRY_LIST_REQUEST";
// 業界検索結果ページ取得
export const INDUSTRY_PAGE_GET = "search/INDUSTRY_PAGE_GET";
// 開示情報検索結果取得
export const DISCLOSURE_GET = "search/DISCLOSURE_GET";
// 開示情報検索結果ページ取得
export const DISCLOSURE_PAGE_GET = "search/DISCLOSURE_PAGE_GET";
// 開示情報PDFダウンロード取得
export const DISCLOSURE_PDF_DOWNLOAD = "search/DISCLOSURE_PDF_DOWNLOAD";
// 開示情報XBRLダウンロード取得
export const DISCLOSURE_XBRL_DOWNLOAD = "search/DISCLOSURE_XBRL_DOWNLOAD";
// 開示情報ATTACHダウンロード取得
export const DISCLOSURE_ATTACH_DOWNLOAD = "search/DISCLOSURE_ATTACH_DOWNLOAD";
// ニュース検索結果取得
export const NEWS_GET = "search/NEWS_GET";
// ニュース検索結果ページ取得
export const NEWS_PAGE_GET = "search/NEWS_PAGE_GET";
// 業界検索結果取得
export const INDUSTRY_GET = "search/INDUSTRY_GET";
// ニュースPDFダウンロード
export const NEWS_PDF_DOWNLOAD = "search/NEWS_PDF_DOWNLOAD";
export const NEWS_XBRL_DOWNLOAD = "search/NEWS_XBRL_DOWNLOAD";
// 雑誌記事検索結果取得
export const MAGAZINE_GET = "search/MAGAZINE_GET";
// 雑誌記事検索結果ページ取得
export const MAGAZINE_PAGE_GET = "search/MAGAZINE_PAGE_GET";
// 雑誌記事PDFダウンロード
export const MAGAZINE_PDF_DOWNLOAD = "search/MAGAZINE_PDF_DOWNLOAD";
export const MAGAZINE_XBRL_DOWNLOAD = "search/MAGAZINE_XBRL_DOWNLOAD";
export const MAGAZINE_XLS_DOWNLOAD = "search/MAGAZINE_XLS_DOWNLOAD";
// IR情報検索結果取得
export const IR_GET = "search/IR_GET";
// IR情報検索結果一覧取得
export const IR_LIST_GET = "search/IR_LIST_GET";
// 経済統計検索結果取得
export const STATISTICS_GET = "search/STATISTICS_GET";
// 経済統計検索結果一覧取得
export const STATISTICS_LIST_GET = "search/STATISTICS_LIST_GET";

export const SOURCE_ITEMS_SET = "search/SOURCE_ITEMS_SET";
// 検索対象データの初期値取得
export const SEARCHLIST_FILTER_GET = "common/SEARCHLIST_FILTER_GET";
export const INDUSTRY_SEARCHLIST_FILTER_GET =
  "common/INDUSTRY_SEARCHLIST_FILTER_GET";
export const CORP_FILTER_UPDATE = "common/SEARCH_FILTER_UPDATE";
export const INDUSTRY_FILTER_UPDATE = "common/SEARCH_FILTER_INDUSTRY_UPDATE";
export const SEARCHLIST_FILTER_SELECTBOX_UPDATE =
  "common/SEARCHLIST_FILTER_SELECTBOX_UPDATE";
export const CHECKED_CORP_CODES_UPDATE = "common/CHECKED_COMPANY_CODES_UPDATE";
export const CHECKED_INDUSTRY_CODES_UPDATE =
  "common/CHECKED_INDUSTRY_CODES_UPDATE";
export const CHECKED_CORP_CODES_INIT = "common/CHECKED_COMPANY_CODES_INIT";
export const CHECKED_INDUSTRY_CODES_INIT = "common/CHECKED_INDUSTRY_CODES_INIT";
export const CORP_INDUSTRY_TREE_MAP = "search/CORP_INDUSTRY_TREE_MAP";
export const BUBBLE_CHART_ASSOCIATED_ARTICLES_GET =
  "search/BUBBLE_CHART_ASSOCIATED_ARTICLES_GET";
export const TREE_MAP_ASSOCIATED_ARTICLES_GET =
  "search/TREE_MAP_ASSOCIATED_ARTICLES_GET";

export const CORP_RESULT_RESET = "search/CORP_RESULT_RESET";
export const INDUSTRY_RESULT_RESET = "search/INDUSTRY_RESULT_RESET";

// コレクションの検索状態
export const CHECK_SEARCH_COLLECTION = "search/CHECK_SEARCH_COLLECTION";
// コレクションリスト取得
export const COLLECTION_LIST_GET = "search/COLLECTION_LIST_GET";
// コレクションターゲットvalue
export const UPDATE_TARGET_VALUE = "search/UPDATE_TARGET_VALUE";
// コレクションリストソート
export const SORT_COLLECTION = "search/SORT_COLLECTION";
// コレクションリストページ変更
export const GET_COLLECTION_PAGE = "search/GET_COLLECTION_PAGE";
// コレクションフィルター更新
export const UPDATE_COLLECTION_FILTER = "search/UPDATE_COLLECTION_FILTER";

// 官公庁取得
export const GOVERNMENT_GET = "search/GOVERNMENT_GET";
// 官公庁ページ取得
export const GOVERNMENT_PAGE_GET = "search/GOVERNMENT_PAGE_GET";
// タブ情報更新
export const TAB_INFO_SET = "search/TAB_INFO_SET";
// サマリーエラー
export const SET_SUMMARY_ERROR = "search/SET_SUMMARY_ERROR";
// サマリーエラー状態リセット
export const CLEAR_SUMMARY_ERROR = "search/CLEAR_SUMMARY_ERROR";
// 企業検索結果を保存
export const SET_CORP_RESULT = "search/SET_CORP_RESULT";
