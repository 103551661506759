import React, { Component } from "react";

import { isEmpty } from "utils/helper/common";
import { renderBody, renderHeader, renderSub } from "../Common/render";

import "./style.scss";
import commonStyles from "../../Common/style.scss";

class TrendReportPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doPrint: false
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ doPrint: true }), 2000);
  }

  componentDidUpdate() {
    if (this.state.doPrint) {
      window.print();
    }
  }

  render() {
    const {
      title,
      body,
      displayTime,
      mediaName,
      wordCount,
      companyInfo,
      industryInfo
    } = this.props;

    if (isEmpty(title) && isEmpty(body)) return null;

    return (
      <div
        className={commonStyles.component}
        data-testid="Common-Popup-TrendReportPrint"
      >
        <div className={commonStyles.main}>
          {renderHeader(title, displayTime, mediaName, wordCount)}
          <div className={commonStyles.bodyContainer}>{renderBody(body)}</div>
        </div>
        {renderSub({ industryInfo, companyInfo, forPrintUse: true })}
      </div>
    );
  }
}

export default TrendReportPrint;
