var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-BinderPage-Common-SearchModeModal-style__modalSizing--1eJMp {\n  width: 587px;\n  min-width: 400px;\n  height: auto;\n  min-height: 98px;\n}\n\n.src-features-BinderPage-Common-SearchModeModal-style__content--3C283 {\n  padding: 0 24px;\n  margin-top: 24px;\n  font-size: 13px;\n  font-weight: normal;\n  line-height: 1.6;\n}\n\n.src-features-BinderPage-Common-SearchModeModal-style__copyInput--3u9LG {\n  margin-top: 24px;\n}\n\n.src-features-BinderPage-Common-SearchModeModal-style__buttonWrapper--16pRP {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: space-evenly;\n      justify-content: space-evenly;\n  margin: 24px 0;\n}\n.src-features-BinderPage-Common-SearchModeModal-style__buttonWrapper--16pRP > button {\n  width: 250px;\n  height: 40px;\n}\n\n.src-features-BinderPage-Common-SearchModeModal-style__closeButton--msimF {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: start;\n      align-items: flex-start;\n  -ms-flex-pack: end;\n      justify-content: flex-end;\n  width: 100%;\n  height: 24px;\n}\n.src-features-BinderPage-Common-SearchModeModal-style__closeButton--msimF button {\n  cursor: pointer;\n  color: #fff;\n  border: none;\n  background-color: transparent;\n  font-size: 13px;\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n}\n.src-features-BinderPage-Common-SearchModeModal-style__closeButton--msimF .src-features-BinderPage-Common-SearchModeModal-style__icon--1ElBV {\n  width: 14px;\n  height: 14px;\n  cursor: pointer;\n  background: url(" + escape(require("images/icon/close.svg")) + ") no-repeat center;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"modalSizing": "src-features-BinderPage-Common-SearchModeModal-style__modalSizing--1eJMp",
	"content": "src-features-BinderPage-Common-SearchModeModal-style__content--3C283",
	"copyInput": "src-features-BinderPage-Common-SearchModeModal-style__copyInput--3u9LG",
	"buttonWrapper": "src-features-BinderPage-Common-SearchModeModal-style__buttonWrapper--16pRP",
	"closeButton": "src-features-BinderPage-Common-SearchModeModal-style__closeButton--msimF",
	"icon": "src-features-BinderPage-Common-SearchModeModal-style__icon--1ElBV"
};