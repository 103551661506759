import React from "react";
import styles from "./style.scss";
import Heading from "components/Common/Heading";
import ExportButton from "components/Common/ExportButton";
import { isEmpty } from "utils/helper/common";
import TextButton from "components/Common/Button/Text";
import { trackAction } from "utils/helper/analytics";

/**
 * タイトル
 *
 * @prop {?string} sub - 右側の日付などの表示
 * @prop {?string} notice - 注意書きの表示
 * @prop {?string} pathname - 画面URL
 * @prop {array} files - ファイルダウンロード情報
 * @prop {?string} dlpropriety - DL可否 "9"ならDLボタン非活性
 * files = [
 *   { type: "xls", params: {}, key: "key1" },
 *   { type: "csv", params: {}, key: "key2" }
 * ]
 * @prop {?object} anchor - ページ内リンク情報
 * @prop {?function} handleClick - ダウンロードボタン押下時のcallback
 */
const SectionTitle = ({
  children,
  sub = null,
  xlsName = null,
  csvName = null,
  files = [],
  anchor = null,
  handleClick = null,
  dlpropriety = "",
  notice = null,
  tabTitle = false,
  className = null,
  resourceId = "",
  buttonText = "",
  buttonTextOnClick = () => {},
  onDownload = null,
  isSendAtlas = false,
  sendAtlasTitle = ""
}) => {
  // TODO: すべての呼び出し側のxlsName, csvNameを消したら下記を削除(ボタン表示のために残し中)
  const hasExportButton = xlsName !== null || csvName !== null;
  const exportFiles = (() => {
    if (!isEmpty(files)) {
      return files;
    }
    if (hasExportButton) {
      return [
        { type: "xls", params: { dummy: "" }, key: "" },
        { type: "csv", params: { dummy: "" }, key: "" }
      ];
    }
    return [];
  })();

  const headingText = children ? children : anchor != null ? anchor.name : "";
  const headingId = anchor != null ? anchor.anchor : "";

  const clickEsgDownloadButton = type => {
    trackAction("clickEsgDownloadButton", {
      title: sendAtlasTitle,
      type: type.toUpperCase()
    });
  };

  return (
    <div
      className={`${styles.title} ${tabTitle && styles.tabTitle} ${className}`}
      data-testid="Common-SectionTitle-title"
    >
      <Heading lavel="3" id={headingId}>
        {headingText}
      </Heading>
      <div className={styles.right} data-testid="other_text">
        {sub && (
          <span
            className={styles.sub}
            data-testid="Common-SectionTitle-sub_text"
          >
            {sub}
          </span>
        )}
        {notice && <span className={styles.notice}>{notice}</span>}
        {!isEmpty(buttonText) ? (
          <div className={styles.textButtonWrapper}>
            <TextButton data-testid="button_text" onClick={buttonTextOnClick}>
              {buttonText}
            </TextButton>
          </div>
        ) : (
          <ExportButton
            files={exportFiles}
            dlpropriety={dlpropriety}
            handleClick={handleClick}
            resourceId={resourceId}
            onClick={onDownload}
            isSendAtlas={isSendAtlas}
            sendAtlas={isSendAtlas ? clickEsgDownloadButton : () => {}}
          />
        )}
      </div>
    </div>
  );
};

export default SectionTitle;
