// 初期表示
export const PAGE_INIT = "corp/valuation/dcf/PAGE_INIT";
// 入力値チェック（STEP1ユーザー指定時）
export const CHECK_VALUE = "corp/valuation/dcf/CHECK_VALUE";
// 選択値を表示内容に反映（セレクトボックス変更時・ユーザー指定時）
export const UPDATE_PAGE = "corp/valuation/dcf/UPDATE_PAGE";
// リストボックス再設定
export const LIST_BOX_CHANGE = "corp/valuation/dcf/LIST_BOX_CHANGE";

// お気に入り保存数のチェック及びお気に入り名称の取得（条件を保存ボタン押下時(モーダルopen時)）
// 294 /corp/valuation/dcf/check-favolite
export const CHECK_FABORITE = "corp/valuation/dcf/CHECK_FABORITE";
// 選択したお気に入りに登録されている条件を取得(登録ずみ条件をセレクトボックスから選択時)
// 297 /corp/valuation/dcf/dcf-favolite
export const LOAD_FAVORITE = "corp/valuation/dcf/LOAD_FAVORITE";
// 選択したお気に入り条件を削除し、削除後のお気に入り条件一覧を取得(名称変更モーダルで削除リンク押下時)
// 298 /corp/valuation/dcf/delete-favolite
export const DELETE_FAVORITE = "corp/valuation/dcf/DELETE_FAVORITE";
// 画面の条件をお気に入りに保存(条件保存モーダルで保存ボタン押下時)
// 295 /corp/valuation/dcf/save-favolite
export const SAVE_FAVORITE = "corp/valuation/dcf/SAVE_FAVORITE";
// お気に入り条件名を変更(名称変更モーダルで保存ボタン押下時)
// 299 /corp/valuation/dcf/update-favolite
export const UPDATE_FAVORITE = "corp/valuation/dcf/UPDATE_FAVORITE";
// お気に入りに保存した条件一覧を取得(名称変更削除モーダル初期表示時)
// 296 /corp/valuation/dcf/user-favolite-list
export const LOAD_FAVORITE_LIST = "corp/valuation/dcf/LOAD_FAVORITE_LIST";

// 売上高選択ポップアップ計算
// 288 /corp/valuation/dcf/calculate-dcf
export const SALES_POPUP_CALCULATE = "corp/valuation/dcf/SALES_POPUP_CALCULATE";
// 売上高選択ポップアップ計算値クリア
export const SALES_POPUP_CLEAR = "corp/valuation/dcf/SALES_POPUP_CLEAR";
// 売上高選択ポップアップ値変更
export const SALES_POPUP_INPUT = "corp/valuation/dcf/SALES_POPUP_INPUT";

// 選択中の条件変更
export const SELECTED_CONDITION_CHANGE =
  "corp/valuation/dcf/SELECTED_CONDITION_CHANGE";
// ユーザ指定の選択肢追加
export const USER_OPTION_ADD = "corp/valuation/dcf/USER_OPTION_ADD";
// 表示条件保存
export const SAVE_CONDITONS = "corp/valuation/dcf/SAVE_CONDITONS";
// 登録した条件の選択値更新
export const FAVORITE_SELECTED_UPDATE =
  "corp/valuation/dcf/FAVORITE_SELECTED_UPDATE";

// ダウンロードパス生成
// 285 /corp/valuation/dcf/excel-dcf-download
export const DOWNLOAD_PATH_BUILD = "corp/valuation/dcf/DOWNLOAD_PATH_BUILD";

export const TEMP_LAST_SALES_POPUP_SET =
  "corp/valuation/dcf/TEMP_LAST_SALES_POPUP_SET";

export const RESET_USER_SELECTED_VALUES =
  "corp/valuation/dcf/RESET_USER_SELECTED_VALUES";

export const CALCULATE_VALUE_CHECK = "corp/valuation/dcf/CALCULATE_VALUE_CHECK";

export const UPDATE_STATUS = "corp/valuation/dcf/UPDATE_STATUS";
// 表示期間の更新
export const SELECTED_CONDITIONS_UPDATE =
  "corp/valuation/dcf/SELECTED_CONDITIONS_UPDATE";
