exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-Modal-PaidConfirm-style__modal--19cfr {\n  max-width: 432px;\n  min-width: inherit;\n  height: auto;\n  max-height: 100%;\n}\n.src-features-Modal-PaidConfirm-style__modal--19cfr .src-features-Modal-PaidConfirm-style__container--1DF2o {\n  margin: 16px 16px 24px;\n}\n.src-features-Modal-PaidConfirm-style__modal--19cfr .src-features-Modal-PaidConfirm-style__label--2Ia7q {\n  font-weight: bold;\n  margin: 16px 0 8px;\n}\n.src-features-Modal-PaidConfirm-style__modal--19cfr .src-features-Modal-PaidConfirm-style__fee--1e7I0 {\n  color: #ce3939;\n}\n.src-features-Modal-PaidConfirm-style__modal--19cfr .src-features-Modal-PaidConfirm-style__buttonContainer--18xoP {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  margin-top: 40px;\n}\n.src-features-Modal-PaidConfirm-style__modal--19cfr .src-features-Modal-PaidConfirm-style__buttonContainer--18xoP .src-features-Modal-PaidConfirm-style__button--3zDLr {\n  width: 148px;\n  height: 40px;\n}\n.src-features-Modal-PaidConfirm-style__modal--19cfr .src-features-Modal-PaidConfirm-style__buttonContainer--18xoP .src-features-Modal-PaidConfirm-style__button--3zDLr:last-child {\n  margin-left: 16px;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"modal": "src-features-Modal-PaidConfirm-style__modal--19cfr",
	"container": "src-features-Modal-PaidConfirm-style__container--1DF2o",
	"label": "src-features-Modal-PaidConfirm-style__label--2Ia7q",
	"fee": "src-features-Modal-PaidConfirm-style__fee--1e7I0",
	"buttonContainer": "src-features-Modal-PaidConfirm-style__buttonContainer--18xoP",
	"button": "src-features-Modal-PaidConfirm-style__button--3zDLr"
};