import styles from "./style.scss";
import cn from "classnames";

export const isExternalUrl = (urlText: string) => {
  try {
    return new URL(urlText).origin !== location.origin;
  } catch {
    return false;
  }
};

export const replaceUrl = (text: string) => {
  if (!text) return "";
  return text.replace(
    /\[(.*?)\]\((.*?)\)/g,
    (_, linkText, urlText) =>
      `<a href="${urlText}" class="${cn(styles.link, {
        [styles.external]: isExternalUrl(urlText)
      })}" target="_blank" rel="noopener noreferrer">${linkText}</a>`
  );
};

export const replaceHighlight = (text: string, highlight: string[]) => {
  if (!text) return "";
  const regexp = new RegExp("(" + highlight.join("|") + ")", "g");
  return text.replace(
    regexp,
    (_match, p1, offset) =>
      `<span class="${cn(styles.highlight)}" key="${offset}" >${p1}</span>`
  );
};
