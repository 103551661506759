/**
 * レポート作成
 */
// 初期化
export const REPORT_CREATE_INIT = "common/ReportCreator/REPORT_CREATE_INIT";
export const ENTITY_LIST_GET = "common/ReportCreator/ENTITY_LIST_GET";
// 表示・非表示
export const REPORT_CREATE_OPEN = "common/ReportCreator/REPORT_CREATE_OPEN";
export const REPORT_CREATE_CLOSE = "common/ReportCreator/REPORT_CREATE_CLOSE";
// 選択中のレポートタイプ
export const REPORT_TYPE_SET = "common/ReportCreator/REPORT_TYPE_SET";
// 業界分類取得
export const NEEDS_MST_L_GET = "common/ReportCreator/NEEDS_MST_L_GET";
export const NEEDS_MST_M_GET = "common/ReportCreator/NEEDS_MST_M_GET";
export const NEEDS_MST_S_GET = "common/ReportCreator/NEEDS_MST_S_GET";
// 企業検索・再検索
export const COMPANY_GET = "common/ReportCreator/COMPANY_GET";
export const COMPANY_BY_SUGGEST_GET =
  "common/ReportCreator/COMPANY_BY_SUGGEST_GET";
export const LATESTVIEW_COMPANY_GET =
  "common/ReportCreator/LATESTVIEW_COMPANY_GET";
export const BOOKMARK_COMPANY_GET = "common/ReportCreator/BOOKMARK_COMPANY_GET";
export const SELECTED_BOOKMARK_FOLDER_GET =
  "common/ReportCreator/SELECTED_BOOKMARK_FOLDER_GET";
export const COMPANY_LIST_RESEARCH =
  "common/ReportCreator/COMPANY_LIST_RESEARCH";
// 企業選択・追加・削除
export const SELECTED_COMPANIES = "common/ReportCreator/SELECTED_COMPANYS";
export const SELECTED_COMPANIES_ADD =
  "common/ReportCreator/SELECTED_COMPANYS_ADD";
export const SELECTED_COMPANY_REMOVE =
  "common/ReportCreator/SELECTED_COMPANY_REMOVE";
export const SELECTED_COMPANY_CLEAR =
  "common/ReportCreator/SELECTED_COMPANY_CLEAR";
// コンテンツの選択変更
export const CHECKED_CONTENTS_SET = "common/ReportCreator/CHECKED_CONTENTS_SET";
// 業界レポート作成・DL
export const INDUSTRY_REPORT_CREATE =
  "common/ReportCreator/INDUSTRY_REPORT_CREATE";
export const INDUSTRY_REPORT_DOWNLOAD =
  "common/ReportCreator/INDUSTRY_REPORT_DOWNLOAD";
// コンテンツ変更
export const PPT_CONTENTS_CHANGE = "common/ReportCreator/PPT_CONTENTS_CHANGE";
export const WORD_CONTENTS_CHANGE = "common/ReportCreator/WORD_CONTENTS_CHANGE";
export const CONTENTS_UPDATE = "common/ReportCreator/CONTENTS_UPDATE";
// コンテンツ保存・読み込みAPI
export const CONTENTS_SAVE = "common/ReportCreator/CONTENTS_SAVE";
// コンテンツの選択情報設定をstoreに反映
export const CONDITIONS_UPDATE = "common/ReportCreator/CONDITIONS_UPDATE";
// 日付文字列保存
export const DATE_SAVE = "common/ReportCreator/DATE_SAVE";
// レポート作成・DL
export const PPT_REPORT_CREATE = "common/ReportCreator/PPT_REPORT_CREATE";
export const WORD_REPORT_CREATE = "common/ReportCreator/WORD_REPORT_CREATE";
export const REPORT_DOWNLOAD = "common/ReportCreator/REPORT_DOWNLOAD";
// 上場・海外等のチェックボックス項目置き換え
export const ITEMS_SAVE = "common/ReportCreator/ITEMS_SAVE";
// 通貨チェックボックス更新
export const CURRENCY_SAVE = "common/ReportCreator/CURRENCY_SAVE";
// 人事情報取得
export const PERSONNEL_GET = "common/ReportCreator/PERSONNEL_GET";
export const PERSONNEL_SEARCH = "common/ReportCreator/PERSONNEL_SEARCH";
export const PERSONNEL_PAGING = "common/ReportCreator/PERSONNEL_PAGING";
export const PERSONS_SET = "common/ReportCreator/PERSONS_SET";
export const PAGE_SET = "common/ReportCreator/PAGE_SET";
export const TEMP_PERSONS_SET = "common/ReportCreator/TEMP_PERSONS_SET";
// レポート作成領域を閉じる
export const REPORT_CLOSE = "common/ReportCreator/REPORT_CLOSE";
export const REPORT_OPEN = "common/ReportCreator/REPORT_OPEN";
