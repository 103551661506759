// 初期表示
export const PAGE_INIT = "corp/shares/shareholder/PAGE_INIT";
// 大量保有報告書情報の添付資料ダウンロード
export const LARGE_HOLDING_ATTACH_DL =
  "corp/shares/shareholder/LARGE_HOLDING_ATTACH_DL";
// 「大量保有報告書」データ初期取得
export const LARGE_HOLDING_INIT = "corp/shares/shareholder/LARGE_HOLDING_INIT";
// 「大量保有報告書」データ取得（ページャ使用時）
export const LARGE_HOLDING_GET = "corp/shares/shareholder/LARGE_HOLDING_GET";
// 期間を設定して再表示
export const PAGE_SEARCH = "corp/shares/shareholder/PAGE_SEARCH";
