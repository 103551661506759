import React, { useState, useEffect } from "react";
import { useToast } from "hooks/useToast";
import { getPdfParams } from "utils/helper/localStorage";
import { getDownloadUrl } from "utils/helper/download";
import { isEmpty, valueFromKeyOrDefault } from "utils/helper/common";
import Viewer from "./viewer";
import { Helmet } from "react-helmet";

const PdfjsViewer: React.FunctionComponent = () => {
  const [error, setError] = useState<string>("");
  const [url, setUrl] = useState<string>();
  const [keywords, setKeywords] = useState<string[]>([]);

  const toast = useToast();

  const getUrl = async () => {
    const { urlNumber, params, keywords } = getPdfParams();
    setKeywords(keywords);

    try {
      const tmpUrl = await getDownloadUrl(urlNumber, params, null);
      setUrl(tmpUrl);
    } catch (e) {
      if (e.message === "UNAUTHORIZED") {
        location.href = "/login";
      } else {
        const message = valueFromKeyOrDefault(
          e.body,
          "details.0.message",
          ""
        ) as string;
        toast.setErrors(message);
        setError(message);
      }
    }
  };

  useEffect(() => {
    getUrl();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isEmpty(error) || !url) return null;

  return (
    <>
      <Helmet>
        <title>日経バリューサーチ</title>
      </Helmet>
      <Viewer src={url} keywords={keywords} />
    </>
  );
};

export default PdfjsViewer;
