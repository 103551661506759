import React from "react";
import styles from "./style.scss";
import TextButton from "components/Common/Button/Text";
import Checkbox from "components/Common/Checkbox/index";
import cn from "classnames";
import vsLogo from "images/logo/vs.svg";
import { useAuthOption } from "./hook";
import { useReduxState } from "hooks/useReduxState";
import { MobileFooter } from "features/Login/MobileFooter";
import { trackAction } from "utils/helper/analytics";

export const Option: React.FunctionComponent = () => {
  const {
    checkedIds,
    options,
    error,
    handleChangeCheck,
    handleSubmit
  } = useAuthOption();

  const redirectButton = "日経バリューサーチを利用する";

  const invalidFlg = options.every(item => item.remaining === "0");

  const isMobileSize = useReduxState(state => state.global.isMobileSize);

  if (isMobileSize) {
    // Atlas クリックイベント
    const lpUrl = "http://nvs.nikkei.co.jp/";
    const onClickLpLink = () => {
      trackAction("login", {
        target: "日経VS-LP",
        destination: lpUrl
      });
    };

    return (
      <div className={styles.mobileComponent}>
        <div className={styles.mobileLogo}>
          <img src={vsLogo} />
        </div>
        <form onSubmit={handleSubmit}>
          <React.Fragment>
            <div className={cn(styles.body, styles.mobileBody)}>
              <div>
                <div className={styles.title}>オプションの選択</div>
                <div className={styles.description}>
                  <div>
                    ご利用可能なオプションがあります。リストから選択してください。
                  </div>
                </div>
                <div className={cn(styles.content, styles.mobileContent)}>
                  <div className={styles.table}>
                    <table className={styles.vsTable}>
                      <thead>
                        <tr>
                          <th />
                          <th className={styles.left}>オプション名</th>
                        </tr>
                      </thead>
                      <tbody>
                        {options.map((data, index) => {
                          const isChecked = checkedIds.includes(data.productId);
                          const isActive = data.remaining !== "0";
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                if (!isActive) return;
                                handleChangeCheck(!isChecked, data.productId);
                              }}
                              className={cn({ [styles.disable]: !isActive })}
                            >
                              <td>
                                <div
                                  className={`${styles.checkCell} ${styles.center}`}
                                >
                                  <Checkbox
                                    className={styles.checkboxSub}
                                    isActive={isActive}
                                    isChecked={isChecked}
                                    isSmall={true}
                                  />
                                </div>
                              </td>
                              <td
                                className={`
                                    ${styles.left}
                                    ${data.remaining === "0" && styles.gray}
                                  `}
                              >
                                {data.optionName}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {invalidFlg && (
                <div className={styles.errorMsg}>
                  <div>
                    オプションの利用者数が上限に達しているため、ご利用できません。
                  </div>
                </div>
              )}
              {error && <div className={styles.errorMsg}>{error}</div>}
              <TextButton type="submit" className={styles.button}>
                {redirectButton}
              </TextButton>
            </div>
            <div className={styles.mobileFoot}>
              <p>
                日経バリューサーチは、企業・業界分析業務に必要な全てを備える情報プラットフォームです。
              </p>
              <a
                href={lpUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClickLpLink}
              >
                <TextButton>詳しくはこちら</TextButton>
              </a>
            </div>
            <MobileFooter />
          </React.Fragment>
        </form>
      </div>
    );
  }

  return (
    <div className={styles.component}>
      <div className={styles.logo}>
        <img src={vsLogo} />
      </div>
      <form onSubmit={handleSubmit}>
        <React.Fragment>
          <div className={styles.body}>
            <div>
              <div className={styles.title}>オプションの選択</div>
              <div className={styles.description}>
                <div>
                  ご利用可能なオプションがあります。リストから選択してください。
                </div>
              </div>
              <div className={cn(styles.content, styles.pcContent)}>
                <div className={styles.table}>
                  <table className={styles.vsTable}>
                    <thead>
                      <tr>
                        <th />
                        <th className={styles.left}>オプション名</th>
                      </tr>
                    </thead>
                    <tbody>
                      {options.map((data, index) => {
                        const isChecked = checkedIds.includes(data.productId);
                        const isActive = data.remaining !== "0";
                        return (
                          <tr
                            key={index}
                            onClick={() => {
                              if (!isActive) return;
                              handleChangeCheck(!isChecked, data.productId);
                            }}
                            className={cn({ [styles.disable]: !isActive })}
                          >
                            <td>
                              <div
                                className={`${styles.checkCell} ${styles.center}`}
                              >
                                <Checkbox
                                  className={styles.checkboxSub}
                                  isActive={isActive}
                                  isChecked={isChecked}
                                  isSmall={true}
                                />
                              </div>
                            </td>
                            <td
                              className={`
                                  ${styles.left}
                                  ${data.remaining === "0" && styles.gray}
                                `}
                            >
                              {data.optionName}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {invalidFlg && (
              <div className={styles.errorMsg}>
                <div>
                  オプションの利用者数が上限に達しているため、ご利用できません。
                </div>
              </div>
            )}
            {error && <div className={styles.errorMsg}>{error}</div>}
            <TextButton type="submit" className={styles.button}>
              {redirectButton}
            </TextButton>
          </div>
        </React.Fragment>
      </form>
    </div>
  );
};
