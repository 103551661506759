import { Reducer } from "redux";
import { QuickMenuAction } from "modules/quickMenu/actions";
import { CommonNavigationQuickMenuApiResponse } from "../../features/QuickMenuPanel/api/common_navigation_quick-menu/CommonNavigationQuickMenuApiResponse";

export type QuickMenuState = null | CommonNavigationQuickMenuApiResponse;

const initialState: QuickMenuState = null;

export const quickMenu: Reducer<QuickMenuState, QuickMenuAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "quickMenu/SET_QUICK_MENU":
      return action.payload.quickMenu;
    default:
      return state;
  }
};
