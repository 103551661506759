import React, { useCallback, useMemo } from "react";
import styles from "./style.scss";
import { isEmpty } from "lodash";
import { useModalUtil } from "hooks/useModalUtil";

/**
 * 文字入力
 * @prop {?string} className - クラス名
 * @prop {?string} type - input type
 * @prop {?string|boolean} error - string: エラー文言表示 true: 赤枠のみ表示
 * @prop {?boolean} isTopSearch - 一括検索用の表示
 */

interface Props extends React.HTMLProps<HTMLInputElement> {
  isTopSearch?: boolean;
  className?: string;
  error?: string | null | boolean;
  type?: string;
  isNoShadow?: boolean;
  entireText?: boolean;
}

const TextInput: React.FunctionComponent<Props> = ({
  isTopSearch = false,
  error = "",
  type = "text",
  isNoShadow = false,
  className,
  onCompositionEnd = () => {},
  onChange = () => {},
  entireText = false,
  ...otherProps
}: Props) => {
  // エラーのスタイルを適用するか
  const isErrorStyle = useMemo(
    () => (typeof error === "boolean" ? error : !isEmpty(error)),
    [error]
  );

  // エラーメッセージを表示するか
  const hasErrorMessage = useMemo(
    () => (typeof error === "boolean" ? false : !isEmpty(error)),
    [error]
  );

  const { removeKeyEventListener, addKeyEventListener } = useModalUtil();

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (!entireText) {
        // 文字列先頭の半角スペース・全角スペース入力を弾く
        if (event.currentTarget.value.match(/^[\x20\u3000]/)) return;
      }
      onChange(event);
    },
    [entireText, onChange]
  );

  const handleCompositionEnd = useCallback(
    (event: React.CompositionEvent<HTMLInputElement>) => {
      onCompositionEnd(event);
      handleChange(event);
    },
    [handleChange, onCompositionEnd]
  );

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    // テキストフィールドフォーカス中はモーダル閉じるキーイベントを解除
    removeKeyEventListener();
    otherProps.onFocus && otherProps.onFocus(event);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // モーダル閉じるキーイベントを貼り直す
    addKeyEventListener();
    otherProps.onBlur && otherProps.onBlur(event);
  };

  return (
    <>
      <input
        {...otherProps}
        type={type}
        onCompositionEnd={handleCompositionEnd}
        onChange={handleChange}
        className={`
            ${className}
            ${styles.textInput}
            ${isTopSearch && styles.topSearch}
            ${isErrorStyle && styles.error}
            ${(isTopSearch || isNoShadow) && styles.isNoShadow}
          `}
        onFocus={onFocus}
        onBlur={onBlur}
        data-testid="text-input"
      />
      {hasErrorMessage && (
        <div
          className={styles.errorMessage}
          data-testid="Common-TextInput-Error"
        >
          {error}
        </div>
      )}
    </>
  );
};

export default TextInput;
