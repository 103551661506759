// 初期表示
export const PAGE_INIT = "corp/shares/holdings/PAGE_INIT";
// 表示期間変更
export const PAGE_GET = "corp/shares/holdings/PAGE_GET";
// 株主一覧取得
export const SHAREHOLDERS_GET = "corp/shares/holdings/SHAREHOLDERS_GET";
// 株式の保有状況選択
export const SELECTED_CROSSHELD_SET =
  "corp/shares/holdings/SELECTED_CROSSHELD_SET";
export const SELECTED_VOTING_SET = "corp/shares/holdings/SELECTED_VOTING_SET";
