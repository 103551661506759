// 表データ取得
export const GET_COMPARISON_DATA =
  "corp/companison/segment/GET_COMPARISON_DATA";
export const SEARCH_FLAG_SET = "corp/companison/segment/SEARCH_FLAG_SET";
// セレクトボックス初期表示(middleware で `PAGE_INIT` を拾うためにアクション名変更)
export const PAGE_INIT = "corp/comparison/segment/PAGE_INIT";
// セグメントセレクトボックス変更時更新
export const CHANGE_SEGMENT = "corp/companison/segment/CHANGE_SEGMENT";
// 選択行削除
export const DELETE_ROW = "corp/comparison/segment/DELETE_ROW";
// テーブルソート
export const SORT_COMPARISON_DATA =
  "corp/comparison/segment/SORT_COMPARISON_DATA";
// 表示内容変更
export const CHANGE_LIST = "corp/comparison/segment/CHANGE_LIST";
// 条件選択状態の変更
export const SELECTED_CHANGE = "corp/comparison/segment/SELECTED_CHANGE";
// ソート状態を解除
export const SORT_INFO_UNSET = "corp/comparison/segment/SORT_INFO_UNSET";

// ダウンロード
export const EXCEL_DOWNLOAD = "corp/comparison/segment/EXCEL_DOWNLOAD";
export const CSV_DOWNLOAD = "corp/comparison/segment/CSV_DOWNLOAD";
export const PERIOD_GET = "corp/comparison/segment/PERIOD_GET";
export const PERIOD_SET = "corp/comparison/segment/PERIOD_SET";
