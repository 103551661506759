import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  eventData: {},
  eventTypeCodeList: [],
  eventTypeCode: "",
  fromDate: "",
  toDate: "",
  defaultEventTypeCode: "",
  shouldInitDate: false,
  error: null,
  isLoading: {
    condition: false,
    result: false
  }
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      fromDate: action.payload.fromDate,
      toDate: action.payload.toDate,
      shouldInitDate: true
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.EVENT_DATA_GET}_PENDING`]: state => ({
      ...state,
      eventData: initialState.eventData,
      isLoading: {
        ...state.isLoading,
        result: true
      }
    }),
    [`${ActionTypes.EVENT_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      eventData: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.EVENT_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.EVENT_DATA_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      eventData: action.payload
    }),
    [`${ActionTypes.EVENT_DATA_PAGE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.EVENT_TYPE_CODE_LIST_SET]: (state, action) => ({
      ...state,
      eventTypeCodeList: action.payload,
      eventTypeCode: state.eventTypeCode || action.payload[0].value,
      defaultEventTypeCode: action.payload[0].value
    }),
    [ActionTypes.EVENT_TYPE_CODE_SET]: (state, action) => ({
      ...state,
      eventTypeCode: action.payload
    }),
    [ActionTypes.PERIOD_SET]: (state, action) => ({
      ...state,
      [action.payload.key]: action.payload.value,
      shouldInitDate: false
    }),
    [ActionTypes.SHOULD_INIT_DATE_SET]: (state, action) => ({
      ...state,
      shouldInitDate: action.payload
    }),
    [ActionTypes.CONDITION_LOADING_UPDATE]: (state, action) => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        condition: action.payload
      }
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(520, params)),
  getEventData: createAction(ActionTypes.EVENT_DATA_GET, params =>
    request(521, params)
  ),
  getEventDataPage: createAction(ActionTypes.EVENT_DATA_PAGE_GET, params =>
    request(844, params)
  ),
  setEventTypeCodeList: createAction(ActionTypes.EVENT_TYPE_CODE_LIST_SET),
  setEventTypeCode: createAction(ActionTypes.EVENT_TYPE_CODE_SET),
  setPeriod: createAction(ActionTypes.PERIOD_SET),
  setShouldInitDate: createAction(ActionTypes.SHOULD_INIT_DATE_SET),
  updateConditionLoading: createAction(ActionTypes.CONDITION_LOADING_UPDATE)
};
