import React from "react";
import styles from "./style.scss";
import NikkeiImage from "images/logo/nikkei.svg";
import PopupLink from "components/Common/Popup/Link/index";

export const MobileFooter: React.FunctionComponent = () => (
  <footer className={styles.component}>
    <div className={styles.logoWrapper}>
      <PopupLink pathname="http://www.nikkei.co.jp/">
        <img src={NikkeiImage} alt="NIKKEI" />
      </PopupLink>
    </div>
    <div className={styles.linkWrapper}>
      <PopupLink pathname="http://www.nikkei.co.jp/nikkeiinfo">
        会社情報・お知らせ
      </PopupLink>
      <PopupLink pathname="https://www.nikkei.co.jp/digitalmedia/privacy.html">
        個人情報
      </PopupLink>
    </div>
    <div className={styles.description}>
      本サービスに関する知的所有権その他一切の権利は日本経済新聞社またはその情報提供者に帰属します。
      <br />
      また本サービスは方法の如何、有償無償を問わず契約者以外の第三者に利用させることはできません。
    </div>
    <div className={styles.note}>
      Nikkei Inc. No reproduction without permission.
    </div>
  </footer>
);
