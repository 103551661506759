import { BindersAction } from "./actions";
import { BinderItem, BinderActionSet } from "models/BinderItem";
import { Reducer } from "redux";
import produce from "immer";

export interface BindersState {
  openFlg: boolean;
  personal: BinderItem[];
  nikkei: BinderItem[];
  binderAction: BinderActionSet | null;
  isLoading: boolean;
  loadingCount: number;
  personalTotalCount: number;
  nikkeiTotalCount: number;
  isAdding: boolean;
  nikkeiOffset: number;
  nikkeiLimit: number;
  nikkeiSortValue: string;
  personalOffset: number;
  personalLimit: number;
  personalSortValue: string;
}

const initialState: BindersState = {
  openFlg: false,
  personal: [],
  nikkei: [],
  binderAction: null,
  isLoading: false,
  loadingCount: 0,
  personalTotalCount: 0,
  nikkeiTotalCount: 0,
  isAdding: false,
  nikkeiOffset: 0,
  nikkeiLimit: 10,
  nikkeiSortValue: "1",
  personalOffset: 0,
  personalLimit: 10,
  personalSortValue: "1"
};

export const bindersList: Reducer<BindersState, BindersAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "binders/list/SET_PERSONAL_BINDER_ITEMS":
      return produce(state, draft => {
        draft.personal = action.payload.binderItems;
      });
    case "binders/list/ADD_PERSONAL_BINDER_ITEMS":
      return produce(state, draft => {
        draft.personal = draft.personal.concat(action.payload.binderItems);
      });
    case "binders/list/ADD_NIKKEI_BINDER_ITEMS":
      return produce(state, draft => {
        draft.nikkei = draft.nikkei.concat(action.payload.binderItems);
      });
    case "binders/list/REMOVE_PERSONAL_BINDER_ITEMS":
      return produce(state, draft => {
        draft.personal = [];
      });
    case "binders/list/SET_NIKKEI_BINDER_ITEMS":
      return produce(state, draft => {
        draft.nikkei = action.payload.binderItems;
      });
    case "binders/list/SET_BINDER_ACTION_VALUE":
      return produce(state, draft => {
        draft.binderAction = action.payload.binderActionValue;
      });
    case "binders/list/RESET_BINDER_ACTION_VALUE":
      return produce(state, draft => {
        draft.binderAction = initialState.binderAction;
      });
    case "binders/list/SET_BINDER_OPEN_FLG_VALUE":
      return produce(state, draft => {
        draft.openFlg = action.payload.isBinderOpenFlg;
      });
    case "binders/list/CHANGE_ISLOADING":
      return produce(state, draft => {
        draft.loadingCount = action.payload.isLoadingFlg
          ? state.loadingCount + 1
          : state.loadingCount - 1;

        draft.isLoading = draft.loadingCount >= 1;
      });
    case "binders/list/SET_PERSONAL_TOTAL_COUNT":
      return produce(state, draft => {
        draft.personalTotalCount = action.payload.totalCount;
      });
    case "binders/list/SET_NIKKEI_TOTAL_COUNT":
      return produce(state, draft => {
        draft.nikkeiTotalCount = action.payload.totalCount;
      });
    case "binders/list/CHANGE_ISADDING":
      return produce(state, draft => {
        draft.isAdding = action.payload.isAdding;
      });
    case "binders/list/SET_NIKKEI_OFFSET":
      return produce(state, draft => {
        draft.nikkeiOffset = action.payload.nikkeiOffset;
      });
    case "binders/list/SET_NIKKEI_LIMIT":
      return produce(state, draft => {
        draft.nikkeiLimit = action.payload.nikkeiLimit;
      });
    case "binders/list/SET_NIKKEI_SORT_VALUE":
      return produce(state, draft => {
        draft.nikkeiSortValue = action.payload.nikkeiSortValue;
      });
    case "binders/list/SET_PERSONAL_OFFSET":
      return produce(state, draft => {
        draft.personalOffset = action.payload.personalOffset;
      });
    case "binders/list/SET_PERSONAL_LIMIT":
      return produce(state, draft => {
        draft.personalLimit = action.payload.personalLimit;
      });
    case "binders/list/SET_PERSONAL_SORT_VALUE":
      return produce(state, draft => {
        draft.personalSortValue = action.payload.personalSortValue;
      });
    default:
      return state;
  }
};
