import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import telecomParams from "utils/helper/telecomParams";

const initialState = {
  data: {},
  riskInfo: {},
  articlePDF: {},
  article: {},
  articleDetail: {},
  sortOptions: [],
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.RISK_INFO_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      riskInfo: action.payload
    }),
    [`${ActionTypes.RISK_INFO_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.ARTICLE_PDF_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      articlePDF: action.payload
    }),
    [`${ActionTypes.ARTICLE_PDF_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.ARTICLE_LIST_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      article: action.payload
    }),
    [`${ActionTypes.ARTICLE_LIST_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.ARTICLE_DETAIL_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      articleDetail: action.payload
    }),
    [`${ActionTypes.ARTICLE_DETAIL_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.ARTICLE_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      article: action.payload
    }),
    [`${ActionTypes.ARTICLE_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.ARTICLE_LIST_SORT_OPTIONS_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      sortOptions: action.payload
    }),
    [`${ActionTypes.ARTICLE_LIST_SORT_OPTIONS_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(195, params)),
  getRiskInfo: createAction(ActionTypes.RISK_INFO_GET, params =>
    request(198, params)
  ),
  getArticlePDF: createAction(ActionTypes.ARTICLE_PDF_GET, params =>
    request(199, params)
  ),
  getArticleList: createAction(ActionTypes.ARTICLE_LIST_INIT, params =>
    request(200, params)
  ),
  getArticleDetail: createAction(ActionTypes.ARTICLE_DETAIL_GET, params =>
    request(201, params)
  ),
  getArticleListPage: createAction(ActionTypes.ARTICLE_LIST_GET, params =>
    request(202, params)
  ),
  getArticleListSortOptions: createAction(
    ActionTypes.ARTICLE_LIST_SORT_OPTIONS_GET,
    params => {
      const info = telecomParams()[0];
      return request(info.url, { ...info.params, ...params });
    }
  )
};
