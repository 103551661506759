import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  conditions: {
    from: "",
    to: "",
    selectedBusinessYearFrom: "",
    selectedBusinessYearTo: "",
    isCheckedLtm: false,
    isCheckedExpectation: false
  },
  bodyKeys: [
    {
      name: "時価総額",
      key: "currentPriceAmount",
      child: [
        { name: "株価（円）", key: "stockPrice" },
        { name: "発行済み株式数（株）", key: "stockAmount" }
      ],
      isOpen: false
    },
    {
      name: "現預金・短期有価証券",
      key: "moneySecurities",
      child: [],
      isOpen: false
    },
    {
      name: "有利子負債",
      key: "interestBearing",
      child: [
        { name: "短期借入金", key: "shortTermDebt" },
        { name: "1年内返済の長期借入金", key: "oneyearLongTermDebt" },
        { name: "1年内償還の社債・転換社債", key: "oneyearBond" },
        { name: "CP", key: "cp" },
        { name: "長期借入金", key: "longTermDebt" },
        { name: "社債・転換社債", key: "bond" },
        { name: "その他", key: "other" }
      ],
      isOpen: false
    },
    {
      name: "EV",
      key: "ev",
      child: [],
      isOpen: false
    },
    {
      name: "EBITDA",
      key: "ebitda",
      child: [
        { name: "営業利益", key: "operatingIncome" },
        { name: "減価償却費", key: "depreciation" },
        { name: "のれん・負ののれん償却額", key: "amortizationGoodwill" }
      ],
      isOpen: false
    },
    {
      name: "EV/EBITDA倍率（倍）",
      key: "magEvEbitda",
      child: [],
      isOpen: false
    },
    {
      name: "業種平均EV/EBITDA倍率（倍）",
      key: "magEvEbitdaIndustryAvg",
      child: [],
      isOpen: false
    },
    {
      name: "EV/売上高倍率（倍）",
      key: "magEvSales",
      child: [{ name: "売上高", key: "sales" }],
      isOpen: false
    },
    {
      name: "EV/営業利益倍率（倍）",
      key: "magEvEbitdaOperatingIncome",
      child: [{ name: "営業利益", key: "operatingIncome" }],
      isOpen: false
    },
    {
      name: "EV/EBIT倍率（倍）",
      key: "magEvEbit",
      child: [
        { name: "経常利益", key: "ordinaryIncome" },
        { name: "支払利息", key: "interestExpense" }
      ],
      isOpen: false
    },
    {
      name: "EV/償却前営業利益倍率（倍）",
      key: "magEvOpba",
      child: [
        { name: "営業利益", key: "operatingIncome" },
        { name: "減価償却費", key: "depreciation" }
      ],
      isOpen: false
    },
    {
      name: "推定株価（円）",
      key: "presumptionStockPrice",
      child: [],
      isOpen: false
    },
    {
      name: "推計株価[未経過リース料考慮]（円）",
      key: "leasePresumptionStockPrice",
      child: [
        {
          name: "未経過リース料含む有利子負債",
          key: "leaseInterestObligationAmount"
        }
      ],
      isOpen: false
    },
    {
      name: "市場付加価値",
      key: "bookValueTravelMarket",
      child: [],
      isOpen: false
    }
  ]
};

const createConditions = payload => {
  return {
    from: payload.selectedAccFrom,
    to: payload.selectedAccTo,
    selectedBusinessYearFrom: payload.selectedBusinessYearFrom,
    selectedBusinessYearTo: payload.selectedBusinessYearTo,
    isCheckedLtm: payload.ltmFlg === "1",
    isCheckedExpectation: payload.exceptionFlg === "1"
  };
};

const closeAll = tree => {
  tree = tree.map(item => {
    const newItem = { ...item };
    newItem.isOpen = false;
    return newItem;
  });
  return tree;
};

const toggleTree = (bodyKeys, parentKey) => {
  let newBody = [...bodyKeys];
  newBody = newBody.map(item => {
    const newItem = { ...item };
    if (newItem.key === parentKey) {
      newItem.isOpen = !newItem.isOpen;
    }
    return newItem;
  });
  return newBody;
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      conditions: createConditions(action.payload),
      bodyKeys: closeAll(state.bodyKeys)
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      conditions: createConditions(action.payload),
      bodyKeys: closeAll(state.bodyKeys)
    }),
    [`${ActionTypes.PAGE_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      conditions: createConditions(action.payload),
      bodyKeys: closeAll(state.bodyKeys)
    }),
    [ActionTypes.SASVE_CONDITONS]: (state, action) => ({
      ...state,
      conditions: action.payload
    }),
    [ActionTypes.TOGGLE]: (state, action) => ({
      ...state,
      bodyKeys: toggleTree(state.bodyKeys, action.payload)
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(310, params)),
  updatePage: createAction(ActionTypes.PAGE_UPDATE, params =>
    request(311, params)
  ),
  changePage: createAction(ActionTypes.PAGE_CHANGE, params =>
    request(312, params)
  ),
  saveConditions: createAction(ActionTypes.SASVE_CONDITONS),
  toggleChild: createAction(ActionTypes.TOGGLE)
};
