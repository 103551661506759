// APIが旧システムのパスを返却するので置換する
export const replaceUrl = (url, menuContentId = "") => {
  switch (menuContentId) {
    case "B9010":
      return url.replace(new RegExp("/report/event"), "/industry/event");
    case "B2030":
      return url.replace(new RegExp("/industry/ir"), "/industry/ir-data");
    case "C9010": // 同業企業比較
    case "CH9010":
    case "CHD9010":
    case "CK9010":
      return url.replace(
        new RegExp("/tool/comparisonlist"),
        "/corp/comparison/finance"
      );
    case "C6010": // 企業ニュース
    case "CH6010":
    case "CHD6010":
    case "CHK6010":
    case "C6020": // 企業レポート
    case "CH6020":
    case "CHD6020":
    case "CHK6020":
      return url;
  }
  return (
    url
      .replace(new RegExp("/jsp/report/news"), "/article/news")
      .replace(new RegExp("/jsp/report/researchranking"), "/article/report")
      .replace(
        new RegExp("/jsp/report/industryresearchreportglance"),
        "/article/research-report"
      )
      .replace(new RegExp("/jsp/report/ma"), "/article/ma")
      .replace(new RegExp("/jsp/report/strategy"), "/article/strategy")
      .replace(new RegExp("/jsp/macro/summary"), "/macro/summary")
      .replace(new RegExp("/jsp/macro/stat"), "/macro/statistics")
      .replace(new RegExp("/jsp/macro/needs"), "/macro/forecast")
      .replace(new RegExp("/jsp/macro/commodity"), "/macro/commodity")
      .replace(new RegExp("/jsp/macro/area"), "/macro/area")
      .replace(new RegExp("/jsp/tool/valuationmultiple"), "/tool/multiple")
      .replace(
        new RegExp("/jsp/tool/creategraph/dateSelect"),
        "/tool/graph-generator"
      )
      .replace(new RegExp("/jsp/user/option"), "/setting/option")
      .replace(new RegExp("/jsp/user/userfeelist"), "/setting/user-fee")
      .replace(new RegExp("/jsp/user/loginhistory"), "/setting/login-history")
      .replace(new RegExp("/jsp/user/information"), "/setting/user")
      .replace(new RegExp("/jsp/user/alert"), "/setting/personal")
      .replace(new RegExp("/jsp/sys/information"), "/setting/contents")
      .replace(new RegExp("/searchmap"), "/search/by-industry")
      // .replace(new RegExp("/searchmap"), "/search/classification")
      .replace(new RegExp("/industrylistsearch"), "/search/industry")
      .replace(new RegExp("/corp/generalinfo"), "/corp/overview")
      .replace(new RegExp("/corp/riskinfo"), "/corp/risk")
      .replace(new RegExp("/corp/shareholders"), "/corp/shares/shareholder")
      .replace(new RegExp("/corp/stock"), "/corp/shares/holdings")
      .replace(new RegExp("/corp/shareholder/meeting"), "/corp/meeting")
      .replace(new RegExp("/corp/csr/report"), "/corp/esg/environment")
      .replace(new RegExp("/corp/csr/green"), "/corp/csr/environment")
      .replace(new RegExp("/corp/csr/cges"), "/corp/csr/evaluation")
      .replace(new RegExp("/corp/fin"), "/corp/finance")
      .replace(new RegExp("/corp/georev"), "/corp/segment/country")
      .replace(new RegExp("/corp/banking/dept"), "/corp/loan")
      .replace(
        new RegExp("/corp/business/performance"),
        "/corp/earning-forecast"
      )
      .replace(new RegExp("/corp/ir"), "/corp/ir-data")
      .replace(
        new RegExp("/corp/valuation/index"),
        "/corp/valuation/investment-index"
      )
      .replace(new RegExp("/tool/comparisonlist"), "/tool/comparison/corp")
      // .replace(new RegExp("/tool/comparisonlist"), "/corp/comparison/finance")
      // .replace(new RegExp("/tool/comparisonlist"), "/corp/comparison/segment")
      .replace(new RegExp("/corp/maevent"), "/corp/ma-event")
      .replace(new RegExp("/corp/mastrategy"), "/corp/ma-strategy")
      .replace(new RegExp("/corp/info/search"), "/corp/telecom/search")
      .replace(
        new RegExp("/corp/info/industrysearch"),
        "/corp/telecom/trade-press"
      )
      .replace(new RegExp("/corp/unlisted/generalinfo"), "/corp/overview")
      .replace(new RegExp("/corp/unlisted/fin"), "/corp/finance")
      .replace(
        new RegExp("/corp/overseas/shareholders"),
        "/corp/shares/shareholder"
      )
      .replace(new RegExp("/corp/overseas/fin"), "/corp/finance")
      .replace(new RegExp("/corp/overseas/segment"), "/corp/segment/business")
      .replace(new RegExp("/corp/georev"), "/corp/segment/country")
      .replace(
        new RegExp("/corp/overseas/performance"),
        "/corp/earning-forecast"
      )
      .replace(new RegExp("/corp/overseas/disclosure"), "/corp/disclosure")
      .replace(new RegExp("/corp/overseas/index"), "/corp/index")
      .replace(new RegExp("/corp/overseas/valuation"), "/corp/valuation/value")
      .replace(new RegExp("/industry/ir"), "/macro/ir-data")
      // .replace(new RegExp("/industry/ir"), "/industry/ir-data")
      .replace(new RegExp("/corp/news"), "/industry/news")
      .replace(new RegExp("/corp/report"), "/industry/report")
      .replace(
        new RegExp("/corp/industryresearchreport"),
        "/industry/research-report"
      )
      .replace(
        new RegExp("/industry/info/search"),
        "/industry/telecom/trade-press"
      )
      .replace(new RegExp(/\/macro\/stat(?=\?)/), "/macro/statistics")
      .replace(new RegExp("/macro/needs"), "/macro/forecast")
      .replace(new RegExp("/report/news"), "/article/news")
      .replace(new RegExp("/report/researchranking"), "/article/report")
      .replace(
        new RegExp("/report/researchranking/reportRankingSearch"),
        "/article/report"
      )
      .replace(new RegExp("/report/researchrankingglance"), "/article/report")
      .replace(
        new RegExp("/report/researchrankingglance/reportRankingGlance"),
        "/article/report"
      )
      .replace(
        new RegExp(/\/report\/industryresearchreport(?=\?)/),
        "/article/research-report"
      )
      .replace(
        new RegExp("/report/industryresearchreport/reportRankingSearch"),
        "/article/research-report"
      )
      .replace(
        new RegExp("/report/industryresearchreportglance"),
        "/article/research-report"
      )
      .replace(
        new RegExp(
          "/report/industryresearchreportglance/reportRankingGlanceSearch"
        ),
        "/article/research-report"
      )
      .replace(
        new RegExp("/report/industryresearchreportlist/countryListClick"),
        "/article/research-report"
      )
      .replace(new RegExp("/report/disclosure"), "/article/disclosure")
      .replace(new RegExp("/report/event"), "/article/event")
      // .replace(new RegExp("/report/event"), "/industry/event")
      .replace(new RegExp("/report/ma"), "/article/ma")
      .replace(new RegExp("/report/strategy"), "/article/strategy")
      .replace(new RegExp("/tool/screening/corp"), "/tool/screening/corp/item")
      .replace(
        new RegExp("/tool/screening/business"),
        "/tool/screening/segment/business"
      )
      .replace(
        new RegExp("/tool/screening/area"),
        "/tool/screening/segment/area"
      )
      .replace(
        new RegExp("/tool/industrycomparisonlist"),
        "/tool/comparison/industry"
      )
      .replace(new RegExp("/tool/valuationmultiple"), "/tool/multiple")
      .replace(
        new RegExp("/tool/creategraph/dateSelect"),
        "/tool/graph-generator"
      )
      .replace(new RegExp("/tool/excelplugin"), "/tool/excel-addin")
      .replace(new RegExp("/latestview"), "/history")
      .replace(new RegExp("/user/option"), "/setting/option")
      .replace(new RegExp("/user/userfeelist"), "/setting/user-fee")
      .replace(new RegExp("/user/userfeelist/dateShow"), "/setting/user-fee")
      .replace(new RegExp("/user/loginhistory"), "/setting/login-history")
      .replace(new RegExp("/user/setting"), "/setting/user")
      .replace(new RegExp("/user/alert"), "/setting/personal")
      .replace(
        new RegExp("/tool/screening/scenario"),
        "/tool/screening/corp/scenario"
      )
  );
};
