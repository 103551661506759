import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  profile: {},
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.PROFILE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      profile: action.payload
    }),
    [`${ActionTypes.PROFILE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(116, params)),
  getProfile: createAction(ActionTypes.PROFILE_GET, params =>
    request(119, params)
  )
};
