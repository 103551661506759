// 初期表示
export const PAGE_INIT = "corp/summary/PAGE_INIT";
// ニュース取得
export const NEWS_GET = "corp/summary/NEWS_GET";
// 種類別セグメント情報取得
export const SEGMENT_CORP_GET = "corp/summary/SEGMENT_CORP_GET";
// TDB提供企業情報取得
export const TDB_CORP_GET = "corp/summary/TDB_CORP_GET";
// PDF 取得
export const PDF_GET = "corp/summary/PDF_GET";

// 業界
export const INDUSTRY_LIST_SET = "corp/summary/INDUSTRY_LIST_SET";
export const SELECTED_INDUSTRY_SET = "corp/summary/SELECTED_INDUSTRY_SET";
// 科目
export const SELECTED_KAMOKU_SET = "corp/summary/SELECTED_KAMOKU_SET";

/**
 * TDB購入画面モーダル
 */
export const SET_OPERATE_HISTORY = "corp/summary/SET_OPERATE_HISTORY";
export const MODAL_SUBMIT = "corp/summary/MODAL_SUBMIT";
export const TDB_INFO_GET = "corp/summary/TDB_INFO_GET";
