import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  selectedDate: {},
  profile: {},
  notice: {},
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      selectedDate:
        action.payload.dateselect.length > 0 ? action.payload.dateselect[0] : {} // 初期値は先頭
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.DATE_SELECT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      selectedDate: action.payload.dateselect.find(
        date => date.value === action.payload.opendate
      )
    }),
    [`${ActionTypes.PROFILE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      profile: action.payload
    }),
    [`${ActionTypes.NOTICE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      notice: action.payload
    }),
    [`${ActionTypes.NOTICE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      notice: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(231, params)),
  selectDate: createAction(ActionTypes.DATE_SELECT, params =>
    request(232, params)
  ),
  getProfile: createAction(ActionTypes.PROFILE_GET, params =>
    request(237, params)
  ),
  initNotice: createAction(ActionTypes.NOTICE_INIT, params =>
    request(239, params)
  ),
  getNotice: createAction(ActionTypes.NOTICE_GET, params =>
    request(238, params)
  )
};
