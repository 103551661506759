const supportsPreload = (() => {
  try {
    return document.createElement("link").relList.supports("preload");
  } catch {
    return false;
  }
})();

const appendLink = (href: string, as: string) => {
  const link = document.createElement("link");
  link.setAttribute("rel", "preload");
  link.setAttribute("as", as);
  link.setAttribute("href", href);
  link.onload = () => document.head.removeChild(link);
  document.head.appendChild(link);
};

const preload = (href: string | string[], as: string) => {
  if (!supportsPreload) return;

  if (!Array.isArray(href)) {
    appendLink(href, as);
    return;
  }

  href.map(item => appendLink(item, as));
};

export const preloadImage = (href: string | string[]) => {
  preload(href, "image");
};
