import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  conditions: {
    from: "",
    to: "",
    fromLabel: "",
    toLabel: "",
    isCheckedLtm: false,
    isCheckedExpectation: false
  },
  bodyKeys: [
    {
      name: "時価総額",
      key: "currentPriceAmount",
      child: [
        { name: "株価（円）", key: "stockPrice" },
        { name: "発行済み株式数（株）", key: "stockAmount" }
      ],
      isOpen: false
    },
    {
      name: "PER（倍）",
      key: "per",
      child: [{ name: "当期利益", key: "currentTermProfit" }],
      isOpen: false
    },
    {
      name: "株式益回り（％）",
      key: "stockYield",
      child: [],
      isOpen: false
    },
    {
      name: "PEGレシオ",
      key: "pegRatio",
      child: [
        { name: "一株当たり利益（円）", key: "yieldPerStock" },
        { name: "一株利益5年平均伸び率（％）", key: "yieldPerStockGrowth" }
      ],
      isOpen: false
    },
    {
      name: "PBR（倍）",
      key: "pbr",
      child: [
        { name: "自己資本", key: "equity" },
        { name: "一株当たり純資産（円）", key: "netAssetsPerStock" },
        { name: "当期利益/自己資本（％）", key: "profitsPerAssets" },
        { name: "サスティナブル成長率（％）", key: "sustainable" }
      ],
      isOpen: false
    },
    {
      name: "配当利回り（％）",
      key: "dividendYield",
      child: [{ name: "一株配当（円）", key: "dividendPerStock" }],
      isOpen: false
    },
    {
      name: "PSR（倍）",
      key: "psr",
      child: [
        { name: "一株当たり売上高（円）", key: "salesPerStock" },
        { name: "売上高", key: "sales" }
      ],
      isOpen: false
    },
    {
      name: "PCFR（倍）",
      key: "pcfr",
      child: [
        { name: "一株当たりキャッシュフロー（円）", key: "cashFlowPerStock" },
        { name: "キャッシュフロー", key: "cashFlow" }
      ],
      isOpen: false
    },
    {
      name: "時価総額/包括利益倍率（倍）",
      key: "currentPricePerOwnedCapital",
      child: [{ name: "包括利益", key: "comp" }],
      isOpen: false
    }
  ]
};

const createConditions = payload => {
  const from = payload.periodFromList.find(
    item => item.value === payload.periodFrom
  );
  const fromLabel = from ? from.label : "";
  const to = payload.periodToList.find(item => item.value === payload.periodTo);
  const toLabel = to ? to.label : "";

  return {
    from: payload.periodFrom,
    to: payload.periodTo,
    fromLabel: fromLabel,
    toLabel: toLabel,
    isCheckedLtm: payload.ltmFlg === "1",
    isCheckedExpectation: payload.exceptionFlg === "1"
  };
};

const closeAll = tree => {
  tree = tree.map(item => {
    const newItems = { ...item };
    newItems.isOpen = false;
    return newItems;
  });
  return tree;
};

const toggleTree = (bodyKeys, parentKey) => {
  let newBody = [...bodyKeys];
  newBody = newBody.map(item => {
    const newItem = { ...item };
    if (newItem.key === parentKey) {
      newItem.isOpen = !newItem.isOpen;
    }
    return newItem;
  });
  return newBody;
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      conditions: createConditions(action.payload),
      bodyKeys: closeAll(state.bodyKeys)
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      conditions: createConditions(action.payload),
      bodyKeys: closeAll(state.bodyKeys)
    }),
    [`${ActionTypes.PAGE_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      conditions: createConditions(action.payload),
      bodyKeys: closeAll(state.bodyKeys)
    }),
    [ActionTypes.SASVE_CONDITONS]: (state, action) => ({
      ...state,
      conditions: action.payload
    }),
    [ActionTypes.TOGGLE]: (state, action) => ({
      ...state,
      bodyKeys: toggleTree(state.bodyKeys, action.payload)
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(300, params)),
  updatePage: createAction(ActionTypes.PAGE_UPDATE, params =>
    request(301, params)
  ),
  changePage: createAction(ActionTypes.PAGE_CHANGE, params =>
    request(302, params)
  ),
  saveConditions: createAction(ActionTypes.SASVE_CONDITONS),
  toggleChild: createAction(ActionTypes.TOGGLE)
};
