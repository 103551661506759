import { Reducer } from "redux";
import produce from "immer";
import { DownloadManagementAction } from "./actions";
import { DownloadFile } from "models/DownloadManagement";

interface DownloadManagementState {
  data: {
    currentPage: string;
    downloadFileList: DownloadFile[];
    endNo: string;
    endPageFlag: string;
    startNo: string;
    startPageFlag: string;
    totalCount: string;
  };
}

const initialState: DownloadManagementState = {
  data: {
    currentPage: "",
    downloadFileList: [],
    endNo: "",
    endPageFlag: "",
    startNo: "",
    startPageFlag: "",
    totalCount: ""
  }
};

export const downloadManagement: Reducer<
  DownloadManagementState,
  DownloadManagementAction
> = (state = initialState, action) => {
  switch (action.type) {
    case "tool/download/list/INIT_PAGE":
      return produce(state, draft => {
        draft.data = action.payload;
      });
    case "tool/download/list/SET_DOWNLOADFILE_LIST":
      return produce(state, draft => {
        draft.data.downloadFileList = action.payload;
      });
    case "tool/download/list/SET_START_NO":
      return produce(state, draft => {
        draft.data.startNo = action.payload;
      });
    case "tool/download/list/SET_END_NO":
      return produce(state, draft => {
        draft.data.endNo = action.payload;
      });
    case "tool/download/list/SET_CURRENT_PAGE":
      return produce(state, draft => {
        draft.data.currentPage = action.payload;
      });
    default:
      return state;
  }
};
