// 初期表示
export const PAGE_INIT = "corp/csr/labor/PAGE_INIT";
export const ON_DISPLAY = "corp/csr/labor/ON_DISPLAY";

// 「「関連記事」データ取得
export const RELATED_NEWS_GET = "corp/csr/labor/RELATED_NEWS_GET";
export const PAGING = "corp/csr/labor/PAGING";
export const SORT_OPTIONS_SET = "corp/csr/labor/SORT_OPTIONS_SET";
export const SELECTED_SORT_SET = "corp/csr/labor/SELECTED_SORT_SET";

// 期間変更
export const ENVIRONMENT_PERIOD_CHANGE =
  "corp/csr/labor/ENVIRONMENT_PERIOD_CHANGE";
export const DETAIL_PERIOD_CHANGE = "corp/csr/labor/DETAIL_PERIOD_CHANGE";
