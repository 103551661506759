// 初期表示
export const PAGE_INIT = "corp/risk/PAGE_INIT";
// リスク情報取得
export const RISK_INFO_GET = "corp/risk/RISK_INFO_GET";
// 格付け関連記事PDFダウンロード
export const ARTICLE_PDF_GET = "corp/risk/ARTICLE_PDF_GET";
// 格付け関連記事一覧ページ初期表示
export const ARTICLE_LIST_INIT = "corp/risk/ARTICLE_LIST_INIT";
// 格付け関連記事内容取得
export const ARTICLE_DETAIL_GET = "corp/risk/ARTICLE_DETAIL_GET";
// 格付け関連記事一覧ページ取得
export const ARTICLE_LIST_GET = "corp/risk/ARTICLE_LIST_GET";
// 格付け関連記事一覧ソートオプション取得
export const ARTICLE_LIST_SORT_OPTIONS_GET =
  "corp/risk/ARTICLE_LIST_SORT_OPTIONS_GET";
