import React from "react";
import { Link, LinkProps } from "react-router-dom";
import TextButton from "components/Common/Button/Text";
import styles from "./style.scss";

interface Props {
  to: LinkProps["to"];
  "aria-label"?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const LinkButton: React.FC<Props> = props => {
  const button = props.children ? (
    <TextButton
      aria-label={props["aria-label"]}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </TextButton>
  ) : (
    <button
      aria-label={props["aria-label"]}
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
    />
  );

  return (
    <Link to={props.to} className={styles.link} data-testid="link-button">
      {button}
    </Link>
  );
};
