import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  resetKey: "0",
  init: null,
  keyword: "",
  rawOperand: { and: "", or: "", not: "" },
  isOpenAdvancedSearch: false,
  periodName: "all",
  kikan: "all",
  filterOptionList: [],
  scopeCheckboxes: [
    {
      id: "headline",
      label: "見出し",
      checked: true
    },
    {
      id: "body",
      label: "本文",
      checked: true
    }
  ], // 検索範囲
  targetCheckboxes: [], // 検索対象
  initTargetCheckboxes: [],
  fromDate: "",
  fromDate: "",
  fromDateObj: null,
  isLoading: {
    condition: false,
    result: false
  },
  doForceSearch: false,

  // list
  result: {},

  error: {},

  searched: {
    rawText: "",
    rawOperand: {
      and: "",
      or: "",
      not: ""
    },
    body: null
  },

  preparedTargetCheckboxes: []
};

export default handleActions(
  {
    [`${ActionTypes.SEARCH}_PENDING`]: state => ({
      ...state,
      isLoading: { ...state.isLoading, result: true }
    }),
    [`${ActionTypes.SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      },
      searched: {
        rawText: action.payload.query,
        rawOperand: {
          and: action.payload.keyword,
          or: action.payload.orKeyword,
          not: action.payload.notKeyword
        },
        query: action.payload.query,
        body: action.payload.body
      }
    }),
    [`${ActionTypes.SEARCH}_REJECTED`]: state => ({
      ...state,
      result: initialState.result,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.PAGING}_FULFILLED`]: (state, action) => ({
      ...state,
      result: {
        ...action.payload,
        // ページング押下時のレスポンスに含まれる sort が不正なため（空値）
        sort: state.result.sort
      }
    }),
    [`${ActionTypes.RESULT_SORT}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.RESULT_SORT}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload,
      isLoading: false
    }),
    [ActionTypes.CONDITIONS_CLEAR]: state => ({
      ...state,
      resetKey: state.resetKey === "1" ? "0" : "1",
      targetCheckboxes: state.initTargetCheckboxes,
      scopeCheckboxes: initialState.scopeCheckboxes,
      keyword: "",
      rawOperand: { and: "", or: "", not: "" },
      kikan: "all",
      periodName: "all",
      fromDate: "",
      fromDate: ""
    }),
    [ActionTypes.DO_FORCE_SEARCH_SET]: (state, action) => ({
      ...state,
      doForceSearch: action.payload
    }),
    [ActionTypes.KEYWORD_UPDATE]: (state, action) => ({
      ...state,
      keyword: action.payload
    }),
    [ActionTypes.OPERAND_RAW_UPDATE]: (state, action) => ({
      ...state,
      rawOperand: action.payload
    }),
    [ActionTypes.IS_OPEN_ADVANCED_SEARCH_UPDATE]: (state, action) => ({
      ...state,
      isOpenAdvancedSearch: action.payload
    }),
    [ActionTypes.ENABLE_ALL_PERIOD_SET]: (state, action) => {
      return {
        ...state,
        periodName: action.payload,
        kikan: action.payload === "all" ? "all" : "free"
      };
    },
    [ActionTypes.FROM_DATE_UPDATE]: (state, action) => ({
      ...state,
      fromDate: action.payload
    }),
    [ActionTypes.TO_DATE_UPDATE]: (state, action) => ({
      ...state,
      toDate: action.payload
    }),
    [ActionTypes.SCOPE_CHECKBOXES_UPDATE]: (state, action) => ({
      ...state,
      scopeCheckboxes: action.payload
    }),
    [ActionTypes.INIT_TARGET_CHECKBOXES_SET]: (state, action) => ({
      ...state,
      targetCheckboxes: action.payload.targetCheckboxes,
      initTargetCheckboxes: action.payload.initTargetCheckboxes,
      preparedTargetCheckboxes: initialState.preparedTargetCheckboxes
    }),
    [ActionTypes.TARGET_CHECKBOXES_UPDATE]: (state, action) => ({
      ...state,
      targetCheckboxes: action.payload
    }),
    [ActionTypes.FILTER_OPTION_LIST_INIT]: (state, action) => ({
      ...state,
      filterOptionList: action.payload
    }),
    [`${ActionTypes.PDF_DOWNLOAD}_PENDING`]: (state, action) => ({
      ...state,
      keyword: action.payload
    }),
    [`${ActionTypes.PDF_DOWNLOAD}_FULFILLED`]: (state, action) => ({
      ...state,
      keyword: action.payload
    }),
    [`${ActionTypes.XBRL_DOWNLOAD}_PENDING`]: (state, action) => ({
      ...state,
      keyword: action.payload
    }),
    [`${ActionTypes.XBRL_DOWNLOAD}_FULFILLED`]: (state, action) => ({
      ...state,
      keyword: action.payload
    }),
    [ActionTypes.CONDITION_LOADING_UPDATE]: (state, action) => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        condition: action.payload
      }
    }),
    [ActionTypes.ALL_RESET]: () => initialState,
    [ActionTypes.PREPARED_TARGET_CHECKBOXES_SET]: (state, action) => ({
      ...state,
      preparedTargetCheckboxes: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  search: createAction(ActionTypes.SEARCH, params => request(531, params)),
  paging: createAction(ActionTypes.PAGING, params => request(535, params)),
  sortResult: createAction(ActionTypes.RESULT_SORT, params =>
    request(532, params)
  ),
  clearConditions: createAction(ActionTypes.CONDITIONS_CLEAR),
  setDoForceSearch: createAction(ActionTypes.DO_FORCE_SEARCH_SET),
  updateKeyword: createAction(ActionTypes.KEYWORD_UPDATE),
  updateRawOperand: createAction(ActionTypes.OPERAND_RAW_UPDATE),
  updateAdvanceSearchOpen: createAction(
    ActionTypes.IS_OPEN_ADVANCED_SEARCH_UPDATE
  ),
  setEnableAllPeriod: createAction(ActionTypes.ENABLE_ALL_PERIOD_SET),
  updateFromDate: createAction(ActionTypes.FROM_DATE_UPDATE),
  updateToDate: createAction(ActionTypes.TO_DATE_UPDATE),
  updateScopeCheckboxes: createAction(ActionTypes.SCOPE_CHECKBOXES_UPDATE),
  setInitTargetCheckboxes: createAction(ActionTypes.INIT_TARGET_CHECKBOXES_SET),
  updateTargetCheckboxes: createAction(ActionTypes.TARGET_CHECKBOXES_UPDATE),
  initFilterOptionList: createAction(ActionTypes.FILTER_OPTION_LIST_INIT),
  downloadPdf: createAction(ActionTypes.PDF_DOWNLOAD, params =>
    request(533, params)
  ),
  downloadXBRL: createAction(ActionTypes.XBRL_DOWNLOAD, params =>
    request(534, params)
  ),
  updateConditionLoading: createAction(ActionTypes.CONDITION_LOADING_UPDATE),
  resetAll: createAction(ActionTypes.ALL_RESET),
  setPreparedTargetCheckboxes: createAction(
    ActionTypes.PREPARED_TARGET_CHECKBOXES_SET
  )
};
