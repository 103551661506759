import React from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { actionCreators } from "modules/toast/actions";

interface LocationState {
  reserveErrors: string[];
}

export const useToastContext = () => {
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();

  const setMessages = (messages: string | string[]) =>
    dispatch(actionCreators.setMessages(messages));

  const clearMessages = () => dispatch(actionCreators.clearMessages());

  const setErrors = (messages: string | string[]) =>
    dispatch(actionCreators.setErrors(messages));

  const clearErrors = (forceClear = false) => {
    if (!forceClear && location.state && location.state.reserveErrors) return;

    dispatch(actionCreators.clearErrors());
  };

  return {
    setMessages,
    clearMessages,
    setErrors,
    clearErrors
  };
};

export const ToastContextProvider: React.FunctionComponent = props => {
  const {
    setMessages,
    clearMessages,
    setErrors,
    clearErrors
  } = useToastContext();

  return (
    <ToastContext.Provider
      value={{
        setMessages,
        setErrors,
        clearMessages,
        clearErrors
      }}
    >
      {props.children}
    </ToastContext.Provider>
  );
};

interface ToastContextProps {
  setMessages: (messages: string | string[]) => void;
  setErrors: (messages: string | string[]) => void;
  clearMessages: () => void;
  clearErrors: (forceClear?: boolean) => void;
}

const ToastContext = React.createContext<ToastContextProps>({
  setMessages: () => {},
  setErrors: () => {},
  clearMessages: () => {},
  clearErrors: () => {}
});

export default ToastContext;
