import { StatisticAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";
import { ExtType, ExtStringType } from "models/Macro";
import {
  getEdgeDate,
  buildDateList,
  buildExtList,
  getDayjsUnit,
  getDefaultFromDate
} from "./helper";
import dayjs from "dayjs";

export interface Condition {
  from: string;
  to: string;
  extValue: ExtStringType | "";
  dataType: string;
}

export interface Loading {
  list: boolean;
}

interface StatisticState {
  data: any;
  keyword: {
    list: any[];
    count: number | null;
  };
  seriesInfoList: any;
  newsData: any;
  binderList: any;
  selectedItems: any;
  allSelectedItems: any;
  binderItems: any[];
  updateSearchResult: any;
  conditions: {
    value: Condition;
    list: {
      year: number[];
      month: number[];
      ext: { value: ExtStringType; label: string }[];
    };
  };
  transition: {
    dataType: string;
    items: any[];
    extType: ExtStringType | null;
  };
  isLoading: Loading;
}

const initialState: StatisticState = {
  data: null,
  keyword: {
    list: [],
    count: null
  },
  seriesInfoList: [],
  newsData: {},
  binderList: [],
  selectedItems: [],
  allSelectedItems: [],
  binderItems: [],
  updateSearchResult: {},
  conditions: {
    value: {
      from: "",
      to: "",
      extValue: "",
      dataType: ""
    },
    list: {
      year: [],
      month: [],
      ext: []
    }
  },
  transition: {
    dataType: "",
    items: [],
    extType: null
  },
  isLoading: {
    list: false
  }
};

export const statistic: Reducer<StatisticState, StatisticAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "macro/statistic/INIT_PAGE":
      return produce(state, draft => {
        draft.data = action.payload.data;
      });
    case "macro/statistic/RESET_KEYWORD":
      return produce(state, draft => {
        draft.keyword = initialState.keyword;
      });
    case "macro/statistic/SET_KEYWORD":
      return produce(state, draft => {
        draft.keyword = action.payload;
      });
    case "macro/statistic/GET_SERIESLIST":
      return produce(state, draft => {
        draft.seriesInfoList = action.payload.seriesInfoList;
      });
    case "macro/statistic/GET_NEWS_LIST":
      return produce(state, draft => {
        draft.newsData = action.payload.newsData;
      });
    case "macro/statistic/SET_BINDER_LIST":
      return produce(state, draft => {
        draft.binderList = action.payload;
      });
    case "macro/statistic/SET_BINDER_ITEMS":
      return produce(state, draft => {
        draft.binderItems = action.payload;
      });
    case "macro/statistic/UPDATE_SELECTED_ITEMS":
      return produce(state, draft => {
        draft.selectedItems = action.payload.data;
      });
    case "macro/statistic/GET_IR_DATA":
      return produce(state, draft => {
        draft.selectedItems = action.payload.data;
      });
    case "macro/statistic/SET_UPDATE_SEARCH_RESULT":
      return produce(state, draft => {
        draft.transition = initialState.transition;
        draft.updateSearchResult = action.payload.response;
        if (!action.payload.params) return;

        draft.conditions.value = action.payload.params;
        const extList = state.selectedItems.map(
          (item: any) => item.extFlg
        ) as ExtType[];
        draft.conditions.list.ext = buildExtList(extList);
        if (action.payload.params.extValue === "") return;
        const from = getEdgeDate(extList, "From");
        const dateList = buildDateList(
          from,
          action.payload.params.to,
          action.payload.params.extValue
        );
        draft.conditions.list.year = dateList.year;
        draft.conditions.list.month = dateList.month;
      });
    case "macro/statistic/CHANGE_CONDITION":
      return produce(state, draft => {
        const currentStateExt = state.conditions.value.extValue;
        if (currentStateExt && action.payload.key === "from") {
          const unit = getDayjsUnit(currentStateExt);
          draft.conditions.value["from"] = dayjs(action.payload.value)
            .startOf(unit)
            .format("YYYYMMDD");
        } else if (currentStateExt && action.payload.key === "to") {
          const unit = getDayjsUnit(currentStateExt);
          draft.conditions.value["to"] = dayjs(action.payload.value)
            .endOf(unit)
            .format("YYYYMMDD");
        } else {
          draft.conditions.value[action.payload.key] = action.payload.value;
        }
        if (action.payload.key !== "extValue") return;

        const extList = state.selectedItems.map(
          (item: any) => item.extFlg
        ) as ExtType[];
        const extValue = action.payload.value as ExtStringType;
        const from = getEdgeDate(extList, "From", extValue);
        const to = getEdgeDate(extList, "To", extValue);
        const dateList = buildDateList(from, to, extValue);
        draft.conditions.value.from = getDefaultFromDate(from, to, extValue);
        draft.conditions.value.to = to;
        draft.conditions.list.year = dateList.year;
        draft.conditions.list.month = dateList.month;
      });
    case "macro/statistic/RESET_STATE":
      return initialState;
    case "macro/statistic/SET_TRANSITION_ITEMS":
      return produce(state, draft => {
        draft.transition.items = action.payload.items;
        draft.transition.dataType = action.payload.dataType || "1";
        draft.transition.extType = action.payload.extType || null;
      });
    case "macro/statistic/SET_LOADING":
      return produce(state, draft => {
        draft.isLoading[action.payload.key] = action.payload.value;
      });
    default:
      return state;
  }
};
