import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setBinderOpenFlgValue } from "modules/binders/list/actions";
import { actionCreators } from "modules/common/BookmarkBalloon";
import { actionCreators as toastActionCreators } from "modules/toast/actions";
import { actionCreators as headerActionCreators } from "modules/header";
import { isEmpty } from "utils/helper/common";

import BookmarkBalloon from "components/Common/BookmarkBalloon/Implementation";

const BookmarkBalloonContainer = ({ list, ...props }) => {
  // IP・トークン認証時は表示しない
  if (props.ipAuthFlg) return null;

  let nextList = [...list];
  // フォルダ一覧に表示しないIDが指定されたら除去する
  if (props.hasOwnProperty("hideFolderId")) {
    nextList = list.filter(data => data.value !== props.hideFolderId);
  }
  return <BookmarkBalloon {...props} list={nextList} />;
};

const mapStateToProps = state => ({
  list: state.reducers.commonBookmarkBalloon.list,
  mediaList: state.reducers.commonBookmarkBalloon.media,
  isLoading: state.reducers.commonBookmarkBalloon.isLoading,
  industryInfo: state.reducers.global.industryInfo,
  ipAuthFlg: state.reducers.auth.saved.user.ipAuthFlg,
  isAdvancedMode: state.reducers.operandRawSearch.saved.advancedMode.collection
});

const mapDispatchToProps = dispatch => {
  const insert = async (action, params, type) => {
    // 表示中メッセージをリセット
    dispatch(toastActionCreators.clearErrors());
    dispatch(toastActionCreators.clearMessages());

    const response = await dispatch(action(params));
    if (response.body && response.body.code === 400) {
      dispatch(toastActionCreators.setErrors(response.body.details[0].message));
      return Promise.reject(response);
    }
    if (isEmpty(response.value)) return response;

    // エラーがなければモーダルを閉じてメッセージをポップアップ表示します（順序があります）
    dispatch(setBinderOpenFlgValue(false));
    if (type) {
      if (response.value.saveStatus === "0") {
        dispatch(
          toastActionCreators.setMessages([
            `コレクション（${type}）への登録が完了しました`
          ])
        );
      } else if (response.value.saveStatus === "1") {
        dispatch(
          toastActionCreators.setMessages([
            `既にコレクション（${type}）に登録されています`
          ])
        );
      } else if (response.value.saveStatus === "2") {
        dispatch(
          toastActionCreators.setMessages([
            `コレクション（${type}）への登録が完了しました`
          ])
        );
      }
    } else {
      if (response.value.saveStatus === "0") {
        dispatch(
          toastActionCreators.setMessages([
            `コレクションへの登録が完了しました`
          ])
        );
      } else if (response.value.saveStatus === "1") {
        dispatch(
          toastActionCreators.setMessages([
            `既にコレクションに登録されています`
          ])
        );
      } else if (response.value.saveStatus === "2") {
        dispatch(
          toastActionCreators.setMessages([
            `コレクションへの登録が完了しました`
          ])
        );
      }
    }

    dispatch(headerActionCreators.requestUpdate());
    return response;
  };
  return {
    getBinderList: params => dispatch(actionCreators.getBinderList(params)),
    getMediaList: params => dispatch(actionCreators.getMediaList(params)),
    insertCorpItem: params => insert(actionCreators.insertCorpItem, params),
    insertIndustryItem: params =>
      insert(actionCreators.insertIndustryItem, params),
    insertArticleItem: params =>
      insert(actionCreators.insertArticleItem, params, "保存記事"),
    insertGovernmentItem: params =>
      insert(actionCreators.insertGovernmentItem, params, "ページ"),
    insertStatisticItem: params =>
      insert(actionCreators.insertStatisticItem, params),
    insertAreaItem: params => insert(actionCreators.insertAreaItem, params),
    initBinderList: () => dispatch(),
    toggleBinderListRefetchFlg: () =>
      dispatch(actionCreators.toggleBinderListRefetchFlg())
  };
};

const mergeProps = (state, dispatch, ownProps) => {
  const urlParams = new URLSearchParams(ownProps.location.search);

  return {
    ...state,
    ...dispatch,
    ...ownProps,
    insertItem: params => {
      ["andKeyword", "orKeyword", "notKeyword"].forEach(key => {
        if (params.hasOwnProperty(key)) {
          params[key] = params[key].replace(/　/g, " ");
        }
      });

      switch (ownProps.itemType) {
        case "industry":
          if (ownProps.selectedIndustryCode) {
            if (ownProps.selectedIndustryCode.indexOf(",") !== -1) {
              params["industryCode"] = ownProps.selectedIndustryCode
                .split(",")
                .join("|");
            } else {
              params["industryCode"] = ownProps.selectedIndustryCode;
            }
          } else {
            params["industryCode"] = urlParams.get("corpIndustyCode");
          }
          return dispatch.insertIndustryItem(params);
        case "article":
          params["articleId"] =
            urlParams.get("articleId") ||
            urlParams.get("kijiId") ||
            urlParams.get("keyBody") ||
            ownProps.kijiId;
          params["articleKbn"] =
            ownProps.articleKbn || urlParams.get("articleKbn");
          params["mediaCode"] =
            ownProps.mediaCode || urlParams.get("mediaCode");
          return dispatch.insertArticleItem(params);
        case "government":
          params["articleId"] =
            urlParams.get("articleId") ||
            urlParams.get("kijiId") ||
            urlParams.get("keyBody") ||
            ownProps.kijiId;
          params["articleKbn"] =
            ownProps.articleKbn || urlParams.get("articleKbn");
          params["mediaCode"] =
            ownProps.mediaCode || urlParams.get("mediaCode");
          return dispatch.insertGovernmentItem(params);
        case "ir":
        case "statistics":
        case "commodity":
          params["schema"] = ownProps.items.reduce(
            (acc, cur) => (acc ? `${acc}|${cur.schema}` : cur.schema),
            ""
          );
          params["series"] = ownProps.items.reduce((acc, cur) => {
            const series = cur.series.trim();
            return acc ? `${acc}|${series}` : series;
          }, "");
          params["statisticsType"] = ownProps.items.reduce((acc, cur) => {
            let type;
            switch (ownProps.itemType) {
              case "ir":
                type = cur.isIndustry ? "2" : "3";
                break;
              case "commodity":
                type = "4";
                break;
              default:
                type = "1";
            }
            return acc ? `${acc}|${type}` : type;
          }, "");
          return dispatch.insertStatisticItem(params);
        case "area":
          params["schema"] = ownProps.seriesItems
            .map(item => item.schema)
            .join("|");
          params["series"] = ownProps.seriesItems
            .map(item => item.groupcd.trim())
            .join("|");
          params["statisticsType"] = [...Array(ownProps.seriesItems.length)]
            .map(() => "5")
            .join("|");
          params["area"] = ownProps.areaItems
            .map(item => item.areacode.trim())
            .join("|");
          return dispatch.insertAreaItem(params);
        default:
          if (ownProps.checkedNkcodes) {
            if (ownProps.checkedNkcodes.indexOf(",") !== -1) {
              params["corpCode"] = ownProps.checkedNkcodes.split(",").join("|");
            } else {
              params["corpCode"] = ownProps.checkedNkcodes;
            }
          } else {
            params["corpCode"] = urlParams.get("corpIndustyCode");
          }
          return dispatch.insertCorpItem(params);
      }
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(BookmarkBalloonContainer)
);
