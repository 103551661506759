import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";

const initialState = {
  period: {},
  businessData: {},
  areaData: {},
  countryData: {},
  conditions: {},
  isLoadingCountry: false,
  error: {}
};

// 予想チェックボックスが操作可能ならtrue、不可能ならfalseをかえす
const isActiveExpectation = (page, data) => {
  if (page === "business") {
    return data.corpSegmentBusiness01Dto.hasExpectationData;
  }

  if (page === "area") {
    // 海外売上高または地域ごとの情報の予想データがあればtrue
    return (
      data.corpSegmentArea01Dto.hasExpectationData ||
      data.corpSegmentArea02Dto.hasExpectationData
    );
  }

  return false;
};

export default handleActions(
  {
    [`${ActionTypes.BUSINESS_PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      businessData: action.payload,
      period: action.payload.displayPeriodDto,
      conditions: {
        from: action.payload.periodFrom,
        to: action.payload.periodTo,
        accType: action.payload.accType,
        expectation:
          action.payload.expectationFlag === "0" &&
          isActiveExpectation("business", action.payload)
      }
    }),
    [`${ActionTypes.BUSINESS_PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.BUSINESS_PAGE_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      businessData: action.payload,
      period: {
        period: action.payload.period
      },
      conditions: {
        from: action.payload.periodFrom,
        to: action.payload.periodTo,
        accType: action.payload.accType,
        expectation:
          action.payload.expectationFlag === "0" &&
          isActiveExpectation("business", action.payload)
      }
    }),
    [`${ActionTypes.BUSINESS_PAGE_CHANGE}_PENDING`]: () => initialState,
    [`${ActionTypes.BUSINESS_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      businessData: {
        ...state.businessData,
        corpSegmentBusiness01Dto: action.payload.corpSegmentBusiness01Dto,
        corpSegmentBusiness02Dto: action.payload.corpSegmentBusiness02Dto,
        ToolCreategraphDto1: action.payload.ToolCreategraphDto1,
        ToolCreategraphDto2: action.payload.ToolCreategraphDto2,
        ToolCreategraphDto3: action.payload.ToolCreategraphDto3,
        ToolCreategraphDto4: action.payload.ToolCreategraphDto4,
        itemName: action.payload.itemName,
        accType: action.payload.accType,
        periodFrom: action.payload.periodFrom1,
        periodTo: action.payload.periodTo1,
        expectationFlag: action.payload.expectationFlag
      },
      conditions: {
        from: action.payload.periodFrom,
        to: action.payload.periodTo,
        accType: action.payload.accType,
        expectation:
          action.payload.expectationFlag === "0" &&
          isActiveExpectation("business", action.payload)
      }
    }),
    [`${ActionTypes.UPDATE_BUSINESS_PERIOD_LIST}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      period: action.payload,
      conditions: {
        ...state.conditions,
        from: action.payload.periodFrom,
        to: action.payload.periodTo
      }
    }),
    [`${ActionTypes.AREA_PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      areaData: action.payload,
      period: action.payload.displayPeriodDto,
      conditions: {
        from: action.payload.periodFrom,
        to: action.payload.periodTo,
        accType: action.payload.accType,
        expectation:
          action.payload.expectationFlag === "0" &&
          isActiveExpectation("area", action.payload)
      }
    }),
    [`${ActionTypes.AREA_PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.AREA_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      period: action.payload.displayPeriodDto,
      areaData: {
        ...state.areaData,
        itemName: action.payload.itemName,
        corpSegmentArea01Dto: action.payload.corpSegmentArea01Dto,
        corpSegmentArea02Dto: action.payload.corpSegmentArea02Dto,
        corpSegmentArea03Dto: action.payload.corpSegmentArea03Dto,
        ToolCreategraphDto1: action.payload.ToolCreategraphDto1,
        ToolCreategraphDto2: action.payload.ToolCreategraphDto2,
        ToolCreategraphDto3: action.payload.ToolCreategraphDto3,
        ToolCreategraphDto4: action.payload.ToolCreategraphDto4,
        ToolCreategraphDto5: action.payload.ToolCreategraphDto5,
        ToolCreategraphDto6: action.payload.ToolCreategraphDto6,
        overseasSalesItemName: action.payload.overseasSalesItemName,
        segmentItemName: action.payload.segmentItemName,
        expectationFlag: action.payload.expectationFlag,
        accType: action.payload.accType,
        periodFrom: action.payload.periodFrom1,
        periodTo: action.payload.periodTo1
      },
      conditions: {
        from: action.payload.periodFrom,
        to: action.payload.periodTo,
        accType: action.payload.accType,
        expectation:
          action.payload.expectationFlag === "0" &&
          isActiveExpectation("area", action.payload)
      }
    }),
    [`${ActionTypes.UPDATE_AREA_PERIOD_LIST}_FULFILLED`]: (state, action) => ({
      ...state,
      period: action.payload,
      conditions: {
        ...state.conditions,
        from: action.payload.periodFrom,
        to: action.payload.periodTo
      }
    }),
    [`${ActionTypes.AREA_CORP_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      areaData: {
        ...state.areaData,
        ...action.payload
      },
      period: action.payload.displayPeriodDto,
      conditions: {
        from: action.payload.periodFrom,
        to: action.payload.periodTo,
        accType: action.payload.accType,
        expectation:
          action.payload.expectationFlag === "0" &&
          isActiveExpectation("area", action.payload)
      }
    }),
    [`${ActionTypes.COUNTRY_PAGE_INIT}_PENDING`]: state => ({
      ...state,
      isLoadingCountry: true
    }),
    [`${ActionTypes.COUNTRY_PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      countryData: action.payload,
      isLoadingCountry: false
    }),
    [`${ActionTypes.COUNTRY_PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.COUNTRY_PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingCountry: false
    }),
    [ActionTypes.SET_CONDITION]: (state, action) => ({
      ...state,
      conditions: {
        ...state.conditions,
        ...action.payload
      }
    }),
    [ActionTypes.RESET_CONDITION]: state => ({
      ...state,
      conditions: {}
    })
  },
  initialState
);

export const actionCreators = {
  initBusinessPage: createAction(ActionTypes.BUSINESS_PAGE_INIT, params =>
    request(216, params)
  ),
  updateBusinessData: createAction(ActionTypes.BUSINESS_DATA_UPDATE, params =>
    request(217, params)
  ),
  changeBusinessPage: createAction(ActionTypes.BUSINESS_PAGE_CHANGE, params =>
    request(218, params)
  ),
  updateBusinessPeriodList: createAction(
    ActionTypes.UPDATE_BUSINESS_PERIOD_LIST,
    params => request(224, params)
  ),
  initAreaPage: createAction(ActionTypes.AREA_PAGE_INIT, params =>
    request(205, params)
  ),
  updateAreaData: createAction(ActionTypes.AREA_DATA_UPDATE, params =>
    request(206, params)
  ),
  changeCorpAreaPage: createAction(ActionTypes.AREA_CORP_CHANGE, params =>
    request(207, params)
  ),
  udpateAreaPeriodList: createAction(
    ActionTypes.UPDATE_AREA_PERIOD_LIST,
    params => request(215, params)
  ),
  initCountryPage: createAction(ActionTypes.COUNTRY_PAGE_INIT, params =>
    request(342, params)
  ),
  setConditions: createAction(ActionTypes.SET_CONDITION),
  resetConditions: createAction(ActionTypes.RESET_CONDITION)
};
