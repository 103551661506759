import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

export interface TitleState {
  info: {
    country: string;
    asrf: string;
    diffflg: string;
    name: string;
    nameEn: string;
    nikkeiCode: string;
    placeMarket: string;
    stkcode: string;
    notAsrf: string;
    dis: string;
    corpIndustryPath: string;
  };
  isLoading: boolean;
}

const initialState: TitleState = {
  info: {
    country: "",
    asrf: "",
    diffflg: "",
    name: "",
    nameEn: "",
    nikkeiCode: "",
    placeMarket: "",
    stkcode: "",
    notAsrf: "",
    dis: "",
    corpIndustryPath: ""
  },
  isLoading: true
};

export default handleActions<TitleState, any>(
  {
    [ActionTypes.INFO_SET]: (state, action) => ({
      ...state,
      info: action.payload,
      isLoading: false
    }),
    [ActionTypes.INFO_RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  setInfo: createAction(ActionTypes.INFO_SET),
  resetInfo: createAction(ActionTypes.INFO_RESET)
};
