// 初期表示
export const PAGE_INIT = "industry/news/PAGE_INIT";
export const LOAD_NEWS = "industry/news/LOAD_NEWS";
export const SORT_NEWS = "industry/news/SORT_NEWS";
export const SEARCH_NEWS = "industry/news/SEARCH_NEWS";
export const LOAD_NEWS_PAPER = "industry/news/LOAD_NEWS_PAPER";
export const LOAD_NEWS_MAGAZINE = "industry/news/LOAD_NEWS_MAGAZINE";
export const LOAD_NIKKEI_PRIME = "industry/news/LOAD_NIKKEI_PRIME";

// 選択項目セット
export const SORT_OPTIONS_SET = "industry/news/SORT_OPTIONS_SET";
export const NEWS_OPTIONS_SET = "industry/news/NEWS_OPTIONS_SET";
export const NEWS_PAPER_OPTIONS_SET = "industry/news/NEWS_PAPER_OPTIONS_SET";
export const NEWS_MAGAZINE_OPTIONS_SET =
  "industry/news/NEWS_MAGAZINE_OPTIONS_SET";
export const NIKKEI_PRIME_OPTIONS_SET =
  "industry/news/NIKKEI_PRIME_OPTIONS_SET";
