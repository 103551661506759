exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-BinderPage-List-Components-PersonalBinderList-style__sortWrapper--bgMeF {\n  padding-right: 24px;\n  padding-left: 24px;\n  border-bottom: 1px solid #dbdbdb;\n}\n\n.src-features-BinderPage-List-Components-PersonalBinderList-style__collectionWrapper--2n-Aw {\n  margin: 16px 16px 60px;\n}\n.src-features-BinderPage-List-Components-PersonalBinderList-style__collectionWrapper--2n-Aw .src-features-BinderPage-List-Components-PersonalBinderList-style__card--wEmHj {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  position: relative;\n}\n.src-features-BinderPage-List-Components-PersonalBinderList-style__collectionWrapper--2n-Aw .src-features-BinderPage-List-Components-PersonalBinderList-style__list--g41z3 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  overflow-x: auto;\n  position: relative;\n}\n\n.src-features-BinderPage-List-Components-PersonalBinderList-style__component--2MuDV {\n  position: relative;\n}\n\n.src-features-BinderPage-List-Components-PersonalBinderList-style__loadingWrapper--3OOC7 {\n  height: calc(100vh - 180px);\n}\n\n.src-features-BinderPage-List-Components-PersonalBinderList-style__loadingContainer--1Y_k1 {\n  text-align: center;\n}\n.src-features-BinderPage-List-Components-PersonalBinderList-style__loadingContainer--1Y_k1 .src-features-BinderPage-List-Components-PersonalBinderList-style__loading--3PxyL {\n  width: 30px;\n  height: 30px;\n  padding: 30px;\n  animation: src-features-BinderPage-List-Components-PersonalBinderList-style__spin--3oYEF 1s linear infinite;\n}\n@keyframes src-features-BinderPage-List-Components-PersonalBinderList-style__spin--3oYEF {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"sortWrapper": "src-features-BinderPage-List-Components-PersonalBinderList-style__sortWrapper--bgMeF",
	"collectionWrapper": "src-features-BinderPage-List-Components-PersonalBinderList-style__collectionWrapper--2n-Aw",
	"card": "src-features-BinderPage-List-Components-PersonalBinderList-style__card--wEmHj",
	"list": "src-features-BinderPage-List-Components-PersonalBinderList-style__list--g41z3",
	"component": "src-features-BinderPage-List-Components-PersonalBinderList-style__component--2MuDV",
	"loadingWrapper": "src-features-BinderPage-List-Components-PersonalBinderList-style__loadingWrapper--3OOC7",
	"loadingContainer": "src-features-BinderPage-List-Components-PersonalBinderList-style__loadingContainer--1Y_k1",
	"loading": "src-features-BinderPage-List-Components-PersonalBinderList-style__loading--3PxyL",
	"spin": "src-features-BinderPage-List-Components-PersonalBinderList-style__spin--3oYEF"
};