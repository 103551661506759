import { isEmpty } from "utils/helper/common";

/**
 * 前と次のindustryParamsをそれぞれ受け取り、同じ業界かを判定する
 * @param {object} next 次の業界情報
 * @param {object} prev 前の業界情報
 * @return {bool} 同じ業界かどうか
 */
export const isSameIndustry = (
  next: { [key: string]: unknown },
  prev: { [key: string]: unknown }
) => {
  if (isEmpty(next) || isEmpty(prev)) return false;
  // 業界の判別に使うキー
  const keys = ["corpIndustyCode", "corpIndustyKbn"];
  return keys.every(key => next[key] === prev[key]);
};

/**
 * 前と次のindustryParamsをそれぞれ受け取り、IndustryPathから同じ業界かを判定する
 * @param {object} next 次の業界情報
 * @param {object} prev 前の業界情報
 * @return {bool} 同じ業界かどうか
 */
export const isSameIndustryPath = (
  next: { [key: string]: unknown },
  prev: { [key: string]: unknown }
) => {
  if (isEmpty(next) || isEmpty(prev)) return false;
  // 業界の判別に使うキー
  const keys = ["corpIndustryPath"];
  return keys.every(key => next[key] === prev[key]);
};
