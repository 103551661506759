import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  modalData: {},
  accKbnList: [],
  accKbn: "",
  expectationTypeList: [],
  expectationType: "",
  currencyKind: "",
  unitList: [],
  unit: "",
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      accKbn:
        action.payload.OverseasCorpBusinessPerformance01Dto
          .accKbnList_SelectedItem_CodeList_Value,
      unit:
        action.payload.OverseasCorpBusinessPerformance01Dto
          .unitList_SelectedItem_CodeList_Value,
      expectationType:
        action.payload.OverseasCorpBusinessPerformance01Dto
          .expectationTypeList_SelectedItem_CodeList_Value,
      currencyKind:
        action.payload.OverseasCorpBusinessPerformance01Dto.currencyKind
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.OPTION_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      optionList: action.payload
    }),
    [`${ActionTypes.OPTION_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      accKbn:
        action.payload.OverseasCorpBusinessPerformance01Dto
          .accKbnList_SelectedItem_CodeList_Value,
      unit:
        action.payload.OverseasCorpBusinessPerformance01Dto
          .unitList_SelectedItem_CodeList_Value,
      expectationType:
        action.payload.OverseasCorpBusinessPerformance01Dto
          .expectationTypeList_SelectedItem_CodeList_Value,
      currencyKind:
        action.payload.OverseasCorpBusinessPerformance01Dto.currencyKind
    }),
    [`${ActionTypes.CONDITION_CHANGE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.OPEN_MODAL}_FULFILLED`]: (state, action) => ({
      ...state,
      modalData: action.payload
    }),
    [`${ActionTypes.OPEN_MODAL}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.OPTION_LIST_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),
    [ActionTypes.CONDITION_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(492, params)),
  changeCondition: createAction(ActionTypes.CONDITION_CHANGE, params =>
    request(493, params)
  ),
  openModal: createAction(ActionTypes.OPEN_MODAL, params =>
    request(494, params)
  ),
  getOptionList: createAction(ActionTypes.OPTION_LIST_GET, params =>
    request(822, params)
  ),
  setOptionList: createAction(
    ActionTypes.OPTION_LIST_SET,
    x => x,
    (params, key) => ({ key: key })
  ),
  setCondition: createAction(
    ActionTypes.CONDITION_SET,
    x => x,
    (params, key) => ({ key: key })
  )
};
