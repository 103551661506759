import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";
import { convertToOptionsFromResponse } from "utils/helper/telecomParams";

const initialState = {
  // searchCondition
  resetKey: "0",
  keyword: "",
  rawOperand: { and: "", or: "", not: "" },
  preparedEdinet: [],
  preparedCheckboxes: [],
  checkboxes: [],
  initCheckboxes: [],
  isOpenAdvancedSearch: false,
  periodName: "all",
  kikan: "all",
  fromDate: "",
  toDate: "",
  fromDateObj: null,
  enableAllPeriod: true,
  // list
  result: {},
  initResult: {},
  pdf: {},
  xbrl: {},
  attachment: {},
  // corpmodal
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  corpListBySuggest: null,
  corpList: [],
  isLoadingCorpList: false,
  selectedCorpList: [],
  docKbnList: [],
  selectedDocKbnList: [],
  filterOptionList: [],
  error: {},
  isLoading: {
    condition: false,
    corpList: false,
    result: false
  },
  doForceSearch: false,
  submitSearchedParams: {
    body: "",
    query: "",
    rawOperand: {
      and: "",
      or: "",
      not: ""
    }
  },
  isTimelyDisclosure: false
};

// チェックボックス更新
// 資料区分を選択すると、edinetの"有価証券報告書""四半期報告書""半期報告書"以外のチェックボックスが非活性になる(現行システム参照)
const applyCheckboxesActive = (checkboxes, selectedDocKbnList) => {
  const newCheckboxes = [...checkboxes].map(parent => {
    const newParent = { ...parent };
    const docSelected = selectedDocKbnList.length !== 0;
    newParent.isActive = !(
      docSelected &&
      (newParent.id === "timely" || newParent.id === "other")
    );
    if (newParent.child) {
      newParent.child = newParent.child.map((child, index) => {
        const newChild = { ...child };
        const isEdinetDocType = newParent.id === "edinet" && index > 2;
        const isOtherType =
          newParent.id === "timely" || newParent.id === "other";
        newChild.isActive = !(docSelected && (isEdinetDocType || isOtherType));
        return newChild;
      });
    }
    return newParent;
  });
  return newCheckboxes;
};

const buildDisclosureCheckboxes = (timelyList, otherList) => {
  const timely = {
    id: "timely",
    label: "適時開示",
    checked: true,
    isActive: true,
    child: convertToOptionsFromResponse(timelyList)
  };
  const other = {
    id: "other",
    label: "その他開示資料",
    checked: false,
    isActive: true,
    child: convertToOptionsFromResponse(otherList)
  };

  return { timely, other };
};

const changeChecked = (checkboxes, preparedCheckboxes) => {
  if (preparedCheckboxes.length === 0) return checkboxes;

  return checkboxes.map(checkbox => {
    const preparedTarget = preparedCheckboxes.find(
      item => checkbox.id === item.id
    );
    if (!preparedTarget) return checkbox;

    const child = checkbox.child.map(item => ({
      ...item,
      checked: preparedTarget.child.includes(item.value)
    }));

    return {
      ...checkbox,
      child,
      checked: child.some(item => item.checked)
    };
  });
};

export default handleActions(
  {
    [`${ActionTypes.INIT_PAGE}_FULFILLED`]: (state, action) => {
      const checkboxes = [...state.checkboxes];
      const initCheckboxes = [...state.initCheckboxes];

      // 適時開示・その他開示資料のチェックボックス生成
      const { timely, other } = buildDisclosureCheckboxes(
        action.payload.timelyDisclosureList,
        action.payload.otherDisclosureList
      );

      timely.child.forEach(child => {
        (child.checked = true), (child.isActive = true);
      });

      other.child.forEach(child => {
        (child.checked = false), (child.isActive = true);
      });

      checkboxes[1] = timely;
      initCheckboxes[1] = timely;
      checkboxes[2] = other;
      initCheckboxes[2] = other;

      const changedCheckboxes = changeChecked(
        checkboxes,
        state.preparedCheckboxes
      );

      return {
        ...state,
        checkboxes: changedCheckboxes,
        initCheckboxes,
        preparedCheckboxes: initialState.preparedCheckboxes
      };
    },
    [`${ActionTypes.SEARCH}_PENDING`]: state => ({
      ...state,
      doForceSearch: initialState.doForceSearch,
      isLoading: { ...state.isLoading, result: true }
    }),
    [`${ActionTypes.SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload,
      initResult: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      },
      submitSearchedParams: {
        body: action.payload.body,
        query: action.payload.query,
        rawOperand: {
          and: action.payload.keyword,
          or: action.payload.orKeyword,
          not: action.payload.notKeyword
        },
        query: action.payload.query
      }
    }),
    [`${ActionTypes.SEARCH}_REJECTED`]: state => ({
      ...state,
      result: initialState.result,
      initResult: initialState.initResult,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.SORT}_PENDING`]: state => ({
      ...state,
      isLoading: { ...state.isLoading, result: true }
    }),
    [`${ActionTypes.SORT}_FULFILLED`]: (state, action) => ({
      ...state,
      result: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.PAGING}_FULFILLED`]: (state, action) => ({
      ...state,
      result: {
        ...action.payload,
        // ページング押下時のレスポンスに含まれる sort が不正なため（空値）
        sort: state.result.sort
      }
    }),
    [`${ActionTypes.GET_NEEDS_MST_L}_PENDING`]: state => ({
      ...state,
      needsMstL: [],
      needsMstM: [],
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstL: true
    }),
    [`${ActionTypes.GET_NEEDS_MST_L}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      needsMstM: [],
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_L}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_M}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstM: true
    }),
    [`${ActionTypes.GET_NEEDS_MST_M}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_M}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_S}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.GET_NEEDS_MST_S}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      corpList: [],
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_S}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.GET_COMPANY_BY_SUGGEST}_FULFILLED`]: (state, action) => ({
      ...state,
      corpListBySuggest: action.payload.companyList
    }),
    [ActionTypes.CLEAR_COMPANY_BY_SUGGEST]: state => ({
      ...state,
      corpListBySuggest: initialState.corpListBySuggest
    }),
    [`${ActionTypes.GET_COMPANY}_PENDING`]: state => ({
      ...state,
      corpList: [],
      isLoadingCorpList: {
        ...state.isLoading,
        corpList: true
      }
    }),
    [`${ActionTypes.GET_COMPANY}_FULFILLED`]: (state, action) => ({
      ...state,
      corpList: action.payload.companyList,
      isLoadingCorpList: {
        ...state.isLoading,
        corpList: false
      }
    }),
    [`${ActionTypes.GET_COMPANY}_REJECTED`]: state => ({
      ...state,
      isLoadingCorpList: {
        ...state.isLoading,
        corpList: false
      }
    }),
    [ActionTypes.ADD_SELECTED_CORPS]: (state, action) => {
      // サジェスト検索と企業Pickerで、表示しているリストが異なる
      const currentCorpList = isEmpty(state.corpListBySuggest)
        ? state.corpList
        : state.corpListBySuggest;
      // 現在表示されている企業リストからすでに選択済みのものを抽出
      const prevCurrentSelectedCorpList = state.selectedCorpList.filter(
        item => {
          const existCorp = currentCorpList.find(({ companyCode }) => {
            return (
              !isEmpty(item.companyCode) && companyCode === item.companyCode
            );
          });
          if (!isEmpty(existCorp)) return item;
        }
      );
      // 選択解除された企業の洗い出し
      const removalSelectedCorpList = prevCurrentSelectedCorpList.filter(
        item => {
          const isExist = action.payload.some(
            ({ companyCode }) => companyCode === item.companyCode
          );
          if (!isExist) return item;
        }
      );
      // 新規に追加される企業のみを洗い出す
      const newSelectedCorpList = action.payload.filter(item => {
        const existCorp = state.selectedCorpList.find(({ companyCode }) => {
          return !isEmpty(item.companyCode) && companyCode === item.companyCode;
        });
        if (isEmpty(existCorp)) return item;
      });
      // 選択解除された企業を除外
      const selectedCorpList = state.selectedCorpList.filter(item => {
        const isRemoval = removalSelectedCorpList.find(
          ({ companyCode }) => companyCode === item.companyCode
        );
        if (!isRemoval) return item;
      });
      return {
        ...state,
        selectedCorpList: [...selectedCorpList, ...newSelectedCorpList]
      };
    },
    [ActionTypes.REMOVE_SELECTED_CORP]: (state, action) => {
      const selectedCorpList = state.selectedCorpList.filter(
        corp => corp.companyCode != action.payload.companyCode
      );
      return {
        ...state,
        selectedCorpList: selectedCorpList
      };
    },
    [ActionTypes.REMOVE_ALL_SELECTED_CORP]: state => ({
      ...state,
      selectedCorpList: []
    }),
    [`${ActionTypes.GET_DOC_KBN_LIST}_FULFILLED`]: (state, action) => ({
      ...state,
      docKbnList: action.payload.shiryoKubunList
    }),
    [ActionTypes.CHANGE_DOC_KBN_LIST]: (state, action) => ({
      ...state,
      docKbnList: state.docKbnList.map((item, idx) => {
        if (idx == action.payload) item.isSelected = !item.isSelected;
        return item;
      })
    }),
    [ActionTypes.CHANGE_ALL_DOC_KBN_LIST]: state => {
      const isCheckAll = state.docKbnList.every(item => item.isSelected);
      const docKbnList = state.docKbnList.map(item => {
        item.isSelected = !isCheckAll;
        return item;
      });
      return {
        ...state,
        docKbnList: docKbnList
      };
    },
    [ActionTypes.ADD_SELECTED_DOC_KBNS]: (state, action) => ({
      ...state,
      checkboxes: applyCheckboxesActive(state.checkboxes, action.payload),
      selectedDocKbnList: action.payload
    }),
    [ActionTypes.REMOVE_SELECTED_DOC_KBN]: (state, action) => {
      const selectedDocKbnList = state.selectedDocKbnList.filter(
        docKbn => docKbn.inneyCode != action.payload.inneyCode
      );
      const docKbnList = state.docKbnList.map(docKbn => {
        if (docKbn.inneyCode === action.payload.inneyCode)
          docKbn.isSelected = false;
        return docKbn;
      });
      return {
        ...state,
        checkboxes: applyCheckboxesActive(state.checkboxes, selectedDocKbnList),
        docKbnList: docKbnList,
        selectedDocKbnList: selectedDocKbnList
      };
    },
    [ActionTypes.REMOVE_ALL_SELECTED_DOC_KBN]: state => ({
      ...state,
      checkboxes: applyCheckboxesActive(state.checkboxes, []),
      selectedDocKbnList: [],
      docKbnList: state.docKbnList.map(item => {
        item.isSelected = false;
        return item;
      })
    }),
    [ActionTypes.FILTER_OPTION_LIST_INIT]: (state, action) => ({
      ...state,
      filterOptionList: action.payload
    }),
    [ActionTypes.ALL_RESET]: () => initialState,
    [ActionTypes.INIT_CHECKBOXES_SET]: (state, action) => {
      const checkboxes = [...state.checkboxes];
      const initCheckboxes = [...state.initCheckboxes];
      checkboxes[0] = action.payload.checkboxes;
      initCheckboxes[0] = action.payload.initCheckboxes;

      const changedCheckboxes = changeChecked(checkboxes, state.preparedEdinet);

      return {
        ...state,
        checkboxes: changedCheckboxes,
        initCheckboxes,
        preparedEdinet: initialState.preparedEdinet
      };
    },
    [ActionTypes.CHECKBOXES_UPDATE]: (state, action) => ({
      ...state,
      checkboxes: applyCheckboxesActive(
        action.payload,
        state.selectedDocKbnList
      )
    }),
    [ActionTypes.KEYWORD_UPDATE]: (state, action) => ({
      ...state,
      keyword: action.payload
    }),
    [ActionTypes.OPERAND_RAW_UPDATE]: (state, action) => ({
      ...state,
      rawOperand: action.payload
    }),
    [ActionTypes.IS_OPEN_ADVANCED_SEARCH_UPDATE]: (state, action) => ({
      ...state,
      isOpenAdvancedSearch: action.payload
    }),
    [ActionTypes.FROM_DATE_UPDATE]: (state, action) => ({
      ...state,
      fromDate: action.payload
    }),
    [ActionTypes.TO_DATE_UPDATE]: (state, action) => ({
      ...state,
      toDate: action.payload
    }),
    [ActionTypes.ENABLE_ALL_PERIOD_SET]: (state, action) => ({
      ...state,
      enableAllPeriod: action.payload,
      periodName: action.payload,
      kikan: action.payload === "all" ? "all" : "free"
    }),
    [ActionTypes.CONDITIONS_CLEAR]: state => ({
      ...state,
      resetKey: state.resetKey === "1" ? "0" : "1",
      checkboxes: state.initCheckboxes,
      keyword: "",
      rawOperand: { and: "", or: "", not: "" },
      kikan: "all",
      periodName: "all",
      fromDate: "",
      toDate: "",
      enableAllPeriod: true
    }),
    [ActionTypes.CONDITIONS_LOADING_UPDATE]: (state, action) => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        condition: action.payload
      }
    }),
    [ActionTypes.DO_FORCE_SEARCH_SET]: (state, action) => ({
      ...state,
      doForceSearch: action.payload
    }),
    [ActionTypes.PREPARED_CHECKBOXES_SET]: (state, action) => ({
      ...state,
      preparedCheckboxes: action.payload
    }),
    [ActionTypes.PREPARED_EDINET_SET]: (state, action) => ({
      ...state,
      preparedEdinet: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initFilterOptionList: createAction(ActionTypes.FILTER_OPTION_LIST_INIT),
  setInitCheckboxes: createAction(ActionTypes.INIT_CHECKBOXES_SET),
  updateCheckboxes: createAction(ActionTypes.CHECKBOXES_UPDATE),
  updateKeyword: createAction(ActionTypes.KEYWORD_UPDATE),
  updateRawOperand: createAction(ActionTypes.OPERAND_RAW_UPDATE),
  updateAdvanceSearchOpen: createAction(
    ActionTypes.IS_OPEN_ADVANCED_SEARCH_UPDATE
  ),
  updateFromDate: createAction(ActionTypes.FROM_DATE_UPDATE),
  updateToDate: createAction(ActionTypes.TO_DATE_UPDATE),
  setEnableAllPeriod: createAction(ActionTypes.ENABLE_ALL_PERIOD_SET),
  clearConditions: createAction(ActionTypes.CONDITIONS_CLEAR),
  search: createAction(ActionTypes.SEARCH, params => request(506, params)),
  sort: createAction(ActionTypes.SORT, params => request(507, params)),
  paging: createAction(ActionTypes.PAGING, params => request(511, params)),
  getNeedsMstL: createAction(ActionTypes.GET_NEEDS_MST_L, params =>
    request(513, params)
  ),
  getNeedsMstM: createAction(ActionTypes.GET_NEEDS_MST_M, params =>
    request(514, params)
  ),
  getNeedsMstS: createAction(ActionTypes.GET_NEEDS_MST_S, params =>
    request(515, params)
  ),
  getCompanyBySuggest: createAction(
    ActionTypes.GET_COMPANY_BY_SUGGEST,
    params => request(512, params)
  ),
  clearCompanyBySuggest: createAction(ActionTypes.CLEAR_COMPANY_BY_SUGGEST),
  getCompany: createAction(ActionTypes.GET_COMPANY, params =>
    request(516, params)
  ),
  addSelectedCorps: createAction(ActionTypes.ADD_SELECTED_CORPS),
  initPage: createAction(ActionTypes.INIT_PAGE, params => request(505, params)),
  getDocKbnList: createAction(ActionTypes.GET_DOC_KBN_LIST, params =>
    request(517, params)
  ),
  changeDocKbnList: createAction(ActionTypes.CHANGE_DOC_KBN_LIST),
  changeAllDocKbnList: createAction(ActionTypes.CHANGE_ALL_DOC_KBN_LIST),
  addSelectedDocKbns: createAction(ActionTypes.ADD_SELECTED_DOC_KBNS),
  removeSelectedCorp: createAction(ActionTypes.REMOVE_SELECTED_CORP),
  removeAllSelectedCorp: createAction(ActionTypes.REMOVE_ALL_SELECTED_CORP),
  removeSelectedDocKbn: createAction(ActionTypes.REMOVE_SELECTED_DOC_KBN),
  removeAllSelectedDocKbn: createAction(
    ActionTypes.REMOVE_ALL_SELECTED_DOC_KBN
  ),
  resetAll: createAction(ActionTypes.ALL_RESET),
  updateConditionsLoading: createAction(ActionTypes.CONDITIONS_LOADING_UPDATE),
  setDoForceSearch: createAction(ActionTypes.DO_FORCE_SEARCH_SET),
  setPreparedCheckboxes: createAction(ActionTypes.PREPARED_CHECKBOXES_SET),
  setPreparedEdinet: createAction(ActionTypes.PREPARED_EDINET_SET)
};
