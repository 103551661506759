import { AuthSavedAction } from "./actions";
import { Reducer } from "redux";
import { NidAuth } from "models/Auth";
import { produce } from "immer";
import { pick } from "lodash";
import { LoginError, CollectionAuthority } from "models/Auth";

export interface AuthSavedState {
  hasChargeCode: boolean;
  chargeCode1: string;
  chargeCode2: string;
  auth: NidAuth | null;
  lastInput: {
    hasChargeCode: boolean;
    chargeCode1: string;
    chargeCode2: string;
  };
  user: {
    contractId: string;
    userId: string;
    userName: string;
    contractPlanCode: string;
    ipAuthFlg: boolean;
    tokenKey: string;
  };
  collectionAuthority: CollectionAuthority | null;
  redirectPath: string | null;
  errors: LoginError | null;
  readNoticeTitle: string[];
  downloadManagementLastViewingTime: string;
}

const initialState: AuthSavedState = {
  hasChargeCode: false,
  chargeCode1: "",
  chargeCode2: "",
  auth: null,
  lastInput: {
    hasChargeCode: false,
    chargeCode1: "",
    chargeCode2: ""
  },
  user: {
    contractId: "",
    userId: "",
    userName: "",
    contractPlanCode: "",
    ipAuthFlg: false,
    tokenKey: ""
  },
  collectionAuthority: null,
  redirectPath: null,
  errors: null,
  readNoticeTitle: [],
  downloadManagementLastViewingTime: ""
};

export const authSaved: Reducer<AuthSavedState, AuthSavedAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "auth/saved/SAVE_CHARGE_CODE":
      return {
        ...state,
        ...action.payload
      };
    case "auth/saved/CLEAR_CHARGE_CODE":
      return {
        ...state,
        hasChargeCode: initialState.hasChargeCode,
        chargeCode1: initialState.chargeCode1,
        chargeCode2: initialState.chargeCode2
      };
    case "auth/saved/SET_NID_AUTH":
      return produce(state, draft => {
        draft.auth = action.payload;
      });
    case "auth/saved/SAVE_LAST_INPUT":
      return produce(state, draft => {
        draft.lastInput = action.payload;
      });
    case "auth/saved/SET_USER_INFO":
      return produce(state, draft => {
        draft.user = {
          ...pick(action.payload, [
            "contractId",
            "userId",
            "userName",
            "contractPlanCode",
            "tokenKey"
          ]),
          ipAuthFlg: action.payload.ipAuthFlg === "true"
        };
      });
    case "auth/saved/SET_COLLECTION_AUTHORITY":
      return produce(state, draft => {
        draft.collectionAuthority = action.payload;
      });
    case "auth/saved/SET_REDIRECT_PATH":
      const blackList = ["/login", "/post-logout"];
      if (blackList.find(url => action.payload.startsWith(url)) !== undefined)
        return state;

      return produce(state, draft => {
        draft.redirectPath = action.payload;
      });
    case "auth/saved/CLEAR_REDIRECT_PATH":
      return produce(state, draft => {
        draft.redirectPath = initialState.redirectPath;
      });
    case "auth/saved/SET_ERROR":
      return produce(state, draft => {
        draft.errors = { ...state.errors, ...action.payload };
      });
    case "auth/saved/CLEAR_ERROR":
      return produce(state, draft => {
        draft.errors = initialState.errors;
      });
    case "auth/saved/CLEAR_AUTH":
      return produce(state, draft => {
        draft.auth = initialState.auth;
      });
    case "auth/saved/READ_NOTICE": {
      return produce(state, draft => {
        draft.readNoticeTitle = action.payload;
      });
    }
    case "auth/saved/DOWNLOAD_MANAGEMENT_LAST_VIEWING_TIME":
      return produce(state, draft => {
        draft.downloadManagementLastViewingTime = String(new Date());
      });
    default:
      return state;
  }
};
