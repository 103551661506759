import { SummaryAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";
import { changeKeyStatisticSeriesName } from "./helper";

export interface SeriesData {
  groupcd: string;
  orgdescj: string;
  schema: string;
  series: string;
  seriesdescj: string;
  extFlg: { [key: string]: string };
}

export interface CalendarList {
  moveUrl: string;
  seriesName: string;
  schema: string;
  series: string;
  seriesData: SeriesData;
}

export interface CalendarData {
  calenderList: CalendarList[];
  releaseDate: string;
}

export interface Stock {
  closedChangeRate: string;
  dataDate: string;
  indexName: string;
  indexValue: string;
  seq: string;
}

export interface HeaderLabel {
  dispName: string;
  outTitle: string | null;
  unit: string;
  url: string;
  seriesData: SeriesData | null;
}

export interface Graph {
  chartUrl: string;
  freq: string;
  str01: string;
}

export interface KeyStatistic {
  data1: string[][];
  dispNameList: HeaderLabel[];
}

interface SummaryState {
  data: {
    calendar: {
      dataList: CalendarData[];
    };
    graph: Graph;
    stock: {
      stockIndexQuotesDailyDetail: Stock[];
    };
    keyStatistic: KeyStatistic;
    overseasStatic?: {
      data1: string[][];
      dispNameList: HeaderLabel[];
    };
    dataType: string;
    dispId: string;
    selectedItem: string;
    areaItem: string;
    statisticalKind: string;
  };
}

const initialState: SummaryState = {
  data: {
    calendar: {
      dataList: []
    },
    graph: {
      chartUrl: "",
      freq: "",
      str01: ""
    },
    stock: {
      stockIndexQuotesDailyDetail: []
    },
    keyStatistic: {
      data1: [],
      dispNameList: []
    },
    overseasStatic: {
      data1: [],
      dispNameList: []
    },
    dataType: "",
    dispId: "",
    selectedItem: "1",
    areaItem: "00",
    statisticalKind: "11"
  }
};

export const summary: Reducer<SummaryState, SummaryAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "macro/summary/INIT_PAGE":
      return produce(state, draft => {
        const keyStatistic = changeKeyStatisticSeriesName(
          action.payload.data.macroSummary04
        );

        draft.data = {
          ...state.data,
          calendar: action.payload.data.macroSummary01,
          graph: action.payload.data.macroSummary02,
          stock: action.payload.data.macroSummary03,
          keyStatistic: keyStatistic,
          overseasStatic: action.payload.data.macroSummary05,
          dataType: action.payload.data.dataType,
          dispId: action.payload.data.dispId,
          selectedItem: action.payload.data.selectedItem,
          areaItem: action.payload.data.areaName
        };
      });
    case "macro/summary/SET_DATA":
      return produce(state, draft => {
        const keyStatistic = changeKeyStatisticSeriesName(
          action.payload.data.macroSummary04
        );

        draft.data = {
          calendar: action.payload.data.macroSummary01,
          graph: action.payload.data.macroSummary02,
          stock: action.payload.data.macroSummary03,
          keyStatistic: keyStatistic,
          overseasStatic: action.payload.data.macroSummary05,
          dataType: action.payload.data.dataType,
          dispId: action.payload.data.dispId,
          selectedItem: action.payload.data.selectedItem,
          areaItem: action.payload.data.areaItem,
          statisticalKind: action.payload.data.statisticalKind
        };
      });
    default:
      return state;
  }
};
