import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  page: {}, // ページ全体
  investments: {}, // 投資先一覧
  largeHoldings: {}, // 大量保有報告書
  largeHoldingsBody: {}, // 大量保有報告書記事データ
  body: {},
  isLoading: false
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      page: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.LARGE_HOLDING_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      largeHoldings: action.payload
    }),
    [`${ActionTypes.LARGE_HOLDING_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      largeHoldings: action.payload
    }),
    [`${ActionTypes.PAGE_SEARCH}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.PAGE_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      page: action.payload,
      isLoading: false
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(248, params)),
  downloadLargeHoldingAttach: createAction(
    ActionTypes.LARGE_HOLDING_ATTACH_DL,
    params => request(828, params)
  ),
  initLargeHolding: createAction(ActionTypes.LARGE_HOLDING_INIT, params =>
    request(257, params)
  ),
  getLargeHolding: createAction(ActionTypes.LARGE_HOLDING_GET, params =>
    request(260, params)
  ),
  searchPage: createAction(ActionTypes.PAGE_SEARCH, params =>
    request(249, params)
  )
};
