import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {}, // ページデータ
  dateList: [], // 年月選択
  selectedMonthIndex: 0, // 全体の年月選択index
  selectedUserDetailMonthIndex: 0, // 利用明細側の年月選択index
  selectedChargeCode: "",
  currentUserId: "",
  currentUserEmail: "",
  summary: {},
  usageDetail: {},
  isLoadingInit: false,
  isLoadingSummary: false,
  isLoadingUsageDetail: false
};

export default handleActions(
  {
    [`${ActionTypes.INIT_PAGE}_PENDING`]: state => ({
      ...state,
      isLoadingInit: true
    }),
    [`${ActionTypes.INIT_PAGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      dateList: action.payload.selectDate,
      selectedDate: action.payload.date,
      isLoadingInit: false
    }),
    [`${ActionTypes.SUMMARY_GET}_PENDING`]: state => ({
      ...state,
      isLoadingSummary: true
    }),
    [`${ActionTypes.SUMMARY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      summary: action.payload,
      isLoadingSummary: false
    }),
    [ActionTypes.SELECTED_MONTH_INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedMonthIndex: action.payload,
      selectedUserDetailMonthIndex: action.payload
    }),
    [ActionTypes.SELECTED_USER_DETAIL_MONTH_INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedUserDetailMonthIndex: action.payload
    }),
    [ActionTypes.SELECTED_CHARGE_CODE_UPDATE]: (state, action) => ({
      ...state,
      selectedChargeCode: action.payload
    }),
    [ActionTypes.CURRENT_USER_SET]: (state, action) => ({
      ...state,
      currentUserId: action.payload.userId,
      currentUserEmail: action.payload.email
    }),
    [`${ActionTypes.USAGE_DETAIL_GET}_PENDING`]: state => ({
      ...state,
      isLoadingUsageDetail: true
    }),
    [`${ActionTypes.USAGE_DETAIL_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      usageDetail: action.payload,
      isLoadingUsageDetail: false
    }),
    [ActionTypes.ALL_RESET]: () => ({
      ...initialState
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.INIT_PAGE, params => request(782, params)),
  getSummary: createAction(ActionTypes.SUMMARY_GET, params =>
    request(786, params)
  ),
  updateSelectedMonthIndex: createAction(
    ActionTypes.SELECTED_MONTH_INDEX_UPDATE
  ),
  updateSelectedUserDetailMonthIndex: createAction(
    ActionTypes.SELECTED_USER_DETAIL_MONTH_INDEX_UPDATE
  ),
  updateSelectedChargeCode: createAction(
    ActionTypes.SELECTED_CHARGE_CODE_UPDATE
  ),
  setCurrentUser: createAction(ActionTypes.CURRENT_USER_SET),
  getUsageDetail: createAction(ActionTypes.USAGE_DETAIL_GET, params =>
    request(787, params)
  ),
  resetAll: createAction(ActionTypes.ALL_RESET)
};
