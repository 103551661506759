// 初期表示
export const PAGE_INIT = "corp/overseas/finance/PAGE_INIT";
// 検索条件変更
export const CONDITION_CHANGE = "corp/overseas/finance/CONDITION_CHANGE";
// DisplayCondition の各パラメータ変更
export const FROM_UPDATE = "corp/overseas/finance/FROM_UPDATE";
export const TO_UPDATE = "corp/overseas/finance/TO_UPDATE";
export const DIVISION_UPDATE = "corp/overseas/finance/DIVISION_UPDATE";
export const LTM_UPDATE = "corp/overseas/finance/LTM_UPDATE";
export const EXPECT_UPDATE = "corp/overseas/finance/EXPECT_UPDATE";
export const UNIT_UPDATE = "corp/overseas/finance/UNIT_UPDATE";
export const TYPE_UPDATE = "corp/overseas/finance/TYPE_UPDATE";
