exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Heading-style__base--ofDyc {\n  margin: 0;\n  color: #21465d;\n}\n\n.src-components-Common-Heading-style__underline--2sUZ1 {\n  margin-bottom: 16px;\n  padding: 10px 0;\n  border-bottom: 1px solid #eaf1f5;\n}\n\n.src-components-Common-Heading-style__heading1---rB3C {\n  font-size: 40px;\n  font-weight: bold;\n  line-height: 40px;\n}\n\n.src-components-Common-Heading-style__heading2--3kf-f {\n  letter-spacing: -0.2px;\n  font-size: 28px;\n  font-weight: bold;\n  line-height: 1.2;\n}\n\n.src-components-Common-Heading-style__heading3--1D40M {\n  font-size: 20px;\n  font-weight: bold;\n  line-height: 1.2;\n}\n\n.src-components-Common-Heading-style__heading4--2cwqe {\n  font-size: 16px;\n  font-weight: normal;\n  line-height: 1.2;\n}\n\n.src-components-Common-Heading-style__heading5--2pejR {\n  font-size: 13px;\n  font-weight: normal;\n  line-height: 20px;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"base": "src-components-Common-Heading-style__base--ofDyc",
	"underline": "src-components-Common-Heading-style__underline--2sUZ1",
	"heading1": "src-components-Common-Heading-style__heading1---rB3C",
	"heading2": "src-components-Common-Heading-style__heading2--3kf-f",
	"heading3": "src-components-Common-Heading-style__heading3--1D40M",
	"heading4": "src-components-Common-Heading-style__heading4--2cwqe",
	"heading5": "src-components-Common-Heading-style__heading5--2pejR"
};