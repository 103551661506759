/** 事業別 */
// 初期表示
export const BUSINESS_PAGE_INIT = "corp/segment/BUSINESS_PAGE_INIT";
// 企業切り替え
export const BUSINESS_PAGE_CHANGE = "corp/segment/BUSINESS_PAGE_CHANGE";
// 科目セレクトボックス選択時
export const BUSINESS_DATA_UPDATE = "corp/segment/BUSINESS_DATA_UPDATE";
// 事業別で決算区分選択時
export const UPDATE_BUSINESS_PERIOD_LIST =
  "corp/segment/UPDATE_BUSINESS_PERIOD_LIST";

/** 地域別 */
// 初期表示
export const AREA_PAGE_INIT = "corp/segment/AREA_PAGE_INIT";
// 科目セレクトボックス選択時
export const AREA_DATA_UPDATE = "corp/segment/AREA_DATA_UPDATE";
// 事業別で決算区分選択時
export const UPDATE_AREA_PERIOD_LIST = "corp/segment/UPDATE_AREA_PERIOD_LIST";
// 企業切り替え
export const AREA_CORP_CHANGE = "corp/segment/AREA_CORP_CHANGE";

/** 国別 */
// 初期表示
export const COUNTRY_PAGE_INIT = "corp/segment/COUNTRY_PAGE_INIT";

/** 共通 */
// 期間等条件設定
export const SET_CONDITION = "corp/segment/SET_CONDITION";
// 期間等条件リセット
export const RESET_CONDITION = "corp/segment/RESET_CONDITION";
