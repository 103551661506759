import { isEmpty } from "utils/helper/common";
import {
  corpType,
  corpIndustryKbn,
  isIndustryMedium,
  isIndustrySmall,
  loadCorporationType
} from "utils/helper/url";

export const getAccessContentId = urlNum => {
  const accessContentId = {
    "25": () => "C206001",
    "28": () => "C304001",
    "29": () => "C304001",
    "59": () => "C205003",
    "60": () => "C205003",
    "65": () => "C205002",
    "66": () => "C205002",
    "73": () => "C205005",
    "83": () => "C205001",
    "93": () => "C401001",
    "94": () => "C401001",
    "95": () => "C401001",
    "116": () => "C201002",
    "342": () => (isOverseas() ? "CK202002" : "C202005"),
    "124": () => "C903001",
    "125": () => "C903001",
    "136": () => "C501001",
    "146": () => "B603001",
    "147": () => "B603001",
    "148": () => "B603001",
    "149": () => "B603001",
    "152": () => "B603001",
    "155": () => "C402001",
    "160": () => "C902001",
    "161": () => "C902001",
    "162": () => "C902001",
    "163": () => "C902001",
    "164": () => "C902001",
    "167": () => "C904001",
    "168": () => "C904001",
    "169": () => "C904001",
    "170": () => "C904001",
    "171": () => "C904001",
    "177": () => "C904001",
    "178": getCorpNewsId,
    "179": getCorpNewsId,
    "180": getCorpNewsId,
    "181": getCorpNewsId,
    "182": getCorpNewsId,
    "89": () => "CK401001",
    "90": () => "CK401001",
    "100": getOverseasFinId,
    "101": getOverseasFinId,
    "129": () => "CK501001",
    "492": () => "CK304001",
    "493": () => "CK304001",
    "225": () => "CK202001",
    "226": () => "CK202001",
    "241": () => "CK203001",
    "305": () => "CK502001",
    "306": () => "CK502001",
    "188": getCorpReportId,
    "189": getCorpReportId,
    "190": getCorpReportId,
    "195": () => "C201004",
    "205": () => "C202002",
    "206": () => "C202002",
    "207": () => "C202002",
    "216": () => "C202001",
    "217": () => "C202001",
    "218": () => "C202001",
    "231": () => "C204001",
    "232": () => "C204001",
    "248": () => "C203001",
    "249": () => "C203001",
    "264": () => "C203002",
    "265": () => "C203002",
    "270": getCorpSummaryId,
    "589": getCorpSupplychainId,
    "112": () => "CH301001",
    "113": () => "CH301001",
    "120": () => "CH201002",
    "280": () => "C502007",
    "281": () => "C502007",
    "284": () => "C502005",
    "300": () => "C502003",
    "301": () => "C502003",
    "302": () => "C502003",
    "310": () => "C502001",
    "311": () => "C502001",
    "312": () => "C502001",
    "315": getCorpWhoswhoId,
    "354": () => "B202001",
    "366": getIndustryIrId,
    "397": () => "B204001",
    "398": () => "B204001",
    "34": () => "B902001",
    "35": () => "B902001",
    "38": () => "B902001",
    "39": () => "B902001",
    "40": () => "B902001",
    "42": () => "B902001",
    "403": () => "B201001",
    "520": getReportEventId,
    "521": getReportEventId,
    "524": getReportEventId,
    "525": getReportEventId,
    "526": getReportEventId,
    "620": getComparisonlistId,
    "478": () => "E101001",
    "479": () => "E101001",
    "461": () => "E201001",
    "451": () => "E202001",
    "416": () => "E204001",
    "434": () => "E205001",
    "505": () => "N401001",
    "506": () => "N401001",
    "507": () => "N401001",
    "511": () => "N401001",
    "530": () => "N601001",
    "531": () => "N601001",
    "532": () => "N601001",
    "535": () => "N601001",
    "544": () => "N602001",
    "545": () => "N602001",
    "550": () => "N602001",
    "551": () => "N602001",
    "542": () => "N602002",
    "543": () => "N602002",
    "386": () => "N603002",
    "527": () => "N901001",
    "528": () => "N901001",
    "553": () => "N905001",
    "554": () => "N905001",
    "557": () => "N905001",
    "558": () => "N905001",
    "559": () => "N905001",
    "561": () => "N905001",
    "801": () => "T202001",
    "802": () => "T202001",
    "730": () => "T301001",
    "641": () => "T402001",
    "668": () => "T404001",
    "716": () => "T501001",
    "705": () => "T501002",
    "692": () => "T601001",
    "752": () => "T701001",
    "765": () => "U005004",
    "774": () => "U005009",
    "782": () => "U002001",
    "783": () => "U002001",
    "789": () => "U004001",
    "764": () => "U005001",
    "792": () => "U006001",
    "793": () => "U006001",
    "794": () => "U006001",
    "831": () => "T301002",
    "877": () => "T801001"
  };
  if (!accessContentId.hasOwnProperty(urlNum)) return;
  return accessContentId[urlNum]();
};

const newSearch = () => new URLSearchParams(location.search);
const isOverseas = () => newSearch().get("corpType") === corpType.overseas;

const switchCorpIndustryType = (
  listed,
  unlisted,
  report,
  tdb,
  overseas,
  industry,
  startup,
  unlistedOverseas
) => {
  const search = newSearch();
  const kbn = search.get("corpIndustyKbn");
  if (isIndustrySmall(kbn) || isIndustryMedium(kbn)) return industry;
  const type = loadCorporationType(search.get("corpType"), search.get("asrf"));

  const args = {
    listed,
    unlisted,
    report,
    tdb,
    overseas,
    startup,
    unlistedOverseas
  };
  return args[type];
};

/**
 * 国内企業/財務情報 の API リクエストパラメータに付与する基本の accessContentId を返す
 */
export const getCorpFinStaticId = () => {
  return "C30";
};

export const getCorpFinId = () => {
  const menuId = newSearch().get("menuId");
  return menuId + "01";
};

/**
 * 国内企業用の contentId を返す
 * @param {?string} contentTailId contentId の末尾に付与する数値
 *                               明細表・注記 の初回表示時は、末尾数値の判定が出来ないため渡す
 */
export const getCorpFinTabId = contentTailId => {
  const search = newSearch();
  const menuId = search.get("menuId");
  const contentId = search.get("contentId");
  const getId = () => {
    const menuIds = ["C3010", "C3020", "C3030"];
    // 海外
    if (menuId === "CK3010") return menuIds[0] + "07";
    if (menuId === "CK3020") return menuIds[2] + "06";

    if (isEmpty(contentId)) return `${menuId}${contentTailId || "01"}`;

    const contentIds = {
      corpfinreportsummary: "01",
      corpfinreportbs: "02",
      corpfinreportpl: "03",
      corpfinreportcash: "04",
      corpfinreportstock: "05",
      corpfinreportetc: "06",
      corpfinOverseas: "07",
      corpfinanalysisOverseas: "06"
    };

    if (contentIds.hasOwnProperty(contentId))
      return menuId + contentIds[contentId];
    return menuId + contentId.substr(contentId.length - 2);
  };
  return getId();
};

const getCorpNewsId = () =>
  switchCorpIndustryType(
    "C601001",
    "CH601001",
    "C601001",
    "CHD60101",
    null,
    "B601001",
    "CHK60101"
  );

const getOverseasFinId = () => newSearch().get("menuId") + "01";

const getCorpReportId = () =>
  switchCorpIndustryType(
    "C602001",
    "CH602001",
    "C602001",
    "CHD60201",
    null,
    "B602001",
    "CHK60201"
  );

const getCorpSummaryId = () =>
  switchCorpIndustryType(
    "C201001",
    "CH201001",
    "C201001",
    "CHD20101",
    "CK201001",
    null,
    "CHK2010",
    "CKU20101"
  );

const getCorpSupplychainId = () =>
  switchCorpIndustryType(
    "C201005",
    null,
    "C201005",
    null,
    "CK201002",
    null,
    null,
    "CKU20102"
  );

const getCorpWhoswhoId = () =>
  switchCorpIndustryType("C201003", "CH201003", "C201003");

const getIndustryIrId = () => {
  const kbn = newSearch().get("corpIndustyKbn");
  if (isIndustrySmall(kbn) || isIndustryMedium(kbn)) return "B203001";
  if (kbn === corpIndustryKbn.statistics) return "E203001";
};

const getReportEventId = () => {
  const kbn = newSearch().get("corpIndustyKbn");
  if (isIndustrySmall(kbn) || isIndustryMedium(kbn)) return "B901001";
  if (kbn === corpIndustryKbn.article) return "N902001";
};

const getComparisonlistId = () => {
  const kbn = newSearch().get("corpIndustyKbn");
  if (kbn === corpIndustryKbn.tool) return "T201001";
  return switchCorpIndustryType(
    "C901001",
    "CH901001",
    "C901001",
    "CHD90101",
    "CK901001"
  );
};
