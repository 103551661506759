import React, { Component } from "react";
import styles from "./style.scss";
import { isEmpty } from "utils/helper/common";
import cn from "classnames";

import Transition from "react-transition-group/Transition";

import Lottie from "components/Common/Lottie";
import loaderAnimationData from "images/animation/loader.json";

const duration = 0;

const transitionStyles = {
  entering: { opacity: 0, pointerEvents: "none" },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0, pointerEvents: "none" }
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
    x: "90",
    y: "0"
  }
};
const defaultProps = { isLarge: false, isTransparent: false };
const sectionLoading = WrappedComponent =>
  class extends Component {
    constructor() {
      super();
      this.state = { height: 0 };
      this.wrappedRef = null;
    }

    componentDidMount() {
      this.updateHeight();
    }

    componentDidUpdate() {
      this.updateHeight();
    }

    updateHeight() {
      if (isEmpty(this.wrappedRef)) return;
      // 最低値よりも下回らないように処理
      const clientHeight =
        this.wrappedRef.clientHeight > 48 ? this.wrappedRef.clientHeight : 48;
      if (this.state.height == clientHeight) return;
      this.setState({ height: clientHeight });
    }

    render() {
      // WrapperComponent の高さを loadingWrapper に当てる
      const loadingStyles = !isEmpty(this.state.height)
        ? { height: `${this.state.height}px` }
        : {};
      return (
        <div
          className={cn(styles.loadingWrapper, {
            [styles.fitParent]: this.props.fitParent
          })}
        >
          <Transition in={this.props.isLoading} timeout={duration}>
            {state => (
              <div
                className={cn(styles.loading, this.props.className, {
                  [styles.large]: this.props.isLarge,
                  [styles.transparent]: this.props.isTransparent
                })}
                style={{ ...transitionStyles[state], ...loadingStyles }}
              >
                <Lottie
                  options={defaultOptions}
                  height={this.props.isLarge ? "88px" : "48px"}
                />
              </div>
            )}
          </Transition>
          <div className={styles.wrapped} ref={el => (this.wrappedRef = el)}>
            <WrappedComponent {...this.props} />
          </div>
        </div>
      );
    }
  };
sectionLoading.defaultProps = defaultProps;
export default sectionLoading;
