// 初期表示
export const PAGE_INIT = "corp/news/PAGE_INIT";
export const LOAD_NEWS = "corp/news/LOAD_NEWS";
export const SORT_NEWS = "corp/news/SORT_NEWS";
export const SEARCH_NEWS = "corp/news/SEARCH_NEWS";
export const LOAD_NEWS_PAPER = "corp/news/LOAD_NEWS_PAPER";
export const LOAD_NEWS_MAGAZINE = "corp/news/LOAD_NEWS_MAGAZINE";
export const LOAD_NIKKEI_PRIME = "corp/news/LOAD_NIKKEI_PRIME";

// 選択項目のセット
export const SET_NEWS_ITEMS = "corp/news/SET_NEWS_ITEMS";
export const SET_NEWS_PAPER_ITEMS = "corp/news/SET_NEWS_PAPER_ITEMS";
export const SET_NEWS_MAGAZINE_ITEMS = "corp/news/SET_NEWS_MAGAZINE_ITEMS";
export const SET_SORT_OPTIONS = "corp/news/SET_SORT_OPTIONS";
export const SET_NIKKEI_PRIME_ITEMS = "corp/news/SET_NIKKEI_PRIME_ITEMS";
