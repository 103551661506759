import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { deepClone } from "utils/helper/common";

const initialState = {
  data: {},
  seriesData: {},
  irData: {},
  selectedItems: [],
  displayItems: [],
  needsMstL: {},
  needsMstM: {},
  needsMstS: {},
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  searchResult: {},
  myFolderList: [],
  dataTypeList: [],
  isSelectedIr: false,
  conditionParams: {
    termToParam: "",
    termFromParam: "",
    extFlgParam: "",
    seriesdescjParam: "",
    seriesParam: "",
    dataTypeParam: "",
    schemaParam: "",
    orgdescjParam: ""
  },
  dlParams: {
    dlxls: "",
    dlxlspath: "",
    dlcsv: "",
    dlcsvpath: "",
    dlpropriety: "",
    kigyouName: ""
  },
  shouldConditionsInitialize: false,
  irInitParameters: {},
  workDay: "",
  specialFlg: false,
  conditionChange: true,
  binderList: [],
  statisticsList: null,
  isLoading: {
    collectionList: false,
    collectionItem: false,
    keyword: false,
    itemByIndustry: false,
    result: false
  }
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      dataTypeList: action.payload.industryIRDto.dataTypeList,
      conditions: {
        ...state,
        dataTypeParam: action.payload.industryIRDto.dataTypeList[0].value
      },
      dlParams: {
        dlxls: action.payload.dlxls,
        dlxlspath: action.payload.dlxlspath,
        dlcsv: action.payload.dlcsv,
        dlcsvpath: action.payload.dlcsvpath,
        dlpropriety: action.payload.dlpropriety,
        kigyouName: action.payload.industryIR01Dto.treeDataList[0].data[0]
      },
      workDay: action.payload.industryIRDto.workDay
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: (state, action) => {
      return action.meta.inittialize
        ? {
            ...initialState,
            conditionChange: state.conditionChange
          }
        : {
            ...state,
            data: initialState.data,
            seriesData: initialState.seriesData
          };
    },
    [`${ActionTypes.SERIES_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        itemByIndustry: true
      }
    }),
    [`${ActionTypes.SERIES_DATA_GET}_REJECTED`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        itemByIndustry: false
      }
    }),
    [`${ActionTypes.SERIES_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      seriesData: action.payload,
      isLoading: {
        ...state.isLoading,
        itemByIndustry: false
      }
    }),
    [ActionTypes.SELECTED_ITEMS_ADD]: (state, action) => ({
      ...state,
      selectedItems: action.payload,
      displayItems: action.payload,
      isSelectedIr: action.payload.some(item => !item.isIndustry)
    }),
    [ActionTypes.SELECTED_ITEMS_REPLACE]: (state, action) => ({
      ...state,
      displayItems: action.payload,
      isSelectedIr: action.payload.some(item => !item.isIndustry)
    }),
    [ActionTypes.ALL_SELECTED_ITEMS_REMOVE]: state => ({
      ...state,
      selectedItems: [],
      isSelectedIr: false
    }),
    [ActionTypes.IR_DATA_CLEAR]: state => ({
      ...state,
      irData: {},
      selectedItems: [],
      displayItems: [],
      isSelectedIr: false
    }),
    [`${ActionTypes.IR_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        result: true
      }
    }),
    [`${ActionTypes.IR_DATA_GET}_REJECTED`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.IR_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      irData: action.payload,
      specialFlg: action.payload.specialFlg === "1",
      // 表示するデータ内容に合わせてstoreを更新
      selectedItems: deepClone(state.displayItems),
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [ActionTypes.CHANGE_CONDITION_STATUS_ENABLE]: (state, action) => ({
      ...state,
      conditionChange: action.payload
    }),
    [`${ActionTypes.GET_NEEDS_MST_L}_PENDING`]: state => ({
      ...state,
      needsMstL: initialState.needsMstL,
      needsMstM: initialState.needsMstM,
      needsMstS: initialState.needsMstS,
      isLoadingNeedsMstL: true
    }),
    [`${ActionTypes.GET_NEEDS_MST_L}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_L}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_M}_PENDING`]: state => ({
      ...state,
      needsMstM: initialState.needsMstM,
      needsMstS: initialState.needsMstS,
      isLoadingNeedsMstM: true
    }),
    [`${ActionTypes.GET_NEEDS_MST_M}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_M}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_S}_PENDING`]: state => ({
      ...state,
      needsMstS: initialState.needsMstS,
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.GET_NEEDS_MST_S}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.GET_NEEDS_MST_S}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.SEARCH}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        keyword: true
      }
    }),
    [`${ActionTypes.SEARCH}_REJECTED`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        keyword: false
      }
    }),
    [`${ActionTypes.SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      searchResult: action.payload,
      isLoading: {
        ...state.isLoading,
        keyword: false
      }
    }),
    [ActionTypes.CLEAR_SEARCH_RESULT]: state => ({
      ...state,
      searchResult: initialState.searchResult
    }),
    [`${ActionTypes.GET_MY_FOLDER_LIST}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        myFolderList: action.payload.industryMyFolderDataList
      };
    },
    [`${ActionTypes.GET_BINDER_LIST}_PENDING`]: state => {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          collectionList: true
        }
      };
    },
    [`${ActionTypes.GET_BINDER_LIST}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        binderList: action.payload.binderList,
        isLoading: {
          ...state.isLoading,
          collectionList: false
        }
      };
    },
    [`${ActionTypes.GET_STATISTICS}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        collectionItem: true
      }
    }),
    [`${ActionTypes.GET_STATISTICS}_REJECTED`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        collectionItem: false
      }
    }),
    [`${ActionTypes.GET_STATISTICS}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        statisticsList: action.payload.statisticsList,
        isLoading: {
          ...state.isLoading,
          collectionItem: false
        }
      };
    },
    [`${ActionTypes.DELETE_MY_FOLDER}_FULFILLED`]: state => ({
      ...state
    }),
    [`${ActionTypes.RENAME_MY_FOLDER}_FULFILLED`]: state => ({
      ...state
    }),
    [ActionTypes.CONDITION_PARAMS_SET]: (state, action) => ({
      ...state,
      conditionParams: {
        ...state.conditionParams,
        ...action.payload
      }
    }),
    [ActionTypes.SHOULD_CONDITIONS_INITIALIZE_SET]: (state, action) => ({
      ...state,
      shouldConditionsInitialize: action.payload
    }),
    [ActionTypes.INIT_PARAMETER_SET]: (state, action) => ({
      ...state,
      irInitParameters: action.payload
    }),
    [ActionTypes.ALL_DATA_RESET]: () => ({
      ...initialState
    })
  },
  initialState
);

export const actionCreators = {
  /**
   * 業界から選択
   */
  // /industry/ir
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => request(366, params),
    (_, inittialize) => ({ inittialize })
  ),
  // /industry/ir/series
  getSeriesData: createAction(ActionTypes.SERIES_DATA_GET, params =>
    request(370, params)
  ),
  addSelectedItems: createAction(ActionTypes.SELECTED_ITEMS_ADD),
  replaceSelectedItems: createAction(ActionTypes.SELECTED_ITEMS_REPLACE),
  removeAllSelectedItems: createAction(ActionTypes.ALL_SELECTED_ITEMS_REMOVE),
  clearIrData: createAction(ActionTypes.IR_DATA_CLEAR),
  // /industry/ir/industry-ir
  getIrData: createAction(ActionTypes.IR_DATA_GET, params =>
    request(368, params)
  ),
  enableChangeConditionStatus: createAction(
    ActionTypes.CHANGE_CONDITION_STATUS_ENABLE
  ),
  /**
   * 業界分類選択モーダル
   */
  // /industry/ir/corp
  getNeedsMstL: createAction(ActionTypes.GET_NEEDS_MST_L, params =>
    request(372, params)
  ),
  // /industry/ir/needs-mst-m
  getNeedsMstM: createAction(ActionTypes.GET_NEEDS_MST_M, params =>
    request(373, params)
  ),
  // /industry/ir/needs-mst-s
  getNeedsMstS: createAction(ActionTypes.GET_NEEDS_MST_S, params =>
    request(374, params)
  ),
  /**
   * キーワード検索
   */
  // /industry/ir/keyword
  search: createAction(ActionTypes.SEARCH, params => request(369, params)),
  clearSearchResult: createAction(ActionTypes.CLEAR_SEARCH_RESULT),
  /**
   * マイフォルダーから選択
   */
  // /industry/ir/my-folder
  getMyFolderList: createAction(ActionTypes.GET_MY_FOLDER_LIST, params =>
    request(378, params)
  ),
  // /industry/ir/delete-folder
  deleteMyFolder: createAction(ActionTypes.DELETE_MY_FOLDER, params =>
    request(379, params)
  ),
  // /industry/ir/update-folder
  renameMyFolder: createAction(ActionTypes.RENAME_MY_FOLDER, params =>
    request(380, params)
  ),
  /**
   * コレクション取得
   */
  getBinderList: createAction(ActionTypes.GET_BINDER_LIST, () =>
    request("/common/binder")
  ),
  getStatistics: createAction(ActionTypes.GET_STATISTICS, params =>
    request("/common/binder/list/statistics", params)
  ),
  /**
   * データ表示部
   */
  setConditionParams: createAction(ActionTypes.CONDITION_PARAMS_SET),
  setShouldConditionsInitialize: createAction(
    ActionTypes.SHOULD_CONDITIONS_INITIALIZE_SET
  ),
  // 業界概要から遷移時のリクエストパラメーター設定
  setIrInitParameters: createAction(ActionTypes.INIT_PARAMETER_SET),
  // IRデータのstoreをクリア
  resetAllData: createAction(ActionTypes.ALL_DATA_RESET)
};
