import React, { useEffect, useState } from "react";
import styles from "./style.scss";

import RadioComponent from "components/Common/Radio";

import { isEmpty } from "utils/helper/common";

interface Option {
  value: string;
  label: string;
  isActive?: boolean;
  companyName?: string;
  kabushikiCode?: string;
  companyCode?: string;
}

interface Props {
  optionList: Option[];
  isEnableDefault?: boolean;
  selectedOption: string;
  name?: string;
  className?: string;
  uncontrolled?: boolean;
  onSelected: (option: Option) => void;
}

/**
 * 汎用Select要素
 *
 * @prop {object[]} optionList - option項目の配列
 * @prop {string} selectedOption - 選択中のoption項目の文字列
 * @prop {function} onSelected - 選択時の要素を返す callback
 * @prop {string} name - input に指定する名前、ラジオボタンのグルーピングに必要
 * @prop {boolean} isActive - 活性ならtrue、非活性ならfalse
 * options = [
 *  {value: "200709", label: "2007"}
 * ]
 */

const Radio: React.FunctionComponent<Props> = ({
  optionList = [],
  selectedOption = "",
  onSelected = () => {},
  className = "",
  name = "radio",
  isEnableDefault = true,
  uncontrolled
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    optionList.findIndex(option => option.value == selectedOption)
  );

  useEffect(() => {
    let index = isEnableDefault ? 0 : null;
    if (!isEmpty(optionList) && selectedOption) {
      index = optionList.findIndex(option => option.value == selectedOption);
    }

    setSelectedIndex(index);
  }, [isEnableDefault, optionList, selectedOption]);

  const handleClick = (selectedOption: Option) => {
    const index = optionList.findIndex(
      (option: Option) =>
        option.value == selectedOption.value ||
        (!isEmpty(option.companyCode) &&
          !isEmpty(option.kabushikiCode) &&
          option.companyCode == selectedOption.companyCode &&
          option.kabushikiCode == selectedOption.kabushikiCode)
    );
    setSelectedIndex(index);
    onSelected(optionList[index]);
  };

  if (isEmpty(optionList)) return null;

  return (
    <span
      className={`${styles.component} ${className}`}
      data-testid="radio-button"
    >
      {optionList.map((option: Option, index) => {
        return (
          <RadioComponent
            key={index}
            id={`${name}_${index}`}
            name={name}
            label={option.label || option.companyName}
            value={option.value}
            className={styles.radio}
            onSelected={handleClick}
            isSelected={index == selectedIndex}
            isActive={option.isActive}
            uncontrolled={uncontrolled}
          />
        );
      })}
    </span>
  );
};

export default Radio;
