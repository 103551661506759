import { Reducer } from "redux";
import { TabAction } from "./actions";
import produce from "immer";

export interface TabState {
  selectedReportItem: string;
  selectedKeyword: string | null;
}

const initialState: TabState = {
  selectedReportItem: "1",
  selectedKeyword: null
};

export const tab: Reducer<TabState, TabAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "article/report/tab/SET_SELECTED_REPORT_ITEM":
      return produce(state, draft => {
        draft.selectedReportItem = action.payload.item;
      });
    case "article/report/tab/SET_SELECTED_KEYWORD":
      return produce(state, draft => {
        draft.selectedKeyword = action.payload.keyword;
      });
    case "article/report/tab/CLEAR_TAB":
      return produce(state, draft => {
        draft.selectedReportItem = initialState.selectedReportItem;
      });
    default:
      return state;
  }
};
