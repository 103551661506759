import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  error: {},
  newsItems: [],
  newsPaperItems: [],
  newsMagazineItems: [],
  nikkeiPrimeItems: [],
  sortOptions: [],
  selectedOptions: {
    nikkeiSort: "",
    magazineSort: "",
    nikkeiPrimeSort: "",
    genrelTabId1: "",
    articleSort: "",
    genrelTabId2: "",
    genrelTabId3: "",
    genrelTabId4: ""
  }
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.LOAD_NEWS}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        corpNewsDto: {
          ...state.data.corpNewsDto,
          nikkeiCurPage: action.payload.corpNewsDto.nikkeiCurPage,
          nikkeiEndNo: action.payload.corpNewsDto.nikkeiEndNo,
          nikkeiEndPage: action.payload.corpNewsDto.nikkeiEndPage,
          nikkeiNewsList: action.payload.corpNewsDto.nikkeiNewsList,
          nikkeiStartNo: action.payload.corpNewsDto.nikkeiStartNo,
          nikkeiStartPage: action.payload.corpNewsDto.nikkeiStartPage,
          nikkeiTotalCnt: action.payload.corpNewsDto.nikkeiTotalCnt,
          nikkeiTrId: action.payload.corpNewsDto.nikkeiTrId
        }
      }
    }),
    [`${ActionTypes.SORT_NEWS}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...action.payload,
        // 以下は使い回すため保持
        // 日経速報ニュース
        newsKey: state.data.newsKey,
        // 新聞記事
        articleKey: state.data.articleKey,
        magazineArticlesKey: state.data.magazineArticlesKey,
        nikkeiPrimeArticlesKey: state.data.nikkeiPrimeArticlesKey
      },

      selectedOptions: {
        ...state.selectedOptions,
        articleSort: action.payload.articleSort,
        nikkeiSort: action.payload.nikkeiSort,
        magazineSort: action.payload.magazineSort,
        nikkeiPrimeSort: action.payload.nikkeiPrimeSort
      }
    }),
    [`${ActionTypes.SEARCH_NEWS}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      selectedOptions: {
        ...state.selectedOptions,
        ...action.meta
      }
    }),
    [`${ActionTypes.LOAD_NEWS_PAPER}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        corpNewsDto: {
          ...state.data.corpNewsDto,
          articleCurPage: action.payload.corpNewsDto.articleCurPage,
          articleEndNo: action.payload.corpNewsDto.articleEndNo,
          articleEndPage: action.payload.corpNewsDto.articleEndPage,
          articleList: action.payload.corpNewsDto.articleList,
          articleStartNo: action.payload.corpNewsDto.articleStartNo,
          articleStartPage: action.payload.corpNewsDto.articleStartPage,
          articleTotalCnt: action.payload.corpNewsDto.articleTotalCnt,
          articleTrId: action.payload.corpNewsDto.articleTrId
        }
      }
    }),
    [`${ActionTypes.LOAD_NEWS_MAGAZINE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        corpNewsDto: {
          ...state.data.corpNewsDto,
          magazineArticlesCurPage:
            action.payload.corpNewsDto.magazineArticlesCurPage,
          magazineArticlesEndNo:
            action.payload.corpNewsDto.magazineArticlesEndNo,
          magazineArticlesEndPage:
            action.payload.corpNewsDto.magazineArticlesEndPage,
          magazineArticlesList: action.payload.corpNewsDto.magazineArticlesList,
          magazineArticlesStartNo:
            action.payload.corpNewsDto.magazineArticlesStartNo,
          magazineArticlesStartPage:
            action.payload.corpNewsDto.magazineArticlesStartPage,
          magazineArticlesTotalCnt:
            action.payload.corpNewsDto.magazineArticlesTotalCnt,
          magazineArticlesTrId: action.payload.corpNewsDto.magazineArticlesTrId
        }
      }
    }),
    [`${ActionTypes.LOAD_NIKKEI_PRIME}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        corpNewsDto: {
          ...state.data.corpNewsDto,
          nikkeiPrimeArticlesCurPage:
            action.payload.corpNewsDto.nikkeiPrimeArticlesCurPage,
          nikkeiPrimeArticlesEndNo:
            action.payload.corpNewsDto.nikkeiPrimeArticlesEndNo,
          nikkeiPrimeArticlesEndPage:
            action.payload.corpNewsDto.nikkeiPrimeArticlesEndPage,
          nikkeiPrimeArticlesList:
            action.payload.corpNewsDto.nikkeiPrimeArticlesList,
          nikkeiPrimeArticlesStartNo:
            action.payload.corpNewsDto.nikkeiPrimeArticlesStartNo,
          nikkeiPrimeArticlesStartPage:
            action.payload.corpNewsDto.nikkeiPrimeArticlesStartPage,
          nikkeiPrimeArticlesTotalCnt:
            action.payload.corpNewsDto.nikkeiPrimeArticlesTotalCnt,
          nikkeiPrimeArticlesTrId:
            action.payload.corpNewsDto.nikkeiPrimeArticlesTrId
        }
      }
    }),
    [ActionTypes.SET_NEWS_ITEMS]: (state, action) => ({
      ...state,
      newsItems: action.payload,
      selectedOptions: {
        ...state.selectedOptions,
        genrelTabId1: action.payload[0].value
      }
    }),
    [ActionTypes.SET_NEWS_PAPER_ITEMS]: (state, action) => ({
      ...state,
      newsPaperItems: action.payload,
      selectedOptions: {
        ...state.selectedOptions,
        genrelTabId2: action.payload[0].value
      }
    }),
    [ActionTypes.SET_NEWS_MAGAZINE_ITEMS]: (state, action) => ({
      ...state,
      newsMagazineItems: action.payload,
      selectedOptions: {
        ...state.selectedOptions,
        genrelTabId3: action.payload[0].value
      }
    }),
    [ActionTypes.SET_NIKKEI_PRIME_ITEMS]: (state, action) => ({
      ...state,
      nikkeiPrimeItems: action.payload,
      selectedOptions: {
        ...state.selectedOptions,
        genrelTabId4: action.payload[0].value
      }
    }),
    [ActionTypes.SET_SORT_OPTIONS]: (state, action) => ({
      ...state,
      sortOptions: action.payload,
      selectedOptions: {
        ...state.selectedOptions,
        nikkeiSort: action.payload[0].value,
        articleSort: action.payload[0].value
      }
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => request(178, params) // ニュース・新聞初期表示
  ),
  loadNews: createAction(
    ActionTypes.LOAD_NEWS,
    params => request(181, params) // ニュースページング
  ),
  sortNews: createAction(
    ActionTypes.SORT_NEWS,
    params => request(180, params) // ニュース・新聞、雑誌記事ソート
  ),
  searchNews: createAction(
    ActionTypes.SEARCH_NEWS,
    params => request(179, params), // ニュース・新聞絞り込み
    (_, meta) => meta
  ),
  loadNewsPaper: createAction(
    ActionTypes.LOAD_NEWS_PAPER,
    params => request(182, params) // 新聞ページング
  ),
  loadNewsMagazine: createAction(
    ActionTypes.LOAD_NEWS_MAGAZINE,
    params => request(872, params) // 雑誌
  ),
  loadNikkeiPrime: createAction(
    ActionTypes.LOAD_NIKKEI_PRIME,
    params => request(886, params) // NikkeiPrime
  ),
  setNewsItems: createAction(ActionTypes.SET_NEWS_ITEMS),
  setNewsPaperItems: createAction(ActionTypes.SET_NEWS_PAPER_ITEMS),
  setNewsMagazineItems: createAction(ActionTypes.SET_NEWS_MAGAZINE_ITEMS),
  setNikkeiPrimeItems: createAction(ActionTypes.SET_NIKKEI_PRIME_ITEMS),
  setSortOptions: createAction(ActionTypes.SET_SORT_OPTIONS)
};
