// 初期表示
export const PAGE_INIT = "corp/meeting/PAGE_INIT";
// 開催日選択
export const DATE_SELECT = "corp/meeting/DATE_SELECT";
// 人事情報の取得
export const PROFILE_GET = "corp/meeting/PROFILE_GET";
// 招集通知、臨時報告書の初期表示
export const NOTICE_INIT = "corp/meeting/NOTICE_INIT";
// 招集通知、臨時報告書のページ取得
export const NOTICE_GET = "corp/meeting/NOTICE_GET";
