import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useReduxState } from "hooks/useReduxState";
import { Response } from "models/api/binder/List/Article";
import { request } from "utils/apiClient";
import {
  setArticles,
  changeIsAdding,
  setArticleOffset,
  addArticles
} from "modules/binders/item/actions";
import { changeIsLoading } from "modules/binders/list/actions";

interface ResponseSave {
  saveStatus: string;
  result: string;
}
export const useArticleItem = () => {
  const articles = useReduxState(s => s.binders.item.articles);
  const isLoading = useReduxState(s => s.binders.list.isLoading);
  const binderInfo = useReduxState(s => s.binders.item.binderInfo);
  const isAdding = useReduxState(s => s.binders.item.isAdding);
  const offset = useReduxState(s => s.binders.item.articleOffset);
  const limit = useReduxState(s => s.binders.item.articleLimit);
  const dispatch = useDispatch();

  const fetchArticleList = useCallback(
    async (binderId: string) => {
      try {
        dispatch(changeIsLoading(true));
        dispatch(setArticleOffset(0));
        const response = await request<Response>("/binder/list/article", {
          binderId,
          limit,
          offset: 0
        });
        dispatch(setArticles(response.feedArticleList));
      } finally {
        dispatch(changeIsLoading(false));
      }
    },
    [dispatch, limit]
  );

  const saveArticle = (params: {
    binderId: string;
    articleId: string;
    articleKbn: string;
    mediaCode: string;
  }) => {
    return request<ResponseSave>(
      "/binder/card/saved-article/save",
      params,
      "POST"
    );
  };

  const saveGovernment = (params: {
    binderId: string;
    articleId: string;
    articleKbn: string;
    mediaCode: string;
  }) => {
    return request("/binder/card/external-page/save", params, "POST");
  };

  const addArticleItems = useCallback(
    async (binderId: string) => {
      if (offset + limit < 500) {
        dispatch(changeIsAdding(true));
        dispatch(setArticleOffset(offset + limit));
        const response = await request<Response>("/binder/list/article", {
          binderId,
          limit,
          offset: offset + limit
        });
        dispatch(addArticles(response.feedArticleList));
        dispatch(changeIsAdding(false));
      }
    },
    [dispatch, limit, offset]
  );

  return {
    articles,
    fetchArticleList,
    isLoading,
    saveArticle,
    binderInfo,
    isAdding,
    addArticleItems,
    saveGovernment
  };
};
