// 初期表示
export const PAGE_INIT = "industry/industry-index/PAGE_INIT";
// 事業セグメント比較(小分類) 取得
export const SEGMENT_CORP_GET = "industry/industry-index/SEGMENT_CORP_GET";
// 業界内指標比較表(小分類) 表示最大件数 取得
export const INDUSTRY_MAX_COUNT_GET =
  "industry/industry-index/INDUSTRY_MAX_COUNT_GET";
// 業界内指標比較表(小分類) ヘッダデータ 取得
export const INDUSTRIES_HEAD_GET =
  "industry/industry-index/INDUSTRIES_HEAD_GET";
// 業界内指標比較表(小分類) 表データ 取得
export const INDUSTRIES_DATA_GET =
  "industry/industry-index/INDUSTRIES_DATA_GET";
// 業界内指標比較表(小分類) 表データ ソート状態 取得
export const INDUSTRIES_DATA_SEARCH =
  "industry/industry-index/INDUSTRIES_DATA_SEARCH";
