import React, { useEffect, useState } from "react";
import styles from "./style.scss";
import cn from "classnames";

import { isEmpty } from "utils/helper/common";

interface Option {
  value: string;
  label: string;
}

interface Props {
  optionList: Option[];
  selectedOption: string;
  onSelected: (option: Option) => void;
  className?: string;
  uncontrolled?: boolean;
  label: string | null;
}

/**
 * 汎用Select要素
 *
 * @prop {object[]} optionList - option項目の配列
 * @property {string} selectedOption - 選択中のoption項目の文字列
 * @property {function} onSelected - 選択時の要素を返す callback
 * options = [
 *  {value: "200709", label: "2007"}
 * ]
 */

const Label: React.FunctionComponent<Props> = ({
  optionList = [],
  selectedOption = "",
  onSelected = () => {},
  className = "",
  uncontrolled = false,
  label = null
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let index = 0;

    if (!isEmpty(optionList) && selectedOption) {
      index = optionList.findIndex(option => option.value == selectedOption);
      if (index < 0) index = 0;
    }

    setSelectedIndex(index);
  }, [optionList, selectedOption]);

  const handleClick = (index: number) => {
    setSelectedIndex(index);
    onSelected(optionList[index]);
  };

  const selectedParentIndex = optionList.findIndex(
    option => option.value == selectedOption
  );

  if (isEmpty(optionList)) return null;
  const classSelectedIndex = !uncontrolled
    ? selectedIndex
    : selectedParentIndex;

  return (
    <div className={cn(styles.component, className)} data-testid="label-button">
      {label && <span className={styles.label}>{label}</span>}
      <ul>
        {optionList.map((option, index) => {
          return (
            <li key={index}>
              <span
                data-testid={`label-button_button-${index}`}
                className={`${index == classSelectedIndex && styles.active}`}
                onClick={() => handleClick(index)}
              >
                {option.label}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Label;
