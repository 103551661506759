import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  reportData: {},
  reportPdf: {},
  reportXbrl: {},
  isLoading: false
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.REPORT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      reportData: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(83, params)),
  getReport: createAction(ActionTypes.REPORT_GET, params => request(86, params))
};
