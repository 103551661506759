var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-BinderPage-Common-SearchEmpty-style__component--1BV_j {\n  width: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n}\n.src-features-BinderPage-Common-SearchEmpty-style__component--1BV_j .src-features-BinderPage-Common-SearchEmpty-style__icon--2TLrt {\n  margin-top: 84px;\n  width: 54px;\n  height: 54px;\n  background-image: url(" + escape(require("images/icon/search_navy_54.svg")) + ");\n}\n.src-features-BinderPage-Common-SearchEmpty-style__component--1BV_j .src-features-BinderPage-Common-SearchEmpty-style__label--32CV1 {\n  margin-top: 27px;\n  font-weight: 700;\n  font-size: 28px;\n  color: #141e32;\n}\n.src-features-BinderPage-Common-SearchEmpty-style__component--1BV_j .src-features-BinderPage-Common-SearchEmpty-style__description--1y72K {\n  margin-top: 39px;\n  font-weight: 400;\n  font-size: 13px;\n  color: #141e32;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-features-BinderPage-Common-SearchEmpty-style__component--1BV_j",
	"icon": "src-features-BinderPage-Common-SearchEmpty-style__icon--2TLrt",
	"label": "src-features-BinderPage-Common-SearchEmpty-style__label--32CV1",
	"description": "src-features-BinderPage-Common-SearchEmpty-style__description--1y72K"
};