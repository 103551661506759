import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  isLoading: false,
  items: [],
  sortOptions: [],
  countryOptions: [],
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.LOAD_REPORT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.SORT_REPORT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.SEARCH_REPORT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [ActionTypes.SET_ITEMS]: (state, action) => ({
      ...state,
      items: action.payload
    }),
    [ActionTypes.SET_SORT_OPTIONS]: (state, action) => ({
      ...state,
      sortOptions: action.payload
    }),
    [ActionTypes.SET_COUNTRY_OPTIONS]: (state, action) => ({
      ...state,
      countryOptions: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(188, params)),
  loadReport: createAction(
    ActionTypes.LOAD_REPORT,
    params => request(190, params) // ページング
  ),
  sortReport: createAction(
    ActionTypes.SORT_REPORT,
    params => request(189, params) // ソート
  ),
  searchReport: createAction(
    ActionTypes.SEARCH_REPORT,
    params => request(189, params) // 絞り込み
  ),
  setItems: createAction(ActionTypes.SET_ITEMS),
  setSortOptions: createAction(ActionTypes.SET_SORT_OPTIONS),
  setCountryOptions: createAction(ActionTypes.SET_COUNTRY_OPTIONS)
};
