import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

const initialState = {
  tabInfo: []
};

export default handleActions(
  {
    [ActionTypes.TAB_INFO_SET]: (state, action) => ({
      ...state,
      tabInfo: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  setTabInfo: createAction(ActionTypes.TAB_INFO_SET)
};
