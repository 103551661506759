import React from "react";
import styles from "./style.scss";

import Lottie from "components/Common/Lottie";
import downloadButtonData from "images/animation/download_btn.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: downloadButtonData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet"
  },
  size: "middle"
};

interface Props {
  type:
    | "bookmark"
    | "report"
    | "downloading"
    | "sector-report"
    | "sector-report-top"
    | "close"
    | "analysis-report";
  color?: string;
  visible?: boolean;
  className?: string;
  size?: string;
}

/**
 * 定義済みボタン
 *
 * @prop {string} type - ボタン種別
 * @prop {?string} color - 色
 * @prop {function} onClick - ボタン押下時コールバック
 */
const Button: React.FunctionComponent<Props &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type">> = ({
  type,
  color = "primary",
  visible = true,
  className = "",
  size,
  ...props
}) => {
  let style = `${className} ${styles.btn}`;
  let iconPixel = "24px";
  if (!visible) {
    style += ` ${styles.invisible}`;
  }

  if (styles.hasOwnProperty(color)) {
    style += ` ${styles[color]}`;
  }

  let iconStyle;
  let text: JSX.Element | string = "";
  switch (type) {
    case "analysis-report":
      iconStyle = styles.analysis;
      style += ` ${styles.analysis}`;
      break;
    case "bookmark":
      iconStyle = styles.bookmark;
      text = "コレクションに追加";
      style += ` ${styles.bookmark}`;
      break;
    case "report":
      iconStyle = styles.report;
      text = "レポート作成";
      break;
    case "downloading":
      iconStyle = "downloading";
      text = "レポート作成";
      break;
    case "sector-report":
      style += ` ${styles.sectorReport}`;
      iconStyle = styles.report;
      text =
        size !== "small" ? (
          <div>
            <p>詳細レポートは</p>
            <p>こちら</p>
          </div>
        ) : (
          "詳細レポートはこちら"
        );
      break;
    case "sector-report-top":
      style += ` ${styles.sectorReportTop}`;
      iconStyle = styles.topReport;
      text = "レポートDL";
      break;
    case "close":
    default:
      text = "閉じる";
  }

  if (size === "small") {
    style += ` ${styles.small}`;
    iconPixel = "20px";

    if (iconStyle !== "downloading") {
      iconStyle += ` ${styles.small}`;
    }
  }

  return (
    <button {...props} className={style}>
      {iconStyle && iconStyle === "downloading" ? (
        <span className={styles.icon}>
          <Lottie
            options={defaultOptions}
            width={iconPixel}
            height={iconPixel}
          />
        </span>
      ) : (
        <span className={`${styles.icon} ${iconStyle}`} />
      )}
      {text}
    </button>
  );
};

export default Button;
