import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  resetKey: "0",
  keyword: "",
  rawOperand: { and: "", or: "", not: "" },
  isOpenAdvancedSearch: false,
  kikan: "al",
  periodName: "al",
  updateDateTo: "",
  updateDateFrom: "",
  searchItemList: {
    fld: [],
    baitaiCheck: [],
    globalBaitaiCheck: [],
    sort: [],
    irCountry: [],
    euroCountry: [],
    allCountry: [],
    report: [],
    perPage: [
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "30", value: "30" },
      { label: "50", value: "50" },
      { label: "100", value: "100" }
    ]
  },
  searchItemSelected: {
    fld: [],
    baitaiCheck: [],
    globalBaitaiCheck: [],
    sort: "",
    irCountry: "",
    euroCountry: "",
    allCountry: "",
    report: "",
    perPage: ""
  },
  searched: {
    fld: [],
    query: "",
    rawOperand: {
      rawText: "",
      and: "",
      or: "",
      not: ""
    }
  },
  baitaiCheckNameList: [],
  data: {},
  reportList: {},
  fetchedScreenIds: [],
  globalFetchedScreenIds: [],
  tabList: {},
  error: null,
  reportSelectors: [],
  selectedReportId: "",
  isLoading: {
    parameter: false,
    result: false,
    list: false
  },
  doForceSearch: false,
  selectedItemReport: "1",
  isMoreSectorReport: false
};

export default handleActions(
  {
    [ActionTypes.KEYWORD_UPDATE]: (state, action) => ({
      ...state,
      keyword: action.payload
    }),
    [ActionTypes.OPERAND_RAW_UPDATE]: (state, action) => ({
      ...state,
      rawOperand: action.payload
    }),
    [ActionTypes.IS_OPEN_ADVANCED_SEARCH_UPDATE]: (state, action) => ({
      ...state,
      isOpenAdvancedSearch: action.payload
    }),
    [ActionTypes.ENABLE_ALL_PERIOD_SET]: (state, action) => ({
      ...state,
      periodName: action.payload,
      kikan: action.payload === "all" ? "al" : "free"
    }),
    [ActionTypes.FROM_DATE_UPDATE]: (state, action) => ({
      ...state,
      updateDateFrom: action.payload
    }),
    [ActionTypes.TO_DATE_UPDATE]: (state, action) => ({
      ...state,
      updateDateTo: action.payload
    }),
    [ActionTypes.FLD_LIST_UPDATE]: (state, action) => {
      return {
        ...state,
        searchItemSelected: {
          ...state.searchItemSelected,
          fld: action.payload.checked
            ? [...state.searchItemSelected.fld, action.payload.value]
            : state.searchItemSelected.fld.filter(
                value => value !== action.payload.value
              )
        }
      };
    },
    [ActionTypes.BAITAI_LIST_UPDATE]: (state, action) => {
      if (action.payload.isParent) {
        return {
          ...state,
          searchItemSelected: {
            ...state.searchItemSelected,
            baitaiCheck: action.payload.checked
              ? state.searchItemList.baitaiCheck.map(item => item.value)
              : []
          }
        };
      }

      const selected = action.payload.checked
        ? [...state.searchItemSelected.baitaiCheck, action.payload.value]
        : state.searchItemSelected.baitaiCheck.filter(
            value => value !== action.payload.value
          );

      return {
        ...state,
        searchItemSelected: {
          ...state.searchItemSelected,
          baitaiCheck: selected
        }
      };
    },
    [ActionTypes.GLOBAL_BAITAI_LIST_UPDATE]: (state, action) => {
      if (action.payload.isParent) {
        return {
          ...state,
          searchItemSelected: {
            ...state.searchItemSelected,
            globalBaitaiCheck: action.payload.checked
              ? state.searchItemList.globalBaitaiCheck.map(item => item.value)
              : []
          }
        };
      }

      const selected = action.payload.checked
        ? [...state.searchItemSelected.globalBaitaiCheck, action.payload.value]
        : state.searchItemSelected.globalBaitaiCheck.filter(
            value => value !== action.payload.value
          );

      return {
        ...state,
        searchItemSelected: {
          ...state.searchItemSelected,
          globalBaitaiCheck: selected
        }
      };
    },
    [ActionTypes.PARAMETER_LIST_GET]: (state, action) => {
      return {
        ...state,
        searchItemList: {
          ...state.searchItemList,
          sort: action.payload.sort,
          fld: action.payload.fld,
          baitaiCheck: action.payload.baitaiCheck,
          globalBaitaiCheck: action.payload.globalBaitaiCheck,
          irCountry: action.payload.irCountry,
          euroCountry: action.payload.euroCountry,
          allCountry: action.payload.allCountry,
          report: action.payload.report
        }
      };
    },
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      reportSelectors: action.payload.tabIds
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...initialState,
      isMoreSectorReport: state.isMoreSectorReport
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.REPORT_DATA_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      reportList: action.payload.reportResearchRankingListDto,
      isLoading: {
        ...state.isLoading,
        result: false
      },
      searchItemSelected: {
        ...state.searchItemSelected,
        sort: state.searchItemList.sort
          ? state.searchItemList.sort[0].value
          : "",
        irCountry: state.searchItemList.irCountry
          ? state.searchItemList.irCountry[0].value
          : "",
        euroCountry: state.searchItemList.euroCountry
          ? state.searchItemList.euroCountry[0].value
          : "",
        allCountry: state.searchItemList.allCountry
          ? state.searchItemList.allCountry[0].value
          : "",
        report: state.searchItemList.report
          ? state.searchItemList.report[0].value
          : "",
        perPage: action.payload.count || state.searchItemList.perPage[0].value
      },
      fetchedScreenIds: state.searchItemSelected.baitaiCheck,
      globalFetchedScreenIds: state.searchItemSelected.globalBaitaiCheck,
      searched: {
        fld: action.payload.fld.split(","),
        query: action.payload.query,
        rawOperand: {
          and: action.payload.keyword,
          or: action.payload.orKeyword,
          not: action.payload.notKeyword
        }
      }
    }),
    [`${ActionTypes.REPORT_DATA_SEARCH}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        result: true
      },
      fetchedScreenIds: initialState.fetchedScreenIds,
      globalFetchedScreenIds: initialState.globalFetchedScreenIds
    }),
    [`${ActionTypes.REPORT_DATA_SEARCH}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [ActionTypes.CONDITIONS_CLEAR]: state => ({
      ...state,
      resetKey: state.resetKey === "1" ? "0" : "1",
      keyword: "",
      rawOperand: { and: "", or: "", not: "" },
      kikan: "al",
      periodName: "al",
      updateDateTo: "",
      updateDateFrom: "",
      searchItemSelected: {
        ...state.searchItemSelected,
        fld: state.searchItemList.fld
          ? state.searchItemList.fld.map(item => item.value)
          : [],
        baitaiCheck: state.searchItemList.baitaiCheck
          ? state.searchItemList.baitaiCheck.map(item => item.value)
          : "",
        globalBaitaiCheck: state.searchItemList.globalBaitaiCheck
          ? state.searchItemList.globalBaitaiCheck.map(item => item.value)
          : ""
      }
    }),
    [ActionTypes.DO_FORCE_SEARCH_SET]: (state, action) => ({
      ...state,
      doForceSearch: action.payload
    }),
    [ActionTypes.ARTICLE_LIST_CLEAR]: state => ({
      ...state,
      reportList: {},
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.TAB_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      tabList: action.payload,
      isLoading: {
        ...state.isLoading,
        list: false
      }
    }),
    [`${ActionTypes.TAB_LIST_GET}_PENDING`]: state => ({
      ...state,
      tabList: initialState.tabList,
      isLoading: {
        ...state.isLoading,
        list: true
      }
    }),
    [`${ActionTypes.TAB_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: {
        ...state.isLoading,
        list: false
      },
      selectedReportId: action.meta
    }),
    [`${ActionTypes.GLANCE_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      reportList: action.payload.reportResearchRankingListDto,
      searchItemSelected: {
        ...state.searchItemSelected,
        sort: state.searchItemList.sort[0]
          ? state.searchItemList.sort[0].value
          : "",
        irCountry: state.searchItemList.irCountry[0]
          ? state.searchItemList.irCountry[0].value
          : "",
        euroCountry: state.searchItemList.euroCountry[0]
          ? state.searchItemList.euroCountry[0].value
          : "",
        allCountry: state.searchItemList.allCountry[0]
          ? state.searchItemList.allCountry[0].value
          : "",
        report: state.searchItemList.report[0]
          ? state.searchItemList.report[0].value
          : "",
        perPage: state.searchItemList.perPage[0].value
      },
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.GLANCE_LIST_GET}_PENDING`]: state => ({
      ...state,
      reportList: initialState.reportList,
      isLoading: {
        ...state.isLoading,
        result: true
      }
    }),
    [`${ActionTypes.GLANCE_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.REPORT_DATA_SORT}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        result: true
      }
    }),
    [`${ActionTypes.REPORT_DATA_SORT}_FULFILLED`]: (state, action) => ({
      ...state,
      reportList: action.payload.reportResearchRankingListDto,
      isLoading: {
        ...state.isLoading,
        result: false
      },
      searchItemSelected: {
        ...state.searchItemSelected,
        sort: action.meta.sort,
        perPage: action.meta.perPage
      }
    }),
    [`${ActionTypes.REPORT_DATA_SORT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [`${ActionTypes.REPORT_PAGE_GET}_PENDING`]: state => ({
      ...state
    }),
    [`${ActionTypes.REPORT_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      reportList: action.payload.reportResearchRankingListDto
    }),
    [`${ActionTypes.REPORT_PAGE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.COUNTRY_LIST_GET}_PENDING`]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        result: true
      }
    }),
    [`${ActionTypes.COUNTRY_LIST_GET}_FULFILLED`]: (state, action) => {
      const selectedCountry = {
        7: { irCountry: action.meta.country },
        8: { euroCountry: action.meta.country },
        9: { allCountry: action.meta.country }
      }[action.meta.selectedScreenId];
      const selectedReport = {
        9: { report: action.meta.report }
      }[action.meta.selectedScreenId];
      return {
        ...state,
        reportList: action.payload.reportResearchRankingListDto,
        isLoading: {
          ...state.isLoading,
          result: false
        },
        searchItemSelected: {
          ...state.searchItemSelected,
          ...selectedCountry,
          ...selectedReport
        }
      };
    },
    [`${ActionTypes.COUNTRY_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoading: {
        ...state.isLoading,
        result: false
      }
    }),
    [ActionTypes.PARAMETER_LOADING_SET]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        parameter: true
      }
    }),
    [ActionTypes.PARAMETER_LOADING_RESET]: state => ({
      ...state,
      isLoading: {
        ...state.isLoading,
        parameter: false
      }
    }),
    [ActionTypes.SET_SELECTED_ITEM_REPORT]: (state, action) => ({
      ...state,
      selectedItemReport: action.payload
    }),
    [ActionTypes.SET_IS_MORE_SECTOR_REPORT]: (state, action) => ({
      ...state,
      isMoreSectorReport: action.payload
    }),
    [ActionTypes.ALL_RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  updateKeyword: createAction(ActionTypes.KEYWORD_UPDATE),
  updateRawOperand: createAction(ActionTypes.OPERAND_RAW_UPDATE),
  updateAdvanceSearchOpen: createAction(
    ActionTypes.IS_OPEN_ADVANCED_SEARCH_UPDATE
  ),
  setEnableAllPeriod: createAction(ActionTypes.ENABLE_ALL_PERIOD_SET),
  updateFromDate: createAction(ActionTypes.FROM_DATE_UPDATE),
  updateToDate: createAction(ActionTypes.TO_DATE_UPDATE),
  updateFldList: createAction(ActionTypes.FLD_LIST_UPDATE),
  updateBaitaiList: createAction(ActionTypes.BAITAI_LIST_UPDATE),
  updateGlobalBaitaiList: createAction(ActionTypes.GLOBAL_BAITAI_LIST_UPDATE),
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(550, params)),
  searchReportData: createAction(ActionTypes.REPORT_DATA_SEARCH, params =>
    request(551, params)
  ),
  clearConditions: createAction(ActionTypes.CONDITIONS_CLEAR),
  setDoForceSearch: createAction(ActionTypes.DO_FORCE_SEARCH_SET),
  clearArticleList: createAction(ActionTypes.ARTICLE_LIST_CLEAR),
  getTabList: createAction(
    ActionTypes.TAB_LIST_GET,
    params => request(542, params),
    params => params.selectedScreenId
  ),
  getGlanceList: createAction(ActionTypes.GLANCE_LIST_GET, params =>
    request(543, params)
  ),
  sortReportData: createAction(
    ActionTypes.REPORT_DATA_SORT,
    params => request(545, params),
    params => ({ sort: params.sort, perPage: params.sortCount })
  ),
  getReportPage: createAction(ActionTypes.REPORT_PAGE_GET, params =>
    request(544, params)
  ),
  getCountryList: createAction(
    ActionTypes.COUNTRY_LIST_GET,
    params => request(871, params),
    params => ({
      country: params.country,
      report: params.report,
      selectedScreenId: params.selectedScreenId
    })
  ),
  getParameterList: createAction(ActionTypes.PARAMETER_LIST_GET),
  setParameterLoading: createAction(ActionTypes.PARAMETER_LOADING_SET),
  setSelectedItemReport: createAction(ActionTypes.SET_SELECTED_ITEM_REPORT),
  resetParameterLoading: createAction(ActionTypes.PARAMETER_LOADING_RESET),
  setIsMoreSectorReport: createAction(ActionTypes.SET_IS_MORE_SECTOR_REPORT),
  resetAll: createAction(ActionTypes.ALL_RESET)
};
