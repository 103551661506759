import React from "react";
import styles from "./style.scss";
import commonStyles from "../Common/style.scss";
import { isEmpty } from "utils/helper/common";
import { renderBody, renderHeader, renderSub } from "./Common/render";
import { trackAction } from "utils/helper/analytics";
import BookmarkBalloon from "components/Common/BookmarkBalloon";

const TrendReport = ({
  title,
  body,
  displayTime,
  kijiId,
  companyInfo,
  industryInfo,
  articleKbn,
  mediaFeeType,
  mediaName,
  wordCount,
  mediaCode
}) => {
  if (isEmpty(title) && isEmpty(body)) return null;

  // Atlas トラッキング送信（企業、業界タグクリック）
  const trackAtlasActionRelatedLinks = data => {
    const typeText = {
      corp: "企業タグ",
      industry: "業界タグ"
    };
    trackAction("trendReport", {
      eventName: `${typeText[data.type]}-${data.name}`,
      type: "link",
      id: kijiId,
      name: title,
      destination: data.url,
      industryCode: data.type === "industry" ? data.code : null,
      companyCode: data.type === "corp" ? data.code : null
    });
  };

  return (
    <div
      className={commonStyles.component}
      data-testid="Common-Popup-TrendReport"
    >
      <div className={commonStyles.main}>
        <div className={styles.bookmark}>
          {mediaFeeType === "0" && (
            <BookmarkBalloon
              itemType="article"
              size="small"
              kijiId={kijiId}
              articleKbn={articleKbn}
              mediaCode={mediaCode}
            />
          )}
        </div>
        {renderHeader(title, displayTime, mediaName, wordCount)}
        <div className={commonStyles.bodyContainer}>{renderBody(body)}</div>
      </div>
      {renderSub({
        industryInfo,
        companyInfo,
        trackAtlasAction: trackAtlasActionRelatedLinks
      })}
    </div>
  );
};

export default TrendReport;
