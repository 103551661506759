exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-PayTag-style__tag--2oMNT {\n  display: inline-block;\n  width: 32px;\n  height: 14px;\n  margin-left: 8px;\n  text-align: center;\n  color: #00578e;\n  border: 1px solid #00578e;\n  font-size: 10px;\n  line-height: 14px;\n}\n.src-components-Common-PayTag-style__tag--2oMNT.src-components-Common-PayTag-style__disabled--3ZARH {\n  color: #a7a8a9;\n  border-color: #a7a8a9;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"tag": "src-components-Common-PayTag-style__tag--2oMNT",
	"disabled": "src-components-Common-PayTag-style__disabled--3ZARH"
};