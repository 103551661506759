import { isEmpty } from "utils/helper/common";
import { request } from "utils/apiClient/base";

/**
 * 指定の画面でのテレコンパラメータ情報すべてを配列で返す
 * @param {string} path 画面のURL
 */
export default (path = location.pathname) => params[path] || [];

/**
 * 指定の画面のindex番目のテレコンパラメータ情報を返す
 * @param {integer} index テレコンパラメータ情報があるindex
 * @param {string} path 画面のURL
 */
export const getTelecomParamsInfo = (index, path = location.pathname) =>
  params[path][index];

/**
 * テレコンパラメータをAPIから取得して返す
 * @param {object} params 規定値以外にAPIに渡すパラメータ
 * @param {string} path API情報を特定するキー
 */
export const getOptionsResponses = (otherParams, path = location.pathname) => {
  const telecomInfo = params[path];
  if (!telecomInfo) return Promise.reject();

  return Promise.all(
    telecomInfo.map(info =>
      request(info.url, { ...info.params, ...otherParams })
    )
  );
};

/**
 * レスポンスの配列からセレクトボックスのoptionsに対応した配列に整形して返す
 * @param {object[]} res レスポンスのオプション配列
 * @return {object[]} セレクトボックスに使うoptionsの形式にした配列
 */
export const convertToOptionsFromResponse = res =>
  res.map(r => ({
    value: [
      r.pagerId,
      r.groupId,
      r.typeCode,
      isEmpty(r.inneyCode) ? "0" : r.inneyCode
    ].join("|"),
    label: r.displayLabel,
    mediaFeeType: r.mediaFeeType,
    baseOptFlg: r.baseOptFlg,
    samplePath: r.samplePath,
    option: r.option
  }));

// /common/telecom/parameterのURL番号
const TELECOM_PARAM_URL = "821";
// /common/code-listのURL番号
const CODE_LIST_URL = "822";
// /report/researchranking/parameterのURL番号
const REPORT_PARAM_URL = "855";

// テレコンパラメータ取得に必要なキー
const keys = ["gamenId", "groupId", "typeCode", "ignoreLastOne"];

/**
 * パラメータオブジェクトを引数とkeysから作成する
 * @param {string[]} keysに対応した値を順番に渡す
 * @return {object} テレコンパラメータ
 * {
 *   gamenId: 第1引数,
 *   groupId: 第2引数,
 *   typeCode: 第3引数,
 *   ignoreLastOne: 第4引数
 * }
 */
const createParams = (...params) => {
  const res = {};
  params.forEach((param, i) => {
    const key = keys[i];
    if (key == undefined) return;
    res[key] = param;
  });
  return res;
};

/**
 * テレコンパラメータ情報を作成して返す
 * @param {string} url URL番号
 * @param {string[]} params テレコンパラメータ配列
 * @return {object}
 */
const info = (url, ...params) => ({
  url: url,
  params: createParams(...params)
});

// 「並び順」コンボボックスのパラメータ
const SORT_PARAMS = ["ALL", "6", "9", true];

const params = {
  "/searchlist": [info(CODE_LIST_URL, "H0020", "04")],
  "/corp/risk": [info(TELECOM_PARAM_URL, ...SORT_PARAMS)],
  "/corp/csr/labor": [info(TELECOM_PARAM_URL, ...SORT_PARAMS)],
  "/corp/earning-forecast": [
    info(CODE_LIST_URL, "C3040", "03"),
    info(CODE_LIST_URL, "C3040", "04"),
    info(CODE_LIST_URL, "C3040", "02"),
    info(CODE_LIST_URL, "C3040", "01"),
    info(CODE_LIST_URL, "C3040", "07")
  ],
  "/corp/disclosure": [
    info(TELECOM_PARAM_URL, ...SORT_PARAMS),
    info(TELECOM_PARAM_URL, "C4010", "3", "1")
  ],
  "/corp/index": [info(TELECOM_PARAM_URL, "C5010", "3", "6")],
  "/corp/news": [
    info(TELECOM_PARAM_URL, ...SORT_PARAMS),
    info(TELECOM_PARAM_URL, "C6010", "1", "6"),
    info(TELECOM_PARAM_URL, "C6010", "2", "6"),
    info(TELECOM_PARAM_URL, "C6010", "3", "6"),
    info(TELECOM_PARAM_URL, "C6010", "4", "6")
  ],
  "/corp/report": [
    info(TELECOM_PARAM_URL, ...SORT_PARAMS),
    info(TELECOM_PARAM_URL, "ALL", "4", "3")
  ],
  "/corp/ma-strategy": [
    info(CODE_LIST_URL, "N9050", "08"),
    info(CODE_LIST_URL, "N9050", "09")
  ],
  "/corp/graph": [
    info(CODE_LIST_URL, "C9030", "01"),
    info(CODE_LIST_URL, "C3010", "03"),
    info(CODE_LIST_URL, "C3010", "02")
  ],
  "/industry/news": [
    info(TELECOM_PARAM_URL, ...SORT_PARAMS),
    info(TELECOM_PARAM_URL, "C6010", "1", "6"),
    info(TELECOM_PARAM_URL, "C6010", "6", "6"),
    info(TELECOM_PARAM_URL, "C6010", "3", "6"),
    info(TELECOM_PARAM_URL, "C6010", "4", "6")
  ],
  "/industry/report": [
    info(TELECOM_PARAM_URL, ...SORT_PARAMS),
    info(TELECOM_PARAM_URL, "N6020", "4", "2"),
    info(CODE_LIST_URL, "N6030", "01")
  ],
  "/industry/research-report": [
    info(CODE_LIST_URL, "N6030", "01"),
    info(TELECOM_PARAM_URL, "N6030", "2", "1")
  ],
  "/industry/event": [info(CODE_LIST_URL, "N9020", "01")],
  "/industry/strategy": [
    info(CODE_LIST_URL, "N9050", "06"),
    info(CODE_LIST_URL, "N9050", "02"),
    info(CODE_LIST_URL, "N9050", "05"),
    info(CODE_LIST_URL, "N9050", "01"),
    info(CODE_LIST_URL, "N9050", "01"),
    info(CODE_LIST_URL, "N9050", "08"),
    info(CODE_LIST_URL, "N9050", "09")
  ],
  "/article/news": [
    info(TELECOM_PARAM_URL, "ALL", "1", "2"),
    info(TELECOM_PARAM_URL, "ALL", "2", "2"),
    info(TELECOM_PARAM_URL, "ALL", "9", "2"),
    info(TELECOM_PARAM_URL, "ALL", "10", "2"),
    info(TELECOM_PARAM_URL, "ALL", "11", "2"),
    info(TELECOM_PARAM_URL, ...SORT_PARAMS)
  ],
  "/article/disclosure": [
    info(TELECOM_PARAM_URL, ...SORT_PARAMS),
    info(TELECOM_PARAM_URL, "ALL", "3", "1")
  ],
  "/article/report": [
    info(TELECOM_PARAM_URL, ...SORT_PARAMS),
    info(TELECOM_PARAM_URL, "ALL", "6", "5"),
    info(REPORT_PARAM_URL, "N6020", "7", "3"),
    info(REPORT_PARAM_URL, "N6020", "8", "3"),
    info(CODE_LIST_URL, "N6020", "01"),
    info(CODE_LIST_URL, "N6020", "02"),
    info(CODE_LIST_URL, "N6020", "03"),
    info(CODE_LIST_URL, "N6020", "04")
  ],
  "/article/event": [info(CODE_LIST_URL, "N9020", "01")],
  createGraph: [
    info(CODE_LIST_URL, "T4020", "30"),
    info(CODE_LIST_URL, "T4020", "30"),
    info(CODE_LIST_URL, "T4020", "30"),
    info(CODE_LIST_URL, "T4020", "70"),
    info(CODE_LIST_URL, "T4020", "40"),
    info(CODE_LIST_URL, "T4020", "40"),
    info(CODE_LIST_URL, "T4020", "80")
  ]
};
