import React, { useEffect, useRef } from "react";
import LottieWeb from "lottie-web";

interface Props {
  height?: string;
  width?: string;
  options: object;
}

/**
 * Lottie
 */
const Lottie: React.FunctionComponent<Props> = ({
  height = "",
  width = "",
  options
}) => {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const defaultOptions = {
      ...options,
      container: animationContainer.current
    };

    LottieWeb.loadAnimation(defaultOptions as any);
  }, [options]);

  return (
    <div ref={animationContainer} style={{ height: height, width: width }} />
  );
};

export default Lottie;
