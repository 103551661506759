import { ExcelAddInAction } from "./actions";
import { AddInInstaller, AddInTemplateGroup } from "models/ExcelAddIn";
import { Reducer } from "redux";
import produce from "immer";

export interface ExcelAddInState {
  addInInstaller: AddInInstaller | null;
  templateGroups: AddInTemplateGroup[];
}

const initialState: ExcelAddInState = {
  addInInstaller: null,
  templateGroups: []
};

export const toolExcelAddIn: Reducer<ExcelAddInState, ExcelAddInAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "tool/excelAddIn/SET_TEMPLATE_GROUPS":
      return produce(state, draft => {
        draft.templateGroups = action.payload;
      });
    case "tool/excelAddIn/SET_ADD_IN_INSTALLER":
      return produce(state, draft => {
        draft.addInInstaller = action.payload;
      });
    default:
      return state;
  }
};
