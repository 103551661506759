exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-BinderPage-List-style__component--2t6q6 {\n  width: 100%;\n}\n\n.src-features-BinderPage-List-style__title--2VEnp {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  padding: 16px;\n  border-bottom: 1px solid #dbdbdb;\n}\n\n.src-features-BinderPage-List-style__buttonWrapper--2qoXU {\n  display: -ms-flexbox;\n  display: flex;\n}\n.src-features-BinderPage-List-style__buttonWrapper--2qoXU > * {\n  margin-right: 16px;\n}\n.src-features-BinderPage-List-style__buttonWrapper--2qoXU > *:last-child {\n  margin-right: 0;\n}\n\n.src-features-BinderPage-List-style__tabContent--2skfg {\n  padding-top: 16px;\n  border-top: 1px solid #eaf1f5;\n}\n\n.src-features-BinderPage-List-style__content--3W4_Z {\n  margin-top: 17px;\n}\n\n.src-features-BinderPage-List-style__new--2i_EU {\n  width: 170px;\n}\n\n.src-features-BinderPage-List-style__btn--10eCE {\n  padding: 0 20px;\n}\n\n.src-features-BinderPage-List-style__additem--2SZpb {\n  margin: 30px 34px 0;\n}\n.src-features-BinderPage-List-style__additem--2SZpb input {\n  height: 52px;\n}\n\n.src-features-BinderPage-List-style__label--qhVqo {\n  margin: 8px 0;\n  font-weight: bold;\n}\n\n.src-features-BinderPage-List-style__group--R02Eu {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: row;\n      flex-direction: row;\n  margin-top: 16px;\n}\n.src-features-BinderPage-List-style__group--R02Eu button {\n  display: block;\n  width: 98px;\n  text-align: left;\n  color: #00578e;\n  background: none;\n}\n\n.src-features-BinderPage-List-style__additem--2SZpb:first-child {\n  margin-top: 20px;\n}\n\n.src-features-BinderPage-List-style__additem--2SZpb:last-child {\n  margin-top: 44px;\n}\n\n.src-features-BinderPage-List-style__modal--1hKUh {\n  width: auto;\n  min-width: inherit;\n  height: auto;\n  max-height: calc(100% - 100px);\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-features-BinderPage-List-style__component--2t6q6",
	"title": "src-features-BinderPage-List-style__title--2VEnp",
	"buttonWrapper": "src-features-BinderPage-List-style__buttonWrapper--2qoXU",
	"tabContent": "src-features-BinderPage-List-style__tabContent--2skfg",
	"content": "src-features-BinderPage-List-style__content--3W4_Z",
	"new": "src-features-BinderPage-List-style__new--2i_EU",
	"btn": "src-features-BinderPage-List-style__btn--10eCE",
	"additem": "src-features-BinderPage-List-style__additem--2SZpb",
	"label": "src-features-BinderPage-List-style__label--qhVqo",
	"group": "src-features-BinderPage-List-style__group--R02Eu",
	"modal": "src-features-BinderPage-List-style__modal--1hKUh"
};