// モーダルを管理stackに登録
export const registerModal = (modalId: string) => ({
  type: "modal/MODAL_REGISTER" as const,
  payload: modalId
});

// モーダルを管理stackから削除
export const releaseModal = () => ({
  type: "modal/MODAL_RELEASE" as const
});

// モーダルを管理stackを初期化
export const resetModalStack = () => ({
  type: "modal/MODAL_STACK_RESET" as const
});

export const actionCreators = {
  registerModal,
  releaseModal,
  resetModalStack
};

export type ModalAction = ReturnType<
  typeof registerModal | typeof releaseModal | typeof resetModalStack
>;
