import React from "react";
import styles from "./style.scss";
import cn from "classnames";
import { RawInput } from "features/RawInput";
import { RawOperand } from "models/GlobalSearch";

export interface Props {
  onEnter?: (event: React.KeyboardEvent<Element>) => void;
  onChangeRawOperand?: (rawOperand: RawOperand) => void;
  rawOperand: RawOperand;
  historyMode: boolean;
  selected?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const QueryBuilder: React.FunctionComponent<Props> = ({
  onEnter,
  onChangeRawOperand,
  rawOperand,
  historyMode,
  selected,
  onClick,
  disabled = false
}) => {
  return (
    <div
      className={cn({
        [styles.queryBuilder]: !historyMode,
        [styles.historyQueryBuilder]: historyMode,
        [styles.selected]: selected
      })}
      onClick={onClick}
      data-testid="FormulaSearch-QueryBuilder"
    >
      <div className={styles.container}>
        <div className={cn(styles.row)} data-testid="QueryBuilder_and">
          すべてを含む
          <RawInput
            className={styles.operandRawInput}
            rawText={rawOperand.and}
            onChangeRawText={(rawText: string) => {
              onChangeRawOperand &&
                onChangeRawOperand({
                  ...rawOperand,
                  and: rawText
                });
            }}
            onKeyPress={onEnter}
            disabled={disabled}
          />
        </div>
        <div className={cn(styles.row)} data-testid="QueryBuilder_or">
          いずれかを含む
          <RawInput
            className={styles.operandRawInput}
            rawText={rawOperand.or}
            onChangeRawText={(rawText: string) => {
              onChangeRawOperand &&
                onChangeRawOperand({
                  ...rawOperand,
                  or: rawText
                });
            }}
            onKeyPress={onEnter}
            disabled={disabled}
          />
        </div>
        <div className={cn(styles.row)} data-testid="QueryBuilder_not">
          含まない
          <RawInput
            className={styles.operandRawInput}
            rawText={rawOperand.not}
            onChangeRawText={(rawText: string) => {
              onChangeRawOperand &&
                onChangeRawOperand({
                  ...rawOperand,
                  not: rawText
                });
            }}
            onKeyPress={onEnter}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
