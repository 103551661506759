// 初期表示
export const PAGE_INIT = "corp/valuation/investment-index/PAGE_INIT";
// 表示条件変更
export const PAGE_UPDATE = "corp/valuation/investment-index/PAGE_UPDATE";
// 表示条件変更
export const PAGE_CHANGE = "corp/valuation/investment-index/PAGE_CHANGE";
// 表示条件保存
export const SASVE_CONDITONS =
  "corp/valuation/investment-index/SASVE_CONDITONS";
// 子ツリーの開閉トグル
export const TOGGLE = "corp/valuation/value/TOGGLE";
