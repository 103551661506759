// トーストメッセージ設定
export const setMessages = (messages: string | string[]) => ({
  type: "toast/MESSAGES_SET" as const,
  payload: messages
});

// トーストメッセージクリア
export const clearMessages = () => ({
  type: "toast/MESSAGES_CLEAR" as const
});

// トーストエラー設定
export const setErrors = (errors: string | string[]) => ({
  type: "toast/ERRORS_SET" as const,
  payload: errors
});

// トーストエラークリア
export const clearErrors = () => ({
  type: "toast/ERRORS_CLEAR" as const
});

export const actionCreators = {
  setMessages,
  clearMessages,
  setErrors,
  clearErrors
};

export type ToastAction = ReturnType<
  | typeof setMessages
  | typeof clearMessages
  | typeof setErrors
  | typeof clearErrors
>;
