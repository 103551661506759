export const setSelectedReportItem = (item: string) => ({
  type: "article/report/tab/SET_SELECTED_REPORT_ITEM" as const,
  payload: { item }
});

export const setSelectedKeyword = (keyword: string | null) => ({
  type: "article/report/tab/SET_SELECTED_KEYWORD" as const,
  payload: { keyword }
});

export const clearTab = () => ({
  type: "article/report/tab/CLEAR_TAB" as const
});

export type TabAction = ReturnType<
  typeof setSelectedReportItem | typeof setSelectedKeyword | typeof clearTab
>;
