import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

const initialState = {
  home: {
    menuOpenFlag: true // 目的別メニューの開閉状態
  },
  industry: {
    // 業界ページ
    isOpenedSectorReportInfoModal: false // 業界小で表示する新業界レポート追加情報モーダルの表示済み状態を設定
  },
  search: {
    isRenderingBubbleChartAssociatedArticles: true, // インサイトタブのバブルチャート関連記事を表示するか
    isRenderingTreeMapAssociatedArticles: true // インサイトタブのツリーマップ関連記事を表示するか
  },
  tab: {} // 保存したタブのキー、値リスト
};

export default handleActions(
  {
    [ActionTypes.HOME_MENU_OPEN_FLAG_SET]: (state, action) => ({
      ...state,
      home: {
        ...state.home,
        menuOpenFlag: action.payload
      }
    }),
    [ActionTypes.INDUSTRY_SEGMENT_REPORT_INFO_MODAL_OPENED]: state => ({
      ...state,
      industry: {
        ...state.industry,
        isOpenedSectorReportInfoModal: true
      }
    }),
    [ActionTypes.SEARCH_INSIGHT_BUBBLE_RENDERING_ARTICLE_TOGGLE]: state => ({
      ...state,
      search: {
        ...state.search,
        isRenderingBubbleChartAssociatedArticles: !state.search
          .isRenderingBubbleChartAssociatedArticles
      }
    }),
    [ActionTypes.SEARCH_INSIGHT_TREE_RENDERING_ARTICLE_TOGGLE]: state => ({
      ...state,
      search: {
        ...state.search,
        isRenderingTreeMapAssociatedArticles: !state.search
          .isRenderingTreeMapAssociatedArticles
      }
    }),
    [ActionTypes.TAB_STATE_SAVE]: (state, action) => ({
      ...state,
      tab: { ...state.tab, ...action.payload }
    })
  },
  initialState
);

export const actionCreators = {
  setHomeMenuOpenFlag: createAction(ActionTypes.HOME_MENU_OPEN_FLAG_SET),
  setIndustrySectorReportInfoModalOpened: createAction(
    ActionTypes.INDUSTRY_SEGMENT_REPORT_INFO_MODAL_OPENED
  ),
  toggleSearchBubbleChartRenderingArticle: createAction(
    ActionTypes.SEARCH_INSIGHT_BUBBLE_RENDERING_ARTICLE_TOGGLE
  ),
  toggleSearchTreeMapRenderingArticle: createAction(
    ActionTypes.SEARCH_INSIGHT_TREE_RENDERING_ARTICLE_TOGGLE
  ),
  saveTabState: createAction(ActionTypes.TAB_STATE_SAVE)
};
