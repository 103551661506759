import { KeyStatistic } from "./index";

// グラフ表示項目の名前を変更する
export const changeKeyStatisticSeriesName = (
  statistic: KeyStatistic
): KeyStatistic => {
  return {
    ...statistic,
    dispNameList: statistic.dispNameList.map(data => ({
      ...data,
      seriesData: data.seriesData
        ? { ...data.seriesData, seriesdescj: data.dispName }
        : null
    }))
  };
};
