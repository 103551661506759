import { isIE } from "./common";

const set = (key: string, data: object | string) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    if (!isIE()) throw e;
    sessionStorage.setItem(key, JSON.stringify(data));
  }
};

const get = (key: string) => {
  try {
    const json: string | null = localStorage.getItem(key);
    if (!json) return null;
    return JSON.parse(json);
  } catch (_) {
    if (!isIE()) return null;
    const item = sessionStorage && sessionStorage.getItem(key);
    if (!item) return null;
    try {
      return JSON.parse(item);
    } catch (e) {
      return null;
    }
  }
};

const remove = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    if (!isIE()) throw e;
    sessionStorage.removeItem(key);
  }
};

/**
 * Popup の本文設定
 */
export const setPopupBody = (data: string) => set("popupBody", data);

/**
 * Popup の本文取得
 */
export const getPopupBody = () => get("popupBody");

/**
 * Popup のタイトル設定
 */
export const setPopupTitle = (data: string) => set("popupTitle", data);

/**
 * Popup のタイトル取得
 */
export const getPopupTitle = () => get("popupTitle");

/**
 * 認証トークン・ユーザーIDの設定
 */
export const setTokenAndId = (token: string, id: string, contractId: string) =>
  set("token", { token, id, contractId });

/**
 * 認証トークン・ユーザーIDの取得
 */
export const getTokenAndId = () => {
  const data = get("token");
  return data === null ? { token: null, id: null, contractId: null } : data;
};

/**
 * 認証トークン・ユーザーIDの削除
 */
export const removeTokenAndId = () => remove("token");

/**
 * PDFダウンロードパラメーター設定
 */
export const setPdfParams = (
  urlNumber: number,
  params: object,
  keywords: string[]
) => {
  const data = { urlNumber, params, keywords };
  set("pdfDownload", data);
};

/**
 * PDFダウンロードパラメーター取得
 */
export const getPdfParams = () => {
  const data = get("pdfDownload");
  return data === null ? { urlNumber: null, params: null } : data;
};

/**
 * トークン認証 Rtoaster ID の設定
 */

export const setRtoasterId = (id: string) => {
  set("RtoasterId", id);
};

/**
 * トークン認証 Rtoaster ID の取得
 */

export const getRtoasterId = () => {
  return get("RtoasterId");
};

type AuthMethod = "NID" | "IP" | "Token";

/**
 * 認証方法の設定
 */
export const setAuthMethod = (value: AuthMethod) => {
  set("authMethod", value);
};

/**
 * 認証方法の取得
 */
export const getAuthMethod = (): AuthMethod => {
  return get("authMethod");
};

/**
 * メールアドレスの設定
 */
export const setMailAddress = (mailAddress: string) =>
  set("mailAddress", mailAddress);

/**
 * メールアドレスの取得
 */
export const getMailAddress = () => {
  return get("mailAddress");
};

/**
 * メールアドレスの削除
 */
export const removeMailAddress = () => remove("mailAddress");
