import { useMemo } from "react";
import { useLocation } from "react-router";

const MOBILE_PAGES = [
  /\/binder\/\d+\/article/,
  /\/popup/,
  /\/disclaimer/,
  /\/policy/,
  /\/rights/,
  /\/login/
];

export const useMobilePage = () => {
  const { pathname } = useLocation();
  return useMemo(() => MOBILE_PAGES.some(page => page.test(pathname)), [
    pathname
  ]);
};
