import React from "react";

import styles from "./style.scss";

import Modal from "components/Common/Modal/Minimum";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DetailModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      header="オプションでご利用いただけます"
      onClose={onClose}
    >
      <div className={styles.content}>
        <div>
          このコンテンツは以下のオプションのご契約、及び利用者登録でご利用いただけます
        </div>
        <br />
        <br />
        <div className={styles.bold}>
          ・日経バリューサーチ 海外企業情報プレミアム
        </div>
      </div>
    </Modal>
  );
};

export default DetailModal;
