import React, { useState, useEffect } from "react";
import styles from "./style.scss";
import TextInput from "components/Common/TextInput";
import Checkbox from "components/Common/Checkbox";
import {
  BinderActionType,
  BinderInfo,
  Media as MediaType,
  Complements,
  ComplementsError
} from "models/BinderItem";
import Button from "components/Common/Button/Text";
import cn from "classnames";
import { BinderRequestParams } from "features/BinderPage/Common/BinderActionModal/hook";
import { useModalUtil } from "hooks/useModalUtil";
import { FormulaSearch } from "features/FormulaSearch";
import { request } from "utils/apiClient";
import { validateKeywords } from "features/FormulaSearch/handlers";
import Radio from "components/Common/Radio";
import { useReduxState } from "hooks/useReduxState";
import { ToolTip } from "features/ToolTip";

import openIcon from "images/icon/accordion_open.svg";
import closeIcon from "images/icon/accordion_close.svg";
import { trackAction } from "utils/helper/analytics";
import PayTag from "components/Common/PayTag";
import { RawOperand } from "models/GlobalSearch";
import { convertSearchKeyword } from "utils/helper/searchTag";
import { isEmpty } from "utils/helper/common";

const mediaTypes = ["新聞", "雑誌", "NIKKEI Prime", "英文記事", "その他"];

interface Props {
  menuType: BinderActionType;
  binderInfo: BinderInfo | undefined;
  onAction: (params: BinderRequestParams) => void;
  handleSave?: (params: BinderRequestParams) => void;
  isSaveLoading?: boolean;
  error: {
    caption: string;
    description: string;
    other: string;
    definition: string;
    background: string;
    task: string;
    technology: string;
    keywords: string;
    topics: string;
    regulation: string;
  };
  setIsOpenHelp: (isOpen: boolean) => void;
  setIsOpenDetailModal: (isOpen: boolean) => void;
}

export const BinderForm: React.FunctionComponent<Props> = props => {
  const authority = useReduxState(
    state => state.auth.saved.collectionAuthority
  );
  const advancedMode = useReduxState(
    state => state.operandRawSearch.saved.advancedMode
  );

  const [sharedFlg, setSharedFlg] = useState(false);
  const [publishedFlg, setPublishedFlg] = useState(false);
  const [keywordFeedFlg, setKeywordFeedFlg] = useState(true);
  const [searchType, setSearchType] = useState("0");
  const [companyFeedFlg, setCompanyFeedFlg] = useState(true);
  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [definition, setDefinition] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [definitionError, setDefinitionError] = useState<boolean>(false);
  const [background, setBackground] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [backgroundError, setBackgroundError] = useState<boolean>(false);
  const [task, setTask] = useState<Complements[]>([{ text: "", label: "" }]);
  const [taskError, setTaskError] = useState<boolean>(false);
  const [technology, setTechnology] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [technologyError, setTechnologyError] = useState<ComplementsError[]>(
    []
  );
  const [topics, setTopics] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [topicsError, setTopicsError] = useState<ComplementsError[]>([]);
  const [regulation, setRegulation] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [regulationError, setRegulationError] = useState<ComplementsError[]>(
    []
  );
  const [keyword, setKeyword] = useState<string>("");

  const [rawOperand, setRawOperand] = useState<RawOperand>({
    and: props.binderInfo?.andKeyword || "",
    or: props.binderInfo?.orKeyword || "",
    not: props.binderInfo?.notKeyword || ""
  });
  const [mediaList, setMediaList] = useState<MediaType[]>([]);

  const [openEditComplement, setOpenEditComplement] = useState(false);

  const { removeKeyEventListener, addKeyEventListener } = useModalUtil();

  const isShowing = {
    caption:
      props.menuType !== "share" &&
      props.menuType !== "publish" &&
      authority?.complemntUpdate,
    description:
      props.menuType !== "share" &&
      props.menuType !== "publish" &&
      authority?.complemntUpdate,
    keyword:
      props.menuType !== "share" &&
      props.menuType !== "publish" &&
      authority?.complemntUpdate,
    share:
      props.menuType === "new" ||
      props.menuType === "clone_show_new" ||
      props.menuType === "share",
    media:
      props.menuType === "edit" ||
      props.menuType === "editItem" ||
      props.menuType === "new",
    publish: props.menuType === "publish",
    editComplement:
      props.menuType !== "share" &&
      props.menuType !== "publish" &&
      authority?.complemntUpdate
  };

  const trackMediaCheck = (
    collectionId: string,
    mediaCode: string,
    checked: boolean,
    newCollection: boolean
  ) => {
    trackAction("clickMediaCheckBox", {
      collectionId,
      mediaCode,
      checked,
      newCollection
    });
  };

  useEffect(() => {
    setDefinitionError(definition[0].text.length > 1500);
    setBackgroundError(background[0].text.length > 1500);
    setTaskError(task[0].text.length > 1500);
  }, [background, definition, task]);

  useEffect(() => {
    setCaption(props.binderInfo?.caption ?? "");
    setDescription(props.binderInfo?.description ?? "");
    setDefinition(
      props.binderInfo?.definition[0]
        ? props.binderInfo?.definition
        : [{ text: "", label: "" }]
    );
    setBackground(
      props.binderInfo?.background[0]
        ? props.binderInfo?.background
        : [{ text: "", label: "" }]
    );
    setTask(
      props.binderInfo?.task[0]
        ? props.binderInfo?.task
        : [{ text: "", label: "" }]
    );
    setTechnology(
      props.binderInfo?.technology[0]
        ? props.binderInfo?.technology
        : [{ text: "", label: "" }]
    );
    setTopics(
      props.binderInfo?.topics[0]
        ? props.binderInfo?.topics
        : [{ text: "", label: "" }]
    );
    setRegulation(
      props.binderInfo?.regulation[0]
        ? props.binderInfo?.regulation
        : [{ text: "", label: "" }]
    );
    setSearchType(
      props.binderInfo?.searchType ? props.binderInfo.searchType : "0"
    );
    if (props.binderInfo) {
      setKeyword(
        !isEmpty(props.binderInfo.query)
          ? props.binderInfo.query
          : convertSearchKeyword({
              and: props.binderInfo?.andKeyword || "",
              or: props.binderInfo?.orKeyword || "",
              not: props.binderInfo?.notKeyword || ""
            })
      );
    }
    const hasKeyword =
      props.binderInfo?.andKeyword !== "" ||
      props.binderInfo?.orKeyword !== "" ||
      props.binderInfo?.notKeyword !== "" ||
      props.binderInfo?.query !== "";
    setKeywordFeedFlg(hasKeyword);
    if (props.binderInfo?.includeRelatedCorp) {
      setCompanyFeedFlg(props.binderInfo.includeRelatedCorp === "1");
    }
    setSharedFlg(props.binderInfo?.sharedFlg ?? false);
    if (props.binderInfo?.publicStatus) {
      setPublishedFlg(props.binderInfo?.publicStatus === "1");
    }
  }, [props.binderInfo]);

  const fetchMediaList = async () => {
    if (
      (props.menuType === "edit" || props.menuType === "editItem") &&
      props.binderInfo?.mediaList
    ) {
      setMediaList(props.binderInfo.mediaList);
      return;
    }

    const response = await request<{ mediaList: MediaType[] }>(
      "/binder/article-media"
    );
    setMediaList(response.mediaList);
  };

  const changeText = (witchBlock: string, value: string, index: number) => {
    switch (witchBlock) {
      case "defintion":
        const definitionSets = definition.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: definition[index].label
            };
          }
          return setValue;
        });
        setDefinition(definitionSets);
        break;
      case "background":
        const backgroundSets = background.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: background[index].label
            };
          }
          return setValue;
        });
        setBackground(backgroundSets);
        break;
      case "task":
        const taskSets = task.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: task[index].label
            };
          }
          return setValue;
        });
        setTask(taskSets);
        break;
      case "technology":
        const technologySets = technology.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: technology[index].label
            };
          }
          return setValue;
        });
        setTechnology(technologySets);
        break;
      case "topics":
        const topicSets = topics.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: topics[index].label
            };
          }
          return setValue;
        });
        setTopics(topicSets);
        break;
      case "regulation":
        const regulationSets = regulation.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: regulation[index].label
            };
          }
          return setValue;
        });
        setRegulation(regulationSets);
        break;
    }
  };

  const changeTitle = (witchBlock: string, value: string, index: number) => {
    switch (witchBlock) {
      case "defintion":
        const definitionSets = definition.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: definition[index].text,
              label: value
            };
          }
          return setValue;
        });
        setDefinition(definitionSets);
        break;
      case "background":
        const backgroundSets = background.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: background[index].text,
              label: value
            };
          }
          return setValue;
        });
        setBackground(backgroundSets);
        break;
      case "task":
        const taskSets = task.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: task[index].text,
              label: value
            };
          }
          return setValue;
        });
        setTask(taskSets);
        break;
      case "technology":
        const technologySets = technology.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: technology[index].text,
              label: value
            };
          }
          return setValue;
        });
        setTechnology(technologySets);
        break;
      case "topics":
        const topicSets = topics.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: topics[index].text,
              label: value
            };
          }
          return setValue;
        });
        setTopics(topicSets);
        break;
      case "regulation":
        const regulationSets = regulation.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: regulation[index].text,
              label: value
            };
          }
          return setValue;
        });
        setRegulation(regulationSets);
        break;
    }
  };

  const addBlock = (witchBlock: string) => {
    switch (witchBlock) {
      case "defintion":
        const definitionSets = Object.assign([], definition);
        definitionSets.push({ text: "", label: "" });
        setDefinition(definitionSets);
        break;
      case "background":
        const backgroundSets = Object.assign([], background);
        backgroundSets.push({ text: "", label: "" });
        setBackground(backgroundSets);
        break;
      case "task":
        const taskSets = Object.assign([], task);
        taskSets.push({ text: "", label: "" });
        setTask(taskSets);
        break;
      case "technology":
        const technologySets = Object.assign([], technology);
        technologySets.push({ text: "", label: "" });
        setTechnology(technologySets);
        break;
      case "topics":
        const topicSets = Object.assign([], topics);
        topicSets.push({ text: "", label: "" });
        setTopics(topicSets);
        break;
      case "regulation":
        const regulationSets = Object.assign([], regulation);
        regulationSets.push({ text: "", label: "" });
        setRegulation(regulationSets);
        break;
    }
  };

  const removeBlock = (witchBlock: string) => {
    switch (witchBlock) {
      case "defintion":
        if (definition.length <= 1) {
          setDefinition([{ text: "", label: "" }]);
        } else {
          const definitionSets = Object.assign([], definition);
          definitionSets.pop();
          setDefinition(definitionSets);
        }
        break;
      case "background":
        if (background.length <= 1) {
          setBackground([{ text: "", label: "" }]);
        } else {
          const backgroundSets = Object.assign([], background);
          backgroundSets.pop();
          setBackground(backgroundSets);
        }
        break;
      case "task":
        if (task.length <= 1) {
          setTask([{ text: "", label: "" }]);
        } else {
          const taskSets = Object.assign([], task);
          taskSets.pop();
          setTask(taskSets);
        }
        break;
      case "technology":
        if (technology.length <= 1) {
          setTechnology([{ text: "", label: "" }]);
        } else {
          const technologySets = Object.assign([], technology);
          technologySets.pop();
          setTechnology(technologySets);
        }
        break;
      case "topics":
        if (topics.length <= 1) {
          setTopics([{ text: "", label: "" }]);
        } else {
          const topicSets = Object.assign([], topics);
          topicSets.pop();
          setTopics(topicSets);
        }
        break;
      case "regulation":
        if (regulation.length <= 1) {
          setRegulation([{ text: "", label: "" }]);
        } else {
          const regulationSets = Object.assign([], regulation);
          regulationSets.pop();
          setRegulation(regulationSets);
        }
        break;
    }
  };

  useEffect(() => {
    if (isShowing.media) {
      fetchMediaList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFocus = () => {
    // テキストフィールドフォーカス中はモーダル閉じるキーイベントを解除
    removeKeyEventListener();
  };

  const onBlur = () => {
    // モーダル閉じるキーイベントを貼り直す
    addKeyEventListener();
  };

  const defaultUpperLimit = {
    label: "1500",
    text: "1500"
  };

  const captionProps = {
    caption,
    setCaption,
    error: props.error.caption
  };

  const descriptionProps = {
    description,
    setDescription,
    error: props.error.description,
    onFocus,
    onBlur
  };

  const definitionProps = {
    definition,
    error: props.error.definition,
    onFocus,
    onBlur,
    changeText,
    definitionError,
    textLimit: props.binderInfo?.upperLimit.definition.text
      ? props.binderInfo?.upperLimit.definition.text
      : defaultUpperLimit.text
  };

  const backgroundProps = {
    background,
    error: props.error.background,
    onFocus,
    onBlur,
    changeText,
    backgroundError,
    textLimit: props.binderInfo?.upperLimit.background.text
      ? props.binderInfo?.upperLimit.background.text
      : defaultUpperLimit.text
  };

  const taskProps = {
    task,
    error: props.error.task,
    onFocus,
    onBlur,
    changeText,
    taskError,
    textLimit: props.binderInfo?.upperLimit.task.text
      ? props.binderInfo?.upperLimit.task.text
      : defaultUpperLimit.text
  };

  const technologyProps = {
    technology,
    error: props.error.technology,
    onFocus,
    onBlur,
    changeText,
    changeTitle,
    addBlock,
    removeBlock,
    technologyError,
    setTechnologyError,
    textLimit: props.binderInfo?.upperLimit.technology.text
      ? props.binderInfo?.upperLimit.technology.text
      : defaultUpperLimit.text,
    titleLimit: props.binderInfo?.upperLimit.technology.label
      ? props.binderInfo?.upperLimit.technology.label
      : defaultUpperLimit.label
  };

  const topicsProps = {
    topics,
    error: props.error.topics,
    onFocus,
    onBlur,
    changeText,
    changeTitle,
    addBlock,
    removeBlock,
    topicsError,
    setTopicsError,
    textLimit: props.binderInfo?.upperLimit.topics.text
      ? props.binderInfo?.upperLimit.topics.text
      : defaultUpperLimit.text,
    titleLimit: props.binderInfo?.upperLimit.topics.label
      ? props.binderInfo?.upperLimit.topics.label
      : defaultUpperLimit.label
  };

  const regulationProps = {
    regulation,
    error: props.error.regulation,
    onFocus,
    onBlur,
    changeText,
    changeTitle,
    addBlock,
    removeBlock,
    regulationError,
    setRegulationError,
    textLimit: props.binderInfo?.upperLimit.regulation.text
      ? props.binderInfo?.upperLimit.regulation.text
      : defaultUpperLimit.text,
    titleLimit: props.binderInfo?.upperLimit.regulation.label
      ? props.binderInfo?.upperLimit.regulation.label
      : defaultUpperLimit.label
  };

  const keywordProps = {
    keywordFeedFlg,
    setKeywordFeedFlg,
    searchType,
    setSearchType,
    rawOperand,
    setRawOperand,
    companyFeedFlg,
    setCompanyFeedFlg,
    keyword,
    setKeyword,
    error: props.error.keywords,
    menuType: props.menuType,
    setIsOpenHelp: props.setIsOpenHelp
  };

  const shareProps = {
    sharedFlg,
    setSharedFlg
  };

  const publishProps = {
    publishedFlg,
    setPublishedFlg
  };

  const mediaProps = {
    mediaList,
    onChange: (media: MediaType) => {
      setMediaList(
        mediaList.map(line => {
          const item = { ...line };
          if (item.value !== media.value) return item;
          item.checked = !item.checked;
          trackMediaCheck(
            props.menuType !== "new" && props.binderInfo
              ? props.binderInfo.binderId
              : "新規",
            item.value,
            item.checked,
            props.menuType === "new"
          );
          return item;
        })
      );
    },
    onChangeList: (medium: MediaType[], checked: boolean) => {
      setMediaList(
        mediaList.map(line => {
          const item = { ...line };
          // 非活性の場合は操作をしない
          if (item.baseOptFlg === "9") return item;
          const mediaIndex = medium.findIndex(
            media => media.value === line.value
          );
          if (mediaIndex < 0) return item;
          item.checked = checked;
          trackMediaCheck(
            props.menuType !== "new" && props.binderInfo
              ? props.binderInfo.binderId
              : "新規",
            item.value,
            item.checked,
            props.menuType === "new"
          );

          return item;
        })
      );
    },
    setIsOpenDetailModal: props.setIsOpenDetailModal
  };

  const reformComplements = () => {
    const definitionText: string[] = [];
    const definitionLabel: string[] = [];
    const backgroundText: string[] = [];
    const backgroundLabel: string[] = [];
    const taskText: string[] = [];
    const taskLabel: string[] = [];
    const technologyText: string[] = [];
    const technologyLabel: string[] = [];
    const topicsText: string[] = [];
    const topicsLabel: string[] = [];
    const regulationText: string[] = [];
    const regulationLabel: string[] = [];
    definition.map(item => {
      definitionText.push(item.text);
      definitionLabel.push(item.label);
    });
    background.map(item => {
      backgroundText.push(item.text);
      backgroundLabel.push(item.label);
    });
    task.map(item => {
      taskText.push(item.text);
      taskLabel.push(item.label);
    });
    technology.map(item => {
      technologyText.push(item.text);
      technologyLabel.push(item.label);
    });
    topics.map(item => {
      topicsText.push(item.text);
      topicsLabel.push(item.label);
    });
    regulation.map(item => {
      regulationText.push(item.text);
      regulationLabel.push(item.label);
    });
    return {
      definitionText: definitionText.join("|"),
      definitionLabel: definitionLabel.join("|"),
      backgroundText: backgroundText.join("|"),
      backgroundLabel: backgroundLabel.join("|"),
      taskText: taskText.join("|"),
      taskLabel: taskLabel.join("|"),
      technologyText: technologyText.join("|"),
      technologyLabel: technologyLabel.join("|"),
      topicsText: topicsText.join("|"),
      topicsLabel: topicsLabel.join("|"),
      regulationText: regulationText.join("|"),
      regulationLabel: regulationLabel.join("|")
    };
  };

  const binderId = props.binderInfo?.binderId ?? "";

  const isComplementsError = (error: ComplementsError) => {
    return error.labelError || error.textError;
  };

  const clickSave = () => {
    const complementsReform = reformComplements();
    const validatedKeywords = validateKeywords(
      keyword,
      rawOperand,
      advancedMode["collection"] || false
    );
    const params = {
      binderId: props.menuType !== "new" ? binderId : "",
      caption,
      description,
      definition: complementsReform.definitionText,
      definitionLabel: complementsReform.definitionLabel,
      background: complementsReform.backgroundText,
      backgroundLabel: complementsReform.backgroundLabel,
      task: complementsReform.taskText,
      taskLabel: complementsReform.taskLabel,
      technology: complementsReform.technologyText,
      technologyLabel: complementsReform.technologyLabel,
      topics: complementsReform.topicsText,
      topicsLabel: complementsReform.topicsLabel,
      regulation: complementsReform.regulationText,
      regulationLabel: complementsReform.regulationLabel,
      andKeyword: keywordFeedFlg ? validatedKeywords.andKeyword : "",
      orKeyword: keywordFeedFlg ? validatedKeywords.orKeyword : "",
      notKeyword: keywordFeedFlg ? validatedKeywords.notKeyword : "",
      includeRelatedCorp: companyFeedFlg ? "1" : "0",
      setShare: sharedFlg,
      setPublish: publishedFlg,
      searchType,
      query: keywordFeedFlg ? validatedKeywords.rawText : "",
      mediaList: isShowing.media
        ? mediaList
            .filter(item => item.checked)
            .map(item => item.value)
            .join(",")
        : ""
    };
    if (props.handleSave) {
      props.handleSave(params);
    } else {
      props.onAction(params);
    }
  };

  return (
    <div className={cn(styles.component)}>
      {props.error.other && (
        <div className={styles.otherError}>{props.error.other}</div>
      )}
      {isShowing.caption && <Caption {...captionProps} />}
      {isShowing.description && <Description {...descriptionProps} />}
      {isShowing.editComplement && (
        <div className={cn(styles.editComplement)}>
          <div
            className={cn(styles.openEditComplement)}
            onClick={() => {
              setOpenEditComplement(!openEditComplement);
            }}
            data-testid="BinderPage-Common-BinderForm-openEditComplement"
          >
            <img
              src={openEditComplement ? openIcon : closeIcon}
              className={styles.editComplementIcon}
            />
            <span>補足情報</span>
          </div>
          {openEditComplement && (
            <>
              <Definition {...definitionProps} />
              <Background {...backgroundProps} />
              <Task {...taskProps} />
              <Technology {...technologyProps} />
              <Topics {...topicsProps} />
              <Regulation {...regulationProps} />
            </>
          )}
        </div>
      )}
      {isShowing.keyword && <Keyword {...keywordProps} />}
      {isShowing.media && <Media {...mediaProps} />}
      {isShowing.share && <Share {...shareProps} />}
      {isShowing.publish && <Publish {...publishProps} />}
      <div
        className={styles.buttonWrapper}
        data-testid="features-BinderPage-Common-BinderForm-button"
      >
        <Button
          className={styles.button}
          color="orange"
          onClick={clickSave}
          disabled={
            definitionError ||
            backgroundError ||
            taskError ||
            technologyError.some(isComplementsError) ||
            topicsError.some(isComplementsError) ||
            regulationError.some(isComplementsError) ||
            props.isSaveLoading
          }
          data-testid="BinderPage-Common-BinderForm-actionButton"
        >
          {props.menuType === "new" ? "コレクションを作成" : "保存"}
        </Button>
      </div>
    </div>
  );
};

interface CaptionProps {
  caption: string;
  setCaption: (arg: string) => void;
  error: string;
}

const Caption: React.FunctionComponent<CaptionProps> = ({
  caption,
  setCaption,
  error
}) => (
  <div className={styles.additem}>
    <div className={styles.label}>コレクション名</div>
    <TextInput
      placeholder="名称未設定"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setCaption(event.target.value);
      }}
      value={caption}
      error={error}
    />
  </div>
);

interface DescriptionProps {
  description: string;
  setDescription: (arg: string) => void;
  error: string;
  onFocus: () => void;
  onBlur: () => void;
}

const Description: React.FunctionComponent<DescriptionProps> = ({
  description,
  setDescription,
  error,
  onFocus,
  onBlur
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>説明</div>
    <textarea
      placeholder="コレクションの説明文"
      onChange={event => {
        setDescription(event.target.value);
      }}
      value={description}
      rows={5}
      onFocus={onFocus}
      onBlur={onBlur}
    />
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface DefinitionProps {
  definition: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  definitionError: boolean;
  textLimit: string;
}

const Definition: React.FunctionComponent<DefinitionProps> = ({
  definition,
  error,
  onFocus,
  onBlur,
  changeText,
  definitionError,
  textLimit
}) => (
  <div
    className={cn(styles.additem, { [styles.error]: error !== "" })}
    data-testid="BinderPage-Common-BinderForm-Definition"
  >
    <div className={styles.label}>用語の定義</div>
    <div className={styles.complement}>
      <textarea
        placeholder="コレクションの用語の定義"
        onChange={event => {
          changeText("defintion", event.target.value, 0);
        }}
        value={definition[0].text}
        rows={5}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
    <div className={styles.complementDescription}>
      コレクションの主要なキーワードの定義について簡潔に説明。
    </div>
    {!error && definitionError && (
      <div className={styles.errorMessage}>
        用語の定義は{textLimit}文字以内で入力してください
      </div>
    )}
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface BackgroundProps {
  background: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  backgroundError: boolean;
  textLimit: string;
}

const Background: React.FunctionComponent<BackgroundProps> = ({
  background,
  error,
  onFocus,
  onBlur,
  changeText,
  backgroundError,
  textLimit
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>背景</div>
    <div className={styles.complement}>
      <textarea
        placeholder="コレクションの背景"
        onChange={event => {
          changeText("background", event.target.value, 0);
        }}
        value={background[0].text}
        rows={5}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
    <div className={styles.complementDescription}>
      このトレンドがなぜ重要なのか？社会的・経済的な背景を説明。
    </div>
    {!error && backgroundError && (
      <div className={styles.errorMessage}>
        背景は{textLimit}文字以内で入力してください
      </div>
    )}
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface TaskProps {
  task: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  taskError: boolean;
  textLimit: string;
}

const Task: React.FunctionComponent<TaskProps> = ({
  task,
  error,
  onFocus,
  onBlur,
  changeText,
  taskError,
  textLimit
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>解決する課題</div>
    <div className={styles.complement}>
      <textarea
        placeholder="コレクションの用語の課題"
        onChange={event => {
          changeText("task", event.target.value, 0);
        }}
        value={task[0].text}
        rows={5}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
    <div className={styles.complementDescription}>
      この技術が解決する社会課題についてキーワードを列挙。1つのコレクションに複数のキーワードが紐づく。(
      , ) カンマ区切りで入力。
    </div>
    {!error && taskError && (
      <div className={styles.errorMessage}>
        解決する課題は{textLimit}文字以内で入力してください
      </div>
    )}
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface TechnologyProps {
  technology: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  changeTitle: (witchBlock: string, value: string, index: number) => void;
  addBlock: (witchBlock: string) => void;
  removeBlock: (witchBlock: string) => void;
  technologyError: ComplementsError[];
  setTechnologyError: (error: ComplementsError[]) => void;
  textLimit: string;
  titleLimit: string;
}

const Technology: React.FunctionComponent<TechnologyProps> = ({
  technology,
  error,
  onFocus,
  onBlur,
  changeText,
  changeTitle,
  addBlock,
  removeBlock,
  technologyError,
  setTechnologyError,
  textLimit,
  titleLimit
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>鍵となる技術</div>
    {technology.map((item, index) => {
      return (
        <div className={styles.complement} key={`technology-${index}`}>
          <TextInput
            placeholder="技術のタイトル"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              changeTitle("technology", event.target.value, index);
              technologyError[index]
                ? (technologyError[index].labelError =
                    event.target.value.length > 1500)
                : (technologyError[index] = {
                    labelError: event.target.value.length > 1500,
                    textError: false
                  });
              setTechnologyError(technologyError);
            }}
            value={item.label}
            error={error}
          />
          {!error && technologyError[index]?.labelError && (
            <div className={styles.errorMessage}>
              鍵となる技術のタイトル{index + 1}は{titleLimit}
              文字以内で入力してください
            </div>
          )}
          <textarea
            placeholder="コレクションの用語の鍵となる技術"
            onChange={event => {
              changeText("technology", event.target.value, index);
              technologyError[index]
                ? (technologyError[index].textError =
                    event.target.value.length > 1500)
                : (technologyError[index] = {
                    labelError: false,
                    textError: event.target.value.length > 1500
                  });
              setTechnologyError(technologyError);
            }}
            value={item.text}
            rows={5}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {!error && technologyError[index]?.textError && (
            <div className={styles.errorMessage}>
              鍵となる技術の本文{index + 1}は{textLimit}
              文字以内で入力してください
            </div>
          )}
        </div>
      );
    })}
    <div className={styles.complementDescription}>
      この分野・ジャンルにおいて鍵となるテクノロジーについて列挙し、それぞれ説明。
    </div>
    <div className={styles.complementDescription}>
      技術名と説明文のペアが1つのコレクションに複数紐づく。
    </div>
    <div className={styles.blockTool}>
      <span
        className={styles.addBlock}
        onClick={() => addBlock("technology")}
        data-testid="BinderPage-Common-BinderForm-Technology-addBlock"
      />
      <span
        className={styles.removeBlock}
        onClick={() => removeBlock("technology")}
        data-testid="BinderPage-Common-BinderForm-Technology-removeBlock"
      />
    </div>
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface TopicsProps {
  topics: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  changeTitle: (witchBlock: string, value: string, index: number) => void;
  addBlock: (witchBlock: string) => void;
  removeBlock: (witchBlock: string) => void;
  topicsError: ComplementsError[];
  setTopicsError: (error: ComplementsError[]) => void;
  textLimit: string;
  titleLimit: string;
}

const Topics: React.FunctionComponent<TopicsProps> = ({
  topics,
  error,
  onFocus,
  onBlur,
  changeText,
  changeTitle,
  addBlock,
  removeBlock,
  topicsError,
  setTopicsError,
  textLimit,
  titleLimit
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>関連する話題</div>
    {topics.map((item, index) => {
      return (
        <div className={styles.complement} key={`topics-${index}`}>
          <TextInput
            placeholder="関連する話題のタイトル"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              changeTitle("topics", event.target.value, index);
              topicsError[index]
                ? (topicsError[index].labelError =
                    event.target.value.length > 1500)
                : (topicsError[index] = {
                    labelError: event.target.value.length > 1500,
                    textError: false
                  });
              setTopicsError(topicsError);
            }}
            value={item.label}
            error={error}
          />
          {!error && topicsError[index]?.labelError && (
            <div className={styles.errorMessage}>
              関連する話題のタイトル{index + 1}は{titleLimit}
              文字以内で入力してください
            </div>
          )}
          <textarea
            placeholder="コレクションの関連する話題"
            onChange={event => {
              changeText("topics", event.target.value, index);
              topicsError[index]
                ? (topicsError[index].textError =
                    event.target.value.length > 1500)
                : (topicsError[index] = {
                    labelError: false,
                    textError: event.target.value.length > 1500
                  });
              setTopicsError(topicsError);
            }}
            value={item.text}
            rows={5}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {!error && topicsError[index]?.textError && (
            <div className={styles.errorMessage}>
              関連する話題の本文{index + 1}は{textLimit}
              文字以内で入力してください
            </div>
          )}
        </div>
      );
    })}
    <div className={styles.complementDescription}>
      周辺のビジネス領域に関して説明。
    </div>
    <div className={styles.complementDescription}>
      トピックと説明文のペアが1つのコレクションに複数紐づく。
    </div>
    <div className={styles.blockTool}>
      <span
        className={styles.addBlock}
        onClick={() => addBlock("topics")}
        data-testid="BinderPage-Common-BinderForm-Topics-addBlock"
      />
      <span
        className={styles.removeBlock}
        onClick={() => removeBlock("topics")}
        data-testid="BinderPage-Common-BinderForm-Topics-removeBlock"
      />
    </div>
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface RegulationProps {
  regulation: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  changeTitle: (witchBlock: string, value: string, index: number) => void;
  addBlock: (witchBlock: string) => void;
  removeBlock: (witchBlock: string) => void;
  regulationError: ComplementsError[];
  setRegulationError: (error: ComplementsError[]) => void;
  textLimit: string;
  titleLimit: string;
}

const Regulation: React.FunctionComponent<RegulationProps> = ({
  regulation,
  error,
  onFocus,
  onBlur,
  changeText,
  changeTitle,
  addBlock,
  removeBlock,
  regulationError,
  setRegulationError,
  textLimit,
  titleLimit
}) => {
  return (
    <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
      <div className={styles.label}>関連する法規制</div>
      {regulation.map((item, index) => {
        return (
          <div className={styles.complement} key={`regulation-${index}`}>
            <TextInput
              placeholder="関連する法規制のタイトル"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                changeTitle("regulation", event.target.value, index);
                regulationError[index]
                  ? (regulationError[index].labelError =
                      event.target.value.length > 1500)
                  : (regulationError[index] = {
                      labelError: event.target.value.length > 1500,
                      textError: false
                    });
                setRegulationError(regulationError);
              }}
              value={item.label}
              error={error}
            />
            {!error && regulationError[index]?.labelError && (
              <div className={styles.errorMessage}>
                関連する法規制のタイトル{index + 1}は{titleLimit}
                文字以内で入力してください
              </div>
            )}
            <textarea
              placeholder="コレクションの関連する法規制"
              onChange={event => {
                changeText("regulation", event.target.value, index);
                regulationError[index]
                  ? (regulationError[index].textError =
                      event.target.value.length > 1500)
                  : (regulationError[index] = {
                      labelError: false,
                      textError: event.target.value.length > 1500
                    });
                setRegulationError(regulationError);
              }}
              value={item.text}
              rows={5}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {!error && regulationError[index]?.textError && (
              <div className={styles.errorMessage}>
                関連する法規制の本文{index + 1}は{textLimit}
                文字以内で入力してください
              </div>
            )}
          </div>
        );
      })}
      <div className={styles.blockTool}>
        <span
          className={styles.addBlock}
          onClick={() => addBlock("regulation")}
          data-testid="BinderPage-Common-BinderForm-Regulation-addBlock"
        />
        <span
          className={styles.removeBlock}
          onClick={() => removeBlock("regulation")}
          data-testid="BinderPage-Common-BinderForm-Regulation-removeBlock"
        />
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

interface KeywordProps {
  keywordFeedFlg: boolean;
  setKeywordFeedFlg: (arg: boolean) => void;
  searchType: string;
  setSearchType: (value: string) => void;
  rawOperand: RawOperand;
  setRawOperand: (arg: RawOperand) => void;
  companyFeedFlg: boolean;
  setCompanyFeedFlg: (arg: boolean) => void;
  error: string;
  menuType: string;
  keyword: string;
  setKeyword: (arg: string) => void;
  setIsOpenHelp: (isOpen: boolean) => void;
}

const Keyword: React.FunctionComponent<KeywordProps> = ({
  keywordFeedFlg,
  setKeywordFeedFlg,
  searchType,
  setSearchType,
  rawOperand,
  setRawOperand,
  companyFeedFlg,
  setCompanyFeedFlg,
  error,
  menuType,
  keyword,
  setKeyword,
  setIsOpenHelp
}) => {
  const onChange = (condition: {
    isKeyword: boolean;
    isCompany: boolean;
    isAnd: boolean;
  }) => {
    setCompanyFeedFlg(condition.isCompany);
    setKeywordFeedFlg(condition.isKeyword);
    setSearchType(condition.isAnd ? "1" : "0");
  };

  return (
    <div className={`${styles.additem} ${styles.feeds}`}>
      <div className={styles.feedLabel}>フィード条件</div>
      <div className={styles.feedDescription}>
        選択した媒体に対し、以下の条件で記事を抽出します
      </div>
      <div
        className={styles.item}
        onClick={() =>
          onChange({
            isKeyword: false,
            isCompany: true,
            isAnd: false
          })
        }
        data-testid="BinderPage-Common-BinderForm-setCompanyFeed"
      >
        <Radio
          name="feedConditions"
          isSelected={companyFeedFlg && !keywordFeedFlg}
          uncontrolled
        />
        <div className={styles.itemContent}>
          <div className={styles.itemName}>登録した企業</div>
          <div className={styles.itemDescription}>
            選択した媒体から、登録した企業に関連する記事を抽出します
          </div>
        </div>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          onChange({
            isKeyword: true,
            isCompany: false,
            isAnd: false
          });
        }}
        data-testid="BinderPage-Common-BinderForm-setKeywordFeed"
      >
        <Radio
          name="feedConditions"
          isSelected={!companyFeedFlg && keywordFeedFlg}
          uncontrolled
        />
        <div className={styles.itemContent}>
          <div className={styles.itemName}>キーワード</div>
          <div className={styles.itemDescription}>
            選択した媒体から、指定したキーワードを含む記事を抽出します
          </div>
        </div>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          onChange({
            isKeyword: true,
            isCompany: true,
            isAnd: false
          });
        }}
        data-testid="BinderPage-Common-BinderForm-setKeywordOrCompanyFeed"
      >
        <Radio
          name="feedConditions"
          isSelected={companyFeedFlg && keywordFeedFlg && searchType === "0"}
          uncontrolled
        />
        <div className={styles.itemContent}>
          <div className={styles.itemName}>登録した企業またはキーワード</div>
          <div className={styles.itemDescription}>
            選択した媒体から、登録した企業またはキーワードのいずれかに合致する記事を抽出します
          </div>
        </div>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          onChange({
            isKeyword: true,
            isCompany: true,
            isAnd: true
          });
        }}
        data-testid="BinderPage-Common-BinderForm-setKeywordAndCompanyFeed"
      >
        <Radio
          name="feedConditions"
          isSelected={companyFeedFlg && keywordFeedFlg && searchType === "1"}
          uncontrolled
        />
        <div className={styles.itemContent}>
          <div className={styles.itemName}>登録した企業とキーワード</div>
          <div className={styles.itemDescription}>
            選択した媒体から、登録した企業またはキーワードの両方に合致する記事を抽出します
          </div>
        </div>
      </div>
      <div className={styles.checkboxWrapper}>
        <div className={styles.keyword}>
          <FormulaSearch
            rawOperand={rawOperand}
            onChangeRawOperand={setRawOperand}
            witch="collection"
            disabled={!keywordFeedFlg}
            atlasPageName={
              menuType === "new" ? "コレクション作成" : "コレクション編集"
            }
            canOpenCaution={true}
            keyword={keyword}
            onChangeRawText={setKeyword}
            setIsOpenHelp={setIsOpenHelp}
          />
          {error && <div className={styles.errorMessage}>{error}</div>}
        </div>
      </div>
    </div>
  );
};

interface ShareProps {
  sharedFlg: boolean;
  setSharedFlg: (arg: boolean) => void;
}

const Share: React.FunctionComponent<ShareProps> = ({
  sharedFlg,
  setSharedFlg
}) => (
  <div
    className={cn(styles.additem, styles.media)}
    data-testid="BinderPage-Common-BinderForm-share"
  >
    <div className={styles.label}>共有設定</div>
    <Checkbox
      name="組織にコレクションを共有する"
      className={styles.checkbox}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setSharedFlg(event.target.checked);
      }}
      isChecked={sharedFlg}
    />
    <div className={styles.tips}>
      組織に共有されたコレクションは全員が編集・削除を行えるようになります
    </div>
  </div>
);

interface PublishProps {
  publishedFlg: boolean;
  setPublishedFlg: (arg: boolean) => void;
}

const Publish: React.FunctionComponent<PublishProps> = ({
  publishedFlg,
  setPublishedFlg
}) => (
  <div className={cn(styles.additem, styles.media)}>
    <div className={styles.label}>公開</div>
    <Checkbox
      name="日経コレクションとして公開する"
      className={styles.checkbox}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setPublishedFlg(event.target.checked);
      }}
      isChecked={publishedFlg}
    />
  </div>
);

interface MediaProps {
  mediaList: MediaType[];
  onChange: (media: MediaType) => void;
  onChangeList: (media: MediaType[], checked: boolean) => void;
  setIsOpenDetailModal: (isOpen: boolean) => void;
}

const Media: React.FunctionComponent<MediaProps> = props => (
  <div className={cn(styles.additem, styles.media)}>
    <div className={styles.label}>媒体選択</div>
    <div className={styles.checkboxContainer}>
      {props.mediaList.length !== 0 &&
        mediaTypes.map((mediaType, mediaTypeIndex) => {
          const medium = props.mediaList.filter(
            media => media.hierarchy === mediaType
          );
          const activeCheckbox = medium.filter(
            media => media.baseOptFlg !== "9"
          );
          const checkedCheckbox = medium.filter(
            media => media.baseOptFlg !== "9" && media.checked
          );
          const allChecked = medium.every(media => media.baseOptFlg === "9")
            ? medium.every(media => media.checked)
            : activeCheckbox.length === checkedCheckbox.length;

          return (
            <div
              className={styles.mediaGroup}
              key={`media-wrapper-${mediaTypeIndex}`}
            >
              <div className={styles.parentCheckboxWrapper}>
                <Checkbox
                  name={mediaType}
                  isActive={medium.some(media => media.baseOptFlg !== "9")}
                  isIndeterminate={
                    medium.some(media => media.checked) && !allChecked
                  }
                  isChecked={allChecked}
                  onChange={() => {
                    const checked = !medium.every(
                      media => media.baseOptFlg === "9" || media.checked
                    );

                    props.onChangeList(medium, checked);
                  }}
                />
                <div className={styles.helpIconWrapper}>
                  {mediaType === "英文記事" && (
                    <ToolTip text="検索キーワードは英文で指定してください">
                      <div className={styles.searchHelpIcon} />
                    </ToolTip>
                  )}
                </div>
              </div>
              <div className={styles.mediaList}>
                {medium.map((media, index) => {
                  const isActive = media.baseOptFlg !== "9";
                  return (
                    <span
                      className={styles.item}
                      key={`media-${index}`}
                      data-testid={`BinderPage-Common-BinderForm-media-${index}`}
                    >
                      <Checkbox
                        name={
                          media.value === "WC1"
                            ? "外部サイト（官公庁）"
                            : media.label
                        }
                        isSmall
                        className={styles.checkbox}
                        onChange={() => {
                          props.onChange(media);
                        }}
                        isChecked={media.checked}
                        isActive={isActive}
                      />
                      {/* 日経産業新聞は更新停止のラベルを追加する */}
                      {media.value === "NSS" && (
                        <span className={styles.updateStop}>（更新停止）</span>
                      )}
                      {media.mediaFeeType === "1" && (
                        <PayTag isActive={isActive} />
                      )}
                      {!isActive && media.mediaFeeType === "0" && (
                        <ToolTip
                          content={
                            <div className={styles.tooltipContent}>
                              オプションでご利用いただけます。
                              <span
                                className={styles.detail}
                                onClick={() => props.setIsOpenDetailModal(true)}
                              >
                                詳細
                              </span>
                            </div>
                          }
                        >
                          <div className={cn(styles.lockIcon, styles.cursor)} />
                        </ToolTip>
                      )}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  </div>
);
