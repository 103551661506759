// 初期表示
export const PAGE_INIT = "corp/ma-strategy/PAGE_INIT";

// テレコン用 Cookie 情報取得
export const TELECOM_GET = "corp/ma-strategy/TELECOM_GET";

// 表示期間変更
export const PERIOD_CHANGE = "corp/ma-strategy/PERIOD_CHANGE";

// 活動内容変更
export const ACTIVE_CONTENT_CHANGE = "corp/ma-strategy/ACTIVE_CONTENT_CHANGE";

// 企業活動ページ遷移
export const STRATEGY_PAGER_CHANGE = "corp/ma-strategy/STRATEGY_PAGER_CHANGE";

// 企業情報ソート区分もしくは表示件数変更
export const SORT_OR_COUNT_CHANGE = "corp/ma-strategy/SORT_OR_COUNT_CHANGE";

// M&A 表示
export const MA_PAGING = "corp/ma-strategy/MA_PAGING";

// 選択項目のセット
export const SET_SORT_OPTIONS = "corp/news/SET_SORT_OPTIONS";
// 表示件数のセット
export const SET_PER_PAGE_OPTIONS = "corp/news/SET_PER_PAGE_OPTIONS";
