import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";

const initialState = {
  countryData: {},
  isLoading: false,
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...state,
      isLoading: true
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      countryData: action.payload,
      isLoading: false
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      isLoading: false,
      error: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initCountryPage: createAction(ActionTypes.PAGE_INIT, params =>
    request(342, params)
  )
};
