import { RawOperand } from "models/GlobalSearch";
import { isEmpty } from "./common";

export interface OperandTags {
  and: string[];
  or: string[];
  not: string[];
}

export const OPERATOR_OR = "/";
export const OPERATOR_NOT = "-";
export const OPERATORS = [OPERATOR_OR, OPERATOR_NOT];

const TAG_SEPARATOR = " ";
const MAX_SEARCH_WORD_COUNT = 1000;

export const tagsToRawText = (tags: string[]): string => {
  const s = tags.join(TAG_SEPARATOR);
  return s === "" ? s : s + TAG_SEPARATOR;
};

export const splitKeyword = (inputStr: string): string[] => {
  const keywords = [];
  let endQuote;
  let endWord;
  let tmpWord;
  let inMiddleDoubleQuotes;

  inputStr = inputStr.trim();

  while (inputStr.length > 0) {
    if (inputStr[0] === '"') {
      inputStr = inputStr.slice(1, inputStr.length);
      endQuote = inputStr.indexOf('"');
      keywords.push(`"${inputStr.slice(0, endQuote + 1)}`);
      inputStr = inputStr.slice(endQuote + 1, inputStr.length).trim();
    } else {
      endWord = inputStr.search(/[\s　]/g);
      if (endWord === -1) {
        endWord = inputStr.length;
      }
      tmpWord = inputStr.slice(0, endWord);

      inMiddleDoubleQuotes = tmpWord.indexOf('"');

      if (inMiddleDoubleQuotes === -1) {
        keywords.push(inputStr.slice(0, endWord));
        inputStr = inputStr.slice(endWord, inputStr.length).trim();
      } else {
        // 単語の途中に " がある時の処理
        keywords.push(inputStr.slice(0, inMiddleDoubleQuotes));
        inputStr = inputStr.slice(inMiddleDoubleQuotes, inputStr.length).trim();
      }
    }
  }

  return keywords;
};

export const tagsToOperandTags = (tags: string[]): OperandTags => {
  const operandTags: OperandTags = { and: [], or: [], not: [] };

  let operatorOr = false;
  let operatorNot = false;

  for (const i in tags) {
    const tag = tags[i];
    if (operatorOr) {
      operandTags.or.push(tag);
      operatorOr = false;
      continue;
    }
    if (operatorNot) {
      operandTags.not.push(tag);
      operatorNot = false;
      continue;
    }

    if (tag === OPERATOR_OR) {
      operatorOr = true;
    } else if (tag === OPERATOR_NOT) {
      operatorNot = true;
    } else {
      operandTags.and.push(tag);
    }
  }

  return operandTags;
};

export const addOperand = (keyword: string, operand: string): string => {
  return splitKeyword(keyword)
    .filter(v => v)
    .map(value => `${operand} ${value}`)
    .join(" ");
};

export const rawOperandToRawText = (rawOperand: RawOperand): string => {
  return [
    rawOperand.and,
    rawOperand.or ? addOperand(rawOperand.or, "/") : "",
    rawOperand.not ? addOperand(rawOperand.not, "-") : ""
  ]
    .filter(v => v !== "")
    .join(" ");
};

/**
 * プレーンなテキストから AND, OR, NOT に変換する
 */
export const rawTextToRawOperand = (rawText: string): RawOperand => {
  const result = tagsToOperandTags(splitKeyword(rawText));
  return {
    and: result.and.join(" "),
    or: result.or.join(" "),
    not: result.not.join(" ")
  };
};
const OPERANDS = ["/", "-"];

export const removeOperand = (text: string, operand = OPERANDS): string => {
  return splitKeyword(text)
    .filter(word => !operand.includes(word) && word !== "")
    .filter((value, index, self) => self.indexOf(value) === index)
    .join(" ");
};

export const buildHighlightKeyword = (
  rawText: string,
  rawOperand: RawOperand,
  operand?: string[]
) => {
  return rawText
    ? [removeOperand(rawText, operand)]
    : [...splitKeyword(rawOperand.and), ...splitKeyword(rawOperand.or)].filter(
        v => v
      );
};

export const separateKeywordToRawText = (
  keyword?: string,
  orKeyword?: string,
  notKeyword?: string
): string => {
  if (!keyword && !orKeyword && !notKeyword) return "";

  const convertedKeyword = keyword
    ? splitKeyword(keyword.trim()).join(" ")
    : "";
  const convertedOrKeyword = orKeyword
    ? ` ${addOperand(splitKeyword(orKeyword.trim()).join(" "), "/")}`
    : "";
  const convertedNotKeyword = notKeyword
    ? ` ${addOperand(splitKeyword(notKeyword.trim()).join(" "), "-")}`
    : "";
  return `${convertedKeyword}${convertedOrKeyword}${convertedNotKeyword}`;
};

export const removeSpaceSplitKeyword = (keyword: string): string[] => {
  const removed = keyword.trim().replace(/[ 　]+/g, " ");
  return splitKeyword(removed);
};

/**
 * AND, OR, NOT の予約語を追加（一括検索以外用）
 */
export const convertSearchKeyword = (rawOperand: RawOperand) => {
  switch (
    [
      rawOperand.and.length === 0 ? "0" : "1",
      rawOperand.or.length === 0 ? "0" : "1",
      rawOperand.not.length === 0 ? "0" : "1"
    ].toString()
  ) {
    case "1,0,0": {
      return removeSpaceSplitKeyword(rawOperand.and).join(" AND ");
    }
    case "1,1,0": {
      return (
        "( " +
        removeSpaceSplitKeyword(rawOperand.and).join(" AND ") +
        " ) AND ( " +
        removeSpaceSplitKeyword(rawOperand.or).join(" OR ") +
        " )"
      );
    }
    case "1,0,1": {
      return (
        "( " +
        removeSpaceSplitKeyword(rawOperand.and).join(" AND ") +
        " ) NOT ( " +
        removeSpaceSplitKeyword(rawOperand.not).join(" OR ") +
        " )"
      );
    }
    case "1,1,1": {
      return (
        "( " +
        removeSpaceSplitKeyword(rawOperand.and).join(" AND ") +
        " ) AND ( " +
        removeSpaceSplitKeyword(rawOperand.or).join(" OR ") +
        " ) NOT ( " +
        removeSpaceSplitKeyword(rawOperand.not).join(" OR ") +
        " )"
      );
    }
    case "0,1,0": {
      return removeSpaceSplitKeyword(rawOperand.or).join(" OR ");
    }
    case "0,1,1": {
      return (
        "( " +
        removeSpaceSplitKeyword(rawOperand.or).join(" OR ") +
        " ) NOT ( " +
        removeSpaceSplitKeyword(rawOperand.not).join(" OR ") +
        " )"
      );
    }
    case "0,0,1": {
      return removeSpaceSplitKeyword(rawOperand.not).join(" OR ");
    }
    case "0,0,0": {
      return "";
    }
    default:
      return "";
  }
};

export const validateParams = (
  rawText: string,
  operandRaw: RawOperand,
  isAdvanced: boolean
): { validate: boolean; message?: string } => {
  // キーワードがない場合は検索しない
  if (
    (!isAdvanced && rawText === "") ||
    (isAdvanced &&
      operandRaw.and === "" &&
      operandRaw.or === "" &&
      operandRaw.not === "")
  )
    return { validate: false };

  if (!isAdvanced) {
    const convertedRawOperand = rawTextToRawOperand(rawText);
    if (isEmpty(convertedRawOperand.and) && isEmpty(convertedRawOperand.or)) {
      return {
        validate: false,
        message:
          "「すべてを含む」もしくは「いずれかを含む」にキーワードを設定してください"
      };
    }
  }

  // AND,ORキーワードがない場合は警告表示
  if (isAdvanced && operandRaw.and === "" && operandRaw.or === "") {
    return {
      validate: false,
      message:
        "「すべてを含む」もしくは「いずれかを含む」にキーワードを設定してください"
    };
  }

  const totalLength = isAdvanced
    ? (operandRaw.and + operandRaw.or + operandRaw.not).length
    : rawText.length;

  if (totalLength > MAX_SEARCH_WORD_COUNT) {
    return {
      validate: false,
      message: `W1082 入力可能な文字数(${MAX_SEARCH_WORD_COUNT})を超えています`
    };
  }

  return { validate: true };
};
