import { EsgEnvironment } from "./actions";
import { Reducer } from "redux";
import produce from "immer";

import {
  DislosureYears,
  EnvironmentalReport,
  EsgData,
  EsgInfoData
} from "models/CorpEsg";

export interface EsgEnvironmentState {
  data?: EsgData;
  environmentalReport?: EnvironmentalReport;
  dislosureYears: DislosureYears[];
  airEmissionInfo?: EsgInfoData;
  waterWasteWaterInfo?: EsgInfoData;
  energyInfo?: EsgInfoData;
  wasteInfo?: EsgInfoData;
  biodiversityInfo?: EsgInfoData;
  environmentalComplianceInfo?: EsgInfoData;
  environmentPeriod?: {
    from: string;
    to: string;
  };
  airEmissionDisclosureYear: string;
  waterWasteWaterDisclosureYear: string;
  energyDisclosureYear: string;
  wasteDisclosureYear: string;
  biodiversityDisclosureYear: string;
  environmentalComplianceDisclosureYear: string;
  airEmissionTextDataYear: string;
  waterWasteWaterTextDataYear: string;
  energyTextDataYear: string;
  wasteTextDataYear: string;
  biodiversityTextDataYear: string;
  environmentalComplianceTextDataYear: string;
  defaultBusinessYear: string;
}

const initialState: EsgEnvironmentState = {
  data: undefined,
  environmentalReport: undefined,
  dislosureYears: [],
  airEmissionInfo: undefined,
  waterWasteWaterInfo: undefined,
  energyInfo: undefined,
  wasteInfo: undefined,
  biodiversityInfo: undefined,
  environmentalComplianceInfo: undefined,
  environmentPeriod: undefined,
  airEmissionDisclosureYear: "",
  waterWasteWaterDisclosureYear: "",
  energyDisclosureYear: "",
  wasteDisclosureYear: "",
  biodiversityDisclosureYear: "",
  environmentalComplianceDisclosureYear: "",
  airEmissionTextDataYear: "",
  waterWasteWaterTextDataYear: "",
  energyTextDataYear: "",
  wasteTextDataYear: "",
  biodiversityTextDataYear: "",
  environmentalComplianceTextDataYear: "",
  defaultBusinessYear: ""
};

export const corpEsgEnvironment: Reducer<
  EsgEnvironmentState,
  EsgEnvironment
> = (state = initialState, action) => {
  switch (action.type) {
    case "corp/esg/environment/SET_GREEN_DATA_PAGE_INIT":
      return produce(state, draft => {
        draft.data = action.payload;
        draft.environmentPeriod = {
          from: action.payload.selectedAccFrom,
          to: action.payload.selectedAccTo
        };
      });
    case "corp/esg/environment/SET_ENVIRONMENTAL_REPORT":
      return produce(state, darft => {
        darft.environmentalReport = action.paylaod;
      });
    case "corp/esg/environment/SET_DISCLOSURE_YEARS":
      return produce(state, draft => {
        draft.dislosureYears = action.payload;
      });
    case "corp/esg/environment/SET_AIR_EMISSION_INFO":
      return produce(state, draft => {
        draft.airEmissionInfo = action.payload;
      });
    case "corp/esg/environment/SET_WATER_WASTE_WATER_INFO":
      return produce(state, draft => {
        draft.waterWasteWaterInfo = action.payload;
      });
    case "corp/esg/environment/SET_ENERGY_INFO":
      return produce(state, draft => {
        draft.energyInfo = action.payload;
      });
    case "corp/esg/environment/SET_WASTE_INFO":
      return produce(state, draft => {
        draft.wasteInfo = action.payload;
      });
    case "corp/esg/environment/SET_BIODIVERSITY_INFO":
      return produce(state, draft => {
        draft.biodiversityInfo = action.payload;
      });
    case "corp/esg/environment/SET_ENVIRONMENTAL_COMPLIANCE_INFO":
      return produce(state, draft => {
        draft.environmentalComplianceInfo = action.payload;
      });
    case "corp/esg/environment/SET_AIR_EMISSION_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.airEmissionDisclosureYear = action.payload;
      });
    case "corp/esg/environment/SET_WATER_WASTE_WATER_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.waterWasteWaterDisclosureYear = action.payload;
      });
    case "corp/esg/environment/SET_ENERGY_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.energyDisclosureYear = action.payload;
      });
    case "corp/esg/environment/SET_WASTE_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.wasteDisclosureYear = action.payload;
      });
    case "corp/esg/environment/SET_BIODIVERSITY_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.biodiversityDisclosureYear = action.payload;
      });
    case "corp/esg/environment/SET_ENVIRONMENTAL_COMPLIANCE_DISCLOSURE_YEAR":
      return produce(state, draft => {
        draft.environmentalComplianceDisclosureYear = action.payload;
      });
    case "corp/esg/environment/SET_AIR_EMISSION_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.airEmissionTextDataYear = action.payload;
      });
    case "corp/esg/environment/SET_WATER_WASTE_WATER_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.waterWasteWaterTextDataYear = action.payload;
      });
    case "corp/esg/environment/SET_ENERGY_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.energyTextDataYear = action.payload;
      });
    case "corp/esg/environment/SET_WASTE_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.wasteTextDataYear = action.payload;
      });
    case "corp/esg/environment/SET_BIODIVERSITY_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.biodiversityTextDataYear = action.payload;
      });
    case "corp/esg/environment/SET_ENVIRONMENTAL_COMPLIANCE_TEXT_DATA_YEAR":
      return produce(state, draft => {
        draft.environmentalComplianceTextDataYear = action.payload;
      });
    default:
      return state;
  }
};
