import React from "react";
import { getPdfParams } from "utils/helper/localStorage";
import { getDownloadUrl } from "utils/helper/download";
import { valueFromKeyOrDefault } from "utils/helper/common";
import Toast from "components/Common/Toast/index";

// メールの URL から開かれた時の API を指定
// https://nikkeilabs.backlog.jp/view/B2B_NVS_FR_2018-5083#comment-1447880833
const PDF_API = {
  "1": "96", // 適時開示
  "2": "96" // 適時開示
};

/**
 * PDF表示
 */
class Pdf extends React.Component {
  constructor() {
    super();
    this.state = { error: "" };
  }

  async componentDidMount() {
    const { urlNumber, params } = getPdfParams();
    try {
      const searchParams = new URLSearchParams(location.search);
      const emailPdfType = searchParams.get("pdfType"); // email の URL から PDF を開く場合は pdfType が渡される

      if (emailPdfType) {
        const url = await getDownloadUrl(PDF_API[emailPdfType], {
          keyPdf: searchParams.get("keyPdf")
        });

        location.href = url;
        return;
      }

      const url = await getDownloadUrl(urlNumber, params);
      location.href = url;
    } catch (error) {
      if (error.message === "UNAUTHORIZED") {
        location.href = "/login";
      } else {
        const message = valueFromKeyOrDefault(
          error.body,
          "details.0.message",
          ""
        );
        this.setState({ error: message });
      }
    }
  }

  render() {
    return this.state.error === "" ? null : (
      <Toast errorMessages={[this.state.error]} />
    );
  }
}

export default Pdf;
