import React, { useMemo } from "react";
import cn from "classnames";
import styles from "./style.scss";

export interface OperandRaw {
  rawText: string;
  and: string;
  or: string;
  not: string;
}

export const OPERATOR_OR = "/";
export const OPERATOR_NOT = "-";
export const OPERATORS = [OPERATOR_OR, OPERATOR_NOT];

export interface Props {
  className?: string;
  onChangeRawText: (text: string) => void;
  onKeyPress?: React.KeyboardEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  rawText: string;
  addonPrepend?: React.ReactNode;
  addonAppend?: React.ReactNode;
  inputRef?: React.RefObject<HTMLInputElement>;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  placeholder?: string;
  scrollable?: boolean;
  disabled?: boolean;
  isHideAddonPrependInputting?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
}

export const RawInput: React.FunctionComponent<Props> = ({
  className,
  rawText,
  addonPrepend,
  addonAppend,
  inputRef,
  onChangeRawText,
  onFocus,
  onBlur,
  onKeyPress,
  onKeyDown,
  placeholder,
  scrollable = false,
  disabled = false,
  isHideAddonPrependInputting = false,
  onClick
}) => {
  const isHideAddonPrepend = useMemo(() => {
    if (!isHideAddonPrependInputting) return false;

    return rawText.length !== 0;
  }, [isHideAddonPrependInputting, rawText]);

  return (
    <label
      className={cn(styles.inputArea, className, {
        [styles.inputArea__scrollable]: scrollable,
        [styles.disabled]: disabled
      })}
    >
      {!isHideAddonPrepend && addonPrepend}
      {
        <input
          data-testid={"RawInput_input"}
          ref={inputRef}
          type="text"
          className={cn(styles.inputAreaElement, styles.actualInput, {
            [styles.inputting]: isHideAddonPrepend
          })}
          value={rawText}
          onChange={event => onChangeRawText(event.target.value)}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          onClick={onClick}
        />
      }
      <div className={styles.positionRight}>{addonAppend}</div>
    </label>
  );
};
