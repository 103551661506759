import { useReduxState } from "hooks/useReduxState";
import { useDispatch } from "react-redux";
import {
  setHistoryModalOpenStatus,
  setAdvancedMode,
  historyReset,
  advancedHistoryReset
} from "modules/operandRawSearch/actions";
import { Witch } from "modules/operandRawSearch";
import { RawOperand } from "models/GlobalSearch";

export const useFormulaSearch = () => {
  const dispatch = useDispatch();

  const historyModalOpenStatus = useReduxState(
    state => state.operandRawSearch.saved.historyModalOpenStatus
  );

  const advancedMode = useReduxState(
    state => state.operandRawSearch.saved.advancedMode
  );

  const history = useReduxState(state => state.operandRawSearch.saved.history);

  const advancedHistory = useReduxState(
    state => state.operandRawSearch.saved.advancedHistory
  );

  const setHistoryModalStatus = (status: boolean) => {
    dispatch(setHistoryModalOpenStatus(status));
  };

  const setMode = (mode: boolean, witch: Witch) => {
    dispatch(setAdvancedMode(mode, witch));
  };

  const resetHistory = (history: string[], witch: Witch) => {
    dispatch(historyReset(history, witch));
  };

  const resetAdvancedHistory = (history: RawOperand[], witch: Witch) => {
    dispatch(advancedHistoryReset(history, witch));
  };

  return {
    historyModalOpenStatus,
    advancedMode,
    setHistoryModalStatus,
    setMode,
    history,
    advancedHistory,
    resetHistory,
    resetAdvancedHistory
  };
};
