import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  segmentCorp: {},
  industryMaxCount: 0,
  industryHead: null,
  industryData: null,
  error: null
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SEGMENT_CORP_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      segmentCorp: action.payload
    }),
    [`${ActionTypes.SEGMENT_CORP_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDUSTRY_MAX_COUNT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      industryMaxCount: action.payload.pageCount
    }),
    [`${ActionTypes.INDUSTRY_MAX_COUNT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDUSTRIES_HEAD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      industryHead: action.payload
    }),
    [`${ActionTypes.INDUSTRIES_HEAD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDUSTRIES_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      industryData: action.payload
    }),
    [`${ActionTypes.INDUSTRIES_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDUSTRIES_DATA_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      industryData: action.payload
    }),
    [`${ActionTypes.INDUSTRIES_DATA_SEARCH}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(354, params)),
  getSegmentCorp: createAction(ActionTypes.SEGMENT_CORP_GET, params =>
    request(356, params)
  ),
  getIndustryMaxCount: createAction(
    ActionTypes.INDUSTRY_MAX_COUNT_GET,
    params => request(362, params)
  ),
  getIndustryHead: createAction(ActionTypes.INDUSTRIES_HEAD_GET, params =>
    request(357, params)
  ),
  getIndustryData: createAction(ActionTypes.INDUSTRIES_DATA_GET, params =>
    request(358, params)
  ),
  searchIndustryData: createAction(ActionTypes.INDUSTRIES_DATA_SEARCH, params =>
    request(359, params)
  )
};
