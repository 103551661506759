import React from "react";
import styles from "./style.scss";
import Modal from "components/Common/Modal";
import Button from "components/Common/Button/Text";
import { CopyInput } from "../CopyInput";

interface Props {
  isOpen: boolean;
  title: string;
  description?: string;
  copyText: string;
  keepButtonText: string;
  closeButtonText?: string;
  onClickChangeButton: () => void;
  onClickCloseButton: () => void;
}

export const SearchModeModal: React.FunctionComponent<Props> = ({
  isOpen,
  title,
  description,
  copyText,
  keepButtonText,
  closeButtonText = "キャンセル",
  onClickChangeButton,
  onClickCloseButton
}) => (
  <div
    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    }}
  >
    <Modal
      title={title}
      isOpen={isOpen}
      uncontrolled
      modalClassName={styles.modalSizing}
      onClose={() => {
        onClickCloseButton();
      }}
    >
      <div className={styles.content}>{description}</div>
      <div className={styles.copyInput}>
        <CopyInput className={styles.operandRawInput} copyText={copyText} />
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          color="red"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            onClickChangeButton();
            onClickCloseButton();
          }}
          data-testid="BinderPage-Common-SearchModeModal-actionButton"
        >
          {keepButtonText}
        </Button>
        <Button
          color="navy"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            onClickCloseButton();
          }}
          data-testid="BinderPage-Common-SearchModeModal-cancelButton"
        >
          {closeButtonText}
        </Button>
      </div>
    </Modal>
  </div>
);

export default SearchModeModal;
