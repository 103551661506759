import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  periodFrom: null,
  periodTo: null,
  accKbn: null,
  unit: null,
  periodFromList: {},
  periodToList: {},
  accKbnList: {},
  unitList: {}
};

const setState = (state, action) => ({
  ...state,
  data: action.payload,
  periodFrom: action.payload.accFrom,
  periodTo: action.payload.accTo,
  accKbn: action.payload.accKbn,
  unit: action.payload.monetaryCode,
  periodFromList: action.payload.periodFromList,
  periodToList: action.payload.periodToList,
  accKbnList: action.payload.accKbnList,
  unitList: action.payload.unitList
});

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) =>
      setState(state, action),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.ACC_TYPE_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      periodFrom: action.payload.fromDate,
      periodTo: action.payload.toDate,
      periodFromList: action.payload.periodFromList,
      periodToList: action.payload.periodToList
    }),
    [`${ActionTypes.ACC_TYPE_CHANGE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        accFrom: action.payload.accFrom,
        accTo: action.payload.accTo,
        accKbn: action.payload.accKbn,
        monetaryCode: action.payload.monetaryCode,
        bakAccFrom: action.payload.bakAccFrom,
        bakAccTo: action.payload.bakAccTo,
        bakAccKbn: action.payload.bakAccKbn,
        bakMonetaryCode: action.payload.bakMonetaryCode,
        corpFinOverseasDto: action.payload.corpFinOverseasDto
      }
    }),
    [`${ActionTypes.CONDITION_CHANGE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.CONDITION_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(305, params)),
  changeAccType: createAction(ActionTypes.ACC_TYPE_CHANGE, params =>
    request(307, params)
  ),
  changeCondition: createAction(ActionTypes.CONDITION_CHANGE, params =>
    request(306, params)
  ),
  setCondition: createAction(
    ActionTypes.CONDITION_SET,
    x => x,
    (params, key) => ({ key })
  )
};
