// 初期表示
export const PAGE_INIT = "corp/disclosure/PAGE_INIT";
export const CONTENTS_SET = "corp/disclosure/CONTENTS_SET";
export const SORT_OPTIONS_SET = "corp/disclosure/SORT_OPTIONS_SET";
// オプション選択
export const SELECTED_OPTION_SET = "corp/disclosure/SELECTED_OPTION_SET";
// ページ再取得
export const PAGE_GET = "corp/disclosure/PAGE_GET";
// ページ遷移
export const PAGER_CHANGE = "corp/disclosure/PAGER_CHANGE";
