import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { actionCreators } from "modules/common/Popup";
import { actionCreators as downloadActionCreators } from "modules/download/actions";
import { actionCreators as toastActionCreators } from "modules/toast/actions";
import { setSessionState } from "modules/auth/temporary/actions";
import { setRedirectPath } from "modules/auth/saved/actions";

import ToastContext from "contexts/toast";
import DownloadContext from "contexts/download";

import Popup from "components/Common/Popup";

import Toast from "components/Common/Toast";

import { getPopupBody, getPopupTitle } from "utils/helper/localStorage";
import { generateResourceId } from "utils/helper/download";
import { findAnalyticsWithPopup, parseQuery } from "utils/helper/url";
import { initPage as atlasInitPage } from "utils/helper/analytics";

/**
 * ポップアップ
 * 本文未取得時urlパラメータ: /popup?keyBody=xxxxx&transitionId=xxxxx&pathname=xxxxx
 * 本文取得済み時urlパラメータ： /popup?hasBodyText=true
 *
 * 本文未取得時は、urlパラメータで本文取得APIリクエストパラメータを受け取ってDidMount時にリクエストする。
 * 本文取得APIのリクエスト先は画面ごとにあるので、pathnameをactionCreatorに渡してリクエスト先を制御する。
 *
 * 本文取得済み時は、ローカルストレージにあらかじめ保存された本文とタイトルを取得して表示する
 * @class PopupContainer
 * @extends {Component}
 */
class PopupContainer extends Component {
  constructor() {
    super();
    this.state = {
      resourceId: generateResourceId()
    };
    this.sendAtlas = this.sendAtlas.bind(this);
    this.addBeforeUnloadEventListener = this.addBeforeUnloadEventListener.bind(
      this
    );
    this.removeBeforeUnloadEventListener = this.removeBeforeUnloadEventListener.bind(
      this
    );
    this.clearErrorMessages = this.clearErrorMessages.bind(this);
  }

  componentDidMount() {
    const pathname = parseQuery(this.props.location.search).pathname;
    const search = new URLSearchParams(this.props.location.search);
    if (search.has("hasBodyText")) {
      // 本文取得済みの場合はローカルストレージから本文とタイトルを取得
      this.props.setBodyText(getPopupBody(), getPopupTitle());
    } else {
      //pathnameは本文取得APIリクエストに不要なので取り除く
      search.delete("pathname");
      // 日本語文字列を含む場合があるのでデコードしてからパース
      const params = parseQuery(decodeURIComponent(search.toString()));
      // Bizトレンド表示時は1ページ目を指定する
      if (pathname === "/home/trend-report") {
        params["currentPage"] = 1;
      }

      const handleResponse = res => {
        if (res.value) {
          const data =
            res.value.cpfText ||
            res.value.trendReportText ||
            res.value.telecomText ||
            res.value;
          this.sendAtlas(res.value.title, 1, data.mediaCode);
        }
        if (res instanceof Error) {
          // 認証失敗時
          if (res.message === "UNAUTHORIZED") {
            this.props.needAuthenticate();
          }
        }
      };

      if (params["mediaFeeType"] === "1" && params["keyBodyFare"] !== "0") {
        this.props.getArticlePreview(params).then(handleResponse);
      } else {
        if (typeof pathname === "string" && pathname.startsWith("/binder")) {
          if (params.mediaFeeType == 0) {
            this.props.getBinderArticleFreeText(params).then(handleResponse);
          } else {
            this.props.getBinderArticle(params).then(handleResponse);
          }
        } else {
          this.props.getBody(params, pathname).then(handleResponse);
        }
      }
    }

    // downloadResourceId をクリア
    this.props.stopDownload();

    this.addBeforeUnloadEventListener();
  }

  componentWillUnmount() {
    this.removeBeforeUnloadEventListener();
  }

  // Atlas ページビュー送信
  sendAtlas(title, pageNum, mediaCode) {
    // Atlas 情報取得
    const analyticsInfo = findAnalyticsWithPopup(location.search);
    if (analyticsInfo === undefined) return;

    // Atlas 送信
    atlasInitPage({
      ...analyticsInfo,
      planValue: this.props.contractPlanCode,
      contentName: title,
      pageNum,
      customObject: {
        media_code: mediaCode
      }
    });
  }

  // `before unload` イベント登録用関数
  addBeforeUnloadEventListener() {
    window.addEventListener("beforeunload", this.clearErrorMessages);
  }

  // `before unload` イベント削除用関数
  removeBeforeUnloadEventListener() {
    window.removeEventListener("beforeunload", this.clearErrorMessages);
    this.clearErrorMessages();
  }

  clearErrorMessages() {
    this.props.clearErrors();
  }

  render() {
    const params = parseQuery(this.props.location.search);
    const pathname = params.pathname;
    const sharesHoldingsProps = {
      ...this.props.data,
      getBody: this.props.getBody,
      selectedOption: this.props.selectedOption,
      setSelectedOption: this.props.setSelectedOption,
      resourceId: this.state.resourceId
    };
    const corpParams = {
      corpIndustyCode: params.corpIndustyCode ? params.corpIndustyCode : "",
      corpType: params.corpType ? params.corpType : "",
      corpIndustyKbn: params.corpIndustyKbn ? params.corpIndustyKbn : "",
      corpStkCode: params.corpStkCode ? params.corpStkCode : "",
      corpPlaceMarket: params.corpPlaceMarket ? params.corpPlaceMarket : ""
    };
    const trendReportProps = {
      title: this.props.data.title,
      body: this.props.data.body,
      displayTime: this.props.data.displayTime,
      kijiId: this.props.data.kijiId,
      companyInfo: this.props.data.companyInfo,
      industryInfo: this.props.data.industryInfo,
      articleKbn: this.props.data.articleKbn,
      mediaFeeType: this.props.data.mediaFeeType,
      mediaCode: this.props.data.mediaCode,
      keyBodyFare: this.props.data.keyBodyFare,
      mediaName: this.props.data.mediaName,
      wordCount: this.props.data.wordCount
    };
    const tdbPopupProps = {
      isPopupLoading: this.props.isPopupLoading
    };

    return (
      <ToastContext.Provider
        value={{
          setMessages: this.props.setMessages,
          setErrors: this.props.setErrors,
          clearMessages: this.props.clearMessages,
          clearErrors: this.props.clearErrors
        }}
      >
        <DownloadContext.Provider
          value={{
            downloadResourceId: this.props.download.downloadResourceId,
            startDownload: this.props.startDownload,
            stopDownload: this.props.stopDownload,
            needAuthenticate: this.props.needAuthenticate
          }}
        >
          <Popup
            {...this.props.data}
            downloadPdf={this.props.downloadPdf}
            downloadXls={this.props.downloadXls}
            downloadXbrl={this.props.downloadXbrl}
            getBody={this.props.getBody}
            pathname={pathname}
            downloadPdfKey={params.downloadPdfKey ? params.downloadPdfKey : ""}
            downloadXlsKey={params.downloadXlsKey ? params.downloadXlsKey : ""}
            downloaXbrlKey={params.downloaXbrlKey ? params.downloaXbrlKey : ""}
            downloadAttachKey={
              params.downloadAttachKey ? params.downloadAttachKey : ""
            }
            setToastMessages={this.props.setMessages}
            setToastErrors={this.props.setErrors}
            resourceId={this.state.resourceId}
            sharesHoldingsProps={sharesHoldingsProps}
            corpParams={corpParams}
            trendReportProps={trendReportProps}
            tdbPopupProps={tdbPopupProps}
            location={this.props.location}
            isMobileSize={this.props.isMobileSize}
          />
          <Toast
            messages={this.props.messages}
            errorMessages={this.props.errors}
            onClose={this.props.clearMessages}
            onErrorClose={this.props.clearErrors}
          />
        </DownloadContext.Provider>
      </ToastContext.Provider>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  query: ownProps.query,
  data: state.reducers.commonPopup.data,
  messages: state.reducers.toast.messages,
  errors: state.reducers.toast.errors,
  selectedOption: state.reducers.commonPopup.selectedOption,
  download: state.reducers.download,
  isPopupLoading: state.reducers.commonPopup.isPopupLoading,
  contractPlanCode: state.reducers.auth.saved.user.contractPlanCode,
  isMobileSize: state.reducers.global.isMobileSize
});

const mapDispatchToProps = (dispatch, props) => ({
  setMessages: messages => dispatch(toastActionCreators.setMessages(messages)),
  clearMessages: () => dispatch(toastActionCreators.clearMessages()),
  setErrors: messages => dispatch(toastActionCreators.setErrors(messages)),
  clearErrors: () => dispatch(toastActionCreators.clearErrors()),
  getBody: (params, path) => dispatch(actionCreators.getBody(params, path)),
  downloadPdf: (params, path) =>
    dispatch(actionCreators.downloadPdf(params, path)),
  downloadXls: (params, path) =>
    dispatch(actionCreators.downloadXls(params, path)),
  downloadXbrl: (params, path) =>
    dispatch(actionCreators.downloadXbrl(params, path)),
  setBodyText: (bodyText, title) =>
    dispatch(actionCreators.setBodyText(bodyText, title)),
  setSelectedOption: params =>
    dispatch(actionCreators.setSelectedOption(params)),
  // Donwload Context
  startDownload: resourceId =>
    dispatch(downloadActionCreators.startDownload(resourceId)),
  stopDownload: () => dispatch(downloadActionCreators.stopDownload()),
  needAuthenticate: () => {
    const location = props.location;
    const redirectPath = location.pathname + location.search + location.hash;
    dispatch(setSessionState("inactive"));
    dispatch(setRedirectPath(redirectPath));
    location.href = "/login";
  },
  getBinderArticle: params => dispatch(actionCreators.getBinderArticle(params)),
  getBinderArticleFreeText: params =>
    dispatch(actionCreators.getBinderArticleFreeText(params)),
  getArticlePreview: params =>
    dispatch(actionCreators.getArticlePreview(params))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PopupContainer)
);
