import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Sentry from "components/Sentry";
import setupLogService from "stores/setupLogService";
import { store, persistor } from "stores/store";
import App from "./App";

setupLogService();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Sentry>
        <App />
      </Sentry>
    </PersistGate>
  </Provider>,
  document.getElementById("app")
);

/* eslint-disable no-console */
console.log(`Release date: ${RELEASE_DATE}`);
console.log(`Version hash: ${VERSION_HASH}`);
