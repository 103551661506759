import React, { useEffect, useRef } from "react";
import styles from "./style.scss";
import Modal from "components/Common/Modal";
import Button from "components/Common/Button/Text";

interface Props {
  headline: string;
  fare: string;
  open: () => void;
  onClose: () => void;
}

interface ModalRef {
  open: () => void;
  close: () => void;
}

const PaidConfirm: React.FunctionComponent<Props> = props => {
  const modal = useRef<ModalRef | null>(null);

  useEffect(() => {
    modal.current && modal.current.open();
  }, []);

  return (
    <Modal
      ref={(el: ModalRef) => (modal.current = el)}
      title="有料コンテンツ閲覧の確認"
      modalClassName={styles.modal}
      onClose={() => props.onClose()}
    >
      <div className={styles.container}>
        <div className={styles.label}>見出し</div>
        <div>{props.headline}</div>
        <div className={styles.label}>料金</div>
        <div className={styles.fee}>{props.fare}</div>
        <div
          className={styles.buttonContainer}
          data-testid="features-Modal-PaidConfirm"
        >
          <Button
            color="navy"
            className={styles.button}
            onClick={() => modal.current && modal.current.close()}
          >
            閉じる
          </Button>
          <Button
            color="orange"
            className={styles.button}
            onClick={() => {
              props.open();
              modal.current && modal.current.close();
            }}
          >
            閲覧する
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaidConfirm;
