import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  corpList: [],
  corpFilter: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  isLoadingCorpList: false,
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...state,
      isLoadingNeedsMstL: true
    }),
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.searchMap01Dto.needsMstL,
      needsMstM: [],
      needsMstS: [],
      corpList: [],
      corpFilter: action.payload.searchMap01Dto.corpFilterDto,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_PENDING`]: state => {
      return {
        ...state,
        needsMstM: [],
        needsMstS: [],
        corpList: [],
        isLoadingNeedsMstM: true
      };
    },
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      corpList: [],
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      corpList: [],
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingNeedsMstS: false,
      error: action.payload
    }),
    [`${ActionTypes.CORP_GET}_PENDING`]: state => ({
      ...state,
      corpList: [],
      isLoadingCorpList: true
    }),
    [`${ActionTypes.CORP_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      corpList: action.payload.companyList,
      isLoadingCorpList: false
    }),
    [`${ActionTypes.CORP_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingCorpList: false,
      error: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(573, params)),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(574, params)
  ),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(575, params)
  ),
  getCorp: createAction(ActionTypes.CORP_GET, params => request(576, params))
};
