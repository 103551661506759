exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Loading-style__component--2r1ws {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n  position: absolute;\n  z-index: 2;\n  width: 100%;\n  height: 100%;\n  transition: opacity 200ms ease-in-out;\n  opacity: 0;\n  background-color: #fff;\n}\n.src-components-Common-Loading-style__component--2r1ws.src-components-Common-Loading-style__transparent--MTgZv {\n  background-color: rgba(255, 255, 255, 0.8);\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-components-Common-Loading-style__component--2r1ws",
	"transparent": "src-components-Common-Loading-style__transparent--MTgZv"
};