interface ObjectType {
  [key: string]: unknown;
}
const isObjectType = (arg: any): arg is ObjectType => {
  return typeof arg === "object" && arg !== null;
};

interface ObjectTypeArray {
  [key: string]: unknown[];
}

/**
 * isNotEmptyKeys
 * 対象のオブジェクトに検索対象のキーがすべて存在するかチェックする
 * すべて存在すれば true, それ以外は false を返す
 * @param {object} target 検索対象のオブジェクト
 * @param {array} keys 探すキーのリスト、ドット演算子を含めた孫以下の判定も可能
 *     keys: ["aaa"] or ["aaa.bbb"]
 */
export const isNotEmptyKeys = (target: ObjectType, keys: string[]): boolean => {
  if (!target || !keys) return false;
  return keys.every(key => {
    if (typeof key !== "string") return false;
    const keySpl = key.split(".");
    const currentKey = keySpl.splice(0, 1)[0];
    const currentValue = target[currentKey];
    return keySpl.length >= 1 && isObjectType(currentValue)
      ? isNotEmptyKeys(currentValue, [keySpl.join(".")])
      : target.hasOwnProperty(key) && target[key];
  });
};

/**
 * isEmpty
 * 対象のオブジェクトが空かどうかを返す、 空判定や false な判定であれば true
 * `new String("aa")` などのオブジェクト型は非想定
 * ex. 0 => true, NaN => true, 1 => false
 *     "" => true, "a" => false
 *     true => true, true => false
 *     [] => true, [3] => false
 *     {} => true, {"1": "2"} => false
 * @param {object|string|number|undefined} target 判定対象のオブジェクト
 */
export const isEmpty = (target: unknown): boolean => {
  if (!target) return true;
  const type = typeof target;
  if (type == "boolean" || type == "number") return false;
  if (typeof target == "object") {
    for (const _ in target) return false;
    return true;
  }
  return false;
};

/**
 * valueFromKeyOrDefault
 * 対象のオブジェクトに検索対象のキーがあれば、そのキーに対する値を返す
 * キーがない場合は、指定したデフォルト値を返す
 * @param {object} target 判定対象のオブジェクト
 * @param {string} key 検索対象のキー名、ドット演算子を含めた孫以下の判定も可能
 *     key: "aaa" or "aaa.bbb"
 * @param {object|boolean} defaultValue 存在しない場合のデフォルト値
 */
export const valueFromKeyOrDefault = (
  object: object,
  key: string,
  defaultValue: unknown
): unknown => {
  if (
    object == null ||
    typeof key !== "string" ||
    typeof object !== "object" ||
    !isObjectType(object)
  )
    return defaultValue;
  const keySpl = key.split(".");
  const currentValue = object[keySpl.splice(0, 1)[0]];
  return keySpl.length >= 1 && isObjectType(currentValue)
    ? valueFromKeyOrDefault(currentValue, keySpl.join("."), defaultValue)
    : object.hasOwnProperty(key) && object[key] !== undefined
    ? object[key]
    : defaultValue;
};

/**
 * 連続する数値の配列を作成
 * @param {number} length - 配列数
 * @param {?number} start - 最初の値
 * @param {?number} step - 公差
 * ex. makeSequentialNumberArray(5, 1) = [1, 2, 3, 4, 5]
 */
export const makeSequentialNumberArray = (
  length: number,
  start = 0,
  step = 1
) => {
  if (typeof length !== "number") length = Number(length);
  return Array.from(new Array(length)).map((_, i) => i * step + start);
};

/**
 * 与えた値の前方に0を埋めて返す
 * 桁数が足りない場合は文字列化したものを返す
 * @param {string | number} str - 0埋めしたい値
 * @param {number} length - 0埋め後の桁数
 * ex. fillZero("3", 4) = "0004"
 */
export const fillZero = (str: string | number, length = 2) => {
  if (typeof str === "number") str = str.toString();
  else if (typeof str !== "string") return str;
  if (str.length >= length) return str;
  let zeroStr = "";
  for (let i = 0; i < length; i++) zeroStr += "0";
  return (zeroStr + str).slice(-length);
};

/**
 * エラー番号からエラー文言を返す。
 * {0}や{1}を置き換える文字列を配列でわたせば、置換済みのエラー文言を返す。
 * エラー番号に対応するエラー文言がない場合は空文字を返す。
 * @param {string} key エラー番号（"W"+４桁の数字）
 * @param {array} words {0}や{1}を置き換える文字列の配列["{0}を置き換える文字列", "{1}を置き換える文字列"]
 */
export const loadErrorMessage = (key: string, words: string[] = []) => {
  const messages = require("../json/errorMessage.json");
  if (messages[key] === undefined) return "";
  if (words.length === 0) return messages[key];
  let message = messages[key];
  for (const index in words) {
    message = message.replace(`{${index}}`, words[index]);
  }
  return message;
};

/**
 * 文字数をカウント
 * ※ 半角は1文字、全角は2文字としてカウントする
 * @param {string} target - 対象文字列
 * @returns {number} 文字数
 */
export const countCharacter = (target: string) => {
  if (typeof target !== "string") return 0;

  let count = 0;
  for (let i = 0; i < target.length; i++) {
    const chr = target.charCodeAt(i);
    if (
      (chr >= 0x00 && chr < 0x81) ||
      chr === 0xf8f0 ||
      (chr >= 0xff61 && chr < 0xffa0) ||
      (chr >= 0xf8f1 && chr < 0xf8f4)
    ) {
      //半角文字の場合は1を加算
      count += 1;
    } else {
      //それ以外の文字の場合は2を加算
      count += 2;
    }
  }

  return count;
};

/**
 * ２つの値が型を含めて同じか
 * @param {*} base 比較元
 * @param {*} target 比較対象
 * @returns {boolean}
 */
export const isEqual = (base: unknown, target: unknown): boolean => {
  if (base === null || target === null) {
    return base === target;
  }
  const baseType = typeof base;
  if (baseType !== typeof target) return false;
  switch (baseType) {
    case "object":
      if (base === null) return target === null;
      if (
        isObjectType(base) &&
        isObjectType(target) &&
        Object.keys(base).length !== Object.keys(target).length
      )
        return false;
      return (
        isObjectType(base) &&
        isObjectType(target) &&
        Object.keys(base).every(key => {
          if (!target.hasOwnProperty(key)) return false;
          return isEqual(base[key], target[key]);
        })
      );
    default:
      return base === target;
  }
};

/**
 * IE 判定
 */
export const isIE = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1;
};

/**
 * FireFox 判定
 */
export const isFireFox = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf("firefox") != -1;
};

/**
 * 渡されたitemがObjectかどうかのチェックを実施
 * 配列の場合はfalseを返す
 * `new String("aa")` などのオブジェクト型は非想定
 * @param {*} item チェックするitem
 * @returns {boolean}
 */
export const isObject = (item: unknown) =>
  typeof item === "object" && item !== null && !Array.isArray(item);

/**
 * 要素のdeepcopyを実施する
 * `Date型` や `new String("aa")` などのオブジェクト型は非想定
 * @param {*} obj コピーするオブジェクト
 * @returns {boolean}
 */
export const deepClone = (obj: unknown) => {
  if (!isObject(obj) && !Array.isArray(obj)) return obj;
  const r: unknown = Array.isArray(obj) ? [] : {};
  if (typeof obj !== "object") return;
  for (const name in obj) {
    if (!isObjectType(obj) || !isObjectType(r)) return;
    const currentObject = obj[name];
    isObject(currentObject)
      ? (r[name] = deepClone(currentObject))
      : Array.isArray(currentObject)
      ? (r[name] = deepClone(currentObject))
      : (r[name] = currentObject);
  }
  return r;
};

/**
 * ツリー構造から `isSelected` プロパティが `true` であるアイテムのみを平坦化して抽出する
 * @param {any[]} items ツリー構造リスト
 * @param {string} childKey 子要素を特定するためのキー
 */
export const getFlattenRecursiveSelected = (
  items: unknown[],
  childKey = "child"
): unknown[] => {
  return (
    Array.isArray(items) &&
    items.reduce((selected: object[], item: ObjectTypeArray) => {
      const { [childKey]: children, ...parent } = item;
      return [
        ...selected,
        ...(parent.isSelected ? [parent] : []),
        ...(!isEmpty(children)
          ? getFlattenRecursiveSelected(children, childKey)
          : [])
      ];
    }, [])
  );
};

/**
 * 前後の全半角空白を除去
 * @param {string} target 対象文字列
 * @returns {string}
 */
export const trimAllSpace = (target: unknown) => {
  const tmp = typeof target !== "string" ? String(target) : target;
  return tmp.replace(/^[\s　]+|[\s　]+$/g, ""); // eslint-disable-line no-irregular-whitespace
};
