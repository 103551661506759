import { AccessControlAction } from "./actions";
import { Reducer } from "redux";

export type AccessStatus =
  | ""
  | "SKIPPED"
  | "PENDING"
  | "AUTHORIZED"
  | "DENIED"
  | "OPTION"
  | "OPTION2"
  | "UNAVAILABLE";

export interface AccessControlState {
  accessState: AccessStatus;
}

const initialState: AccessControlState = {
  accessState: ""
};

export const accessControl: Reducer<AccessControlState, AccessControlAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "accessControl/ACCESS_STATUS_UPDATE":
      return { accessState: action.payload };
    default:
      return state;
  }
};
