import { AuthSavedState } from "./index";
import {
  NidAuth,
  UserInfo,
  LoginError,
  CollectionAuthority
} from "models/Auth";

type ChargeCodeParams = Pick<
  AuthSavedState,
  "hasChargeCode" | "chargeCode1" | "chargeCode2"
>;

export const saveChargeCode = (arg: ChargeCodeParams) => ({
  type: "auth/saved/SAVE_CHARGE_CODE" as const,
  payload: arg
});

export const clearChargeCode = () => ({
  type: "auth/saved/CLEAR_CHARGE_CODE" as const
});

export const setNidAuth = (nidAuth: NidAuth) => ({
  type: "auth/saved/SET_NID_AUTH" as const,
  payload: nidAuth
});

export const saveLastInput = (chargeCodes: {
  hasChargeCode: boolean;
  chargeCode1: string;
  chargeCode2: string;
}) => ({
  type: "auth/saved/SAVE_LAST_INPUT" as const,
  payload: chargeCodes
});

export const setUserInfo = (userInfo: UserInfo) => ({
  type: "auth/saved/SET_USER_INFO" as const,
  payload: userInfo
});

export const setCollectionAuthority = (
  collectionAuthority: CollectionAuthority
) => ({
  type: "auth/saved/SET_COLLECTION_AUTHORITY" as const,
  payload: collectionAuthority
});

export const setRedirectPath = (path: string) => ({
  type: "auth/saved/SET_REDIRECT_PATH" as const,
  payload: path
});

export const clearRedirectPath = () => ({
  type: "auth/saved/CLEAR_REDIRECT_PATH" as const
});

export const setError = (errors: LoginError) => ({
  type: "auth/saved/SET_ERROR" as const,
  payload: errors
});

export const clearError = () => ({
  type: "auth/saved/CLEAR_ERROR" as const
});

export const clearAuth = () => ({
  type: "auth/saved/CLEAR_AUTH" as const
});

export const readNotice = (readNoticeTitle: string[]) => ({
  type: "auth/saved/READ_NOTICE" as const,
  payload: readNoticeTitle
});

export const downloadManagementLastViewingTime = () => ({
  type: "auth/saved/DOWNLOAD_MANAGEMENT_LAST_VIEWING_TIME" as const
});

export type AuthSavedAction = ReturnType<
  | typeof saveChargeCode
  | typeof clearChargeCode
  | typeof setNidAuth
  | typeof saveLastInput
  | typeof setUserInfo
  | typeof setCollectionAuthority
  | typeof setRedirectPath
  | typeof clearRedirectPath
  | typeof setError
  | typeof clearError
  | typeof clearAuth
  | typeof readNotice
  | typeof downloadManagementLastViewingTime
>;
