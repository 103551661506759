exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-SectionLoading-style__component--3nW-V {\n  min-width: 100%;\n  min-height: 48px;\n}\n\n.src-components-Common-SectionLoading-style__loadingWrapper--2ZstW {\n  position: relative;\n}\n\n.src-components-Common-SectionLoading-style__loading--2-oHr {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n  position: absolute;\n  z-index: 1;\n  width: 100%;\n  height: 100%;\n  min-height: 48px;\n  transition: opacity 200ms ease-in-out;\n  background-color: white;\n}\n.src-components-Common-SectionLoading-style__loading--2-oHr.src-components-Common-SectionLoading-style__transparent--3H-c1 {\n  background-color: transparent;\n}\n\n.src-components-Common-SectionLoading-style__wrapped---cIyg {\n  position: relative;\n}\n\n.src-components-Common-SectionLoading-style__fitParent--2A8ED {\n  width: 100%;\n  height: 100%;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-components-Common-SectionLoading-style__component--3nW-V",
	"loadingWrapper": "src-components-Common-SectionLoading-style__loadingWrapper--2ZstW",
	"loading": "src-components-Common-SectionLoading-style__loading--2-oHr",
	"transparent": "src-components-Common-SectionLoading-style__transparent--3H-c1",
	"wrapped": "src-components-Common-SectionLoading-style__wrapped---cIyg",
	"fitParent": "src-components-Common-SectionLoading-style__fitParent--2A8ED"
};