import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import styles from "./style.scss";

import { isEmpty } from "utils/helper/common";
import { download, generateResourceId } from "utils/helper/download";

import DownloadButton from "components/Common/Button/Download";
import toast from "../Toast/enhance";
import DownloadContext, { DownloadContextProperties } from "contexts/download";
import { isEqual } from "lodash";

/**
 * 出力ボタン
 *
 * @prop {?string} dlpropriety - DL可否 "9"ならDLボタン非活性
 * @prop {array} files - ファイルダウンロード情報、配列に入れた順番に表示される。
 * 表示しないボタンは入れなくて良い。
 * files = [
 *   { type: "xls", params: {}, key: "key1" },
 *   { type: "csv", params: {}, key: "key2" },
 *   { type: "pdf", params: {}, key: "key3", disabledFlags: ["keyBinary", "keyPdf"] },
 *   { type: "xbrl", params: {}, key: "key4", disabledFlags: ["keyBinary"] },
 *   { type: "document", params: {}, key: "key5" },
 * ]
 */

type Type = "xls" | "csv" | "pdf" | "xbrl" | "document" | "sample";

export interface File {
  type: Type;
  params: { [key: string]: any };
  key: string;
  disabledFlags?: string[];
  getSvgData?: any;
  svgInfos?: {
    key: string;
    index: number;
  }[];
  dl_file_path?: string;
}

interface Handler {
  handleClick: (
    file: File,
    resourceId: string,
    _download: DownloadContextProperties
  ) => Promise<void>;
}

interface Props {
  files: File[];
  isLarge?: boolean;
  isSmall?: boolean;
  dlpropriety?: string;
  resourceId?: string;
  btnRef?: React.RefObject<HTMLButtonElement> | null;
  onClick?: (type: Type) => "cancel" | void;
  handleClick?: (file: File, resourceId: string) => void;
  setToastErrors?: (message: string) => void;
  ref?: React.RefObject<Handler>;
  isSendAtlas?: boolean;
  sendAtlas?: (downloadFileType: string) => void;
}

const priority: { [key: string]: number } = {
  xls: 1,
  csv: 2,
  pdf: 3,
  xbrl: 4,
  document: 5
};

export const ExportButton: React.ForwardRefRenderFunction<Handler, Props> = (
  props,
  ref
) => {
  const [resourceId, setResourceId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const prevFiles = useRef<File[] | null>(null);

  useEffect(() => {
    const resourceId =
      !props.resourceId || isEmpty(props.resourceId)
        ? generateResourceId()
        : props.resourceId;
    setResourceId(resourceId);
  }, [props.resourceId]);

  useEffect(() => {
    if (!isEqual(prevFiles.current, props.files)) {
      setIsLoading(true);
    }
    prevFiles.current = props.files;
  }, [props.files]);

  // resourceId: 対象ボタンの一意ID
  // _download:
  const handleClick = async (
    file: File,
    resourceId: string,
    _download: DownloadContextProperties
  ) => {
    // ダウンロード中であれば処理しない
    if (!isEmpty(_download.downloadResourceId)) return;

    if (props.handleClick && !isEmpty(props.handleClick)) {
      props.handleClick(file, resourceId);
      return;
    }

    if (props.onClick) {
      const clicked = props.onClick(file.type);
      if (clicked === "cancel") return;
    }

    // ダウンロードパラメーター揃っていなければダウンロードしない
    if (
      !file.hasOwnProperty("params") ||
      isEmpty(file.params) ||
      !file.hasOwnProperty("key") ||
      isEmpty(file.key)
    ) {
      return;
    }

    if (props.isSendAtlas && props.sendAtlas) {
      props.sendAtlas(file.key);
    }

    // DL アニメーションする resourceId を登録
    _download.startDownload(resourceId);

    await download(file).catch(error => {
      // 401 であれば再ログイン要求
      if (error.body == "UNAUTHORIZED") {
        _download.needAuthenticate();
      } else if (error.body == "GATEWAY_TIMEOUT") {
        props.setToastErrors &&
          props.setToastErrors("タイムアウトが発生しました");
      } else {
        props.setToastErrors && props.setToastErrors(error.message);
      }
    });
    // DL アニメーションする resourceId を解除
    _download.stopDownload();
  };

  useImperativeHandle(ref, () => ({
    handleClick: handleClick
  }));

  const files = [...props.files].sort(
    (a, b) => priority[a.type] - priority[b.type]
  );

  const _download = useContext(DownloadContext);

  return (
    <div className={styles.component}>
      {files.map(file => {
        // dlpropriety==="9"もしくはdisabledFlagsに指定されたパラメータがすべて空ならグレー表示(disabled)にする
        const disabled =
          props.dlpropriety === "9" ||
          (Array.isArray(file.disabledFlags) &&
            file.disabledFlags.every(flg => {
              const param = file.params[flg];
              return isEmpty(param);
            }));

        const fileResourceId = `${resourceId}_${file.type}`;

        if (file.getSvgData) {
          file.getSvgData().then((data: string[]) => {
            if (data && data.length > 0) {
              setIsLoading(false);
            }
          });

          return (
            <DownloadButton
              key={file.type}
              type={file.type}
              onClick={() => handleClick(file, fileResourceId, _download)}
              isLarge={props.isLarge}
              isSmall={props.isSmall}
              disabled={disabled || isLoading}
              className={styles.button}
              isDownloading={_download.downloadResourceId === fileResourceId}
              btnRef={props.btnRef || null}
            />
          );
        } else {
          return (
            <DownloadButton
              key={file.type}
              type={file.type}
              onClick={() => handleClick(file, fileResourceId, _download)}
              isLarge={props.isLarge}
              isSmall={props.isSmall}
              disabled={disabled}
              className={styles.button}
              isDownloading={_download.downloadResourceId === fileResourceId}
              btnRef={props.btnRef || null}
            />
          );
        }
      })}
    </div>
  );
};

export default toast(
  React.forwardRef(ExportButton)
) as React.ForwardRefRenderFunction<Handler, Props>;
