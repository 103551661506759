import React, { useState, useEffect, useMemo, useRef } from "react";
import styles from "./style.scss";
import cn from "classnames";

interface Props {
  text?: string;
  content?: React.ReactNode;
  className?: string;
  slideLength?: string;
  position?: "top" | "right" | "bottom" | "left" | "rightTop" | "leftTop";
  tooltipWidth?: number;
  textAlign?: string;
  fadeout?: boolean;
  onFadeoutEnded?: () => void;
}

export const ToolTip: React.FunctionComponent<Props> = ({
  children,
  content,
  text,
  className = "",
  slideLength = "0px",
  position = "top",
  tooltipWidth = 210,
  fadeout = false,
  onFadeoutEnded
}) => {
  const slideLengthNumber = Number(slideLength.split("px")[0]);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!childrenRef.current) return;
    const clientRects = childrenRef.current.getClientRects()[0];
    if (clientRects) {
      setWidth(clientRects.width);
    }
  }, [childrenRef]);

  const marginLeft = useMemo(() => {
    switch (position) {
      case "top":
      case "bottom":
        return `${-tooltipWidth / 2 + slideLengthNumber}px`;
      case "left":
        return childrenRef.current ? `-${width / 2 + tooltipWidth + 5}px` : 0;
      case "right":
        return childrenRef.current ? `${width / 2 + 5}px` : 0;
    }
  }, [position, slideLengthNumber, tooltipWidth, width]);

  return (
    <div
      className={cn(styles.component, className, { [styles.fadeout]: fadeout })}
      onAnimationEnd={onFadeoutEnded}
      ref={childrenRef}
    >
      {children}
      <div
        data-testid="features-ToolTip-tooltip"
        className={cn(
          styles.tooltip,
          { [styles.top]: position === "top" },
          { [styles.bottom]: position === "bottom" },
          { [styles.left]: position === "left" },
          { [styles.right]: position === "right" },
          { [styles.rightTop]: position === "rightTop" },
          { [styles.leftTop]: position === "leftTop" }
        )}
        style={{
          width: `${tooltipWidth}px`,
          marginLeft: marginLeft
        }}
      >
        <div className={styles.description}>
          {content}
          {text}
        </div>
        <div>
          <div
            className={styles.arrow}
            style={{
              marginLeft: `${-slideLengthNumber -
                (position === "right"
                  ? -slideLengthNumber || 10
                  : position === "left"
                  ? 0
                  : 5)}px`
            }}
          />
        </div>
      </div>
    </div>
  );
};
