/**
 * 業界から選択
 */
// 初期表示
export const PAGE_INIT = "common/IRDataSearch/PAGE_INIT";
// 業界から選択 選択可能リスト項目取得
export const SERIES_DATA_GET = "common/IRDataSearch/SERIES_DATA_GET";
// 業界から選択 選択可能リストのチェックボックスを選択時処理。選択済み項目を追加
export const SELECTED_ITEMS_ADD = "common/IRDataSearch/SELECTED_ITEMS_ADD";
// 業界から選択 選択済み項目並び替え・削除時処理。選択済み項目を更新
export const SELECTED_ITEMS_REPLACE =
  "common/IRDataSearch/SELECTED_ITEMS_REPLACE";
// 業界から選択 選択済み項目全件削除時処理。選択済み項目を更新
export const ALL_SELECTED_ITEMS_REMOVE =
  "common/IRDataSearch/ALL_SELECTED_ITEMS_REMOVE";
// 選択した統計の IR データを取得
export const IR_DATA_GET = "common/IRDataSearch/IR_DATA_GET";
//IR データのクリア
export const IR_DATA_CLEAR = "common/IRDataSearch/IR_DATA_CLEAR";
// コンディション変更フラグの有効化
export const CHANGE_CONDITION_STATUS_ENABLE =
  "common/IRDataSearch/CHANGE_CONDITION_STATUS_ENABLE";

/**
 * 業界指定モーダル
 */
// 業界大分類取得
export const GET_NEEDS_MST_L = "common/IRDataSearch/GET_NEEDS_MST_L";
// 業界中文類取得
export const GET_NEEDS_MST_M = "common/IRDataSearch/GET_NEEDS_MST_M";
// 業界小分類取得
export const GET_NEEDS_MST_S = "common/IRDataSearch/GET_NEEDS_MST_S";

/**
 * キーワード検索
 */
export const SEARCH = "common/IRDataSearch/SEARCH";
export const CLEAR_SEARCH_RESULT = "common/IRDataSearch/CLEAR_SEARCH_RESULT";

/**
 * マイフォルダ
 */
export const GET_MY_FOLDER_LIST = "common/IRDataSearch/GET_MY_FOLDER_LIST";
export const DELETE_MY_FOLDER = "common/IRDataSearch/DELETE_MY_FOLDER";
export const RENAME_MY_FOLDER = "common/IRDataSearch/RENAME_MY_FOLDER";

/**
 * コレクション
 */
export const GET_BINDER_LIST = "common/IRDataSearch/GET_BINDER_LIST";
export const GET_STATISTICS = "common/IRDataSearch/GET_STATISTICS";

/**
 * データ表示部
 */
export const CONDITION_PARAMS_SET = "common/IRDataSearch/CONDITION_PARAMS_SET";
export const SHOULD_CONDITIONS_INITIALIZE_SET =
  "common/IRDataSearch/SHOULD_CONDITIONS_INITIALIZE_SET";

/**
 * 業界概要からの遷移
 */
export const INIT_PARAMETER_SET = "common/IRDataSearch/INIT_PARAMETER_SET";

/**
 * STORE初期化
 */
export const ALL_DATA_RESET = "common/IRDataSearch/ALL_DATA_RESET";
