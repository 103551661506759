import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  // 設定状況 --------------------
  alerts: [], // 保存済みアラート設定
  checkedAlertIds: [], // 選択されたアラート設定のID

  // 企業選択 --------------------
  needsMstL: [], // 業界大分類
  needsMstM: [], // 業界中分類
  needsMstS: [], // 業界小分類
  companyList: [], // （業界分類から取得した）企業

  // 条件登録 --------------------
  alertId: "", // アラートID
  alertName: "", // アラート設定の名称
  alertKeyword: "", // アラート条件のフリーキーワード（入力文字列そのまま）
  conditionCompanyList: [], // 条件に追加した企業
  alertPreview: [] // 設定したアラート条件でのプレビュー結果表示用データ
};

export default handleActions(
  {
    [`${ActionTypes.ALL_ALERT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      alerts: action.payload.timelyIndicatAlertInfo
    }),
    [ActionTypes.ALERT_NAME_UPDATE]: (state, action) => ({
      ...state,
      alertName: action.payload.alertName
    }),
    [ActionTypes.ALERT_KEYWORD_UPDATE]: (state, action) => ({
      ...state,
      alertKeyword: action.payload.alertKeyword
    }),
    [ActionTypes.CONDITION_CLEAR]: state => ({
      ...state,
      alertId: "",
      alertName: "",
      alertKeyword: "",
      conditionCompanyList: [],
      alertPreview: []
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      needsMstM: [],
      needsMstS: [],
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      companyList: []
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      companyList: []
    }),
    [`${ActionTypes.COMPANY_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      companyList: action.payload.companyList
    }),
    [ActionTypes.COMPANY_CONDITION_ADD]: (state, action) => {
      const newCompanyList = [...state.conditionCompanyList];
      const existsCompanyCode = state.conditionCompanyList.map(
        item => item.companyCode
      );

      action.payload.forEach(item => {
        if (!existsCompanyCode.includes(item.companyCode)) {
          newCompanyList.push(item);
        }
      });
      return {
        ...state,
        conditionCompanyList: newCompanyList
      };
    },
    [ActionTypes.COMPANY_CONDITION_REMOVE]: (state, action) => {
      const codes = action.payload;
      const newCompanyList = state.conditionCompanyList.filter(
        company => !codes.includes(company.companyCode)
      );
      return {
        ...state,
        conditionCompanyList: newCompanyList
      };
    },
    [`${ActionTypes.ALERT_GET}_FULFILLED`]: (state, action) => {
      const conditionCompanyList = action.payload.corpInfoList.map(company => {
        return {
          companyCode: company.nkcode,
          companyName: company.ccname,
          kabushikiCode: company.stkcode
        };
      });
      return {
        ...state,
        alertId: action.meta.selAlertId,
        alertName: action.meta.alertName,
        alertKeyword: action.meta.alertKeyword,
        conditionCompanyList: conditionCompanyList
      };
    },
    [`${ActionTypes.ALERT_PREVIEW_GET}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        freeKeyword: action.payload.freeKeyword,
        selectedCorpStkCodeAlert: action.payload.selectedCorpStkCodeAlert,
        alertPreview: action.payload.showDataInfo
      };
    },
    [`${ActionTypes.ALERT_SAVE}_FULFILLED`]: (state, action) => ({
      ...state,
      alerts: action.payload.timelyIndicatAlertInfo
    }),
    [`${ActionTypes.ALERT_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      checkedAlertIds: [],
      alerts: action.payload.timelyIndicatAlertInfo
    }),
    [ActionTypes.CHECKED_ALERT_ID_UPDATE]: (state, action) => ({
      ...state,
      checkedAlertIds: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  getAllAlerts: createAction(ActionTypes.ALL_ALERT_GET, params =>
    request(781, params)
  ),
  updateAlertName: createAction(ActionTypes.ALERT_NAME_UPDATE),
  updateAlertKeyword: createAction(ActionTypes.ALERT_KEYWORD_UPDATE),
  clearCondition: createAction(ActionTypes.CONDITION_CLEAR),
  getNeedsMstL: createAction(ActionTypes.NEEDS_MST_L_GET, params =>
    request(766, params)
  ),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(767, params)
  ),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(768, params)
  ),
  getCompany: createAction(ActionTypes.COMPANY_GET, params =>
    request(780, params)
  ),
  addCompanyToCondition: createAction(ActionTypes.COMPANY_CONDITION_ADD),
  removeCompanyFromCondition: createAction(
    ActionTypes.COMPANY_CONDITION_REMOVE
  ),
  getAlert: createAction(
    ActionTypes.ALERT_GET,
    params => request(779, params.payload),
    params => params.meta
  ),
  getAlertPreview: createAction(ActionTypes.ALERT_PREVIEW_GET, params =>
    request(778, params)
  ),
  saveAlert: createAction(ActionTypes.ALERT_SAVE, params =>
    request(781, params)
  ),
  deleteAlert: createAction(ActionTypes.ALERT_DELETE, params =>
    request(781, params)
  ),
  updateCheckedAlertId: createAction(ActionTypes.CHECKED_ALERT_ID_UPDATE)
};
