exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-LoadingSpinner-style__loading--QIPsv {\n  width: 24px;\n  height: 24px;\n  animation: src-components-Common-LoadingSpinner-style__spin--2vzxo 1s linear infinite;\n}\n.src-components-Common-LoadingSpinner-style__loading--QIPsv.src-components-Common-LoadingSpinner-style__sizeS--2lCsI {\n  width: 12px;\n  height: 12px;\n}\n.src-components-Common-LoadingSpinner-style__loading--QIPsv.src-components-Common-LoadingSpinner-style__sizeL--24lvo {\n  width: 36px;\n  height: 36px;\n}\n\n@keyframes src-components-Common-LoadingSpinner-style__spin--2vzxo {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"loading": "src-components-Common-LoadingSpinner-style__loading--QIPsv",
	"spin": "src-components-Common-LoadingSpinner-style__spin--2vzxo",
	"sizeS": "src-components-Common-LoadingSpinner-style__sizeS--2lCsI",
	"sizeL": "src-components-Common-LoadingSpinner-style__sizeL--24lvo"
};