exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Popup-Tdb-style__header--9JkBD {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  border-bottom: 1px solid #eaf1f5;\n  padding-bottom: 16px;\n  margin-bottom: 24px;\n}\n.src-components-Common-Popup-Tdb-style__header--9JkBD button {\n  width: 240px;\n  height: 48px;\n}\n\n.src-components-Common-Popup-Tdb-style__text--2bP38 {\n  text-align: center;\n  margin: 16px;\n}\n\n@media print {\n  @page {\n    margin: 5mm;\n  }\n  button {\n    display: none !important;\n  }\n\n  thead {\n    display: table-row-group;\n  }\n\n  th,\ntd {\n    font-size: 10px;\n    padding: 2px !important;\n  }\n\n  footer {\n    display: none !important;\n  }\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"header": "src-components-Common-Popup-Tdb-style__header--9JkBD",
	"text": "src-components-Common-Popup-Tdb-style__text--2bP38"
};