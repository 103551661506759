import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

const initialState = {
  data: {}
};

export default handleActions(
  {
    [ActionTypes.RESULT_DATA_SET]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [ActionTypes.RESULT_DATA_CLEAR]: state => ({
      ...state,
      data: {}
    })
  },
  initialState
);

export const actionCreators = {
  setResultData: createAction(ActionTypes.RESULT_DATA_SET),
  clearResultData: createAction(ActionTypes.RESULT_DATA_CLEAR)
};
