import React from "react";
import styles from "./style.scss";
import { isEmpty, makeSequentialNumberArray } from "utils/helper/common";
import Heading from "components/Common/Heading/index";

const createTable = (head, data, style = "") => (
  <table className={`${styles.vsTable} ${styles.innerTable} ${style}`}>
    <thead>
      <tr>
        {head.map((h, i) => (
          <th key={i}>{h}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((d, i) => (
        <tr key={i}>
          {d.map((text, i) => (
            <td key={i}>{text}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const createCorpDataList = data => [
  [{ title: "会社名", body: data.companyNameInKANJI }],
  [
    { title: "会社種別", body: data.stockDisclosureState },
    { title: "コード", body: data.tdbcompanyCode }
  ],
  [
    {
      title: "所在地",
      body: `〒${data.companyPostalCode} ${data.companyAddress}`
    }
  ],
  [{ title: "代表者", body: data.chiefExecutiveOfficerNameInKANJI }],
  [{ title: "電話番号", body: data.companyPhoneNumber }],
  [{ title: "設立", body: data.companyEstablishedDate }],
  [{ title: "創業", body: data.companyBusinessStartDate }],
  [
    {
      title: "業種名",
      body: (() => {
        const industryNameList = [
          `（主業種）${data.primaryIndustrySectorNameInKANJI}`
        ];
        if (!isEmpty(data.secondaryIndustrySectorNameInKANJI)) {
          industryNameList.push(
            `（従業種）${data.secondaryIndustrySectorNameInKANJI}`
          );
        }
        return industryNameList.join("\n");
      })()
    }
  ],
  [
    {
      title: "資本金",
      body: data.companyCapital ? data.companyCapital + " 千円" : ""
    }
  ],
  [
    {
      title: "従業員",
      body: data.numberOfEmployees ? data.numberOfEmployees + " 名" : ""
    }
  ],
  [
    {
      title: "株主数",
      body: data.numberOfStockholder ? data.numberOfStockholder + " 名" : ""
    }
  ],
  [
    {
      title: "株主",
      body: (() => {
        const re = /stockholderName\d+(?!\D)/;
        const validStockholderNameKeys = Object.keys(data).filter(elem => {
          return re.test(elem) && data[elem];
        });
        const head = ["株主名", "持株数"];
        const keyNums = makeSequentialNumberArray(
          validStockholderNameKeys.length,
          1
        );
        const body = keyNums.map(num => [
          data["stockholderName" + num],
          data["numberOfSharesHeld" + num] + " 株"
        ]);
        return createTable(head, body);
      })()
    }
  ],
  [
    {
      title: "役員",
      body: (() => {
        const re = /executiveOfficerNameKanji\d+(?!\D)/;
        const validExecutiveOfficerNameKanjiKeys = Object.keys(data).filter(
          elem => {
            return re.test(elem) && data[elem];
          }
        );
        const head = ["役職名", "氏名"];
        const keyNums = makeSequentialNumberArray(
          validExecutiveOfficerNameKanjiKeys.length,
          1
        );
        const body = keyNums.map(num => [
          data["executiveOfficerPosition" + num],
          data["executiveOfficerNameKanji" + num]
        ]);
        return createTable(head, body);
      })()
    }
  ],
  [{ title: "事業所数", body: data.numberOfBranches }],
  [{ title: "取引銀行", body: data.lineBankList.join("\n") }],
  [{ title: "仕入先", body: data.supplierList.join("\n") }],
  [{ title: "販売先", body: data.clientList.join("\n") }],
  [
    {
      title: "業績",
      body: (() => {
        const head = ["決算期", "売上(百万円)", "利益(千円)", "資本構成"];
        const keyNums = makeSequentialNumberArray(3, 6, -1);
        const body = keyNums.map(num => [
          data["dateOfSettlement" + num],
          data["salesRevenue" + num],
          data["profitAfterTax" + num],
          data["capitalAdequacyRatio" + num] + "%"
        ]);
        return createTable(head, body, styles.right);
      })()
    }
  ],
  [
    {
      title: "順位",
      body: (
        <table
          className={`${styles.vsTable} ${styles.innerTable} ${styles.right}`}
        >
          <caption className={styles.caption}>業種別売上高</caption>
          <tbody>
            <tr>
              <th>全国</th>
              <td>{data.numberOfCompanyInNation} 社</td>
              <td>{data.rankingInNation} 位</td>
            </tr>
            <tr>
              <th>県</th>
              <td>{data.numberOfCompanyInPrefecture} 社</td>
              <td>{data.rankingInPrefecture} 位</td>
            </tr>
          </tbody>
        </table>
      )
    }
  ],
  [{ title: "評点", body: data.creditRating }]
];

const createExOfficerDataList = data => [
  { title: "役職", body: data.chiefExecutiveOfficerPosition },
  {
    title: "氏名",
    body: `${data.chiefExecutiveOfficerNameInKANJI}(${data.chiefExecutiveOfficerNameInKANA})`
  },
  { title: "生年月日", body: data.chiefExecutiveOfficerBirthDate }
];

const CompanyTable = ({ data }) => {
  return (
    <React.Fragment>
      <Heading className={styles.title} level="3">
        企業情報
      </Heading>
      <table
        className={styles.vsTable}
        data-testid="Common-Popup-Tdb-CompanyTable"
      >
        <tbody>
          {createCorpDataList(data).map((corpData, index) => (
            <tr key={index}>
              {corpData.map((d, i) => (
                <React.Fragment key={i}>
                  <th>{d.title}</th>
                  <td colSpan={corpData.length > 1 ? 1 : 3}>{d.body}</td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Heading className={styles.title} level="3">
        代表者プロフィル
      </Heading>
      <table className={styles.vsTable}>
        <tbody>
          {createExOfficerDataList(data).map((data, i) => (
            <tr key={i}>
              <th>{data.title}</th>
              <td>{data.body}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.checkDate}>調査日 {data.checkDate}</div>
    </React.Fragment>
  );
};

export default CompanyTable;
