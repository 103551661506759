// すべて表示状態のフラグ取得
export const OPEN_MENU_FLG_GET = "common/MainMenu/OPEN_MENU_FLG_GET";
// 表示項目の設定
export const ITEMS_SET = "common/MainMenu/ITEMS_SET";
export const ITEMS_UPDATE = "common/MainMenu/ITEMS_UPDATE";
// 親メニューの開閉
export const PARENT_TOGGLE = "common/MainMenu/PARENT_TOGGLE";
// 全ての開閉
export const ALL_TOGGLE = "common/MainMenu/ALL_TOGGLE";
// メニュー取得API用パラメータセット
export const PARAMS_SET = "common/MainMenu/PARAMS_SET";
