import React from "react";
import { Router, Route, Switch } from "react-router";
import { history } from "stores/store";
import asyncComponent from "components/AsyncComponent";
import { Atlas } from "@nikkei/atlas-react";
import Popup from "containers/common/Popup";
import Pdf from "components/Pdf";
import PdfViewer from "features/PdfjsViewer";
import NotFound from "features/NotFound";
import { Debug } from "features/DebugPage";
import { Auth } from "features/Auth";
import { useMediaLayout } from "hooks/useMedia";

const Main = asyncComponent(() =>
  import("features/Main").then(module => module.default)
);
const AccessControl = asyncComponent(() =>
  import("features/AccessControl").then(module => module.default)
);
const Home = asyncComponent(() =>
  import("containers/pages/home").then(module => module.default)
);
const History = asyncComponent(() =>
  import("containers/pages/history").then(module => module.default)
);
const Login = asyncComponent(() =>
  import("features/Login").then(module => module.Login)
);
const Rights = asyncComponent(() =>
  import("components/Rights").then(module => module.default)
);
const Policy = asyncComponent(() =>
  import("components/Policy").then(module => module.default)
);
const Disclaimer = asyncComponent(() =>
  import("components/Disclaimer").then(module => module.default)
);
const DomesticCorporation = asyncComponent(() =>
  import("containers/pages/corp").then(module => module.default)
);
const Industry = asyncComponent(() =>
  import("containers/pages/industry").then(module => module.default)
);
const IndustrySearch = asyncComponent(() =>
  import("./features/IndustrySearch").then(module => module.default)
);
const Tool = asyncComponent(() =>
  import("containers/pages/tool").then(module => module.default)
);

const Article = asyncComponent(() =>
  import("components/Article").then(module => module.default)
);

const SearchList = asyncComponent(() =>
  import("./features/SearchList").then(module => module.default)
);

const Search = asyncComponent(() =>
  import("components/CorporationIndustry/Search").then(module => module.default)
);

const SearchIndsutry = asyncComponent(() =>
  import("./features/IndustrySearch").then(module => module.default)
);

const Macro = asyncComponent(() =>
  import("containers/pages/macro").then(module => module.default)
);

const Sample = asyncComponent(() =>
  import("containers/pages/sample").then(module => module.default)
);

const Setting = asyncComponent(() =>
  import("containers/Setting").then(module => module.default)
);

const Binder = asyncComponent(() =>
  import("./features/BinderPage").then(module => module.BinderPage)
);

const App = () => {
  useMediaLayout({ maxWidth: "520px" });

  return (
    <Router history={history}>
      <React.Fragment>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/pdf-viewer" component={PdfViewer} />
          <Auth>
            <Main>
              <AccessControl>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/binder" component={Binder} />
                  <Route path="/home" component={Home} />
                  <Route path="/gohome" component={Home} />
                  <Route path="/corp" component={DomesticCorporation} />
                  <Route path="/industry" component={Industry} />
                  <Route path="/industrySearch" component={IndustrySearch} />
                  <Route path="/tool" component={Tool} />
                  <Route path="/article" component={Article} />
                  <Route path="/searchlist" component={SearchList} />
                  <Route path="/search" component={Search} />
                  <Route path="/industry-search" component={SearchIndsutry} />
                  <Route exact path="/history" component={History} />
                  <Route exact path="/rights" component={Rights} />
                  <Route exact path="/policy" component={Policy} />
                  <Route exact path="/disclaimer" component={Disclaimer} />
                  <Route path="/macro" component={Macro} />
                  <Route exact path="/popup" component={Popup} />
                  <Route exact path="/pdf" component={Pdf} />
                  <Route exact path="/sample" component={Sample} />
                  <Route path="/setting" component={Setting} />
                  <Route path="/debug" component={Debug} />
                  <Route component={NotFound} />
                </Switch>
              </AccessControl>
            </Main>
          </Auth>
        </Switch>
        {MODE === "production" && (
          <Atlas
            provideReferrer={true}
            trackingHtmlUrl="/vs-atlas/tracking.rev-d097ff0.html"
          />
        )}
      </React.Fragment>
    </Router>
  );
};

export default App;
