import { Reducer } from "redux";
import { HomeTopAction } from "modules/homeTop/actions";
import { BinderItem } from "models/BinderItem";
import { HomeTrendReportApiResponse } from "../../features/HomeTopPage/api/home_home-trend-report/HomeTrendReportApiResponse";
import { HomeSectorReportApiResponse } from "../../features/HomeTopPage/api/home_home-sector-report/HomeSectorReportApiResponse";
import { HomeStrategyApiResponse } from "../../features/HomeTopPage/api/home_strategy/HomeStrategyApiResponse";

export interface HomeTopState {
  myBinders?: BinderItem[];
  nikkeiBinders?: BinderItem[];
  nikkeiBizTrends?: HomeTrendReportApiResponse;
  sectorReports?: HomeSectorReportApiResponse;
  sectorAuth?: boolean;
  corporationStrategies?: HomeStrategyApiResponse;
}

const initialState: HomeTopState = {};

export const homeTop: Reducer<HomeTopState, HomeTopAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "homeTop/SET_MY_BINDERS":
      return { ...state, myBinders: action.payload.myBinders };
    case "homeTop/SET_NIKKEI_BINDERS":
      return { ...state, nikkeiBinders: action.payload.nikkeiBinders };
    case "homeTop/SET_NIKKEI_BIZ_TRENDS":
      return { ...state, nikkeiBizTrends: action.payload.nikkeiBizTrends };
    case "homeTop/SET_SECTOR_REPORTS":
      return { ...state, sectorReports: action.payload.sectorReports };
    case "homeTop/SET_SECTOR_AUTHORITY":
      return { ...state, sectorAuth: action.payload.auth };
    case "homeTop/SET_CORPORATION_STRATEGIES":
      return {
        ...state,
        corporationStrategies: action.payload.corporationStrategies
      };
    default:
      return state;
  }
};
