// 共通パラメータの設定
export const PARAMS_SET = "global/PARAMS_SET";
// 企業情報の保存
export const CORP_INFO_SET = "global/CORP_INFO_SET";
// 業界情報の保存
export const INDUSTRY_INFO_SET = "global/INDUSTRY_INFO_SET";
// 関連コレクションの保存
export const RELATED_COLLECTION_SET = "global/RELATED_COLLECTION_SET";
// テレコン情報取得
export const LOAD_TELECOM_INFO = "global/LOAD_TELECOM_INFO";
// asrfの保存
export const ASRF_SAVE = "global/ASRF_SAVE";
// ロード状態の設定
export const LOADING_SET = "global/LOADING_SET";
export const CORP_PARAMS_SET = "global/CORP_PARAMS_SET";
export const RELATED_COLLECTION_RESET = "global/RELATED_COLLECTION_RESET";
// モバイルサイズ
export const IS_MOBILE_SIZE_SET = "global/IS_MOBILE_SIZE_SET";
