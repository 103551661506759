import React from "react";
import styles from "./style.scss";
import Lottie from "components/Common/Lottie";
import loaderAnimationData from "images/animation/loader_light.json";
import cn from "classnames";

interface Props {
  title?: string;
  display?: boolean;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
    x: "90",
    y: "0"
  }
};

export const Loading: React.FunctionComponent<Props> = ({
  title,
  display = true
}) => {
  return (
    <div className={cn(styles.component, { [styles.hide]: !display })}>
      <Lottie height={"72px"} options={defaultOptions} />
      <div className={styles.loading}>LOADING…</div>
      {title && <div className={styles.title}>{title}</div>}
    </div>
  );
};
