import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  error: {},
  conditions: {
    from: "",
    to: "",
    accKind: "",
    accType: "",
    monetaryCode: ""
  },
  periodFromList: [],
  periodToList: [],
  accKinds: [], // 決算種別
  accTypes: [], // 決算区分
  monetaryCodes: [] // 通貨単位区分
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      periodFromList: action.payload.periodFromList,
      periodToList: action.payload.periodToList,
      conditions: {
        from: action.payload.periodFrom,
        to: action.payload.periodTo,
        monetaryCode: action.payload.monetaryCode,
        accKind: action.payload.accKind,
        accType: action.payload.accType
      }
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.ACC_TYPE_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        periodDto: action.payload
      },
      conditions: {
        ...state.conditions,
        from: action.payload.fromDate,
        to: action.payload.toDate
      }
    }),
    [`${ActionTypes.ACC_TYPE_CHANGE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.GRAPH_SHOW}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.GRAPH_SHOW}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.SET_ACC_KINDS]: (state, action) => ({
      ...state,
      accKinds: action.payload
    }),
    [ActionTypes.SET_ACC_TYPES]: (state, action) => ({
      ...state,
      accTypes: action.payload
    }),
    [ActionTypes.SET_MONETARY_CODES]: (state, action) => ({
      ...state,
      monetaryCodes: action.payload
    }),
    [ActionTypes.SET_CONDITIONS]: (state, action) => ({
      ...state,
      conditions: {
        ...state.conditions,
        ...action.payload
      }
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(124, params)),
  accTypeChange: createAction(ActionTypes.ACC_TYPE_CHANGE, params =>
    request(128, params)
  ),
  graphShow: createAction(ActionTypes.GRAPH_SHOW, params =>
    request(125, params)
  ),
  setAccKinds: createAction(ActionTypes.SET_ACC_KINDS),
  setAccTypes: createAction(ActionTypes.SET_ACC_TYPES),
  setMonetaryCodes: createAction(ActionTypes.SET_MONETARY_CODES),
  setConditions: createAction(ActionTypes.SET_CONDITIONS)
};
