import React from "react";
import ToastContext from "contexts/toast";

const toast = Component =>
  React.forwardRef((props, ref) => (
    <ToastContext.Consumer>
      {methods => (
        <Component
          ref={ref}
          setToastMessages={methods.setMessages}
          setToastErrors={methods.setErrors}
          clearToastMessages={methods.clearMessages}
          clearToastErrors={methods.clearErrors}
          {...props}
        />
      )}
    </ToastContext.Consumer>
  ));

export default toast;
