import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { isEmpty, valueFromKeyOrDefault } from "utils/helper/common";

import { selectTable } from "components/Tool/Common/IndustryComparisonTable/ComparisonTable/Table/checkboxData";

const initialState = {
  selectedIndustryClassData: {}, // 検索対象業種分類 の選択状態を保存する
  selectedCondition: null, // 登録した条件から保存 で選択中の value 値
  conditionData: {},
  matchCountData: {},
  inputAreaDataList: [],
  isLoadingChildData: false,
  isLoadingUserCondition: false,
  isLoadingUserConditionAdd: false,
  tempSaveFavoriteData: {}, // "条件を保存" から保存リクエストする際に必要なパラメータセット
  isLoadingSavedFavoriteDetail: false,
  screeningResult: {},
  isLoadingScreeningResult: false,
  comparisonHead: {},
  isLoadingComparisonHead: false,
  comparisonData: {},
  isLoadingComparisonData: false,
  comparisonConditions: {},
  // comparisonConditions: { // 比較表 選択状態の管理データ
  //   listingMiddleSelect: // 中分類チェックボックスチェック状態
  //   listingSmallSelect:  // 小分類チェックボックスチェック状態
  //   middleIndustryList:  // 削除後の中分類コードリスト
  //   selectedMiddleList: // 削除後の中分類コードリスト
  //   selectedSmallList: // 削除後の小分類コードリスト
  //   smallIndustryList: // 削除後の小分類コードリスト
  //   sortFlag: // ソート状態(up / down)
  //   sortId: // ソート中の列番号
  //   userIndexValue: // スクリーニング検索条件セレクトボックスの選択値
  // }
  isLoadingChartCount: false,
  chartData: {},
  isLoadingChartData: false,
  savedFavoriteList: [],
  indexTree: [],
  selectedOption: "", // ユーザ指標編集 選択中の保存条件
  parentId: "",
  isAvailableIndex: "",
  selectedIndex: [],
  savedConditionList: [],

  // 比較表データ再取得系
  needsSearch: false, // 再検索フラグ
  init: false,

  // 比較表ファイルダウンロードモーダル系
  dlPeriodList: [],
  dlPeriodFrom: "",
  dlPeriodTo: "",

  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      selectedIndustryClassData: {
        middleSelect: action.payload.middleSelect, // 文字列 "true" で降ってくる
        smallSelect: action.payload.smallSelect
      },
      conditionData: action.payload,
      selectedCondition: state.selectedCondition || action.payload.userCondition
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDUSTRY_CLASS_DATA_UPDATE]: (state, action) => ({
      ...state,
      selectedIndustryClassData: action.payload
    }),
    [ActionTypes.CONDITION_DATA_UPDATE]: (state, action) => ({
      ...state,
      conditionData: action.payload
    }),
    [`${ActionTypes.MATCH_COUNT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      // 入力アイテムがすでに空の場合は、件数表示0件にする
      matchCountData: isEmpty(state.inputAreaDataList) ? {} : action.payload
    }),
    [`${ActionTypes.MATCH_COUNT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      matchCountData: {},
      error: action.payload
    }),
    [ActionTypes.INPUT_AREA_DATA_LIST_UPDATE]: (state, action) => {
      const newInputAreaDataList = action.payload;
      return {
        ...state,
        // 入力アイテムが空になった場合、件数表示をリセットする
        matchCountData: isEmpty(newInputAreaDataList)
          ? {}
          : state.matchCountData,
        inputAreaDataList: newInputAreaDataList
      };
    },
    [`${ActionTypes.SCREENING_TREE_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      const newConditionData = { ...state.conditionData };
      let newTreeDataList = payload.treeDataList;
      if (!isEmpty(meta.kensaku)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            const newItem = { ...item };
            if (!isEmpty(newItem.child)) {
              newItem.childExistFlag = true;
              newItem.isOpen = true;
              newItem.childLoaded = true;
              newItem.child = setOpenToAllChildState(newItem.child);
            }
            return newItem;
          });
        };
        newTreeDataList = setOpenToAllChildState(newTreeDataList);
      }
      return {
        ...state,
        conditionData: {
          ...newConditionData,
          toolScreeningCorpDto: {
            ...newConditionData.toolScreeningCorpDto,
            treeInfoDto: {
              ...newConditionData.toolScreeningCorpDto.treeInfoDto,
              treeDataList: newTreeDataList
            }
          }
        }
      };
    },
    [`${ActionTypes.SCREENING_TREE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CHILD_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChildData: true
    }),
    [`${ActionTypes.CHILD_DATA_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      // データを取得できたデータを state に反映
      let newConditionData = { ...state.conditionData };
      let depth = 0;
      const updateTargetChildState = treeDataList => {
        const newTreeDataList = [...treeDataList];
        const targetParentItemId = meta.currentTreeIds[depth];
        for (const prop in newTreeDataList) {
          const item = { ...newTreeDataList[prop] };
          if (item.id === targetParentItemId) {
            depth += 1;
            item.child = updateTargetChildState(item.child);
          } else if (!targetParentItemId && item.id === meta.currentId) {
            if (!item.childLoaded) {
              item.child = payload.treeInfoDto.treeDataList;
              item.childLoaded = true;
              item.isOpen = true;
            } else {
              item.isOpen = !item.isOpen;
            }
          }
          newTreeDataList[prop] = item;
        }
        return newTreeDataList;
      };
      newConditionData = {
        ...newConditionData,
        toolScreeningCorpDto: {
          ...newConditionData.toolScreeningCorpDto,
          treeInfoDto: {
            ...newConditionData.toolScreeningCorpDto.treeInfoDto,
            treeDataList: updateTargetChildState(
              newConditionData.toolScreeningCorpDto.treeInfoDto.treeDataList
            )
          }
        }
      };

      return {
        ...state,
        conditionData: newConditionData,
        isLoadingChildData: false
      };
    },
    [`${ActionTypes.CHILD_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.USER_CONDITION_GET}_PENDING`]: state => ({
      ...state,
      isLoadingUserCondition: true
    }),
    [`${ActionTypes.USER_CONDITION_GET}_FULFILLED`]: state => ({
      ...state,
      isLoadingUserCondition: false
    }),
    [`${ActionTypes.USER_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingUserCondition: false,
      error: action.payload
    }),
    [`${ActionTypes.USER_CONDITION_ADD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingUserConditionAdd: true
    }),
    [`${ActionTypes.USER_CONDITION_ADD_GET}_FULFILLED`]: (state, action) => {
      const newInputAreaDataList = state.inputAreaDataList.slice();
      const payload = action.payload;
      const conditions = valueFromKeyOrDefault(
        payload,
        "toolCommon06DtoList",
        []
      );
      // inputArea の追加処理
      if (!isEmpty(conditions[0]) && !isEmpty(conditions[0].screeningId)) {
        const newAddScreeningId = conditions[0].screeningId;
        const matchedData = newInputAreaDataList.find(data => {
          return data.screeningId === newAddScreeningId;
        });
        if (
          !matchedData &&
          newInputAreaDataList.length < action.meta.maxScreeningSearchCondCount
        )
          newInputAreaDataList.push(conditions[0]);
      }
      return {
        ...state,
        inputAreaDataList: newInputAreaDataList,
        isLoadingUserConditionAdd: false
      };
    },
    [`${ActionTypes.USER_CONDITION_ADD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingUserConditionAdd: false,
      error: action.payload
    }),
    [`${ActionTypes.FAVORITE_CHECK}_FULFILLED`]: (state, action) => ({
      ...state,
      tempSaveFavoriteData: action.meta
    }),
    [`${ActionTypes.FAVORITE_SAVE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 保存した条件が選択中の条件になるよう更新
      selectedCondition: action.payload.selectedCondition,
      // 登録した条件から選択 セレクトリストの更新
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      tempSaveFavoriteData: {}
    }),
    [ActionTypes.SAVE_FAVORITE_CANCEL]: state => ({
      ...state,
      tempSaveFavoriteData: {} // 一時データを削除する
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_PENDING`]: state => ({
      ...state,
      screeningResult: {},
      comparisonData: {}, //クリア
      isLoadingScreeningResult: true
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonConditions: {
        favoriteDetail: action.meta.favoriteDetail
      }, // 比較表 ファイル DL リクエスト時に必要なパラメータを保持、入力状態をクリア
      screeningResult: action.payload,
      // 件数0の場合はクリア
      comparisonHead: action.payload != "0" ? state.comparisonHead : {},
      isLoadingScreeningResult: false
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      comparisonConditions: {}, // 選択、入力状態をクリア
      screeningResult: {},
      isLoadingScreeningResult: false,
      error: action.payload
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonHead: true
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_FULFILLED`]: (state, action) => {
      const comparisonHead = action.payload;
      const newComparisonConditions = { ...state.comparisonConditions };
      for (const prop in selectTable) {
        const key = selectTable[prop];
        if (comparisonHead[key] == "1") newComparisonConditions[key] = "1";
      }
      const {
        userIndexGroupListOfScreening,
        userIndexGroupListOfBasic,
        userIndexGroupListOfUserSet
      } = comparisonHead.userIndexGroupListDto;
      let userIndexValue;
      if (
        !isEmpty(action.meta.dispItemListInitValue) &&
        userIndexGroupListOfUserSet.find(
          index => index.value == action.meta.dispItemListInitValue
        )
      ) {
        // 選択中のユーザ指標
        userIndexValue = action.meta.dispItemListInitValue;
      } else if (!isEmpty(action.meta.nowEditItem)) {
        // ユーザ指標編集で選択されたアイテムのラベル
        userIndexValue = userIndexGroupListOfUserSet.find(
          index => index.label == action.meta.nowEditItem
        ).value;
      } else {
        // デフォルト値
        userIndexValue = !isEmpty(userIndexGroupListOfScreening)
          ? userIndexGroupListOfScreening[0].value
          : !isEmpty(userIndexGroupListOfBasic)
          ? userIndexGroupListOfBasic[0].value
          : "";
      }
      newComparisonConditions["userIndexValue"] = userIndexValue;
      newComparisonConditions["screeningIdList"] =
        state.screeningResult.screeningIdList;
      newComparisonConditions["middleIndustryList"] =
        state.screeningResult.middleIndustryList;
      newComparisonConditions["smallIndustryList"] =
        state.screeningResult.smallIndustryList;
      newComparisonConditions["selectedMiddleList"] =
        state.screeningResult.selectedMiddleList;
      newComparisonConditions["selectedSmallList"] =
        state.screeningResult.selectedSmallList;
      return {
        ...state,
        comparisonHead: comparisonHead,
        comparisonConditions: newComparisonConditions,
        isLoadingComparisonHead: false
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonHead: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true,
      init: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: action.payload,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_FULFILLED`]: (state, action) => {
      const sortData = action.payload;
      return {
        ...state,
        comparisonData: {
          ...state.comparisonData,
          tableData: sortData.tableData
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_SORT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_FULFILLED`]: (
      state,
      action
    ) => {
      const sortData = action.payload;
      // 必要なデータだけマージする
      const newComparisonData = {
        ...state.comparisonData,
        fullNumber: sortData.fullNumber,
        hasReachedNkgnCodeShowMaxSize: sortData.hasReachedNkgnCodeShowMaxSize,
        listingMiddleCount: sortData.listingMiddleCount,
        listingMiddleSelect: sortData.listingMiddleSelect,
        listingSmallCount: sortData.listingSmallCount,
        listingSmallSelect: sortData.listingSmallSelect,
        maxDisplayableNkgnCodeCount: sortData.maxDisplayableNkgnCodeCount,
        messageDto: sortData.messageDto,
        middleIndustryList: sortData.middleIndustryList,
        nkgnCodeCountForShow: sortData.nkgnCodeCountForShow,
        nkgnCodeShowMaxSize: sortData.nkgnCodeShowMaxSize,
        screeningIdList: sortData.screeningIdList,
        selectedMiddleList: sortData.selectedMiddleList,
        selectedSmallList: sortData.selectedSmallList,
        smallIndustryList: sortData.smallIndustryList,
        tableData: sortData.tableData
      };
      const meta = action.meta;
      let newComparisonConditions = { ...state.comparisonConditions };
      // meta に渡された List 系を次のリクエストでも使うために、 comparisonConditions に保存する
      if (meta) {
        newComparisonConditions = {
          ...newComparisonConditions,
          middleIndustryList: meta.middleIndustryList || "",
          smallIndustryList: meta.smallIndustryList || "",
          selectedMiddleList: meta.selectedMiddleList || "",
          selectedSmallList: meta.selectedSmallList || ""
        };
      }
      return {
        ...state,
        comparisonData: newComparisonData,
        comparisonConditions: newComparisonConditions,
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.COMPARISON_DATA_CLEAR]: state => ({
      ...state,
      screeningResult: {},
      comparisonData: {}, // クリア
      comparisonConditions: {} // 選択、入力状態をクリア
    }),
    [ActionTypes.COMPARISON_CONDITIONS_UPDATE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [`${ActionTypes.CHART_COUNT_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartCount: true
    }),
    [`${ActionTypes.CHART_COUNT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData:
        parseInt(action.payload) >= action.meta.industryCodeListLength
          ? state.chartData
          : {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_COUNT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      chartData: {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: {
        ...state.chartData,
        bubbleChartData: action.payload.bubbleChartData,
        bubbleChartDataForEdit: action.payload.bubbleChartDataForEdit,
        competitionCompareIndustrySumList:
          action.payload.competitionCompareIndustrySumList,
        lineChartData: action.payload.lineChartData,
        unitX: action.payload.unitX,
        unitY: action.payload.unitY
      },
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [ActionTypes.RESULT_DATA_APPLY]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    // 登録条件系
    [`${ActionTypes.SAVED_FAVORITE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 選択中の項目が消された場合の考慮
      selectedCondition: action.payload.userConditionList.find(
        condition => condition.value == state.selectedCondition
      )
        ? state.selectedCondition
        : action.payload.userConditionList[0].value,
      // 登録した条件から選択 セレクトボックスで使うデータも更新する
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 登録した条件から選択 セレクトボックスで使うデータも更新する
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_PENDING`]: state => ({
      ...state,
      isLoadingSavedFavoriteDetail: true
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      selectedCondition: action.meta.favoriteId,
      inputAreaDataList: action.payload.toolCommon06DtoList.slice(),
      isLoadingSavedFavoriteDetail: false
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingSavedFavoriteDetail: false,
      error: action.payload
    }),
    [ActionTypes.SELECTED_CONDITION_CLEAR]: state => ({
      ...state,
      selectedCondition: state.conditionData.userConditionList[0].value
    }),
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_FULFILLED`]: (state, action) => {
      const newIndexTree = action.payload;
      let selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });

      if (action.meta.kensakuName !== undefined && selectedIndex.length === 0) {
        selectedIndex = state.selectedIndex;
      }
      if (!isEmpty(action.meta.kensakuName)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            if (!isEmpty(item.child)) {
              item.childExistFlag = true;
              item.isOpen = true;
              item.childLoaded = true;
              setOpenToAllChildState(item.child);
            }
            return item;
          });
        };
        newIndexTree.treeDataList = setOpenToAllChildState(
          newIndexTree.treeDataList
        );
      }
      return {
        ...state,
        indexTree: newIndexTree,
        selectedOption: isEmpty(state.selectedOption)
          ? action.payload.userSelect[0].value
          : state.selectedOption,
        selectedIndex: selectedIndex
      };
    },
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      let newIndexTree = { ...state.indexTree };
      let depth = 0;
      const updateTargetChildState = treeDataList => {
        const newTreeDataList = [...treeDataList];
        const targetParentItemId = meta.currentTreeIds[depth];
        for (const prop in newTreeDataList) {
          const item = { ...newTreeDataList[prop] };
          if (item.id === targetParentItemId) {
            depth += 1;
            item.child = updateTargetChildState(item.child);
          } else if (!targetParentItemId && item.id === meta.currentId) {
            if (!item.childLoaded) {
              item.child = payload.treeDataList;
              item.childLoaded = true;
              item.isOpen = true;
            } else {
              item.isOpen = !item.isOpen;
            }
          }
          newTreeDataList[prop] = item;
        }
        return newTreeDataList;
      };
      newIndexTree = {
        ...newIndexTree,
        treeDataList: updateTargetChildState(newIndexTree.treeDataList)
      };

      return {
        ...state,
        indexTree: newIndexTree
      };
    },
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_FULFILLED`]: state => state,
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_ADD}_FULFILLED`]: (state, action) => {
      const newSelectedIndex = [...state.selectedIndex];
      // 存在、上限チェック
      const matchedIndex = newSelectedIndex.find(
        index => index.screeningId == action.payload.screeningId
      );
      if (
        !matchedIndex &&
        parseInt(state.indexTree.maxCount) > newSelectedIndex.length
      ) {
        newSelectedIndex.push(action.payload);
      }
      return {
        ...state,
        selectedIndex: newSelectedIndex
      };
    },
    [`${ActionTypes.INDEX_ADD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedIndex: action.payload
    }),
    [ActionTypes.INDEX_REMOVE_ALL]: state => ({
      ...state,
      selectedIndex: [],
      // 選択中の条件も先頭にリセット
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      indexTree: {
        ...state.indexTree,
        userSelect: action.payload
      },
      selectedOption: action.payload[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_SET}_FULFILLED`]: state => ({
      ...state,
      indexTree: {} // クリア
    }),
    [`${ActionTypes.CONDITION_SET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      return {
        ...state,
        selectedIndex: selectedIndex,
        selectedOption: action.meta.userSetId
      };
    },
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_ALL_CLEAR]: state => ({
      ...state,
      indexTree: {},
      selectedOption: "",
      parentId: "",
      isAvailableIndex: "",
      selectedIndex: [],
      savedConditionList: []
    }),

    /**
     * 比較表データ再取得系
     */
    [ActionTypes.SEARCH_FLAG_SET]: (state, action) => ({
      ...state,
      needsSearch: true,
      // 初回リクエストのみ init を付与する必要があるため、 payload から受けとる
      init: action.payload && action.payload.init ? true : false
    }),
    [ActionTypes.SEARCH_FLAG_RESET]: state => ({
      ...state,
      needsSearch: false
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      }
    }),

    // 比較表ファイルダウンロードモーダル系
    [`${ActionTypes.DOWNLOAD_PERIOD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      dlPeriodList: action.payload.periodList,
      dlPeriodFrom: action.payload.periodFrom
    }),
    [ActionTypes.DOWNLOAD_PERIOD_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),
    [ActionTypes.ALL_RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(752, params)),
  updateIndustryClassData: createAction(ActionTypes.INDUSTRY_CLASS_DATA_UPDATE),
  updateConditionData: createAction(ActionTypes.CONDITION_DATA_UPDATE),
  updateInputAreaDataList: createAction(
    ActionTypes.INPUT_AREA_DATA_LIST_UPDATE
  ),
  getMatchCount: createAction(ActionTypes.MATCH_COUNT_GET, params =>
    request(672, params)
  ),
  getScreeningTree: createAction(
    ActionTypes.SCREENING_TREE_GET,
    params => request(754, params.payload),
    params => params.meta
  ),
  getChildData: createAction(
    ActionTypes.CHILD_DATA_GET,
    params => request(756, params.payload),
    params => params.meta
  ),
  getUserCondition: createAction(ActionTypes.USER_CONDITION_GET, params =>
    request(755, params)
  ),
  getUserConditionAdd: createAction(
    ActionTypes.USER_CONDITION_ADD_GET,
    params => request(757, params.payload),
    params => ({ ...params.meta })
  ),
  checkFavorite: createAction(
    ActionTypes.FAVORITE_CHECK,
    params => request(760, params),
    // 保存処理を行う際に必要なパラメータを meta に仕込み、リクエスト成功時に tempSaveFavoriteData に格納
    params => ({
      favoriteId: params.favoliteId,
      favoriteName: params.favoliteName,
      favoriteDetail: params.favoliteDetail,
      favoriteDetailLabel: params.favoliteDetailLabel
    })
  ),
  saveFavorite: createAction(ActionTypes.FAVORITE_SAVE, params =>
    request(761, params)
  ),
  cancelSaveFavorite: createAction(ActionTypes.SAVE_FAVORITE_CANCEL),
  getScreeningResult: createAction(
    ActionTypes.SCREENING_RESULT_GET,
    params => request(672, params),
    // 比較表ファイルダウンロード時に必要なパラメータを保持するべく meta に仕込む
    params => ({ favoriteDetail: params.favoliteDetail })
  ),
  getComparisonHead: createAction(
    ActionTypes.COMPARISON_HEAD_GET,
    params => request(670, params),
    params => ({
      nowEditItem: params.nowEditItem,
      dispItemListInitValue: params.dispItemListInitValue
    })
  ),
  getComparisonData: createAction(ActionTypes.COMPARISON_DATA_GET, params =>
    request(671, params)
  ),
  sortComparisonData: createAction(
    ActionTypes.COMPARISON_DATA_SORT,
    params => request(671, params) // get と API が同じ
  ),
  removeComparisonDataRow: createAction(
    ActionTypes.COMPARISON_DATA_ROW_REMOVE,
    params => request(671, params), // get と API が同じ
    // comparisonConditions に残したいパラメータのみを meta に渡す
    params => ({
      middleIndustryList: params.middleIndustryList,
      smallIndustryList: params.smallIndustryList,
      selectedMiddleList: params.selectedMiddleList,
      selectedSmallList: params.selectedSmallList
    })
  ),
  clearComparisonData: createAction(ActionTypes.COMPARISON_DATA_CLEAR),
  updateComparisonConditions: createAction(
    ActionTypes.COMPARISON_CONDITIONS_UPDATE
  ),
  getChartCount: createAction(
    ActionTypes.CHART_COUNT_GET,
    params => request(691, params.payload),
    params => ({ ...params.meta })
  ),
  getChartData: createAction(ActionTypes.CHART_DATA_GET, params =>
    request(701, params)
  ),
  updateChartData: createAction(ActionTypes.CHART_DATA_UPDATE, params =>
    request(702, params)
  ),
  applyResultData: createAction(ActionTypes.RESULT_DATA_APPLY),
  getSavedFavoriteList: createAction(ActionTypes.SAVED_FAVORITE_GET, params =>
    request(759, params)
  ),
  deleteSavedFavorite: createAction(ActionTypes.SAVED_FAVORITE_DELETE, params =>
    request(763, params)
  ),
  updateSavedFavorite: createAction(ActionTypes.SAVED_FAVORITE_UPDATE, params =>
    request(762, params)
  ),
  getSavedFavoriteDetail: createAction(
    ActionTypes.SAVED_FAVORITE_DETAIL_GET,
    params => request(758, params),
    // selectedCondition に残したいパラメータのみを meta に渡す
    params => ({ favoriteId: params.favoliteId })
  ),
  clearSelectedCondition: createAction(ActionTypes.SELECTED_CONDITION_CLEAR),
  getIndexTreeRoot: createAction(
    ActionTypes.INDEX_TREE_ROOT_GET,
    params => request(673, params),
    params => ({ kensakuName: params.kensakuName })
  ),
  getIndexTreeChild: createAction(
    ActionTypes.INDEX_TREE_CHILD_GET,
    params => request(674, params.payload),
    params => params.meta
  ),
  getIndexAvailability: createAction(
    ActionTypes.INDEX_AVAILABILITY_GET,
    param => request(675, param)
  ),
  addIndex: createAction(ActionTypes.INDEX_ADD, params => request(676, params)),
  updateIndex: createAction(ActionTypes.INDEX_UPDATE),
  removeAllIndex: createAction(ActionTypes.INDEX_REMOVE_ALL),
  getSavedConditionList: createAction(ActionTypes.SAVED_CONDITION_GET, params =>
    request(679, params)
  ),
  getSavedConditionSelectList: createAction(
    ActionTypes.SAVED_CONDITION_SELECT_GET,
    params => request(682, params)
  ),
  deleteSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_DELETE,
    params => request(680, params)
  ),
  updateSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_UPDATE,
    params => request(681, params)
  ),
  getSavedConditionDetail: createAction(
    ActionTypes.SAVED_CONDITION_DETAIL_GET,
    params => request(678, params),
    params => ({ userSetId: params.userSetId })
  ),
  setCondition: createAction(
    ActionTypes.CONDITION_SET,
    params => request(677, params),
    params => ({ userSetName: params.userSetName })
  ),
  clearIndexAll: createAction(ActionTypes.INDEX_ALL_CLEAR),

  // 比較表データ再取得系
  setSearchFlag: createAction(ActionTypes.SEARCH_FLAG_SET),
  resetSearchFlag: createAction(ActionTypes.SEARCH_FLAG_RESET),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET),

  // 比較表ファイルダウンロードモーダル系
  getDownloadPeriod: createAction(ActionTypes.DOWNLOAD_PERIOD_GET, params =>
    request(684, params)
  ),
  setDownloadPeriod: createAction(
    ActionTypes.DOWNLOAD_PERIOD_SET,
    period => period,
    (_, key) => ({ key })
  ),
  resetAll: createAction(ActionTypes.ALL_RESET)
};
