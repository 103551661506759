import { AuthTemporaryAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";
import {
  SessionState,
  Notice,
  OptionInfo,
  LoginUserData,
  LoginError
} from "models/Auth";

export interface AuthTemporaryState {
  sessionState: SessionState;
  isAuthorizing: boolean;
  isLoading: boolean;
  contractorId: string;
  contractorName: string;
  nidAuthenticated: boolean;
  notices: Notice[];
  options: OptionInfo[];
  isMaximum: boolean;
  loginUsers: LoginUserData[];
  isLogoutLoading: boolean;
  logoutError: string | null;
  errors: LoginError | null;
}

const initialState: AuthTemporaryState = {
  sessionState: "unknown",
  isAuthorizing: false,
  isLoading: false,
  contractorId: "",
  contractorName: "",
  nidAuthenticated: false,
  notices: [],
  options: [],
  isMaximum: false,
  loginUsers: [],
  isLogoutLoading: false,
  logoutError: "",
  errors: null
};

export const authTemporary: Reducer<AuthTemporaryState, AuthTemporaryAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "auth/temporary/SET_SESSION_STATE":
      return produce(state, draft => {
        draft.sessionState = action.payload.sessionState;
      });
    case "auth/temporary/SET_LOGIN_INFO":
      return {
        ...state,
        ...action.payload
      };
    case "auth/temporary/SET_IS_AUTHORIZING":
      return produce(state, draft => {
        draft.isAuthorizing = action.payload.isAuthorizing;
      });
    case "auth/temporary/SET_IS_LOADING":
      return produce(state, draft => {
        draft.isLoading = action.payload.isLoading;
      });
    case "auth/temporary/SET_LOGIN_OPTIONS":
      return produce(state, draft => {
        draft.options = action.payload;
      });
    case "auth/temporary/CLEAR_LOGIN_OPTIONS":
      return produce(state, draft => {
        draft.options = initialState.options;
      });
    case "auth/temporary/SET_IS_MAXIMUM":
      return produce(state, draft => {
        draft.isMaximum = action.payload.isMaximum;
      });
    case "auth/temporary/SET_LOGIN_USERS":
      return produce(state, draft => {
        draft.loginUsers = action.payload.users;
      });
    case "auth/temporary/SET_IS_LOGOUT_LOADING":
      return produce(state, draft => {
        draft.isLogoutLoading = action.payload.isLogoutLoading;
      });
    case "auth/temporary/SET_LOGOUT_ERROR":
      return produce(state, draft => {
        draft.logoutError = action.payload.logoutError;
      });
    case "auth/temporary/SET_ERROR":
      return produce(state, draft => {
        draft.errors = { ...state.errors, ...action.payload };
      });
    case "auth/temporary/CLEAR_ERROR":
      return produce(state, draft => {
        draft.errors = initialState.errors;
      });
    default:
      return state;
  }
};
