import { isEmpty } from "utils/helper/common";
import { corpIndustryKbn } from "utils/helper/url";
import { isArray } from "util";

/**
 * 前と次のcorpParamsをそれぞれ受け取り、同じ企業かを判定する
 * @param {object} next 次の企業情報
 * @param {object} prev 前の企業情報
 * @param {boolean} isStrict 対象のキーがどちらかのオブジェクトに存在しない場合別の企業として扱うかどうか
 * @param {string|string[]} additionalKeys 判定に追加したいキー
 * @return {bool} 同じ企業かどうか
 */
export const isSameCorp = (
  next: { [key: string]: unknown },
  prev: { [key: string]: unknown },
  isStrict = true,
  additionalKeys: string | string[] = []
): boolean => {
  if (isEmpty(next) || isEmpty(prev)) return false;
  // 企業の判別に使うキー
  const keys = [
    "corpIndustyCode",
    "corpIndustyKbn",
    "corpType",
    ...(isArray(additionalKeys) ? additionalKeys : [additionalKeys])
  ];
  return keys.every(key => {
    if (isEmpty(next[key]) && isEmpty(prev[key])) return true;
    if (isEmpty(next[key]) || isEmpty(prev[key])) return !isStrict;
    return next[key] === prev[key];
  });
};

/**
 * 前と次のcorpInfoをそれぞれ受け取り、同じ企業かを判定する
 * @param {object} next 次の企業情報
 * @param {object} prev 前の企業情報
 * @param {string|string[]} additionalKeys 判定に追加したいキー
 * @return {bool} 同じ企業かどうか
 */
export const isSameCorpInfo = (
  next: { [key: string]: unknown },
  prev: { [key: string]: unknown },
  additionalKeys: string | string[] = []
) => {
  if (isEmpty(next) || isEmpty(prev)) return false;
  // 企業の判別に使うキー
  const keys = [
    "nikkeiCode",
    "asrf",
    "diffflg",
    "name",
    ...(isArray(additionalKeys) ? additionalKeys : [additionalKeys])
  ];
  return keys.every(key => next[key] === prev[key]);
};

/**
 * 企業・業界コードから区分を取得
 * @param {string} code 企業・業界コード
 * @returns {string|false} 区分|false:取得失敗
 */
export const getKbnWithCode = (code: string): string | false => {
  if (typeof code !== "string") return false;
  if (code.match(/^\d{3}$/) !== null) return corpIndustryKbn.industryMedium;
  if (code.match(/^\d{4}$/) !== null) return corpIndustryKbn.industrySmall;
  if (code.match(/^\d{7,}$/) !== null) return corpIndustryKbn.domestic;
  return corpIndustryKbn.overseas;
};

/**
 * 企業・業界コードから区分を補正（ダイレクトアクセス時に起きる）
 * @param {string} corpIndustyCode 企業・業界コード
 * @param {string} corpIndustyKbn 区分
 * @returns {pbject} {code, kbn}
 */
export const fixKbn = (corpIndustyCode: string, corpIndustyKbn: string) => {
  const code =
    typeof corpIndustyCode === "string"
      ? corpIndustyCode
      : String(corpIndustyCode);
  const kbn = getKbnWithCode(code);
  return kbn ? { code, kbn } : { code, kbn: corpIndustyKbn };
};
