import React from "react";
import styles from "./style.scss";

import Lottie from "components/Common/Lottie";
import downloadButtonData from "images/animation/download_btn.json";
import cn from "classnames";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: downloadButtonData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet"
  }
};

interface Props {
  type:
    | "ppt"
    | "csv"
    | "xls"
    | "xlsx"
    | "pdf"
    | "xbrl"
    | "document"
    | "sample"
    | "ppt";
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  isLarge?: boolean;
  className?: string;
  classExportButton?: string;
  isDownloading?: boolean;
  isSmall?: boolean;
  isSlender?: boolean;
  btnRef?: React.RefObject<HTMLButtonElement> | null;
  onMouseOver?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * CSVダウンロードボタン
 * このボタンでダウンロード処理は実装せず、
 * 親コンポーネント側で onClick 発火時にダウンロード処理を実装する
 * (DL API リクエストパラメータが API により違い、複雑なため)
 *
 * @prop {string} type 表示するボタンタイプ
 * type: "csv", "xls", "pdf", ppt,"xbrl", "document"
 * @prop {function} onClick - ボタン押下時コールバック
 * @prop {boolean} disabled 非活性化どうか
 * @prop {boolean} isLarge 大きいサイズかどうか
 * @prop {boolean} isDownloading ダウンロード中かどうか
 * @prop {boolean} isSmall 小さいサイズかどうか
 * @prop {boolean} isSlender 細長いサイズかどうか
 */
export const Button: React.FunctionComponent<Props> = ({
  type = "csv",
  onClick = () => {},
  disabled = false,
  isLarge = false,
  className = "",
  classExportButton = "",
  isDownloading = false,
  isSmall = false,
  isSlender = false,
  btnRef,
  ...props
}) => {
  let text = "";
  switch (type) {
    case "csv":
      text = "CSV";
      break;
    case "xls":
    case "xlsx":
      text = "XLSX";
      break;
    case "pdf":
      text = "PDF";
      break;
    case "ppt":
      text = "PPT";
      break;
    case "xbrl":
      text = "XBRL";
      break;
    case "document":
      text = "添付\n資料";
      break;
  }

  if (disabled) {
    return (
      <div className={cn([styles.wrapper, className])}>
        <button
          className={styles.button}
          data-testid={"button_disabled"}
          disabled
        />
      </div>
    );
  }

  if (isDownloading) {
    return isLarge ? (
      <button
        className={`
          ${styles.button}
          ${styles.download}
          ${styles.hasColor}
          ${styles.isLarge}
          ${styles[type]}
          ${className}
        `}
        data-testid={"button_isDownloading_isLarge"}
        onClick={onClick}
        {...props}
        ref={btnRef}
      >
        <div className={styles.downloading}>
          <Lottie options={defaultOptions} height={"24px"} />
        </div>
        <span>{text}</span>
      </button>
    ) : (
      <div
        className={`${styles.wrapper} ${styles.download} ${className}
      `}
        data-testid={"button_isDownloading"}
      >
        <div
          className={`${styles.background} ${styles[type]} ${classExportButton}`}
        >
          <Lottie options={defaultOptions} height={"32px"} />
        </div>
      </div>
    );
  }

  if (isSlender) {
    return (
      <button
        className={cn(
          styles.button,
          styles.hasColor,
          styles[type],
          styles.slender,
          className
        )}
        data-testid={"button_isSlender"}
        onClick={onClick}
        {...props}
        ref={btnRef}
      >
        <span className={cn(styles.icon)} />
        {text}
      </button>
    );
  }

  if (isSmall) {
    return (
      <button
        className={`
          ${styles.button}
          ${styles.hasColor}
          ${styles.isSmall}
          ${styles[type]}
          ${className}
        `}
        data-testid={"button_isSmall"}
        onClick={onClick}
        {...props}
        ref={btnRef}
      >
        {text}
      </button>
    );
  }

  return isLarge ? (
    <button
      className={`
        ${styles.button}
        ${styles.hasColor}
        ${styles.isLarge}
        ${styles[type]}
        ${className}
      `}
      data-testid={"button_isLarge"}
      onClick={onClick}
      {...props}
      ref={btnRef}
    >
      <span className={styles.icon} />
      <span>{text}</span>
    </button>
  ) : (
    <div className={`${styles.wrapper} ${className}`}>
      <div
        className={`normal-button ${styles.background} ${styles[type]} ${classExportButton}`}
      >
        <span>{text}</span>
      </div>
      <button
        className={`
          ${styles.button}
          ${styles[type]}
          ${classExportButton}
        `}
        data-testid={"normal_button"}
        onClick={onClick}
        {...props}
        ref={btnRef}
      />
    </div>
  );
};

export default Button;
