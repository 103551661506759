import fetchJsonp from "fetch-jsonp";
const url = require("../json/suggestUrl.json");

export const request = params => {
  let requestUrl = "https://t21s.nikkei.co.jp" + url.getSuggest;
  const requestParams = {
    ...params,
    // callback: "", // semi-opt
    // limit: 10, // optional
    encoding: "UTF-8"
  };
  requestUrl +=
    "?" +
    Object.keys(requestParams)
      .map(
        key =>
          encodeURIComponent(key) + "=" + encodeURIComponent(requestParams[key])
      )
      .join("&");
  return fetchJsonp(requestUrl)
    .then(response => response.json())
    .then(result => result);
};
