import React from "react";
import styles from "./style.scss";

import SelectList from "components/Common/SelectBox";

interface Option {
  seq?: string;
  label: string;
  inneyCode?: string;
  value: string;
  group?: Option[];
  displayLabel?: string;
}

interface Props {
  optionList: Option[];
  selectedOption: string;
  label: string | null;
  onSelected?: (option?: Option[]) => void;
  onClick?: () => void;
  multiple?: boolean;
  size?: number;
  uncontrolled?: boolean;
  className?: string;
  disabled?: boolean;
}

const Select = ({
  optionList,
  selectedOption,
  label,
  onSelected = () => {},
  multiple = false,
  size = 0,
  uncontrolled = false,
  className = "",
  disabled = false,
  ...props
}: Props) => {
  const getSelectedOption = (list: Option[], selectedValue: string) => {
    let selectedOption;
    list.forEach(option => {
      const selected = option.group // optgroup がある場合再帰で取得
        ? getSelectedOption(option.group, selectedValue)
        : option.value == selectedValue || option.displayLabel == selectedValue
        ? option
        : undefined;
      if (selected) selectedOption = selected;
    });
    return selectedOption;
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (multiple) {
      const options = event.target.options;
      const selectedOptions = [...options]
        .filter(option => option.selected)
        .map(option => {
          return { value: option.value, label: option.label };
        });
      onSelected(selectedOptions);
      return;
    }
    const selectedValue = event.target.value;
    const selected = getSelectedOption(optionList, selectedValue);
    onSelected(selected);
  };

  return (
    <SelectList
      options={optionList}
      selectedOption={selectedOption}
      onChange={handleChange}
      label={label || ""}
      multiple={multiple}
      size={size}
      className={multiple ? `${styles.multiple} ${className}` : className}
      uncontrolled={uncontrolled}
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();
        if (props.onClick) props.onClick();
      }}
      {...props}
    />
  );
};

export default Select;
