export const checkboxSourceItems = [
  {
    id: "GROUPFILTER_CHECKBOXID_MIDDLE_CreateReport",
    labelId: "GROUPFILTER_LABELID_MIDDLE_CreateReport",
    selected: false,
    corpCount: "0",
    active: true,
    haveAuthority: true,
    fsarea: "",
    value: "1",
    seq: "1",
    inneyCode: "1",
    label: "中分類"
  },
  {
    id: "GROUPFILTER_CHECKBOXID_SMALL_CreateReport",
    labelId: "GROUPFILTER_LABELID_SMALL_CreateReport",
    selected: false,
    corpCount: "0",
    active: true,
    haveAuthority: true,
    fsarea: "",
    value: "2",
    seq: "2",
    inneyCode: "2",
    label: "小分類"
  }
];

export const countTable = {
  GROUPFILTER_CHECKBOXID_MIDDLE_CreateReport: "listingMiddleCount", // 中分類
  GROUPFILTER_CHECKBOXID_SMALL_CreateReport: "listingSmallCount" // 小分類
};

export const selectTable = {
  GROUPFILTER_CHECKBOXID_MIDDLE_CreateReport: "listingMiddleSelect", // 中分類
  GROUPFILTER_CHECKBOXID_SMALL_CreateReport: "listingSmallSelect" // 小分類
};
