import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { isEmpty, valueFromKeyOrDefault } from "utils/helper/common";

const initialState = {
  selectedConditionSourceList: null, // 検索条件のチェックボックス選択状態リスト
  ytConditionDisabled: true, // 検索条件の 有報・短信 の非活性状態
  selectedCondition: null, // 登録した条件から保存 で選択中の value 値
  screeningResult: {},
  conditionData: {},
  inputAreaDataList: [],
  isLoadingTreeMst: false,
  isLoadingChildData: false,
  isLoadingUserCondition: false,
  isLoadingUserConditionAdd: false,
  tempSaveFavoriteData: {}, // "条件を保存" から保存リクエストする際に必要なパラメータセット
  isLoadingScreeningResult: false,
  comparisonHead: {},
  isLoadingComparisonHead: false,
  comparisonData: {},
  isLoadingComparisonData: false,
  comparisonConditions: {},
  isLoadingChartCount: false,
  chartData: {},
  isLoadingChartData: false,
  savedFavoriteList: [],
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  marketList: [],
  fnclList: [],
  addressList: [],
  ntclsList: [],
  indexTree: {},
  selectedOption: "", // ユーザ指標編集 選択中の保存条件
  parentId: "",
  isAvailableIndex: "",
  selectedIndex: [],
  savedConditionList: [],

  // 比較表データ再取得系
  needsSearch: false, // 再検索フラグ
  init: false,

  dlPeriodList: [],
  dlPeriodFrom: "",
  dlPeriodTo: "",
  error: null
};

// 条件セレクトボックスの 有報・短信 非活性判定
const getYtConditionDisabled = inputAreaDataList => {
  const newYtConditionDisabled = isEmpty(
    inputAreaDataList.find(inputArea => {
      const hierarchyInfoSpl = (inputArea.hierarchyInfo || "").split("~");
      return hierarchyInfoSpl[4] === "130"; // 財務の項目があるか判定
    })
  );
  return newYtConditionDisabled;
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => {
      const payload = action.payload;
      const { baseSourceItems } = action.meta;
      // 検索条件のチェックボックス選択状態リストをレスポンスから生成
      // サーバ側のチェック状態(conditionData.userCorpFilterScr)は使用しない
      const newSelectedConditionSourceList = baseSourceItems
        .filter(
          item =>
            item.value != "4" &&
            item.value !== "14" &&
            item.active &&
            item.selected
        )
        .map(item => item.value);
      // デフォルト値設定
      payload.ytConditionList[0].selected = true;
      payload.scConditionList[0].selected = true;
      payload.currencyList[0].selected = true;

      return {
        ...state,
        selectedConditionSourceList: newSelectedConditionSourceList,
        conditionData: payload
      };
    },
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.CONDITION_SOURCE_LIST_UPDATE]: (state, action) => ({
      ...state,
      selectedConditionSourceList: action.payload
    }),
    [ActionTypes.SELECTED_CONDITION_CLEAR]: state => ({
      ...state,
      selectedCondition: null
    }),
    [ActionTypes.CONDITION_DATA_UPDATE]: (state, action) => ({
      ...state,
      conditionData: action.payload
    }),
    [ActionTypes.YT_CONDITION_SELECTED_UPDATE]: (state, action) => {
      const newYtConditionList = state.conditionData.ytConditionList.map(
        item => {
          const newItem = { ...item };
          newItem.selected =
            newItem.value === action.payload.value ? true : false;
          return newItem;
        }
      );
      return {
        ...state,
        conditionData: {
          ...state.conditionData,
          ytConditionList: newYtConditionList
        }
      };
    },
    [ActionTypes.SC_CONDITION_SELECTED_UPDATE]: (state, action) => {
      const newScConditionList = state.conditionData.scConditionList.map(
        item => {
          const newItem = { ...item };
          newItem.selected =
            newItem.value === action.payload.value ? true : false;
          return newItem;
        }
      );
      return {
        ...state,
        conditionData: {
          ...state.conditionData,
          scConditionList: newScConditionList
        }
      };
    },
    [ActionTypes.CURRENCY_SELECTED_UPDATE]: (state, action) => {
      const newCurrencyList = state.conditionData.currencyList.map(item => {
        const newItem = { ...item };
        newItem.selected =
          newItem.value === action.payload.value ? true : false;
        return newItem;
      });
      return {
        ...state,
        conditionData: {
          ...state.conditionData,
          currencyList: newCurrencyList
        }
      };
    },
    [ActionTypes.INPUT_AREA_DATA_LIST_UPDATE]: (state, action) => {
      const newYtConditionDisabled = getYtConditionDisabled(action.payload);
      // 有報・短信が disabled になった際に、リストの選択状態をリセット
      let newYtConditionList = [...state.conditionData.ytConditionList];
      if (
        newYtConditionDisabled &&
        newYtConditionDisabled !== state.ytConditionDisabled
      ) {
        newYtConditionList = newYtConditionList.map(item => {
          const newItem = { ...item };
          delete newItem.selected;
          return newItem;
        });
        newYtConditionList[0].selected = true;
      }
      return {
        ...state,
        conditionData: {
          ...state.conditionData,
          ytConditionList: newYtConditionList
        },
        inputAreaDataList: action.payload,
        ytConditionDisabled: newYtConditionDisabled
      };
    },
    [`${ActionTypes.TREE_MST_GET}_PENDING`]: state => ({
      ...state,
      isLoadingTreeMst: true
    }),
    [`${ActionTypes.TREE_MST_GET}_FULFILLED`]: (state, action) => {
      const payload = action.payload;
      // データを取得できたデータを state に反映
      const newConditionData = { ...state.conditionData };
      newConditionData.toolScreeningCorpDto = {
        ...newConditionData.toolScreeningCorpDto
      };
      const mergeKeyList = ["userConditionList", "treeInfoDto"];
      mergeKeyList.forEach(key => {
        if (!isEmpty(payload[key])) {
          newConditionData.toolScreeningCorpDto[key] = payload[key];
        }
      });
      return {
        ...state,
        conditionData: newConditionData,
        isLoadingTreeMst: false
      };
    },
    [`${ActionTypes.TREE_MST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingTreeMst: false,
      error: action.payload
    }),
    [`${ActionTypes.SCREENING_TREE_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      const newConditionData = {
        ...state.conditionData,
        toolScreeningCorpDto: {
          ...state.conditionData.toolScreeningCorpDto,
          treeInfoDto: {
            ...state.conditionData.toolScreeningCorpDto.treeInfoDto
          }
        }
      };
      let newTreeDataList = payload.treeDataList;
      if (!isEmpty(meta.kensaku)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            const newItem = { ...item };
            if (!isEmpty(newItem.child)) {
              newItem.childExistFlag = true;
              newItem.isOpen = true;
              newItem.childLoaded = true;
              newItem.child = setOpenToAllChildState(newItem.child);
            }
            return newItem;
          });
        };
        newTreeDataList = setOpenToAllChildState(newTreeDataList);
      }
      newConditionData.toolScreeningCorpDto.treeInfoDto.treeDataList = newTreeDataList;
      return {
        ...state,
        conditionData: newConditionData
      };
    },
    [`${ActionTypes.SCREENING_TREE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CHILD_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChildData: true
    }),
    [`${ActionTypes.CHILD_DATA_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      // データを取得できたデータを state に反映
      const newConditionData = { ...state.conditionData };
      let depth = 0;
      const updateTargetChildState = treeDataList => {
        const newTreeDataList = { ...treeDataList };
        const targetParentItemId = meta.currentTreeIds[depth];
        for (const prop in newTreeDataList) {
          const item = { ...newTreeDataList[prop] };
          if (item.id === targetParentItemId) {
            depth += 1;
            item.child = updateTargetChildState(item.child);
          } else if (!targetParentItemId && item.id === meta.currentId) {
            if (!item.childLoaded) {
              item.child = payload.treeInfoDto.treeDataList;
              item.childLoaded = true;
              item.isOpen = true;
            } else {
              item.isOpen = !item.isOpen;
            }
          }
          newTreeDataList[prop] = item;
        }
        return newTreeDataList;
      };
      newConditionData.toolScreeningCorpDto = {
        ...newConditionData.toolScreeningCorpDto,
        treeInfoDto: {
          ...newConditionData.toolScreeningCorpDto.treeInfoDto,
          treeDataList: {
            ...updateTargetChildState(
              newConditionData.toolScreeningCorpDto.treeInfoDto.treeDataList
            )
          }
        }
      };

      return {
        ...state,
        conditionData: newConditionData,
        isLoadingChildData: false
      };
    },
    [`${ActionTypes.CHILD_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.USER_CONDITION_GET}_PENDING`]: state => ({
      ...state,
      isLoadingUserCondition: true
    }),
    [`${ActionTypes.USER_CONDITION_GET}_FULFILLED`]: state => ({
      ...state,
      isLoadingUserCondition: false
    }),
    [`${ActionTypes.USER_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingUserCondition: false,
      error: action.payload
    }),
    [`${ActionTypes.USER_CONDITION_ADD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingUserConditionAdd: true
    }),
    [`${ActionTypes.USER_CONDITION_ADD_GET}_FULFILLED`]: (state, action) => {
      const newInputAreaDataList = state.inputAreaDataList.slice();
      const payload = action.payload;
      const conditions = valueFromKeyOrDefault(
        payload,
        "toolCommon06DtoList",
        []
      );
      // inputArea の追加処理
      if (!isEmpty(conditions[0])) {
        const newScreeningId = conditions[0].screeningId;
        let matchedData = newInputAreaDataList.find(data => {
          return data.screeningId === newScreeningId;
        });
        // テーマ別重複チェック
        if (!matchedData && conditions[0].inputAreaId == "1") {
          matchedData = newInputAreaDataList.find(data => {
            return data.inputAreaId === "1";
          });
        }
        // （#）グループ項目重複チェック
        const isExistRedundantlyGroupItem = action.meta.getIsExistRedundantlyGroupItem(
          newInputAreaDataList,
          newScreeningId
        );
        // 件数上限チェック
        const uniqueScreeningIds = newInputAreaDataList.filter(
          ({ screeningId }, index, array) =>
            array.findIndex(data => data.screeningId == screeningId) == index
        );
        if (
          !matchedData &&
          uniqueScreeningIds.length < action.meta.maxCount &&
          !isExistRedundantlyGroupItem
        ) {
          const priorities = ["1", "3", "2", "5", "6", "7"]; // inputAreaId 優先度リスト
          newInputAreaDataList.push(conditions[0]);
          // inputAreaId によるソート
          newInputAreaDataList.sort((a, b) => {
            const aPriorityIndex = priorities.indexOf(a.inputAreaId);
            const bPriorityIndex = priorities.indexOf(b.inputAreaId);
            if (aPriorityIndex == bPriorityIndex) return 0;
            if (aPriorityIndex == -1) return 1;
            if (bPriorityIndex == -1) return -1;
            return aPriorityIndex - bPriorityIndex;
          });
        }
      }
      return {
        ...state,
        inputAreaDataList: newInputAreaDataList,
        ytConditionDisabled: getYtConditionDisabled(newInputAreaDataList),
        isLoadingUserConditionAdd: false
      };
    },
    [`${ActionTypes.USER_CONDITION_ADD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingUserConditionAdd: false,
      error: action.payload
    }),
    [`${ActionTypes.FAVORITE_CHECK}_FULFILLED`]: (state, action) => ({
      ...state,
      tempSaveFavoriteData: action.meta
    }),
    [`${ActionTypes.FAVORITE_SAVE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 保存した条件が選択中の条件になるよう更新
      selectedCondition: action.payload.selectedCondition,
      // 登録した条件から選択 セレクトリストの更新
      conditionData: {
        ...state.conditionData,
        toolScreeningCorpDto: {
          ...state.conditionData.toolScreeningCorpDto,
          userConditionList: action.payload.userConditionList
        }
      },
      tempSaveFavoriteData: {}
    }),
    [ActionTypes.SAVE_FAVORITE_CANCEL]: state => ({
      ...state,
      tempSaveFavoriteData: {} // 一時データを削除する
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_PENDING`]: state => ({
      ...state,
      screeningResult: {},
      comparisonData: {}, // クリア
      isLoadingScreeningResult: true
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      screeningResult: action.payload,
      comparisonConditions: {
        toolsearchKeyword: action.payload.screeningIdAndConditionsListStr,
        needs: action.payload.needsListStr
      }, // 比較表リクエスト時に必要なパラメータを保持、入力状態をクリア
      isLoadingScreeningResult: false
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      screeningResult: {},
      comparisonConditions: {}, // 選択、入力状態をクリア
      isLoadingScreeningResult: false,
      error: action.payload
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonHead: true
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_FULFILLED`]: (state, action) => {
      const comparisonHead = action.payload;
      // meta で渡された sourceSelectConditions 企業主別チェック状態を反映
      let newComparisonConditions = { ...state.comparisonConditions };
      if (!isEmpty(action.meta.sourceSelectConditions)) {
        newComparisonConditions = {
          ...newComparisonConditions,
          ...action.meta.sourceSelectConditions
        };
      }
      const {
        userIndexGroupListOfBasic,
        userIndexGroupListOfBusiness,
        userIndexGroupListOfUserSet
      } = comparisonHead.userIndexGroupListDto;
      let userIndexValue;
      if (
        !isEmpty(action.meta.dispItemListInitValue) &&
        userIndexGroupListOfUserSet.find(
          index => index.value == action.meta.dispItemListInitValue
        )
      ) {
        // 選択中のユーザ指標
        userIndexValue = action.meta.dispItemListInitValue;
      } else if (!isEmpty(action.meta.nowEditItem)) {
        // ユーザ指標編集で選択されたアイテムのラベル
        userIndexValue = userIndexGroupListOfUserSet.find(
          index => index.label == action.meta.nowEditItem
        ).value;
      } else {
        // デフォルト値
        userIndexValue = !isEmpty(userIndexGroupListOfBusiness)
          ? userIndexGroupListOfBusiness[0].value
          : userIndexGroupListOfBasic[0].value;
      }
      newComparisonConditions["userIndexValue"] = userIndexValue;
      newComparisonConditions["accKbnValue"] =
        comparisonHead.accKbnList[0].value;
      newComparisonConditions["ytConditionValue"] = state.conditionData
        .ytConditionList
        ? state.conditionData.ytConditionList.find(item => item.selected).value
        : "";
      newComparisonConditions["scConditionValue"] = state.conditionData
        .scConditionList
        ? state.conditionData.scConditionList.find(item => item.selected).value
        : "";
      newComparisonConditions["currencyConditionValue"] = state.conditionData
        .currencyList
        ? state.conditionData.currencyList.find(item => item.selected).value
        : "";
      newComparisonConditions["ltmValue"] = "0";
      newComparisonConditions["expectValue"] = "0";
      return {
        ...state,
        comparisonHead: comparisonHead,
        comparisonConditions: newComparisonConditions,
        isLoadingComparisonHead: false
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonHead: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true,
      init: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_FULFILLED`]: (state, action) => {
      const isCheckboxesActive =
        action.payload.selectItemDisabled == "1" ||
        action.payload.selectItemDisabled == "3";
      return {
        ...state,
        comparisonData: action.payload,
        comparisonConditions: {
          ...state.comparisonConditions,
          // チェックボックス群が無効になった場合、選択状態解除
          ltmValue: isCheckboxesActive
            ? state.comparisonConditions.ltmValue
            : "0",
          expectValue: isCheckboxesActive
            ? state.comparisonConditions.expectValue
            : "0"
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_FULFILLED`]: (state, action) => {
      const sortData = action.payload;
      return {
        ...state,
        comparisonData: {
          ...state.comparisonData,
          maxWidthOfAllColumns: sortData.maxWidthOfAllColumns,
          tableData: sortData.tableData
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_SORT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_FULFILLED`]: (
      state,
      action
    ) => {
      const sortData = action.payload;
      return {
        ...state,
        comparisonData: {
          ...state.comparisonData,
          // 必要なデータだけマージする
          africaNkcodeCount: sortData.africaNkcodeCount,
          africaNkcodeTotalCount: sortData.africaNkcodeTotalCount,
          asiaPacificNkcodeCount: sortData.asiaPacificNkcodeCount,
          asiaPacificNkcodeTotalCount: sortData.asiaPacificNkcodeTotalCount,
          currentNkCodeList: sortData.currentNkCodeList,
          errorMessage: sortData.errorMessage,
          europeNkcodeCount: sortData.europeNkcodeCount,
          europeNkcodeTotalCount: sortData.europeNkcodeTotalCount,
          hasReachedMaxDisplayableNkcodeCount:
            sortData.hasReachedMaxDisplayableNkcodeCount,
          industrycorptop: sortData.industrycorptop,
          listingNkcodeCount: sortData.listingNkcodeCount,
          listingNkcodeTotalCount: sortData.listingNkcodeTotalCount,
          maxDisplayableNkcodeCount: sortData.maxDisplayableNkcodeCount,
          maxWidthOfAllColumns: sortData.maxWidthOfAllColumns,
          messageDto: sortData.messageDto,
          mideastNkcodeCount: sortData.mideastNkcodeCount,
          mideastNkcodeTotalCount: sortData.mideastNkcodeTotalCount,
          nkCodeListTotalList: sortData.nkCodeListTotalList,
          nkCodeShowMaxSize: sortData.nkCodeShowMaxSize,
          nkcodeCountForShow: sortData.nkcodeCountForShow,
          northAmericaNkcodeCount: sortData.northAmericaNkcodeCount,
          northAmericaNkcodeTotalCount: sortData.northAmericaNkcodeTotalCount,
          sortFlag: sortData.sortFlag,
          sortID: sortData.sortID,
          sortedNkCodeList: sortData.sortedNkCodeList,
          southAmericaNkcodeCount: sortData.southAmericaNkcodeCount,
          southAmericaNkcodeTotalCount: sortData.southAmericaNkcodeTotalCount,
          tableData: sortData.tableData,
          unListedHolderNkcodeCount: sortData.unListedHolderNkcodeCount,
          unListedHolderNkcodeTotalCount:
            sortData.unListedHolderNkcodeTotalCount,
          unListedNkcodeCount: sortData.unListedNkcodeCount,
          unListedNkcodeTotalCount: sortData.unListedNkcodeTotalCount,
          unListedTdbNkcodeCount: sortData.unListedTdbNkcodeCount,
          unListedTdbNkcodeTotalCount: sortData.unListedTdbNkcodeTotalCount,
          unlistedNorthAmericaNkcodeTotalCount:
            sortData.unlistedNorthAmericaNkcodeTotalCount,
          unlistedSouthAmericaNkcodeTotalCount:
            sortData.unlistedSouthAmericaNkcodeTotalCount,
          unlistedEuropeNkcodeTotalCount:
            sortData.unlistedEuropeNkcodeTotalCount,
          unlistedAsiaPacificNkcodeTotalCount:
            sortData.unlistedAsiaPacificNkcodeTotalCount,
          unlistedMideastNkcodeTotalCount:
            sortData.unlistedMideastNkcodeTotalCount,
          unlistedAfricaNkcodeTotalCount:
            sortData.unlistedAfricaNkcodeTotalCount
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.COMPARISON_DATA_CLEAR]: state => ({
      ...state,
      screeningResult: {},
      comparisonData: {}, // クリア
      comparisonConditions: {} // 選択、入力状態をクリア
    }),
    [ActionTypes.COMPARISON_CONDITIONS_UPDATE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [`${ActionTypes.CHART_COUNT_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartCount: true
    }),
    [`${ActionTypes.CHART_COUNT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData:
        parseInt(action.payload) >= action.meta.nkcodeInfosLength
          ? state.chartData
          : {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_COUNT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      chartData: {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: {
        ...state.chartData,
        chart01Str: action.payload.chart01Str,
        chart02Str: action.payload.chart02Str,
        chart03Str: action.payload.chart03Str,
        chart04Str: action.payload.chart04Str
      },
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [ActionTypes.CHART_DATA_CLEAR]: state => ({
      ...state,
      chartData: null
    }),
    [ActionTypes.RESULT_DATA_APPLY]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [`${ActionTypes.SAVED_FAVORITE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_FAVORITE_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 登録した条件から選択 セレクトボックスで使うデータも更新する
      conditionData: {
        ...state.conditionData,
        toolScreeningCorpDto: {
          ...state.conditionData.toolScreeningCorpDto,
          userConditionList: action.payload.userConditionList
        }
      },
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_DELETE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_FAVORITE_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 登録した条件から選択 セレクトボックスで使うデータも更新する
      conditionData: {
        ...state.conditionData,
        toolScreeningCorpDto: {
          ...state.conditionData.toolScreeningCorpDto,
          userConditionList: action.payload.userConditionList
        }
      },
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_FULFILLED`]: (state, action) => {
      const newInputAreaDataList = action.payload.toolCommon06DtoList.slice();
      const newYtConditionDisabled = getYtConditionDisabled(
        newInputAreaDataList
      );
      // 有報・短信が disabled になった際に、リストの選択状態をリセット
      const newYtConditionList = [...state.conditionData.ytConditionList];
      if (
        newYtConditionDisabled &&
        newYtConditionDisabled !== state.ytConditionDisabled
      ) {
        newYtConditionList.forEach(item => delete item.selected);
        newYtConditionList[0].selected = true;
      }
      return {
        ...state,
        selectedCondition: action.meta.favoriteId,
        conditionData: {
          ...state.conditionData,
          ytConditionList: newYtConditionList
        },
        inputAreaDataList: newInputAreaDataList,
        ytConditionDisabled: newYtConditionDisabled
      };
    },
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_PENDING`]: state => ({
      ...state,
      needsMstL: [],
      isLoadingNeedsMstL: true
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstM: true
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstS: false
    }),
    [ActionTypes.NEEDS_MST_CLEAR]: state => ({
      ...state,
      needsMstL: [],
      needsMstM: [],
      needsMstS: []
    }),
    [`${ActionTypes.MARKET_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      marketList: action.payload.sijyouLst
    }),
    [`${ActionTypes.MARKET_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.FNCL_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      fnclList: action.payload.fnclLst
    }),
    [`${ActionTypes.FNCL_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.ADDRESS_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      addressList: action.payload.addressLst
    }),
    [`${ActionTypes.ADDRESS_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NTCLS_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      ntclsList: action.payload.ntclsList
    }),
    [`${ActionTypes.NTCLS_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_FULFILLED`]: (state, action) => {
      const newIndexTree = action.payload;
      let selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      if (action.meta.kensakuName !== undefined && selectedIndex.length === 0) {
        selectedIndex = state.selectedIndex;
      }
      if (!isEmpty(action.meta.kensakuName)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            if (!isEmpty(item.child)) {
              item.childExistFlag = true;
              item.isOpen = true;
              item.childLoaded = true;
              setOpenToAllChildState(item.child);
            }
            return item;
          });
        };
        newIndexTree.treeDataList = setOpenToAllChildState(
          newIndexTree.treeDataList
        );
      }
      return {
        ...state,
        indexTree: newIndexTree,
        selectedOption: isEmpty(state.selectedOption)
          ? action.payload.userSelect[0].value
          : state.selectedOption,
        selectedIndex: selectedIndex
      };
    },
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      const newIndexTree = { ...state.indexTree };
      let depth = 0;
      const updateTargetChildState = treeDataList => {
        const targetParentItemId = meta.currentTreeIds[depth];
        for (const prop in treeDataList) {
          const item = treeDataList[prop];
          if (item.id === targetParentItemId) {
            depth += 1;
            updateTargetChildState(item.child);
          } else if (!targetParentItemId && item.id === meta.currentId) {
            if (!item.childLoaded) {
              item.child = payload.treeDataList;
              item.childLoaded = true;
              item.isOpen = true;
            } else {
              item.isOpen = !item.isOpen;
            }
          }
        }
      };
      updateTargetChildState(newIndexTree.treeDataList);

      return {
        ...state,
        indexTree: newIndexTree
      };
    },
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_FULFILLED`]: state => state,
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_ADD}_FULFILLED`]: (state, action) => {
      const newSelectedIndex = [...state.selectedIndex];
      // 存在、上限チェック
      const matchedIndex = newSelectedIndex.find(
        index => index.screeningId == action.payload.screeningId
      );
      // （#）重複チェック
      const isExistRedundantlyScreeningId =
        state.selectedIndex.find(
          ({ screeningId }) =>
            action.meta.indexEditorRedundantlyScreeningIds.indexOf(
              screeningId
            ) >= 0
        ) &&
        action.meta.indexEditorRedundantlyScreeningIds.indexOf(
          action.payload.screeningId
        ) >= 0;
      if (
        !matchedIndex &&
        parseInt(state.indexTree.maxCount) > newSelectedIndex.length &&
        !isExistRedundantlyScreeningId
      ) {
        newSelectedIndex.push(action.payload);
      }
      return {
        ...state,
        selectedIndex: newSelectedIndex
      };
    },
    [`${ActionTypes.INDEX_ADD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedIndex: action.payload
    }),
    [ActionTypes.INDEX_REMOVE_ALL]: state => ({
      ...state,
      selectedIndex: [],
      // 選択中の条件も先頭にリセット
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      indexTree: {
        ...state.indexTree,
        userSelect: action.payload
      },
      selectedOption: action.payload[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_SET}_FULFILLED`]: state => ({
      ...state,
      indexTree: {} // クリア
    }),
    [`${ActionTypes.CONDITION_SET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      return {
        ...state,
        selectedIndex: selectedIndex,
        selectedOption: action.meta.userSetId
      };
    },
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_ALL_CLEAR]: state => ({
      ...state,
      indexTree: {},
      selectedOption: "",
      parentId: "",
      isAvailableIndex: "",
      selectedIndex: [],
      savedConditionList: []
    }),

    /**
     * 比較表データ再取得系
     */
    [ActionTypes.SEARCH_FLAG_SET]: (state, action) => ({
      ...state,
      needsSearch: true,
      // 初回リクエストのみ init を付与する必要があるため、 payload から受けとる
      init: action.payload && action.payload.init ? true : false
    }),
    [ActionTypes.SEARCH_FLAG_RESET]: state => ({
      ...state,
      needsSearch: false
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      }
    }),

    [`${ActionTypes.DOWNLOAD_PERIOD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      dlPeriodList: action.payload.periodList,
      dlPeriodFrom: action.payload.periodFrom
    }),
    [ActionTypes.DOWNLOAD_PERIOD_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),
    [ActionTypes.ALL_RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => request(730, { ...params.payload, corpIndustyKbn: "7" }),
    params => params.meta
  ),
  updateConditionSourceList: createAction(
    ActionTypes.CONDITION_SOURCE_LIST_UPDATE
  ),
  clearSelectedCondition: createAction(ActionTypes.SELECTED_CONDITION_CLEAR),
  updateConditionData: createAction(ActionTypes.CONDITION_DATA_UPDATE),
  updateYtConditionSelected: createAction(
    ActionTypes.YT_CONDITION_SELECTED_UPDATE
  ),
  updateScConditionSelected: createAction(
    ActionTypes.SC_CONDITION_SELECTED_UPDATE
  ),
  updateCurrencySelected: createAction(ActionTypes.CURRENCY_SELECTED_UPDATE),
  updateInputAreaDataList: createAction(
    ActionTypes.INPUT_AREA_DATA_LIST_UPDATE
  ),
  getTreeMst: createAction(ActionTypes.TREE_MST_GET, params =>
    request(733, params)
  ),
  getScreeningTree: createAction(
    ActionTypes.SCREENING_TREE_GET,
    params => request(734, params.payload),
    params => params.meta
  ),
  getChildData: createAction(
    ActionTypes.CHILD_DATA_GET,
    params => request(736, params.payload),
    params => params.meta
  ),
  getUserCondition: createAction(ActionTypes.USER_CONDITION_GET, params =>
    request(735, params)
  ),
  getUserConditionAdd: createAction(
    ActionTypes.USER_CONDITION_ADD_GET,
    params => request(737, params.payload),
    params => params.meta
  ),
  checkFavorite: createAction(
    ActionTypes.FAVORITE_CHECK,
    params => request(747, params),
    // 保存処理を行う際に必要なパラメータを meta に仕込み、リクエスト成功時に tempSaveFavoriteData に格納
    params => ({
      favoriteId: params.favoliteId,
      favoriteName: params.favoliteName,
      favoriteDetail: params.favoliteDetail,
      favoriteValue: params.favoliteValue
    })
  ),
  saveFavorite: createAction(ActionTypes.FAVORITE_SAVE, params =>
    request(748, params)
  ),
  cancelSaveFavorite: createAction(ActionTypes.SAVE_FAVORITE_CANCEL),
  getScreeningResult: createAction(ActionTypes.SCREENING_RESULT_GET, params =>
    request(751, params)
  ),
  getComparisonHead: createAction(
    ActionTypes.COMPARISON_HEAD_GET,
    params => request(595, params.payload),
    params => ({ ...params.meta })
  ),
  getComparisonData: createAction(ActionTypes.COMPARISON_DATA_GET, params =>
    request(597, params)
  ),
  sortComparisonData: createAction(ActionTypes.COMPARISON_DATA_SORT, params =>
    request(609, params)
  ),
  removeComparisonDataRow: createAction(
    ActionTypes.COMPARISON_DATA_ROW_REMOVE,
    params => request(597, params)
  ),
  clearComparisonData: createAction(ActionTypes.COMPARISON_DATA_CLEAR),
  updateComparisonConditions: createAction(
    ActionTypes.COMPARISON_CONDITIONS_UPDATE
  ),
  getChartCount: createAction(
    ActionTypes.CHART_COUNT_GET,
    params => request(614, params.payload),
    params => params.meta
  ),
  getChartData: createAction(ActionTypes.CHART_DATA_GET, params =>
    request(629, params)
  ),
  updateChartData: createAction(ActionTypes.CHART_DATA_UPDATE, params =>
    request(630, params)
  ),
  clearChartData: createAction(ActionTypes.CHART_DATA_CLEAR),
  applyResultData: createAction(ActionTypes.RESULT_DATA_APPLY),
  getSavedFavoriteList: createAction(ActionTypes.SAVED_FAVORITE_GET, params =>
    request(746, params)
  ),
  deleteSavedFavorite: createAction(ActionTypes.SAVED_FAVORITE_DELETE, params =>
    request(750, params)
  ),
  updateSavedFavorite: createAction(ActionTypes.SAVED_FAVORITE_UPDATE, params =>
    request(749, params)
  ),
  getSavedFavoriteDetail: createAction(
    ActionTypes.SAVED_FAVORITE_DETAIL_GET,
    params => request(745, params),
    // selectedCondition に残したいパラメータのみを meta に渡す
    params => ({ favoriteId: params.favoliteId })
  ),
  getNeedsMstL: createAction(ActionTypes.NEEDS_MST_L_GET, params =>
    request(738, params)
  ),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(739, params)
  ),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(740, params)
  ),
  clearNeedsMst: createAction(ActionTypes.NEEDS_MST_CLEAR),
  getMarketList: createAction(ActionTypes.MARKET_LIST_GET, params =>
    request(741, params)
  ),
  getFnclList: createAction(ActionTypes.FNCL_LIST_GET, params =>
    request(743, params)
  ),
  getAddressList: createAction(ActionTypes.ADDRESS_LIST_GET, params =>
    request(744, params)
  ),
  getNtclsList: createAction(ActionTypes.NTCLS_LIST_GET, params =>
    request(742, params)
  ),
  getIndexTreeRoot: createAction(
    ActionTypes.INDEX_TREE_ROOT_GET,
    params => request(599, params),
    params => ({ kensakuName: params.kensakuName })
  ),
  getIndexTreeChild: createAction(
    ActionTypes.INDEX_TREE_CHILD_GET,
    params => request(606, params.payload),
    params => params.meta
  ),
  getIndexAvailability: createAction(
    ActionTypes.INDEX_AVAILABILITY_GET,
    params => request(605, params)
  ),
  addIndex: createAction(
    ActionTypes.INDEX_ADD,
    params => request(607, params.payload),
    params => params.meta
  ),
  updateIndex: createAction(ActionTypes.INDEX_UPDATE),
  removeAllIndex: createAction(ActionTypes.INDEX_REMOVE_ALL),
  getSavedConditionList: createAction(ActionTypes.SAVED_CONDITION_GET, params =>
    request(600, params)
  ),
  getSavedConditionSelectList: createAction(
    ActionTypes.SAVED_CONDITION_SELECT_GET,
    params => request(608, params)
  ),
  deleteSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_DELETE,
    params => request(602, params)
  ),
  updateSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_UPDATE,
    params => request(601, params)
  ),
  getSavedConditionDetail: createAction(
    ActionTypes.SAVED_CONDITION_DETAIL_GET,
    params => request(604, params),
    params => ({ userSetId: params.userSetId })
  ),
  setCondition: createAction(
    ActionTypes.CONDITION_SET,
    params => request(603, params),
    params => ({ userSetName: params.userSetName })
  ),
  clearIndexAll: createAction(ActionTypes.INDEX_ALL_CLEAR),

  // 比較表データ再取得系
  setSearchFlag: createAction(ActionTypes.SEARCH_FLAG_SET),
  resetSearchFlag: createAction(ActionTypes.SEARCH_FLAG_RESET),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET),

  getDownloadPeriod: createAction(ActionTypes.DOWNLOAD_PERIOD_GET, params =>
    request(616, params)
  ),
  setDownloadPeriod: createAction(
    ActionTypes.DOWNLOAD_PERIOD_SET,
    period => period,
    (period, key) => ({ key })
  ),
  resetAll: createAction(ActionTypes.ALL_RESET)
};
