// 検索文字列の更新
export const KEYWORD_UPDATE = "article/report/KEYWORD_UPDATE";
// 検索文字列の更新
export const OPERAND_RAW_UPDATE = "article/report/OPERAND_RAW_UPDATE";
// 高度な検索：開閉
export const IS_OPEN_ADVANCED_SEARCH_UPDATE =
  "article/report/IS_OPEN_ADVANCED_SEARCH_UPDATE";
// 検索期間：全期間かどうか
export const ENABLE_ALL_PERIOD_SET = "article/report/ENABLE_ALL_PERIOD_SET";
// 検索from日付の設定
export const FROM_DATE_UPDATE = "article/report/FROM_DATE_UPDATE";
// 検索to日付の設定
export const TO_DATE_UPDATE = "article/report/TO_DATE_UPDATE";
//検索範囲更新
export const FLD_LIST_UPDATE = "article/report/FLD_LIST_UPDATE";
// 国内チェック状態対象更新
export const BAITAI_LIST_UPDATE = "article/report/BAITAI_LIST_UPDATE";
// グローバルチェック状態更新
export const GLOBAL_BAITAI_LIST_UPDATE =
  "article/report/GLOBAL_BAITAI_LIST_UPDATE";
// 初期表示
export const PAGE_INIT = "article/report/PAGE_INIT";
//雑誌・レポート検索結果
export const REPORT_DATA_SEARCH = "article/report/REPORT_DATA_SEARCH";
//検索条件をクリア
export const CONDITIONS_CLEAR = "article/report/REPORT_DATA_SEARCH";
//雑誌・レポート一覧取得
export const TAB_LIST_GET = "article/report/TAB_LIST_GET";
//レポート一覧検索
export const GLANCE_LIST_GET = "article/report/GLANCE_LIST_GET";
//記事のリストを空にします
export const ARTICLE_LIST_CLEAR = "article/report/ARTICLE_LIST_CLEAR";
// ソート・表示件数を変更してデータ取得
export const REPORT_DATA_SORT = "article/report/REPORT_DATA_SORT";
// 雑誌・レポートページ変更
export const REPORT_PAGE_GET = "article/report/REPORT_PAGE_GET";
// レポート国変更
export const COUNTRY_LIST_GET = "article/report/COUNTRY_LIST_GET";
// 雑誌フィルターチェックボックス初期化
export const PARAMETER_LIST_GET = "article/report/PARAMETER_LIST_GET";

export const PARAMETER_LOADING_SET = "article/report/PARAMETER_LOADING_SET";
export const PARAMETER_LOADING_RESET = "article/report/PARAMETER_LOADING_RESET";
// 自動検索実行用フラグ
export const DO_FORCE_SEARCH_SET = "article/report/DO_FORCE_SEARCH_SET";
// 全データクリア
export const ALL_RESET = "article/news/ALL_RESET";
// 選択している媒体をセット
export const SET_SELECTED_ITEM_REPORT =
  "article/report/SET_SELECTED_ITEM_REPORT";
// トップページの「日経業界分析レポート」のもっと見るから遷移されたかのフラグ
export const SET_IS_MORE_SECTOR_REPORT =
  "article/report/SET_IS_MORE_SECTOR_REPORT";
