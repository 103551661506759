import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";

const initialState = {
  data: {
    industrySummary01: null,
    ToolCreategraphDto: null,
    html01: null,
    html02: null,
    html03: null,
    TOOLCREATEGRAPHDTO1: null,
    TOOLCREATEGRAPHDTO2: null
  },
  industryText: {
    gyoukaiGaiyou: null,
    sijouKannkyou: null,
    kyousouKannkyou: null
  },
  newsPage: {},
  categoryItems: {},
  mainFlgList: {},
  comparisonConditions: {
    userIndexValue: "", // 項目
    accKbnValue: "", // 期間単位
    ytConditionValue: "", // 有報
    scConditionValue: "", // 連結
    currencyConditionValue: "", // 通貨単位
    ltmValue: "0", // LTM
    expectValue: "0", // 日経予想
    listingCorpSelect: "", // 上場
    unlistedHolderCorpSelect: "", // 非上場有報
    unlistedTdbCorpSelect: "", // 非上場(TDB)
    unlistedCorpSelect: "", // 非上場(日経)
    industrycorptop: "", // 売上高上位
    industrycorptopCondition: "",
    northAmericaSelect: "", // 北米
    southAmericaSelect: "", // 南米
    europeSelect: "", // 欧州
    asiaSelect: "", // アジア
    mideastSelect: "", // 中東
    africaSelect: "", // アフリカ
    nkgncodes: "", // 業界
    mainFlg: "", // 主／所属業種
    sortId: "", // ソート列番号
    sortFlag: "", // ソートの種類 up|down
    unlistedNorthAmericaSelect: "",
    unlistedSouthAmericaSelect: "",
    unlistedEuropeSelect: "",
    unlistedAsiaSelect: "",
    unlistedMideastSelect: "",
    unlistedAfricaSelect: ""
  },
  nkCodeListTotalList: "", // 企業コードリスト
  originalNkCodeList: "", //
  currentNkCodeList: "",
  nkcodeList: "",
  comparisonHead: {},
  isLoadingComparisonHead: false,
  comparisonData: {},
  isLoadingComparisonData: false,
  needsSearch: false, // getComparisonData 再リクエストフラグ
  isComparisonCleared: false, // 1000 件表示キャンセル時に内容非表示にするフラグ
  init: false,
  isLoadingChartCount: false,
  chartData: {},
  isLoadingChartData: false,
  zAxisList: [],
  indexTree: [],
  selectedOption: "", // ユーザ指標編集 選択中の保存条件
  parentId: "",
  isAvailableIndex: "",
  selectedIndex: [],
  savedConditionList: [],
  dlPeriodList: [],
  dlPeriodFrom: "",
  dlPeriodTo: "",
  error: null
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: { ...state.data, ...action.payload },
      nkCodeListTotalList: action.payload.industryComparisonDto.nkcodeList,
      originalNkCodeList: action.payload.industryComparisonDto.nkcodeList,
      currentNkCodeList: action.payload.industryComparisonDto.nkcodeList,
      zAxisList: action.payload.labelValueDtoList08,
      isComparisonCleared: false
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDUSTRY_TEXT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      industryText: action.payload
    }),
    [`${ActionTypes.INDUSTRY_TEXT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NEWS_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      newsPage: action.payload
    }),
    [`${ActionTypes.NEWS_PAGE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDUSTRY_ENTRT_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      categoryItems: action.payload.corpEntityList,
      mainFlgList: action.payload.mainFlgList
    }),
    [ActionTypes.COMPARISON_CONDITION_UPDATE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: {
        ...state.comparisonData,
        tableData: action.payload.tableData
      },
      currentNkCodeList: action.payload.sortedNkCodeList,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.SORT_COMPARISON_DATA}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingComparisonData: false,
      error: action.payload,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.DELETE_ROW}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.DELETE_ROW}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: action.payload,
      currentNkCodeList: action.payload.currentNkCodeList,
      nkCodeListTotalList: action.payload.nkCodeListTotalList,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.DELETE_ROW}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingComparisonData: false,
      error: action.payload,
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.SEARCH_DATA_CLEAR]: state => ({
      ...state,
      comparisonData: {}, // クリア
      comparisonConditions: {}, // 選択、入力状態をクリア
      isComparisonCleared: true
    }),
    [`${ActionTypes.LIST_CHANGE}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        comparisonData: {},
        originalNkCodeList: action.payload.nikkeiCodeList,
        currentNkCodeList: action.payload.nikkeiCodeList,
        nkCodeListTotalList: action.payload.nikkeiCodeList
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonHead: true
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_FULFILLED`]: (state, action) => {
      const comparisonHead = action.payload;
      let newComparisonConditions = { ...state.comparisonConditions };
      if (!isEmpty(action.meta.sourceSelectConditions)) {
        newComparisonConditions = {
          ...newComparisonConditions,
          ...{
            africaSelect: "1",
            asiaSelect: "1",
            europeSelect: "1",
            listingCorpSelect: "1",
            mideastSelect: "1",
            northAmericaSelect: "1",
            southAmericaSelect: "1",
            unlistedAfricaSelect: "1",
            unlistedAsiaSelect: "1",
            unlistedCorpSelect: "1",
            unlistedEuropeSelect: "1",
            unlistedHolderCorpSelect: "1",
            unlistedMideastSelect: "1",
            unlistedNorthAmericaSelect: "1",
            unlistedSouthAmericaSelect: "1",
            unlistedTdbCorpSelect: "1"
          } // 存在する企業種別は全てONにしておく
        };
      }
      const {
        userIndexGroupListOfBasic,
        userIndexGroupListOfUserSet
      } = comparisonHead.userIndexGroupListDto;
      // セレクトボックス初期値の取得
      let userIndexValue;
      if (
        !isEmpty(action.meta.dispItemListInitValue) &&
        userIndexGroupListOfUserSet.find(
          index => index.value == action.meta.dispItemListInitValue
        )
      ) {
        // 選択中のユーザ指標
        userIndexValue = action.meta.dispItemListInitValue;
      } else if (!isEmpty(action.meta.nowEditItem)) {
        // ユーザ指標編集で選択されたアイテムのラベル
        userIndexValue = userIndexGroupListOfUserSet.find(
          index => index.label == action.meta.nowEditItem
        ).value;
      } else {
        // デフォルト値
        userIndexValue = !isEmpty(userIndexGroupListOfBasic)
          ? userIndexGroupListOfBasic[0].value
          : userIndexGroupListOfUserSet[0].value;
      }
      // 主業種選択肢
      if (state.mainFlgList[0] && !state.mainFlgList[0].value)
        state.mainFlgList[0].value = "";

      const mainFlg = isEmpty(action.meta.mainFlg)
        ? state.mainFlgList[0]
          ? state.mainFlgList[0].value
          : ""
        : action.meta.mainFlg;

      newComparisonConditions = {
        ...newComparisonConditions,
        userIndexValue: userIndexValue,
        accKbnValue: comparisonHead.accKbnList[0].value,
        ytConditionValue: comparisonHead.ytCommonConditionList[0].value,
        scConditionValue: comparisonHead.scCommonConditionList[0].value,
        currencyConditionValue: comparisonHead.currencyList[0].value,
        nkgncodes: state.categoryItems[0] ? state.categoryItems[0].value : "",
        mainFlg: mainFlg
      };
      return {
        ...state,
        comparisonHead: action.payload,
        comparisonConditions: {
          ...state.comparisonConditions,
          ...newComparisonConditions
        },
        isLoadingComparisonHead: false
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonHead: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true,
      init: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_FULFILLED`]: (state, action) => {
      const isCheckboxesActive =
        action.payload.selectItemDisabled == "1" ||
        action.payload.selectItemDisabled == "3";

      return {
        ...state,
        comparisonData: action.payload,
        nkCodeListTotalList: action.payload.nkCodeListTotalList,
        currentNkCodeList: action.payload.currentNkCodeList,
        comparisonConditions: {
          ...state.comparisonConditions,
          // チェックボックス群が無効になった場合、選択状態解除
          ltmValue: isCheckboxesActive
            ? state.comparisonConditions.ltmValue
            : "0",
          expectValue: isCheckboxesActive
            ? state.comparisonConditions.expectValue
            : "0"
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.SEARCH_FLAG_SET]: (state, action) => ({
      ...state,
      needsSearch: true,
      // 初回リクエストのみ init を付与する必要があるため、 payload から受けとる
      init: action.payload && action.payload.init ? true : false
    }),
    [ActionTypes.SEARCH_FLAG_RESET]: state => ({
      ...state,
      needsSearch: false
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      }
    }),
    [`${ActionTypes.CHART_COUNT_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartCount: true
    }),
    [`${ActionTypes.CHART_COUNT_GET}_FULFILLED`]: state => ({
      ...state,
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_COUNT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: {
        ...state.chartData,
        chart01Str: action.payload.chart01Str,
        chart02Str: action.payload.chart02Str,
        chart03Str: action.payload.chart03Str,
        chart04Str: action.payload.chart04Str
      },
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [ActionTypes.CHART_DATA_CLEAR]: state => ({
      ...state,
      chartData: null
    }),
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_FULFILLED`]: (state, action) => {
      const newIndexTree = action.payload;
      let selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      if (action.meta.kensakuName !== undefined && selectedIndex.length === 0) {
        selectedIndex = state.selectedIndex;
      }
      if (!isEmpty(action.meta.kensakuName)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            if (!isEmpty(item.child)) {
              item.childExistFlag = true;
              item.isOpen = true;
              item.childLoaded = true;
              setOpenToAllChildState(item.child);
            }
            return item;
          });
        };
        newIndexTree.treeDataList = setOpenToAllChildState(
          newIndexTree.treeDataList
        );
      }
      return {
        ...state,
        indexTree: newIndexTree,
        selectedOption: isEmpty(state.selectedOption)
          ? action.payload.userSelect[0].value
          : state.selectedOption,
        selectedIndex: selectedIndex
      };
    },
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      const newIndexTree = { ...state.indexTree };
      let depth = 0;
      const updateTargetChildState = treeDataList => {
        const targetParentItemId = meta.currentTreeIds[depth];
        for (const prop in treeDataList) {
          const item = treeDataList[prop];
          if (item.id === targetParentItemId) {
            depth += 1;
            updateTargetChildState(item.child);
          } else if (!targetParentItemId && item.id === meta.currentId) {
            if (!item.childLoaded) {
              item.child = payload.treeDataList;
              item.childLoaded = true;
              item.isOpen = true;
            } else {
              item.isOpen = !item.isOpen;
            }
          }
        }
      };
      updateTargetChildState(newIndexTree.treeDataList);

      return {
        ...state,
        indexTree: newIndexTree
      };
    },
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_FULFILLED`]: state => state,
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_ADD}_FULFILLED`]: (state, action) => {
      const newSelectedIndex = [...state.selectedIndex];
      // 存在、上限チェック
      const matchedIndex = newSelectedIndex.find(
        index => index.screeningId == action.payload.screeningId
      );
      // （#）重複チェック
      const isExistRedundantlyScreeningId =
        state.selectedIndex.find(
          ({ screeningId }) =>
            action.meta.indexEditorRedundantlyScreeningIds.indexOf(
              screeningId
            ) >= 0
        ) &&
        action.meta.indexEditorRedundantlyScreeningIds.indexOf(
          action.payload.screeningId
        ) >= 0;
      if (
        !matchedIndex &&
        parseInt(state.indexTree.maxCount) > newSelectedIndex.length &&
        !isExistRedundantlyScreeningId
      ) {
        newSelectedIndex.push(action.payload);
      }
      return {
        ...state,
        selectedIndex: newSelectedIndex
      };
    },
    [`${ActionTypes.INDEX_ADD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedIndex: action.payload
    }),
    [ActionTypes.INDEX_REMOVE_ALL]: state => ({
      ...state,
      selectedIndex: [],
      // 選択中の条件も先頭にリセット
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      indexTree: {
        ...state.indexTree,
        userSelect: action.payload
      },
      selectedOption: action.payload[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_SET}_FULFILLED`]: state => ({
      ...state,
      indexTree: {} // クリア
    }),
    [`${ActionTypes.CONDITION_SET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      return {
        ...state,
        selectedIndex: selectedIndex,
        selectedOption: action.meta.userSetId
      };
    },
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_ALL_CLEAR]: state => ({
      ...state,
      indexTree: {},
      selectedOption: "",
      parentId: "",
      isAvailableIndex: "",
      selectedIndex: [],
      savedConditionList: []
    }),
    [`${ActionTypes.DOWNLOAD_PERIOD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      dlPeriodList: action.payload.periodList,
      dlPeriodFrom: action.payload.periodFrom,
      // API からの返却値に periodTo が含まれない
      dlPeriodTo: action.payload.periodList[0].value
    }),
    [ActionTypes.DOWNLOAD_PERIOD_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(403, params)),
  getIndustryText: createAction(ActionTypes.INDUSTRY_TEXT_GET, params =>
    request(404, params)
  ),
  getNewsPage: createAction(ActionTypes.NEWS_PAGE_GET, params =>
    request(405, params)
  ),
  getIndustryEntryList: createAction(
    ActionTypes.INDUSTRY_ENTRT_LIST_GET,
    params => request(410, params)
  ),
  updateComparisonConditions: createAction(
    ActionTypes.COMPARISON_CONDITION_UPDATE,
    params => params
  ),
  sort: createAction(ActionTypes.SORT_COMPARISON_DATA, params =>
    request(609, params)
  ),
  deleteRow: createAction(ActionTypes.DELETE_ROW, params =>
    request(597, params)
  ),
  clearSearchData: createAction(ActionTypes.SEARCH_DATA_CLEAR),
  changeList: createAction(ActionTypes.LIST_CHANGE, params =>
    request(411, params)
  ),
  getComparisonHead: createAction(
    ActionTypes.COMPARISON_HEAD_GET,
    params => request(595, params.payload),
    params => params.meta
  ),
  getComparisonData: createAction(ActionTypes.COMPARISON_DATA_GET, params =>
    request(597, params)
  ),
  setSearchFlag: createAction(ActionTypes.SEARCH_FLAG_SET),
  resetSearchFlag: createAction(ActionTypes.SEARCH_FLAG_RESET),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET),
  getChartCount: createAction(ActionTypes.CHART_COUNT_GET, params =>
    request(614, params)
  ),
  getChartData: createAction(ActionTypes.CHART_DATA_GET, params =>
    request(629, params)
  ),
  updateChartData: createAction(ActionTypes.CHART_DATA_UPDATE, params =>
    request(630, params)
  ),
  clearChartData: createAction(ActionTypes.CHART_DATA_CLEAR),
  getIndexTreeRoot: createAction(
    ActionTypes.INDEX_TREE_ROOT_GET,
    params => request(599, params),
    params => ({ kensakuName: params.kensakuName })
  ),
  getIndexTreeChild: createAction(
    ActionTypes.INDEX_TREE_CHILD_GET,
    params => request(606, params.payload),
    params => params.meta
  ),
  getIndexAvailability: createAction(
    ActionTypes.INDEX_AVAILABILITY_GET,
    params => request(605, params)
  ),
  addIndex: createAction(
    ActionTypes.INDEX_ADD,
    params => request(607, params.payload),
    params => params.meta
  ),
  updateIndex: createAction(ActionTypes.INDEX_UPDATE),
  removeAllIndex: createAction(ActionTypes.INDEX_REMOVE_ALL),
  getSavedConditionList: createAction(ActionTypes.SAVED_CONDITION_GET, params =>
    request(600, params)
  ),
  getSavedConditionSelectList: createAction(
    ActionTypes.SAVED_CONDITION_SELECT_GET,
    params => request(608, params)
  ),
  deleteSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_DELETE,
    params => request(602, params)
  ),
  updateSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_UPDATE,
    params => request(601, params)
  ),
  getSavedConditionDetail: createAction(
    ActionTypes.SAVED_CONDITION_DETAIL_GET,
    params => request(604, params),
    params => ({ userSetId: params.userSetId })
  ),
  setCondition: createAction(
    ActionTypes.CONDITION_SET,
    params => request(603, params),
    params => ({ userSetName: params.userSetName })
  ),
  clearIndexAll: createAction(ActionTypes.INDEX_ALL_CLEAR),

  getDownloadPeriod: createAction(ActionTypes.DOWNLOAD_PERIOD_GET, params =>
    request(616, params)
  ),
  setDownloadPeriod: createAction(
    ActionTypes.DOWNLOAD_PERIOD_SET,
    period => period,
    (period, key) => ({ key })
  )
};
