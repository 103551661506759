import { Reducer } from "redux";
import { CorpAuth } from "models/Auth";
import { AuthCorpAction } from "./actions";
import produce from "immer";

interface AuthCorpState {
  corpAuth?: CorpAuth;
}

const initialState: AuthCorpState = {
  corpAuth: undefined
};

export const authCorp: Reducer<AuthCorpState, AuthCorpAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "auth/corp/SET_CORP_AUTH":
      return produce(state, draft => {
        draft.corpAuth = action.payload;
      });
    default:
      return state;
  }
};
