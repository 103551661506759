import axios, { AxiosError } from "axios";
import { getTokenAndId } from "../helper/localStorage";
import { store } from "stores/store";
import { isIE } from "utils/helper/common";

export class VsApiError implements Error {
  public name = "VsApiError";
  public body: any;
  public path = "";

  constructor(public message: string) {}

  toString() {
    return `${this.name} ${this.message}`;
  }
}

export const request = <T>(
  endpoint: string,
  params?: any,
  type?: "GET" | "POST",
  isDownload?: boolean
) => {
  const host = API_DOMAIN === "" ? location.host : API_DOMAIN;

  const { token, id } = getApiInfo(params);
  const headers: { [key: string]: string } = {};
  if (token) headers["x-nvs-token"] = token;
  if (isIE() && MODE === "production") {
    headers["If-Modified-Since"] = "Thu, 01 Jun 1970 00:00:00 GMT";
  }
  const client = axios.create({
    baseURL: `${IS_PROXY ? "http" : "https"}://${host}/${
      isDownload ? API_DOWNLOAD_PATH : API_PATH
    }`,
    headers
  });

  return new Promise<T>((resolve, reject) => {
    if (type === "POST") {
      store.dispatch({ type: `api${endpoint}_PENDING` });
      client
        .post<T>(endpoint, { ...params, userId: id })
        .then(response => {
          store.dispatch({
            type: `api${endpoint}_FULFILLED`,
            payload: response.data
          });
          return resolve(response.data);
        })
        .catch((e: AxiosError) => {
          reject(handleError(e, endpoint));
        })
        .catch(e => reject(e));
    } else {
      store.dispatch({ type: `api${endpoint}_PENDING` });
      client
        .get<T>(endpoint, { params: { ...params, userId: id } })
        .then(response => {
          store.dispatch({
            type: `api${endpoint}_FULFILLED`,
            payload: response.data
          });
          return resolve(response.data);
        })
        .catch((e: AxiosError) => {
          reject(handleError(e, endpoint));
        })
        .catch(e => reject(e));
    }
  });
};

const getApiInfo = (params?: any) => {
  let token = "";
  let id = "";
  if (params) {
    if (params.hasOwnProperty("tokenKey")) token = params["tokenKey"];
    if (params.hasOwnProperty("userId")) id = params["userId"];
  }

  if (token === "" || id === "") {
    const tokenAndId = getTokenAndId();
    token = tokenAndId.token;
    id = tokenAndId.id;
  }

  return { token, id };
};

const handleError = (error: AxiosError, path: string) => {
  let message = "UNHANDLED_ERROR";
  switch (error.response?.status) {
    case 400:
      message = "BAD_REQUEST";
      break;
    case 401:
      message = "UNAUTHORIZED";
      break;
    case 500:
      message = "INTERNAL_SERVER_ERROR";
      break;
    case 502:
      message = "BAD_GATEWAY";
      break;
    case 504:
      message = "GATEWAY_TIMEOUT";
      break;
    case 404:
      message = "NOT_FOUND";
      break;
  }

  const vsError = new VsApiError(message);
  if (error.response) {
    vsError.body = error.response.data;
  }
  vsError.path = path;

  store.dispatch({ type: `api${path}_REJECTED`, payload: vsError });

  return vsError;
};
