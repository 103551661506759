import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { valueFromKeyOrDefault } from "utils/helper/common";
import { getCorpFinStaticId } from "utils/helper/accessContentId";

const initialState = {
  isLoading: false,
  // 企業切り替え済フラグ、企業切り替え後の不要な getPage リクエストキャンセル判断に使用する
  corpChanged: false,
  table: {}, // 表データ
  graph: [], // グラフデータ
  graphDto: [], // グラフデータ
  graphEdit: [],
  graphModifyFlagList: [],
  tabList: [], // タブ情報リスト
  download: {
    dlpropriety: "", // ダウンロード可否種別
    xls: {}, // XLSダウンロード用パラメーター
    csv: {} // CSVダウンロード用パラメーター
  },
  condition: {
    periodFromList: [], // 期間Fromの選択肢
    periodToList: [], // 期間Toの選択肢
    divisionOptionList: [], // 決算区分の選択肢
    unitOptionList: [], // 単位の選択肢
    typeOptionList: [], // 決算種別の選択肢
    selectedFrom: "", // 期間 from 選択中の値
    selectedTo: "", // 期間 to 選択中の値
    selectedDivision: "", // 決算区分 選択中の値
    selectedUnit: "", // 単位 選択中の値
    selectedType: "", // 決算種別 選択中の値
    isCheckedLTM: false, // 決算区分 LTM チェック状態
    isCheckedExpect: false, // 決算区分 予想 チェック状態
    ltmEnable: false, // 決算区分 LTM 有効／無効
    expectEnable: false // 決算区分 予想 有効／無効
  },
  downloadParams: {
    bakExpectFlg: "",
    bakLtmFlg: "",
    bakMonetaryCode: "",
    bakAccKind: "",
    bakAccKbn: "",
    bakAccTo: "",
    bakAccFrom: "",
    bakMonetary: "",
    menuId: "",
    selectcontentId: "",
    selectcountryType: "1" // 固定
  },
  forCheck: {
    // チェックボックス判定用
    ltmEnableFlg: false,
    isZaimuSamary: false,
    middleItemLtmDataCnt: "",
    expectEnableFlg: ""
  },
  menuId: "", // 表示中のメニューID
  contentId: "", // 表示中のコンテンツID
  unit: "", // 単位
  transiKeyPdf: "",
  directaccessCode: {}
};

const isEnableLtm = ({
  ltmEnableFlg,
  isZaimuSamary,
  accKbn,
  middleItemLtmDataCnt,
  periodToList,
  accTo
}) => {
  const isLatest = periodToList.findIndex(data => data.value === accTo) === 0;
  if (ltmEnableFlg && accKbn == "1" && isLatest) {
    if (!isZaimuSamary) return true;
    if (isZaimuSamary && middleItemLtmDataCnt > 0) return true;
  }
  return false;
};

const formatGraphEdit = edit => {
  const json = JSON.parse(edit);
  json.chartSeriesList = json.chartSeriesList.map(series => ({
    ...series,
    data: `[${series.data}]`
  }));
  return JSON.stringify(json);
};

const isEnableExpect = ({ expectEnableFlg, accKbn }) => {
  if (accKbn == "5") return false;
  return expectEnableFlg;
};

const extractDownloadParams = (state, { payload }) => {
  const opt = payload.unitList.find(opt => opt.value === payload.monetaryCode);
  const bakMonetary = opt === undefined ? "" : opt.label;
  return {
    ...state.downloadParams,
    bakExpectFlg: payload.bakExpectFlg,
    bakLtmFlg: payload.bakLtmFlg,
    bakMonetaryCode: payload.bakMonetaryCode,
    bakAccKind: payload.bakAccKind,
    bakAccKbn: payload.bakAccKbn,
    bakAccTo: payload.bakAccTo,
    bakAccFrom: payload.bakAccFrom,
    bakMonetary: bakMonetary,
    menuId: payload.menuId,
    selectcontentId: payload.contentId
  };
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: (_, action) => ({
      ...initialState,
      menuId: action.meta.menuId,
      isLoading: true,
      corpChanged: false
    }),
    [`${ActionTypes.BUSINESSCODE_CHANGE}_PENDING`]: (_, action) => ({
      ...initialState,
      menuId: action.meta.menuId,
      contentId: action.meta.contentId,
      isLoading: true,
      corpChanged: false
    }),
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => {
      const ltmEnable = isEnableLtm(action.payload);
      const expectEnable = isEnableExpect(action.payload);
      return {
        ...state,
        table: action.payload.corpFin.treeTable,
        graph: action.payload.ToolCreategraphDto,
        graphDto: action.payload.corpFin.graphDto,
        graphEdit: action.payload.ToolCreategraphDto1.map(formatGraphEdit),
        graphModifyFlagList: action.payload.corpFin.graphDto.modifyFlag,
        tabList: valueFromKeyOrDefault(action.payload, "tabList", []),
        forCheck: {
          ltmEnableFlg: action.payload.ltmEnableFlg,
          isZaimuSamary: action.payload.isZaimuSamary,
          middleItemLtmDataCnt: action.payload.middleItemLtmDataCnt,
          expectEnableFlg: action.payload.expectEnableFlg
        },
        condition: {
          ...state.condition,
          periodFromList: action.payload.periodFromList,
          periodToList: action.payload.periodToList,
          divisionOptionList: action.payload.accKbnList,
          unitOptionList: action.payload.unitList,
          typeOptionList: action.payload.accKindList,
          selectedFrom: action.payload.accFrom,
          selectedTo: action.payload.accTo,
          selectedDivision: action.payload.accKbn,
          selectedUnit: action.payload.monetaryCode,
          selectedType: action.payload.accKind,
          ltmEnable: ltmEnable,
          expectEnable: expectEnable,
          isCheckedLTM: ltmEnable ? state.condition.isCheckedLTM : false,
          isCheckedExpect: expectEnable
            ? state.condition.isCheckedExpect
            : false
        },
        download: {
          dlpropriety: action.payload.dlpropriety,
          xls: {
            dl_file_name: action.payload.dlxls,
            dl_file_path: action.payload.dlxlspath
          },
          csv: {
            dl_file_name: action.payload.dlcsv,
            dl_file_path: action.payload.dlcsvpath
          }
        },
        menuId: action.payload.menuId,
        contentId: action.payload.contentId,
        unit: action.payload.corpFin.unitName,
        transiKeyPdf: action.payload.transiKeyPdf,
        downloadParams: extractDownloadParams(state, action),
        isLoading: false,
        directaccessCode: {
          corpIndustyKbn: action.payload.directaccess_corpIndustyKbn,
          corpIndustyCode: action.payload.directaccess_corpIndustyCode,
          corpType: action.payload.directaccess_corpType
        }
      };
    },
    [`${ActionTypes.BUSINESSCODE_CHANGE}_FULFILLED`]: (state, action) => {
      const ltmEnable = isEnableLtm(action.payload);
      const expectEnable = isEnableExpect(action.payload);
      return {
        ...state,
        table: action.payload.corpFin.treeTable,
        graph: action.payload.ToolCreategraphDto,
        graphDto: action.payload.corpFin.graphDto,
        graphEdit: action.payload.ToolCreategraphDto1.map(formatGraphEdit),
        graphModifyFlagList: action.payload.corpFin.graphDto.modifyFlag,
        tabList: valueFromKeyOrDefault(action.payload, "tabList", []),
        forCheck: {
          ltmEnableFlg: action.payload.ltmEnableFlg,
          isZaimuSamary: action.payload.isZaimuSamary,
          middleItemLtmDataCnt: action.payload.middleItemLtmDataCnt,
          expectEnableFlg: action.payload.expectEnableFlg
        },
        condition: {
          ...state.condition,
          periodFromList: action.payload.periodFromList,
          periodToList: action.payload.periodToList,
          divisionOptionList: action.payload.accKbnList,
          unitOptionList: action.payload.unitList,
          typeOptionList: action.payload.accKindList,
          selectedFrom: action.payload.accFrom,
          selectedTo: action.payload.accTo,
          selectedDivision: action.payload.accKbn,
          selectedUnit: action.payload.monetaryCode,
          selectedType: action.payload.accKind,
          ltmEnable: ltmEnable,
          expectEnable: expectEnable,
          isCheckedLTM: ltmEnable ? state.condition.isCheckedLTM : false,
          isCheckedExpect: expectEnable
            ? state.condition.isCheckedExpect
            : false
        },
        download: {
          dlpropriety: action.payload.dlpropriety,
          xls: {
            dl_file_name: action.payload.dlxls,
            dl_file_path: action.payload.dlxlspath
          },
          csv: {
            dl_file_name: action.payload.dlcsv,
            dl_file_path: action.payload.dlcsvpath
          }
        },
        menuId: action.payload.menuId,
        contentId: action.payload.contentId,
        unit: action.payload.corpFin.unitName,
        transiKeyPdf: action.payload.transiKeyPdf,
        downloadParams: extractDownloadParams(state, action),
        isLoading: false,
        corpChanged: true
      };
    },
    [`${ActionTypes.PAGE_GET}_PENDING`]: (state, action) => ({
      ...state,
      contentId: action.meta.contentId,
      isLoading: true
    }),
    [`${ActionTypes.PAGE_GET}_FULFILLED`]: (state, action) => {
      const ltmEnable = isEnableLtm(action.payload);
      const expectEnable = isEnableExpect(action.payload);
      return {
        ...state,
        table: action.payload.corpFin.treeTable,
        graph: action.payload.ToolCreategraphDto,
        graphDto: action.payload.corpFin.graphDto,
        graphEdit: action.payload.ToolCreategraphDto1.map(formatGraphEdit),
        graphModifyFlagList: action.payload.corpFin.graphDto.modifyFlag,
        tabList: valueFromKeyOrDefault(action.payload, "tabList", []),
        forCheck: {
          ltmEnableFlg: action.payload.ltmEnableFlg,
          isZaimuSamary: action.payload.isZaimuSamary,
          middleItemLtmDataCnt: action.payload.middleItemLtmDataCnt,
          expectEnableFlg: action.payload.expectEnableFlg
        },
        condition: {
          ...state.condition,
          periodFromList: action.payload.periodFromList,
          periodToList: action.payload.periodToList,
          divisionOptionList: action.payload.accKbnList,
          unitOptionList: action.payload.unitList,
          typeOptionList: action.payload.accKindList,
          selectedFrom: action.payload.accFrom,
          selectedTo: action.payload.accTo,
          selectedDivision: action.payload.accKbn,
          selectedUnit: action.payload.monetaryCode,
          selectedType: action.payload.accKind,
          ltmEnable: ltmEnable,
          expectEnable: expectEnable,
          isCheckedLTM: ltmEnable ? state.condition.isCheckedLTM : false,
          isCheckedExpect: expectEnable
            ? state.condition.isCheckedExpect
            : false
        },
        download: {
          dlpropriety: action.payload.dlpropriety,
          xls: {
            dl_file_name: action.payload.dlxls,
            dl_file_path: action.payload.dlxlspath
          },
          csv: {
            dl_file_name: action.payload.dlcsv,
            dl_file_path: action.payload.dlcsvpath
          }
        },
        menuId: action.payload.menuId,
        contentId: action.payload.contentId,
        unit: action.payload.corpFin.unitName,
        transiKeyPdf: action.payload.transiKeyPdf,
        downloadParams: extractDownloadParams(state, action),
        isLoading: false
      };
    },
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: state => ({
      ...state,
      isLoading: false
    }),
    [`${ActionTypes.BUSINESSCODE_CHANGE}_REJECTED`]: state => ({
      ...state,
      isLoading: false,
      corpChanged: false
    }),
    [`${ActionTypes.PAGE_GET}_REJECTED`]: state => ({
      ...state,
      isLoading: false
    }),
    [ActionTypes.IDS_CLEAR]: state => ({
      ...state,
      menuId: initialState.menuId,
      contentId: initialState.contentId
    }),
    [`${ActionTypes.FROM_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        selectedFrom: action.payload
      }
    }),
    [`${ActionTypes.TO_UPDATE}`]: (state, action) => {
      const ltmEnable = action.meta.ltmUpdateLater
        ? state.condition.ltmEnable
        : isEnableLtm({
            ...state.forCheck,
            accKbn: state.condition.selectedDivision,
            periodToList: state.condition.periodToList,
            accTo: action.payload
          });
      return {
        ...state,
        condition: {
          ...state.condition,
          selectedTo: action.payload,
          ltmEnable: ltmEnable,
          isCheckedLTM: ltmEnable ? state.condition.isCheckedLTM : false
        }
      };
    },
    [`${ActionTypes.DIVISION_UPDATE}`]: (state, action) => {
      const ltmEnable = action.meta.ltmUpdateLater
        ? state.condition.ltmEnable
        : isEnableLtm({
            ...state.forCheck,
            accKbn: action.payload,
            periodToList: state.condition.periodToList,
            accTo: state.condition.selectedTo
          });
      const expectEnable = isEnableExpect({
        ...state.forCheck,
        accKbn: action.payload
      });
      return {
        ...state,
        condition: {
          ...state.condition,
          selectedDivision: action.payload,
          ltmEnable: ltmEnable,
          expectEnable: expectEnable,
          isCheckedLTM: ltmEnable ? state.condition.isCheckedLTM : false,
          isCheckedExpect: expectEnable
            ? state.condition.isCheckedExpect
            : false
        }
      };
    },
    [`${ActionTypes.LTM_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        isCheckedLTM: action.payload
      }
    }),
    [`${ActionTypes.EXPECT_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        isCheckedExpect: action.payload
      }
    }),
    [`${ActionTypes.UNIT_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        selectedUnit: action.payload
      }
    }),
    [`${ActionTypes.TYPE_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        selectedType: action.payload
      }
    }),
    [`${ActionTypes.CONDITION_CHANGE}_FULFILLED`]: (state, action) => {
      const ltmEnable = isEnableLtm({
        ...state.forCheck,
        middleItemLtmDataCnt: action.payload.middleItemLtmDataCnt,
        accKbn: action.meta.accKbn,
        periodToList: action.payload.periodToList,
        accTo: action.payload.toDate
      });
      const expectEnable = isEnableExpect({
        ...state.forCheck,
        accKbn: action.meta.accKbn
      });
      return {
        ...state,
        condition: {
          ...state.condition,
          selectedDivision: action.meta.accKbn,
          periodFromList: action.payload.periodFromList,
          periodToList: action.payload.periodToList,
          selectedFrom: action.payload.fromDate,
          selectedTo: action.payload.toDate,
          ltmEnable: ltmEnable,
          expectEnable: expectEnable,
          isCheckedLTM: ltmEnable ? state.condition.isCheckedLTM : false,
          isCheckedExpect: expectEnable
            ? state.condition.isCheckedExpect
            : false
        },
        forCheck: {
          ...state.forCheck,
          middleItemLtmDataCnt: action.payload.middleItemLtmDataCnt
        }
      };
    },
    [`${ActionTypes.CORP_CHANGED_CLEAR}`]: state => ({
      ...state,
      corpChanged: false
    })
  },
  initialState
);

export const actionCreators = {
  // 初期表示(MainMenuタブ切り替え) /corp/fin
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => {
      const endpoint = 105;
      const requestParams = {
        ...params,
        // /corp/fin をリクエストする場合、基本の accessContentId をパラメータに付与する
        accessContentId: getCorpFinStaticId()
      };
      return request(endpoint, requestParams);
    },
    params => ({ menuId: params.menuId })
  ),
  // 企業切り替え /corp/fin/businesscode-change
  changeCorp: createAction(
    ActionTypes.BUSINESSCODE_CHANGE,
    params => {
      return request(108, {
        ...params,
        // /corp/fin/businesscode-change をリクエストする場合、基本の accessContentId をパラメータに付与する
        accessContentId: getCorpFinStaticId()
      });
    },
    params => ({ menuId: params.menuId, contentId: params.selectcontentId })
  ),
  // タブ切り替え /corp/fin/corp-fin
  getPage: createAction(
    ActionTypes.PAGE_GET,
    params => {
      const endpoint = 107;
      const requestParams = {
        ...params,
        // /corp/fin/corp-fin をリクエストする場合、基本の accessContentId をパラメータに付与する
        accessContentId: getCorpFinStaticId()
      };
      return request(endpoint, requestParams);
    },
    params => ({ contentId: params.selectcontentId })
  ),
  clearIds: createAction(ActionTypes.IDS_CLEAR),
  // 条件変更 /corp/fin/acc-type-change
  changeCondition: createAction(
    ActionTypes.CONDITION_CHANGE,
    params => request(111, params),
    params => ({ accKbn: params.accKbn })
  ),
  // 期間 開始年の変更
  updateFrom: createAction(ActionTypes.FROM_UPDATE),
  // 期間 終了年の変更
  updateTo: createAction(
    ActionTypes.TO_UPDATE,
    action => action,
    (_, ltmUpdateLater = false) => ({ ltmUpdateLater: ltmUpdateLater })
  ),
  // 決算区分 変更
  updateDivision: createAction(
    ActionTypes.DIVISION_UPDATE,
    action => action,
    (_, ltmUpdateLater = false) => ({ ltmUpdateLater: ltmUpdateLater })
  ),
  // LTM 変更
  updateLTM: createAction(ActionTypes.LTM_UPDATE),
  // 予想 変更
  updateExpect: createAction(ActionTypes.EXPECT_UPDATE),
  // 単位 変更
  updateUnit: createAction(ActionTypes.UNIT_UPDATE),
  // 決算種別 変更
  updateType: createAction(ActionTypes.TYPE_UPDATE),
  // 企業切り替え済フラグの解除
  clearCorpChanged: createAction(ActionTypes.CORP_CHANGED_CLEAR)
};
