import { loadPaths, PathContext, PathInfo, corpIndustryKbn } from "./data";
import { isEmpty, isArray } from "lodash";
import { getContentId } from "utils/helper/contentId";
import { pathToRegexp } from "path-to-regexp";

export { corpIndustryKbn };

// 企業タイプ（上、未、海区分フラグ）
export const corpType = {
  listed: "1", // 上場
  overseas: "3", // 海外
  unlisted: "0", // 非上場（日経）
  startup: "5", // 非上場（ケップル）
  unlistedOverseas: "6", // 海外非上場
  tdb: "4" // TDB
};

export const asrfs = {
  report: "1", // 有報
  unlisted: "0", // 非上場（日経）
  other: "" // 上場・海外・TDB
};

// カテゴリのキー名とカテゴリ表示名のひもづけ
export const categoryNames = {
  corporation: "企業情報",
  finance: "財務情報",
  ir: "ＩＲ情報",
  market: "マーケット関連",
  news: "記事・レポート",
  ma: "比較表・Ｍ＆Ａ",
  tool: "ツール",
  telecom: "日経テレコン",
  industry: "業界情報",
  event: "企業比較・Ｍ＆Ａ"
};

// カテゴリのキー名とcontentIdのひもづけ
export const menuContentIds: { [key: string]: string[] } = {
  basics: ["C2010", "CHD2010", "CH2010", "CK2010", "CHK2010", "CKU2010"], // 企業基本
  shares: ["C2030", "CK2030"], // 株主・保有株式
  meeting: ["C2040"], // 株主総会
  esg: ["C2050"], // ESG
  table: ["C3010", "CH3010", "CK3010"], // 財務諸表
  statement: ["C3020"], // 明細票・注記
  analysis: ["C3030"], // 財務分析表
  indicator: ["CK3020"], // 財務指標
  segment: ["C2020", "CK2020"], // セグメント情報
  loan: ["C2060"], // 金融機関別借入金
  forecast: ["C3040", "CK3040"], // 業績予想
  disclosure: ["C4010", "CK4010"], // 開示資料
  ir: ["C4020"], // IRデータ
  stock: ["C5010", "CK5010"], // 株価
  valuation: ["C5020", "CK5020"], // バリュエーション
  news: ["C6010", "CHD6010", "CH6010", "B6010", "CHK6010"], // ニュース・新聞
  report: ["C6020", "CHD6020", "CH6020", "B6020", "CHK6020"], // 雑誌・レポート
  comparison: ["C9010", "CHD9010", "CH9010", "CK9010"], // 同業企業比較
  event: ["C9020", "B9010"], // 企業イベント
  strategy: ["C9040", "B9020"], // M&A・企業活動、企業活動情報
  graph: ["C9030"], // グラフ集
  search: ["CT2010", "CHDT2010", "CHT2010", "CHKT2010"], // 企業情報検索
  press: ["CT2020", "CHDT2020", "CHT2020", "BT2010", "CHKT2020"], // 業界紙検索
  summary: ["B2010"], // 業界概要
  index: ["B2020"], // 業界指標
  ["ir-data"]: ["B2030"], // 業界統計・ＩＲデータ
  scale: ["B2040"], // 業界規模
  ["research-report"]: ["B6030"] // 産業調査レポート
};

// 企業検索
const companySearchPath = [
  "/tool/screening/corp/item",
  "/tool/screening/corp/scenario",
  "/tool/screening/segment/business",
  "/tool/screening/segment/area",
  "/tool/by-industry"
];

/**
 * 指定した企業・業界コードに一致する企業・業界のコンテキストを返す
 * @param {string} kbn 企業・業界区分コード
 * @param {string} diffflg 企業種別 corpType or diffflg を受け取る
 * @param {string} asrf
 * @return {string} 企業・業界のコンテキスト
 */
const _getCorpIndustryPathContext = (
  kbn = corpIndustryKbn.domestic,
  diffflg = "1",
  asrf = ""
): PathContext | undefined => {
  switch (kbn) {
    case corpIndustryKbn.domestic:
    case corpIndustryKbn.overseas:
      return _getCorpPathContext(kbn, diffflg, asrf);
    case corpIndustryKbn.industryMedium:
      return "medium";
    case corpIndustryKbn.industrySmall:
      return "small";
    default:
      throw new Error(`invalid kbn: ${kbn}`);
  }
};

/**
 * 指定した企業種別・区分に一致する企業のコンテキストを返す
 * @param {string} kbn 企業・業界区分コード
 * @param {string} diffflg 企業種別 corpType or diffflg を受け取る
 * @param {string} asrf
 * @return {string} 企業・業界のコンテキスト
 * 上場：listed、有報：report、非上場日経：unlisted、
 * 非上場TDB：tdb、海外：overseas、非上場ケップル：startup
 */
const _getCorpPathContext = (
  kbn = corpIndustryKbn.domestic,
  diffflg: string,
  asrf = ""
): PathContext | undefined => {
  if (kbn === corpIndustryKbn.overseas) {
    return diffflg === corpType.unlistedOverseas
      ? "unlistedOverseas"
      : "overseas";
  }

  if (kbn !== corpIndustryKbn.domestic) return;

  return _getCorpTypeName(diffflg, asrf);
};

/**
 * diffflgとasrfから、企業のタイプを文字列で返す。
 * @param {string} diffflg
 * @param {string} asrf
 * @return {string} 企業の種別文字列
 * 上場：listed、有報：report、非上場日経：unlisted、
 * 非上場TDB：tdb、海外：overseas、非上場ケップル：startup
 */
const _getCorpTypeName = (diffflg: string, asrf = "") => {
  switch (diffflg) {
    case corpType.listed:
      return "listed";
    case corpType.unlisted:
      return asrf === "1" ? "report" : "unlisted";
    case corpType.tdb:
      return "tdb";
    case corpType.overseas:
      return "overseas";
    case corpType.startup:
      return "startup";
    case corpType.unlistedOverseas:
      return "unlistedOverseas";
    default:
      return;
  }
};

const _getCorpPaths = (
  scope = "*",
  context = "listed" as PathContext
): PathInfo[] => {
  return _getPaths(`corp.${scope}`, context);
};

const _getIndustryPaths = (
  scope = "*",
  context?: PathContext | PathContext[]
): PathInfo[] => {
  if (context) {
    context = ["medium", "small"];
  }

  return _getPaths(`industry.${scope}`, context);
};

const _getPaths = (
  scope: string,
  context?: PathContext | PathContext[]
): PathInfo[] => {
  let paths = loadPaths(context);
  paths = _getRecursive(context)(paths, scope.split("."));

  if (context) {
    paths = _filterChildrenByContext(paths, context);
  }

  return paths.map(_removeContextKey);
};

// 子の階層にあるコンテキスト外の PathInfo を除去する
const _filterChildrenByContext = (
  paths: PathInfo[],
  context: PathContext | PathContext[]
) => {
  return paths.filter(function _recursive(path: PathInfo): boolean {
    const isInclude =
      path.context?.some((ctx: PathContext) =>
        isArray(context) ? context.includes(ctx) : context === ctx
      ) ?? true; // パスに context キーがない（制限がない）場合は常に true

    if (isInclude && !path.children) return true;

    return (path.children = path.children?.filter(_recursive) ?? []).length > 0;
  });
};

const _getRecursive = (context?: PathContext | PathContext[]) =>
  function callRecursive(paths: PathInfo[], scopeKeys = ["*"]): PathInfo[] {
    const [key, ...rest] = scopeKeys;

    const next = (p: PathInfo) => {
      if (context && p.context) {
        context = isArray(context) ? context : [context];

        const isInclude = context.every((c: PathContext) =>
          p.context?.includes(c)
        );

        if (!isInclude) {
          return [];
        }
      }

      return key === "**" && p.children
        ? callRecursive(p.children, ["**"])
        : rest.length > 0 && p.children
        ? callRecursive(p.children, rest)
        : [p];
    };

    if (key === "*" || key === "**") {
      return paths.flatMap(next);
    } else if (key.indexOf("|") > 0) {
      const multiKeys = key.split("|");
      return paths
        .filter(p => (p?.key ? multiKeys.includes(p.key) : true))
        .flatMap(next);
    } else {
      const p = paths.find(p => p.key === key);
      return p ? next(p) : [];
    }
  };

const _removeContextKey = ({ context: _, ...path }: PathInfo) => {
  if (path.children && path.children.length > 0) {
    path.children = path.children.map(_removeContextKey);
  }

  return path;
};

const _findCorpIndustryPath = (
  path: string,
  scope = "*",
  options?: {
    query?: { [k: string]: string };
    context?: PathContext | PathContext[];
    needParent?: boolean;
  }
): PathInfo => {
  const context = options?.context
    ? options.context
    : ([
        "listed",
        "unlisted",
        "startup",
        "tdb",
        "report",
        "medium",
        "small"
      ] as PathContext[]);

  const result = _findPaths(path, `corp|industry.${scope}`, {
    ...options,
    context
  });

  return result[0];
};

const _findPaths = (
  path: string,
  scope: string,
  options?: {
    query?: { [k: string]: string };
    context?: PathContext | PathContext[];
    needParent?: boolean;
    regexp?: boolean;
  }
): PathInfo[] => {
  let paths = _findRecursive(
    path,
    options?.context,
    options?.needParent,
    options?.regexp
  )(loadPaths(options?.context), scope.split("."));

  paths = paths.map(_removeContextKey);

  const query = options?.query;
  if (query !== undefined && !isEmpty(query)) {
    paths = paths.filter((p: PathInfo) =>
      Object.keys(p.query).every((k: string) => {
        if (k === "menuId") {
          // 遷移元と遷移先のページの種類が同じかを判定
          return (
            p.key &&
            menuContentIds[p.key].some(item => item === p.query.menuId) &&
            menuContentIds[p.key].some(item => item === query.menuId)
          );
        }
        return p.query[k] === query[k];
      })
    );
  }

  return paths;
};

const _findRecursive = (
  path: string,
  context?: PathContext | PathContext[],
  needParent = true,
  regexp = false
) =>
  function callRecursive(
    paths: PathInfo[],
    scopeKeys = ["*"],
    parent?: PathInfo
  ): PathInfo[] {
    const [key, ...rest] = scopeKeys;

    const next = (p: PathInfo): PathInfo[] => {
      if (p.scopeRef) {
        const paths = _getPaths(p.scopeRef, context);
        return needParent
          ? callRecursive(paths, ["**"], parent ? { ...p, parent } : p)
          : callRecursive(paths, ["**"]);
      }

      if (rest.length > 0 && p.children) {
        return needParent
          ? callRecursive(p.children, rest, parent ? { ...p, parent } : p)
          : callRecursive(p.children, rest);
      }

      if (key === "**" && p.children) {
        return needParent
          ? callRecursive(p.children, ["**"], parent ? { ...p, parent } : p)
          : callRecursive(p.children, ["**"]);
      }

      if (
        p.path === path ||
        (regexp && pathToRegexp(p.path ?? "").test(path))
      ) {
        const result = needParent ? [{ ...p, parent }] : [p];

        // パスが一致するかつ、 p.context キーが存在しない場合はチェック不要
        if (isEmpty(p.context)) {
          return result;
        }

        // パスが一致するかつ、 引数の context が p.context キーに含まれていること
        if (
          isArray(context) &&
          context.some((c: PathContext) => p.context?.includes(c))
        ) {
          return result;
        }

        if (!isArray(context) && context && p.context?.includes(context)) {
          return result;
        }
      }

      return [];
    };

    if (key === "*" || key === "**") {
      return paths.flatMap(next);
    } else if (key.indexOf("|") > 0) {
      const multiKeys = key.split("|");
      return paths
        .filter(p => (p?.key ? multiKeys.includes(p.key) : true))
        .flatMap(next);
    } else {
      const p = paths.find(p => p.key === key);
      return p ? next(p) : [];
    }
  };

/**
 * 企業業界の区分の情報をかえす
 * @return {object[]} メインメニュー表示用データ
 */
export const loadCorpIndustyKbn = () => corpIndustryKbn;

/**
 * 企業・業界以下ページの親のkeyと子のkeyから、子が持つページをかえす
 * @param {string} parentKey  親のkey
 * @param {string} childKey  子のkey
 * @param {string} kbn 企業・業界区分
 * @return {PathInfo} URLパス情報
 */
export const loadCorporationChildPage = (
  parentKey: string,
  childKey: string,
  kbn = corpIndustryKbn.domestic
): PathInfo => {
  const defaultValue = { children: [] } as PathInfo;
  const context = _getCorpPathContext(kbn, "1", "");

  if (!context) return defaultValue;

  const paths = _getCorpPaths([parentKey, childKey].join("."), context);

  return paths[0] ?? defaultValue;
};

/**
 * 企業・業界以下ページの親のkeyと子のkeyから、子が持つページの名前とURLパスをかえす
 * @param {string} parentKey  親のkey
 * @param {string} childKey  子のkey
 * @param {?string} kbn 企業・業界区分
 * @param {?string} type 企業タイプ（上、未、海区分フラグ）
 * @param {?string} diffflg
 * @param {?string} asrf
 *@return {PathInfo[]} URLパス情報
 */
export const loadCorporationChildPagePaths = (
  parentKey: string,
  childKey: string,
  kbn = corpIndustryKbn.domestic,
  type?: string | null,
  diffflg = "1",
  asrf = ""
): PathInfo[] => {
  const context = _getCorpPathContext(kbn, type || diffflg, asrf);

  if (!context) return [];

  return _getCorpPaths([parentKey, childKey, "*"].join("."), context) || [];
};

/**
 * 企業業界のURLパスと企業・業界区分コードを渡すと、アクティブなメニューの親と子のkeyのオブジェクトを返す
 * @param {string} path URLパス
 * @param {string} kbn 企業・業界区分コード
 * @param {?string} diffflg
 * @param {?string} asrf
 * @return {object[]} アクティブなメニューの親と子のkeyのオブジェクト
 */
export const loadActiveMenu = (
  path: string,
  kbn: string,
  diffflg = "1",
  asrf = ""
) => {
  // 海外 個別対応
  if (kbn === corpIndustryKbn.overseas && diffflg === corpType.overseas) {
    if (
      path === "/corp/shares/shareholder" || // 株主情報
      path === "/corp/shares/holdings" || // 保有株式情報
      path === "/corp/meeting" // 株主総会
    ) {
      return { activeParent: "corporation", activeChild: "shares" };
    } else if (
      path === "/corp/segment/area" // セグメント情報 地域
    ) {
      return { activeParent: "finance", activeChild: "segment" };
    } else if (
      path === "/corp/valuation/dcf" || // DCF
      path === "/corp/valuation/beta" || // ベータ値
      path === "/corp/valuation/value" || // 企業価値
      path === "/corp/valuation/investment-index" // 投資指標
    ) {
      return { activeParent: "market", activeChild: "valuation" };
    }
  }

  const context = _getCorpIndustryPathContext(kbn, diffflg, asrf);
  if (!context)
    return {
      activeParent: null,
      activeChild: null
    };

  const grandChild = _findCorpIndustryPath(path, "*.*.*", { context });

  let child;
  if (!grandChild && path.indexOf("/corp/finance") !== -1) {
    // 財務諸表系パス追加情報
    const query = getParamsFromUrl();

    child = _findCorpIndustryPath(path, "finance.*", { query, context });
  } else {
    child = grandChild?.parent;
  }

  return {
    activeParent: child?.parent?.key ?? null,
    activeChild: child?.key ?? null
  };
};

/**
 * 企業・業界区分コードを渡すと、海外企業コードかどうかの判定を返す
 * @param {string} kbn 企業・業界区分コード
 * @return {boolean}} 判定結果
 */
export const isOverseasCorporation = (kbn: string) => {
  return kbn === corpIndustryKbn.overseas;
};

/**
 * 企業・業界区分コードを渡すと、業界中分類コードかどうかの判定を返す
 * @param {string} kbn 企業・業界区分コード
 * @return {boolean}} 判定結果
 */
export const isIndustryMedium = (kbn: string) => {
  return kbn === corpIndustryKbn.industryMedium;
};

/**
 * 企業・業界区分コードを渡すと、業界小分類コードかどうかの判定を返す
 * @param {string} kbn 企業・業界区分コード
 * @return {boolean}} 判定結果
 */
export const isIndustrySmall = (kbn: string) => {
  return kbn === corpIndustryKbn.industrySmall;
};

/**
 * 企業ページのURLかどうか
 * @param {string} path URL
 * @returns {boolean}
 */
export const isCorporationPath = (path: string) => {
  return path.startsWith("/corp");
};

/**
 * 業界ページのURLかどうか
 * @param {string} path URL
 * @returns {boolean}
 */
export const isIndustryPath = (path: string) => {
  //業界検索のパスのみ/industry-searchのため
  if (path === "/industry-search") return false;
  return path.startsWith("/industry");
};

/**
 * ツールのkeyから、子が持つページの名前とURLパスをかえす
 * @param {string} key  子のkey
 * @return {PathInfo[]} 子が持つページの名前とURLパスのオブジェクトの配列
 */
export const loadToolPagePaths = (key: string) => {
  const paths = _getPaths(`tool.${key}.*`);
  return paths.length > 0 ? paths : [{ name: "", path: "" }];
};

export const loadIndustrySearchPagePaths = () => {
  const paths = _getPaths("industry-search.*");
  return paths.length > 0 ? paths : [{ name: "", path: "", key: "" }];
};

/**
 * グローバルナビゲーションのメニューの親子関係とURLパスのデータをかえす
 * @return {PathInfo[]} グローバルナビゲーション表示用データ
 */
export const loadGlobalNavigationPaths = () => {
  const paths = _getPaths("globalNavigation.*");
  // FIX: データ構造を旧版のデータ構造に変換（暫定対応）
  return paths.reduce((acc: any, p: PathInfo) => {
    if (p.key) {
      acc[p.key] = p.children;
      return acc;
    }
    return acc;
  }, {});
};

// findAnalyticsWithPathのカテゴリのindex
export const categories = {
  top: 0,
  parent: 1,
  children: 2
};

type AnalyticPage = {
  pageName: string;
  categories: string[];
};

/**
 * パスから Analytics 情報を取得
 * @param {string} pathname - URLパス
 * @param {string} menuId - 財務系ページのID
 */
export const findAnalyticsWithPath = (
  pathname: string,
  menuId = ""
): AnalyticPage => {
  const noValue: AnalyticPage = { pageName: "", categories: [""] };

  // ホーム画面
  if (pathname === "/") {
    return { pageName: "ホーム", categories: ["ホーム"] };
  }

  if (pathname.startsWith("/searchlist")) {
    return { pageName: "一括検索", categories: ["一括検索"] };
  }

  try {
    if (pathname === "/corp/finance") {
      const financePath = _findCorpIndustryPath(pathname, "finance.*", {
        query: { menuId }
      });

      return financePath
        ? ({
            pageName: "",
            categories: [
              financePath.parent?.parent?.name,
              financePath.parent?.name,
              financePath.name
            ]
          } as AnalyticPage)
        : noValue;
    }

    const corpIndustryPath = _findCorpIndustryPath(pathname, "*.*.*");

    if (corpIndustryPath) {
      return {
        pageName: corpIndustryPath.name,
        categories: [
          corpIndustryPath.parent?.parent?.parent?.name,
          corpIndustryPath.parent?.parent?.name,
          corpIndustryPath.parent?.name
        ]
      } as AnalyticPage;
    }

    if (companySearchPath.includes(pathname)) {
      const companySearchPath = _findPaths(
        pathname,
        "globalNavigation|company-search.*"
      )[0];

      if (companySearchPath) {
        return {
          pageName: companySearchPath.name,
          categories: [companySearchPath.parent?.name]
        } as AnalyticPage;
      }
    }

    if (pathname.startsWith("/tool")) {
      const toolPath = _findPaths(
        pathname,
        "globalNavigation.corporationIndustries|tools.*.*"
      )[0];

      if (toolPath) {
        return {
          pageName: toolPath.name,
          categories: [
            toolPath.parent?.parent?.parent?.name,
            toolPath.parent?.parent?.name,
            toolPath.parent?.name
          ]
        } as AnalyticPage;
      }
    }

    if (pathname.startsWith("/industry-search")) {
      const toolPath = _findPaths(pathname, "industry-search.*.*")[0];

      if (toolPath) {
        return {
          pageName: toolPath.name,
          categories: [toolPath.parent?.name]
        } as AnalyticPage;
      }
    }

    const globalNaviPath = _findPaths(pathname, "globalNavigation.*.*.*")[0];

    if (globalNaviPath) {
      const categories = [globalNaviPath.parent?.parent?.name];

      if (globalNaviPath.parent?.name) {
        categories.push(globalNaviPath.parent?.name);
      }

      return { pageName: globalNaviPath.name, categories } as AnalyticPage;
    }

    // フッターのみターゲットデータの階層が一段深いため、スコープを ** に設定
    const otherPath = _findPaths(pathname, "setting|header|footer|home.**")[0];
    if (otherPath) {
      return {
        pageName: otherPath.name,
        categories: [otherPath.parent?.name, otherPath.name]
      } as AnalyticPage;
    }

    // コレクション系はURLパスデータを正規表現で保持しているため regexp: true にする
    const binderPath = _findPaths(pathname, "binder.*.*", { regexp: true })[0];
    if (binderPath) {
      return {
        pageName: binderPath.name,
        categories: ["ヘッダー", binderPath.parent?.name, binderPath.name]
      } as AnalyticPage;
    }

    return noValue;
  } catch (err) {
    // IE対策 エラーが発生しても何もしない
    return noValue;
  }
};

/**
 * Popup ページの Analytics 情報取得
 * @param {string} search GETパラメーター
 */
export const findAnalyticsWithPopup = (search: string) => {
  if (search.indexOf("/home/trend-report") !== -1) {
    const searchParams = new URLSearchParams(search);
    const pageName = "日経 Bizトレンド";
    return {
      pageName,
      contentId: searchParams.get("kijiId"),
      categories: ["ポップアップ", pageName]
    };
  }

  return {
    pageName: "",
    categories: ["ポップアップ"]
  };
};

/**
 * URLパスから、そのセグメント情報のページのパスと名前とkeyを返す
 * @param {string} path URLパス
 * @return {PathInfo} ページ情報
 */
export const findActiveSegmentPage = (path: string) => {
  const activePage = _findCorpIndustryPath(path, "finance.segment.*", {
    needParent: false
  });

  return activePage ? activePage : null;
};

/**
 * 設定の名称とURLパスのデータをかえす
 * @return {PathInfo[]} 設定のリンク情報
 */
export const loadSettingPaths = () => {
  return _getPaths("setting.*");
};

/**
 * ヘルプの名称とURLパスのデータをかえす
 * @return {PathInfo[]} 設定のリンク情報
 */
export const loadHelpPaths = () => {
  return _getPaths("help.*");
};

/**
 * フッターの名称とURLパスのデータをかえす
 * @return {PathInfo[]} フッターのリンク情報
 */
export const loadFooterPaths = () => {
  const paths = _getPaths("footer.*");

  // FIX: データ構造を旧版のデータ構造に変換（暫定対応）
  return paths.reduce((acc: any, p: PathInfo) => {
    if (p.key) {
      acc[p.key] = p.children;
      return acc;
    }
    return acc;
  }, {});
};

/**
 * ログイン不要ページのURLであるか判定する
 * @param {string} path 判定URLパス
 * @return boolean
 */
export const isLoginUnnecessaryPaths = (_path: string) => {
  return false;
};

/**
 * URLからクエリのオブジェクトを作成して返す
 * @returns {object} クエリオブジェクト
 */
export const getParamsFromUrl = () => parseQuery(location.href);

/**
 * URLもしくはクエリ文字列からオブジェクトを生成
 * @param {string|false} url URLもしくはクエリ文字列
 * @returns {object}
 */
export const parseQuery = (url: string | boolean) => {
  if (typeof url !== "string") return false;
  const parsedUrl = url.split("?");
  let query = parsedUrl.length > 1 ? parsedUrl[1] : parsedUrl[0];
  // URLのみの考慮
  if (/:\/\//.test(query)) return {};
  query = query.split("#")[0];
  const searchParams = new URLSearchParams(query);
  const params = searchParams.entries();
  const target: any = {};
  for (const param of params) {
    target[param[0]] = param[1];
  }
  return target;
};

/**
 * オブジェクトからGETクエリ文字列作成
 * @param {object} query クエリオブジェクト
 * @param {?boolean} encode URIエンコードするか
 * @returns {string} クエリ文字列
 */
export const stringifyQuery = (query: any, encode = false) => {
  if (typeof query !== "object") return "";
  return Object.keys(query)
    .map(key => {
      const value = encode ? encodeURIComponent(query[key]) : query[key];
      return `${key}=${value}`;
    })
    .join("&");
};

/**
 * 受け取ったデータから、現在のページの企業/業界のURL情報を作成して返す
 * @param {string} url URLもしくはクエリ文字列
 * @param {boolean} isIndustry 業界かどうか
 * @param {?string} pathname 対象とするパス
 * @returns {object}
 */
export const getCorpUrl = (url: string, isIndustry: boolean, pathname = "") => {
  const basePathname = !isEmpty(pathname) ? pathname : location.pathname;
  const defaultPath = isIndustry ? "/industry/summary" : "/corp/summary";
  const path =
    (isIndustry && isIndustryPath(basePathname)) ||
    (!isIndustry && isCorporationPath(basePathname))
      ? basePathname
      : defaultPath;

  const currentQuery = parseQuery(location.search);
  const nextQuery = parseQuery(url);
  let contentId = getContentId(
    path,
    nextQuery.corpType,
    nextQuery.asrf,
    nextQuery.corpIndustyCode
  );

  if (path === "/corp/finance" && currentQuery.hasOwnProperty("contentId")) {
    contentId = currentQuery.contentId;
  }

  // 現状の corpIndustryPath は落とす
  if (isIndustry && currentQuery.hasOwnProperty("corpIndustryPath")) {
    delete currentQuery["corpIndustryPath"];
  }
  const query = { ...currentQuery, ...nextQuery, contentId };

  if (!isIndustry && query.corpPlaceMarket == "null") {
    query.corpPlaceMarket = currentQuery.corpPlaceMarket;
  }

  const whitelist = isIndustry
    ? ["corpIndustyKbn", "corpIndustyCode", "corpIndustryPath"]
    : [
        "corpIndustyKbn",
        "corpIndustyCode",
        "corpType",
        "asrf",
        "corpStkCode",
        "corpPlaceMarket",
        "menuId",
        "contentId"
      ];
  Object.keys(query).forEach(key => {
    // 特定のキーのみ通す
    if (whitelist.find(item => key === item) === undefined) {
      delete query[key];
      return;
    }

    if (query[key] === undefined || query[key] === null) return;

    // 空白除去
    query[key] = query[key].trim();
  });

  // 財務系 menuId を企業分類によって変換
  if (query.hasOwnProperty("menuId")) {
    query.menuId = createMenuId(query.corpType, query.menuId, query.asrf);
  }
  // 財務系 ページ内のタブ切り替え時の contentId がある場合、海外であれば削除する
  // 国内 -> 海外 -> 国内 とした場合、元開いていたタブの contentId が残ってしまうため
  if (
    !isIndustry &&
    (isOverseasType(query.corpType) ||
      currentQuery.corpIndustyKbn === corpIndustryKbn.overseas) &&
    query.hasOwnProperty("contentId")
  ) {
    delete query.contentId;
  }

  const queryString = stringifyQuery(query);

  return {
    pathname: path,
    search: queryString
  };
};

/**
 * 受け取ったコードから企業サマリのURL情報を作成して返す
 * @param {string} corpIndustryCode 企業コード
 * @param {string} corpStkCode 株式コード
 * @param {string} type 企業種別
 * @param {string} asrf 有報/非上場
 * @param {?string} corpIndustryKbn 企業区分
 * @param {?boolean} takeOver クエリを引き継ぐか
 */
export const getCorpSummaryUrl = (
  corpIndustyCode: string,
  corpStkCode: string,
  type: string,
  asrf: string,
  kbn = corpIndustryKbn.domestic,
  takeOver = true
) => {
  if (isEmpty(kbn)) {
    kbn =
      type === corpType.overseas
        ? corpIndustryKbn.overseas
        : corpIndustryKbn.domestic;
  }

  const context = _getCorpPathContext(kbn, type, asrf);
  const paths: PathInfo[] = _getCorpPaths("corporation.basics.*", context);

  const pathname = paths[0]?.path;

  const stk = corpStkCode.match(/\d{4}/) ? corpStkCode : "";

  const params = takeOver ? getParamsFromUrl() : {};

  const search = new URLSearchParams({
    ...params,
    corpIndustyCode: corpIndustyCode,
    corpStkCode: stk,
    corpType: type,
    asrf: asrf,
    corpIndustyKbn: kbn
  }).toString();

  return {
    pathname: pathname,
    search: search
  };
};

/**
 * 業界サマリへのリンク生成
 * @param {string} corpIndustyCode 業界コード
 * @param {string} corpIndustyKbn 業界区分
 */
export const getIndustrySummaryUrl = (
  corpIndustyCode: string,
  corpIndustyKbn: string
) => {
  const paths: PathInfo[] = _getIndustryPaths("industry.summary", "medium");
  const pathname = paths[0]?.path;
  const search = new URLSearchParams({
    corpIndustyCode,
    corpIndustyKbn
  }).toString();

  return { pathname, search };
};

/**
 * diffflgとasrfから、企業のタイプを文字列で返す。
 * @param {string} diffflg
 * @param {string} asrf
 * @return {string} 企業の種別文字列
 * 上場：listed、有報：report、非上場日経：unlisted、
 * 非上場TDB：tdb、海外：overseas、非上場ケップル：startup
 */
export const loadCorporationType = (diffflg: string, asrf = "") => {
  return _getCorpTypeName(diffflg, asrf);
};

/**
 * diffflg, asrfから、全カテゴリの画面が存在する企業区分であればtrueを返す
 * @param {string} diffflg
 * @param {string} asrf
 */
export const isExistAllCategory = (diffflg: string, asrf: string) => {
  const type = _getCorpTypeName(diffflg, asrf);
  return type === "listed" || type === "report";
};

/**
 * diffflg, asrfから、企業・業界区分と企業タイプ（上、未、海区分フラグ）を返す
 * @param {string} diffflg
 * @param {string} asrf
 */
const _convertCorporationType = (diffflg: string, asrf: string) => {
  switch (loadCorporationType(diffflg, asrf)) {
    case "listed":
    case "report":
      return {
        corpIndustryKbn: corpIndustryKbn.domestic,
        corpType: corpType.listed,
        asrf: asrf
      };
    case "unlisted":
      return {
        corpIndustryKbn: corpIndustryKbn.domestic,
        corpType: corpType.unlisted,
        asrf: asrf
      };
    case "tdb":
      return {
        corpIndustryKbn: corpIndustryKbn.domestic,
        corpType: corpType.tdb,
        asrf: asrf
      };
    case "overseas":
      return {
        corpIndustryKbn: corpIndustryKbn.overseas,
        corpType: corpType.overseas,
        asrf: asrf
      };
    case "startup":
      return {
        corpIndustryKbn: corpIndustryKbn.domestic,
        corpType: corpType.startup,
        asrf: asrf
      };
    case "unlistedOverseas":
      return {
        corpIndustryKbn: corpIndustryKbn.overseas,
        corpType: corpType.unlistedOverseas,
        asrf: asrf
      };
  }
};

/**
 * 遷移先のパスを生成して返す
 * @param {string} type 企業タイプ（上、未、海区分フラグ）
 * @param {object} targetCategory 遷移先パスのページが含まれるメニューの親と子のkeyのオブジェクト
 * @param {object[]} exitedPages 遷移先企業のタイプで存在する遷移先メニュー以下のページ情報
 * @param {string} originalPath 遷移先パス
 */
export const _createTargetPath = (
  type: string,
  targetCategory: any,
  exitedPages: PathInfo[],
  originalPath: string,
  asrf: string
) => {
  // targetが海外の企業基本/企業概要の場合は、企業基本/サプライチェーンのパスを返す
  if (type === corpType.overseas && originalPath === "/corp/overview")
    return { path: "/corp/supplychain", menuId: null };

  // 遷移先パスと同カテゴリのページが存在しない場合
  if (isEmpty(exitedPages)) {
    // REMOVE: 成立しないため削除する
    if (
      type === corpType.unlisted &&
      (targetCategory.activeChild === "table" ||
        targetCategory.activeChild === "statement" ||
        targetCategory.activeChild === "analysis")
    ) {
      return { path: "/corp/finance", menuId: "C3010" };
    }

    return { path: "/corp/summary", menuId: null };
  }

  // 遷移先パスと同カテゴリのページが存在する場合
  const page = exitedPages.find(page => page.path === originalPath);
  // 遷移先パスと同じページが存在する場合、リクエストされたパスをそのまま返す
  if (page !== undefined) return { path: originalPath, menuId: null };

  // 同じページが存在しない場合
  if (originalPath === "/corp/finance") {
    // targetが財務系の場合
    const query = getParamsFromUrl();
    const originalMenuId = query.hasOwnProperty("menuId")
      ? query.menuId
      : "C3010";
    return {
      path: originalPath,
      menuId: createMenuId(type, originalMenuId, asrf)
    };
  }
  // 同カテゴリの最初のページのパスを返す
  return { path: exitedPages[0].path, menuId: null };
};

/**
 * ヘッダーやパンクズから企業を選択して遷移時用の遷移先のパスを返す
 * @param {string} path 遷移先パス
 * @param {string} diffflg
 * @param {string} asrf
 */
export const loadTargetPath = (path: string, diffflg: string, asrf: string) => {
  const converted = _convertCorporationType(diffflg, asrf);
  // 消滅会社のときはそのまま返す
  if (converted === undefined) {
    return { path: path, menuId: null };
  }

  const category = loadActiveMenu(
    path,
    converted.corpIndustryKbn,
    converted.corpType,
    converted.asrf
  );

  let pages = [] as PathInfo[];
  if (category.activeParent && category.activeChild) {
    pages = loadCorporationChildPagePaths(
      category.activeParent,
      category.activeChild,
      converted.corpIndustryKbn,
      converted.corpType
    );
  }

  return _createTargetPath(
    converted.corpType,
    category,
    pages,
    path,
    converted.asrf
  );
};

/**
 * 財務系間の遷移用
 * 遷移元のMainIdと遷移先企業のタイプから、遷移先のMainIdを返す
 * @param {string} type 移先企業のタイプ
 * @param {string} originalMainId 遷移元のMainId
 */
export const createMenuId = (
  type: string,
  originalMainId: string,
  asrf: string
) => {
  if (type === corpType.unlisted && asrf === asrfs.unlisted) {
    // 非上場
    return "CH3010";
  }
  if (type === corpType.overseas) {
    // 海外
    // menuIdが "C3030", "CK3020" なら財務指標のmenuIdを、
    // menuIdがその他（"C3010", "C3020", "CH3010", "CK3010"）なら財務諸表のmenuIdを返す
    const analysisMenuIds = ["C3030", "CK3020"];
    return analysisMenuIds.some(id => id === originalMainId)
      ? "CK3020"
      : "CK3010";
  }
  // 上場
  if (originalMainId === "CK3020") return "C3030"; // 財務分析表
  if (originalMainId === "CH3010" || originalMainId === "CK3010")
    return "C3010"; // 財務諸表
  return originalMainId;
};

/**
 * corpType or diffflg を受け取り、海外ならtrueを、それ以外はfalseを返す
 * @param {string} corpType corpType or diffflg
 */
export const isOverseasType = (corpType: string) => {
  return corpType === "3";
};

/**
 * 非上場企業かを判定する
 * @param {object} corpParams 企業情報
 * @param {object} corpInfo 企業情報
 * @return {boolean} true: 非上場, false: それ以外
 */
export const isUnlisted = (
  corpParams: { [k: string]: string },
  corpInfo: { [k: string]: string }
) =>
  corpParams?.corpType === corpType.unlisted &&
  corpInfo?.asrf === asrfs.unlisted;

/**
 * ダイレクトアクセス先のパスから画面名を取得する
 * @param {string} redirectPath ダイレクトアクセス先のパス
 * @return {string} 画面名
 */
export const getDirectAccessTitle = (path: string) => {
  let title = "";

  if (path) {
    const redirectTo = _findPaths(path.split("?")[0], "**", {
      context: "directAccess"
    })[0];

    if (redirectTo) {
      title = redirectTo.name ?? "";
    }
  }
  return title;
};

/**
 * ダイレクトアクセス可能なパスか判定する
 * @param {string} redirectPath ダイレクトアクセス先のパス
 * @return {boolean}
 */
export const isDirectAccessablePath = (path: string) => {
  if (!path) return false;

  const result = _findPaths(path.split("?")[0], "**", {
    context: "directAccess"
  })[0];

  return result ? true : false;
};

const _validationContext = {
  globalNavigationCorpIndustries: () =>
    _getPaths("globalNavigation.corporationIndustries.*.*"),
  globalNavigationArticle: () => _getPaths("globalNavigation.articles.*.*"),
  globalNavigationStatistics: () =>
    _getPaths("globalNavigation.statistics.*.*"),
  globalNavigationTools: () => _getPaths("globalNavigation.tools.*.*"),
  corporationMenu: () => _getCorpPaths("*.*"),
  corporationPage: () => _getCorpPaths("*.*.*"),
  industry: () => _getIndustryPaths("*.*"),
  setting: () => _getPaths("setting.*"),
  home: () => _getPaths("home.*"),
  toolPage: () => _getPaths("tool.*.*"),
  searchlist: () => _getPaths("searchlist.*"),
  popup: () => _getPaths("popup.*"),
  binder: () => _getPaths("binder.*"),
  industrySearch: () => _getPaths("industry-search.*")
};

type ValidationContext = typeof _validationContext;
type ValidationContextName = keyof ValidationContext;

/**
 * @param {ValidationContextName} context 検証コンテキスト
 * @return {function(*=): *} URLパス検証関数
 */
export const validatePath = (context: ValidationContextName) =>
  _validatePath(_validationContext[context]());

/**
 * @param {PathInfo[]} paths
 * @param {string} path URLパス
 * @return {string} 有効なURLパス
 */
const _validatePath = (paths: PathInfo[]) => (path: string) => {
  const actualPath = _interpolationTable[path] || path;

  const hit = paths.some((p: PathInfo) => p.path == actualPath);

  if (!hit) throw new Error(`${path} is not found.`);

  return path;
};

// 前方一致のパスでも検証可能にするためにパスを補間する
const _interpolationTable: { [k: string]: string } = {
  "/corp/comparison": "/corp/comparison/finance",
  "/corp/valuation": "/corp/valuation/dcf",
  "/corp/segment": "/corp/segment/business",
  "/corp/esg": "/corp/esg/environment",
  "/corp/shares": "/corp/shares/shareholder",
  "/corp": "/corp/summary",
  "/industry": "/industry/summary",
  "/industry/telecom": "/industry/telecom/trade-press",
  "/searchlist": "/searchlist/corp",
  "/tool/screening/segment": "/tool/screening/segment/business",
  "/tool/screening/corp": "/tool/screening/corp/scenario",
  "/binder": "/binder/(personal|public)",
  "/industry-search": "/industry-search"
};
