import { isEmpty } from "utils/helper/common";

export default class PDFJs {
  init(source: string, element: HTMLDivElement, highlight?: string[]) {
    const iframe = document.createElement("iframe");

    // 完全一致のフレーズが存在する場合は phrase = true で、一番最初の完全一致のフレーズをハイライト
    // https://nikkeilabs.backlog.jp/view/B2B_NVS_FR_2018-3994
    const perfectMatchingStr = highlight?.find(item => /^\".*\"$/.test(item));

    iframe.src = `/public/pdfjs/web/viewer.html?file=${encodeURIComponent(
      source
    )}${
      highlight?.length
        ? `#search=${encodeURIComponent(
            perfectMatchingStr ? perfectMatchingStr : highlight.join(" ")
          )}&phrase=${!isEmpty(perfectMatchingStr)}`
        : ""
    }`;
    iframe.width = "100%";
    iframe.style.border = "none";

    element.appendChild(iframe);
  }
}
