import { Reducer } from "redux";
import { CommodityAction } from "./actions";
import produce from "immer";
import { ExtStringType, ExtType } from "models/Macro";
import {
  buildExtList,
  getEdgeDate,
  buildDateList,
  getDayjsUnit,
  getDefaultFromDate
} from "./helper";
import dayjs from "dayjs";

export interface Condition {
  from: string;
  to: string;
  extValue: ExtStringType | "";
  dataType: string;
}

export interface Loading {
  list: boolean;
}

interface CommodityState {
  data: any;
  keyword: {
    list: any[];
    count: number | null;
  };
  seriesInfoList: any;
  newsData: any;
  binderList: any;
  selectedItems: any;
  binderItems: any[];
  updateSearchResult: any;
  conditions: {
    value: Condition;
    list: {
      year: number[];
      month: number[];
      ext: { value: ExtStringType; label: string }[];
    };
  };
  transitionItems: any[];
  isLoading: Loading;
}

const initialState: CommodityState = {
  data: null,
  keyword: {
    list: [],
    count: null
  },
  seriesInfoList: [],
  newsData: {},
  binderList: [],
  selectedItems: [],
  binderItems: [],
  updateSearchResult: {},
  conditions: {
    value: {
      from: "",
      to: "",
      extValue: "",
      dataType: ""
    },
    list: {
      year: [],
      month: [],
      ext: []
    }
  },
  transitionItems: [],
  isLoading: {
    list: false
  }
};

export const commodity: Reducer<CommodityState, CommodityAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "macro/commodity/INIT_PAGE":
      return produce(state, draft => {
        draft.data = action.payload.data;
      });
    case "macro/commodity/SET_KEYWORD":
      return produce(state, draft => {
        draft.keyword = action.payload;
      });
    case "macro/commodity/GET_NEWS_LIST":
      return produce(state, draft => {
        draft.newsData = action.payload.newsData;
      });
    case "macro/commodity/SET_BINDER_LIST":
      return produce(state, draft => {
        draft.binderList = action.payload;
      });
    case "macro/commodity/GET_SERIES_LIST":
      return produce(state, draft => {
        draft.seriesInfoList = action.payload.seriesInfoList;
      });
    case "macro/commodity/SET_BINDER_ITEMS":
      return produce(state, draft => {
        draft.binderItems = action.payload;
      });
    case "macro/commodity/UPDATE_SELECTED_ITEMS":
      return produce(state, draft => {
        draft.selectedItems = action.payload.data;
      });
    case "macro/commodity/SET_UPDATE_SEARCH_RESULT":
      return produce(state, draft => {
        draft.transitionItems = initialState.transitionItems;
        draft.updateSearchResult = action.payload.response;
        if (!action.payload.params) return;

        draft.conditions.value = action.payload.params;
        const extList = state.selectedItems.map(
          (item: any) => item.extFlg
        ) as ExtType[];

        draft.conditions.list.ext = buildExtList(extList);
        if (action.payload.params.extValue === "") return;
        const from = getEdgeDate(extList, "From");
        const dataList = buildDateList(
          from,
          action.payload.params.to,
          action.payload.params.extValue
        );
        draft.conditions.list.year = dataList.year;
        draft.conditions.list.month = dataList.month;
      });
    case "macro/commodity/CHANGE_CONDITION":
      return produce(state, draft => {
        const currentStateExt = state.conditions.value.extValue;
        if (currentStateExt && action.payload.key === "from") {
          const unit = getDayjsUnit(currentStateExt);
          draft.conditions.value["from"] = dayjs(action.payload.value)
            .startOf(unit)
            .format("YYYYMMDD");
        } else if (currentStateExt && action.payload.key === "to") {
          const unit = getDayjsUnit(currentStateExt);
          draft.conditions.value["to"] = dayjs(action.payload.value)
            .endOf(unit)
            .format("YYYYMMDD");
        } else {
          draft.conditions.value[action.payload.key] = action.payload.value;
        }
        if (action.payload.key !== "extValue") return;

        const extList = state.selectedItems.map(
          (item: any) => item.extFlg
        ) as ExtType[];

        const extValue = action.payload.value as ExtStringType;
        const from = getEdgeDate(extList, "From", extValue);
        const to = getEdgeDate(extList, "To", extValue);
        const dateList = buildDateList(from, to, extValue);
        draft.conditions.value.from = getDefaultFromDate(from, to, extValue);
        draft.conditions.value.to = to;
        draft.conditions.list.year = dateList.year;
        draft.conditions.list.month = dateList.month;
      });
    case "macro/commodity/RESET_STATE":
      return initialState;
    case "macro/commodity/SET_TRANSITION_ITEMS":
      return produce(state, draft => {
        draft.transitionItems = action.payload;
      });
    case "macro/commodity/SET_LOADING":
      return produce(state, draft => {
        draft.isLoading[action.payload.key] = action.payload.value;
      });
    default:
      return state;
  }
};
