import React from "react";
import styles from "./style.scss";
import Heading from "components/Common/Heading/index";
import { makeSequentialNumberArray } from "utils/helper/common";
import { formatComma } from "utils/helper/format";

const headerIndex = 0;
const tableCaptionIndices = [1, 2];
const tableHeaderIndex = 3;
const tableMaxColCounts = 5;

const Cell = ({ isBody = false, className = "", children }) =>
  isBody ? (
    <td className={className}>{children}</td>
  ) : (
    <th className={className}>{children}</th>
  );

const CellBlocks = ({
  resultList,
  nums,
  termLength,
  corpFinResultIndex,
  isBody = false
}) =>
  isBody && corpFinResultIndex <= tableHeaderIndex
    ? null
    : nums.map((num, index) => (
        <React.Fragment key={num}>
          {index % tableMaxColCounts === 0 && (
            <Cell isBody={isBody}>
              {
                resultList[num].corpFinResult[corpFinResultIndex].tdbFnclMst
                  .title
              }
            </Cell>
          )}
          <Cell isBody={isBody}>
            {isBody
              ? formatComma(
                  resultList[num].corpFinResult[corpFinResultIndex].value
                )
              : resultList[num].corpFinResult[corpFinResultIndex].value}
          </Cell>
          <Cell isBody={isBody}>
            {isBody
              ? formatComma(
                  resultList[num].corpFinResult[corpFinResultIndex]
                    .compositionRatio
                )
              : resultList[num].corpFinResult[corpFinResultIndex]
                  .compositionRatio}
          </Cell>
          {num < termLength - 1 && (
            <Cell isBody={isBody}>
              {isBody
                ? formatComma(
                    resultList[num].corpFinResult[corpFinResultIndex]
                      .lastyearRatio
                  )
                : resultList[num].corpFinResult[corpFinResultIndex]
                    .lastyearRatio}
            </Cell>
          )}
        </React.Fragment>
      ));

const FinanceTable = ({ data }) =>
  data.corpFinTdbResultKindList.map((fin, i) => {
    // データがない時はスキップ
    if (fin.accResultList[0].corpFinResult.length === 0) {
      return null;
    }

    const termLength = fin.accResultList.length;
    const tableIndices = makeSequentialNumberArray(
      Math.floor((termLength - 1) / tableMaxColCounts) + 1
    );
    const resultList = fin.accResultList;
    return tableIndices.map(index => {
      const remaining = termLength - index * tableMaxColCounts;
      const nums = makeSequentialNumberArray(
        remaining > tableMaxColCounts ? tableMaxColCounts : remaining,
        index * tableMaxColCounts
      );
      return (
        <article key={`${i}-${index}`} className={styles.component}>
          <Heading className={styles.title}>
            {fin.accResultList[0].corpFinResult[headerIndex].tdbFnclMst.title}
          </Heading>
          <div className={styles.tableWrapper} data-print-style="tableWrapper">
            <table
              className={styles.vsTable}
              data-testid="Common-Popup-Tdb-FinanceTable"
            >
              <thead>
                {tableCaptionIndices.map(i => (
                  <tr key={i} className={styles.caption}>
                    <CellBlocks
                      resultList={resultList}
                      nums={nums}
                      termLength={termLength}
                      corpFinResultIndex={i}
                    />
                  </tr>
                ))}
                <tr className={styles.vsTableHeader}>
                  <CellBlocks
                    resultList={resultList}
                    nums={nums}
                    termLength={termLength}
                    corpFinResultIndex={tableHeaderIndex}
                  />
                </tr>
              </thead>
              <tbody>
                {resultList[0].corpFinResult.map((_, i) => (
                  <tr key={i}>
                    <CellBlocks
                      resultList={resultList}
                      nums={nums}
                      isBody
                      termLength={termLength}
                      corpFinResultIndex={i}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      );
    });
  });

export default FinanceTable;
