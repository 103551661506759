import React from "react";
import styles from "./style.scss";
import TextButton from "components/Common/Button/Text";
import Heading from "components/Common/Heading";
import { RouteComponentProps } from "react-router-dom";

const NotFound: React.FunctionComponent<RouteComponentProps> = props => {
  const goTop = () => {
    props.history.push("/");
  };

  return (
    <div className={styles.error}>
      <div className={styles.title} data-testid="features-NotFound-title">
        <Heading level="2">お探しのページが見つかりませんでした</Heading>
      </div>
      <div className={styles.content}>
        <p>お探しのページは、削除されたか存在しないページです。</p>
        <p>
          大変お手数ですが、URLが正しいかをご確認いただくか、以下のリンクをご利用ください。
        </p>
      </div>
      <div className={styles.btn}>
        <TextButton className={styles.button} onClick={() => goTop()}>
          トップへ戻る
        </TextButton>
      </div>
    </div>
  );
};

export default NotFound;
