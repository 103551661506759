import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  checkResult: {},
  saveResult: {}
};

export default handleActions(
  {
    [`${ActionTypes.MY_FOLDER_CHECK}_FULFILLED`]: (state, action) => ({
      ...state,
      checkResult: action.payload
    }),
    [`${ActionTypes.MY_FOLDER_SAVE}_FULFILLED`]: (state, action) => ({
      ...state,
      saveResult: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  checkMyFolder: createAction(ActionTypes.MY_FOLDER_CHECK, params =>
    request(375, params)
  ),
  saveMyFolder: createAction(ActionTypes.MY_FOLDER_SAVE, params =>
    request(371, params)
  )
};
