// 記事本文の取得
export const BODY_GET = "common/Popup/BODY_GET";
// pdfのダウンロード
export const PDF_DOWNLOAD = "common/Popup/PDF_DOWNLOAD";
// xlsのダウンロード
export const XLS_DOWNLOAD = "common/Popup/XLS_DOWNLOAD";
// xbrlのダウンロード
export const XBRL_DOWNLOAD = "common/Popup/XBRL_DOWNLOAD";
// テキストで渡した本文をセット
export const SET_BODY_TEXT = "common/Popup/SET_BODY_TEXT";
// 株主（保有目的）セレクトボックスの選択中オプション
export const SET_SELECTED_OPTION = "common/Popup/SET_SELECTED_OPTION";
// Bizトレンド本文取得
export const GET_TREND_REPORT_TEXT = "common/Popup/GET_TREND_REPORT_TEXT";
// コレクション記事取得
export const GET_BINDER_ARTICLE = "common/Popup/GET_BINDER_ARTICLE";
// 記事プレビュー取得
export const GET_ARTICLE_PREVIEW = "common/Popup/GET_ARTICLE_PREVIEW";
