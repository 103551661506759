import React, { Component } from "react";
import styles from "./style.scss";
import { deepClone, isEmpty } from "utils/helper/common";
import SectionTitle from "components/Common/SectionTitle/index";
import Select from "components/Common/Select";
import { getParamsFromUrl } from "utils/helper/url";

const defaultOption = { value: "0", label: "すべて" };
const optionList = [
  defaultOption,
  { value: "1", label: "売買目的" },
  { value: "2", label: "投資" },
  { value: "3", label: "政策投資" },
  { value: "4", label: "みなし保有" }
];

class SharesHoldings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: props.resourceId
    };
  }

  render() {
    const props = this.props;
    if (isEmpty(props.corpStockList)) return null;

    const search = getParamsFromUrl();
    const title = `${props.individualName1}(${props.individualSecCode1})の株主（直近決算期）`;
    // 表示種別
    const kind = search.kind;

    // 表示するセレクトボックスのオプション
    let selectedOption = !isEmpty(props.selectedOption)
      ? deepClone(props.selectedOption)
      : optionList.find(opt => opt.value === kind);
    // 選択項目に対象データがない場合は「すべて」表示
    if (
      isEmpty(selectedOption) ||
      !props.corpStockList.some(item => selectedOption.label === item.purpose)
    ) {
      selectedOption = defaultOption;
    }

    const files = [
      {
        type: "xls",
        params: {
          ...search,
          individualSecCode1: props.individualSecCode1,
          individualName1: props.individualName1,
          individualNikkeiCode1: props.individualNikkeiCode1,
          kind: selectedOption.value
        },
        key: "sharesHoldingsXls"
      }
    ];

    // APIに値なし
    const filteredList = optionList.filter((opt, i) => {
      if (i === 0) return true;
      return props.corpStockList.some(item => opt.label === item.purpose);
    });

    const body = props.corpStockList.map((item, index) => {
      // 「すべて」以外の保有目的のとき選択されたラベル文字列と一致する保有目的の株主のみ表示
      if (
        selectedOption.value !== defaultOption.value &&
        item.purpose !== selectedOption.label
      )
        return null;
      return (
        <tr key={index}>
          <td data-testid="Common-Popup-SharesHoldings-secCode">
            {item.secCode}
          </td>
          <td>{item.shareholderName}</td>
          <td>{item.accPeriod}</td>
          <td>{item.count}</td>
          <td data-testid="Common-Popup-SharesHoldings-purpose">
            {item.purpose}
          </td>
        </tr>
      );
    });

    return (
      <div
        className={styles.component}
        data-testid="Common-Popup-SharesHoldings"
      >
        <SectionTitle files={files} resourceId={this.state.resourceId}>
          {title}
        </SectionTitle>
        <div className={styles.condition}>
          <div>保有目的</div>
          <Select
            optionList={filteredList}
            onSelected={opt => this.props.setSelectedOption(opt)}
            className={styles.select}
            selectedOption={selectedOption.value}
            uncontrolled
          />
        </div>
        <table className={styles.vsTable}>
          <thead>
            <tr>
              <th>株式コード</th>
              <th>会社名</th>
              <th>決算期</th>
              <th>持株数</th>
              <th>保有目的</th>
            </tr>
          </thead>
          <tbody>{body}</tbody>
        </table>
      </div>
    );
  }
}

export default SharesHoldings;
