import React, { Component } from "react";
import styles from "./style.scss";

import toast from "components/Common/Toast/enhance";
import modalUtil from "./enhance";

/**
 * モーダル
 * ローカルのstateで開閉状態を管理する。親コンポーネントから開閉を操作する場合は、
 * Refsを設定してこのコンポーネントのopen(), close()を使う
 *
 * @prop {?string} title - モーダルのタイトル
 * @prop {?function} onClose - 閉じるときのコールバック
 * @prop {?boolean} uncontrolled - 開閉をステート管理しない
 * @prop {?boolean} isOpen - 開閉状態(uncontrolled = true 時のみ有効)
 */
const defaultProps = {
  title: null,
  onClose: null,
  uncontrolled: false,
  isOpen: false,
  className: "",
  modalClassName: "",
  isPreventingClearMessage: false,
  checkReOpenFlag: null,
  isHideCloseButton: false,
  qsFlag: false,
  isHideHeader: false,
  onScroll: () => {},
  setHelpAddonStatus: () => {}
};

interface State {
  isOpen: boolean;
  modalId: string;
  showQs: {
    x: number;
    y: number;
    over: boolean;
  };
}

export interface FloatView {
  open: () => void;
  close: (shouldCallOnClose?: boolean) => void;
  bodyRef: any;
}

class Modal extends Component<any, State> implements FloatView {
  wrapperRef: any;
  bodyRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      modalId: this.generateResourceId(),
      showQs: {
        x: 0,
        y: 0,
        over: false
      }
    };
    this.wrapperRef = React.createRef();
    this.bodyRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  componentDidMount() {
    // Un-Controlledの場合で最初からモーダルOPENの場合（テレコン画面など）
    if (this.props.uncontrolled && this.props.isOpen) {
      // modalIdを登録
      this.props.registerModal(this.state.modalId);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Un-Controlledの場合
    if (this.props.uncontrolled) {
      // モーダルOPENの場合
      if (!prevProps.isOpen && this.props.isOpen) {
        // modalIdを登録
        this.props.registerModal(this.state.modalId);
      } else if (
        (prevProps.isOpen && !this.props.isOpen) ||
        (this.props.isOpen &&
          this.props.modalStack.indexOf(this.state.modalId) < 0)
      ) {
        if (
          this.props.checkReOpenFlag &&
          this.props.checkReOpenFlag() &&
          this.props.isOpen &&
          prevProps.modalStack.indexOf(this.state.modalId) < 0 &&
          this.props.modalStack.indexOf(this.state.modalId) < 0
        ) {
          // ESCキーの場合、再開のため再registする
          this.props.registerModal(this.state.modalId);
        } else {
          // 登録されているmodalIdを削除 (closeボタンを押された場合、escキーが押された場合)
          this.close();
        }
      }

      // Controlledの場合
    } else {
      // ダイアログ表示中かつstoreに登録されていない場合（esc押下のケース）
      if (
        this.state.isOpen &&
        this.props.modalStack.indexOf(this.state.modalId) < 0
      ) {
        this.close();
      }
    }

    if (
      !this.props.isPreventingClearMessage &&
      ((this.props.isOpen !== prevProps.isOpen && !this.props.isOpen) ||
        (this.state.isOpen !== prevState.isOpen && !this.state.isOpen))
    ) {
      // 閉じる時にトーストを消す
      this.props.clearToastMessages();
      this.props.clearToastErrors();
    }
  }

  generateResourceId() {
    // 乱数で整数値を返す
    return `modalid_${Math.floor(Math.random() * 100000000)}`;
  }

  open() {
    // storeに表示中ダイアログを登録
    this.props.registerModal(this.state.modalId);
    this.setState(() => ({
      isOpen: true
    }));
  }

  close(shouldCallOnClose = true) {
    if (shouldCallOnClose && this.props.onClose) {
      // onClose の戻り値が true で設定されている場合は、
      // モーダルを閉じるのをキャンセルしたこととして扱う
      const closeCanceled = this.props.onClose();
      if (closeCanceled) return;
    }

    if (0 <= this.props.modalStack.indexOf(this.state.modalId)) {
      // storeの表示中ダイアログから削除
      this.props.releaseModal();
    }
    // controlled な時には state 管理して、モーダルを閉じる
    // uncontrolled な時には state 管理は不要
    if (!this.props.uncontrolled) {
      this.setState(() => ({
        isOpen: false
      }));
    }
  }

  handleClickOutside(event: any) {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.close();
    }
  }

  handleMouseOver = (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const target = (ev.target as unknown) as HTMLDivElement;
    this.setState(() => ({
      showQs: {
        x: target.offsetLeft - 230,
        y: target.offsetTop + 20,
        over: true
      }
    }));
  };

  handleMouseOut = () => {
    this.setState(() => ({
      showQs: {
        x: 0,
        y: 0,
        over: false
      }
    }));
  };

  render() {
    const isOpen = this.props.uncontrolled
      ? this.props.isOpen
      : this.state.isOpen;
    return (
      <div
        className={`
          ${this.props.className}
          ${styles.wrapper}
          ${!isOpen && styles.hide}
          ${this.props.hasFloatingFooter && styles.hasFloatingFooter}
        `}
        onClick={e => {
          e.stopPropagation();
          this.handleClickOutside(e);
        }}
        data-testid="Common-Modal-wrapper"
      >
        <div
          className={`${styles.modalSizing} ${this.props.modalClassName}`}
          ref={this.wrapperRef}
          data-testid="Common-Modal-modalSizing"
        >
          {!this.props.isHideCloseButton && (
            <div
              className={styles.buttonWrapper}
              data-testid="Common-Modal-closeButton"
              onClick={event => {
                event.stopPropagation();
                this.close();
              }}
            >
              <button>閉じる</button>
              <span className={styles.icon} />
            </div>
          )}
          <div className={styles.modalWrap}>
            <div className={styles.modal} data-testid="Common-Modal-modal">
              {!this.props.isHideHeader && (
                <div
                  className={styles.header}
                  data-testid="Common-Modal-Header"
                >
                  {this.props.title && <span>{this.props.title}</span>}
                  {this.props.title && this.props.qsFlag && (
                    <span
                      className={styles.qs}
                      onMouseEnter={e => this.handleMouseOver(e)}
                      onMouseLeave={() => this.handleMouseOut()}
                      data-testid="Common-Modal-qs"
                    >
                      テキストリンクを設定するには？
                    </span>
                  )}
                </div>
              )}
              {this.state.showQs.over && (
                <div
                  style={{
                    top: this.state.showQs.y + "px",
                    left: this.state.showQs.x + "px",
                    position: "absolute"
                  }}
                  className={styles.showQs}
                  data-testid="Common-Modal-showQs"
                >
                  <div>
                    “[表示テキスト]（URL）”でテキストリンクの設定が可能です。表示したいテキストと、リンクさせたいURLをそれぞれカッコ内に記入してください。
                  </div>
                  <div>
                    テキストリンクは説明文内で利用可能です。タイトルには使用できません。
                  </div>
                </div>
              )}
              <div
                className={styles.body}
                onScroll={this.props.onScroll}
                ref={this.bodyRef}
                data-testid="Common-Modal-body"
              >
                {isOpen && this.props.children}
              </div>
            </div>
            {this.props.appendix}
          </div>
        </div>
      </div>
    );
  }
}

(Modal as any).defaultProps = defaultProps;
export default toast(modalUtil(Modal)) as any;
