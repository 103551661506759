import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";

const initialItems = [
  {
    id: "CORPFILTER_CHECKBOXID_LISTED_CORP",
    labelId: "CORPFILTER_LABELID_LISTED_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "1",
    seq: "1",
    inneyCode: "1",
    label: "上場"
  },
  {
    id: "CORPFILTER_CHECKBOXID_SECURITYREPORTED_CORP",
    labelId: "CORPFILTER_LABELID_SECURITYREPORTED_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "2",
    seq: "2",
    inneyCode: "2",
    label: "有報"
  },
  {
    id: "CORPFILTER_CHECKBOXID_TDB_CORP",
    labelId: "CORPFILTER_LABELID_TDB_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "11",
    seq: "3",
    inneyCode: "11",
    label: "非上場(TDB)"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_CORP",
    labelId: "CORPFILTER_LABELID_UNLISTED_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "3",
    seq: "4",
    inneyCode: "3",
    label: "非上場(日経)"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_CORPTOP",
    labelId: "CORPFILTER_LABELID_UNLISTED_CORPTOP",
    selected: false,
    corpCount: "0",
    industrycorptopCondition: "",
    active: true,
    haveAuthority: true,
    fsarea: "",
    value: "98", // 仮指定
    seq: "",
    inneyCode: "98",
    label: "売上高上位"
  },
  {
    id: "CORPFILTER_CHECKBOXID_OVERSEAS_CORP",
    labelId: "CORPFILTER_LABELID_OVERSEAS_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "4",
    seq: "5",
    inneyCode: "4",
    label: "海外"
  },
  {
    id: "CORPFILTER_CHECKBOXID_OVERSEAS_NORTHAMERICA_CORP",
    labelId: "CORPFILTER_LABELID_OVERSEAS_NORTHAMERICA_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "5",
    seq: "6",
    inneyCode: "5",
    label: "北米"
  },
  {
    id: "CORPFILTER_CHECKBOXID_OVERSEAS_SOUTHAMERICA_CORP",
    labelId: "CORPFILTER_LABELID_OVERSEAS_SOUTHAMERICA_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "6",
    seq: "7",
    inneyCode: "6",
    label: "南米"
  },
  {
    id: "CORPFILTER_CHECKBOXID_OVERSEAS_EUROPE_CORP",
    labelId: "CORPFILTER_LABELID_OVERSEAS_EUROPE_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "7",
    seq: "8",
    inneyCode: "7",
    label: "欧州"
  },
  {
    id: "CORPFILTER_CHECKBOXID_OVERSEAS_ASIAPACIFIC_CORP",
    labelId: "CORPFILTER_LABELID_OVERSEAS_ASIAPACIFIC_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "8",
    seq: "9",
    inneyCode: "8",
    label: "アジア・パシフィック"
  },
  {
    id: "CORPFILTER_CHECKBOXID_OVERSEAS_MIDEAST_CORP",
    labelId: "CORPFILTER_LABELID_OVERSEAS_MIDEAST_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "9",
    seq: "10",
    inneyCode: "9",
    label: "中東"
  },
  {
    id: "CORPFILTER_CHECKBOXID_OVERSEAS_AFRICA_CORP",
    labelId: "CORPFILTER_LABELID_OVERSEAS_AFRICA_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "10",
    seq: "11",
    inneyCode: "10",
    label: "アフリカ"
  },
  {
    id: "CORPFILTER_CHECKBOXID_STARTUP_CORP",
    labelId: "CORPFILTER_LABELID_STARTUP_CORP",
    selected: false,
    corpCount: "0",
    active: false,
    haveAuthority: false,
    fsarea: "",
    value: "13",
    seq: "13",
    inneyCode: "13",
    label: "非上場(ケップル)"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_OVERSEAS_CORP",
    selected: false,
    haveAuthority: false,
    corpCount: "0",
    labelId: "CORPFILTER_LABELID_UNLISTED_OVERSEAS_CORP",
    active: true,
    fsarea: "",
    label: "海外非上場",
    seq: "14",
    inneyCode: "14",
    value: "14"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_OVERSEAS_NORTHAMERICA_CORP",
    selected: false,
    haveAuthority: false,
    corpCount: "0",
    labelId: "CORPFILTER_LABELID_UNLISTED_OVERSEAS_NORTHAMERICA_CORP",
    active: true,
    fsarea: "",
    label: "北米非上場",
    seq: "15",
    inneyCode: "15",
    value: "15"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_OVERSEAS_SOUTHAMERICA_CORP",
    selected: false,
    haveAuthority: false,
    corpCount: "0",
    labelId: "CORPFILTER_LABELID_UNLISTED_OVERSEAS_SOUTHAMERICA_CORP",
    active: true,
    fsarea: "",
    label: "南米非上場",
    seq: "16",
    inneyCode: "16",
    value: "16"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_OVERSEAS_EUROPE_CORP",
    selected: false,
    haveAuthority: false,
    corpCount: "0",
    labelId: "CORPFILTER_LABELID_UNLISTED_OVERSEAS_EUROPE_CORP",
    active: true,
    fsarea: "",
    label: "欧州非上場",
    seq: "17",
    inneyCode: "17",
    value: "17"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_OVERSEAS_ASIAPACIFIC_CORP",
    selected: false,
    haveAuthority: false,
    corpCount: "0",
    labelId: "CORPFILTER_LABELID_UNLISTED_OVERSEAS_ASIAPACIFIC_CORP",
    active: true,
    fsarea: "",
    label: "アジア・パシフィック非上場",
    seq: "18",
    inneyCode: "18",
    value: "18"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_OVERSEAS_MIDEAST_CORP",
    selected: false,
    haveAuthority: false,
    corpCount: "0",
    labelId: "CORPFILTER_LABELID_UNLISTED_OVERSEAS_MIDEAST_CORP",
    active: true,
    fsarea: "",
    label: "中東非上場",
    seq: "19",
    inneyCode: "19",
    value: "19"
  },
  {
    id: "CORPFILTER_CHECKBOXID_UNLISTED_OVERSEAS_AFRICA_CORP",
    selected: false,
    haveAuthority: false,
    corpCount: "0",
    labelId: "CORPFILTER_LABELID_UNLISTED_OVERSEAS_AFRICA_CORP",
    active: true,
    fsarea: "",
    label: "アフリカ非上場",
    seq: "20",
    inneyCode: "20",
    value: "20"
  }
];

const initialState = {
  items: [...initialItems],
  personalSettings: [...initialItems],
  authType: {
    toolComparisonCorp: "vsanan",
    corpReportCreator: "vsannn",
    headerSearch: "vsanan"
  },
  isInitializeItems: true
};

// 海外
const overseasCorpCode = "4";
// 海外地域別
const overseasAreaCodes = ["5", "6", "7", "8", "9", "10"];
// 海外非上場
const unlistedOverseasCorpCode = "14";
// 海外非上場地域別
const unlistedOverseasAreaCodes = ["15", "16", "17", "18", "19", "20"];

const mergeItems = (input, items, keys) =>
  input.items.map(item => {
    const target = items.find(data => data.id.startsWith(item.id));
    if (target == undefined) return item;
    const result = { ...item };
    keys.forEach(key => {
      // inneyCode:3 のみ inneyCode:12 のラベルを使う
      if (key === "label" && item.inneyCode === "3") {
        const labelTarget = items.find(data => data.inneyCode == "12");
        if (labelTarget !== undefined)
          return (result["label"] = labelTarget["label"]);
      }
      result[key] = target[key];
    });
    if (result.inneyCode === overseasCorpCode) {
      result.selected = result.selected
        ? result.selected
        : items
            .filter(item => item.selected)
            .some(item => {
              return overseasAreaCodes.includes(item.inneyCode);
            });
    }
    if (result.inneyCode === unlistedOverseasCorpCode) {
      result.selected = result.selected
        ? result.selected
        : items
            .filter(item => item.selected)
            .some(item => {
              return unlistedOverseasAreaCodes.includes(item.inneyCode);
            });
    }
    // 売上高上位 の選択状態を省く（廃止済みのため）
    if (result.inneyCode === "98") {
      result.selected = false;
    }
    return result;
  });

const getSuggestAuthType = sourceList => {
  if (!sourceList) return;
  const overSeaAuth = sourceList.find(item => item.seq === "5");
  const haveAuthority = overSeaAuth && overSeaAuth.haveAuthority;
  const type = {
    toolScreeningCorpItem: haveAuthority ? "vsafnn" : "vsannn",
    toolComparisonCorp: haveAuthority ? "vsafnn" : "vsannn",
    corpReportCreator: haveAuthority ? "vsafnn" : "vsannn",
    headerSearch: haveAuthority ? "vsafan" : "vsanan"
  };
  return type;
};

export default handleActions(
  {
    [ActionTypes.SELECTION_SAVE]: (state, action) => {
      return {
        ...state,
        items: mergeItems(state, action.payload, ["selected"])
      };
    },
    [`${ActionTypes.SELECTION_GET}_FULFILLED`]: (state, action) => {
      const items = mergeItems(
        initialState,
        action.payload.corpFilter_Checkbox_LabelValueDtoList,
        ["active", "selected", "haveAuthority", "seq", "label"]
      );
      // seq で並べ替え
      items.sort((a, b) => {
        const aSeq = parseInt(a.seq, 10);
        const bSeq = parseInt(b.seq, 10);
        return aSeq - bSeq;
      });
      return {
        ...state,
        items: items,
        personalSettings: items,
        authType: getSuggestAuthType(items),
        isInitializeItems: false
      };
    },
    [ActionTypes.SELECTION_CLEAR]: () => initialState
  },
  initialState
);

export const actionCreators = {
  saveSelection: createAction(ActionTypes.SELECTION_SAVE),
  getSelection: createAction(ActionTypes.SELECTION_GET, params =>
    request(819, {
      contentId: "userinfo",
      ...params
    })
  ),
  clearSelection: createAction(ActionTypes.SELECTION_CLEAR)
};
