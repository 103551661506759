// 初期表示
export const PAGE_INIT = "common/EventSearch/PAGE_INIT";
export const EVENT_TYPE_CODE_LIST_SET =
  "common/EventSearch/EVENT_TYPE_CODE_LIST_SET";
// 検索キーワード設定
export const EVENT_TYPE_CODE_SET = "common/EventSearch/EVENT_TYPE_CODE_SET";
// 期間変更
export const PERIOD_SET = "common/EventSearch/PERIOD_SET";
// イベント情報の検索
export const EVENT_DATA_GET = "common/EventSearch/EVENT_DATA_GET";
// イベント情報のページネーション
export const EVENT_DATA_PAGE_GET = "common/EventSearch/EVENT_DATA_PAGE_GET";
// 日付初期化
export const SHOULD_INIT_DATE_SET = "common/EventSearch/SHOULD_INIT_DATE_SET";
// 検索条件ローディング状態更新
export const CONDITION_LOADING_UPDATE =
  "common/EventSearch/CONDITION_LOADING_UPDATE";
