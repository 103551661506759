import React from "react";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import { findAnalyticsWithPath } from "utils/helper/url";
import { isIE } from "utils/helper/common";

class SentryWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    if (IS_DEBUG) {
      this.setState({ error, errorInfo });
      return;
    }

    // ページ名が取得できれば設定する
    const info = findAnalyticsWithPath(location.pathname);
    const pageName = info === undefined ? "" : info.pageName;

    // Sentry ログ送信
    Sentry.withScope(scope => {
      scope.setExtras({
        pageName,
        catchAt: "SentryWrapper"
      });
      Sentry.captureException(error);
    });

    if (!isIE()) {
      // LogRocket ログ送信
      LogRocket.captureException(error, {
        extra: { pageName }
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ padding: 20 }}>
          <h2>ErrorStack</h2>
          <pre>{this.state.error.stack}</pre>
          <h2>ComponentStack</h2>
          <pre>{this.state.errorInfo.componentStack}</pre>
        </div>
      );
    }

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default SentryWrapper;
