import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  shareholder: {},
  selectedCrossheld: { value: "0" },
  selectedVoting: { value: "0" },
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.SHAREHOLDERS_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      shareholder: action.payload
    }),
    [ActionTypes.SELECTED_CROSSHELD_SET]: (state, action) => ({
      ...state,
      selectedCrossheld: action.payload
    }),
    [ActionTypes.SELECTED_VOTING_SET]: (state, action) => ({
      ...state,
      selectedVoting: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(264, params)),
  getPage: createAction(ActionTypes.PAGE_GET, params => request(265, params)),
  getShareholders: createAction(ActionTypes.SHAREHOLDERS_GET, params =>
    request(266, params)
  ),
  setCrossheld: createAction(ActionTypes.SELECTED_CROSSHELD_SET),
  setVoting: createAction(ActionTypes.SELECTED_VOTING_SET)
};
