import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  strategyData: {},
  itemPerPage: "",
  sortType: "",
  codeList: {
    hitPetternOptionList: [],
    placeCheckboxList: [],
    contentOptionList: [],
    addressOptionList: [],
    releaseDateOptionList: [],
    dispCountList: [],
    sortOptionList: []
  },
  error: null
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      strategyData: action.payload.ReportStrategyDto
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CODE_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      codeList: action.payload,
      itemPerPage: action.payload.dispCountList[0].value,
      sortType: action.payload.sortOptionList[0].value
    }),
    [`${ActionTypes.CODE_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.STRATEGY_DATA_SEARCH}_PENDING`]: state => ({
      ...state,
      // データ表示リスト系で選択中の値をリセット
      itemPerPage: state.codeList.dispCountList[0].value,
      sortType: state.codeList.sortOptionList[0].value
    }),
    [`${ActionTypes.STRATEGY_DATA_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      strategyData: action.payload.ReportStrategyDto
    }),
    [`${ActionTypes.STRATEGY_DATA_SEARCH}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.STRATEGY_PAGING}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      strategyData: action.payload.ReportStrategyDto
    }),
    [`${ActionTypes.STRATEGY_PAGING}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.STRATEGY_DATA_SORT}_PENDING`]: (state, action) => ({
      ...state,
      itemPerPage: action.meta.itemPerPage
        ? action.meta.itemPerPage
        : state.itemPerPage,
      sortType: action.meta.sortType ? action.meta.sortType : state.sortType
    }),
    [`${ActionTypes.STRATEGY_DATA_SORT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      strategyData: action.payload.ReportStrategyDto
    }),
    [`${ActionTypes.STRATEGY_DATA_SORT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(34, params)),
  getCodeList: createAction(ActionTypes.CODE_LIST_GET, params =>
    Promise.all([
      request(822, { gamenId: "N9050", groupId: "01", ...params }),
      request(822, { gamenId: "N9050", groupId: "06", ...params }),
      request(822, { gamenId: "N9050", groupId: "02", ...params }),
      request(822, { gamenId: "N9050", groupId: "05", ...params }),
      request(822, { gamenId: "N9050", groupId: "09", ...params }),
      request(822, { gamenId: "N9050", groupId: "08", ...params })
    ]).then(
      ([
        hitPetternOptionList,
        placeCheckboxList,
        contentOptionList,
        addressOptionList,
        dispCountList,
        sortOptionList
      ]) => ({
        hitPetternOptionList,
        placeCheckboxList,
        contentOptionList,
        addressOptionList,
        dispCountList,
        sortOptionList
      })
    )
  ),
  searchStrategyData: createAction(ActionTypes.STRATEGY_DATA_SEARCH, params =>
    request(35, params)
  ),
  paging: createAction(ActionTypes.STRATEGY_PAGING, params =>
    request(843, params)
  ),
  sortStrategyData: createAction(
    ActionTypes.STRATEGY_DATA_SORT,
    params => request(42, params),
    params => ({ itemPerPage: params.dispCount, sortType: params.sortKbn })
  )
};
