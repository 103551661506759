import { FilterItem, DisplayItem } from "models/CorpScreening";

export const setFilterTree = (tree: FilterItem[]) => ({
  type: "tool/screening/corp/modernItem/tree/SET_FILTER_TREE" as const,
  payload: tree
});

export const setDisplayTree = (list: DisplayItem[]) => ({
  type: "tool/screening/corp/modernItem/tree/SET_DISPLAY_Tree" as const,
  payload: list
});

export const setIsFilterTreeLoading = (loading: boolean) => ({
  type: "tool/screening/corp/modernItem/tree/SET_IS_FILTER_TREE_LOADING" as const,
  payload: loading
});

export type ToolScreeningCorpModernItemTreeAction = ReturnType<
  typeof setFilterTree | typeof setDisplayTree | typeof setIsFilterTreeLoading
>;
