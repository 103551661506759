import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import {
  categoryNames,
  menuContentIds,
  loadActiveMenu
} from "utils/helper/url";
import { request } from "utils/apiClient/base";
import { replaceUrl } from "utils/helper/replaceUrl";

const initialState = {
  isInit: false,
  items: [],
  params: {},
  isLoadingItems: false,
  isAllClose: false,
  activeChild: ""
};

const loadCategoryKey = targetName => {
  for (const key in categoryNames) {
    if (categoryNames[key] === targetName) return key;
  }
};

const loadMenuKey = targetId => {
  for (const key in menuContentIds) {
    const ids = menuContentIds[key];
    if (ids.some(id => id === targetId)) return key;
  }
};

const createItems = origItems => {
  return origItems.map(parent => {
    const newParent = {
      parent: parent.menuName,
      key: loadCategoryKey(parent.menuName),
      validFlg: parent.validFlg,
      showFlg: parent.showFlg,
      children: parent.childMenu.map(child => {
        const newChild = {
          text: child.menuName,
          url: replaceUrl(child.urlValue, child.menuContentId),
          key: loadMenuKey(child.menuContentId),
          query: { menuId: child.menuContentId },
          showFlg: child.showFlg,
          validFlg: child.validFlg
        };
        return newChild;
      })
    };
    return newParent;
  });
};

const updateItems = (items, selected, isAllClose) => {
  return items.map(item => ({
    ...item,
    selected: isAllClose || item.key === selected.activeParent,
    children: item.children.map(child => ({
      ...child,
      selected: child.key === selected.activeChild
    }))
  }));
};

export default handleActions(
  {
    [`${ActionTypes.OPEN_MENU_FLG_GET}_PENDING`]: state => ({
      ...state,
      isInit: true
    }),
    [`${ActionTypes.OPEN_MENU_FLG_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      isAllClose: action.payload.menuOpenFlg === "1" ? true : false
    }),
    [`${ActionTypes.ITEMS_SET}_PENDING`]: state => ({
      ...state,
      isLoadingItems: true
    }),
    [`${ActionTypes.ITEMS_SET}_FULFILLED`]: (state, action) => {
      // 項目の取得
      const items = createItems(
        action.payload.menuInfoList,
        action.meta.pathname
      );
      // 親・子項目の選択状態を取得
      const selected = loadActiveMenu(
        action.meta.pathname,
        action.meta.corpIndustyKbn,
        action.meta.corpInfo.diffflg,
        action.meta.corpInfo.asrf
      );

      const selectedItems = updateItems(items, selected, state.isAllClose);

      return {
        ...state,
        items: selectedItems,
        activeChild: selected.activeChild,
        isLoadingItems: false
      };
    },
    [`${ActionTypes.ITEMS_SET}_REJECTED`]: state => ({
      ...state,
      isLoadingItems: false
    }),
    [ActionTypes.ITEMS_UPDATE]: (state, action) => {
      const data = action.payload;
      // 親・子項目の選択状態を取得
      const selected = loadActiveMenu(
        data.pathname,
        data.corpIndustyKbn,
        data.corpInfo.diffflg,
        data.corpInfo.asrf
      );

      const selectedItems = updateItems(
        state.items,
        selected,
        state.isAllClose
      );

      return {
        ...state,
        items: selectedItems,
        activeChild: selected.activeChild
      };
    },
    [ActionTypes.PARENT_TOGGLE]: (state, action) => {
      const key = action.payload;
      // 指定キーの項目の開閉をトグル
      const newItems = state.items.map(item => {
        if (item.key !== key) return item;
        return { ...item, selected: !item.selected };
      });
      return {
        ...state,
        items: newItems
      };
    },
    [`${ActionTypes.ALL_TOGGLE}_PENDING`]: (state, action) => {
      const { isAllClose } = action.meta;
      // 全項目の開閉を操作
      const newItems = state.items.map(item => {
        return { ...item, selected: isAllClose };
      });
      return {
        ...state,
        items: newItems,
        isAllClose: isAllClose
      };
    },
    [ActionTypes.PARAMS_SET]: (state, action) => {
      return {
        ...state,
        params: action.payload
      };
    }
  },
  initialState
);

export const actionCreators = {
  getOpenMenuFlg: createAction(ActionTypes.OPEN_MENU_FLG_GET, () =>
    request(819, { contentId: "userinfo" })
  ),
  // 表示項目の設定
  setItems: createAction(
    ActionTypes.ITEMS_SET,
    params => request(815, params),
    (params, info) => ({
      ...info,
      corpIndustyKbn: params.corpIndustyKbn
    })
  ),
  updateItems: createAction(ActionTypes.ITEMS_UPDATE),
  // 親メニューの開閉
  toggleParent: createAction(ActionTypes.PARENT_TOGGLE),
  // 全ての開閉
  toggleAll: createAction(
    ActionTypes.ALL_TOGGLE,
    // すべて開閉状態をサーバに保存
    params => request(23, params),
    (_, isAllClose) => ({ isAllClose })
  )
};
