import {
  BinderInfo,
  CategoryInfo,
  Article,
  ExternalPage,
  Corporation,
  Statistic,
  Industry
} from "models/BinderItem";
import { FileDownload } from "models/FileDownload";

export const setBinderInfo = (binderInfo: BinderInfo) => ({
  type: "binders/item/SET_BINDER_INFO" as const,
  payload: binderInfo
});

export const setBinderCategoryInfo = (categoryInfoList: CategoryInfo[]) => ({
  type: "binders/item/SET_BINDER_CATEGORY_INFO" as const,
  payload: categoryInfoList
});

export const setArticles = (articles: Article[]) => ({
  type: "binders/item/SET_ARTICLES" as const,
  payload: articles
});

export const setCorporations = (corporations: Corporation[]) => ({
  type: "binders/item/SET_CORPORATIONS" as const,
  payload: corporations
});

export const setStatistics = (statistics: Statistic[]) => ({
  type: "binders/item/SET_STATISTICS" as const,
  payload: statistics
});

export const setIndustries = (industries: Industry[]) => ({
  type: "binders/item/SET_INDUSTRIES" as const,
  payload: industries
});

export const setSaveArticles = (saveArticles: Article[]) => ({
  type: "binders/item/SET_SAVE_ARTICLES" as const,
  payload: saveArticles
});

export const setExternalPages = (externalPages: ExternalPage[]) => ({
  type: "binders/item/SET_EXTERNAL_PAGES" as const,
  payload: externalPages
});

export const setCorporationDownload = (fileDownload: FileDownload) => ({
  type: "binders/item/SET_CORPORATION_DOWNLOAD" as const,
  payload: fileDownload
});

export const setCorporationMemo = (
  cardId: string,
  memo: Corporation["memo"]
) => ({
  type: "binders/item/SET_CORPORATION_MEMO" as const,
  payload: {
    cardId,
    memo
  }
});

export const setIsLoading = (isLoading: { fetchBinderInfo?: boolean }) => ({
  type: "binders/item/SET_IS_LOADING" as const,
  payload: {
    isLoading
  }
});

export const changeIsAdding = (isAdding: boolean) => ({
  type: "binders/item/CHANGE_ISADDING" as const,
  payload: {
    isAdding
  }
});

export const setArticleOffset = (articleOffset: number) => ({
  type: "binders/item/SET_ARTICLE_OFFSET" as const,
  payload: {
    articleOffset
  }
});

export const setArticleLimit = (articleLimit: number) => ({
  type: "binders/item/SET_ARTICLE_LIMIT" as const,
  payload: {
    articleLimit
  }
});

export const addArticles = (articles: Article[]) => ({
  type: "binders/item/ADD_ARTICLES" as const,
  payload: {
    articles
  }
});

export type BindersAction = ReturnType<
  | typeof setBinderInfo
  | typeof setBinderCategoryInfo
  | typeof setArticles
  | typeof setCorporations
  | typeof setStatistics
  | typeof setIndustries
  | typeof setSaveArticles
  | typeof setExternalPages
  | typeof setCorporationDownload
  | typeof setCorporationMemo
  | typeof setIsLoading
  | typeof changeIsAdding
  | typeof setArticleOffset
  | typeof setArticleLimit
  | typeof addArticles
>;
