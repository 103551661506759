// システム設定

// 初期表示
export const INIT = "setting/personal/system/INIT";
// 選択項目を更新
export const UPDATE = "setting/personal/system/UPDATE";
// 設定を保存
export const SAVE = "setting/personal/system/SAVE";

// 従量課金表示の設定を取得
export const PAYMENT_CONFIRM_FLG_GET =
  "setting/personal/system/PAYMENT_CONFIRM_FLG_GET";
// 従量課金表示の設定を更新
export const PAYMENT_CONFIRM_FLG_UPDATE =
  "setting/personal/system/PAYMENT_CONFIRM_FLG_UPDATE";
// 従量課金表示の設定を保存
export const PAYMENT_CONFIRM_FLG_SAVE =
  "setting/personal/system/PAYMENT_CONFIRM_FLG_SAVE";
// ステートのクリア
export const CLEAR = "setting/personal/system/CLEAR";
