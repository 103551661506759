import { BinderItem, BinderActionSet } from "models/BinderItem";

export const setPersonalBinderItems = (binderItems: BinderItem[]) => ({
  type: "binders/list/SET_PERSONAL_BINDER_ITEMS" as const,
  payload: {
    binderItems
  }
});

export const addPersonalBinderItems = (binderItems: BinderItem[]) => ({
  type: "binders/list/ADD_PERSONAL_BINDER_ITEMS" as const,
  payload: {
    binderItems
  }
});

export const addNikkeiBinderItems = (binderItems: BinderItem[]) => ({
  type: "binders/list/ADD_NIKKEI_BINDER_ITEMS" as const,
  payload: {
    binderItems
  }
});

export const removePersonalBinderItems = () => ({
  type: "binders/list/REMOVE_PERSONAL_BINDER_ITEMS" as const
});

export const setNikkeiBinderItems = (binderItems: BinderItem[]) => ({
  type: "binders/list/SET_NIKKEI_BINDER_ITEMS" as const,
  payload: {
    binderItems
  }
});

export const setBinderActionValue = (binderActionValue: BinderActionSet) => ({
  type: "binders/list/SET_BINDER_ACTION_VALUE" as const,
  payload: {
    binderActionValue
  }
});

export const resetBinderActionValue = () => ({
  type: "binders/list/RESET_BINDER_ACTION_VALUE" as const
});

export const setBinderOpenFlgValue = (isBinderOpenFlg: boolean) => ({
  type: "binders/list/SET_BINDER_OPEN_FLG_VALUE" as const,
  payload: {
    isBinderOpenFlg
  }
});

export const changeIsLoading = (isLoadingFlg: boolean) => ({
  type: "binders/list/CHANGE_ISLOADING" as const,
  payload: {
    isLoadingFlg
  }
});

export const changeIsAdding = (isAdding: boolean) => ({
  type: "binders/list/CHANGE_ISADDING" as const,
  payload: {
    isAdding
  }
});

export const setPersonalTotalCount = (totalCount: number) => ({
  type: "binders/list/SET_PERSONAL_TOTAL_COUNT" as const,
  payload: {
    totalCount
  }
});

export const setNikkeiTotalCount = (totalCount: number) => ({
  type: "binders/list/SET_NIKKEI_TOTAL_COUNT" as const,
  payload: {
    totalCount
  }
});

export const setNikkeiOffset = (nikkeiOffset: number) => ({
  type: "binders/list/SET_NIKKEI_OFFSET" as const,
  payload: {
    nikkeiOffset
  }
});

export const setNikkeiLimit = (nikkeiLimit: number) => ({
  type: "binders/list/SET_NIKKEI_LIMIT" as const,
  payload: {
    nikkeiLimit
  }
});

export const setNikkeiSortValue = (nikkeiSortValue: string) => ({
  type: "binders/list/SET_NIKKEI_SORT_VALUE" as const,
  payload: {
    nikkeiSortValue
  }
});

export const setPersonalOffset = (personalOffset: number) => ({
  type: "binders/list/SET_PERSONAL_OFFSET" as const,
  payload: {
    personalOffset
  }
});

export const setPersonalLimit = (personalLimit: number) => ({
  type: "binders/list/SET_PERSONAL_LIMIT" as const,
  payload: {
    personalLimit
  }
});

export const setPersonalSortValue = (personalSortValue: string) => ({
  type: "binders/list/SET_PERSONAL_SORT_VALUE" as const,
  payload: {
    personalSortValue
  }
});

export type BindersAction = ReturnType<
  | typeof setPersonalBinderItems
  | typeof setPersonalTotalCount
  | typeof addPersonalBinderItems
  | typeof addNikkeiBinderItems
  | typeof removePersonalBinderItems
  | typeof setNikkeiBinderItems
  | typeof setBinderActionValue
  | typeof resetBinderActionValue
  | typeof setBinderOpenFlgValue
  | typeof changeIsLoading
  | typeof changeIsAdding
  | typeof setNikkeiTotalCount
  | typeof setNikkeiOffset
  | typeof setNikkeiLimit
  | typeof setNikkeiSortValue
  | typeof setPersonalOffset
  | typeof setPersonalLimit
  | typeof setPersonalSortValue
>;
