import { Reducer } from "redux";
import produce from "immer";
import { AuthSearchTabAction } from "./actions";
import { AuthTabList } from "models/Auth";

interface AuthSearchTabState {
  auth?: AuthTabList;
}

const initialState: AuthSearchTabState = {
  auth: undefined
};

export const authSearchTab: Reducer<AuthSearchTabState, AuthSearchTabAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "auth/seachTab/SET_AUTH":
      return produce(state, draft => {
        draft.auth = action.payload;
      });
    default:
      return state;
  }
};
