// 初期表示
export const PAGE_INIT = "industry/search/PAGE_INIT";
// 子ノード取得
export const CHILDREN_GET = "industry/search/CHILDREN_GET";
// 検索:大分類
export const PARENT_SEARCH = "industry/search/PARENT_SEARCH";
// 検索:中分類以下
export const SEARCH = "industry/search/SEARCH";
// ダウンロード
export const DOWNLOAD_EXCEL = "industry/search/DOWNLOAD_EXCEL";
// ダウンロード
export const DOWNLOAD_CSV = "industry/search/DOWNLOAD_CSV";
// TREEテーブルスクロールフラグ有効化
export const NEED_SCROLL_FLAG_SET = "industry/search/NEED_SCROLL_FLAG_SET";
// TREEテーブルスクロールフラグ無効化
export const NEED_SCROLL_FLAG_CLEAR = "industry/search/NEED_SCROLL_FLAG_CLEAR";
// 業界ツリー検索完了
export const TREE_LOADED = "industry/search/TREE_LOADED";
// keyword保存
export const KEYWORD_SET = "industry/search/KEYWORD_SET";
// 状態をすべてクリア
export const CLEAR_STATE = "industry/search/CLEAR_STATE";
