import { Reducer } from "redux";
import produce from "immer";
import { EsgAction } from "./actions";

import {
  NeedsMstL,
  NeedsMstM,
  NeedsMstS,
  AddInDisplayItemIds,
  Industry,
  DisplayItemIds,
  Category
} from "models/Esg";

interface EsgState {
  data: AddInDisplayItemIds;
  condition: ConditionState;
  environment: any;
  social: any;
  governance: any;
  resultLoading: {
    environment: boolean;
    social: boolean;
    governance: boolean;
  };
  selectedTabKey: string | null;
  industryId: string;
  selectedIndustry: Industry[];
  resultData: {
    needsListStr: string;
    nkCodeListStr: string;
    screeningIdAndConditionsListStr: string;
    screeningIdListStr: string;
  };
  downloadParams: {
    contentId: string; // ESG用のcontentIDを設定
    corpIndustyCode: string;
    corpIndustyKbn: string;
    corpPlaceMarket: string;
    currentNkCodeList: string;
    dispItemListDownLoad: string;
    excelCsvRadioType: string; // 直近ダウンロード用の指定
    expectCheckDownLoad: string;
    hasReachedMaxDisplayableNkcodeCount: boolean;
    itemsPerPage: string;
    nkcodeCount: string;
    noCorpType: string;
    originalNkCodeList: string;
    pageIndex: string;
    rootDivWidth: string;
    screeningId: string;
    screeningIdAndConditions: null;
    tabIdDownLoad: string;
    toolsearchAccCondition: string;
    toolsearchCurrencyCondition: string;
    toolsearchKeyword: null;
    toolsearchScCondition: string;
    toolsearchYtCondition: string;
    userSetKbn: string;
    unlistedAfricaCheckbox: string;
    unlistedAfricaCorpCount: string;
    unlistedAsiaCheckbox: string;
    unlistedAsiaCorpCount: string;
    unlistedCorpCheckbox: string;
    unlistedCorpCount: string;
    unlistedEuropeCheckbox: string;
    unlistedEuropeCorpCount: string;
    unlistedHolderCorpCheckbox: string;
    unlistedHolderCorpCount: string;
    unlistedMideastCheckbox: string;
    unlistedMideastCorpCount: string;
    unlistedNorthAmericaCheckbox: string;
    unlistedNorthAmericaCorpCount: string;
    unlistedSouthAmericaCheckbox: string;
    unlistedSouthAmericaCorpCount: string;
    unlistedTdbCorpCheckbox: string;
    unlistedTdbCorpCount: string;
  };
  selectedKey: {
    environment?: keyof DisplayItemIds["themeEnvironment"];
    social?: keyof DisplayItemIds["themeSociety"];
    governance?: keyof DisplayItemIds["themeGovernance"];
  };
  selectedTab?: Category;
}

export interface ConditionState {
  // 業界大分類
  needsMstL: NeedsMstL[];
  // 業界中分類
  needsMstM: NeedsMstM[];
  // 業界小分類
  needsMstS: NeedsMstS[];
  isLoadingNeedsMstL: boolean;
  isLoadingNeedsMstM: boolean;
  isLoadingNeedsMstS: boolean;
}

const initialState: EsgState = {
  data: {
    themeEnvironment: {
      displayItemAirEmissions: "",
      displayItemWasteWater: "",
      displayItemEnergy: "",
      displayItemWaste: "",
      displayItemBiodiversity: "",
      displayItemEnvironmentalCompliance: "",
      displayItemCo2Emissions: ""
    },
    themeSociety: {
      displayItemEaseOfWorks: "",
      displayItemEmployeeSituation: "",
      displayItemHumanRightAssessment: "",
      displayItemChildLabor: "",
      displayItemForcedLabor: "",
      displayItemNonDiscrimination: "",
      displayItemOccupationalSafety: ""
    },
    themeGovernance: {
      displayItemOfficer: "",
      displayItemStockOption: "",
      displayItemGeneralDisclosure: "",
      displayItemCustomerPrivacy: "",
      displayItemSustainability: ""
    },
    dlcsvpath: "",
    dlcsv: "",
    dlxls: "",
    dlxlspath: ""
  },
  condition: {
    needsMstL: [], // 業界大分類
    needsMstM: [], // 業界中分類
    needsMstS: [], // 業界小分類
    isLoadingNeedsMstL: false,
    isLoadingNeedsMstM: false,
    isLoadingNeedsMstS: false
  },
  environment: undefined,
  social: undefined,
  governance: undefined,
  resultLoading: {
    environment: true,
    social: true,
    governance: true
  },
  industryId: "",
  selectedIndustry: [
    {
      needsId: ["04", "041", "0261"],
      needsName: "輸送機器 > 自動車 > 自動車（乗用車・一般）",
      selectedIndustry: "s",
      selectedNeedsId: "0261",
      selectedNeedsName: "自動車（乗用車・一般）"
    }
  ],
  // 自動車（乗用車・一般）の初期値
  resultData: {
    // スクリーニングでNEEDS業種分類の小分類を選択した際と同じものを設定
    needsListStr: "SCR000122\u001d04、041、0261、1@",
    // 会社コード
    nkCodeListStr: "0001351,0001353,0001389,0001392,0001393,0001394,0018962,",
    // スクリーニング項目ID&検索条件リスト文字列
    screeningIdAndConditionsListStr: "SCR000122\u001d04、041、0261、1\u001c",
    // スクリーニング項目IDリスト文字列
    screeningIdListStr: "SCR000122"
  },
  selectedTabKey: null,
  downloadParams: {
    contentId: "",
    corpIndustyCode: "",
    corpIndustyKbn: "",
    corpPlaceMarket: "",
    currentNkCodeList: "",
    dispItemListDownLoad: "",
    excelCsvRadioType: "",
    expectCheckDownLoad: "",
    hasReachedMaxDisplayableNkcodeCount: false,
    itemsPerPage: "",
    nkcodeCount: "",
    noCorpType: "",
    originalNkCodeList: "",
    pageIndex: "",
    rootDivWidth: "",
    screeningId: "",
    screeningIdAndConditions: null,
    tabIdDownLoad: "",
    toolsearchAccCondition: "",
    toolsearchCurrencyCondition: "",
    toolsearchKeyword: null,
    toolsearchScCondition: "",
    toolsearchYtCondition: "",
    userSetKbn: "",
    unlistedAfricaCheckbox: "0",
    unlistedAfricaCorpCount: "0",
    unlistedAsiaCheckbox: "0",
    unlistedAsiaCorpCount: "0",
    unlistedCorpCheckbox: "0",
    unlistedCorpCount: "0",
    unlistedEuropeCheckbox: "0",
    unlistedEuropeCorpCount: "0",
    unlistedHolderCorpCheckbox: "0",
    unlistedHolderCorpCount: "0",
    unlistedMideastCheckbox: "0",
    unlistedMideastCorpCount: "0",
    unlistedNorthAmericaCheckbox: "0",
    unlistedNorthAmericaCorpCount: "0",
    unlistedSouthAmericaCheckbox: "0",
    unlistedSouthAmericaCorpCount: "0",
    unlistedTdbCorpCheckbox: "0",
    unlistedTdbCorpCount: "0"
  },
  selectedKey: {
    environment: undefined,
    social: undefined,
    governance: undefined
  },
  selectedTab: undefined
};

export const toolEsgResult: Reducer<EsgState, EsgAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "tool/esg/result/INIT_PAGE":
      return produce(state, draft => {
        draft.data = action.payload;
      });
    case "tool/esg/result/SET_NEED_MST_L":
      return produce(state, draft => {
        draft.condition.needsMstL = action.payload;
      });
    case "tool/esg/result/SET_NEED_MST_M":
      return produce(state, draft => {
        draft.condition.needsMstM = action.payload;
      });
    case "tool/esg/result/SET_NEED_MST_S":
      return produce(state, draft => {
        draft.condition.needsMstS = action.payload;
      });
    case "tool/esg/result/CLEAR_NEED_MST":
      return produce(state, draft => {
        draft.condition = initialState.condition;
      });
    case "tool/esg/result/CLEAR_NEED_MST_S":
      return produce(state, draft => {
        draft.condition.needsMstS = initialState.condition.needsMstS;
      });
    case "tool/esg/result/SET_COMPARISON_SEARCH_ENVIRONMENT_DATA":
      return produce(state, draft => {
        draft.environment = action.payload;
      });
    case "tool/esg/result/SET_COMPARISON_SEARCH_SOCIAL_DATA":
      return produce(state, draft => {
        draft.social = action.payload;
      });
    case "tool/esg/result/SET_COMPARISON_SEARCH_GOVERNANCE_DATA":
      return produce(state, draft => {
        draft.governance = action.payload;
      });
    case "tool/esg/result/CLEAR_COMPARISON_SEARCH_DATA":
      return produce(state, draft => {
        draft.environment = null;
        draft.social = null;
        draft.governance = null;
      });
    case "tool/esg/result/SET_INDUSTRY_ID":
      return produce(state, draft => {
        draft.industryId = action.payload;
      });
    case "tool/esg/result/SET_SELECTED_INDUSTRY":
      return produce(state, draft => {
        draft.selectedIndustry = action.payload;
      });
    case "tool/esg/result/SET_TOOL_ESG_RESULT":
      return produce(state, draft => {
        draft.resultData = action.payload;
      });
    case "tool/esg/result/SET_IS_LOADING":
      return produce(state, draft => {
        draft.resultLoading[action.payload.type] = action.payload.isLoading;
      });
    case "tool/esg/result/SET_NK_CODE_LIST_STR":
      return produce(state, draft => {
        draft.resultData.nkCodeListStr = action.payload;
      });
    case "tool/esg/result/SET_IS_LOADING_NEEDS_MST":
      return produce(state, draft => {
        switch (action.payload.type) {
          case "L":
            draft.condition.isLoadingNeedsMstL = action.payload.isLoading;
            break;
          case "M":
            draft.condition.isLoadingNeedsMstM = action.payload.isLoading;
            break;
          case "S":
            draft.condition.isLoadingNeedsMstS = action.payload.isLoading;
            break;
        }
      });
    case "tool/esg/result/SET_SELECTED_ENVIRONMENT_KEY":
      return produce(state, draft => {
        draft.selectedKey = {
          environment: action.payload.key,
          social: undefined,
          governance: undefined
        };
      });
    case "tool/esg/result/SET_SELECTED_SOCIAL_KEY":
      return produce(state, draft => {
        draft.selectedKey = {
          environment: undefined,
          social: action.payload.key,
          governance: undefined
        };
      });
    case "tool/esg/result/SET_SELECTED_GOVERNANCE_KEY":
      return produce(state, draft => {
        draft.selectedKey = {
          environment: undefined,
          social: undefined,
          governance: action.payload.key
        };
      });
    case "tool/esg/result/SET_SELECTED_TAB":
      return produce(state, draft => {
        draft.selectedTab = action.payload.key;
      });
    default:
      return state;
  }
};
