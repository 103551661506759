import { useDispatch } from "react-redux";
import { actionCreators as sourceSelectorActionCreators } from "modules/common/SourceSelector";
import { actionCreators as screeningCorpItemResultActionCreators } from "modules/tool/screening/corp/legacyItem/result-data";
import { actionCreators as screeningCorpScenarioResultActionCreators } from "modules/tool/screening/corp/scenario/result-data";
import { actionCreators as screeningSegmentBusinessResultActionCreators } from "modules/tool/screening/segment/business/result-data";
import { actionCreators as screeningSegmentAreaResultActionCreators } from "modules/tool/screening/segment/area/result-data";
import { actionCreators as screeningIndustryResultActionCreators } from "modules/tool/screening/industry/result-data";
import { actionCreators as comparisonCorpResultActionCreators } from "modules/tool/comparison/corp/result-data";
import { actionCreators as comparisonIndustryResultActionCreators } from "modules/tool/comparison/industry/result-data";
import { clearTab as clearReportTab } from "modules/article/report/tab/actions";
import { clearAuth } from "modules/auth/saved/actions";
import { clearHistory } from "modules/operandRawSearch/actions";
import { clearCardViewMode } from "modules/binders/saved/actions";

export const useClearPersist = () => {
  const dispatch = useDispatch();

  const clearMainPersist = () => {
    dispatch(sourceSelectorActionCreators.clearSelection());
    dispatch(screeningCorpItemResultActionCreators.clearResultData());
    dispatch(screeningCorpScenarioResultActionCreators.clearResultData());
    dispatch(screeningSegmentBusinessResultActionCreators.clearResultData());
    dispatch(screeningSegmentAreaResultActionCreators.clearResultData());
    dispatch(screeningIndustryResultActionCreators.clearResultData());
    dispatch(comparisonCorpResultActionCreators.clearResultData());
    dispatch(comparisonIndustryResultActionCreators.clearResultData());
    dispatch(clearReportTab());
    dispatch(clearAuth());
  };

  const clearUserPersist = () => {
    dispatch(clearCardViewMode());
    dispatch(clearHistory());
  };

  return {
    clearMainPersist,
    clearUserPersist
  };
};
