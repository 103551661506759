import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

import {
  isEmpty,
  isNotEmptyKeys,
  valueFromKeyOrDefault
} from "utils/helper/common";

const initialState = {
  selectedConditionSourceList: null, // 検索条件のチェックボックス選択状態リスト
  selectedCondition: null, // 登録した条件から保存 で選択中の value 値
  conditionData: {},
  currentScenarioId: "",
  currentScenarioGroup: "",
  scenarioCondition: {},
  initConditionList: [],
  inputConditionList: [],
  tempSaveFavoriteData: {}, // "条件を保存" から保存リクエストする際に必要なパラメータセット
  screeningResult: {},
  isLoadingScreeningResult: false,
  comparisonHead: {},
  isLoadingComparisonHead: false,
  comparisonData: {},
  isLoadingComparisonData: false,
  comparisonConditions: {},
  isLoadingChartCount: false,
  chartData: {},
  isLoadingChartData: false,
  // 絞込条件を追加
  screeningTree: [],
  selectedScreening: [],
  // ユーザ指標編集
  indexTree: [],
  selectedOption: "", // ユーザ指標編集 選択中の保存条件
  parentId: "",
  isAvailableIndex: "",
  selectedIndex: [],
  savedConditionList: [],
  // 比較表データ再取得系
  needsSearch: false, // 再検索フラグ
  init: false,
  // 登録条件系
  savedFavoriteList: [],
  isLoadingSavedFavoriteDetail: false,
  // InputArea モーダル系
  needsMstL: [],
  needsMstM: [],
  needsMstS: [],
  isLoadingNeedsMstL: false,
  isLoadingNeedsMstM: false,
  isLoadingNeedsMstS: false,
  marketList: [],
  fnclList: [],
  addressList: [],
  ntclsList: [],

  // 比較表ファイルダウンロードモーダル系
  dlPeriodList: [],
  dlPeriodFrom: "",
  dlPeriodTo: "",
  error: null,
  timestamp: null
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => {
      const conditionData = action.payload;
      const { baseSourceItems } = action.meta;
      // 初期の currentScenarioId を取得
      let currentScenarioId = state.currentScenarioId;
      if (
        isEmpty(currentScenarioId) &&
        isNotEmptyKeys(conditionData, [
          "screeningScenarioDto.scenarioDetailList"
        ]) &&
        !isEmpty(
          conditionData.screeningScenarioDto.scenarioDetailList[0].scenarioId
        )
      ) {
        currentScenarioId =
          conditionData.screeningScenarioDto.scenarioDetailList[0].scenarioId;
      }

      // シナリオグループの state が空であればシナリオ一覧の１番最初の項目のグループをセットする
      let currentScenarioGroup = state.currentScenarioGroup;
      if (
        isEmpty(currentScenarioGroup) &&
        isNotEmptyKeys(conditionData, [
          "screeningScenarioDto.scenarioDetailList"
        ]) &&
        !isEmpty(
          conditionData.screeningScenarioDto.scenarioDetailList[0].sceGroup1
        )
      ) {
        currentScenarioGroup =
          conditionData.screeningScenarioDto.scenarioDetailList[0].sceGroup1;
      }
      // チェックボックスの初期チェック状態を取得
      // サーバ側のチェック状態(conditionData.userCorpFilterScr)は使用しない
      const newSelectedConditionSourceList = baseSourceItems
        .filter(
          item =>
            item.value != "4" &&
            item.value !== "14" &&
            item.active &&
            item.selected
        )
        .map(item => item.value);
      return {
        ...state,
        selectedConditionSourceList: newSelectedConditionSourceList,
        // 保存済みのデータが既に存在すれば、それを優先する
        selectedCondition:
          state.selectedCondition || conditionData.userCondition,
        conditionData: conditionData,
        currentScenarioId: currentScenarioId,
        currentScenarioGroup: currentScenarioGroup
      };
    },
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.CONDITION_SOURCE_LIST_UPDATE]: (state, action) => ({
      ...state,
      selectedConditionSourceList: action.payload
    }),
    [`${ActionTypes.INIT_CONDITION_LIST_ITEM_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const item = action.payload;
      // 表示している画面のtimestampとrequest時のtimestampを比較
      if (state.timestamp !== action.meta.timestamp) {
        return state;
      }
      let condition = {};
      if (!isEmpty(item) && !isEmpty(item.toolCommon06DtoList)) {
        condition = item.toolCommon06DtoList[0];
      }
      return {
        ...state,
        initConditionList: [...state.initConditionList, { ...condition }],
        // 絞込条件にも事前に入れておく
        inputConditionList: [...state.inputConditionList, { ...condition }]
      };
    },
    [`${ActionTypes.INIT_CONDITION_LIST_ITEM_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      initConditionList: [...state.initConditionList, {}],
      error: action.payload
    }),
    [ActionTypes.CURRENT_SCENARIO_ID_UPDATE]: (state, action) => ({
      ...state,
      currentScenarioId: action.payload
    }),
    [ActionTypes.CURRENT_SCENARIO_GROUP_UPDATE]: (state, action) => ({
      ...state,
      currentScenarioGroup: action.payload
    }),
    [`${ActionTypes.SCENARIO_CONDITION_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      scenarioCondition: {
        ...action.payload,
        scenarioId: action.meta.scenarioId,
        scenarioGroup: action.meta.scenarioGroup
      }
    }),
    [`${ActionTypes.SCENARIO_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.SCENARIO_CONDITION_RESELECT]: state => ({
      ...state,
      scenarioCondition: {
        ...state.scenarioCondition,
        canceled: false
      }
    }),
    [ActionTypes.SCENARIO_COND_LIST_UPDATE]: (state, action) => ({
      ...state,
      scenarioCondition: {
        ...state.scenarioCondition,
        scenarioCondList: action.payload
      }
    }),
    [ActionTypes.SCENARIO_CONDITION_CANCEL]: state => ({
      ...state,
      scenarioCondition: {
        ...state.scenarioCondition,
        canceled: true
      }
    }),
    [ActionTypes.SELECTED_SCENARIO_DATAS_RESET]: state => {
      // 選択中のシナリオ で入力中のデータをクリア
      const newScenarioCondList = state.scenarioCondition.scenarioCondList.map(
        condition => {
          delete condition.inputtedData;
          return condition;
        }
      );
      // 絞込条件を初期リストに変更
      const newInputConditionList = state.initConditionList.map(condition => ({
        ...condition
      }));
      return {
        ...state,
        scenarioCondition: {
          ...state.scenarioCondition,
          scenarioCondList: newScenarioCondList
        },
        inputConditionList: newInputConditionList
      };
    },
    [`${ActionTypes.FAVORITE_CHECK}_FULFILLED`]: (state, action) => ({
      ...state,
      tempSaveFavoriteData: action.meta
    }),
    [`${ActionTypes.FAVORITE_SAVE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 保存した条件が選択中の条件になるよう更新
      selectedCondition: action.payload.selectedCondition,
      // 登録した条件から選択 セレクトリストの更新
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      tempSaveFavoriteData: {}
    }),
    [ActionTypes.SAVE_FAVORITE_CANCEL]: state => ({
      ...state,
      tempSaveFavoriteData: {} // 一時データを削除する
    }),
    [ActionTypes.INPUT_CONDITION_REMOVE]: (state, action) => {
      const newInputConditionList = state.inputConditionList.slice();
      const targetIndex = newInputConditionList.findIndex(
        cond => cond.screeningId === action.payload
      );
      if (targetIndex >= 0) {
        newInputConditionList.splice(targetIndex, 1);
      }
      return {
        ...state,
        inputConditionList: newInputConditionList
      };
    },
    [ActionTypes.INPUT_CONDITION_LIST_REPLACE]: (state, action) => ({
      ...state,
      inputConditionList: action.payload
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_PENDING`]: state => ({
      ...state,
      screeningResult: {},
      comparisonData: {}, // クリア
      isLoadingScreeningResult: true
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        comparisonConditions: {
          toolsearchKeyword: action.meta.toolsearchKeyword,
          screeningIdAndConditions: action.meta.screeningIdAndConditions,
          needs: action.payload.needsListStr
        }, // 比較表リクエスト時に必要なパラメータを保持、入力状態をクリア
        screeningResult: action.payload,
        isLoadingScreeningResult: false
      };
    },
    [`${ActionTypes.SCREENING_RESULT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      screeningResult: {},
      comparisonConditions: {}, // 選択、入力状態をクリア
      isLoadingScreeningResult: false,
      error: action.payload
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonHead: true
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_FULFILLED`]: (state, action) => {
      const comparisonHead = action.payload;
      // meta で渡された sourceSelectConditions 企業主別チェック状態を反映
      let newComparisonConditions = { ...state.comparisonConditions };
      if (!isEmpty(action.meta.sourceSelectConditions)) {
        newComparisonConditions = {
          ...newComparisonConditions,
          ...action.meta.sourceSelectConditions
        };
      }
      const {
        userIndexGroupListOfBasic,
        userIndexGroupListOfBusiness,
        userIndexGroupListOfUserSet
      } = comparisonHead.userIndexGroupListDto;
      let userIndexValue;
      if (
        !isEmpty(action.meta.dispItemListInitValue) &&
        userIndexGroupListOfUserSet.find(
          index => index.value == action.meta.dispItemListInitValue
        )
      ) {
        // 選択中のユーザ指標
        userIndexValue = action.meta.dispItemListInitValue;
      } else if (!isEmpty(action.meta.nowEditItem)) {
        // ユーザ指標編集で選択されたアイテムのラベル
        userIndexValue = userIndexGroupListOfUserSet.find(
          index => index.label == action.meta.nowEditItem
        ).value;
      } else {
        // デフォルト値
        userIndexValue = !isEmpty(userIndexGroupListOfBusiness)
          ? userIndexGroupListOfBusiness[0].value
          : userIndexGroupListOfBasic[0].value;
      }
      newComparisonConditions["userIndexValue"] = userIndexValue;
      newComparisonConditions["accKbnValue"] =
        comparisonHead.accKbnList[0].value;
      newComparisonConditions["ytConditionValue"] =
        comparisonHead.ytCommonConditionList[0].value;
      newComparisonConditions["scConditionValue"] =
        comparisonHead.scCommonConditionList[0].value;
      newComparisonConditions["currencyConditionValue"] =
        comparisonHead.currencyList[0].value;
      newComparisonConditions["ltmValue"] = "0";
      newComparisonConditions["expectValue"] = "0";
      return {
        ...state,
        comparisonHead: comparisonHead,
        comparisonConditions: newComparisonConditions,
        isLoadingComparisonHead: false
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonHead: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true,
      init: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_FULFILLED`]: (state, action) => {
      const isCheckboxesActive =
        action.payload.selectItemDisabled == "1" ||
        action.payload.selectItemDisabled == "3";
      return {
        ...state,
        comparisonData: action.payload,
        comparisonConditions: {
          ...state.comparisonConditions,
          // チェックボックス群が無効になった場合、選択状態解除
          ltmValue: isCheckboxesActive
            ? state.comparisonConditions.ltmValue
            : "0",
          expectValue: isCheckboxesActive
            ? state.comparisonConditions.expectValue
            : "0"
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_FULFILLED`]: (state, action) => {
      const sortData = action.payload;
      return {
        ...state,
        comparisonData: {
          ...state.comparisonData,
          maxWidthOfAllColumns: sortData.maxWidthOfAllColumns,
          tableData: sortData.tableData
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_SORT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_FULFILLED`]: (
      state,
      action
    ) => {
      const sortData = action.payload;
      return {
        ...state,
        comparisonData: {
          ...state.comparisonData,
          // 必要なデータだけマージする
          africaNkcodeCount: sortData.africaNkcodeCount,
          africaNkcodeTotalCount: sortData.africaNkcodeTotalCount,
          asiaPacificNkcodeCount: sortData.asiaPacificNkcodeCount,
          asiaPacificNkcodeTotalCount: sortData.asiaPacificNkcodeTotalCount,
          currentNkCodeList: sortData.currentNkCodeList,
          errorMessage: sortData.errorMessage,
          europeNkcodeCount: sortData.europeNkcodeCount,
          europeNkcodeTotalCount: sortData.europeNkcodeTotalCount,
          hasReachedMaxDisplayableNkcodeCount:
            sortData.hasReachedMaxDisplayableNkcodeCount,
          industrycorptop: sortData.industrycorptop,
          listingNkcodeCount: sortData.listingNkcodeCount,
          listingNkcodeTotalCount: sortData.listingNkcodeTotalCount,
          maxDisplayableNkcodeCount: sortData.maxDisplayableNkcodeCount,
          maxWidthOfAllColumns: sortData.maxWidthOfAllColumns,
          messageDto: sortData.messageDto,
          mideastNkcodeCount: sortData.mideastNkcodeCount,
          mideastNkcodeTotalCount: sortData.mideastNkcodeTotalCount,
          nkCodeListTotalList: sortData.nkCodeListTotalList,
          nkCodeShowMaxSize: sortData.nkCodeShowMaxSize,
          nkcodeCountForShow: sortData.nkcodeCountForShow,
          northAmericaNkcodeCount: sortData.northAmericaNkcodeCount,
          northAmericaNkcodeTotalCount: sortData.northAmericaNkcodeTotalCount,
          sortFlag: sortData.sortFlag,
          sortID: sortData.sortID,
          sortedNkCodeList: sortData.sortedNkCodeList,
          southAmericaNkcodeCount: sortData.southAmericaNkcodeCount,
          southAmericaNkcodeTotalCount: sortData.southAmericaNkcodeTotalCount,
          tableData: sortData.tableData,
          unListedHolderNkcodeCount: sortData.unListedHolderNkcodeCount,
          unListedHolderNkcodeTotalCount:
            sortData.unListedHolderNkcodeTotalCount,
          unListedNkcodeCount: sortData.unListedNkcodeCount,
          unListedNkcodeTotalCount: sortData.unListedNkcodeTotalCount,
          unListedTdbNkcodeCount: sortData.unListedTdbNkcodeCount,
          unListedTdbNkcodeTotalCount: sortData.unListedTdbNkcodeTotalCount,
          unlistedNorthAmericaNkcodeTotalCount:
            sortData.unlistedNorthAmericaNkcodeTotalCount,
          unlistedSouthAmericaNkcodeTotalCount:
            sortData.unlistedSouthAmericaNkcodeTotalCount,
          unlistedEuropeNkcodeTotalCount:
            sortData.unlistedEuropeNkcodeTotalCount,
          unlistedAsiaPacificNkcodeTotalCount:
            sortData.unlistedAsiaPacificNkcodeTotalCount,
          unlistedMideastNkcodeTotalCount:
            sortData.unlistedMideastNkcodeTotalCount,
          unlistedAfricaNkcodeTotalCount:
            sortData.unlistedAfricaNkcodeTotalCount
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.COMPARISON_DATA_CLEAR]: state => ({
      ...state,
      screeningResult: {},
      comparisonData: {}, // クリア
      comparisonConditions: {} // 選択、入力状態をクリア
    }),
    [ActionTypes.COMPARISON_CONDITIONS_UPDATE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [`${ActionTypes.CHART_COUNT_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartCount: true
    }),
    [`${ActionTypes.CHART_COUNT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData:
        parseInt(action.payload) >= action.meta.nkcodeInfosLength
          ? state.chartData
          : {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_COUNT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      chartData: {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: {
        ...state.chartData,
        chart01Str: action.payload.chart01Str,
        chart02Str: action.payload.chart02Str,
        chart03Str: action.payload.chart03Str,
        chart04Str: action.payload.chart04Str
      },
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [ActionTypes.CHART_DATA_CLEAR]: state => ({
      ...state,
      chartData: null
    }),
    [ActionTypes.RESULT_DATA_APPLY]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [`${ActionTypes.INIT_TREE_MST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      screeningTree: {
        ...action.payload.treeInfoDto,
        treeDataList: action.payload.treeInfoDto.treeDataList.filter(
          // THEME は除く
          data => !data.id.startsWith("THEME")
        )
      }
    }),
    [`${ActionTypes.INIT_TREE_MST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SCREENING_TREE_ROOT_GET}_FULFILLED`]: (state, action) => {
      const newScreeningTree = action.payload;
      const { kensaku } = action.meta;
      // 検索文字列が空でない場合に全て open 状態にする
      if (!isEmpty(kensaku)) {
        const recursiveSearchParent = tree =>
          tree.forEach(node => {
            if (!isEmpty(node.child)) {
              node.childExistFlag = true; // なぜか false になっている時がある
              node.childLoaded = true; // なぜか常に false になっている
              node.isOpen = true;
              recursiveSearchParent(node.child);
            }
          });
        recursiveSearchParent(newScreeningTree.treeDataList);
      }
      return {
        ...state,
        screeningTree: newScreeningTree
      };
    },
    [`${ActionTypes.SCREENING_TREE_ROOT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SCREENING_TREE_CHILD_GET}_FULFILLED`]: (state, action) => {
      // 子を追加したい親ノードのID(meta.parentId)を探して追加
      const { screeningId } = action.meta;
      const parentIds = screeningId.split(",");
      const targetParentId = parentIds[parentIds.length - 1];
      let depth = 0;
      const recursiveSearchParent = tree => {
        for (const key in tree) {
          const node = tree[key];
          if (node.id === parentIds[depth]) {
            if (node.id === targetParentId) {
              node.child = action.payload.treeInfoDto.treeDataList;
              node.childLoaded = true;
              break;
            }
            if (node.childLoaded) {
              depth++;
              recursiveSearchParent(node.child);
            }
          }
        }
      };
      const newScreeningTree = { ...state.screeningTree };
      recursiveSearchParent(newScreeningTree.treeDataList);
      return {
        ...state,
        screeningTree: newScreeningTree
      };
    },
    [`${ActionTypes.SCREENING_TREE_CHILD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SCREENING_AVAILABILITY_GET}_FULFILLED`]: state => state,
    [`${ActionTypes.SCREENING_AVAILABILITY_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SCREENING_ADD}_FULFILLED`]: (state, action) => {
      let newSelectedScreening = state.selectedScreening.slice();
      const screenings = valueFromKeyOrDefault(
        action,
        "payload.toolCommon06DtoList",
        []
      );
      // 同じスクリーニングIDのものは追加しない
      if (!isEmpty(screenings[0])) {
        const newScreeningId = screenings[0].screeningId;
        const matchedData = newSelectedScreening.find(
          data => data.screeningId === newScreeningId
        );
        // （#）グループ項目重複チェック
        const isExistRedundantlyGroupItem = action.meta.getIsExistRedundantlyGroupItem(
          newSelectedScreening,
          newScreeningId
        );
        // inputAreaId が同じアイテムが追加されるパターン考慮
        const newInputAreaId = screenings[0].inputAreaId;
        if (
          !matchedData &&
          (newInputAreaId == 2 ||
            newInputAreaId == 3 ||
            newInputAreaId == 5 ||
            (newInputAreaId == 6 && newScreeningId != "SCR000306") ||
            newInputAreaId == 7)
        ) {
          newSelectedScreening = newSelectedScreening.filter(
            data =>
              data.screeningId == "SCR000306" ||
              data.inputAreaId != newInputAreaId
          );
        }
        if (
          !matchedData &&
          newSelectedScreening.length < action.meta.maxCount &&
          !isExistRedundantlyGroupItem
        ) {
          newSelectedScreening.push(screenings[0]);
        }
      }
      return {
        ...state,
        selectedScreening: newSelectedScreening
      };
    },
    [`${ActionTypes.SCREENING_ADD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.SCREENING_REMOVE]: (state, action) => {
      const newSelectedScreening = [...state.selectedScreening];
      const targetIndex = newSelectedScreening.findIndex(
        item => item.screeningId === action.payload
      );
      if (targetIndex >= 0) {
        newSelectedScreening.splice(targetIndex, 1);
      }
      return {
        ...state,
        selectedScreening: newSelectedScreening
      };
    },
    [ActionTypes.SCREENING_REMOVE_ALL]: state => {
      return {
        ...state,
        screeningTree: {}, // クリア
        selectedScreening: []
      };
    },
    [ActionTypes.INPUT_CONDITION_LIST_UPDATE]: (state, action) => {
      const selectedCondition = action.payload;
      // 新リストに存在しない条件は削除
      const oldScreeningIds = state.inputConditionList.map(
        data => data.screeningId
      );
      const newScreeningIds = selectedCondition.map(data => data.screeningId);
      const newInputConditionList = state.inputConditionList.filter(
        ({ screeningId }) => newScreeningIds.indexOf(screeningId) >= 0
      );
      // 新リストにしか存在しない条件を追加
      selectedCondition.forEach(cond => {
        if (oldScreeningIds.indexOf(cond.screeningId) == -1)
          newInputConditionList.push(cond);
      });
      // ソート
      newInputConditionList.sort((a, b) => {
        const aPos = newScreeningIds.indexOf(a.screeningId);
        const bPos = newScreeningIds.indexOf(b.screeningId);
        return aPos - bPos;
      });
      return {
        ...state,
        inputConditionList: newInputConditionList
      };
    },
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_FULFILLED`]: (state, action) => {
      const newIndexTree = action.payload;
      let selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      if (action.meta.kensakuName !== undefined && selectedIndex.length === 0) {
        selectedIndex = state.selectedIndex;
      }
      if (!isEmpty(action.meta.kensakuName)) {
        // 検索文字列が空でない場合に全て open 状態にする
        const setOpenToAllChildState = treeDataList => {
          return treeDataList.map(item => {
            if (!isEmpty(item.child)) {
              item.childExistFlag = true;
              item.isOpen = true;
              item.childLoaded = true;
              setOpenToAllChildState(item.child);
            }
            return item;
          });
        };
        newIndexTree.treeDataList = setOpenToAllChildState(
          newIndexTree.treeDataList
        );
      }
      return {
        ...state,
        indexTree: newIndexTree,
        selectedOption: isEmpty(state.selectedOption)
          ? action.payload.userSelect[0].value
          : state.selectedOption,
        selectedIndex: selectedIndex
      };
    },
    [`${ActionTypes.INDEX_TREE_ROOT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_FULFILLED`]: (state, action) => {
      const { payload, meta } = action;
      const newIndexTree = { ...state.indexTree };
      let depth = 0;
      const updateTargetChildState = treeDataList => {
        const targetParentItemId = meta.currentTreeIds[depth];
        for (const prop in treeDataList) {
          const item = treeDataList[prop];
          if (item.id === targetParentItemId) {
            depth += 1;
            updateTargetChildState(item.child);
          } else if (!targetParentItemId && item.id === meta.currentId) {
            if (!item.childLoaded) {
              item.child = payload.treeDataList;
              item.childLoaded = true;
              item.isOpen = true;
            } else {
              item.isOpen = !item.isOpen;
            }
          }
        }
      };
      updateTargetChildState(newIndexTree.treeDataList);

      return {
        ...state,
        indexTree: newIndexTree
      };
    },
    [`${ActionTypes.INDEX_TREE_CHILD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_FULFILLED`]: state => state,
    [`${ActionTypes.INDEX_AVAILABILITY_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.INDEX_ADD}_FULFILLED`]: (state, action) => {
      const newSelectedIndex = [...state.selectedIndex];
      // 存在、上限チェック
      const matchedIndex = newSelectedIndex.find(
        index => index.screeningId == action.payload.screeningId
      );
      // （#）重複チェック
      const isExistRedundantlyScreeningId =
        state.selectedIndex.find(
          ({ screeningId }) =>
            action.meta.indexEditorRedundantlyScreeningIds.indexOf(
              screeningId
            ) >= 0
        ) &&
        action.meta.indexEditorRedundantlyScreeningIds.indexOf(
          action.payload.screeningId
        ) >= 0;
      if (
        !matchedIndex &&
        parseInt(state.indexTree.maxCount) > newSelectedIndex.length &&
        !isExistRedundantlyScreeningId
      ) {
        newSelectedIndex.push(action.payload);
      }
      return {
        ...state,
        selectedIndex: newSelectedIndex
      };
    },
    [`${ActionTypes.INDEX_ADD}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_UPDATE]: (state, action) => ({
      ...state,
      selectedIndex: action.payload
    }),
    [ActionTypes.INDEX_REMOVE_ALL]: state => ({
      ...state,
      selectedIndex: [],
      // 選択中の条件も先頭にリセット
      selectedOption: state.indexTree.userSelect[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_SELECT_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      indexTree: {
        ...state.indexTree,
        userSelect: action.payload
      },
      selectedOption: action.payload[0].value
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_DELETE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      savedConditionList: action.payload.userSetDisplayItemList
    }),
    [`${ActionTypes.SAVED_CONDITION_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_SET}_FULFILLED`]: state => ({
      ...state,
      indexTree: {} // クリア
    }),
    [`${ActionTypes.CONDITION_SET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => {
      const selectedIndex = [];
      action.payload.userSetDetailList.forEach(detail => {
        // 通常の項目
        if (!isEmpty(detail.scrGroup1)) {
          selectedIndex.push({ ...detail });
        } else {
          // グループ項目
          const lastPos = selectedIndex.length - 1;
          if (isEmpty(selectedIndex[lastPos].groupList))
            selectedIndex[lastPos].groupList = [];
          // 条件を選択したときと同じデータに整形
          selectedIndex[lastPos].groupList.push(
            `${detail.screeningId}_${detail.hierarchyName}`
          );
        }
      });
      return {
        ...state,
        selectedIndex: selectedIndex,
        selectedOption: action.meta.userSetId
      };
    },
    [`${ActionTypes.SAVED_CONDITION_DETAIL_GET}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.INDEX_ALL_CLEAR]: state => ({
      ...state,
      indexTree: {},
      selectedOption: "",
      parentId: "",
      isAvailableIndex: "",
      selectedIndex: [],
      savedConditionList: []
    }),

    // 登録条件系
    [`${ActionTypes.SAVED_FAVORITE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 選択中の項目が消された場合の考慮
      selectedCondition: action.payload.userConditionList.find(
        condition => condition.value == state.selectedCondition
      )
        ? state.selectedCondition
        : action.payload.userConditionList[0].value,
      // 登録した条件から選択 セレクトボックスで使うデータも更新する
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 登録した条件から選択 セレクトボックスで使うデータも更新する
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_PENDING`]: state => ({
      ...state,
      isLoadingSavedFavoriteDetail: true
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_FULFILLED`]: (state, action) => {
      // シナリオグループがAPI側から返ってこないため、シナリオIDが一番最初に一致するシナリオグループのIDで補完する
      const scenarioDetail = state.conditionData.screeningScenarioDto.scenarioDetailList.find(
        item => item.scenarioId === action.payload.scenarioId
      );
      return {
        ...state,
        selectedCondition: action.meta.favoriteId,
        currentScenarioId: action.payload.scenarioId,
        currentScenarioGroup: scenarioDetail.sceGroup1 || "1",
        scenarioCondition: {
          ...state.scenarioCondition,
          scenarioCondList: action.payload.scenarioCondList,
          canceled: false
        },
        inputConditionList: action.payload.scenarioOtherCondList,
        isLoadingSavedFavoriteDetail: false
      };
    },
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingSavedFavoriteDetail: false
    }),
    [ActionTypes.ALL_CONDITION_REMOVE]: state => ({
      // 状態をクリアして、初期状態に戻す
      ...state,
      selectedCondition: state.conditionData.userConditionList[0].value,
      currentScenarioId:
        state.conditionData.screeningScenarioDto.scenarioDetailList[0]
          .scenarioId,
      // 絞込条件を初期リストに変更
      inputConditionList: state.initConditionList.map(condition => ({
        ...condition
      })),
      scenarioCondition: {}
    }),
    // InputArea モーダル系
    [`${ActionTypes.NEEDS_MST_L_GET}_PENDING`]: state => ({
      ...state,
      needsMstL: [],
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstL: true
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstL: action.payload.needsMstL,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_L_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstL: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstM: true
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstM,
      needsMstS: [],
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstS,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_REJECTED`]: state => ({
      ...state,
      isLoadingNeedsMstS: false
    }),
    [ActionTypes.NEEDS_MST_CLEAR]: state => ({
      ...state,
      needsMstL: [],
      needsMstM: [],
      needsMstS: []
    }),
    [`${ActionTypes.MARKET_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      marketList: action.payload.sijyouLst
    }),
    [`${ActionTypes.FNCL_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      fnclList: action.payload.fnclLst
    }),
    [`${ActionTypes.ADDRESS_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      addressList: action.payload.addressLst
    }),
    [`${ActionTypes.NTCLS_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      ntclsList: action.payload.ntclsList
    }),

    /**
     * 比較表データ再取得系
     */
    [ActionTypes.SEARCH_FLAG_SET]: (state, action) => ({
      ...state,
      needsSearch: true,
      // 初回リクエストのみ init を付与する必要があるため、 payload から受けとる
      init: action.payload && action.payload.init ? true : false
    }),
    [ActionTypes.SEARCH_FLAG_RESET]: state => ({
      ...state,
      needsSearch: false
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      }
    }),

    // 比較表ファイルダウンロードモーダル系
    [`${ActionTypes.DOWNLOAD_PERIOD_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      dlPeriodList: action.payload.periodList,
      dlPeriodFrom: action.payload.periodFrom
    }),
    [ActionTypes.DOWNLOAD_PERIOD_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),
    [ActionTypes.ALL_RESET]: () => initialState,
    [ActionTypes.SET_TIMESTAMP]: (state, action) => ({
      ...state,
      timestamp: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(
    ActionTypes.PAGE_INIT,
    params => request(831, { ...params.payload, corpIndustyKbn: "7" }),
    params => params.meta
  ),
  updateConditionSourceList: createAction(
    ActionTypes.CONDITION_SOURCE_LIST_UPDATE
  ),
  getInitConditionListItem: createAction(
    ActionTypes.INIT_CONDITION_LIST_ITEM_GET,
    params => request(737, { ...params.payload, corpIndustyKbn: "7" }),
    params => params.meta
  ),
  updateCurrentScenarioId: createAction(ActionTypes.CURRENT_SCENARIO_ID_UPDATE),
  updateCurrentScenarioGroup: createAction(
    ActionTypes.CURRENT_SCENARIO_GROUP_UPDATE
  ),
  getScenarioCondition: createAction(
    ActionTypes.SCENARIO_CONDITION_GET,
    params => request(832, params),
    params => ({
      scenarioId: params.scenarioId,
      scenarioGroup: params.scenarioGroup
    })
  ),
  reselectScenarioCondition: createAction(
    ActionTypes.SCENARIO_CONDITION_RESELECT
  ),
  updateScenarioCondList: createAction(ActionTypes.SCENARIO_COND_LIST_UPDATE),
  cancelScenarioCondition: createAction(ActionTypes.SCENARIO_CONDITION_CANCEL),
  resetSelectedScenarioDatas: createAction(
    ActionTypes.SELECTED_SCENARIO_DATAS_RESET
  ),
  checkFavorite: createAction(
    ActionTypes.FAVORITE_CHECK,
    params => request(836, params),
    // 保存処理を行う際に必要なパラメータを meta に仕込み、リクエスト成功時に tempSaveFavoriteData に格納
    params => ({
      favoriteId: params.favoliteId,
      favoriteName: params.favoliteName,
      scenarioId: params.scenarioId,
      scenarioCondition: params.scenarioCondition,
      otherCondition: params.otherCondition,
      favoriteDetailLabel: params.favoliteDetailLabel
    })
  ),
  saveFavorite: createAction(ActionTypes.FAVORITE_SAVE, params =>
    request(837, params)
  ),
  cancelSaveFavorite: createAction(ActionTypes.SAVE_FAVORITE_CANCEL),
  removeInputCondition: createAction(ActionTypes.INPUT_CONDITION_REMOVE),
  replaceInputConditionList: createAction(
    ActionTypes.INPUT_CONDITION_LIST_REPLACE
  ),
  getScreeningResult: createAction(
    ActionTypes.SCREENING_RESULT_GET,
    params => request(834, params.payload),
    params => params.meta
  ),
  getComparisonHead: createAction(
    ActionTypes.COMPARISON_HEAD_GET,
    params => request(595, params.payload),
    params => params.meta
  ),
  getComparisonData: createAction(ActionTypes.COMPARISON_DATA_GET, params =>
    request(597, params)
  ),
  sortComparisonData: createAction(ActionTypes.COMPARISON_DATA_SORT, params =>
    request(609, params)
  ),
  removeComparisonDataRow: createAction(
    ActionTypes.COMPARISON_DATA_ROW_REMOVE,
    params => request(597, params)
  ),
  clearComparisonData: createAction(ActionTypes.COMPARISON_DATA_CLEAR),
  updateComparisonConditions: createAction(
    ActionTypes.COMPARISON_CONDITIONS_UPDATE
  ),
  getChartCount: createAction(
    ActionTypes.CHART_COUNT_GET,
    params => request(614, params.payload),
    params => params.meta
  ),
  getChartData: createAction(ActionTypes.CHART_DATA_GET, params =>
    request(629, params)
  ),
  updateChartData: createAction(ActionTypes.CHART_DATA_UPDATE, params =>
    request(630, params)
  ),
  clearChartData: createAction(ActionTypes.CHART_DATA_CLEAR),
  applyResultData: createAction(ActionTypes.RESULT_DATA_APPLY),
  getInitTreeMst: createAction(ActionTypes.INIT_TREE_MST_GET, params =>
    request(733, params)
  ),
  getScreeningTreeRoot: createAction(
    ActionTypes.SCREENING_TREE_ROOT_GET,
    params => request(734, params),
    params => ({ kensaku: params.kensaku }) // 検索判定用に検索キーワードを渡す
  ),
  getScreeningTreeChild: createAction(
    ActionTypes.SCREENING_TREE_CHILD_GET,
    params => request(736, params),
    params => ({ screeningId: params.screeningId }) // metaに子を取得したい親ノードのIDをもたせる
  ),
  getScreeningAvailability: createAction(
    ActionTypes.SCREENING_AVAILABILITY_GET,
    params => request(735, params)
  ),
  addScreening: createAction(
    ActionTypes.SCREENING_ADD,
    params => request(737, params.payload),
    params => params.meta
  ),
  removeScreening: createAction(ActionTypes.SCREENING_REMOVE),
  removeAllScreening: createAction(ActionTypes.SCREENING_REMOVE_ALL),
  updateInputConditionList: createAction(
    ActionTypes.INPUT_CONDITION_LIST_UPDATE
  ),
  getIndexTreeRoot: createAction(
    ActionTypes.INDEX_TREE_ROOT_GET,
    params => request(599, params),
    params => ({ kensakuName: params.kensakuName })
  ),
  getIndexTreeChild: createAction(
    ActionTypes.INDEX_TREE_CHILD_GET,
    params => request(606, params.payload),
    params => params.meta
  ),
  getIndexAvailability: createAction(
    ActionTypes.INDEX_AVAILABILITY_GET,
    params => request(605, params)
  ),
  addIndex: createAction(
    ActionTypes.INDEX_ADD,
    params => request(607, params.payload),
    params => params.meta
  ),
  updateIndex: createAction(ActionTypes.INDEX_UPDATE),
  removeAllIndex: createAction(ActionTypes.INDEX_REMOVE_ALL),
  getSavedConditionList: createAction(ActionTypes.SAVED_CONDITION_GET, params =>
    request(600, params)
  ),
  getSavedConditionSelectList: createAction(
    ActionTypes.SAVED_CONDITION_SELECT_GET,
    params => request(608, params)
  ),
  deleteSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_DELETE,
    params => request(602, params)
  ),
  updateSavedCondition: createAction(
    ActionTypes.SAVED_CONDITION_UPDATE,
    params => request(601, params)
  ),
  getSavedConditionDetail: createAction(
    ActionTypes.SAVED_CONDITION_DETAIL_GET,
    params => request(604, params),
    params => ({ userSetId: params.userSetId })
  ),
  setCondition: createAction(
    ActionTypes.CONDITION_SET,
    params => request(603, params),
    params => ({ userSetName: params.userSetName })
  ),
  clearIndexAll: createAction(ActionTypes.INDEX_ALL_CLEAR),

  // 比較表データ再取得系
  setSearchFlag: createAction(ActionTypes.SEARCH_FLAG_SET),
  resetSearchFlag: createAction(ActionTypes.SEARCH_FLAG_RESET),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET),

  // 登録条件系
  getSavedFavoriteList: createAction(ActionTypes.SAVED_FAVORITE_GET, params =>
    request(835, params)
  ),
  deleteSavedFavorite: createAction(ActionTypes.SAVED_FAVORITE_DELETE, params =>
    request(839, params)
  ),
  updateSavedFavorite: createAction(ActionTypes.SAVED_FAVORITE_UPDATE, params =>
    request(838, params)
  ),
  getSavedFavoriteDetail: createAction(
    ActionTypes.SAVED_FAVORITE_DETAIL_GET,
    params => request(833, params),
    // selectedCondition に残したいパラメータのみを meta に渡す
    params => ({ favoriteId: params.favoliteId })
  ),
  removeAllCondition: createAction(ActionTypes.ALL_CONDITION_REMOVE),
  // InputArea モーダル系
  getNeedsMstL: createAction(ActionTypes.NEEDS_MST_L_GET, params =>
    request(738, params)
  ),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(739, params)
  ),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(740, params)
  ),
  clearNeedsMst: createAction(ActionTypes.NEEDS_MST_CLEAR),
  getMarketList: createAction(ActionTypes.MARKET_LIST_GET, params =>
    request(741, params)
  ),
  getFnclList: createAction(ActionTypes.FNCL_LIST_GET, params =>
    request(743, params)
  ),
  getAddressList: createAction(ActionTypes.ADDRESS_LIST_GET, params =>
    request(744, params)
  ),
  getNtclsList: createAction(ActionTypes.NTCLS_LIST_GET, params =>
    request(742, params)
  ),

  // 比較表ファイルダウンロードモーダル系
  getDownloadPeriod: createAction(ActionTypes.DOWNLOAD_PERIOD_GET, params =>
    request(616, params)
  ),
  setDownloadPeriod: createAction(
    ActionTypes.DOWNLOAD_PERIOD_SET,
    period => period,
    (_, key) => ({ key })
  ),
  resetAll: createAction(ActionTypes.ALL_RESET),
  setTimestamp: createAction(ActionTypes.SET_TIMESTAMP, params => params)
};
