import { EsgReportAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";

export interface EsgReportState {
  tabId: string;
  newArticleData: any;
  regulatoryTrendsData: any;
  timelyDisclosureData: any;
}

const initialState: EsgReportState = {
  tabId: "",
  newArticleData: {
    articleList: []
  },
  regulatoryTrendsData: {
    articleList: []
  },
  timelyDisclosureData: {
    articleList: []
  }
};

export const toolEsgReport: Reducer<EsgReportState, EsgReportAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "tool/esg/report/SET_TAB_ID":
      return produce(state, draft => {
        draft.tabId = action.payload;
      });
    case "tool/esg/report/SET_NEW_ARTICLE_DATA":
      return produce(state, draft => {
        draft.newArticleData = action.payload;
      });
    case "tool/esg/report/SET_REGULATORY_TRENDS_DATA":
      return produce(state, draft => {
        draft.regulatoryTrendsData = action.payload;
      });
    case "tool/esg/report/SET_TIMELY_DISCLOSURE_DATA":
      return produce(state, draft => {
        draft.timelyDisclosureData = action.payload;
      });
    default:
      return state;
  }
};
