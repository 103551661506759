// ダウンロードスタート
export const startDownload = (downloadResourceId: string) => ({
  type: "download/DOWNLOAD_START" as const,
  payload: downloadResourceId
});

// ダウンロードストップ
export const stopDownload = () => ({
  type: "download/DOWNLOAD_STOP" as const
});

export const actionCreators = {
  startDownload,
  stopDownload
};

export type DownloadAction = ReturnType<
  typeof startDownload | typeof stopDownload
>;
