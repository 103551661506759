/**
 * 比較表
 */
// 初期表示
export const PAGE_INIT = "corp/companison/finance/PAGE_INIT";
// 表の最大表示件数取得
export const GET_ITEMS_PER_PAGE = "corp/comparison/finance/GET_ITEMS_PER_PAGE";
// 表データの取得
export const CHANGE_LIST = "corp/comparison/finance/CHANGE_LIST";
export const DELETE_ROW = "corp/comparison/finance/DELETE_ROW";
// ユーザが表示キャンセル時に表データをクリアする
export const SEARCH_DATA_CLEAR = "corp/comparison/finance/SEARCH_DATA_CLEAR";
export const GET_COMPARISON_DATA =
  "corp/comparison/finance/GET_COMPARISON_DATA";
export const SORT_COMPARISON_DATA =
  "corp/comparison/finance/SORT_COMPARISON_DATA";
// 条件選択状態の変更
export const SELECTED_CHANGE = "corp/comparison/finance/SELECTED_CHANGE";
// ダウンロード時の期間セレクトボックス情報取得
export const DOWNLOAD_PERIOD_GET =
  "corp/comparison/finance/DOWNLOAD_PERIOD_GET";
export const DOWNLOAD_PERIOD_SET =
  "corp/comparison/finance/DOWNLOAD_PERIOD_SET";
// 比較表上部のセレクトボックス取得
export const COMPARISON_HEAD_GET =
  "corp/comparison/finance/COMPARISON_HEAD_GET";

/**
 * ユーザ指標
 */
// 指標ツリーのルート階層を取得
export const INDEX_TREE_ROOT_GET =
  "corp/comparison/finance/INDEX_TREE_ROOT_GET";
// 指標ツリーの子ノードを取得
export const INDEX_TREE_CHILD_GET =
  "corp/comparison/finance/INDEX_TREE_CHILD_GET";
// 指標を検索条件に追加できるかを取得
export const INDEX_AVAILABILITY_GET =
  "corp/comparison/finance/INDEX_AVAILABILITY_GET";
// 検索条件の変更
export const INDEX_UPDATE = "corp/comparison/finance/INDEX_UPDATE";
// 指標を検索条件に追加
export const INDEX_ADD = "corp/comparison/finance/INDEX_ADD";
// 指標を検索条件から削除
export const INDEX_REMOVE = "corp/comparison/finance/INDEX_REMOVE";
// 指標を検索条件からすべて削除
export const INDEX_REMOVE_ALL = "corp/comparison/finance/INDEX_REMOVE_ALL";
// 保存した検索条件一覧取得
export const SAVED_CONDITION_GET =
  "corp/comparison/finance/SAVED_CONDITION_GET";
// 保存した検索条件の削除
export const SAVED_CONDITION_DELETE =
  "corp/comparison/finance/SAVED_CONDITION_DELETE";
// 保存した検索条件の更新
export const SAVED_CONDITION_UPDATE =
  "corp/comparison/finance/SAVED_CONDITION_UPDATE";
// 保存した検索条件の詳細取得
export const SAVED_CONDITION_DETAIL_GET =
  "corp/comparison/finance/SAVED_CONDITION_DETAIL_GET";
// 保存した検索条件のセレクトボックス取得
export const SAVED_CONDITION_SELECT_LIST_GET =
  "corp/comparison/finance/SAVED_CONDITION_SELECT_LIST_GET";
// 保存した検索条件の取得
export const CONDITION_SET = "corp/comparison/finance/CONDITION_SET";
// 指標編集系の全てデータをクリアする
export const INDEX_ALL_CLEAR = "corp/comparison/finance/INDEX_ALL_CLEAR";

/**
 * チャート
 */
// 散布図選択件数の上限値を取得
export const CHART_COUNT_GET = "corp/comparison/finance/CHART_COUNT_GET";
// チャート情報を取得
export const CHART_DATA_GET = "corp/comparison/finance/CHART_DATA_GET";
export const CHART_DATA_UPDATE = "corp/comparison/finance/CHART_DATA_UPDATE";
export const CHART_DATA_CLEAR = "corp/comparison/finance/CHART_DATA_CLEAR";

/**
 * その他
 */
// 再検索フラグをセット／リセット
export const SEARCH_FLAG_SET = "corp/comparison/finance/SEARCH_FLAG_SET";
export const SEARCH_FLAG_RESET = "corp/comparison/finance/SEARCH_FLAG_RESET";
// ソート状態を解除
export const SORT_INFO_UNSET = "corp/comparison/finance/SORT_INFO_UNSET";
