export type CorpType =
  | "listed"
  | "unlisted"
  | "holder"
  | "nikkei"
  | "tdb"
  | "overseasListed"
  | "overseasUnlisted";

export type CorpArea =
  | "asia"
  | "europe"
  | "northAmerica"
  | "southAmerica"
  | "africa"
  | "mideast";

export type DisplayCondition = "ytCondition" | "scCommonCondition" | "currency";

export interface FilterCondition {
  id: string;
  label: string;
  value: string | string[];
  inputAreaId: string;
  inputRuleMessage: string;
  list?: DisplayConditionItem[];
  list2?: DisplayConditionItem[];
  hierarchyNames: string[];
  needsName: string;
  selectTreeItem?: SelectTreeItem;
  groupItemFlg: string;
}

export interface FilterItem {
  id: string;
  children: FilterItem[];
  childExistFlag: boolean; // 子要素があるか
  label: string;
  group: boolean; // グループ項目であるか
  hierarchyNames: string[];
  listed: boolean;
  unlistedCorp: boolean;
  unlistedHolder: boolean;
  unlistedTdb: boolean;
  unlistedOverseas: boolean;
  listedOverseas: boolean;
  itemDispFlg: number;
}

export interface Corp {
  companyName: string;
  companyCode: string;
  kabushikiCode: string;
  companyKbn?: string;
  source: string;
}

export interface IndustryHierarchy {
  l: MstL;
  m?: MstM;
  s?: MstS;
}

export interface ScreeningItem {
  link?: string;
  text: string;
  textAlign: string;
  value: string;
  highlight: string[];
  highlightText: string[];
}

export interface ConditionDisplayItem {
  id: string;
  label: string;
  hierarchyNames: string[];
  groupList?: string[];
}

export interface DisplayItem {
  id: string;
  label: string;
  children: DisplayItem[];
  childExistFlag: boolean;
  group: boolean;
  hierarchyNames: string[];
  listed: boolean;
  unlistedHolder: boolean;
  unlistedCorp: boolean;
  unlistedTdb: boolean;
  listedOverseas: boolean;
  unlistedOverseas: boolean;
  itemDispFlg: number;
}

export interface DisplayConditionItem {
  inneyCode: string;
  label: string;
  seq: string;
  value: string;
}

export interface FreeWordTarget {
  label: string;
  value: string;
}

export interface MstL {
  nkgncodel: string;
  nkgnnamel: string;
  seq: string;
}

export interface MstM {
  nkgnnamem: string;
  nkgncodem: string;
  seq: string;
}

export interface MstS {
  nkgncodes: string;
  nkgnnames: string;
  seq: string;
}

export interface SourceItem {
  active: boolean;
  corpCount: string;
  fsarea: string;
  haveAuthority: boolean;
  id: string;
  inneyCode: string;
  label: string;
  labelId: string;
  selected: boolean;
  seq: string;
  value: string;
}

export interface SavedCondition {
  id: string;
  name: string;
  created: string;
  display: string;
  filter: string[];
  searchTarget: string;
  selectCorpLabel: string;
}

export interface ErrorCondition {
  messages: string[];
  screeningId: string;
}

export interface ConditionItem {
  inneyCode: string;
  label: string;
  seq: string;
  value: string;
  selectCorpLabel: string;
}

export interface ScatterList {
  periodFromDefault: string;
  periodList: ConditionItem[];
  periodToDefault: string;
  xyaxisListOfGeneral: ConditionItem[];
  xaxisListDefaultOfGeneral: ConditionItem;
  yaxisListDefaultOfGeneral: ConditionItem;
}

export const INNEY_CODE = {
  listed: "1", // 上場
  holder: "2", // 有報
  unlistedCorp: "3", // 非上場(日経)
  tdbCorp: "11", // 非上場(TDB)
  overseasCorp: "4", // 海外
  unlistedOverseasCorp: "14", // 海外非上場
  listedAsia: "8", // アジア（上場）
  unlistedAsia: "18", // アジア（非上場）
  listedEurope: "7", // ヨーロッパ（上場）
  unlistedEurope: "17", // ヨーロッパ（非上場）
  listedNorthAmerica: "5", // 北米（上場）
  unlistedNorthAmerica: "15", // 北米（非上場）
  listedSouthAmerica: "6", // 南米（上場）
  unlistedSouthAmerica: "16", // 南米（非上場）
  listedAfrica: "10", // アフリカ（上場）
  unlistedAfrica: "20", // アフリカ（非上場）
  listedMideast: "9", // 中東（上場）
  unlistedMideast: "19" // 中東（非上場）
};

export const CORP_TYPE_CODE = [
  INNEY_CODE.listed,
  INNEY_CODE.holder,
  INNEY_CODE.unlistedCorp,
  INNEY_CODE.tdbCorp,
  INNEY_CODE.overseasCorp,
  INNEY_CODE.unlistedOverseasCorp
];

export const LISTED_AREA_CODE = [
  INNEY_CODE.listedAsia,
  INNEY_CODE.listedEurope,
  INNEY_CODE.listedNorthAmerica,
  INNEY_CODE.listedSouthAmerica,
  INNEY_CODE.listedMideast,
  INNEY_CODE.listedAfrica
];

export const UNLISTED_AREA_CODE = [
  INNEY_CODE.unlistedAsia,
  INNEY_CODE.unlistedEurope,
  INNEY_CODE.unlistedNorthAmerica,
  INNEY_CODE.unlistedSouthAmerica,
  INNEY_CODE.unlistedMideast,
  INNEY_CODE.unlistedAfrica
];

export const AREA_CODE = [...LISTED_AREA_CODE, ...UNLISTED_AREA_CODE];

export interface SelectTreeItem {
  childTreeItem: {
    groupName?: string;
    itemList?: {
      label: string;
      parentValue: string;
      value: string;
    }[];
  };
  groupName: string;
  itemList: {
    label?: string;
    parentValue?: string;
    value?: string;
  }[];
}

export interface UserConditionAddResponse {
  toolCommon06DtoList: {
    inputAreaId: string;
    hierarchyName: string;
    hierarchyNames: string[];
    inputRuleMessage: string;
    sbContents1: DisplayConditionItem[];
    sbContents2: DisplayConditionItem[];
    selectTreeItem?: SelectTreeItem;
    needsName: string;
    groupItemFlg: string;
  }[];
}

export interface FilterConditionListItemResponse {
  screeningId: string;
  hierarchyName: string;
  value1: string;
  value2: string;
  inputAreaId: string;
  inputRuleMessage: string;
  sbContents1: ConditionItem[];
  sbContents2: ConditionItem[];
  hierarchyNames: string[];
  needsName: string;
  selectTreeItem: SelectTreeItem;
  groupItemFlg: string;
}

export interface CorpCount {
  listingNkcodeCount: string;
  unListedHolderNkcodeCount: string;
  northAmericaNkcodeCount: string;
  southAmericaNkcodeCount: string;
  europeNkcodeCount: string;
  asiaPacificNkcodeCount: string;
  mideastNkcodeCount: string;
  africaNkcodeCount: string;
  unListedNkcodeCount: string;
  unListedTdbNkcodeCount: string;
  unlistedNorthAmericaNkcodeCount: string;
  unlistedSouthAmericaNkcodeCount: string;
  unlistedEuropeNkcodeCount: string;
  unlistedAsiaPacificNkcodeCount: string;
  unlistedMideastNkcodeCount: string;
  unlistedAfricaNkcodeCount: string;
}
