import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  notice: {},
  news: {},
  pdf: {},
  xbrl: {},
  reportText: "",
  error: {},
  trendReport: {}, // 日経 Bizトレンド
  // 日経業界分析レポート
  sectorReport: {
    sectorReportInfoModalToggle: false
  },
  sortKbn: "1"
};

export default handleActions(
  {
    [`${ActionTypes.NOTICE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      notice: action.payload
    }),
    [`${ActionTypes.NOTICE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NEWS_PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      initData: {
        ...state.initData,
        home04: action.payload
      }
    }),
    [`${ActionTypes.NEWS_PAGE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NEWS_PDF_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      pdf: action.payload
    }),
    [`${ActionTypes.NEWS_PDF_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NEWS_XBRL_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      xbrl: action.payload
    }),
    [`${ActionTypes.NEWS_XBRL_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.TREND_REPORT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      trendReport: action.payload
    }),
    [`${ActionTypes.SECTOR_REPORT_GET}_FULFILLED`]: (state, action) => {
      const sortKbn = action.meta.sortKbn
        ? action.meta.sortKbn
        : state.sectorReport.sortKbn;
      return {
        ...state,
        sectorReport: {
          sectorReportInfoModalToggle:
            state.sectorReport.sectorReportInfoModalToggle,
          ...action.payload
        },
        sortKbn
      };
    },
    [ActionTypes.SECTOR_REPORT_INFO_MODAL_OPEN]: state => ({
      ...state,
      sectorReport: {
        ...state.sectorReport,
        sectorReportInfoModalToggle: true
      }
    }),
    [ActionTypes.SECTOR_REPORT_INFO_MODAL_CLOSE]: state => ({
      ...state,
      sectorReport: {
        ...state.sectorReport,
        sectorReportInfoModalToggle: false
      }
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(345, params)),
  getNotice: createAction(ActionTypes.NOTICE_GET, params =>
    request(344, params)
  ),
  getNews: createAction(ActionTypes.NEWS_GET, params => request(348, params)),
  getNewsPage: createAction(ActionTypes.NEWS_PAGE_GET, params =>
    request(349, params)
  ),
  getTrendReport: createAction(ActionTypes.TREND_REPORT_GET, params =>
    request(851, params)
  ),
  getSectorReport: createAction(
    ActionTypes.SECTOR_REPORT_GET,
    params => request(850, params),
    params => params
  ),
  openSectorReportInfoModal: createAction(
    ActionTypes.SECTOR_REPORT_INFO_MODAL_OPEN
  ),
  closeSectorReportInfoModal: createAction(
    ActionTypes.SECTOR_REPORT_INFO_MODAL_CLOSE
  )
};
