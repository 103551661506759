import React from "react";
import styles from "./style.scss";
import cn from "classnames";
import propTypes from "prop-types";

const LOADING_SVG =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPGRlZnM+CiAgICA8cGF0aCBpZD0iaWNuX2xvYWRpbmctYSIgZD0iTTE5LDEyIEMxOSw4LjEzNDAwNjc1IDE1Ljg2NTk5MzIsNSAxMiw1IE0xMiw1IEwxMiwyIEMxNy41MjI4NDc1LDIgMjIsNi40NzcxNTI1IDIyLDEyIEwxOSwxMiIvPgogIDwvZGVmcz4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHBhdGggZmlsbD0iI0M1RERFQiIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTIsMTkgQzE1Ljg2NTk5MzIsMTkgMTksMTUuODY1OTkzMiAxOSwxMiBDMTksOC4xMzQwMDY3NSAxNS44NjU5OTMyLDUgMTIsNSBDOC4xMzQwMDY3NSw1IDUsOC4xMzQwMDY3NSA1LDEyIEM1LDE1Ljg2NTk5MzIgOC4xMzQwMDY3NSwxOSAxMiwxOSBaIE0xMiwyMiBDNi40NzcxNTI1LDIyIDIsMTcuNTIyODQ3NSAyLDEyIEMyLDYuNDc3MTUyNSA2LjQ3NzE1MjUsMiAxMiwyIEMxNy41MjI4NDc1LDIgMjIsNi40NzcxNTI1IDIyLDEyIEMyMiwxNy41MjI4NDc1IDE3LjUyMjg0NzUsMjIgMTIsMjIgWiIvPgogICAgPHVzZSBmaWxsPSIjNTQ5OUMzIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHhsaW5rOmhyZWY9IiNpY25fbG9hZGluZy1hIi8+CiAgPC9nPgo8L3N2Zz4K";

const LoadingSpinner = ({ size = "M", className = "" }) => (
  <img
    src={LOADING_SVG}
    alt="読み込み中"
    className={cn(className, styles.loading, styles[`size${size}`])}
    data-testid="loadingSpinner"
  />
);

LoadingSpinner.propTypes = {
  size: propTypes.oneOf(["S", "M", "L"]),
  className: propTypes.string
};

export default LoadingSpinner;
