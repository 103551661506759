import React from "react";
import styles from "./style.scss";
import ReactModal from "react-modal";
import Button from "components/Common/Button/Text";

interface Props {
  isOpen: boolean;
  title?: string;
  description?: string;
  keepButtonText: string;
  closeButtonText?: string;
  leftButtonColor?: string;
  rightButtonColor?: string;
  onClickKeepButton: () => void;
  onClickCloseButton: () => void;
}

export const CloseConfirmModal: React.FunctionComponent<Props> = ({
  isOpen,
  title = "このウインドウを閉じますか？",
  description = "行った操作は保存されません。\nこのウィンドウを閉じてよろしいですか？",
  keepButtonText,
  closeButtonText = "閉じる",
  leftButtonColor = "red",
  rightButtonColor = "navy",
  onClickKeepButton,
  onClickCloseButton
}) => (
  <ReactModal
    isOpen={isOpen}
    className={styles.modalSizing}
    overlayClassName={styles.wrapper}
    shouldFocusAfterRender={false}
    ariaHideApp={false}
  >
    <div
      className={styles.reactModal}
      data-testid="BinderPage-Common-CloseConfirmModal"
    >
      <div className={styles.header}>{title}</div>
      <div className={styles.body}>
        <div className={styles.reactModalContent}>{description}</div>
        <div className={styles.reactModalButtonWrapper}>
          <Button
            color={leftButtonColor}
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.stopPropagation();
              onClickCloseButton();
            }}
            data-testid="close-confirm-modal-close-button"
          >
            {closeButtonText}
          </Button>
          <Button
            color={rightButtonColor}
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.stopPropagation();
              onClickKeepButton();
            }}
            data-testid="close-confirm-modal-keep-button"
          >
            {keepButtonText}
          </Button>
        </div>
      </div>
    </div>
  </ReactModal>
);
