import React from "react";
import styles from "./style.scss";
import "~/react-select/dist/react-select.css";

import Select, { ValueType } from "react-select";

interface Option {
  seq: string;
  label: string;
  inneyCode: string;
  value: string;
}

interface Props {
  className?: string;
  optionList: Option[];
  onSelected: (option: Option) => void;
  selectedOption: string;
}

const Search: React.FunctionComponent<Props> = ({
  selectedOption = "",
  optionList,
  onSelected,
  className
}) => {
  const handleChange = (object: ValueType<Option>) => {
    if (!object) return;

    const selected = optionList.find(
      row => row.value == (object as Option).value
    );

    if (!selected) return;
    onSelected(selected);
  };

  const value =
    selectedOption === ""
      ? optionList[0]
      : optionList.find(item => item.value === selectedOption);

  return (
    <Select
      className={`${styles.component} ${className}`}
      value={value}
      options={optionList}
      onChange={handleChange}
      clearable={false}
    />
  );
};

export default Search;
