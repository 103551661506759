import React from "react";
import styles from "./style.scss";
import Button from "components/Common/Button/Text";
import { useBinderType } from "features/BinderPage/Item/Components/Common/hook";
import { LinkButton } from "components/Common/LinkButton";
import cn from "classnames";

export type TabType =
  | "corp"
  | "industry"
  | "statistic"
  | "article"
  | "saveArticle"
  | "myBinder"
  | "nikkeiBinder"
  | "externalPage";

export interface EmptyProps {
  name: TabType;
  subLabel?: string;
  isLoading?: boolean;
  onClickFeedEditButton?: () => void;
  isMobile?: boolean;
}

const traslation = {
  corp: "保存された企業",
  industry: "保存された業界",
  statistic: "保存された統計",
  article: "記事",
  saveArticle: "保存された記事",
  externalPage: "保存されたページ",
  myBinder: "マイコレクション",
  nikkeiBinder: "日経コレクション"
};

export const Empty: React.FunctionComponent<EmptyProps> = props => {
  const isLoading = props.isLoading ?? false;
  const styleName = styles[props.name];
  const type = useBinderType();

  if (!isLoading && traslation[props.name] == traslation.article) {
    return (
      <div
        data-testid="features-BinderPage-Common-Empty"
        className={cn(styles.component, {
          [styles.mobileComponent]: props.isMobile
        })}
      >
        <div className={styles.empty}>
          <i className={styleName}></i>
          <p className={styles.label}>
            フィード条件に合致する{traslation[props.name]}がありませんでした
          </p>
          {type !== "nikkei" && (
            <>
              <div className={styles.sublevel}>
                <p>
                  あらかじめ条件を設定しておくことで関連する最新記事を自動で表示させることができます。
                </p>
                {!props.isMobile && (
                  <p>
                    フィード条件の設定はコレクションの「編集」からいつでも行うことができます。
                  </p>
                )}
              </div>
              {!props.isMobile && (
                <Button
                  onClick={() => props.onClickFeedEditButton?.()}
                  className={styles.button}
                >
                  フィード条件を設定する
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    );
  } else if (!isLoading && traslation[props.name] == traslation.myBinder) {
    return (
      <div className={styles.component}>
        <div className={styles.empty}>
          <i className={styleName}></i>
          <p className={styles.label}>
            {traslation[props.name]}を作成しましょう
          </p>
          <div className={styles.sublabel}>
            <p>
              コレクションはテーマやタスクごとに様々な情報を整理整頓し、最新情報の収集やナレッジの蓄積、チームでの情報共有を強力に支える機能です。
            </p>
            <p>
              もっとも簡単にコレクションを使用するには、気になる日経コレクションを複製することがお勧めです。
            </p>
            <LinkButton
              to={{ pathname: "/binder/public" }}
              className={styles.button}
            >
              日経コレクションを見る
            </LinkButton>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.component}>
      {!isLoading && (
        <div className={styles.empty}>
          <i className={styleName}></i>
          <p className={styles.label}>{traslation[props.name]}はありません</p>
        </div>
      )}
    </div>
  );
};
