// 初期表示(事業)
export const PAGE_INIT = "tools/screening/segment/business/PAGE_INIT";
// 事業データ更新
export const CONDITION_UPDATE =
  "tools/screening/segment/business/CONDITION_UPDATE";
// スクリーニング結果を取得
export const SCREENING_RESULT_GET =
  "tools/screening/segment/business/SCREENING_RESULT_GET";
// 比較表ヘッダ情報取得（仮）
export const COMPARISON_HEAD_GET =
  "tools/screening/segment/business/COMPARISON_HEAD_GET";
// 比較表明細情報取得（仮）
export const COMPARISON_DATA_GET =
  "tools/screening/segment/business/COMPARISON_DATA_GET";
// 比較表ソート取得
export const COMPARISON_DATA_SORT =
  "tools/screening/segment/business/COMPARISON_DATA_SORT";
// 比較表行削除
export const COMPARISON_DATA_ROW_REMOVE =
  "tools/screening/segment/business/COMPARISON_DATA_ROW_REMOVE";
// ユーザが表示キャンセル時に表データをクリアする
export const COMPARISON_DATA_CLEAR =
  "tools/screening/segment/business/COMPARISON_DATA_CLEAR";
// 比較表セレクトボックス等の選択状態更新
export const COMPARISON_CONDITIONS_UPDATE =
  "tools/screening/segment/business/COMPARISON_CONDITIONS_UPDATE";
// 散布図選択件数の上限値を取得
export const CHART_COUNT_GET =
  "tools/screening/segment/business/CHART_COUNT_GET";
// チャート情報を取得
export const CHART_DATA_GET = "tools/screening/segment/business/CHART_DATA_GET";
// チャート情報を更新
export const CHART_DATA_UPDATE =
  "tools/screening/segment/business/CHART_DATA_UPDATE";
// チャート情報削除
export const CHART_DATA_CLEAR =
  "tools/screening/segment/business/CHART_DATA_CLEAR";
// 保存データの内容を state に適用する
export const RESULT_DATA_APPLY =
  "tools/screening/segment/business/RESULT_DATA_APPLY";
// "条件を保存"前に保存可能かチェック
export const FAVORITE_CHECK = "tools/screening/segment/business/FAVORITE_CHECK";
// "条件を保存"からキャンセル(一時データを削除)
export const SAVE_FAVORITE_CANCEL =
  "tools/screening/segment/business/SAVE_FAVORITE_CANCEL";
// "条件を保存"モーダルで条件を保存する
export const FAVORITE_SAVE = "tools/screening/segment/business/FAVORITE_SAVE";
// 登録した条件から選択 で選択中の条件をクリアし、クリアデータを取得する
export const ALL_CONDITION_REMOVE =
  "tools/screening/segment/business/ALL_CONDITION_REMOVE";
// 業界選択モーダル系のデータをクリアする
export const SELECTED_INDUSTRIES_CLEAR =
  "tools/screening/segment/business/SELECTED_INDUSTRIES_CLEAR";
// NEEDS分類取得（中）
export const NEEDS_MST_M_GET =
  "tools/screening/segment/business/NEEDS_MST_M_GET";
// NEEDS分類取得（小）
export const NEEDS_MST_S_GET =
  "tools/screening/segment/business/NEEDS_MST_S_GET";
// 業界分類の検索
export const INDUSTRY_SEARCH =
  "tools/screening/segment/business/INDUSTRY_SEARCH";
// 保存した条件(スクリーニング検索条件)一覧取得
export const SAVED_FAVORITE_GET =
  "tools/screening/segment/business/SAVED_FAVORITE_GET";
// 保存した条件(スクリーニング検索条件)の削除
export const SAVED_FAVORITE_DELETE =
  "tools/screening/segment/business/SAVED_FAVORITE_DELETE";
// 保存した条件(スクリーニング検索条件)の更新
export const SAVED_FAVORITE_UPDATE =
  "tools/screening/segment/business/SAVED_FAVORITE_UPDATE";
// 保存した条件(スクリーニング検索条件)の詳細取得
export const SAVED_FAVORITE_DETAIL_GET =
  "tools/screening/segment/business/SAVED_FAVORITE_DETAIL_GET";

/**
 * 比較表データ再取得系
 */
// 再検索フラグをセット／リセット
export const SEARCH_FLAG_SET =
  "tools/screening/segment/business/SEARCH_FLAG_SET";
export const SEARCH_FLAG_RESET =
  "tools/screening/segment/business/SEARCH_FLAG_RESET";
// ソート状態を解除
export const SORT_INFO_UNSET =
  "tools/screening/segment/business/SORT_INFO_UNSET";

// ページ遷移時にキャッシュ削除
export const ALL_RESET = "tools/screening/segment/business/ALL_RESET";
