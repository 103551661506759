// 初期表示
export const PAGE_INIT = "corp/overseas/segment/businessArea/PAGE_INIT";
// 科目セレクトボックス選択時
export const DATA_UPDATE = "corp/overseas/segment/businessArea/DATA_UPDATE";
// 期間・決算区分・単位変更時
export const CONDITION_CHANGE =
  "corp/overseas/segment/businessArea/CONDITION_CHANGE";
// 期間・決算区分・単位リセット時
export const CONDITION_RESET =
  "corp/overseas/segment/businessArea/CONDITION_RESET";
// グラフの種類変更時
export const GRAPH_TYPE_CHANGE =
  "corp/overseas/segment/businessArea/GRAPH_TYPE_CHANGE";
