import { isEmpty } from "utils/helper/common";
import { loadCorporationType, corpIndustryKbn } from "utils/helper/url";
import { getKbnWithCode } from "./corp";

export const getContentId = (pathname, diffflg, asrf, code = null) => {
  let type = loadCorporationType(diffflg, asrf);
  if (type === "report") type = "listed"; // 有報は上場と同じ扱い
  if (type === undefined && code) {
    const corpType = getKbnWithCode(code);
    if (corpType === corpIndustryKbn.overseas) {
      type = "overseas";
    }
  }

  const accessContentId = {
    "/industry/summary": () => "B201001",
    "/industry/index": () => "B202001",
    "/industry/ir-data": () => "B203001",
    "/industry/scale": () => "B204001",
    "/industry/news": () => "B601001",
    "/industry/report": () => "B602001",
    "/industry/research-report": () => "B603001",
    "/industry/event": () => "B901001",
    "/industry/strategy": () => "B902001",
    "/industry/telecom/trade-press": () => "BT2010",
    "/macro/ir-data": () => "E203001",
    "/corp/summary": type => getCorpSummaryId(type),
    "/corp/overview": type => getCorpOverviewId(type),
    "/corp/whoswho": type => getCorpWhoswhoId(type),
    "/corp/risk": type => getIdIfListedCorp(type, "C201004"),
    "/corp/supplychain": type => getCorpSupplychainId(type),
    "/corp/segment/business": type => getCorpSegmentBusinessId(type),
    "/corp/segment/area": type => getCorpSegmentAreaId(type),
    "/corp/segment/country": type => getCorpSegmentCountryId(type),
    "/corp/shares/shareholder": type => getCorpShareholderId(type),
    "/corp/shares/holdings": type => getIdIfListedCorp(type, "C203002"),
    "/corp/meeting": type => getIdIfListedCorp(type, "C204001"),
    "/corp/csr/governance": type => getIdIfListedCorp(type, "C205001"),
    "/corp/csr/environment": type => getIdIfListedCorp(type, "C205002"),
    "/corp/csr/evaluation": type => getIdIfListedCorp(type, "C205003"),
    "/corp/csr/labor": type => getIdIfListedCorp(type, "C205005"),
    "/corp/loan": type => getIdIfListedCorp(type, "C206001"),
    "/corp/finance": type => getCorpFinanceId(type),
    "/corp/earning-forecast": type => getEarningForecast(type),
    "/corp/disclosure": type => getCorpDisclosure(type),
    "/corp/ir-data": type => getIdIfListedCorp(type, "C402001"),
    "/corp/index": type => getCorpIndex(type),
    "/corp/valuation/value": type => getCorpValuation(type),
    "/corp/valuation/index": type => getIdIfListedCorp(type, "C502003"),
    "/corp/valuation/dcf": type => getIdIfListedCorp(type, "C502005"),
    "/corp/valuation/beta": type => getIdIfListedCorp(type, "C502007"),
    "/corp/news": type => getCorpNewsId(type),
    "/corp/report": type => getCorpReportId(type),
    "/corp/comparison/finance": type => getComparisonlistId(type),
    "/corp/ma-event": type => getIdIfListedCorp(type, "C9020"),
    "/corp/graph": type => getIdIfListedCorp(type, "C903001"),
    "/corp/ma-strategy": type => getIdIfListedCorp(type, "C9040"),
    "/corp/telecom/search": type => getTelecomSearchId(type),
    "/corp/telecom/trade-press": type => getTelecomTradePressId(type),
    "/macro/summary": () => "E101001",
    "/macro/statistics": () => "E201001",
    "/macro/forecast": () => "E202001",
    "/macro/area": () => "E204001",
    "/macro/commodity": () => "E205001",
    "/search/by-industry": () => "H004002",
    "/tool/screening/corp": () => "H004003",
    "/tool/screening/segment": () => "H004004",
    "/search/industry": () => "H005001",
    "/search/classification": () => "H005002",
    "/tool/screening/industry": () => "H005003",
    "/article/disclosure": () => "N4010",
    "/article/news": () => "N6010",
    "/article/report": () => "N6020",
    "/article/research-report": () => "N6030",
    "/article/ma": () => "N9010",
    "/article/event": () => "N9020",
    "/article/strategy": () => "N9050",
    "/tool/comparison/corp": () => "T201001",
    "/tool/multiple": () => "T2020",
    "/tool/screening/corp/item": () => "T301001",
    "/tool/screening/corp/scenario": () => "T301002",
    "/tool/graph-generator": () => "T402001",
    "/tool/excel-addin": () => "T404001",
    "/tool/screening/segment/business": () => "T501001",
    "/tool/screening/segment/area": () => "T501002",
    "/tool/comparison/industry": () => "T6010",
    "/setting/option": () => "U0010",
    "/setting/user": () => "U0070",
    "/setting/login-history": () => "U006001"
  };
  if (!accessContentId.hasOwnProperty(pathname)) return;
  return accessContentId[pathname](type);
};

const searchParams = () => new URLSearchParams(location.search);

const getCorpFinTabId = () => {
  const menuId = searchParams().get("menuId");
  const contentId = searchParams().get("contentId");
  const [report, detail, analysis, overseasReport, overseasAnalysis] = [
    "C3010",
    "C3020",
    "C3030",
    "CK3010",
    "CK3020"
  ];
  const reportIds = {
    corpfinreportsummary: "01",
    corpfinreportbs: "02",
    corpfinreportpl: "03",
    corpfinreportcash: "04",
    corpfinreportstock: "05",
    corpfinreportetc: "06",
    corpfinOverseas: "07"
  };
  switch (menuId) {
    case report:
      // 財務諸表
      if (isEmpty(contentId)) return menuId + reportIds.corpfinreportsummary;
      return menuId + reportIds[contentId];

    case detail:
      // 明細表・注記
      if (isEmpty(contentId)) return menuId + "01";
      return menuId + contentId.substr(contentId.length - 2);

    case analysis:
      // 財務分析表
      if (isEmpty(contentId)) return menuId + "01";
      // 海外(FactSet)
      if (contentId === "corpfinanalysisOverseas") return menuId + "06";
      return menuId + contentId.substr(contentId.length - 2);

    case overseasReport:
      // 海外
      return report + "07";

    case overseasAnalysis:
      // 海外
      return analysis + "06";

    default:
      return null;
  }
};

const getIdIfListedCorp = (type, id) => {
  if (type === "listed") {
    return id;
  }
  return null;
};

const getOtherFinId = () => searchParams().get("menuId") + "01";

const getCorpNewsId = type => {
  const ids = {
    listed: "C601001",
    unlisted: "CH601001",
    tdb: "CHD60101",
    overseas: null,
    startup: "CHK6010"
  };
  return ids[type];
};
const getCorpReportId = type => {
  const ids = {
    listed: "C602001",
    unlisted: "CH602001",
    tdb: "CHD60201",
    overseas: null,
    startup: "CHK6020"
  };
  return ids[type];
};
const getCorpSummaryId = type => {
  const ids = {
    listed: "C201001",
    unlisted: "CH201001",
    tdb: "CHD20101",
    overseas: "CK201001",
    startup: "CHK2010",
    unlistedOverseas: "CKU20101"
  };
  return ids[type];
};
const getCorpOverviewId = type => {
  const ids = {
    listed: "C201002",
    unlisted: "CH201002",
    tdb: null,
    overseas: null
  };
  return ids[type];
};
const getCorpSupplychainId = type => {
  const ids = {
    listed: "C201005",
    unlisted: null,
    tdb: null,
    overseas: "CK201002",
    unlistedOverseas: "CKU20102"
  };
  return ids[type];
};
const getCorpWhoswhoId = type => {
  const ids = {
    listed: "C201003",
    unlisted: "CH201003",
    tdb: null,
    overseas: null
  };
  return ids[type];
};
const getComparisonlistId = type => {
  const ids = {
    listed: "C901001",
    unlisted: "CH901001",
    tdb: "CHD90101",
    overseas: "CK901001"
  };
  return ids[type];
};
const getTelecomSearchId = type => {
  const ids = {
    listed: "CT2010",
    unlisted: "CHT2010",
    tdb: "CHDT2010",
    overseas: null,
    startup: "CHKT2010"
  };
  return ids[type];
};
const getTelecomTradePressId = type => {
  const ids = {
    listed: "CT2020",
    unlisted: "CHT2020",
    tdb: "CHDT2020",
    overseas: null,
    startup: "CHKT2020"
  };
  return ids[type];
};
const getCorpSegmentBusinessId = type => {
  const ids = {
    listed: "C202001",
    unlisted: null,
    tdb: null,
    overseas: "CK202001"
  };
  return ids[type];
};
const getCorpSegmentAreaId = type => {
  const ids = {
    listed: "C202002",
    unlisted: null,
    tdb: null,
    overseas: null
  };
  return ids[type];
};
const getCorpSegmentCountryId = type => {
  const ids = {
    listed: "C202005",
    unlisted: null,
    tdb: null,
    overseas: "CK202002"
  };
  return ids[type];
};
const getCorpShareholderId = type => {
  const ids = {
    listed: "C203001",
    unlisted: null,
    tdb: null,
    overseas: "CK203001"
  };
  return ids[type];
};
const getCorpFinanceId = type => {
  const ids = {
    listed: getCorpFinTabId(),
    unlisted: getOtherFinId(),
    tdb: null,
    overseas: getOtherFinId()
  };
  return ids[type];
};
const getEarningForecast = type => {
  const ids = {
    listed: "C304001",
    unlisted: null,
    tdb: null,
    overseas: "CK304001"
  };
  return ids[type];
};
const getCorpDisclosure = type => {
  const ids = {
    listed: "C401001",
    unlisted: null,
    tdb: null,
    overseas: "CK401001"
  };
  return ids[type];
};
const getCorpIndex = type => {
  const ids = {
    listed: "C501001",
    unlisted: null,
    tdb: null,
    overseas: "CK501001"
  };
  return ids[type];
};
const getCorpValuation = type => {
  const ids = {
    listed: "C502001",
    unlisted: null,
    tdb: null,
    overseas: "CK502001"
  };
  return ids[type];
};
