import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { isEmpty } from "utils/helper/common";

const initialState = {
  page: {},
  personnel: {},
  selectedPerson: { keyBody: "" },
  details: {},
  changes: {},
  newsList: {},
  pdf: {},
  error: {},
  // 人事情報リストデータ
  list: {
    items: [],
    page: {
      start: 1, // 表示中の件数：開始値
      end: 20 // 表示中の件数：終了値
    },
    pnaver: "",
    perPageNumber: 20 // 1ページあたり一覧表示数
  }
};

const createPage = (personnel, perPageNumber) => {
  if (isEmpty(personnel.totalCount))
    return {
      start: 1,
      end: perPageNumber
    };
  return {
    start: 1,
    end: Math.min(perPageNumber, personnel.totalCount)
  };
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      page: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...initialState,
      list: {
        ...initialState.list,
        perPageNumber: state.list.perPageNumber
      }
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.PERSONNEL_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      personnel: action.payload,
      selectedPerson: !isEmpty(action.payload.whosList)
        ? action.payload.whosList[0]
        : initialState.selectedPerson,
      list: {
        ...state.list,
        items: action.payload.whosList,
        page: createPage(action.payload, state.list.perPageNumber),
        pnaver: action.payload.pnaver
      }
    }),
    [`${ActionTypes.PERSONNEL_GET}_REJECTED`]: (state, action) => ({
      ...state,
      selectedPerson: initialState.selectedPerson,
      error: action.payload
    }),
    [`${ActionTypes.MORE_PERSONNEL_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      list: {
        ...state.list,
        items: [...state.list.items, ...action.payload.whosList],
        pnaver: action.payload.pnaver
      }
    }),
    [`${ActionTypes.MORE_PERSONNEL_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.DETAIL_GET}_PENDING`]: (state, action) => ({
      ...state,
      selectedPerson: !isEmpty(action.meta.person)
        ? action.meta.person
        : state.selectedPerson
    }),
    [`${ActionTypes.DETAIL_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      details: action.payload
    }),
    [`${ActionTypes.DETAIL_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.PRTSONNEL_CHANGES_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      changes: action.payload
    }),
    [`${ActionTypes.PRTSONNEL_CHANGES_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NEWS_LIST_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      newsList: action.payload
    }),
    [`${ActionTypes.NEWS_LIST_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.NEWS_PDF_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      pdf: action.payload
    }),
    [`${ActionTypes.NEWS_PDF_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.PAGE_SET]: (state, action) => ({
      ...state,
      list: {
        ...state.list,
        page: action.payload
      }
    }),
    [ActionTypes.PERSON_SELECT]: (state, action) => ({
      ...state,
      selectedPerson: action.payload
    }),
    [ActionTypes.PER_PAGE_CHANGE]: (state, action) => ({
      ...state,
      list: {
        ...state.list,
        perPageNumber: action.payload
      }
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(315, params)),
  getPersonnel: createAction(ActionTypes.PERSONNEL_GET, params =>
    request(316, params)
  ),
  getMorePersonnel: createAction(ActionTypes.MORE_PERSONNEL_GET, params =>
    request(317, params)
  ),
  getDetail: createAction(
    ActionTypes.DETAIL_GET,
    params => request(318, params),
    (_, person) => ({ person })
  ),
  getPersonnelChanges: createAction(ActionTypes.PRTSONNEL_CHANGES_GET, params =>
    request(319, params)
  ),
  getNewsList: createAction(ActionTypes.NEWS_LIST_GET, params =>
    request(320, params)
  ),
  getNewsPdf: createAction(ActionTypes.NEWS_PDF_GET, params =>
    request(322, params)
  ),
  setPage: createAction(ActionTypes.PAGE_SET),
  selectPerson: createAction(ActionTypes.PERSON_SELECT),
  changePerPage: createAction(ActionTypes.PER_PAGE_CHANGE)
};
