// 初期表示
export const PAGE_INIT = "tools/screening/industry/PAGE_INIT";
// 検索条件の検索対象業種分類データを更新
export const INDUSTRY_CLASS_DATA_UPDATE =
  "tools/screening/industry/INDUSTRY_CLASS_DATA_UPDATE";
// スクリーニング条件検索データ更新
export const CONDITION_DATA_UPDATE =
  "tools/screening/industry/CONDITION_DATA_UPDATE";
// 検索条件結果件数取得
export const MATCH_COUNT_GET = "tools/screening/industry/MATCH_COUNT_GET";
// 企業スクリーニングツリー情報を検索(再取得)
export const SCREENING_TREE_GET = "tools/screening/industry/SCREENING_TREE_GET";
// 入力エリアデータリストの更新
export const INPUT_AREA_DATA_LIST_UPDATE =
  "tools/screening/industry/INPUT_AREA_DATA_LIST_UPDATE";
// ツリー子ノード情報取得
export const CHILD_DATA_GET = "tools/screening/industry/CHILD_DATA_GET";
// ツリー階層の項目情報(入力アイテムを追加できるかどうか)取得
export const USER_CONDITION_GET = "tools/screening/industry/USER_CONDITION_GET";
// 指定したスクリーニングIDに対する入力項目情報(どんな入力アイテム形式か)の取得
export const USER_CONDITION_ADD_GET =
  "tools/screening/industry/USER_CONDITION_ADD_GET";
// "条件を保存"前に保存可能かチェック
export const FAVORITE_CHECK = "tools/screening/industry/FAVORITE_CHECK";
// "条件を保存"からキャンセル(一時データを削除)
export const SAVE_FAVORITE_CANCEL =
  "tools/screening/industry/SAVE_FAVORITE_CANCEL";
// "条件を保存"モーダルで条件を保存する
export const FAVORITE_SAVE = "tools/screening/industry/FAVORITE_SAVE";
// スクリーニング結果の企業コードを取得
export const SCREENING_RESULT_GET =
  "tools/screening/industry/SCREENING_RESULT_GET";
// 比較表ヘッダ情報取得（仮）
export const COMPARISON_HEAD_GET =
  "tools/screening/industry/COMPARISON_HEAD_GET";
// 比較表明細情報取得（仮）
export const COMPARISON_DATA_GET =
  "tools/screening/industry/COMPARISON_DATA_GET";
// 比較表ソート取得
export const COMPARISON_DATA_SORT =
  "tools/screening/industry/COMPARISON_DATA_SORT";
// 比較表行削除
export const COMPARISON_DATA_ROW_REMOVE =
  "tools/screening/industry/COMPARISON_DATA_ROW_REMOVE";
// ユーザが表示キャンセル時に表データをクリアする
export const COMPARISON_DATA_CLEAR =
  "tools/screening/industry/COMPARISON_DATA_CLEAR";
// 比較表セレクトボックス等の選択状態更新
export const COMPARISON_CONDITIONS_UPDATE =
  "tools/screening/industry/COMPARISON_CONDITIONS_UPDATE";
// 散布図選択件数の上限値を取得
export const CHART_COUNT_GET = "tools/screening/industry/CHART_COUNT_GET";
// チャート情報を取得
export const CHART_DATA_GET = "tools/screening/industry/CHART_DATA_GET";
// チャート情報を更新
export const CHART_DATA_UPDATE = "tools/screening/industry/CHART_DATA_UPDATE";
// 保存データの内容を state に適用する
export const RESULT_DATA_APPLY = "tools/screening/industry/RESULT_DATA_APPLY";
// 保存した条件(スクリーニング検索条件)一覧取得
export const SAVED_FAVORITE_GET = "tools/screening/industry/SAVED_FAVORITE_GET";
// 保存した条件(スクリーニング検索条件)の削除
export const SAVED_FAVORITE_DELETE =
  "tools/screening/industry/SAVED_FAVORITE_DELETE";
// 保存した条件(スクリーニング検索条件)の更新
export const SAVED_FAVORITE_UPDATE =
  "tools/screening/industry/SAVED_FAVORITE_UPDATE";
// 保存した条件(スクリーニング検索条件)の詳細取得
export const SAVED_FAVORITE_DETAIL_GET =
  "tools/screening/industry/SAVED_FAVORITE_DETAIL_GET";
// 登録した条件から選択 で選択中の条件をクリアする
export const SELECTED_CONDITION_CLEAR =
  "tools/screening/industry/SELECTED_CONDITION_CLEAR";
// 指標ツリーのルート階層を取得
export const INDEX_TREE_ROOT_GET =
  "tools/screening/industry/INDEX_TREE_ROOT_GET";
// 指標ツリーの子ノードを取得
export const INDEX_TREE_CHILD_GET =
  "tools/screening/industry/INDEX_TREE_CHILD_GET";
// 指標を検索条件に追加できるかを取得
export const INDEX_AVAILABILITY_GET =
  "tools/screening/industry/INDEX_AVAILABILITY_GET";
// 指標を検索条件に追加
export const INDEX_ADD = "tools/screening/industry/INDEX_ADD";
// 指標の選択状態を更新
export const INDEX_UPDATE = "tools/screening/industry/INDEX_UPDATE";
// 指標を検索条件からすべて削除
export const INDEX_REMOVE_ALL = "tools/screening/industry/INDEX_REMOVE_ALL";
// 保存した検索条件セレクトボックスの中身取得
export const SAVED_CONDITION_SELECT_GET =
  "tools/screening/industry/SAVED_CONDITION_SELECT_GET";
// 保存した検索条件一覧取得
export const SAVED_CONDITION_GET =
  "tools/screening/industry/SAVED_CONDITION_GET";
// 保存した検索条件の削除
export const SAVED_CONDITION_DELETE =
  "tools/screening/industry/SAVED_CONDITION_DELETE";
// 保存した検索条件の更新
export const SAVED_CONDITION_UPDATE =
  "tools/screening/industry/SAVED_CONDITION_UPDATE";
// 保存した検索条件の詳細取得
export const SAVED_CONDITION_DETAIL_GET =
  "tools/screening/industry/SAVED_CONDITION_DETAIL_GET";
// 保存した検索条件の取得
export const CONDITION_SET = "tools/screening/industry/CONDITION_SET";
// 指標編集系の全てデータをクリアする
export const INDEX_ALL_CLEAR = "tools/screening/industry/INDEX_ALL_CLEAR";

/**
 * 比較表データ再取得系
 */
// 再検索フラグをセット／リセット
export const SEARCH_FLAG_SET = "tools/screening/industry/SEARCH_FLAG_SET";
export const SEARCH_FLAG_RESET = "tools/screening/industry/SEARCH_FLAG_RESET";
// ソート状態を解除
export const SORT_INFO_UNSET = "tools/screening/industry/SORT_INFO_UNSET";

// ダウンロード時の期間セレクトボックス情報取得
export const DOWNLOAD_PERIOD_GET =
  "tools/screening/industry/DOWNLOAD_PERIOD_GET";
export const DOWNLOAD_PERIOD_SET =
  "tools/screening/industry/DOWNLOAD_PERIOD_SET";

// ページ遷移時にキャッシュ削除
export const ALL_RESET = "tools/screening/industry/ALL_RESET";
