import { OperandRaw } from "features/RawInput";
import { RawOperand } from "models/GlobalSearch";
import { Witch } from ".";

// 検索履歴状態保存
export const setHistoryModalOpenStatus = (status: boolean) => ({
  type: "operandRawSearch/HISTORY_MODAL_SET" as const,
  payload: {
    status
  }
});

// 入力モード保存
export const setAdvancedMode = (mode: boolean, witch: Witch) => ({
  type: "operandRawSearch/ADVANCED_MODE" as const,
  payload: {
    mode,
    witch
  }
});

// 検索履歴保存（標準検索）
export const setHistory = (history: string, witch: Witch) => ({
  type: "operandRawSearch/HISTORY_SET" as const,
  payload: {
    history,
    witch
  }
});

// 検索履歴保存（高度な検索）
export const setAdvancedHistory = (history: OperandRaw, witch: Witch) => ({
  type: "operandRawSearch/ADVANCED_HISTORY_SET" as const,
  payload: {
    history,
    witch
  }
});

// 検索履歴リセット
export const historyReset = (history: string[] = [], witch: Witch) => ({
  type: "operandRawSearch/HISTORY_RESET" as const,
  payload: {
    history,
    witch
  }
});

// 検索履歴リセット高度検索
export const advancedHistoryReset = (
  history: RawOperand[] = [],
  witch: Witch
) => ({
  type: "operandRawSearch/ADVANCED_HISTORY_RESET" as const,
  payload: {
    history,
    witch
  }
});

// 検索履歴クリア
export const clearHistory = () => ({
  type: "operandRawSearch/CLEAR_HISTORY" as const
});

export type OperandRawSearchAction = ReturnType<
  | typeof setHistoryModalOpenStatus
  | typeof setAdvancedMode
  | typeof setHistory
  | typeof setAdvancedHistory
  | typeof historyReset
  | typeof advancedHistoryReset
  | typeof clearHistory
>;
