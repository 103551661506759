import React from "react";
import styles from "./style.scss";

const Overview = props => {
  return (
    <div
      className={styles.component}
      data-testid="Common-Popup-Overview"
      dangerouslySetInnerHTML={{ __html: props.body }}
    />
  );
};

export default Overview;
