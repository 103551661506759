import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

import { isEmpty } from "utils/helper/common";

import { selectTable } from "components/Common/SegmentComparisonTable/Table/sourceSelectData";

const initialState = {
  conditionData: {},
  selectedCondition: null, // 登録した条件から保存 で選択中の value 値
  tempSaveFavoriteData: {}, // "条件を保存" から保存リクエストする際に必要なパラメータセット
  screeningResult: {},
  isLoadingScreeningResult: false,
  comparisonHead: {},
  isLoadingComparisonHead: false,
  comparisonData: {},
  isLoadingComparisonData: false,
  comparisonConditions: {},
  chartCount: "0",
  isLoadingChartCount: false,
  chartData: {},
  isLoadingChartData: false,
  needsMstS: [],
  needsMstM: [],
  isLoadingNeedsMstS: false,
  isLoadingNeedsMstM: false,
  searchResult: [],
  isEmptySearchResult: false,
  savedFavoriteList: [],
  isLoadingSavedFavoriteDetail: false,
  error: null
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      conditionData: action.payload,
      selectedCondition: state.selectedCondition || action.payload.userCondition
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_PENDING`]: state => ({
      ...state,
      screeningResult: {},
      comparisonData: {}, // クリア
      isLoadingScreeningResult: true
    }),
    [`${ActionTypes.SCREENING_RESULT_GET}_FULFILLED`]: (state, action) => {
      // 選択、入力状態をクリア
      const comparisonConditions = {
        toolsearchKeyword: action.payload.screeningIdAndConditionsListStr
      };
      // 比較表 企業種別チェック状態があれば保持
      const sourceItemKeys = ["listingCorpSelect", "unlistedHolderCorpSelect"];
      sourceItemKeys.forEach(key => {
        if (state.comparisonConditions.hasOwnProperty(key))
          comparisonConditions[key] = state.comparisonConditions[key];
      });
      return {
        ...state,
        screeningResult: action.payload,
        isLoadingScreeningResult: false,
        comparisonConditions
      };
    },
    [`${ActionTypes.SCREENING_RESULT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      screeningResult: {},
      comparisonConditions: {}, // 選択、入力状態をクリア
      isLoadingScreeningResult: false,
      error: action.payload
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonHead: true
    }),
    [`${ActionTypes.COMPARISON_HEAD_GET}_FULFILLED`]: (state, action) => {
      const comparisonHead = action.payload;
      const newComparisonConditions = { ...state.comparisonConditions };
      // 初期データとしてチェックボックスの選択状態をマージ
      // セグメントの条件入力にはチェックボックスはないが、比較表で使用する
      // スクリーニング(セグメント)の場合は前にチェックした状態を保持する
      for (const prop in selectTable) {
        const key = selectTable[prop];
        if (!newComparisonConditions.hasOwnProperty(key))
          newComparisonConditions[key] = comparisonHead[key];
      }
      const {
        userIndexGroupListOfBasic,
        userIndexGroupListOfBusiness
      } = comparisonHead.userIndexGroupListDto;
      const userIndexValue = !isEmpty(userIndexGroupListOfBusiness)
        ? userIndexGroupListOfBusiness[0].value
        : userIndexGroupListOfBasic[0].value;
      newComparisonConditions["userIndexValue"] = userIndexValue;
      newComparisonConditions["accKbnValue"] =
        comparisonHead.accKbnList[0].value;
      newComparisonConditions["ytConditionValue"] =
        comparisonHead.ytCommonConditionList[0].value;
      newComparisonConditions["scConditionValue"] =
        comparisonHead.scCommonConditionList[0].value;
      newComparisonConditions["currencyConditionValue"] =
        comparisonHead.currencyList[0].value;
      newComparisonConditions["ltmValue"] = "0";
      newComparisonConditions["expectValue"] = "0";
      return {
        ...state,
        comparisonHead: comparisonHead,
        comparisonConditions: newComparisonConditions,
        isLoadingComparisonHead: false
      };
    },
    [`${ActionTypes.COMPARISON_HEAD_GET}_REJECTED`]: (state, action) => ({
      ...state,
      comparisonHead: {},
      isLoadingComparisonHead: false,
      error: action.payload
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true,
      init: false
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      comparisonData: action.payload,
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [`${ActionTypes.COMPARISON_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {}, // チャートデータクリア
      error: action.payload
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_SORT}_FULFILLED`]: (state, action) => {
      const sortData = action.payload;
      return {
        ...state,
        comparisonData: {
          ...state.comparisonData,
          maxWidthOfAllColumns: sortData.maxWidthOfAllColumns,
          tableData: sortData.tableData
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_SORT}_REJECTED`]: (state, action) => ({
      ...state,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {}, // チャートデータクリア
      error: action.payload
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_PENDING`]: state => ({
      ...state,
      isLoadingComparisonData: true
    }),
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_FULFILLED`]: (
      state,
      action
    ) => {
      const sortData = action.payload;
      return {
        ...state,
        comparisonData: {
          ...state.comparisonData,
          // 必要なデータだけマージする
          currentNkCodeList: sortData.currentNkCodeList,
          nkCodeListTotalList: sortData.nkCodeListTotalList,
          errorMessage: sortData.errorMessage,
          fullNumber: sortData.fullNumber,
          hasReachedMaxDisplayableNkcodeCount:
            sortData.hasReachedMaxDisplayableNkcodeCount,
          listingNkcodeCount: sortData.listingNkcodeCount,
          messageDto: sortData.messageDto,
          tableData: sortData.tableData,
          unListedHolderNkcodeCount: sortData.unListedHolderNkcodeCount
        },
        isLoadingComparisonData: false,
        chartData: {} // チャートデータクリア
      };
    },
    [`${ActionTypes.COMPARISON_DATA_ROW_REMOVE}_REJECTED`]: (
      state,
      action
    ) => ({
      ...state,
      error: action.payload,
      comparisonData: {},
      isLoadingComparisonData: false,
      chartData: {} // チャートデータクリア
    }),
    [ActionTypes.COMPARISON_DATA_CLEAR]: state => ({
      ...state,
      screeningResult: {},
      comparisonData: {}, // クリア
      comparisonConditions: {} // 選択、入力状態をクリア
    }),
    [ActionTypes.COMPARISON_CONDITIONS_UPDATE]: (state, action) => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        ...action.payload
      }
    }),
    [`${ActionTypes.CHART_COUNT_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartCount: true
    }),
    [`${ActionTypes.CHART_COUNT_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData:
        parseInt(action.payload) >= action.meta.nkcodeInfosLength
          ? state.chartData
          : {},
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_COUNT_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartCount: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_PENDING`]: state => ({
      ...state,
      isLoadingChartData: true
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      chartData: {
        ...state.chartData,
        chart01Str: action.payload.chart01Str,
        chart02Str: action.payload.chart02Str,
        chart03Str: action.payload.chart03Str,
        chart04Str: action.payload.chart04Str
      },
      isLoadingChartData: false
    }),
    [`${ActionTypes.CHART_DATA_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload,
      isLoadingChartData: false
    }),
    [ActionTypes.CHART_DATA_CLEAR]: state => ({
      ...state,
      chartData: {}
    }),
    [ActionTypes.RESULT_DATA_APPLY]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [`${ActionTypes.FAVORITE_CHECK}_FULFILLED`]: (state, action) => ({
      ...state,
      tempSaveFavoriteData: action.meta
    }),
    [`${ActionTypes.FAVORITE_SAVE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 保存した条件が選択中の条件になるよう更新
      selectedCondition: action.payload.selectedCondition,
      // 登録した条件から選択 セレクトリストの更新
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      tempSaveFavoriteData: {}
    }),
    [ActionTypes.SAVE_FAVORITE_CANCEL]: state => ({
      ...state,
      tempSaveFavoriteData: {} // 一時データを削除する
    }),
    [`${ActionTypes.ALL_CONDITION_REMOVE}_FULFILLED`]: (state, action) => ({
      ...state,
      selectedCondition: state.conditionData.userConditionList[0].value,
      conditionData: {
        ...state.conditionData,
        toolScreeningBusinessDto: {
          ...state.conditionData.toolScreeningBusinessDto,
          toolCommon06DtoList: action.payload.toolCommon06DtoList
        }
      }
    }),
    [ActionTypes.SELECTED_INDUSTRIES_CLEAR]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      searchResult: [],
      isEmptySearchResult: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_PENDING`]: state => ({
      ...state,
      needsMstS: [],
      isLoadingNeedsMstS: true
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstS: action.payload.needsMstSList,
      isLoadingNeedsMstS: false
    }),
    [`${ActionTypes.NEEDS_MST_S_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingNeedsMstS: false,
      error: action.payload
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_PENDING`]: state => ({
      ...state,
      needsMstM: [],
      needsMstS: [],
      isLoadingNeedsMstM: true
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      needsMstM: action.payload.needsMstMList,
      needsMstS: [],
      isLoadingNeedsMstM: false
    }),
    [`${ActionTypes.NEEDS_MST_M_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingNeedsMstM: false,
      error: action.payload
    }),
    [`${ActionTypes.INDUSTRY_SEARCH}_FULFILLED`]: (state, action) => ({
      ...state,
      searchResult: action.payload.map(item => {
        let className = "",
          industryName = "",
          industryCodeDisp = "";
        if (!isEmpty(item.nkgncodes)) {
          className = "小分類";
          industryName = item.nkgnnames;
          industryCodeDisp = item.nkgncodes;
        } else if (!isEmpty(item.nkgncodem)) {
          className = "中分類";
          industryName = item.nkgnnamem;
          industryCodeDisp = item.nkgncodem;
        } else {
          className = "大分類";
          industryName = item.nkgnnamel;
          industryCodeDisp = item.nkgncodel;
        }
        return {
          className: className,
          industryName: industryName,
          industryCode: (
            item.nkgncodel +
            item.nkgncodem +
            item.nkgncodes +
            "         "
          ).substr(0, 9),
          industryCodeDisp: industryCodeDisp,
          isActive: true
        };
      }),
      isEmptySearchResult: isEmpty(action.payload)
    }),
    [`${ActionTypes.INDUSTRY_SEARCH}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    // 登録条件系
    [`${ActionTypes.SAVED_FAVORITE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_DELETE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 選択中の項目が消された場合の考慮
      selectedCondition: action.payload.userConditionList.find(
        condition => condition.value == state.selectedCondition
      )
        ? state.selectedCondition
        : action.payload.userConditionList[0].value,
      // 登録した条件から選択 セレクトボックスで使うデータも更新する
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      // 登録した条件から選択 セレクトボックスで使うデータも更新する
      conditionData: {
        ...state.conditionData,
        userConditionList: action.payload.userConditionList
      },
      savedFavoriteList: action.payload.screeningUserFavoliteList
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_PENDING`]: state => ({
      ...state,
      isLoadingSavedFavoriteDetail: true
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_FULFILLED`]: (
      state,
      action
    ) => ({
      ...state,
      selectedCondition: action.meta.favoriteId,
      conditionData: {
        ...state.conditionData,
        toolScreeningBusinessDto: {
          ...state.conditionData.toolScreeningBusinessDto,
          toolCommon06DtoList: action.payload.toolCommon06DtoList
        }
      },
      screeningResult: {},
      comparisonHead: {},
      comparisonData: {},
      isLoadingSavedFavoriteDetail: false
    }),
    [`${ActionTypes.SAVED_FAVORITE_DETAIL_GET}_REJECTED`]: (state, action) => ({
      ...state,
      isLoadingSavedFavoriteDetail: false,
      error: action.payload
    }),

    /**
     * 比較表データ再取得系
     */
    [ActionTypes.SEARCH_FLAG_SET]: (state, action) => ({
      ...state,
      needsSearch: true,
      // 初回リクエストのみ init を付与する必要があるため、 payload から受けとる
      init: action.payload && action.payload.init ? true : false
    }),
    [ActionTypes.SEARCH_FLAG_RESET]: state => ({
      ...state,
      needsSearch: false
    }),
    [ActionTypes.SORT_INFO_UNSET]: state => ({
      ...state,
      comparisonConditions: {
        ...state.comparisonConditions,
        sortId: "",
        sortFlag: ""
      }
    }),
    [ActionTypes.ALL_RESET]: () => initialState
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(716, params)),
  getScreeningResult: createAction(
    ActionTypes.SCREENING_RESULT_GET,
    params => request(729, params),
    params => ({ toolsearchKeyword: params.favoliteDetail })
  ),
  getComparisonHead: createAction(ActionTypes.COMPARISON_HEAD_GET, params =>
    request(595, params)
  ),
  getComparisonData: createAction(ActionTypes.COMPARISON_DATA_GET, params =>
    request(597, params)
  ),
  sortComparisonData: createAction(ActionTypes.COMPARISON_DATA_SORT, params =>
    request(609, params)
  ),
  removeComparisonDataRow: createAction(
    ActionTypes.COMPARISON_DATA_ROW_REMOVE,
    params => request(597, params)
  ),
  clearComparisonData: createAction(ActionTypes.COMPARISON_DATA_CLEAR),
  updateComparisonConditions: createAction(
    ActionTypes.COMPARISON_CONDITIONS_UPDATE
  ),
  getChartCount: createAction(
    ActionTypes.CHART_COUNT_GET,
    params => request(614, params.payload),
    params => params.meta
  ),
  getChartData: createAction(ActionTypes.CHART_DATA_GET, params =>
    request(629, params)
  ),
  updateChartData: createAction(ActionTypes.CHART_DATA_UPDATE, params =>
    request(630, params)
  ),
  clearChartData: createAction(ActionTypes.CHART_DATA_CLEAR),
  applyResultData: createAction(ActionTypes.RESULT_DATA_APPLY),
  checkFavorite: createAction(
    ActionTypes.FAVORITE_CHECK,
    params => request(725, params),
    // 保存処理を行う際に必要なパラメータを meta に仕込み、リクエスト成功時に tempSaveFavoriteData に格納
    params => ({
      favoriteId: params.favoliteId,
      favoriteName: params.favoliteName,
      favoriteDetail: params.favoliteDetail,
      favoriteDetailLabel: params.favoliteDetailLabel
    })
  ),
  saveFavorite: createAction(ActionTypes.FAVORITE_SAVE, params =>
    request(726, params)
  ),
  cancelSaveFavorite: createAction(ActionTypes.SAVE_FAVORITE_CANCEL),
  clearSelectedIndustries: createAction(ActionTypes.SELECTED_INDUSTRIES_CLEAR),
  getNeedsMstS: createAction(ActionTypes.NEEDS_MST_S_GET, params =>
    request(722, params)
  ),
  getNeedsMstM: createAction(ActionTypes.NEEDS_MST_M_GET, params =>
    request(721, params)
  ),
  searchIndustry: createAction(ActionTypes.INDUSTRY_SEARCH, params =>
    request(845, params)
  ),
  // 登録条件系
  getSavedFavoriteList: createAction(ActionTypes.SAVED_FAVORITE_GET, params =>
    request(723, params)
  ),
  deleteSavedFavorite: createAction(ActionTypes.SAVED_FAVORITE_DELETE, params =>
    request(728, params)
  ),
  updateSavedFavorite: createAction(ActionTypes.SAVED_FAVORITE_UPDATE, params =>
    request(727, params)
  ),
  getSavedFavoriteDetail: createAction(
    ActionTypes.SAVED_FAVORITE_DETAIL_GET,
    params => request(724, params),
    // selectedCondition に残したいパラメータのみを meta に渡す
    params => ({ favoriteId: params.favoliteId })
  ),
  removeAllCondition: createAction(ActionTypes.ALL_CONDITION_REMOVE, params =>
    request(720, params)
  ),

  // 比較表データ再取得系
  setSearchFlag: createAction(ActionTypes.SEARCH_FLAG_SET),
  resetSearchFlag: createAction(ActionTypes.SEARCH_FLAG_RESET),
  unsetSortInfo: createAction(ActionTypes.SORT_INFO_UNSET),

  resetAll: createAction(ActionTypes.ALL_RESET)
};
