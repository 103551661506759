import React, { useEffect } from "react";
import styles from "./style.scss";

import closeIcon from "images/icon/close.svg";

import { isEmpty } from "utils/helper/common";
import { useToast } from "hooks/useToast";
import { useReduxState } from "hooks/useReduxState";
import { useLocationCheck } from "hooks/useLocationCheck";

/**
 * トースト
 *
 * @prop {string[]} messages - メッセージの配列
 * @prop {string[]} errorMessages - メッセージの配列
 * @prop {?function} onClose - 閉じるボタンで閉じた際に呼ばれる Callback
 * @prop {?function} onErrorClose - 閉じるボタンで閉じた際に呼ばれる Callback
 */
const Toast: React.FunctionComponent = () => {
  const messages = useReduxState(state => state.toast.messages);
  const errorMessages = useReduxState(state => state.toast.errors);

  const { clearMessages, clearErrors } = useToast();

  const { isSameCorpOrIndustryPage } = useLocationCheck();

  useEffect(() => {
    if (isSameCorpOrIndustryPage()) return;

    // トーストを消す
    clearErrors();
    clearMessages();
  }, [isSameCorpOrIndustryPage, clearErrors, clearMessages]);

  if (isEmpty(messages) && isEmpty(errorMessages)) return null;

  return (
    <div className={styles.component}>
      {!isEmpty(messages) && (
        <div className={styles.toast} data-testid="Common-Toast-toast">
          <div className={styles.buttonWrapper} />
          <div className={styles.messages}>
            {messages.map((message, index) => {
              return (
                <div
                  key={index}
                  className={styles.message}
                  data-testid="Common-Toast-Message"
                >
                  {message}
                </div>
              );
            })}
          </div>
          <div className={styles.buttonWrapper}>
            <img
              src={closeIcon}
              className={styles.icon}
              onClick={clearMessages}
              data-testid="Common-Toast-MessagesButton"
            />
          </div>
        </div>
      )}
      {!isEmpty(errorMessages) && (
        <div
          className={`${styles.toast} ${styles.error}`}
          data-testid="Common-Toast-toast"
        >
          <div className={styles.buttonWrapper} />
          <div className={styles.messages}>
            {errorMessages.map((message, index) => {
              return (
                <div
                  key={index}
                  className={styles.message}
                  data-testid="Common-Toast-ErrorMessages"
                >
                  {message}
                </div>
              );
            })}
          </div>
          <div className={styles.buttonWrapper}>
            <img
              src={closeIcon}
              className={styles.icon}
              onClick={() => clearErrors(true)}
              data-testid="Common-Toast-clearErrorsButton"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Toast;
