import React from "react";
import styles from "./style.scss";

import SectionTitle from "components/Common/SectionTitle";

const Investment = props => {
  const {
    placeMarket,
    kigyouName,
    stkcode,
    shareholderNikkeiCode,
    shareholderName,
    resourceId
  } = props;
  const files = [
    {
      type: "xls",
      params: {
        ...props.corpParams,
        placeMarket,
        kigyouName,
        stkcode,
        shareholderNikkeiCode,
        shareholderName
      },
      key: "investmentXls"
    }
  ];

  const renderInvestmentsList = list => {
    return (
      <div className={styles.investments} data-testid="Common-Popup-Investment">
        <table className={styles.vsTable}>
          <thead>
            <tr>
              <th>株式コード</th>
              <th>会社名</th>
              <th>決算期</th>
              <th>持株数（千株）</th>
              <th>所有割合（%）</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index}>
                <td>{item.stkcode}</td>
                <td>{item.companyName}</td>
                <td>{item.acc}</td>
                <td>{item.count}</td>
                <td>{item.ratio}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <React.Fragment>
      <SectionTitle files={files} resourceId={resourceId}>
        {props.title}
      </SectionTitle>
      {/* 大株主の投資先一覧 */}
      {props.investmentsList !== undefined &&
        renderInvestmentsList(props.investmentsList)}
    </React.Fragment>
  );
};

export default Investment;
