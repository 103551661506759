/**
 * 比較表
 */
// 初期表示
export const PAGE_INIT = "tools/comparison/corp/PAGE_INIT";
// 表の最大表示件数取得
export const GET_ITEMS_PER_PAGE = "tools/comparison/corp/GET_ITEMS_PER_PAGE";
// 表データの取得
export const LIST_CHANGE = "tools/comparison/corp/LIST_CHANGE";
export const FINANCE_ROW_DELETE = "tools/comparison/corp/FINANCE_ROW_DELETE";
export const SEGMENT_ROW_DELETE = "tools/comparison/corp/SEGMENT_ROW_DELETE";
export const FINANCE_SEARCH = "tools/comparison/corp/FINANCE_SEARCH";
export const SEGMENT_SEARCH = "tools/comparison/corp/SEGMENT_SEARCH";
export const FINANCE_SORT = "tools/comparison/corp/FINANCE_SORT";
export const SEGMENT_SORT = "tools/comparison/corp/SEGMENT_SORT";
export const COMPARISON_DATAS_CLEAR =
  "tools/comparison/corp/COMPARISON_DATAS_CLEAR";
// 条件選択状態の変更
export const SELECTED_CHANGE = "tools/comparison/corp/SELECTED_CHANGE";
export const SEGMENT_SELECTED_CHANGE =
  "tools/comparison/corp/SEGMENT_SELECTED_CHANGE";
// ダウンロード時の期間セレクトボックス情報取得
export const DOWNLOAD_PERIOD_GET = "tools/comparison/corp/DOWNLOAD_PERIOD_GET";
export const DOWNLOAD_PERIOD_SET = "tools/comparison/corp/DOWNLOAD_PERIOD_SET";
// 比較表タブきりかえ
export const TAB_SWITCH = "tools/comparison/corp/TAB_SWITCH";

/**
 * ユーザ指標
 */
// 指標ツリーのルート階層を取得
export const INDEX_TREE_ROOT_GET = "tools/comparison/corp/INDEX_TREE_ROOT_GET";
// 指標ツリーの子ノードを取得
export const INDEX_TREE_CHILD_GET =
  "tools/comparison/corp/INDEX_TREE_CHILD_GET";
// 指標を検索条件に追加できるかを取得
export const INDEX_AVAILABILITY_GET =
  "tools/comparison/corp/INDEX_AVAILABILITY_GET";
// 指標を検索条件に追加
export const INDEX_ADD = "tools/comparison/corp/INDEX_ADD";
// 指標を検索条件から削除
export const INDEX_UPDATE = "tools/comparison/corp/INDEX_UPDATE";
// 指標を検索条件からすべて削除
export const INDEX_REMOVE_ALL = "tools/comparison/corp/INDEX_REMOVE_ALL";
// 保存した検索条件セレクトボックスの中身取得
export const SAVED_CONDITION_SELECT_GET =
  "tools/comparison/corp/SAVED_CONDITION_SELECT_GET";
// 保存した検索条件一覧取得
export const SAVED_CONDITION_GET = "tools/comparison/corp/SAVED_CONDITION_GET";
// 保存した検索条件の削除
export const SAVED_CONDITION_DELETE =
  "tools/comparison/corp/SAVED_CONDITION_DELETE";
// 保存した検索条件の更新
export const SAVED_CONDITION_UPDATE =
  "tools/comparison/corp/SAVED_CONDITION_UPDATE";
// 保存した検索条件の詳細取得
export const SAVED_CONDITION_DETAIL_GET =
  "tools/comparison/corp/SAVED_CONDITION_DETAIL_GET";
// 保存した検索条件の取得
export const CONDITION_SET = "tools/comparison/corp/CONDITION_SET";
export const RESULT_DATA_APPLY = "tools/comparison/corp/RESULT_DATA_APPLY";
export const INDEX_ALL_CLEAR = "tools/comparison/corp/INDEX_ALL_CLEAR";
export const COMPARISON_HEAD_GET = "tools/comparison/corp/COMPARISON_HEAD_GET";

/**
 * チャート
 */
// 散布図選択件数の上限値を取得
export const CHART_COUNT_GET = "tools/comparison/corp/CHART_COUNT_GET";
// チャート情報を取得
export const CHART_DATA_GET = "tools/comparison/corp/CHART_DATA_GET";
export const CHART_DATA_UPDATE = "tools/comparison/corp/CHART_DATA_UPDATE";
// チャート情報を初期化
export const CHART_DATA_CLEAR = "tools/comparison/corp/CHART_DATA_CLEAR";

/**
 * その他
 */
// 再検索フラグをセット／リセット
export const SEARCH_FINANCE_FLAG_SET =
  "tools/comparison/corp/SEARCH_FINANCE_FLAG_SET";
export const SEARCH_FINANCE_FLAG_RESET =
  "tools/comparison/corp/SEARCH_FINANCE_FLAG_RESET";
export const SEARCH_SEGMENT_FLAG_SET =
  "tools/comparison/corp/SEARCH_SEGMENT_FLAG_SET";
export const SEARCH_SEGMENT_FLAG_RESET =
  "tools/comparison/corp/SEARCH_SEGMENT_FLAG_RESET";
// ソート状態を解除
export const SORT_INFO_UNSET = "tools/comparison/corp/SORT_INFO_UNSET";

// NEEDS分類取得（大）
export const NEEDS_MST_L_GET = "tools/comparison/corp/NEEDS_MST_L_GET";
// NEEDS分類取得（中）
export const NEEDS_MST_M_GET = "tools/comparison/corp/NEEDS_MST_M_GET";
// NEEDS分類取得（小）
export const NEEDS_MST_S_GET = "tools/comparison/corp/NEEDS_MST_S_GET";
// 選択した小分類に所属する企業一覧の取得
export const CORP_GET = "tools/comparison/corp/CORP_GET";
// 企業分類フィルター選択変更時
export const FILTER_UPDATE = "tools/comparison/corp/FILTER_UPDATE";
// 企業検索
export const CORP_SELECT = "tools/comparison/corp/CORP_SELECT";
export const CORP_SEARCH = "tools/comparison/corp/CORP_SEARCH";
export const RECENT_GET = "tools/comparison/corp/RECENT_GET";
export const SELECTED_BOOKMARK_FOLDER_GET =
  "tools/comparison/corp/SELECTED_BOOKMARK_FOLDER_GET";
export const BOOKMARK_GET = "tools/comparison/corp/BOOKMARK_GET";
export const FOLDER_GET = "tools/comparison/corp/FOLDER_GET";

// 遷移元から企業コードを渡された場合
export const DEFAULT_SELECTED_CODES_SET =
  "tools/comparison/corp/DEFAULT_SELECTED_CODES_SET";
export const DEFAULT_SELECTED_CODES_CLEAR =
  "tools/comparison/corp/DEFAULT_SELECTED_CODES_CLEAR";

// ページ遷移時にキャッシュ削除
export const ALL_RESET = "tools/comparison/corp/ALL_RESET";
