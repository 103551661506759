exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-BinderPage-Common-CopyInput-style__inputArea--2YCZl {\n  border-radius: 3px;\n  border: solid 1px #C6E0F0;\n  background: linear-gradient(0deg, #eaf1f5, #eaf1f5);\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  padding: 0 15px;\n  min-height: 40px;\n  margin: 0 24px;\n}\n.src-features-BinderPage-Common-CopyInput-style__inputArea--2YCZl .src-features-BinderPage-Common-CopyInput-style__text--LbybB {\n  width: 424px;\n  height: auto;\n  word-wrap: break-word;\n  padding: 10px 0;\n}\n.src-features-BinderPage-Common-CopyInput-style__inputArea--2YCZl .src-features-BinderPage-Common-CopyInput-style__text--LbybB.src-features-BinderPage-Common-CopyInput-style__isClosedText--2RKDl {\n  width: 424px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.src-features-BinderPage-Common-CopyInput-style__inputArea--2YCZl .src-features-BinderPage-Common-CopyInput-style__expandIcon--3J_0X {\n  margin-right: 8px;\n  margin-top: 5px;\n  cursor: pointer;\n}\n.src-features-BinderPage-Common-CopyInput-style__inputArea--2YCZl .src-features-BinderPage-Common-CopyInput-style__copyIcon--2DDN3 {\n  margin-top: 5px;\n  cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"inputArea": "src-features-BinderPage-Common-CopyInput-style__inputArea--2YCZl",
	"text": "src-features-BinderPage-Common-CopyInput-style__text--LbybB",
	"isClosedText": "src-features-BinderPage-Common-CopyInput-style__isClosedText--2RKDl",
	"expandIcon": "src-features-BinderPage-Common-CopyInput-style__expandIcon--3J_0X",
	"copyIcon": "src-features-BinderPage-Common-CopyInput-style__copyIcon--2DDN3"
};