import { EsgMaterialityAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";

export interface EsgMaterialityState {
  materialityList: any;
}

const initialState: EsgMaterialityState = {
  materialityList: {}
};

export const toolEsgMateriality: Reducer<
  EsgMaterialityState,
  EsgMaterialityAction
> = (state = initialState, action) => {
  switch (action.type) {
    case "tool/esg/materiality/SET_MATERIALITY_DATA":
      return produce(state, draft => {
        draft.materialityList = action.payload;
      });
    default:
      return state;
  }
};
