// 初期表示
export const PAGE_INIT = "corp/ir-data/PAGE_INIT";
// データ表示部データ取得
export const LOAD_DATA = "corp/ir-data/LOAD_DATA";
// 選択可能リスト項目取得
export const LOAD_ITEMS = "corp/ir-data/LOAD_ITEMS";
// 選択可能リストのチェックボックスを選択時処理。選択済み項目を追加
export const ADD_ITEMS = "corp/ir-data/ADD_ITEMS";
// 選択済み項目並び替え・削除時処理。選択済み項目を更新
export const REPLACE_ITEMS = "corp/ir-data/REPLACE_ITEMS";
// 選択済み項目全件削除時処理。選択済み項目を更新
export const REMOVE_ALL_ITEMS = "corp/ir-data/REMOVE_ALL_ITEMS";
// 期間、期種項目の変更
export const CONDITIONS_SET = "corp/ir-data/CONDITIONS_SET";
// 表示部を初期化するか
export const SHOULD_CONDITIONS_INITIALIZE_SET =
  "corp/ir-data/SHOULD_CONDITIONS_INITIALIZE_SET";
