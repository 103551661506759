import React, { useEffect, useRef } from "react";
import helper from "./helper";

interface Props {
  src: string;
  keywords?: string[];
}

const PdfViewer: React.FunctionComponent<Props> = props => {
  const viewerRef = useRef<HTMLDivElement>(null);
  const backend = new helper();

  const resize = () => {
    if (!viewerRef.current) return;
    const viewer = viewerRef.current.getElementsByTagName("iframe")[0];

    viewer.style.height = `${window.innerHeight}px`;
    viewer.style.verticalAlign = "bottom";
  };

  useEffect(() => {
    if (!viewerRef.current) return;

    backend.init(props.src, viewerRef.current, props.keywords);

    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={viewerRef}></div>;
};

export default PdfViewer;
