import React from "react";
import styles from "./style.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import cn from "classnames";

interface UrlTabItem {
  text: string;
  pathname: string;
  search?: string;
  count?: number | string;
}

interface TabProps {
  items: UrlTabItem[];
  borderColor?: "default" | "primary" | "none";
  onClick?: (item: UrlTabItem) => void;
  type?: "tab" | "button";
  className?: string;
}

/**
 * URL 遷移するタブ
 */
export const Tab: React.FunctionComponent<TabProps> = ({
  items,
  borderColor = "default",
  onClick,
  type = "tab",
  className
}) => (
  <ul
    className={cn(styles.component, styles[borderColor], className, {
      [styles.button]: type === "button"
    })}
  >
    {items.map((item, index) => (
      <LinkItem
        key={index}
        url={item.pathname + (item.search ?? "")}
        onClick={() => onClick?.(item)}
      >
        <div data-testid={`Tab-LinkItem-${item.text}`}>{item.text}</div>
        {item.count && (
          <div
            className={styles.number}
            data-testid={`Tab-LinkItem-${item.text}-count`}
          >
            {item.count}
          </div>
        )}
      </LinkItem>
    ))}
  </ul>
);

interface LinkItemProps {
  url: string;
  onClick: () => void;
}

const LinkItem: React.FunctionComponent<LinkItemProps> = props => {
  const location = useLocation();
  const itemUrl = new URL(props.url, window.location.href);
  const selected = itemUrl.pathname === location.pathname;
  return (
    <li>
      <Link
        to={props.url}
        className={cn(styles.item, { [styles.selected]: selected })}
        onClick={props.onClick}
      >
        {props.children}
      </Link>
    </li>
  );
};

interface SwitchTabItem {
  text: string;
  key: string;
  onSwitch?: () => void;
  count?: number;
}

interface SwitchTabProps {
  items: SwitchTabItem[];
  selectedKey: string;
  onSwitch: (item: SwitchTabItem) => void;
  borderColor?: "default" | "primary" | "none";
  className?: string;
  children: (JSX.Element | false)[];
  sendAtlas?: (tabtype: string) => void;
}

/**
 * 子要素の表示／非表示を切り替えるタブ
 */
export const SwitchTab: React.FunctionComponent<SwitchTabProps> = ({
  items,
  selectedKey,
  onSwitch,
  borderColor = "default",
  className,
  children,
  sendAtlas
}) => (
  <>
    <ul
      className={cn(
        styles.component,
        styles.switch,
        styles[borderColor],
        className
      )}
    >
      {items.map(item => (
        <SwitchItem
          key={item.key}
          selected={item.key === selectedKey}
          onClick={() => {
            item.onSwitch?.();
            onSwitch?.(item);
            sendAtlas?.(item.text);
          }}
        >
          <div>{item.text}</div>
          {item.count && <div className={styles.number}>{item.count}</div>}
        </SwitchItem>
      ))}
    </ul>
    {React.Children.map(children, (child, index) => {
      if (!child) return null;
      const tabKeys = items.map(item => item.key);
      if (typeof child.key !== "string" || !tabKeys.includes(child.key)) {
        console.error(
          `Contents key "${child.key}" is not including [${tabKeys.join(
            ", "
          )}].`
        );
      }
      return (
        <div
          key={index}
          className={cn({
            [styles.invisible]: child.key !== selectedKey
          })}
        >
          {child}
        </div>
      );
    })}
  </>
);

interface SwitchItemProps {
  selected: boolean;
  onClick: () => void;
}

const SwitchItem: React.FunctionComponent<SwitchItemProps> = props => (
  <li>
    <span
      data-testid="switch-item"
      className={cn(styles.item, { [styles.selected]: props.selected })}
      onClick={props.onClick}
    >
      {props.children}
    </span>
  </li>
);
