export const CHANGE_INPUT_RAW_TEXT = "globalSearch/CHANGE_INPUT_RAW_TEXT";
export const CHANGE_INPUT_RAW_OPERAND = "globalSearch/CHANGE_INPUT_RAW_OPERAND";
export const CLEAR_SUGGESTIONS = "globalSearch/CLEAR_SUGGESTIONS";
export const OPEN_ADVANCED_SEARCH = "globalSearch/OPEN_ADVANCED_SEARCH";
export const CLOSE_ADVANCED_SEARCH = "globalSearch/CLOSE_ADVANCED_SEARCH";
export const TOGGLE_ADVANCED_SEARCH = "globalSearch/TOGGLE_ADVANCED_SEARCH";
export const SET_IS_TOGGLE = "globalSearch/SET_IS_TOGGLE";
export const REQUEST_SUGGESTIONS = "globalSearch/REQUEST_SUGGESTIONS";
export const CURSOR_UP_SUGGESTION_ACTIVE_INDEX =
  "globalSearch/CURSOR_UP_SUGGESTION_ACTIVE_INDEX";
export const CURSOR_DOWN_SUGGESTION_ACTIVE_INDEX =
  "globalSearch/CURSOR_DOWN_SUGGESTION_ACTIVE_INDEX";
export const UNSET_SUGGESTION_ACTIVE_INDEX =
  "globalSearch/UNSET_SUGGESTION_ACTIVE_INDEX";
export const RESET_INPUT_TEXT = "globalSearch/RESET_INPUT_TEXT";
export const CHANGE_LAST_SEARCH = "globalSearch/CHANGE_LAST_SEARCH";
export const ABORT_SUGGESTIONS = "globalSearch/SET_ABORT_SUGGESTIONS";
export const SET_FORCE_SEARCH = "globalSearch/SET_FORCE_SEARCH";
