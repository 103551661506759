import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  tldTransitionId: "",
  transitionId: "",
  researchResult: {},
  sortOptionList: [],
  countryOptionList: [],
  sort: "",
  country: "",
  error: null
};

const setResultState = (state, action) => ({
  ...state,
  researchResult: action.payload.industryResearchReportListDto,
  tldTransitionId: action.payload.industryResearchReportListDto.tldTransitionId,
  transitionId: action.payload.industryResearchReportListDto.transitionId
});

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...setResultState(state, action),
      data: action.payload
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.RESEARCH_PAGE_GET}_FULFILLED`]: setResultState,
    [`${ActionTypes.RESEARCH_PAGE_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.RESEARCH_SORTED_GET}_FULFILLED`]: setResultState,
    [`${ActionTypes.RESEARCH_SORTED_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.RESEARCH_COUNTRY_GET}_FULFILLED`]: setResultState,
    [`${ActionTypes.RESEARCH_COUNTRY_GET}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.RESEARCH_INDUSTRY_CHANGE}_FULFILLED`]: (state, action) => ({
      ...setResultState(state, action),
      data: action.payload
    }),
    [`${ActionTypes.RESEARCH_INDUSTRY_CHANGE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.OPTION_LIST_SET]: (state, action) => ({
      ...state,
      [`${action.meta.key}OptionList`]: action.payload,
      [action.meta.key]: action.payload[0].value
    }),
    [ActionTypes.OPTION_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(146, params)),
  getResearchPage: createAction(ActionTypes.RESEARCH_PAGE_GET, params =>
    request(149, params)
  ),
  getResearchSorted: createAction(ActionTypes.RESEARCH_SORTED_GET, params =>
    request(148, params)
  ),
  getResearchCountry: createAction(ActionTypes.RESEARCH_COUNTRY_GET, params =>
    request(152, params)
  ),
  changeResearchIndustry: createAction(
    ActionTypes.RESEARCH_INDUSTRY_CHANGE,
    params => request(147, params)
  ),
  setOptionList: createAction(
    ActionTypes.OPTION_LIST_SET,
    list => list,
    (params, key) => ({ key })
  ),
  setOption: createAction(
    ActionTypes.OPTION_SET,
    opt => opt,
    (params, key) => ({ key })
  )
};
