// 検索
export const SEARCH = "article/disclosure/SEARCH";
// ソート
export const SORT = "article/disclosure/SORT";
// ページング
export const PAGING = "article/disclosure/PAGING";
// 業界分類取得
export const GET_NEEDS_MST_L = "article/disclosure/GET_NEEDS_MST_L";
export const GET_NEEDS_MST_M = "article/disclosure/GET_NEEDS_MST_M";
export const GET_NEEDS_MST_S = "article/disclosure/GET_NEEDS_MST_S";
// 企業取得、追加、削除
export const GET_COMPANY_BY_SUGGEST =
  "article/disclosure/GET_COMPANY_BY_SUGGEST";
// サジェスト結果クリア
export const CLEAR_COMPANY_BY_SUGGEST =
  "article/disclosure/CLEAR_COMPANY_BY_SUGGEST";
export const GET_COMPANY = "article/disclosure/GET_COMPANY";
export const ADD_SELECTED_CORPS = "article/disclosure/ADD_SELECTED_CORPS";
export const REMOVE_SELECTED_CORP = "article/disclosure/REMOVE_SELECTED_CORP";
export const REMOVE_ALL_SELECTED_CORP =
  "article/disclosure/REMOVE_ALL_SELECTED_CORP";
// 資料区分取得、追加、削除
export const INIT_PAGE = "article/disclosure/PAGE_INIT";
export const GET_DOC_KBN_LIST = "article/disclosure/GET_DOC_KBN_LIST";
// 資料区分のチェック状態更新
export const CHANGE_DOC_KBN_LIST = "article/disclosure/CHANGE_DOC_KBN_LIST";
// 資料区分のALLチェック状態更新
export const CHANGE_ALL_DOC_KBN_LIST =
  "article/disclosure/CHANGE_ALL_DOC_KBN_LIST";
export const ADD_SELECTED_DOC_KBNS = "article/disclosure/ADD_SELECTED_DOC_KBNS";
export const REMOVE_SELECTED_DOC_KBN =
  "article/disclosure/REMOVE_SELECTED_DOC_KBN";
export const REMOVE_ALL_SELECTED_DOC_KBN =
  "article/disclosure/REMOVE_ALL_SELECTED_DOC_KBN";
// 資料フィルターチェックボックス初期化
export const FILTER_OPTION_LIST_INIT =
  "article/disclosure/FILTER_OPTION_LIST_INIT";
// 検索時の状態に戻す
export const SEARCHED_STATE_RESTORE =
  "article/disclosure/SEARCHED_STATE_RESTORE";
// ストアを初期化
export const ALL_RESET = "article/disclosure/ALL_RESET";
// 検索対象チェックボックスの初期状態セット
export const INIT_CHECKBOXES_SET = "article/disclosure/INIT_CHECKBOXES_SET";
// 検索対象チェックボックスの更新
export const CHECKBOXES_UPDATE = "article/disclosure/CHECKBOXES_UPDATE";
// 検索文字列の更新
export const KEYWORD_UPDATE = "article/disclosure/KEYWORD_UPDATE";
// 検索文字列の更新
export const OPERAND_RAW_UPDATE = "article/disclosure/OPERAND_RAW_UPDATE";
// 高度な検索：開閉
export const IS_OPEN_ADVANCED_SEARCH_UPDATE =
  "article/disclosure/IS_OPEN_ADVANCED_SEARCH_UPDATE";
// 検索from日付の設定
export const FROM_DATE_UPDATE = "article/disclosure/FROM_DATE_UPDATE";
// 検索to日付の設定
export const TO_DATE_UPDATE = "article/disclosure/TO_DATE_UPDATE";
// 検索期間：全期間かどうか
export const ENABLE_ALL_PERIOD_SET = "article/disclosure/ENABLE_ALL_PERIOD_SET";
// 検索条件をクリアする
export const CONDITIONS_CLEAR = "article/disclosure/CONDITIONS_CLEAR";
// 検索条件のローディング状態更新
export const CONDITIONS_LOADING_UPDATE =
  "article/disclosure/CONDITIONS_LOADING_UPDATE";
// 自動検索実行用フラグ
export const DO_FORCE_SEARCH_SET = "article/disclosure/DO_FORCE_SEARCH_SET";
// 検索対象チェックボックスの事前設定
export const PREPARED_CHECKBOXES_SET =
  "article/disclosure/PREPARED_CHECKBOXES_SET";
// 検索対象チェックボックス(EDINET)の事前設定
export const PREPARED_EDINET_SET = "article/disclosure/PREPARED_EDINET_SET";
