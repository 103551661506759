import { OperandRawSearchAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";
import { RawOperand } from "models/GlobalSearch";

export interface OperandRawSearchState {
  historyModalOpenStatus: boolean;
  advancedMode: {
    news: boolean;
    report: boolean;
    disclosure: boolean;
    collection?: boolean;
  };
  advancedHistory: {
    news: RawOperand[];
    report: RawOperand[];
    disclosure: RawOperand[];
    collection: RawOperand[];
  };
  history: {
    news: string[];
    report: string[];
    disclosure: string[];
    collection: string[];
  };
  isOpenHelpModal: boolean;
  isOpenHelpAddon: boolean;
}

export const initialState: OperandRawSearchState = {
  historyModalOpenStatus: false,
  advancedMode: {
    news: false,
    report: false,
    disclosure: false,
    collection: false
  },
  history: { news: [], report: [], disclosure: [], collection: [] },
  advancedHistory: { news: [], report: [], disclosure: [], collection: [] },
  isOpenHelpModal: false,
  isOpenHelpAddon: false
};

export type Witch = "news" | "report" | "disclosure" | "collection";

export const operandRawSearch: Reducer<
  OperandRawSearchState,
  OperandRawSearchAction
> = (state = initialState, action) => {
  switch (action.type) {
    case "operandRawSearch/HISTORY_MODAL_SET":
      return produce(state, draft => {
        draft.historyModalOpenStatus = action.payload.status;
      });
    case "operandRawSearch/ADVANCED_MODE":
      return produce(state, draft => {
        draft.advancedMode[action.payload.witch] = action.payload.mode;
      });
    case "operandRawSearch/HISTORY_SET":
      return produce(state, draft => {
        const historyIndex = draft.history[action.payload.witch].indexOf(
          action.payload.history
        );

        if (historyIndex === -1) {
          draft.history[action.payload.witch].push(action.payload.history);
        } else {
          draft.history[action.payload.witch].splice(historyIndex, 1);
          draft.history[action.payload.witch].push(action.payload.history);
        }
      });
    case "operandRawSearch/ADVANCED_HISTORY_SET":
      return produce(state, draft => {
        const advancedHistoryIndex = draft.advancedHistory[
          action.payload.witch
        ].findIndex(
          history =>
            JSON.stringify(history.and) ===
              JSON.stringify(action.payload.history.and) &&
            JSON.stringify(history.or) ===
              JSON.stringify(action.payload.history.or) &&
            JSON.stringify(history.not) ===
              JSON.stringify(action.payload.history.not)
        );

        if (advancedHistoryIndex === -1) {
          draft.advancedHistory[action.payload.witch].push(
            action.payload.history
          );
        } else {
          draft.advancedHistory[action.payload.witch].splice(
            advancedHistoryIndex,
            1
          );
          draft.advancedHistory[action.payload.witch].push(
            action.payload.history
          );
        }
      });
    case "operandRawSearch/HISTORY_RESET":
      return produce(state, draft => {
        draft.history[action.payload.witch] = action.payload.history;
      });
    case "operandRawSearch/ADVANCED_HISTORY_RESET":
      return produce(state, draft => {
        draft.advancedHistory[action.payload.witch] = action.payload.history;
      });
    case "operandRawSearch/CLEAR_HISTORY":
      return produce(state, draft => {
        draft.advancedMode = initialState.advancedMode;
        draft.history = initialState.history;
        draft.advancedHistory = initialState.advancedHistory;
        draft.historyModalOpenStatus = initialState.historyModalOpenStatus;
      });
    default:
      return state;
  }
};
