import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  condition: {},
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      condition: {
        selectedDivision: action.payload.accKbn,
        selectedUnit: action.payload.monetaryCode,
        selectedFrom: action.payload.accFrom,
        selectedTo: action.payload.accTo,
        selectedType: action.payload.accKind
      }
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.CONDITION_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    }),
    [`${ActionTypes.CONDITION_CHANGE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.FROM_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        selectedFrom: action.payload
      }
    }),
    [`${ActionTypes.TO_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        selectedTo: action.payload
      }
    }),
    [`${ActionTypes.DIVISION_UPDATE}_FULFILLED`]: (state, action) => {
      return {
        ...state,
        condition: {
          ...state.condition,
          selectedDivision: action.meta.accKbn,
          periodFromList: action.payload.periodFromList,
          periodToList: action.payload.periodToList,
          selectedFrom: action.payload.fromDate,
          selectedTo: action.payload.toDate
        }
      };
    },
    [`${ActionTypes.DIVISION_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        selectedDivision: action.payload
      }
    }),
    [`${ActionTypes.LTM_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        isCheckedLTM: action.payload
      }
    }),
    [`${ActionTypes.EXPECT_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        isCheckedExpect: action.payload
      }
    }),
    [`${ActionTypes.UNIT_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        selectedUnit: action.payload
      }
    }),
    [`${ActionTypes.TYPE_UPDATE}`]: (state, action) => ({
      ...state,
      condition: {
        ...state.condition,
        selectedType: action.payload
      }
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(100, params)),
  changeCondition: createAction(ActionTypes.CONDITION_CHANGE, params =>
    request(101, params)
  ),
  updateFrom: createAction(ActionTypes.FROM_UPDATE),
  updateTo: createAction(ActionTypes.TO_UPDATE),
  updateDivision: createAction(
    ActionTypes.DIVISION_UPDATE,
    params => request(104, params),
    params => ({ accKbn: params.accKbn })
  ),
  updateLTM: createAction(ActionTypes.LTM_UPDATE),
  updateExpect: createAction(ActionTypes.EXPECT_UPDATE),
  updateUnit: createAction(ActionTypes.UNIT_UPDATE),
  updateType: createAction(ActionTypes.TYPE_UPDATE)
};
