import { buildConfig } from "analytics/config";
import { BuildInitPage, buildInitPage } from "analytics/initPage";
import { actions, ActionName, Options } from "analytics/action";

type PartiallyRequired<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>;

// Atlas ページビュー送信
export const initPage = ({
  pageName = "",
  categories = [],
  planValue = null,
  contentId = null,
  contentName = null,
  pageNum = 1,
  customObject = {},
  referrer = null,
  url = null
}: PartiallyRequired<BuildInitPage, "pageName" | "categories">) => {
  const port = location.port ? `:${location.port}` : "";
  const origin = `${location.protocol}//${location.hostname}${port}`;
  const fixedPageName = pageName.replace(/\n/g, " ");
  window.postMessage(
    {
      type: "config",
      payload: buildConfig({ url, referrer })
    },
    origin
  );
  window.postMessage(
    {
      type: "initPage",
      payload: buildInitPage({
        pageName: fixedPageName,
        categories,
        planValue,
        contentId,
        contentName,
        pageNum,
        customObject
      })
    },
    origin
  );
};

/**
 * Atlas イベント計測
 * @param {string} actionName アクション名
 * @param {?object} options Atlas 設定値
 */
export const trackAction = <T extends ActionName>(
  actionName: T,
  options?: Options<T>
) => {
  const port = location.port ? `:${location.port}` : "";
  const origin = `${location.protocol}//${location.hostname}${port}`;
  const payload = actions[actionName](options as any);

  if (MODE === "development") {
    // eslint-disable-next-line no-console
    console.info(payload);
  }
  window.postMessage(
    {
      type: "config",
      payload: buildConfig()
    },
    origin
  );
  window.postMessage(
    {
      type: "trackAction",
      payload: payload
    },
    origin
  );
};
