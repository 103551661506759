import React from "react";
import styles from "./style.scss";

import { isEmpty } from "utils/helper/common";
import { Link } from "react-router-dom";
import {
  corpIndustryKbn,
  loadCorporationChildPagePaths
} from "utils/helper/url";

class ArticleStrategy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: props.resourceId
    };

    this.getFlag = this.getFlag.bind(this);
  }

  getFlag(data, industryInfoList) {
    const industry = industryInfoList.filter(item => {
      return item.companyName === data;
    });
    if (industry && industry[0] && industry[0].flag) {
      return true;
    }
    return false;
  }

  renderTable(datas) {
    const buildCropUrl = details => {
      // 空か上場企業でなければスキップ
      if (
        isEmpty(details) ||
        !details.some(detail => detail.header === "日経会社コード")
      )
        return false;

      const search = new URLSearchParams();
      search.append("corpIndustyKbn", corpIndustryKbn.domestic);
      for (const key in details) {
        switch (details[key].header) {
          case "日経会社コード":
            search.append("corpIndustyCode", details[key].data);
            break;
          case "corpType":
            search.append("corpType", details[key].data);
            break;
          case "asrf":
            search.append("asrf", details[key].data);
            break;
        }
      }

      const paths = loadCorporationChildPagePaths(
        "corporation",
        "basics",
        corpIndustryKbn.domestic
      ); // 企業サマリのパス

      return `${paths[0].path}?${search}`;
    };

    const colNum = 3;
    const element = (
      <table className={styles.vsTableHasLink}>
        <tbody>
          {datas.map((data, index) => {
            const dataDetails = data.details.filter(detail => {
              return detail.header !== "corpType" && detail.header !== "asrf";
            });
            const url = buildCropUrl(data.details);
            return (
              <React.Fragment key={index}>
                <tr>
                  <th
                    style={{
                      backgroundColor: index % 2 === 0 ? "#eaf1f5" : "#dee8ef"
                    }}
                    className={styles.header}
                    rowSpan={dataDetails.length + 1}
                  >
                    <span className={styles.fulfilledCell}>{data.header}</span>
                  </th>
                  <td colSpan={colNum - 1}>
                    {url ? (
                      <Link
                        to={url}
                        className={styles.fulfilledCell}
                        target="_blank"
                      >
                        {data.data}
                      </Link>
                    ) : (
                      <span className={styles.fulfilledCell}>{data.data}</span>
                    )}
                  </td>
                </tr>
                {!isEmpty(dataDetails) &&
                  dataDetails.map((detail, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.header}>
                          <span className={styles.fulfilledCell}>
                            {detail.header}
                          </span>
                        </td>
                        <td>
                          {url && detail.header === "株式コード" ? (
                            <Link
                              to={url}
                              className={styles.fulfilledCell}
                              target="_blank"
                            >
                              {detail.data}
                            </Link>
                          ) : (
                            <span className={styles.fulfilledCell}>
                              {detail.data}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );

    return element;
  }

  renderMeta() {
    const keys = [
      "printedDate",
      "mediaName",
      "pageFromTo",
      "characterCount",
      "keyPdf"
    ];
    const props = this.props;
    return keys.map(key => {
      if (isEmpty(props[key])) return;
      if (key === "printedDate") {
        const printedDate = props[key].trim().substr(0, 10);
        return (
          <span key={key} className={styles.data}>
            {printedDate}
          </span>
        );
      }

      if (key === "characterCount") {
        let characterCountDisp = "";
        const re = /文字$/;
        if (props[key].match(re)) {
          characterCountDisp = props[key];
        } else if (!props[key].match(re) && props.keyText == "1") {
          characterCountDisp = `全${props.characterCount}件`;
        } else {
          characterCountDisp = `${props.characterCount}文字`;
        }
        return (
          <span key={key} className={styles.data}>
            {characterCountDisp}
          </span>
        );
      }

      if (key === "keyPdf")
        return (
          <span key={key} className={styles.data}>
            PDF有
          </span>
        );

      return (
        <span key={key} className={styles.data}>
          {props[key]}
        </span>
      );
    });
  }

  render() {
    const props = this.props;

    return (
      <React.Fragment>
        <div
          className={styles.component}
          data-testid="Common-Popup-ArticleStrategy"
        >
          <div className={styles.main}>
            <div className={styles.meta}>{this.renderMeta()}</div>
            <div className={styles.title}>{props.headline}</div>
            {/* テーブル系レンダリング */}
            {!isEmpty(props.reportStrategyDatas) &&
              this.renderTable(props.reportStrategyDatas)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ArticleStrategy;
