import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  contents: [],
  selectedContent: {},
  sortOptions: [],
  selectedSort: {},
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      selectedContent: action.payload.mediaTabId
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [ActionTypes.CONTENTS_SET]: (state, action) => ({
      ...state,
      contents: action.payload
    }),
    [ActionTypes.SORT_OPTIONS_SET]: (state, action) => ({
      ...state,
      sortOptions: action.payload,
      selectedSort: action.payload[0].value
    }),
    [ActionTypes.SELECTED_OPTION_SET]: (state, action) => ({
      ...state,
      [action.meta.key]: action.payload
    }),
    [`${ActionTypes.PAGE_GET}_PENDING`]: (state, action) => ({
      ...state,
      selectedSort: action.meta.sort
    }),
    [`${ActionTypes.PAGE_GET}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      selectedContent: action.payload.mediaTabId,
      selectedSort: action.payload.sort
    }),
    [`${ActionTypes.PAGER_CHANGE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(93, params)),
  getPage: createAction(
    ActionTypes.PAGE_GET,
    params => request(94, params),
    params => ({ sort: params.sort })
  ),
  setContents: createAction(ActionTypes.CONTENTS_SET),
  setSortOptions: createAction(ActionTypes.SORT_OPTIONS_SET),
  setSelectedOption: createAction(
    ActionTypes.SELECTED_OPTION_SET,
    params => params,
    (params, key) => ({ key: key })
  ),
  changePage: createAction(ActionTypes.PAGER_CHANGE, params =>
    request(95, params)
  )
};
