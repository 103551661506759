import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

import { request } from "utils/apiClient/base";

const initialState = {
  data: {},
  conditions: {
    periodFrom: "",
    periodTo: "",
    currencyKind: "",
    bussegmentItemName: "",
    segmentItemName: ""
  },
  graphType: {
    business: 0,
    area: 0
  },
  error: {}
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      conditions: {
        periodFrom: action.payload.periodFrom,
        periodTo: action.payload.periodTo,
        currencyKind: action.payload.currencyKind,
        bussegmentItemName: action.payload.bussegmentItemName,
        segmentItemName: action.payload.segmentItemName
      }
    }),
    [`${ActionTypes.PAGE_INIT}_PENDING`]: () => initialState,
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [`${ActionTypes.DATA_UPDATE}_FULFILLED`]: (state, action) => ({
      ...state,
      data: action.payload,
      graphType: initialState.graphType
    }),
    [`${ActionTypes.DATA_UPDATE}_REJECTED`]: (state, action) => ({
      ...state,
      error: action.payload
    }),
    [ActionTypes.CONDITION_CHANGE]: (state, action) => ({
      ...state,
      conditions: {
        ...state.conditions,
        [action.meta.key]: action.payload
      }
    }),
    [ActionTypes.CONDITION_RESET]: state => ({
      ...state,
      conditions: {
        ...initialState.conditions
      }
    }),
    [ActionTypes.GRAPH_TYPE_CHANGE]: (state, action) => ({
      ...state,
      graphType: {
        ...state.graphType,
        [action.meta.key]: action.payload
      }
    })
  },
  initialState
);

export const actionCreators = {
  initBusinessAreaPage: createAction(ActionTypes.PAGE_INIT, params =>
    request(225, params)
  ),
  updateBusinessAreaData: createAction(ActionTypes.DATA_UPDATE, params =>
    request(226, params)
  ),
  changeCondition: createAction(
    ActionTypes.CONDITION_CHANGE,
    params => params,
    (params, key) => ({ key: key })
  ),
  resetConditions: createAction(ActionTypes.CONDITION_RESET),
  changeGraphType: createAction(
    ActionTypes.GRAPH_TYPE_CHANGE,
    params => params,
    (params, key) => ({ key: key })
  )
};
