import { useDispatch } from "react-redux";
import { request } from "utils/apiClient";
import { FilterItem } from "models/CorpScreening";
import {
  setFilterTree,
  setIsFilterTreeLoading
} from "modules/tool/screening/corp/modernItem/tree/actions";
import { setDisplayTree } from "modules/tool/screening/corp/modernItem/tree/actions";
import { actionCreators as toastActionCreators } from "modules/toast/actions";

interface TreeResponse {
  data: string[];
  id: string;
  child: TreeResponse[];
  childExistFlag: boolean;
  groupFlg: "0" | "1";
  listedUsedFlg: "0" | "1";
  unlistedHolderFlg: "0" | "1";
  unlistedFlg: "0" | "1";
  unlistedTdbFlg: "0" | "1";
  overseasFlg: "0" | "1";
  overseasUnlistedFlg: "0" | "1";
  itemDispFlg: string;
}

const convertTreeData = (data: TreeResponse[]): FilterItem[] => {
  return data.map(d => ({
    id: d.id,
    label: d.data[0],
    children: convertTreeData(d.child),
    childExistFlag: d.childExistFlag,
    hierarchyNames: [],
    group: d.groupFlg === "1",
    listed: d.listedUsedFlg === "1",
    unlistedHolder: d.unlistedHolderFlg === "1",
    unlistedCorp: d.unlistedFlg === "1",
    unlistedTdb: d.unlistedTdbFlg === "1",
    listedOverseas: d.overseasFlg === "1",
    unlistedOverseas: d.overseasUnlistedFlg === "1",
    itemDispFlg: Number(d.itemDispFlg)
  }));
};

export const useScreeningTree = () => {
  const dispatch = useDispatch();

  const fetchFilterTreeList = async ({ currency }: { currency: string }) => {
    try {
      dispatch(setIsFilterTreeLoading(true));

      const response = await request<TreeResponse[]>(
        "/tool/screening/corp/detail/filter-tree-list",
        {
          currency,
          corpIndustyKbn: "7"
        },
        "POST",
        true
      );

      dispatch(setFilterTree(convertTreeData(response)));
    } catch (error) {
      const e = error as any;
      dispatch(toastActionCreators.setErrors(e.message));
    } finally {
      dispatch(setIsFilterTreeLoading(false));
    }
  };

  const fetchDisplayTree = async () => {
    const response = await request<TreeResponse[]>(
      "/tool/screening/corp/detail/display-tree-list",
      {
        corpIndustyKbn: "7"
      },
      "POST",
      true
    );

    dispatch(setDisplayTree(convertTreeData(response)));
  };

  return { fetchFilterTreeList, fetchDisplayTree };
};
