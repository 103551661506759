import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";

const initialState = {
  data: {},
  tdbInfo: {
    tdbNkgnCodes: "",
    tbdIndustryCodeList: []
  },
  isRetried: false, // disc 無限ループ防止フラグ
  needsRequest: false, // disc リクエスト要求
  discParams: {} // 直近にdiscリクエストしたパラメータ
};

export default handleActions(
  {
    [ActionTypes.ITEMS_RESET]: state => ({
      ...state,
      data: initialState.data,
      isRetried: false
    }),
    [ActionTypes.ITEMS_GET]: (state, action) => ({
      ...state,
      data: action.payload,
      isRetried: action.meta
    }),
    [ActionTypes.TDB_INFO_SET]: (state, action) => ({
      ...state,
      tdbInfo: action.payload
    }),
    [ActionTypes.NEEDS_REQUEST_SET]: state => ({
      ...state,
      needsRequest: true
    }),
    [ActionTypes.NEEDS_REQUEST_UNSET]: state => ({
      ...state,
      needsRequest: false
    }),
    [ActionTypes.DISC_PARAMS_SET]: (state, action) => ({
      ...state,
      discParams: action.payload
    })
  },
  initialState
);

export const actionCreators = {
  resetItems: createAction(ActionTypes.ITEMS_RESET),
  getItems: createAction(
    ActionTypes.ITEMS_GET,
    json => json,
    (json, isRetry) => isRetry
  ),
  setTdbInfo: createAction(ActionTypes.TDB_INFO_SET),
  setNeedsRequest: createAction(ActionTypes.NEEDS_REQUEST_SET),
  unsetNeedsRequest: createAction(ActionTypes.NEEDS_REQUEST_UNSET),
  setDiscParams: createAction(ActionTypes.DISC_PARAMS_SET)
};
