import { AreaAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";
import { buildYearList, getDateFromYear } from "./helper";
import { getEdgeDate } from "./helper";
import { Series, Area, Binder, CollectionItem, AreaData } from "models/Macro";

export interface Condition {
  from: string;
  to: string;
  extValue: string;
  dataType: string;
  type: "series" | "area" | "init";
  typeValue: string;
}

export interface Loading {
  list: boolean;
}

interface AreaState {
  data: AreaData | null;
  keyword: {
    list: any[];
    count: number | null;
  };
  seriesTempInfoList: Series[];
  binderItems: CollectionItem[];
  binderList: Binder[];
  areaInfoList: {
    list: Area[];
    keyword: Area[];
  };
  selectedSeriesItems: Series[];
  selectedAreaItems: Area[];
  updateSearchResult: any;
  seriesResult: any;
  areaResult: any;
  conditions: {
    value: Condition;
    list: {
      year: number[];
    };
  };
  newsData: any;
  transitionItems: any[];
  isLoading: Loading;
}

const initialState: AreaState = {
  data: null,
  keyword: {
    list: [],
    count: null
  },
  seriesTempInfoList: [],
  binderItems: [],
  binderList: [],
  areaInfoList: {
    list: [],
    keyword: []
  },
  selectedSeriesItems: [],
  selectedAreaItems: [],
  updateSearchResult: {},
  seriesResult: {},
  areaResult: {},
  conditions: {
    value: {
      from: "",
      to: "",
      extValue: "",
      dataType: "1",
      type: "init",
      typeValue: ""
    },
    list: {
      year: []
    }
  },
  newsData: {},
  transitionItems: [],
  isLoading: {
    list: false
  }
};

export const area: Reducer<AreaState, AreaAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "macro/area/INIT_PAGE":
      return produce(state, draft => {
        draft.data = action.payload.data;
      });
    case "macro/area/SET_KEYWORD":
      return produce(state, draft => {
        draft.keyword = action.payload;
      });
    case "macro/area/SET_BINDER_LIST":
      return produce(state, draft => {
        draft.binderList = action.payload;
      });
    case "macro/area/SET_BINDER_ITEMS":
      return produce(state, draft => {
        draft.binderItems = action.payload;
      });
    case "macro/area/GET_SERIES_LIST":
      return produce(state, draft => {
        draft.seriesTempInfoList = action.payload;
      });
    case "macro/area/GET_AREA_LIST":
      return produce(state, draft => {
        if (action.payload.key === "list") {
          draft.areaInfoList.list = action.payload.areaInfoList;
          return;
        }
        draft.areaInfoList.keyword = action.payload.areaInfoList;
      });
    case "macro/area/UPDATE_SERIES_SELECTED_ITEMS":
      return produce(state, draft => {
        draft.selectedSeriesItems = action.payload.data;
      });

    case "macro/area/UPDATE_AREA_SELECTED_ITEMS":
      return produce(state, draft => {
        draft.selectedAreaItems = action.payload.data;
      });
    case "macro/area/CHANGE_CONDITION":
      return produce(state, draft => {
        if (action.payload.key === "type") {
          draft.conditions.value.type = action.payload.value.type;
          draft.conditions.value.typeValue = action.payload.value.value;
          return;
        } else if (action.payload.key === "from") {
          draft.conditions.value.from = getDateFromYear(
            action.payload.value,
            "From"
          );
          return;
        } else if (action.payload.key === "to") {
          draft.conditions.value.to = getDateFromYear(
            action.payload.value,
            "To"
          );
          return;
        } else if (action.payload.key === "dataType") {
          draft.conditions.value.dataType = action.payload.value;
          return;
        } else if (action.payload.key === "reset") {
          draft.conditions.value = initialState.conditions.value;
        }
      });
    case "macro/area/SET_UPDATE_SEARCH_RESULT":
      return produce(state, draft => {
        draft.transitionItems = initialState.transitionItems;

        if (action.payload.params.type !== "init") {
          draft.updateSearchResult = action.payload.response;
        }

        if (!action.payload.params) return;

        if (action.payload.params.type === "series") {
          draft.seriesResult = action.payload.response;
        } else {
          draft.areaResult = action.payload.response;
        }

        if (action.payload.params.type === "init") return;
        draft.conditions.value = {
          ...draft.conditions.value,
          ...action.payload.params
        };

        const extList = state.selectedSeriesItems.map(
          (item: any) => item.extPeriodInfo || item.areaExtFlg
        );
        const from = getEdgeDate(
          extList,
          "From",
          state.data ? state.data.macroArea.workDay : ""
        );
        const to = getEdgeDate(
          extList,
          "To",
          state.data ? state.data.macroArea.workDay : ""
        );

        const year = buildYearList(from, to);
        draft.conditions.list.year = year;
      });
    case "macro/area/GET_NEWS_LIST":
      return produce(state, draft => {
        draft.newsData = action.payload.newsData;
      });
    case "macro/area/RESET_STATE":
      return initialState;
    case "macro/area/SET_TRANSITION_ITEMS":
      return produce(state, draft => {
        draft.transitionItems = action.payload;
      });
    case "macro/area/SET_LOADING":
      return produce(state, draft => {
        draft.isLoading[action.payload.key] = action.payload.value;
      });
    default:
      return state;
  }
};
