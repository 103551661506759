import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";

const initialState = {
  tree: [],
  result: [],
  average: "", // 平均値データ文字列
  error: {},
  file: {
    xls: {
      dl_file_name: "",
      dl_file_path: ""
    },
    csv: {
      dl_file_name: "",
      dl_file_path: ""
    }
  },
  needScroll: false,
  isLoaded: false,
  // 大項目検索リクエストの状態、ローディング表示に使用する
  isParentLoaded: false,
  // サジェスト検索フラグ、単一ハイライト判定に使用する
  isSuggest: false,
  keyword: ""
};

export default handleActions(
  {
    [`${ActionTypes.PAGE_INIT}_PENDING`]: state => ({
      ...state,
      isLoaded: false,
      isParentLoaded: false,
      keyword: initialState.keyword,
      isSuggest: false
    }),
    [`${ActionTypes.PAGE_INIT}_FULFILLED`]: (state, action) => ({
      ...state,
      tree: action.payload.searchMap01Dto.treeDataList,
      average: action.payload.aveVarsData,
      file: {
        xls: {
          dl_file_name: action.payload.dlxls,
          dl_file_path: action.payload.dlxlspath
        },
        csv: {
          dl_file_name: action.payload.dlcsv,
          dl_file_path: action.payload.dlcsvpath
        }
      },
      isLoaded: true,
      isParentLoaded: true
    }),
    [`${ActionTypes.PAGE_INIT}_REJECTED`]: state => ({
      ...state,
      isLoaded: true,
      isParentLoaded: true
    }),
    [`${ActionTypes.CHILDREN_GET}_FULFILLED`]: (state, action) => {
      // 子を追加したい親ノードのID(state.parentId)を探して追加
      const recursiveSearchParent = (tree, parentId) => {
        return tree.map(node => {
          const newNode = { ...node };
          // 子ツリーを追加
          if (newNode.id === parentId) {
            newNode.child = action.payload.treeDataList;
            newNode.childLoaded = true;
            newNode.isOpen = true;

            // highlightInfoListを追加
            action.payload.highlightInfoList.forEach((highlightInfo, index) => {
              const child = action.payload.treeDataList[index];
              if (highlightInfo.splitHitText.join("") == child.data[0]) {
                // サジェストの場合、完全一致した場合(単一)のみハイライトする
                if (!state.isSuggest || highlightInfo.splitHitText.length === 1)
                  child.highlightInfo = highlightInfo;
              }
            });
            return newNode;
          }
          // 子ツリーがロードされていれば再帰処理
          if (newNode.childLoaded) {
            newNode.child = recursiveSearchParent(newNode.child, parentId);
          }
          return newNode;
        });
      };
      const newIndexTree = recursiveSearchParent(
        state.tree,
        action.meta.parentId
      );

      return {
        ...state,
        tree: newIndexTree
      };
    },
    [`${ActionTypes.SEARCH}_FULFILLED`]: (state, action) => {
      let newIndexTree = [...state.tree];
      const recursiveResetParent = tree => {
        return tree.map(node => {
          const newNode = { ...node };
          // 閉じる
          newNode.isOpen = false;
          // 子ツリーがロードされていた場合
          if (newNode.childLoaded) {
            // ハイライト消す
            newNode.child = newNode.child.map(child => {
              delete child.highlightInfo;
              return child;
            });
            // 子を初期化
            newNode.child = recursiveResetParent(newNode.child);
          }
          return node;
        });
      };
      newIndexTree = recursiveResetParent(newIndexTree);

      return {
        ...state,
        tree: newIndexTree,
        result: action.payload
      };
    },
    [`${ActionTypes.PARENT_SEARCH}_PENDING`]: state => ({
      ...state,
      isLoaded: false,
      isParentLoaded: false
    }),
    [`${ActionTypes.PARENT_SEARCH}_FULFILLED`]: (state, action) => {
      const highlightInfoList = action.payload.searchMap01Dto.highlightInfoList;
      const treeDataList = action.payload.searchMap01Dto.treeDataList;

      // highlightInfoListを追加
      highlightInfoList.forEach((highlightInfo, index) => {
        const child = treeDataList[index];
        if (highlightInfo.splitHitText.join("") == child.data[0]) {
          child.highlightInfo = highlightInfo;
        }
      });

      let parentIndexTree = treeDataList;
      const recursiveResetParent = tree => {
        return tree.map(node => {
          const newNode = { ...node };
          // 閉じる
          newNode.isOpen = false;
          // 子ツリーがロードされていた場合
          if (newNode.childLoaded) {
            // ハイライト消す
            newNode.child = newNode.child.map(child => {
              delete child.highlightInfo;
              return child;
            });
            // 子を初期化
            newNode.child = recursiveResetParent(newNode.child);
          }
          return node;
        });
      };
      parentIndexTree = recursiveResetParent(parentIndexTree);

      return {
        ...state,
        tree: parentIndexTree,
        result: action.payload,
        isParentLoaded: true
      };
    },
    [`${ActionTypes.PARENT_SEARCH}_REJECTED`]: state => ({
      ...state,
      isParentLoaded: true
    }),
    [ActionTypes.NEED_SCROLL_FLAG_SET]: state => ({
      ...state,
      needScroll: true
    }),
    [ActionTypes.NEED_SCROLL_FLAG_CLEAR]: state => ({
      ...state,
      needScroll: false
    }),
    [ActionTypes.TREE_LOADED]: state => ({
      ...state,
      isLoaded: true
    }),
    [ActionTypes.KEYWORD_SET]: (state, action) => ({
      ...state,
      keyword: action.payload.keyword,
      // 次の search 処理まで isSuggest フラグを維持する
      isSuggest: action.payload.isSuggest
    }),
    [ActionTypes.CLEAR_STATE]: () => initialState
  },
  initialState
);

export const actionCreators = {
  initPage: createAction(ActionTypes.PAGE_INIT, params => request(381, params)),
  getChildren: createAction(
    ActionTypes.CHILDREN_GET,
    params => request(382, params),
    (_, parentId) => ({ parentId: parentId })
  ),
  searchParent: createAction(ActionTypes.PARENT_SEARCH, params =>
    request(381, params)
  ),
  search: createAction(ActionTypes.SEARCH, params => request(383, params)),
  setNeedScrollFlag: createAction(ActionTypes.NEED_SCROLL_FLAG_SET),
  clearNeedScrollFlag: createAction(ActionTypes.NEED_SCROLL_FLAG_CLEAR),
  treeLoaded: createAction(ActionTypes.TREE_LOADED),
  setKeyword: createAction(ActionTypes.KEYWORD_SET),
  clear: createAction(ActionTypes.CLEAR_STATE)
};
