exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Select-Label-style__component--2yIkT {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  width: 100%;\n  font-size: 13px;\n}\n.src-components-Common-Select-Label-style__component--2yIkT .src-components-Common-Select-Label-style__label--3iWUt {\n  margin-right: 12px;\n}\n.src-components-Common-Select-Label-style__component--2yIkT ul {\n  display: -ms-flexbox;\n  display: flex;\n  margin: 0;\n  padding-right: 8px;\n  padding-left: 0;\n  list-style: none;\n  border-right: 1px solid #eaf1f5;\n  border-left: 1px solid #eaf1f5;\n}\n.src-components-Common-Select-Label-style__component--2yIkT li {\n  padding: 8px;\n}\n.src-components-Common-Select-Label-style__component--2yIkT li > span {\n  cursor: pointer;\n  color: #00578e;\n}\n.src-components-Common-Select-Label-style__component--2yIkT li > span.src-components-Common-Select-Label-style__active--ITpaG {\n  cursor: default;\n  font-weight: bold;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-components-Common-Select-Label-style__component--2yIkT",
	"label": "src-components-Common-Select-Label-style__label--3iWUt",
	"active": "src-components-Common-Select-Label-style__active--ITpaG"
};